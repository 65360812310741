import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import { db, storage, getProviderUserByProderviderUserDataUid } from '../firebase';
import Pagination from '../Components/Pagination';
import { BanProviderUserForm } from './ViewProviderUsers';
const axios = require('axios').default;
const CancelToken = axios.CancelToken;
let cancel;
const defaultLuimit = 10;
export default class DevProviderUsers extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            training_date_status: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : defaultLuimit,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : 'avaliable',
            orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : '',
            tranningStatus: Helper.getParameterByName('tranningStatus') ? Helper.getParameterByName('tranningStatus') : '',
            amphoe: Helper.getParameterByName('amphoe') ? Helper.getParameterByName('amphoe') : '',
            province: Helper.getParameterByName('province') ? Helper.getParameterByName('province') : '',
            phoneNumber: Helper.getParameterByName('phoneNumber') ? Helper.getParameterByName('phoneNumber') : '',
            is_show_next_button: false,
            provider_all: 0,
            provider_wait_for_approve: 0,
            provider_approved: 0,
            provider_avaliable: 0,
            provider_ban: 0,
            all_items: 0,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : 'avaliable',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : defaultLuimit,
                orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : '',
                tranningStatus: Helper.getParameterByName('tranningStatus') ? Helper.getParameterByName('tranningStatus') : '',
                amphoe: Helper.getParameterByName('amphoe') ? Helper.getParameterByName('amphoe') : '',
                province: Helper.getParameterByName('province') ? Helper.getParameterByName('province') : '',
                phoneNumber: Helper.getParameterByName('phoneNumber') ? Helper.getParameterByName('phoneNumber') : '',
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: defaultLuimit,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        if (cancel !== undefined) cancel();
        let data = [];
        let prepare_data = [];
        let count = this.state.all_items;
        let provider_all = this.state.provider_all || 0;
        let provider_wait_for_approve = this.state.provider_wait_for_approve || 0;
        let provider_approved = this.state.provider_approved || 0;
        let provider_avaliable = this.state.provider_avaliable || 0;
        let provider_ban = this.state.provider_ban || 0;
        let _training_date_status = [...this.state.training_date_status];
        if (this.state.training_date_status.length == 0) {
            let training_date_status = await db.collection('training_date_status').get();
            _training_date_status = await Promise.all(
                training_date_status.docs.map(async (element) => {
                    let element_data = { ...element.data() };
                    return {
                        ...element_data,
                        id: element.id,
                    };

                })
            );
        }
        if (!this.state.set_status) {
            let all_provicerRef = await db.collection("provider_users_data").get();
            await Promise.all(
                all_provicerRef.docs.map((element) => {
                    provider_all++;
                    if (element.data().status === 'waitForApprove') {
                        provider_wait_for_approve++;
                    }
                    if (element.data().status === 'approved') {
                        provider_approved++;
                    }
                    if (element.data().status === 'avaliable') {
                        provider_avaliable++;
                    }
                    if (element.data().status === 'ban') {
                        provider_ban++;
                    }
                    return true
                })
            );
        }
        let filter = {
            page: this.state.page,
            limit: this.state.limit,
        };
        if (this.state.name) {
            filter.name = this.state.name;
        }
        if (this.state.tel) {
            filter.tel = this.state.tel;
        }
        if (this.state.status) {
            filter.status = this.state.status;
        }
        if (this.state.phoneNumber) {
            let phone = this.state.phoneNumber;
            let phoneCheck = phone.charAt(0);
            if (phoneCheck == '0') {
                phone = '+66' + phone.substr(1, 9);
            }
            filter.phoneNumber = phone;
        }
        if (this.state.tranningStatus) {
            filter.tranningStatus = this.state.tranningStatus;
        }
        if (this.state.amphoe) {
            filter.amphoe = this.state.amphoe;
        }
        if (this.state.province) {
            filter.province = this.state.province;
        }

        if (this.state.orderBy === 'name_asc' || !this.state.orderBy) {
            filter.orderBy = "firstName";
            filter.orderByType = 'asc';
        }
        if (this.state.orderBy === 'name_desc') {
            filter.orderBy = "firstName";
            filter.orderByType = 'desc';
        }
        if (this.state.orderBy === 'date_asc') {
            filter.orderBy = "createdAt";
            filter.orderByType = 'asc';
        }
        if (this.state.orderBy === 'date_desc') {
            filter.orderBy = "createdAt";
            filter.orderByType = 'desc';
        }
        // if (this.state.all_items == 0) {
        //     filter.requestCountAll = true;
        // }
        let res = await axios.get(`${window.api_host}/providers?${window.$.param(filter)}`, {
            cancelToken: new CancelToken(c => cancel = c),
        });
        if (res.data.success) {
            data = res.data.data;
            count = res.data.count_items;
        }
        this.setState({
            data: data,
            is_loading: false,
            is_absolute_loading: false,
            all_items: count,
            set_status: true,
            provider_all: provider_all,
            provider_wait_for_approve: provider_wait_for_approve,
            provider_approved: provider_approved,
            provider_avaliable: provider_avaliable,
            provider_ban: provider_ban,
            training_date_status: _training_date_status,
        })
    }

    async delete(data) {

        const participants = await db.collection(`training_date/${data.traningUID}/participants`).get();
        participants.docs.map(async (doc) => {
            if (doc.data().providerUid === data.id) {
                await db.doc(`training_date/${data.traningUID}/participants/` + doc.id).delete();
            }
        })
        const booking = await db.collection('books').where('providerUserUid', '==', data.id).get();
        let documentRef = db.doc(`provider_users_data/` + data.id);
        if (booking.docs.length > 0) {
            documentRef.update({ status: 'cancel' }).then(() => {
                this.fetchData();
                window.$(document).Toasts('create', {
                    title: 'ลบข้อมูลสำเร็จ',
                    body: 'ทำการลบข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
            })
        }
        if (booking.docs.length === 0) {
            if (data.transferImageUrl) {
                storage.ref(data.transferImageUrl).delete();
            }
            if (data.profileImageUrl) {
                storage.ref(data.profileImageUrl).delete();
            }
            if (data.IDcardImageUrl) {
                storage.ref(data.IDcardImageUrl).delete();
            }
            if (data.bankAccountImageUrl) {
                storage.ref(data.bankAccountImageUrl).delete();
            }
            documentRef.delete().then(() => {
                this.fetchData();
                window.$(document).Toasts('create', {
                    title: 'ลบข้อมูลสำเร็จ',
                    body: 'ทำการลบข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
            });
        }
    }

    updateStatus(id, data) {
        this.setState({
            is_absolute_loading: true,
        }, async () => {
            await db.doc("provider_users_data/" + id).update({ ...data }).then(() => {
                window.$(document).Toasts('create', {
                    title: 'แก้ไขข้อมูลสำเร็จ',
                    body: 'เปลี่ยนสถานะสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                this.fetchData();
            });
        })
    }


    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">แม่บ้าน DEV</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    {
                                        this.state.status === 'cancel' ?
                                            <li className="breadcrumb-item"><Link to="/provider-users">แม่บ้าน</Link></li>
                                            : null
                                    }
                                    <li className="breadcrumb-item active">แม่บ้านที่ถูกยกเลิก</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header ">
                                <h3 className="card-title">รายชื่อแม่บ้าน</h3>
                                <div className="card-tools">
                                    <form className="input-group input-group-sm" ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let params = {
                                                page: 1,
                                                ...data,
                                            }
                                            if (this.state.amphoe) {
                                                params.amphoe = this.state.amphoe
                                            }
                                            let url = Helper.getQueryStringLinkFromObject(params)
                                            navigate(url);
                                        }}
                                    >
                                        <select
                                            className="form-control placeholder_font"
                                            name="tranningStatus"
                                            defaultValue={this.state.tranningStatus}
                                            onChange={() => {
                                                this.$searchForm.find('[type="submit"]').trigger('click');
                                            }}
                                        >
                                            <option value="">ค้นหาจากสถานะอบรม</option>
                                            {
                                                this.state.training_date_status.map((training_date_status, training_date_status_i) => (
                                                    <option value={training_date_status.id} key={training_date_status_i}>{training_date_status.status_name}</option>
                                                ))
                                            }

                                        </select>
                                        <select
                                            className="form-control placeholder_font"
                                            name="orderBy"
                                            defaultValue={this.state.orderBy}
                                            onChange={() => {
                                                this.$searchForm.find('[type="submit"]').trigger('click');
                                            }}
                                        >
                                            <option value="">เรียงลำดับ</option>
                                            <option value="name_asc">ชื่อ A-Z</option>
                                            <option value="name_desc">ชื่อ Z-A</option>
                                            <option value="date_desc">วันที่สร้าง ใหม่-เก่า</option>
                                            <option value="date_asc">วันที่สร้าง เก่า-ใหม่</option>
                                        </select>
                                        <select
                                            className="form-control placeholder_font"
                                            name="status"
                                            defaultValue={this.state.status}
                                            onChange={() => {
                                                this.$searchForm.find('[type="submit"]').trigger('click');
                                            }}
                                        >
                                            <option value="">ค้นหาจากสถานะ</option>
                                            <option value="waitForApprove">รอการยืนยัน</option>
                                            <option value="approved">ยืนยันแล้ว</option>
                                            <option value="avaliable">ใช้งานได้ </option>
                                            <option value="ban">ระงับการใช้งาน</option>
                                            <option value="cancel">ยกเลิก</option>
                                        </select>
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากชื่อ" defaultValue={this.state.name}
                                            onChange={() => {
                                                window.$(`input[name="phoneNumber"]`).val("")
                                            }}
                                        />
                                        <input type="text" name="phoneNumber" className="form-control float-right" placeholder="ค้นหาจากเบอร์โทรศัพท์" defaultValue={this.state.phoneNumber}
                                            onChange={() => {
                                                window.$(`input[name="name"]`).val("")
                                            }}
                                        />

                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    this.state.status !== 'cancel' ?
                                        <div className={"flex row form-group"}>
                                            <div>
                                                <Link to={"/provider-users/add"}>
                                                    <button className="btn btn-primary">เพิ่มแม่บ้าน</button>
                                                </Link>
                                                <Link to={"/provider-users?status=cancel"} style={{ marginLeft: 10 }}>
                                                    <button className="btn btn-default">ข้อมูลแม่บ้านที่ถูกยกเลิก</button>
                                                </Link>
                                                <Link to={"/provider/report"} style={{ marginLeft: 10 }}>
                                                    <button className="btn btn-default">รายงานแม่บ้าน</button>
                                                </Link>
                                                <Link to={"/provider/report/adjust"} style={{ marginLeft: 10 }}>
                                                    <button className="btn btn-default">รายงานปรับรายได้แม่บ้าน</button>
                                                </Link>
                                            </div>
                                            <div className={"card-right"}>
                                                <div className="flex row" style={{ alignItems: 'center' }}>
                                                    <div style={{ marginRight: 10 }}>
                                                        <button className="btn btn-default"
                                                            onClick={() => {
                                                                Helper.reactToDom(
                                                                    window.$('body'),
                                                                    <ProvincesAndAmphursSearch
                                                                        amphoe={this.state.amphoe || false}
                                                                        province={this.state.province || false}
                                                                        onSearch={(data) => {
                                                                            let params = {
                                                                                page: 1,
                                                                                ...data,
                                                                            }
                                                                            if (this.state.amphoe) {
                                                                                params.amphoe = this.state.amphoe
                                                                            }
                                                                            let url = Helper.getQueryStringLinkFromObject({
                                                                                page: 1,
                                                                                name: this.state.name || '',
                                                                                status: this.state.status || '',
                                                                                orderBy: this.state.orderBy || '',
                                                                                // orderByType: this.state.orderByType,
                                                                                tranningStatus: this.state.tranningStatus || '',
                                                                                amphoe: data.amphoe || '',
                                                                                province: data.province || '',
                                                                            })
                                                                            navigate(url)
                                                                        }}
                                                                    />
                                                                )
                                                            }}
                                                        >ค้นหาจากพื้นที่รับงาน</button>
                                                    </div>
                                                    <div style={{ marginRight: 10 }}>ทั้งหมด {this.state.provider_all} คน</div>
                                                    <div style={{ marginLeft: 5 }}><i className={"fas fa-circle"} style={{ color: '#ffa200' }} /> {this.state.provider_wait_for_approve}</div>
                                                    <div style={{ marginLeft: 5 }}><i className={"fas fa-circle"} style={{ color: 'blue' }} /> {this.state.provider_approved}</div>
                                                    <div style={{ marginLeft: 5 }}><i className={"fas fa-circle"} style={{ color: 'green' }} /> {this.state.provider_avaliable}</div>
                                                    <div style={{ marginLeft: 5 }}><i className={"fas fa-circle"} style={{ color: 'red' }} /> {this.state.provider_ban}</div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    this.state.is_absolute_loading ?
                                        <div className="absolute_loading">
                                            <div className="ui active inline loader small" ></div>
                                        </div>
                                        : null
                                }
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "20%", minWidth: 230 }}>ชื่อ</th>
                                            <th>เขต</th>
                                            <th style={{ minWidth: 150 }}>ข้อมูลการติดต่อ</th>
                                            <th style={{ minWidth: 150 }}>ข้อมูลอบรม</th>
                                            <th style={{ minWidth: 130 }}>สถานะ App</th>
                                            <th style={{ minWidth: 95 }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    let training_status = "ไม่มีสถานะ";
                                                    if (_data.tranningStatus) {
                                                        let get_training_status = this.state.training_date_status.filter((e) => { return e.id == _data.tranningStatus });
                                                        // console.log(get_training_status)
                                                        if (get_training_status.length > 0) {
                                                            training_status = get_training_status[0].status_name
                                                        }
                                                    }
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {_data.gender === "ชาย" ? <i className="fas fa-mars"></i> : null}
                                                                {_data.gender === "หญิง" ? <i className="fas fa-venus"></i> : null}
                                                                {' '}
                                                                {_data.prefix} {_data.firstName} {_data.lastName}<br />
                                                                <i className="fas fa-star" style={{ color: '#ffa200' }}></i> {_data.provider_user && _data.provider_user.starFloat !== undefined ? _data.provider_user.starFloat.toFixed(2) : '5.00'}
                                                                {
                                                                    _data.lastActive ?
                                                                        <div>ใช้งานล่าสุด {Helper.getDateThai(_data.lastActive, { is_show_time: true })}</div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.provincesCode && _data.amphoesCode
                                                                        && _data.provincesCode.length > 0 && _data.amphoesCode.length > 0 ?
                                                                        <>
                                                                            {
                                                                                Helper.showProvinceWithAmphoe(_data.provincesCode, _data.amphoesCode).map((pv, pv_i) => {
                                                                                    return (
                                                                                        <React.Fragment key={pv_i}>
                                                                                            {pv_i > 0 ? ", " : null}
                                                                                            {pv.province}
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <div>
                                                                                <div className={"btn btn-default btn-sm"}
                                                                                    onClick={() => {
                                                                                        Helper.reactToDom(window.$('body'),
                                                                                            <ProvincesAndAmphursList
                                                                                                provinces={Helper.showProvinceWithAmphoe(_data.provincesCode, _data.amphoesCode)}
                                                                                            />
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    ดูข้อมูลพื้นที่ให้บริการ
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        // <>
                                                                        //     <div>{_data.province}</div>
                                                                        //     {
                                                                        //         _data.amphoe
                                                                        //         && _data.amphoe.length > 0 ?
                                                                        //             <>
                                                                        //                 <div className="tag-label">
                                                                        //                     {
                                                                        //                         _data.amphoe ?
                                                                        //                             _data.amphoe.map((_amphoe, _amphoe_i) => {
                                                                        //                                 if (_amphoe_i < 3) {
                                                                        //                                     return (<div key={_amphoe_i}>{_amphoe}</div>)
                                                                        //                                 }
                                                                        //                             })
                                                                        //                             : null
                                                                        //                     }
                                                                        //                 </div>
                                                                        //                 {
                                                                        //                     _data.amphoe.length > 3 ?
                                                                        //                         <div className={"btn btn-default btn-sm"}
                                                                        //                             onClick={() => {
                                                                        //                                 Helper.reactToDom(window.$('body'),
                                                                        //                                     <AmphurList
                                                                        //                                         amphoe={_data.amphoe}
                                                                        //                                         province={_data.province}
                                                                        //                                     />
                                                                        //                                 )
                                                                        //                             }}
                                                                        //                         >
                                                                        //                             ข้อมูล{_data.province === 'กรุงเทพมหานคร' ? 'เขต' : 'อำเภอ'}ทั้งหมด
                                                                        //                         </div>
                                                                        //                         : null
                                                                        //                 }
                                                                        //             </>
                                                                        //             : null
                                                                        //     }

                                                                        // </>
                                                                        null
                                                                }

                                                            </td>
                                                            <td>
                                                                <i className="fas fa-phone"></i> {_data.phoneNumber}
                                                                {
                                                                    _data.lineID ?
                                                                        <div><i className="fab fa-line"></i> {_data.lineID}</div>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.agent ?
                                                                        <div>ตัวแทน : <Link to={`/agent/${_data.agentUid}`}>{_data.agent.name}</Link></div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className={"flex row"}>
                                                                    <div>{training_status}</div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        <div className="dropdown">
                                                                            <button className="btn btn-default btn-xs dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <i className="far fa-edit"></i>
                                                                            </button>
                                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                {
                                                                                    this.state.training_date_status.map((training_date_status, training_date_status_i) => {
                                                                                        if (training_date_status.id === _data.tranningStatus) { return null }
                                                                                        return (
                                                                                            <div className="dropdown-item"
                                                                                                style={{ cursor: 'pointer' }}
                                                                                                key={training_date_status_i}
                                                                                                onClick={() => {
                                                                                                    let update = { tranningStatus: training_date_status.id }
                                                                                                    if (_data.updateBy) {
                                                                                                        update.updateBy = [..._data.updateBy];
                                                                                                    }
                                                                                                    update = Helper.providerUpdateData(update, this.props.admin, { updateType: 'tranningStatus' });
                                                                                                    this.updateStatus(_data.id, update);
                                                                                                }}
                                                                                            >
                                                                                                {training_date_status.status_name}
                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {_data.educateDate ? <div>{Helper.getDateThai(Helper.getDateToEdit(_data.educateDate))}</div> : '-'}
                                                            </td>
                                                            <td>
                                                                <div className={"flex row"}>
                                                                    <div>

                                                                        {
                                                                            _data.status === 'waitForApprove' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: '#ffa200' }} /> รอการยืนยัน</>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === 'approved' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: 'blue' }} /> ยืนยันแล้ว</>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === 'avaliable' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: 'green' }} /> ใช้งานได้</>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === 'ban' || _data.status === 'cancel' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: 'red' }} /> ระงับการใช้งาน</>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {
                                                                            _data.status !== 'cancel' ?
                                                                                <div style={{ marginLeft: 'auto' }}>
                                                                                    <div className="dropdown">
                                                                                        <button className="btn btn-default btn-xs dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                            <i className="far fa-edit"></i>
                                                                                        </button>
                                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                            {
                                                                                                _data.status === 'waitForApprove' ?
                                                                                                    null
                                                                                                    :
                                                                                                    <div className="dropdown-item" style={{ cursor: 'pointer' }}
                                                                                                        onClick={() => {
                                                                                                            let update = { status: "waitForApprove" }
                                                                                                            if (_data.updateBy) {
                                                                                                                update.updateBy = [..._data.updateBy];
                                                                                                            }
                                                                                                            // console.log(update);
                                                                                                            update = Helper.providerUpdateData(update, this.props.admin, { updateType: 'status' })
                                                                                                            // console.log(update);
                                                                                                            this.updateStatus(_data.id, update);
                                                                                                        }}
                                                                                                    ><i className={"fas fa-circle"} style={{ color: '#ffa200' }} /> รอการยืนยัน</div>
                                                                                            }
                                                                                            {
                                                                                                _data.status === 'approved' ?
                                                                                                    null
                                                                                                    :
                                                                                                    <div className="dropdown-item" style={{ cursor: 'pointer' }}
                                                                                                        onClick={() => {
                                                                                                            let update = { status: "approved" }
                                                                                                            if (_data.updateBy) {
                                                                                                                update.updateBy = [..._data.updateBy];
                                                                                                            }
                                                                                                            update = Helper.providerUpdateData(update, this.props.admin, { updateType: 'status' })
                                                                                                            this.updateStatus(_data.id, update);
                                                                                                        }}
                                                                                                    ><i className={"fas fa-circle"} style={{ color: 'blue' }} /> ยืนยันแล้ว</div>
                                                                                            }
                                                                                            {
                                                                                                _data.status === 'avaliable' ?
                                                                                                    null
                                                                                                    :
                                                                                                    <div className="dropdown-item" style={{ cursor: 'pointer' }}
                                                                                                        onClick={() => {
                                                                                                            let update = { status: "avaliable" }
                                                                                                            if (_data.updateBy) {
                                                                                                                update.updateBy = [..._data.updateBy];
                                                                                                            }
                                                                                                            update = Helper.providerUpdateData(update, this.props.admin, { updateType: 'status' })
                                                                                                            this.updateStatus(_data.id, update);
                                                                                                        }}
                                                                                                    ><i className={"fas fa-circle"} style={{ color: 'green' }} /> ใช้งานได้</div>
                                                                                            }
                                                                                            {
                                                                                                _data.status === 'ban' ?
                                                                                                    null
                                                                                                    :
                                                                                                    <div className="dropdown-item" style={{ cursor: 'pointer' }}
                                                                                                        onClick={() => {
                                                                                                            Helper.reactToDom(window.$('body'),
                                                                                                                <BanProviderUserForm
                                                                                                                    user={_data}
                                                                                                                    admin={this.props.admin}
                                                                                                                    onSuccess={() => {
                                                                                                                        this.fetchData()
                                                                                                                    }}
                                                                                                                    userUID={_data.id}
                                                                                                                />
                                                                                                            )
                                                                                                        }}
                                                                                                    ><i className={"fas fa-circle"} style={{ color: 'red' }} /> ระงับการใช้งาน</div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <Link to={'/provider-users/' + _data.id} type="button" className="btn btn-default">ดูข้อมูล</Link>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export class ProvincesAndAmphursList extends React.Component {
    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        data.createAt = new Date().getTime();
                        if (this.props.mode === 'create') {
                            this.add(data);
                        }
                        if (this.props.mode === 'edit') {
                            this.update(data);
                        }
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                พื้นที่ให้บริการ
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            {
                                this.props.provinces.map((province, province_i) => {
                                    return (
                                        <React.Fragment key={province_i}>
                                            {
                                                province_i > 0 ?
                                                    <div className={"divider"} />
                                                    : null
                                            }
                                            <h5>{province.province}</h5>
                                            <ul className="tag-label" style={{ paddingLeft: 0 }}>
                                                {
                                                    province.amphoes.map((amphoe, i) => {
                                                        return (
                                                            <div key={i}>
                                                                <div>{amphoe.amphoe}</div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </ul>

                                        </React.Fragment>
                                    );
                                })
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export class AmphurList extends React.Component {
    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        data.createAt = new Date().getTime();
                        if (this.props.mode === 'create') {
                            this.add(data);
                        }
                        if (this.props.mode === 'edit') {
                            this.update(data);
                        }
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                {
                                    this.props.province
                                }
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            <ul className="tag-label" style={{ paddingLeft: 0 }}>
                                {
                                    this.props.amphoe.map((amphoe) => {
                                        return (
                                            <div>
                                                <div>{amphoe}</div>
                                            </div>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
class ProvincesAndAmphursSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_province: false,
            selected_amphoe: false,
            db: [],
            is_loading: true,
        }
    }
    componentDidMount() {
        this.fetch();
    }
    async fetch() {
        let province = await axios.get(window.api_host + "/service-area").then(async (res) => {
            let _data = res.data.data.filter((resp) => {
                return resp.amphoe.length > 0
            });
            //    console.log(_data)
            return _data
        })
        this.setState({
            db: province,
            selected_province: this.props.province || province[0].provinceCode,
            selected_amphoe: this.props.amphoe || false,
            is_loading: false,
        })
    }
    getAmphoe() {
        let apm = [];
        this.state.db.forEach((db) => {
            if (db.provinceCode === this.state.selected_province) {
                db.amphoe.forEach((amph, amph_i) => {
                    apm.push({ name: amph, code: db.amphoesCode[amph_i] });
                })
            }
        });
        // if (this.state.selected_amphoe.length > 0) {
        //     apm = apm.filter((_apm) => {
        //         let count = 0;
        //         this.state.selected_amphoe.forEach((selected_amphoe) => {
        //             if (_apm.code === selected_amphoe.code) {
        //                 count = 1;
        //             }
        //         })
        //         return count === 0
        //     })
        // }
        apm.sort()
        return apm;
    }
    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) { return; }
                        this.$submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        // console.log(data)
                        this.props.onSearch(data)
                        this.$modal.modal('hide')
                    }}
                    style={{ position: 'relative' }}
                >
                    {
                        this.state.is_loading ?
                            <div className="absolute_loading">
                                <div className="ui active inline loader small" ></div>
                            </div>
                            : null
                    }
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                ค้นหาจากพื้นที่ให้บริการ
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>จังหวัดที่ต้องการค้นหา</label>
                                <select className="form-control"
                                    name={"province"}
                                    value={this.state.selected_province}
                                    onChange={(e) => {
                                        this.setState({
                                            selected_province: e.target.value,
                                            selected_amphoe: [],
                                        })
                                    }}
                                >
                                    {
                                        this.state.db.map((item, item_i) => (
                                            <option value={item.provinceCode} key={item_i}>{item.province}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label>พื้นที่เขต</label>
                                {
                                    !this.state.selected_province ?
                                        <input type={"test"} value={"เลือกจังหวัด"} disabled={true} className={"form-control"} />
                                        : null
                                }
                                {
                                    this.state.selected_province ?
                                        <select className="form-control"
                                            name={"amphoe"}
                                            onChange={(e) => {
                                                // if (e.target.value === "" || !e.target.value) { return; }
                                                this.setState({
                                                    selected_amphoe: e.target.value,
                                                })
                                            }}
                                            value={this.state.selected_amphoe}
                                        >
                                            <option value="">ทั้งหมด</option>
                                            {
                                                this.getAmphoe().map((amp, amp_i) => (
                                                    <option value={amp.code} key={amp_i}
                                                    >{amp.name}</option>
                                                ))
                                            }
                                        </select>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                            <button type="submit" className="btn btn-primary">ค้นหา</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}