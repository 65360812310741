import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../Helper';
import { db, getProviderUserByProderviderUserDataUid } from '../firebase';
// import Pagination from '../Components/Pagination';

export default class ViewRatingProviderUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            user: {},
            provider_id: props.provider_id,
        }
    }

    componentDidMount() {
        this.fetchUser();
    }
    async fetchUser() {
        const providerUser = await getProviderUserByProderviderUserDataUid(this.state.provider_id);
        let user = {};
        await db.doc("provider_users_data/" + this.state.provider_id).get().then(async (doc) => {
            user = { ...doc.data() };
            if (user.traningUID) {
                await db.doc("training_date/" + user.traningUID).get().then(async (doc) => {
                    user.educateDate = doc.data() && doc.data().date ? doc.data().date : new Date().getTime();
                })
            }
        })
        let _user = { ...user };
        _user.birthDay = Helper.getDateToEdit(user.birthDay);
        _user.educateDate = Helper.getDateToEdit(user.educateDate);
        _user.transferDate = Helper.getDateToEdit(user.transferDate);
        _user.provider_user = {};
        _user.mailbox = [];
        let provider_user = await db.doc("provider_users_data/" + this.state.provider_id).get();
        if (provider_user.exists) {
            let user = await db.doc("provider_users/" + providerUser.id).get();
            if (user.exists) {
                _user.user = user.data();
            }
            let mailbox = await db.collection("provider_users/" + providerUser.id + "/mailbox").get();
            _user.mailbox = await Promise.all(
                mailbox.docs.map(async (element) => {
                    return { ...element.data(), id: element.id };
                })
            );
            let report = await db.collection("provider_users/" + providerUser.id + "/report").orderBy('createdAt', 'desc').get();
            _user.report = await Promise.all(
                report.docs.map(async (element) => {
                    let books = await (await db.doc('books/' + element.id).get()).data();
                    let user = await (await db.doc("users/" + element.data().userUid).get()).data();
                    return {
                        ...element.data(),
                        id: element.id,
                        user: user,
                        books: books
                    };
                })
            );
            let wallets = await db.collection("provider_users/" + providerUser.id + "/wallets").get();
            _user.wallets = await Promise.all(
                wallets.docs.map(async (element) => {
                    return { ...element.data(), id: element.id };
                })
            );
            _user.provider_user = { ...provider_user.data() };
        }
        let training_date = [];
        await db.collection("training_date").orderBy('date', 'asc').get().then(async (doc) => {
            let data = [];
            data = await Promise.all(
                doc.docs.map(async (element) => {
                    return { ...element.data(), date: new Date(element.data().date), id: element.id };
                })
            );
            training_date = data;
        })
        this.setState({
            training_date: training_date,
            user: _user,
            is_loading: false,
        }, () => {
            window.$.Thailand({
                $amphoe: this.amphoe, // input ของอำเภอ
                $province: this.province, // input ของจังหวัด
                templates: {
                    empty: ' ',
                    suggestion: function (data) {
                        console.log(data);
                        return '<div>' + data.amphoe + ' » ' + data.province + '</div>';
                    }
                }
            });
        })
    }


    render() {
        console.log(this.state.user)
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รีวิวแม่บ้าน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/provider-users">แม่บ้าน</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/provider-users/" + this.state.provider_id}>ข้อมูลแม่บ้าน</Link></li>
                                    <li className="breadcrumb-item active">รีวิวแม่บ้าน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.user ?
                                <>
                                    <div className={"row"}>
                                        <div className="col-lg-4">
                                            <div className="card">
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h3 className="card-title">
                                                        แม่บ้าน
                                                    </h3>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        ทั้งหมด {
                                                            this.state.user &&
                                                                this.state.user.report ?
                                                                this.state.user.report.length
                                                                : 0
                                                        } รีวิว
                                                    </div>
                                                </div>
                                                <div className="card-body" >
                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-6">
                                                            <img src={this.state.user.profileImage ? this.state.user.profileImage : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                        </div>
                                                        <div className="col-sm-12 col-lg-6">
                                                            <div className={"rating_ui right"}>
                                                                <span>
                                                                    {
                                                                        this.state.user
                                                                            && this.state.user.user
                                                                            && this.state.user.user.starFloat !== undefined
                                                                            ?
                                                                            this.state.user.user.starFloat.toFixed(2)
                                                                            : '5.00'
                                                                    }
                                                                </span>
                                                                <i className="fas fa-star"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {this.state.user.prefix} {this.state.user.firstName} {this.state.user.lastName}
                                                    </div>
                                                    <div className={"form-group"}>
                                                        {this.state.user.phoneNumber}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            {
                                                this.state.user &&
                                                    this.state.user.report ?
                                                    this.state.user.report.map((report, report_i) => (
                                                        <div className="card"
                                                            key={report_i}
                                                        >
                                                            <div className="card-header"
                                                                style={{ display: 'flex', flexDirection: 'row' }}
                                                            >
                                                                <h3 className="card-title">
                                                                    Booking No : {report ? report.bookOn : null} {report.createdAt ? <div className={"beeclean-block"}>{Helper.getDateThai(new Date(report.createdAt), { is_show_time: true })}</div> : null}
                                                                </h3>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {report.books && report.books.address && report.books.address.placeType && report.books.address.placeType.name_th ?
                                                                        report.books.address.placeType.name_th + ' '
                                                                        : null
                                                                    }
                                                                    {report.books && report.books.address && report.books.address.placeType && report.books.address.placeType.place_sub_type && report.books.address.placeType.place_sub_type.description_th ?
                                                                        report.books.address.placeType.place_sub_type.description_th + ' '
                                                                        : null
                                                                    }
                                                                    {report.books && report.books.address && report.books.bookTime && report.books.bookTime.name_th ?
                                                                        report.books.bookTime.name_th
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="card-body" >
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-lg-2">
                                                                        <img src={report.user && report.user.photoURL ? report.user.photoURL : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                                    </div>
                                                                    <div className="col-sm-12 col-lg-10">
                                                                        <div><label>ชื่อ : </label> {report.user && report.user.displayName ? report.user.displayName : '-'}</div>
                                                                        <div><label>เบอร์โทรศัพท์ : </label> {report.user && report.user.phoneNumber ? report.user.phoneNumber : "-"}</div>
                                                                        <div><label>คอมเม้น : </label> {report.report || "-"}</div>
                                                                        <div className={"row form-group"} style={{ alignItems: 'center' }}>
                                                                            <div className="col-sm-12 col-lg-2">
                                                                                <div className={"rating_ui"}>
                                                                                    <span>
                                                                                        {report.star ? report.star.toFixed(1) : "5.0"}
                                                                                    </span>
                                                                                    <i className="fas fa-star"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12 col-lg-10"
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    justifyContent: 'center'
                                                                                }}
                                                                            >
                                                                                <div className={"row"} >
                                                                                    <div className={"col-lg-3"}>
                                                                                        <div className={report.rateClean[0] ? "raing_icon check" : "raing_icon"}>
                                                                                            <div className={"rating_circle"}>
                                                                                                <img src={'/assets/images/clean.png'} alt={"Clean"} />
                                                                                            </div>
                                                                                            <div>
                                                                                                Clean & Comfy
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={"col-lg-3"}>
                                                                                        <div className={report.rateClean[1] ? "raing_icon check" : "raing_icon"}>
                                                                                            <div className={"rating_circle"}>
                                                                                                <img src={'/assets/images/women-icon.png'} alt={"Clean"} />
                                                                                            </div>
                                                                                            <div>
                                                                                                Good Personality
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={"col-lg-3"}>
                                                                                        <div className={report.rateClean[2] ? "raing_icon check" : "raing_icon"}>
                                                                                            <div className={"rating_circle"}>
                                                                                                <img src={'/assets/images/chat-icon.png'} alt={"Clean"} />
                                                                                            </div>
                                                                                            <div >
                                                                                                Good Communicate
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={"col-lg-3"}>
                                                                                        <div className={report.rateClean[3] ? "raing_icon check" : "raing_icon"}>
                                                                                            <div className={"rating_circle"}>
                                                                                                <img src={'/assets/images/award-icon.png'} alt={"Clean"} />
                                                                                            </div>
                                                                                            <div>
                                                                                                Excellent Service
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    : null
                                            }

                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                </section>
            </>
        );
    }
}