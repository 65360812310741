import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import CustomDateInput from './../Components/CustomDateInput';
import DatePicker from "react-datepicker";
// import { db } from '../firebase';
import Pagination from '../Components/Pagination';
const axios = require('axios').default;

const default_limit = 5;
export default class Promotion extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            all_items: 0,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            type: Helper.getParameterByName('type') ? Helper.getParameterByName('type') : '',
            market_user_id: Helper.getParameterByName('market_user_id') ? Helper.getParameterByName('market_user_id') : '',
            promotionCondition: Helper.getParameterByName('promotionCondition') ? Helper.getParameterByName('promotionCondition') : '',
            isAllTime: Helper.getParameterByName('isAllTime') ? Helper.getParameterByName('isAllTime') : '',
            isSetLimit: Helper.getParameterByName('isSetLimit') ? Helper.getParameterByName('isSetLimit') : '',
            is_show_next_button: false,
            users_count: 0,
            margin_users: [],
            push_data: [],
            date: Helper.getParameterByName('date') ? parseFloat(Helper.getParameterByName('date')) : false,
            start: Helper.getParameterByName('start') ? parseFloat(Helper.getParameterByName('start')) : false,
            end: Helper.getParameterByName('end') ? parseFloat(Helper.getParameterByName('end')) : false,
        }
    }
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                type: Helper.getParameterByName('type') ? Helper.getParameterByName('type') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
                market_user_id: Helper.getParameterByName('market_user_id') ? Helper.getParameterByName('market_user_id') : '',
                promotionCondition: Helper.getParameterByName('promotionCondition') ? Helper.getParameterByName('promotionCondition') : '',
                isAllTime: Helper.getParameterByName('isAllTime') ? Helper.getParameterByName('isAllTime') : '',
                isSetLimit: Helper.getParameterByName('isSetLimit') ? Helper.getParameterByName('isSetLimit') : '',
                date: Helper.getParameterByName('date') ? parseFloat(Helper.getParameterByName('date')) : false,
                start: Helper.getParameterByName('start') ? parseFloat(Helper.getParameterByName('start')) : false,
                end: Helper.getParameterByName('end') ? parseFloat(Helper.getParameterByName('end')) : false,
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: '',
                date: false
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        let data = {};
        data.page = this.state.page;
        data.limit = this.state.limit;
        if (this.state.name) {
            data.name = this.state.name
        }
        if (this.state.start && this.state.end) {
            let start = new Date(this.state.start);
            let getStartDay = start.getDate();
            let startDay = getStartDay < 10 ? `0${getStartDay}` : getStartDay;
            let getStartMonth = start.getMonth() + 1;
            let startMonth = getStartMonth < 10 ? `0${getStartMonth}` : getStartMonth;
            let startYear = start.getFullYear();
            data.start = `${startYear}-${startMonth}-${startDay}`;


            let end = new Date(this.state.end);
            let getEndDay = end.getDate();
            let endDay = getEndDay < 10 ? `0${getEndDay}` : getEndDay;
            let getEndMonth = end.getMonth() + 1;
            let endMonth = getEndMonth < 10 ? `0${getEndMonth}` : getEndMonth;
            let endYear = end.getFullYear();
            data.end = `${endYear}-${endMonth}-${endDay}`;
        }
        // let offset = this.state.limit * (this.state.page - 1);
        let res = await axios.get(window.api_host + '/voucherList?' + window.$.param(data));
        let count_all = 0;
        let count_item = 0;
        let _data = [];
        if (res.data.success) {
            _data = [...res.data.data];
            count_all = res.data.count_all;
            count_item = res.data.count_item;
        }
        this.setState({
            is_loading: false,
            data: _data,
            count_all: count_all,
            count_item: count_item,
        })
        // console.log(res);
    }

    async deleteVoucher(id, options) {
        let data = {
            id: id,
        }
        let res = await axios.delete(window.api_host + '/voucherList?' + window.$.param(data));
        if (res.data.success) {
            this.setState({
                is_loading: true
            }, () => {
                this.fetchData();
            })
        }
        if (!res.data.success) {
            alert("Error Something")
            options.onCancel();
        }
    }


    render() {
        let block_btn = this.state.is_loading ? true : false;
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Voucher</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/promotion">โปรโมชั่น</Link></li>
                                    <li className="breadcrumb-item active">Voucher</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายการโปรโมชั่น</h3>
                            </div>
                            <div className="card-body">
                                <div className="d-flex form-group " style={{ width: `100%` }}>
                                    <div className='d-flex'>
                                        <button className="btn btn-default"
                                            onClick={() => {
                                                Helper.reactToDom(window.$('body'),
                                                    <VoucherForm
                                                        mode="create"
                                                        onSuccess={() => {
                                                            this.setState({
                                                                is_loading: true,
                                                            }, () => {
                                                                this.fetchData()
                                                            })
                                                        }} />
                                                )
                                            }}
                                        >เพิ่ม Voucher</button>
                                        <div style={{ marginLeft: 10 }}>
                                            <DatePicker
                                                onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
                                                onChange={date => {
                                                    let _date = new Date(date)
                                                    _date.setHours(0, 0, 0);
                                                    this.setState({
                                                        start: _date.getTime(),
                                                    }, () => {
                                                        if (this.state.end) {
                                                            let data = Helper.getFormInputObject(this.$searchForm);
                                                            let url = Helper.getQueryStringLinkFromObject({
                                                                page: 1,
                                                                start: _date.getTime()
                                                            }, '/voucher')
                                                            navigate(url)
                                                        }
                                                    })
                                                    // this.setState({
                                                    //     day: _date.getDate(),
                                                    //     month: _date.getMonth(),
                                                    //     year: _date.getFullYear(),
                                                    //     date: _date.getTime(),
                                                    // }, () => {
                                                    //     this.searchName.val("")
                                                    //     this.$searchForm.find('[type="submit"]').trigger('click');
                                                    // })
                                                }}
                                                locale="th-TH"
                                                selected={this.state.start ? Helper.getDateToEdit(this.state.start) : false}
                                                startDate={this.state.start ? Helper.getDateToEdit(this.state.start) : false}
                                                endDate={this.state.end ? Helper.getDateToEdit(this.state.end) : false}
                                                placeholderText={"วัน / เดือน / ปี (สร้าง)"}
                                                name={"birthDay"}
                                                maxDate={this.state.end ? new Date(this.state.end) : false}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                // withPortal
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => {
                                                    let now = new Date();
                                                    let selected_date = new Date(date);
                                                    // months_th
                                                    const years = [];
                                                    let i;
                                                    for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                        years.push(i)
                                                    }
                                                    return (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className={"btn btn-primary "}
                                                                type={"button"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                            >
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <select
                                                                className={"form-control"}
                                                                value={selected_date.getFullYear()}
                                                                onChange={({ target: { value } }) => { console.log(value); changeYear(value) }}
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {years.map(option => (
                                                                    <option key={option} value={option} >
                                                                        {option + 543}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className={"form-control"}
                                                                value={window.months_th[selected_date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(window.months_th.indexOf(value))
                                                                }
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {window.months_th.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                className={"btn btn-primary"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                type={"button"}
                                                            >
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                    )
                                                }}
                                                customInput={<CustomDateInput size={"sm"} />}
                                            />
                                            <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />
                                            <input type="hidden" id="day" name="day" value={this.state.day} onChange={() => { console.log('change') }} readOnly={true} />
                                            <input type="hidden" id="month" name="month" value={this.state.month} onChange={() => { console.log('change') }} readOnly={true} />
                                            <input type="hidden" id="year" name="year" defaultValue={this.state.year} onChange={() => { console.log('change') }} readOnly={true} />
                                        </div>
                                        <div style={{ marginLeft: 10 }}>
                                            <DatePicker
                                                onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
                                                onChange={date => {
                                                    let _date = new Date(date)
                                                    _date.setHours(0, 0, 0);
                                                    this.setState({
                                                        end: _date.getTime(),
                                                    }, () => {
                                                        let data = Helper.getFormInputObject(this.$searchForm);
                                                        let url = Helper.getQueryStringLinkFromObject({
                                                            page: 1,
                                                            end: _date.getTime(),
                                                            start: this.state.start,
                                                        }, '/voucher')
                                                        navigate(url)
                                                    })
                                                    // this.setState({
                                                    //     day: _date.getDate(),
                                                    //     month: _date.getMonth(),
                                                    //     year: _date.getFullYear(),
                                                    //     date: _date.getTime(),
                                                    // }, () => {
                                                    //     this.searchName.val("")
                                                    //     this.$searchForm.find('[type="submit"]').trigger('click');
                                                    // })
                                                }}
                                                locale="th-TH"
                                                selected={this.state.end ? Helper.getDateToEdit(this.state.end) : false}
                                                startDate={this.state.start ? Helper.getDateToEdit(this.state.start) : false}
                                                endDate={this.state.end ? Helper.getDateToEdit(this.state.end) : false}
                                                placeholderText={"วัน / เดือน / ปี (สร้าง)"}
                                                name={"birthDay"}
                                                maxDate={new Date()}
                                                minDate={this.state.start ? new Date(this.state.start) : false}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                // withPortal
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => {
                                                    let now = new Date();
                                                    let selected_date = new Date(date);
                                                    // months_th
                                                    const years = [];
                                                    let i;
                                                    for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                        years.push(i)
                                                    }
                                                    return (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className={"btn btn-primary "}
                                                                type={"button"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                            >
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <select
                                                                className={"form-control"}
                                                                value={selected_date.getFullYear()}
                                                                onChange={({ target: { value } }) => { console.log(value); changeYear(value) }}
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {years.map(option => (
                                                                    <option key={option} value={option} >
                                                                        {option + 543}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className={"form-control"}
                                                                value={window.months_th[selected_date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(window.months_th.indexOf(value))
                                                                }
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {window.months_th.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                className={"btn btn-primary"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                type={"button"}
                                                            >
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                    )
                                                }}
                                                customInput={<CustomDateInput size={"sm"} />}
                                            />
                                            <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />
                                            <input type="hidden" id="day" name="day" value={this.state.day} onChange={() => { console.log('change') }} readOnly={true} />
                                            <input type="hidden" id="month" name="month" value={this.state.month} onChange={() => { console.log('change') }} readOnly={true} />
                                            <input type="hidden" id="year" name="year" defaultValue={this.state.year} onChange={() => { console.log('change') }} readOnly={true} />
                                        </div>
                                        {
                                            this.state.start || this.state.end ?
                                                <button className='btn btn-default ml-2'
                                                    onClick={() => {
                                                        let data = Helper.getFormInputObject(this.$searchForm);
                                                        let url = Helper.getQueryStringLinkFromObject({
                                                            page: 1,
                                                        }, '/voucher')
                                                        navigate(url)
                                                    }}
                                                >ล้างการค้นหา</button>
                                                : null
                                        }
                                    </div>
                                    <div className='ml-auto'>
                                        <form className="d-flex align-items-center"
                                            style={{ alignItems: 'center' }}
                                            ref={(ref) => { this.$searchForm = window.$(ref) }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                let data = Helper.getFormInputObject(this.$searchForm);
                                                let url = Helper.getQueryStringLinkFromObject({
                                                    page: 1,
                                                    ...data
                                                }, '/voucher')
                                                navigate(url)
                                            }}
                                        >
                                            <div className="input-group input-group-sm" style={{ marginTop: 0 }}>
                                                <input type="text"
                                                    name="name"
                                                    ref={(ref) => this.searchName = window.$(ref)}
                                                    className="form-control float-right"
                                                    placeholder="ค้นหา Voucher"
                                                    defaultValue={this.state.name}
                                                    style={{ minWidth: 200 }}
                                                    disabled={block_btn}
                                                />
                                                <div className="input-group-append">
                                                    <button type="submit"
                                                        className="btn btn-default"
                                                        disabled={block_btn}
                                                    >
                                                        <i className="fas fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* <button onClick={() => {
                                    this.createVoucher();
                                }}>Run</button> */}
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Voucher</th>
                                            <th>ส่วนลด</th>
                                            <th>จำนวนสิทธิ์ ใช้แล้ว/ทั้งหมด</th>
                                            <th style={{ width: 200 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                <div>
                                                                    {_data.name}
                                                                </div>
                                                                {
                                                                    _data.createdAt ?
                                                                        <div>
                                                                            <small><i className='far fa-calendar-check'></i> {Helper.getDateThai(_data.createdAt, { is_show_time: true })}</small>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {Helper.numberFormat(_data.discountValue)}
                                                                {_data.valueType === 'raw' ? ' บาท' : null}
                                                                {_data.valueType === 'percent' ? ' %' : null}
                                                            </td>
                                                            <td>
                                                                {Helper.numberFormat(_data.used)}/
                                                                {Helper.numberFormat(_data.amount)}
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <Link className="btn btn-default" to={"/promotion?voucherListCode=" + _data.uid}>ดูข้อมูล</Link>
                                                                    <button
                                                                        ref={(ref) => {
                                                                            window.$(ref).off().on('click', () => {
                                                                                if (window.$(ref).hasClass('loading')) { return; }
                                                                                window.$(ref).addClass('loading');
                                                                                Helper.confirmModal({
                                                                                    title: "ยืนยันการลบ Voucher",
                                                                                    description: 'หากทำการลบ Voucher แล้วจะไม่สามารถดึงข้อมูลกลับมาได้ ต้องการลบหรือไม่ ?',
                                                                                    submit_text: 'ลบ',
                                                                                    onConfirm: () => {
                                                                                        this.deleteVoucher(_data.uid, {
                                                                                            onCancel: () => {
                                                                                                window.$(ref).removeClass('loading')
                                                                                            }
                                                                                        });
                                                                                    },
                                                                                    onCancel: () => {
                                                                                        window.$(ref).removeClass('loading')
                                                                                    }
                                                                                })
                                                                            })
                                                                        }}
                                                                        className="btn btn-danger"
                                                                    >ลบ</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.count_all}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class VoucherForm extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    async create(vdata) {
        let data = {};
        data.name = vdata.name;
        const now = new Date();
        data.createdAt = now;
        data.createdAtTime = now.getTime();
        data.amount = JSON.parse(vdata.amount);
        data.status = 'active';
        data.keyword = vdata.keyword;
        data.discountValue = JSON.parse(vdata.discountValue);
        data.valueType = vdata.valueType;
        data.isAllTime = JSON.parse(vdata.isAllTime)
        if (!JSON.parse(vdata.isAllTime)) {
            data.EndAt = vdata.EndAt;
            data.startAt = vdata.startAt;
        }

        let res = await axios.post(`${window.api_host}/voucher`, { ...data });
        if (res.data.success) {
            this.$modal.modal('hide')
            this.props.onSuccess();
        }
        if (res.data.error) {
            Helper.messageTop({ message: ',เกิดข้อผิดพลาดกรุณาติดต่อผู้จัดทำระบบ' });
            this.$submit.removeClass('loading');
            this.$cancel.attr('disabled', false)
            return;
        }
        // console.log(res);
    }

    render() {
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard="false" data-backdrop="static"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) { return false; }
                        this.$submit.addClass('loading');
                        this.$cancel.attr('disabled', true)
                        let data = Helper.getFormInputObject(this.$form);
                        data.sameCode = true;
                        if (data.isAllTime) {
                            data.isAllTime = false;
                        } else {
                            data.isAllTime = true;
                            data.startAt = 0;
                            data.EndAt = 0;
                        }
                        this.create(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                เพิ่ม Voucher
                            </h4>
                        </div>
                        <div className="modal-body">
                            <>
                                <div className={"form-group"}>
                                    <label>ชื่อ Voucher</label>
                                    <input type="text"
                                        className="form-control"
                                        name={"name"}
                                        defaultValue={this.props.data && this.props.data.name ? this.props.data.name : ''}
                                        required={true}
                                    />
                                </div>
                                <div className={"form-group"}>
                                    <label>จำนวนรหัส
                                        <small style={{ color: 'red', marginLeft: 3 }}>สูงสุด 1000 รหัส</small>
                                    </label>
                                    <input type="number"
                                        className="form-control"
                                        name={"amount"}
                                        maxLength={1000}
                                        defaultValue={this.props.data && this.props.data.amount ? this.props.data.amount : ''}
                                        required={true}
                                    />
                                </div>
                                <div className={"form-group"}>
                                    <label>Voucher Keyword
                                        <small style={{ color: 'red', marginLeft: 3 }}>คำที่ใช้นำหน้าหัสโปรโมชั่น (<span style={{ color: 'blue' }}>TEST</span>XXXXX)</small>
                                    </label>
                                    <input type="text"
                                        className="form-control"
                                        name={"keyword"}
                                        defaultValue={this.props.data && this.props.data.keyword ? this.props.data.keyword : ''}
                                    />
                                </div>
                                <div className={"form-group"} style={this.props.mode === 'edit' ? { display: 'none' } : null}>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            ref={(ref) => { this.$checkboxDate = window.$(ref) }}
                                            id="isAllTime"
                                            name={"isAllTime"}
                                            defaultChecked={this.props.data && !this.props.data.isAllTime}
                                            defaultValue={"on"}
                                            onChange={() => {
                                                if (this.$checkboxDate.prop("checked")) {
                                                    window.$('#date_rage').show()
                                                } else {
                                                    window.$('#date_rage').hide()
                                                }
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="isAllTime">กำหนดเวลา</label>
                                    </div>
                                    <div id={"date_rage"} style={{ display: this.props.data && !this.props.data.isAllTime ? 'block' : 'none' }}>
                                        <div className={"row"}>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <label>วันที่เริ่มต้น</label>
                                                <DatePicker
                                                    onChange={date => {
                                                        date = new Date(date);
                                                        date = date.setHours(0, 0, 0, 0);
                                                        this.setState({
                                                            start_date: date
                                                        })
                                                    }}
                                                    selectsStart
                                                    startDate={this.state.start_date}
                                                    endDate={this.state.end_date}
                                                    locale="th-TH"
                                                    selected={this.state.start_date}
                                                    placeholderText={"วัน / เดือน / ปี"}
                                                    name={"birthDay"}
                                                    dateFormat="MMMM d, yyyy 00:00:00"
                                                    withPortal
                                                    onCalendarOpen={() => { Helper.renderDate() }}
                                                    renderCustomHeader={({
                                                        date,
                                                        changeYear,
                                                        changeMonth,
                                                        decreaseMonth,
                                                        increaseMonth,
                                                        prevMonthButtonDisabled,
                                                        nextMonthButtonDisabled
                                                    }) => {
                                                        date = new Date(date);
                                                        let now = new Date();
                                                        // months_th
                                                        const years = [];
                                                        let i;
                                                        for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                            years.push(i)
                                                        }
                                                        return (
                                                            <div
                                                                style={{
                                                                    margin: 10,
                                                                    display: "flex",
                                                                    justifyContent: "center"
                                                                }}
                                                            >
                                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                    className={"btn btn-primary "}
                                                                    type={"button"}
                                                                    style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                >
                                                                    <i className="fas fa-chevron-left" />
                                                                </button>
                                                                <select
                                                                    className={"form-control"}
                                                                    value={date.getFullYear()}
                                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                                    style={{ borderRadius: 0 }}
                                                                >
                                                                    {years.map(option => (
                                                                        <option key={option} value={option} >
                                                                            {option + 543}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                <select
                                                                    className={"form-control"}
                                                                    value={window.months_th[date.getMonth()]}
                                                                    onChange={({ target: { value } }) =>
                                                                        changeMonth(window.months_th.indexOf(value))
                                                                    }
                                                                    style={{ borderRadius: 0 }}
                                                                >
                                                                    {window.months_th.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                    className={"btn btn-primary"}
                                                                    style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                    type={"button"}
                                                                >
                                                                    <i className="fas fa-chevron-right" />
                                                                </button>
                                                            </div>
                                                        )
                                                    }}
                                                    customInput={<CustomDateInput />}
                                                />
                                                <input type="hidden" id="start_date" name="startAt" value={new Date(this.state.start_date).getTime()} onChange={() => { console.log('change') }} readOnly={true} />
                                            </div>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <label>วันที่สิ้นสุด</label>
                                                <DatePicker
                                                    onChange={date => {
                                                        date = new Date(date);
                                                        date = date.setHours(23, 59, 0, 0);
                                                        this.setState({
                                                            end_date: date
                                                        })
                                                    }}
                                                    selectsEnd
                                                    startDate={this.state.start_date}
                                                    endDate={this.state.end_date}
                                                    locale="th-TH"
                                                    selected={this.state.end_date}
                                                    placeholderText={"วัน / เดือน / ปี"}
                                                    name={"birthDay"}
                                                    dateFormat="MMMM d, yyyy 23:59:00"
                                                    withPortal
                                                    onCalendarOpen={() => { Helper.renderDate() }}
                                                    renderCustomHeader={({
                                                        date,
                                                        changeYear,
                                                        changeMonth,
                                                        decreaseMonth,
                                                        increaseMonth,
                                                        prevMonthButtonDisabled,
                                                        nextMonthButtonDisabled
                                                    }) => {
                                                        date = new Date(date);
                                                        let now = new Date();
                                                        // months_th
                                                        const years = [];
                                                        let i;
                                                        for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                            years.push(i)
                                                        }
                                                        return (
                                                            <div
                                                                style={{
                                                                    margin: 10,
                                                                    display: "flex",
                                                                    justifyContent: "center"
                                                                }}
                                                            >
                                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                    className={"btn btn-primary "}
                                                                    type={"button"}
                                                                    style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                >
                                                                    <i className="fas fa-chevron-left" />
                                                                </button>
                                                                <select
                                                                    className={"form-control"}
                                                                    value={date.getFullYear()}
                                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                                    style={{ borderRadius: 0 }}
                                                                >
                                                                    {years.map(option => (
                                                                        <option key={option} value={option} >
                                                                            {option + 543}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                <select
                                                                    className={"form-control"}
                                                                    value={window.months_th[date.getMonth()]}
                                                                    onChange={({ target: { value } }) =>
                                                                        changeMonth(window.months_th.indexOf(value))
                                                                    }
                                                                    style={{ borderRadius: 0 }}
                                                                >
                                                                    {window.months_th.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                    className={"btn btn-primary"}
                                                                    style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                    type={"button"}
                                                                >
                                                                    <i className="fas fa-chevron-right" />
                                                                </button>
                                                            </div>
                                                        )
                                                    }}
                                                    customInput={<CustomDateInput />}
                                                />
                                                <input type="hidden" id="end_date" name="EndAt" value={new Date(this.state.end_date).getTime()} onChange={() => { console.log('change') }} readOnly={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"form-group"}>
                                    <label>ส่วนลด</label>
                                    <div className="input-group">
                                        <input type="text"
                                            className="form-control"
                                            name={"discountValue"}
                                            defaultValue={this.props.data && this.props.data.value ? this.props.data.value : '0'}
                                        />
                                        <div className="input-group-append">
                                            <select className="form-control"
                                                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                name={"valueType"}
                                                defaultValue={this.props.data && this.props.data.valueType ? this.props.data.valueType : 'raw'}
                                                required={true}
                                            >
                                                <option value={"raw"}>฿</option>
                                                <option value={"percent"}>%</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>
                        <div className="modal-footer">
                            <button
                                ref={(ref) => {
                                    this.$cancel = window.$(ref);
                                }}
                                type="button"
                                className="btn btn-default"
                                onClick={() => {
                                    if (this.$submit.hasClass('loading')) { return; };
                                    this.$modal.modal('hide');
                                }}
                            >
                                ยกเลิก
                            </button>
                            {
                                this.props.mode === 'create' || this.props.mode === 'edit' ?
                                    <button className={"btn btn-primary"} type={"submit"}>บันทึก</button>
                                    : null
                            }
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}