import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../../Helper';
import DatePicker from 'react-datepicker';
import CustomDateInput from '../../Components/CustomDateInput';
import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import BankCode from '../../bankCode';
import { db } from '../../firebase';
import '@progress/kendo-theme-default/dist/all.css';
const axios = require('axios').default;
const prepareReportData = (defaultData) => {
    let data = { ...defaultData }
    data.serviceAt = Helper.getDateNumber(defaultData.bookedAt)
    data.serviceTime = Helper.getTime(defaultData.bookedAt);
    data.providerDisplayname = data.providerUser && data.providerUser.displayName ? data.providerUser.displayName : null;
    data.providerUser.bankAccountNo = defaultData.providerUser?.bankAccountNo ? defaultData.providerUser.bankAccountNo.replaceAll('-', "") : ''
    data.providerUser.personalNo = defaultData.providerUser?.personalNo ? defaultData.providerUser.personalNo.replaceAll(' ', "") : ''
    data.bookUid = defaultData.id;
    data.providerUserUid = defaultData.providerUserUid;
    return data
}
let abort
const defaultParams = () => {
    let defaultStatus = 'finish';
    let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
    let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
    let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : false;
    if (!_sortField) {
        if (type == 'all') {
            if (booking_type && booking_type == 'admin_created') {
                defaultStatus = 'all'
            }
        }
        if (type == 'user') {
            defaultStatus = 'all'
        }

    }
    let params = {
        isClearDevAccount: Helper.getParameterByName('isClearDevAccount') && Helper.getParameterByName('isClearDevAccount') == `false` ? false : true,
        render_start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
        render_end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
        start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
        end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
        booking_type: Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : `all`,
        status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : defaultStatus,
        finishedFilter: Helper.getParameterByName('finishedFilter') ? Helper.getParameterByName('finishedFilter') : 'all'
    }
    return params;
}
export default class BookingReport extends React.Component {
    constructor() {
        super();
        let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
        let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
        let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : false;
        let sortField = _sortField || "bookedAt";
        if (!_sortField) {
            if (type == 'all') {
                if (booking_type && booking_type == 'admin_created') {
                    sortField = 'createdAt';
                }
            }
            if (type == 'user') {
                sortField = 'createdAt';
            }

        }
        let sort_data = {
            sortField: sortField,
            // sortField: "bookedAt",
            sortDir: 'desc',
            sort: [{ field: 'bookedAt', dir: 'desc' }]
        };
        // if (type === 'all') {
        //     sort_data = {
        //         sortField: 'createdAt',
        //         sortDir: 'desc',
        //         sort: [{ field: 'createdAt', dir: 'desc' }]
        //     }
        // }
        this.state = {
            data: [],
            items_response: [],
            transfer_data: false,
            revenue_data: false,
            is_loading: true,
            type: type,
            columnSort: 'providerName',
            ...defaultParams(),
            ...sort_data
        }
    }

    componentDidUpdate(prevProps) {
        let defaultStatus = 'done';
        let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
        let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
        let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : false;
        let sortField = _sortField || "bookedAt";
        if (!_sortField) {
            if (
                (type == 'all' && (booking_type && booking_type == 'admin_created'))
                || type == 'user'
            ) {
                sortField = 'createdAt';
                defaultStatus = 'all'
            }

        }
        let sort_data = {
            sortField: sortField,
            // sortField: "bookedAt",
            sortDir: 'desc',
            sort: [{ field: 'bookedAt', dir: 'desc' }]
        };
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                data: [],
                items_response: [],
                transfer_data: false,
                revenue_data: false,
                is_loading: true,
                ...defaultParams(),
                ...sort_data
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                data: [],
                items_response: [],
                transfer_data: false,
                revenue_data: false,
                is_loading: true,
                ...defaultParams(),
                ...sort_data
            }, () => {
                this.fetchData();
            })
        }
    }


    componentDidMount() {
        this.fetchData();
    }


    async fetchData() {
        let qs = { promotionId: this.props.promotion_id }
        // try {
        let promotion = await (await db.doc("promotions/" + this.props.promotion_id).get()).data();
        let res = await axios.get(`${window.api_host}/booking-promotion-report?promotionId=${this.props.promotion_id}`);
        // console.log(res);
        let _data = [];
        let count = 0;
        let provider_all = 0;
        let repeated_count = 0;
        let count_plusTime = 0;
        if (res.data.success) {
            for (const data of res.data.data) {
                if (this.state.isClearDevAccount && data.providerUser.devAccount) {
                    continue;
                }
                let prepare = {};
                let summary = Helper.bookingSummary({ booking: data, payment: data.payment });
                prepare.firstBook = data.isFirstBook ? "ใช้งานครั้งแรก" : null;
                prepare.price = (parseFloat(data.price))
                prepare.plustime_total_price = parseFloat(0);
                data.bookedAtDate = Helper.getDateNumber(data.bookedAt);
                data.bookedAtDateText = Helper.getDateNumber(data.bookedAt, { is_show_time: false });
                data.bookedAtDateTime = Helper.getTime(data.bookedAt);
                prepare.bookedAtDateTime = Helper.getTime(data.bookedAt);
                // prepare.priceApp = (parseFloat(data.priceApp) - parseFloat(summary.tax_total_price));
                prepare.priceApp = !data.changeBookPrice && data.priceAppWithoutAgent ? parseFloat(data.priceAppWithoutAgent) : data.priceApp ? parseFloat(data.priceApp) : 0;
                prepare.priceDiscount = parseFloat(data.priceDiscount) > parseFloat(data.price) ? parseFloat(data.price) : parseFloat(data.priceDiscount);
                prepare.totalPriceProvider = parseFloat(data.priceProvider) > 0 ? parseFloat(data.priceProvider) : (350);
                prepare.chargePrice = parseFloat(data.chargePrice) <= 0 ? 0 : parseFloat(data.chargePrice);
                if (prepare.chargePrice < 0) {
                    prepare.chargePrice = 0;
                }
                prepare.priceAgentCommissionFromProvider = parseFloat(data.priceAgentCommissionFromProvider) || 0;
                prepare.priceAgentCommissionFromApp = parseFloat(data.priceAgentCommissionFromApp) || 0;
                prepare.priceProvider = !data.changeBookPrice && parseFloat(data.priceProviderWithoutAgent) ? parseFloat(data.priceProviderWithoutAgent) : parseFloat(data.priceProvider) > 0 ? parseFloat(data.priceProvider) : (350);
                prepare.totalUserPrice = ((parseFloat(data.price) + parseFloat(prepare.chargePrice)) - parseFloat(prepare.priceDiscount)).toFixed(2)
                prepare.priceUserAfterDiscount = (parseFloat(prepare.price) - parseFloat(prepare.priceDiscount));
                let work_time = parseFloat(data.bookTime.time);
                let _plusTime = 0;
                let priceProviderPlusTime = 0;
                prepare.priceProviderPlusTime = priceProviderPlusTime;
                prepare.plusTime = _plusTime;
                prepare.bookTime = work_time;
                prepare.totalPriceApp = ((parseFloat(prepare.priceApp) + parseFloat(prepare.chargePrice)) - prepare.priceAgentCommissionFromApp).toFixed(2);
                prepare.providerUser = data.providerUser;
                if (data.providerUser && data.providerUser.bank) {
                    let getBank = BankCode.filter((e) => e.name.indexOf(data.providerUser.bank) != -1)[0];
                    if (getBank) {
                        data.providerUser.bankCode = getBank.code;
                    }
                    prepare.providerUser.bankAccountNo = data.providerUser.bankAccountNo.replaceAll('-', '').replaceAll(' ', '').trim()
                }
                if (data.providerUser) {
                    prepare.providerUser.displayName = data.providerUser.firstName ? `${data.providerUser.firstName} ${data.providerUser.lastName}` : data.providerUser.displayName

                }
                prepare.providerDisplayname = prepare.providerUser?.displayName || null;
                prepare.paymentMethod = ""
                if (data.payment) {
                    if (data.payment.paymentBy == 'บัตรเครดิต') {
                        prepare.paymentMethod = "บัตรเครดิต";
                    }
                }
                if (data.type && data.type == 'wegate') {
                    if (data.paymentStatus == 'success') {
                        prepare.payment = {
                            statusText: 'สำเร็จ',

                        }
                    }
                    let channel = data.channel;
                    prepare.payment = { ...prepare.payment }
                    if (channel == 'PP') {
                        prepare.payment.paymentBy = "Ksher (PromptPay)";
                        prepare.paymentMethod = "Ksher (PromptPay)";
                    }
                    if (channel == 'TM') {
                        prepare.payment.paymentBy = "Ksher (True Money)";
                        prepare.paymentMethod = "Ksher (True Money)";
                    }
                    if (channel == 'AL') {
                        prepare.payment.paymentBy = "Ksher (Alipay)";
                        prepare.paymentMethod = "Ksher (Alipay)";
                    }
                    if (channel == 'SP') {
                        prepare.payment.paymentBy = "Ksher (ShopeePay)";
                        prepare.paymentMethod = "Ksher (ShopeePay)";
                    }
                    if (channel == 'LP') {
                        prepare.payment.paymentBy = "Ksher (LINE Pay)";
                        prepare.paymentMethod = "Ksher (LINE Pay)";
                    }
                    if (channel == 'WC') {
                        prepare.payment.paymentBy = "Ksher (WeChat Pay)";
                        prepare.paymentMethod = "Ksher (WeChat Pay)";
                    }
                }
                prepare.bookPaymentInfo = "แอพพลิเคชั่น";
                prepare.totalUserPrice = parseFloat(prepare.totalUserPrice)
                prepare.totalPriceApp = parseFloat(prepare.totalPriceApp)
                if (!data.user_data) {
                    prepare.user_data = {
                        displayName: data.user.displayName,
                        phoneNumber: data.user.guestPhoneNumber,
                    }
                }
                if (data.createdAdmin) {
                    prepare.paymentMethod = "โอนเงิน";
                    prepare.bookPaymentInfo = "ระบบหลังบ้าน";
                    prepare.payment = {
                        statusText: 'สำเร็จ',
                        paymentBy: 'โอนเงิน'
                    }
                }


                prepare.bookActionType = `ปกติ (${prepare.bookPaymentInfo})`;
                prepare.actionDate = Helper.getDateNumber(data.createdAt);
                prepare.actionDateSort = new Date(data.createdAt);
                prepare.bookAt = Helper.getDateNumber(data.createdAt);


                prepare.rentOutOfAppPrice = 0;
                // Urgent calculate
                prepare.priceUrgent = parseFloat(data.priceUrgent) || 0;
                prepare.priceUrgentApp = data.priceUrgentApp && (data.bookStatus == 'done' || data.bookStatus == 'finish') ? parseFloat(data.priceUrgentApp) : 0;
                prepare.priceUrgentProvider = data.priceUrgentProvider && (data.bookStatus == 'done' || data.bookStatus == 'finish') ? parseFloat(data.priceUrgentProvider) : 0;
                prepare.priceUrgentVat = data.priceUrgentVat && (data.bookStatus == 'done' || data.bookStatus == 'finish') ? parseFloat(data.priceUrgentVat) : 0;
                prepare.priceUrgentVatUser = data.priceUrgentVat ? parseFloat(data.priceUrgentVat) : 0;
                prepare.priceUrgentSummaryApp = prepare.priceUrgentApp + prepare.priceUrgentVat;
                prepare.totalPriceUrgent = prepare.priceUrgent + prepare.priceUrgentVat;
                prepare.totalPriceUrgentProvider = prepare.priceUrgentProvider;
                prepare.totalPriceUrgentApp = prepare.priceUrgentApp + prepare.priceUrgentVat;

                prepare.totalPriceApp += prepare.totalPriceUrgentApp
                prepare.totalPriceApp += prepare.adjustVat

                // prepare.transferPrice = (parseFloat(prepare.totalPriceProvider) - parseFloat(prepare.deductions)) + parseFloat(prepare.specialPrice);

                prepare.totalPriceProvider = parseFloat(prepare.totalPriceProvider) + parseFloat(prepare.totalPriceUrgentProvider)

                prepare.transferPrice = (((parseFloat(data.totalPriceProvider) - parseFloat(prepare.deductions))) + parseFloat(prepare.specialPrice)) + parseFloat(prepare.totalPriceUrgentProvider);
                // สูตรรวมที่ต้องโอนของแม่บ้าน ((แม่บ้านได้รับ - คอมมิชชั่น) - หักเงิน + เงินพิเศษ) + เร่งด่วน
                prepare.transferPriceRevenue = prepare.transferPrice;

                let isCanPush = true;
                if (isCanPush) {

                    _data.push({
                        ...data,
                        ...prepare
                    })


                }

            }
            count = res.data.count_all;
            provider_all = res.data.provider_all;
            let adjustRef = await axios.get(`${window.api_host}/book-user-adjust-report?${window.$.param(qs)}`);
            if (adjustRef.data.success) {
                for (const adj of adjustRef.data.items) {
                    if (this.state.isClearDevAccount && adj.providerUser.devAccount) {
                        continue;
                    }
                    let adjPrepare = { ...adj }
                    adjPrepare.firstBook = adj.isFirstBook ? "ใช้งานครั้งแรก" : null;
                    adjPrepare.actionDateSort = new Date(adj.adjustUpdateAt);
                    adjPrepare.actionDate = Helper.getDateNumber(adj.adjustUpdateAt);
                    adjPrepare.bookedAtDateText = Helper.getDateNumber(adj.adjustUpdateAt, { is_show_time: false });
                    adjPrepare.bookedAtDateTime = Helper.getTime(adj.adjustUpdateAt, { is_show_time: false });
                    let isCanPush = true
                    if (isCanPush) {
                        _data.push({
                            ...adjPrepare
                        })
                    }
                }
            }
            let plustimeRef = await axios.get(`${window.api_host}/book-user-plus-time-report?${window.$.param(qs)}`);
            if (plustimeRef.data.success) {
                for (const item of plustimeRef.data.items) {
                    if (this.state.isClearDevAccount && item.providerUser.devAccount) {
                        continue;
                    }
                    let plustimePrepare = { ...item }
                    plustimePrepare.firstBook = item.isFirstBook ? "ใช้งานครั้งแรก" : null;
                    plustimePrepare.actionDateSort = new Date(item.plusTimeUpdateAt);
                    plustimePrepare.actionDate = Helper.getDateNumber(item.plusTimeUpdateAt);
                    plustimePrepare.bookedAtDateText = Helper.getDateNumber(item.plusTimeUpdateAt, { is_show_time: false });
                    plustimePrepare.bookedAtDateTime = Helper.getTime(item.plusTimeUpdateAt, { is_show_time: false });
                    let isCanPush = true
                    if (isCanPush) {
                        _data.push({
                            ...plustimePrepare
                        })
                    }
                }
            }
            let urgentRef = await axios.get(`${window.api_host}/book-user-urgent-report?${window.$.param(qs)}`);
            if (urgentRef.data.success) {
                for (const item of urgentRef.data.items) {
                    if (this.state.isClearDevAccount && item.providerUser.devAccount) {
                        continue;
                    }
                    let urgentPrepare = { ...item }
                    urgentPrepare.firstBook = item.isFirstBook ? "ใช้งานครั้งแรก" : null;
                    urgentPrepare.actionDateSort = new Date(item.urgentUpdateAt);
                    urgentPrepare.actionDate = Helper.getDateNumber(item.urgentUpdateAt);
                    urgentPrepare.bookedAtDateText = Helper.getDateNumber(item.urgentUpdateAt, { is_show_time: false });
                    urgentPrepare.bookedAtDateTime = Helper.getTime(item.urgentUpdateAt, { is_show_time: false });
                    let isCanPush = true

                    if (isCanPush) {
                        _data.push({
                            ...urgentPrepare
                        })
                    }
                }
            }
        }



        _data = _data.sort(function (a, b) {
            if (a.user_data.displayName < b.user_data.displayName) {
                return -1;
            }
            if (a.user_data.displayName > b.user_data.displayName) {
                return 1;
            }
            return 0;
        });
        _data = _data.sort(function (a, b) {
            if (a.actionDate < b.actionDate) {
                return 1;
            }
            if (a.actionDate > b.actionDate) {
                return -1;
            }
            return 0;
        });

        this.setState({
            data: _data,
            is_loading: false,
            all_items: count,
            provider_all: provider_all,
            promotion: promotion,
        }, () => {
            if (repeated_count > 0) {
                window.$(document).Toasts('create', {
                    title: 'เกิดข้อผิดพลาด',
                    body: "มีข้อมูลซ้ำกรุณาตรวจสอบอีกครั้ง",
                    icon: 'fas fa-exclamation',
                    autohide: true,
                    delay: 3000,
                    class: "bg-warning",
                })
            }
        })
        // } catch (error) {
        //     window.$(document).Toasts('create', {
        //         title: 'เกิดข้อผิดพลาด',
        //         body: "ไม่สามารถดึงข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ",
        //         icon: 'fas fa-times',
        //         autohide: true,
        //         delay: 3000,
        //         class: "bg-danger",
        //     })
        //     this.setState({
        //         data: [],
        //         is_loading: false,
        //         all_items: 0,
        //         provider_all: 0,
        //     })
        // }

    }

    render() {
        let page_title = ""
        let fileName = 'รายงานโปรโมชั่น';
        let page_sub_title = "";
        page_sub_title += ` โปรโมชั่น`;
        if (this.state.promotion) {
            let pro = ` ${this.state.promotion ? this.state.promotion.name : null}  ${this.state.promotion && this.state.promotion.nameEN ? `/ ` + this.state.promotion.nameEN : null}`;
            page_sub_title += pro;
            fileName += pro;
        }

        // let _response = ;
        // console.log(_response);
        // console.log(this.state.data);
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายงาน Booking โปรโมชั่น</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    {
                                        this.state.promotion ?
                                            <li className="breadcrumb-item"><Link to={`/promotion/${this.props.promotion_id}`}>โปรโมชั่น {this.state.promotion?.name || null}</Link></li>
                                            : null
                                    }
                                    <li className="breadcrumb-item active">
                                        รายงานโปรโมชั่น
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " >
                                <h3 className="card-title" style={{ display: 'flex', alignItems: 'center' }}>รายการ Booking ของ {page_sub_title}</h3>
                                <div className='card-tools'>
                                    <button
                                        title="Export Excel"
                                        className={`btn btn-default ${this.state.is_loading ? "loading" : ""}`}
                                        onClick={async () => {
                                            if (this.state.is_loading) return;
                                            this._export.save()
                                        }}
                                        disabled={this.state.is_loading}
                                    >
                                        โหลดรายงาน
                                    </button>
                                </div>
                            </div>

                            <div className="card-body">

                                {
                                    this.state.is_loading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }

                                {
                                    !this.state.is_loading ?
                                        <>
                                            <ExcelExport
                                                fileName={fileName}
                                                data={this.state.data}
                                                ref={exporter => this._export = exporter}
                                            >
                                                <Grid data={this.state.data} style={{ height: '650px' }}>
                                                    {/* <GridColumn field="dateFinished" title="เวลาส่งงาน" width="150" /> */}
                                                    <GridColumn field="actionDate" title="วันที่ทำรายการ" width="150" />
                                                    <GridColumn field="bookActionType" title="ประเภท" width="150" />
                                                    <GridColumn field="bookOn" title="เลข Booking" width="150" />
                                                    <GridColumn
                                                        field="user_data.displayName"
                                                        title="สมาชิก"
                                                        width="200"
                                                    />
                                                    <GridColumn
                                                        field="user_data.phoneNumber"
                                                        title="เบอร์โทรศัพท์"
                                                        width="150"
                                                    />

                                                    <GridColumn field="bookTime" title="เวลาที่ให้บริการ" width="120" />
                                                    <GridColumn
                                                        field="providerUser.displayName"
                                                        title="แม่บ้าน"
                                                        width="200"
                                                    />
                                                    <GridColumn
                                                        field="providerUser.phoneNumber"
                                                        title="เบอร์โทรศัพท์"
                                                        width="150"
                                                    />
                                                    {/* <GridColumn field="bookedAtDateText" title="วันที่ให้บริการ" width="230" /> */}
                                                    <GridColumn field="bookedAtDateText" title="วันที่ให้บริการ" width="230" />
                                                    <GridColumn field="bookedAtDateTime" title="เวลาให้บริการ" width="230" />
                                                    <GridColumn
                                                        field="address.name"
                                                        title="สถานที่บริการ"
                                                        width="250"
                                                    />
                                                    <GridColumn
                                                        field="address.placeType.name_th"
                                                        title="ประเภทสถานที่"
                                                        width="150"
                                                    />
                                                    <GridColumn
                                                        field="address.placeType.place_sub_type.description_th"
                                                        title="พืนที่"
                                                        width="150"
                                                    />
                                                    <GridColumn
                                                        field="bookTime"
                                                        title="กี่ชั่วโมง"
                                                        width="60"
                                                    />
                                                    <GridColumn field="payment.statusText" title="ชำระเงิน" width="100" />
                                                    <GridColumn field="payment.paymentBy" title="ช่องทาง" width="100" />
                                                    <GridColumn field="payment.card.brand" title="ประเภทบัตร" width="120" />
                                                    <GridColumn field="payment.card.last_digits" title="ลงท้าย" width="100" />
                                                    <GridColumn field="price" title="ค่าบริการเรียกเก็บจาก สมาชิก" width="200" />
                                                    <GridColumn field="promotion.voucherCode" title="รหัสส่วนลด" width="150" />
                                                    <GridColumn field="priceDiscount" title="ส่วนลดราคา" width="100" />
                                                    <GridColumn field="priceUserAfterDiscount" title="ค่าบริการ" width="200" />
                                                    <GridColumn field="priceUserPlus" title="อื่นๆ" width="100" />
                                                    <GridColumn field="chargePrice" title="ภาษี 7%" width="100" />
                                                    <GridColumn field="totalUserPrice" title="รวม" width="100" />
                                                    <GridColumn
                                                        field="providerUser.displayName"
                                                        title="แม่บ้าน"
                                                        width="200"
                                                    />
                                                    <GridColumn field="starFromProvider" title="ความประทับใจต่อลูกค้า" width="200" />
                                                    <GridColumn field="user_report.report" title="รายงานจากลูกค้า" width="200" />
                                                    <GridColumn field="starFromUser" title="ความประทับใจต่อแม่บ้าน" width="200" />
                                                    <GridColumn field="provider_report.report" title="รายงานจากแม่บ้าน" width="200" />
                                                    <GridColumn field="bookStatusth"
                                                        title="สถานะ"
                                                        width="100"

                                                    />
                                                    <GridColumn field="firstBook"
                                                        title="หมายเหตุ"
                                                        width="100"

                                                    />
                                                </Grid>
                                            </ExcelExport>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
