import React, { Component } from 'react';
import { Helper } from '../../Helper';
import { storage } from '../../firebase';
import { bookService } from '../../Services/bookService';
const axios = require('axios').default;

export default class ChangeBookPriceForm extends Component {
    constructor() {
        super();
        this.state = {
            is_loading: false,
            priceProvider: 0,
            vatPrice: 0,
        }
    }

    async update(data) {
        //console.log(data);

        let res = await bookService.changeBookPrice(
            data.adminUid,
            data.bookUid,
            data.priceProvider,
        )
        if (res.code == 200) {
            window.$(document).Toasts('create', {
                title: 'ปรับค่าบริการที่ APP ได้รับสำเร็จ',
                // body: 'ทำการเพิ่มชั่วโมงเร่งด่วนเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.props.onSuccess();
            this.$modal.modal('hide');
        }
        if (res.error) {
            let error = res.error;
            let title = "ไม่สามารถทำรายการได้" // defult error title
            let description = "กรุณาติดต่อผู้จัดทำระบบ" // defult error description
            if (res.response) {
                description = res.response.data.code + " : " + res.response.data.message;
            }
            // error ตาม response ที่ส่งมา เช็คเอง
            // if (error.response.data.code === -1) {
            //     title = "Error code -1";
            //     description = "Error code description -1";
            // }
            // if (error.response.data.code === -2) {
            //     title = "Error code -2";
            //     description = "Error code description -2";
            // }
            window.$(document).Toasts('create', {
                title: title,
                body: description,
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submitBtn.removeClass('loading');
            return;
        }
    }


    render() {
        let booking = this.props.booking || false;
        let bookingPrice = false;
        bookingPrice = booking.price - this.props.summary.total_discount;
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard="false" data-backdrop="static"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading') || this.state.is_loading) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);

                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                ปรับค่าบริการที่แม่บ้านได้รับ
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            <>
                                {
                                    bookingPrice ?
                                        <div className={"flex row"}>
                                            <div><label>ค่าบริการเรียกเก็บจาก สมาชิก (หลังหักส่วนลด)</label></div>
                                            <div style={{ marginLeft: 'auto' }}>
                                                {parseFloat(bookingPrice) > 0 ? Helper.numberFormat(bookingPrice, { decimal: 2 }) : 0} บาท
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className='mb-3'>
                                    <label>ค่าบริการที่แม่บ้านได้รับ</label>
                                    <input type="text"
                                        className="form-control"
                                        name='priceProvider'
                                        defaultValue={booking?.priceProvider || ''}
                                        onChange={(e) => {
                                            let value = 0;
                                            if (!isNaN(e.target.value)) {
                                                value = e.target.value;
                                            } else {
                                                value = this.state.priceProvider;
                                                window.$(`input[name="priceProvider"]`).val(value)
                                            }
                                            if (value > bookingPrice) {
                                                value = bookingPrice
                                                window.$(`input[name="priceProvider"]`).val(value)
                                            }
                                            this.state.priceProvider = value;
                                            let renderPriceNode = window.$('#Total');
                                            renderPriceNode.html(Helper.numberFormat(value, { decimal: 2 }))
                                        }}
                                    // max={bookingPrice}
                                    />
                                </div>
                                <div className={"salary-transfer-price"}>
                                    <div className={"title"}>ค่าบริการที่แม่บ้านได้รับ</div>
                                    <div className={"price"} id="Total" style={{ color: 'green' }}>{booking.priceProvider ? Helper.numberFormat(booking.priceProvider, { decimal: 2 }) : 0}</div>
                                </div>
                                {/* <hr />
                                <div className={"form-group"}>
                                    <label>หมายเหตุ</label>
                                    <textarea name={"noteUrgent"} className={"form-control"} defaultValue={urgent.noteUrgent || ''}></textarea>
                                </div> */}
                                <input type="hidden" name={"bookUid"} defaultValue={this.props.bookUid} />
                                <input type="hidden" name={"adminUid"} defaultValue={this.props.adminUid} />
                            </>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal" disabled={this.state.is_loading}>ยกเลิก</button>
                            <button type="submit" className="btn btn-primary"
                                disabled={this.state.is_loading}
                                ref={(ref) => { this.$submitBtn = window.$(ref) }}
                            >
                                บันทึก
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
