import React from 'react';
import { db } from './../firebase';
import { Link, navigate } from '@reach/router';
import CustomDateInput from './../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import { Helper } from './../Helper';
import InputMask from 'react-input-mask';
const axios = require('axios').default;
export default class EditProviderUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: false,
            is_loading: true,
            provider_id: props.provider_id,
            selected_province: '',
            selected_amphoe: [],
            db: []
        }
    }

    componentDidMount() {
        this.fetchUser();
    }
    async fetchUser() {
        let user = {};
        await db.doc("provider_users_data/" + this.state.provider_id).get().then((doc) => {
            user = { ...doc.data() };
        })
        let _user = { ...user };
        _user.birthDay = Helper.getDateToEdit(user.birthDay);
        _user.educateDate = Helper.getDateToEdit(user.educateDate);
        _user.transferDateTime = Helper.getDateToEdit(user.transferDateTime);
        // let province = [];
        // await axios.post(window.api_host + '/provinces').then(async (res) => {
        //     let _data = res.data.data;
        //     console.log(_data);
        // })
        // console.log(province);

        this.setState({
            user: _user,
            is_loading: false,
            selected_province: _user.province,
            selected_amphoe: _user.amphoe,
            // db: province,
        }, () => {
            // window.$.Thailand({
            //     // database: './assets/js/database/db.json', 
            //     $amphoe: this.amphoe, // input ของอำเภอ
            //     $province: this.province, // input ของจังหวัด
            //     templates: {
            //       empty: ' ',
            //       suggestion: (data) =>  {
            //         return '<div>' + data.d + '</div>';
            //       }
            //     },
            // });
        })
    }

    getAmphoe() {
        let apm = [];
        this.state.db.forEach((db) => {
            if (db.province === this.state.selected_province) {
                db.amphoe.forEach((amph) => {
                    apm.push(amph);
                })
            }
        });
        if (this.state.selected_amphoe.length > 0) {
            apm = apm.filter((_apm) => {
                let count = 0;
                this.state.selected_amphoe.forEach((selected_amphoe) => {
                    if (_apm === selected_amphoe) {
                        count = 1;
                    }
                })
                return count === 0
            })
        }
        return apm;
    }

    async update(data) {
        data.phoneNumber = '+66' + data.phoneNumber.substr(1, 9);
        let match_phonenumber = await db.collection('provider_users_data').where('phoneNumber', '==', data.phoneNumber).get();
        if (match_phonenumber.docs.length > 0 && match_phonenumber.docs[0].data().phoneNumber !== data.phoneNumber) {
            window.$(document).Toasts('create', {
                title: 'เบอร์โทรสัพท์ซ้ำ',
                body: 'มีผู้ใช้งานเบอร์โทรศัพท์นี้แล้ว',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$form.find('button[type="submit"]').removeClass('loading');
            return;
        }
        data.emergencyContactNumber = '+66' + data.emergencyContactNumber.substr(1, 9);
        // data.amphoe = this.state.selected_amphoe;
        if (data.phoneNumber !== this.state.user.phoneNumber) {
            axios.post(window.api_host + '/changePhoneNumber', {
                phoneNumber: this.state.user.phoneNumber.replace('+66', '66'),
                toNewPhoneNumber: data.phoneNumber.replace('+66', '66'),
                changeForWhat: 'ineedtochange',
            }).then(async (res) => {
                if (res.data.code === 200) {
                    this.updateData(data);
                }
                if (res.data.code === -1) {
                    window.$(document).Toasts('create', {
                        title: 'เบอร์โทรสัพท์ซ้ำ',
                        body: 'มีผู้ใช้งานเบอร์โทรศัพท์นี้แล้ว',
                        icon: 'fas fa-times',
                        autohide: true,
                        delay: 3000,
                        class: "bg-danger",
                    })
                    this.$form.find('button[type="submit"]').removeClass('loading');
                    return;
                }
                if (res.data.code === -10) {
                    window.$(document).Toasts('create', {
                        title: 'ไม่สามารถเปลี่ยนเบอร์ได้',
                        body: 'ไม่สามารถเปลี่ยนเป็นเบอร์ '+data.phoneNumber.replace('+66','0')+' นี้ได้',
                        icon: 'fas fa-times',
                        autohide: true,
                        delay: 3000,
                        class: "bg-danger",
                    })
                    this.$form.find('button[type="submit"]').removeClass('loading');
                    return;
                }
            }).catch(error => {
                this.$form.find('button[type="submit"]').removeClass('loading');
            });
        } else {
            this.updateData(data);
        }

    }
    async updateData(data) {
        // console.log(data);
        let update_data = {...data}
        delete update_data.phoneNumber;
        await db.doc("provider_users_data/" + this.state.provider_id).update({ ...update_data }).then(() => {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            navigate('/provider-users/' + this.state.provider_id)
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">แก้ไขข้อมูลแม่บ้าน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/provider-users">แม่บ้าน</Link></li>
                                    <li className="breadcrumb-item active">แก้ไข</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form className={"content"}
                    ref={(ref) => this.$form = window.$(ref)}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        let $submit = this.$form.find('button[type="submit"]');
                        if ($submit.hasClass('loading')) { return false; }
                        $submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        console.log(data);
                        this.update(data);
                    }}
                >
                    <div className="container-fluid" >
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.user ?
                                <>
                                    <div className={"row"}>
                                        <div className="col-lg-4">

                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">ข้อมูลแม่บ้าน</h3>
                                                </div>
                                                <div className="card-body" >
                                                    <div className="form-group">
                                                        <label htmlFor="personalNo">เลขบัตรประชาชน</label>
                                                        <InputMask
                                                            mask="9 9999 99999 99 9"
                                                            placeholder={"เลขบัตรประชาชน"}
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                let personalNo = e.target.value;
                                                                this.$personalNo.val(personalNo)
                                                            }}
                                                            defaultValue={this.state.user.personalNo}
                                                        />
                                                        <input name={"personalNo"} type={"hidden"} ref={(ref) => { this.$personalNo = window.$(ref) }} defaultValue={this.state.user.personalNo} />
                                                        {/* <input type="text" className="form-control" id="personalNo" name="personalNo" defaultValue={this.state.user.personalNo} /> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>คำนำหน้านาม</label>
                                                        <select className="form-control placeholder_font" name="prefix" defaultValue={this.state.user.prefix}>
                                                            {/* <option value="นาย" >นาย</option> */}
                                                            <option value="นาง">นาง</option>
                                                            <option value="นางสาว">นางสาว</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="firstName">ชื่อ</label>
                                                        <input type="text" className="form-control" id="firstName" name="firstName" defaultValue={this.state.user.firstName} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="lastName">นามสกุล</label>
                                                        <input type="text" className="form-control" id="lastName" name="lastName" defaultValue={this.state.user.lastName} />
                                                        <input type={"hidden"} name="gender" value={"หญิง"} />
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label htmlFor="gender">เพศ</label>
                                                        <select className="form-control placeholder_font" name="gender" defaultValue={this.state.user.gender}>
                                                            <option value="ชาย">ชาย</option>
                                                            <option value="หญิง">หญิง</option>
                                                        </select>
                                                    </div> */}
                                                    <div className="form-group">
                                                        <label htmlFor="birthDay">วันเดือนปีเกิด</label>
                                                        <DatePicker
                                                            onChange={date => {
                                                                let user = { ...this.state.user };
                                                                user.birthDay = date
                                                                this.setState({
                                                                    user: user
                                                                })
                                                            }}
                                                            locale="th-TH"
                                                            selected={this.state.user.birthDay}
                                                            placeholderText={"วัน / เดือน / ปี"}
                                                            name={"birthDay"}
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            withPortal
                                                            onCalendarOpen={() => { Helper.renderDate() }}
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled
                                                            }) => {
                                                                let now = new Date();
                                                                // months_th
                                                                let selected_data = new Date(date);
                                                                const years = [];
                                                                let i;
                                                                for (i = 1960; i <= now.getFullYear(); i++) {
                                                                    years.push(i)
                                                                }
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            margin: 10,
                                                                            display: "flex",
                                                                            justifyContent: "center"
                                                                        }}
                                                                    >
                                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                            className={"btn btn-primary "}
                                                                            type={"button"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                        >
                                                                            <i className="fas fa-chevron-left" />
                                                                        </button>
                                                                        <select
                                                                            className={"form-control"}
                                                                            defaultValue={new Date(this.state.user.birthDay).getFullYear()}
                                                                            value={selected_data.getFullYear()}
                                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {years.map(option => (
                                                                                <option key={option} value={option} >
                                                                                    {option + 543}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <select
                                                                            className={"form-control"}
                                                                            defaultValue={window.months_th[date.getMonth()]}
                                                                            onChange={({ target: { value } }) =>
                                                                                changeMonth(window.months_th.indexOf(value))
                                                                            }
                                                                            value={window.months_th[selected_data.getMonth()]}
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {window.months_th.map(option => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                            className={"btn btn-primary"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                            type={"button"}
                                                                        >
                                                                            <i className="fas fa-chevron-right" />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }}
                                                            customInput={<CustomDateInput />}
                                                        />
                                                        <input type="hidden" className="form-control" id="birthDay" name="birthDay" value={new Date(this.state.user.birthDay).getTime()} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">ข้อมูลที่อยู่</h3>
                                                </div>
                                                <div className="card-body" >
                                                    <div className="form-group">
                                                        <label htmlFor="currentOccupation">อาชีพ</label>
                                                        <input type="text" className="form-control" id="currentOccupation" name="currentOccupation" defaultValue={this.state.user.currentOccupation} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="currentWorkplace">สถานที่ทำงาน</label>
                                                        <input type="text" className="form-control" id="currentWorkplace" name="currentWorkplace" defaultValue={this.state.user.currentWorkplace} />
                                                    </div>
                                                    <div className="form-group">
                                                        <div>
                                                            <label>โรคประจำตัว</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="iscongenitalDisease"
                                                                id="iscongenitalDisease1"
                                                                value="ไม่มี"
                                                                defaultChecked={this.state.user.iscongenitalDisease === 'ไม่มี'}
                                                                onChange={(e) => {
                                                                    this.congenitalDisease.hide();
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="iscongenitalDisease1">ไม่มี</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="iscongenitalDisease"
                                                                id="iscongenitalDisease2"
                                                                value="มี"
                                                                defaultChecked={this.state.user.iscongenitalDisease === 'มี'}
                                                                onChange={(e) => {
                                                                    this.congenitalDisease.show();
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="iscongenitalDisease2">มี</label>
                                                        </div>
                                                        <div className="form-group"
                                                            ref={(ref) => { this.congenitalDisease = window.$(ref) }}
                                                            style={{ display: this.state.user.iscongenitalDisease === 'ไม่มี' ? 'none' : 'block' }}>
                                                            <input type="text" className="form-control" placeholder="ระบุโรคประจำตัว" name="congenitalDisease" defaultValue={this.state.user.congenitalDisease} />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">ข้อมูลติดต่อ</h3>
                                                </div>
                                                <div className="card-body" >
                                                    <div className="form-group">
                                                        <label htmlFor="phoneNumber">เบอร์โทรศัพท์</label>
                                                        <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" defaultValue={this.state.user.phoneNumber.replace('+66', '0')} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="lineID">Line ID</label>
                                                        <input type="text" className="form-control" id="lineID" name="lineID" defaultValue={this.state.user.lineID} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="emergencyContactNumber">เบอร์ติดต่อฉุกเฉิน</label>
                                                        <input type="text" className="form-control" id="emergencyContactNumber" name="emergencyContactNumber" defaultValue={this.state.user.emergencyContactNumber.replace('+66', '0')} />
                                                    </div>
                                                    <div className={"form-group"}>
                                                        <label>สถานะ</label>
                                                        <select className="form-control placeholder_font" name="status" defaultValue={this.state.user.status}>
                                                            <option value="waitForApprove">รอการยืนยัน</option>
                                                            <option value="approved">ยืนยันแล้ว</option>
                                                            <option value="avaliable">ใช้งานได้ </option>
                                                            <option value="ban">ระงับการใช้งาน</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex row m-0" >
                                                <div style={{ marginLeft: 'auto' }}>
                                                    <Link to={"/provider-users/" + this.state.provider_id} className={"btn btn-default"}>ยกเลิก</Link>
                                                    <button type="submit" className={"btn btn-primary"} style={{ marginLeft: '1rem' }}>บันทึก</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </div>


                </form>
            </>
        );
    }
}