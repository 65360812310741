import httpClient, { serverUrl } from './HttpClient';
const _serverUrl = serverUrl;

const baseUrl = `${window.api_host}`;

export const bookService = {
    // get: async (agentUid, timeRange, month, year) => {
    //     const url = `${baseUrl}/salary?agentUid=${agentUid}&timeRange=${timeRange}&month=${month}&year=${year}`;
    //     try {
    //         const res = await httpClient.get(url)
    //         return res.data;
    //     } catch (error) {
    //         return { error: true, ...error };
    //     }
    // },
    urgent: async (adminUid, bookUid, noteUrgent, priceUrgent, priceUrgentApp, priceUrgentProvider, urgentImage) => {
        const url = `${window.api_host}/booking-urgent`;
        try {
            const res = await httpClient.post(url, { adminUid, bookUid, noteUrgent, priceUrgent, priceUrgentApp, priceUrgentProvider, urgentImage })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    changeBookPrice: async (adminUid, bookUid, priceProvider, note) => {
        const url = `${window.api_host}/booking-change-book-price`;
        try {
            const res = await httpClient.post(url, { adminUid, bookUid, priceProvider, note })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
}