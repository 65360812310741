import React, { Component } from 'react'
import { Helper } from '../../Helper';
import { providerService } from '../../Services/providerService';

export default class AvailableProviderChecking extends Component {

    async getProviderWillBan() {
        let res = await providerService.checkAvailable(); // เรียกข้อมูล แม่บ้านที่จะทำการแบน
        if (!res.error) {
            let countBan = res.countBan || 0;
            Helper.confirmModal({
                title: 'ยืนยันการระบใช้งานแม่บ้านที่ออนไลน์',
                description: `มีจำนวนแม่บ้านทั้งหมด ${countBan} คน, ที่จะทำการระงับการใช้งาน`,
                onConfirm: () => {
                    this.confirmBanProvider();
                },
                onCancel: () => {
                    this.$button.removeClass('loading');
                }
            })
        }
    }

    async confirmBanProvider() {
        let res = await providerService.confirmAvailable();
        if (!res.error) {
            window.$(document).Toasts('create', {
                title: 'สำเร็จ',
                body: 'ทำการระบใช้งานแม่บ้านที่ไม่ได้ใช้งานเกิน 6 เดือนแล้ว',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.props.onSuccess();
        } else {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: 'กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$button.removeClass('loading');
        }
    }
    render() {
        return (
            <>
                <button
                    ref={(ref) => {
                        this.$button = window.$(ref);
                        this.$button.off().on('click', () => {
                            if (this.$button.hasClass('loading')) { return; }
                            this.$button.addClass('loading');
                            this.getProviderWillBan();
                        })
                    }}
                    className="btn btn-default"
                    onClick={() => {

                    }}
                >ระงับการใช้งานแม่บ้านที่ไม่ออนไลน์</button>
            </>
        )
    }
}
