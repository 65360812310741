import React from "react";
// import { Link } from "@reach/router";
// import { db, signInWithGoogle } from "../firebase";
// import { auth } from "../firebase";
import { Helper } from "../Helper";
const axios = require('axios').default;


const SignIn = () => {

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [error, setError] = useState(null);

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    let SubmitBtn = $submitBtn;
    SubmitBtn.addClass('loading');
    SubmitBtn.attr('disabled', true);
    $form.find('input').attr('disabled', true);
    event.preventDefault();

    axios.post(`${window.api_host}/user-login`, {
      email, password
    }).then(res => {
        console.log(res.data)
        if (res.data.code === 200) {
          let __date = res.data.user;
          delete __date.email;
          delete __date.password;
          // window.localStorage.setItem('user', JSON.stringify(__date));
          Helper.setCookie('user', JSON.stringify(__date),1)
          window.location = '/';
          Helper.messageTop({ message: 'เข้าสู่ระบบสำเร็จ' ,type_class: 'alert-success'});
        } else {
          Helper.messageTop({ message: 'อีเมล์หรือรหัสผ่านไม่ถูกต้อง'});
          SubmitBtn.removeClass('loading');
          SubmitBtn.attr('disabled', false);
          $form.find('input').attr('disabled', false);
        }
      }).catch(error => {
        console.log(error);
        Helper.messageTop({ message: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'});
        SubmitBtn.removeClass('loading');
        SubmitBtn.attr('disabled', false);
        $form.find('input').attr('disabled', false);
      });

  };


  let $submitBtn;
  let $form;
  return (
    <div className={"hold-transition login-page"}>
      <div className="login-box" style={{marginTop: '-15rem'}}>
        <div className="login-logo">
        <img src="assets/images/logo.png" alt="Beeclean Logo" style={{maxHeight: 150}}/>
      </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">กรอกอีเมลและรหัสผ่านเพื่อเข้าสู่ระบบ</p>

            <form ref={(ref) => $form = window.$(ref)}
              onSubmit={(e) => {
                e.preventDefault();
                let data = {};
                $form.serializeArray().map((item) => {
                  data[item.name] = item.value;
                  return true
                })
                signInWithEmailAndPasswordHandler(e, data.email, data.password)
              }}
            >
              <div className="input-group mb-3">
                <input type="email" className="form-control" placeholder="อีเมล" name="email" />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input type="password" className="form-control" placeholder="รหัสผ่าน" name="password" />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <button type="submit"
                ref={(ref) => { $submitBtn = window.$(ref); }}
                className="btn btn-primary btn-block"
              >เข้าสู่ระบบ</button>
            </form>

            {/* <div className="social-auth-links text-center mb-3">
              <p>- OR -</p>
              <a href="#" className="btn btn-block btn-primary">
              <i className="fab fa-facebook mr-2"></i> Sign in using Facebook
        </a>
              <button
                ref={(ref) => { $googleBtn = window.$(ref); }}
                className="btn btn-block btn-danger"
                onClick={() => {
                  if ($googleBtn.hasClass('loading')) { return; }
                  $googleBtn.addClass("loading");
                  signInWithGoogle();
                }}
              >
                <i className="fab fa-google-plus mr-2"></i> Sign in using Google+
            </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
};

export default SignIn;
