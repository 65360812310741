import httpClient, { serverUrl } from './HttpClient';
const _serverUrl = serverUrl;

export const providerService = {
    checkAvailable: async (csvData, salaryUid) => {
        const url = `${window.api_host}/provider-check-available`;
        try {
            const res = await httpClient.get(url)
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    confirmAvailable: async () => {
        const url = `${window.api_host}/provider-confirm-available`;
        try {
            const res = await httpClient.post(url, { confirm: "confirmAvailable" })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
}

