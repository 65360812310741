import React from 'react';
import { Link, navigate } from '@reach/router';
import { db } from '../firebase';
import { Helper } from './../Helper';
import CustomDateInput from './../Components/CustomDateInput';
import AddProviderSalaryImage from './../Components/AddProviderSalaryImage';
import DatePicker from "react-datepicker";
import { storage } from './../firebase';
import Slider from "react-slick";
import ProviderPaymentCard from '../Components/ProviderPaymentCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const thailandJson = require('../thailand.json');
const axios = require('axios').default;
export default class ViewUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            booking: false,
            booking_id: props.booking_id,
            is_payment_loading: true,
            is_loading_time_plus: true,
        }
    }

    async componentDidMount() {
        this.fetch();
    }
    async fetch() {
        let qs = {};
        qs.bookingID = this.state.booking_id;
        let res = await axios.get(`${window.api_host}/booking-detail?${window.$.param(qs)}`);
        // console.log(res)
        let _booking = false;
        let payment = false;
        if (res.data.success) {
            _booking = res.data.booking;
            payment = res.data.payment;
        }
        this.setState({
            booking: _booking,
            payment: payment,
            is_loading: false,
            is_loading_time_plus: false,
        })
    }
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };
        let summary = Helper.bookingSummary({ booking: this.state.booking, payment: this.state.payment });
        // console.log(summary)
        let plustime_price = summary.plustime_price;
        let plustime_total_price = summary.plustime_total_price;
        let plustime_app_price = summary.plustime_app_price;
        let plustime_provider_price = summary.plustime_provider_price;
        let hours = summary.hours;
        let total_price = summary.total_price;
        let wait_count = 0;
        let total_discount = summary.total_discount;
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">พิมพ์ข้อมูล Booking {this.state.booking ? "#" + this.state.booking.bookOn : null}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/booking">Booking</Link></li>
                                    {
                                        this.props.booking_id ?
                                            <li className="breadcrumb-item"><Link to={"/booking/" + this.props.booking_id}>ข้อมูล Booking</Link></li>
                                            : null
                                    }
                                    <li className="breadcrumb-item active">พิมพ์ข้อมูล Booking</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.booking ?
                                <>
                                    <div className={"row"}>
                                        <div className='col-12 print-hide mb-4'>

                                            <button
                                                className='btn btn-primary'
                                                onClick={() => {
                                                    window.print()
                                                }}
                                            >พิมพ์รายละเอียดการจอง</button>
                                        </div>
                                        <div className={"col-6 col-md-6"}>
                                            <div className="card">
                                                <div className="card-header" style={{ alignItems: 'center', display: 'flex' }}>
                                                    <h4 className="card-title" id="myModalLabel">Booking No : {this.state.booking.bookOn} {this.state.booking.createdAdmin ? <div className="badge badge-warning">สร้างจากระบบหลังบ้าน</div> : null}</h4>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        this.state.booking.createdAdmin ?
                                                            <>
                                                                <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                                    <div><label>สร้างโดย</label> : {this.state.booking.createdAdmin.displayName}</div>
                                                                </div>
                                                                <div className="divider" />
                                                            </>
                                                            : null
                                                    }
                                                    <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                        <div><label>สถานะ</label> : {' '}
                                                            {
                                                                this.state.booking.bookStatus === 'accept' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "blue" }}></i>  ยอมรับ</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'arrive' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "purple" }}></i>  ถึงแล้ว</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'done' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "lightgreen" }}></i>  เสร็จสิ้น</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'finish' && parseFloat(this.state.booking.starFromProvider) === 0 && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "green" }}></i>  เสร็จงาน</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'finish' && parseFloat(this.state.booking.starFromProvider) > 0 && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <>
                                                                        <i className={"fas fa-circle"} style={{ color: "darkgreen" }}></i>  ส่งงาน
                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'going' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "orange" }}></i>  กำลังเดินทาง</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'wait' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "yellow" }}></i>  รอ</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'working' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "darkblue" }}></i>  กำลังทำงาน</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'cancel' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "red" }}></i>  ยกเลิก
                                                                        {this.state.booking.cancelRemark ? <div style={{ marginBottom: '0.5rem' }}>{this.state.booking.cancelRemark}</div> : null}
                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.paymentStatus === 'refund' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "red" }}></i>  คืนเงิน</>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.booking.updateAdmin ?
                                                            <div className={"flex row"}>
                                                                <div>
                                                                    <label>แก้ไขล่าสุด</label> : {' '} {this.state.booking.updateAdmin.displayName}
                                                                </div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {Helper.getDateThai(this.state.booking.updateAdmin.updatedAt, { is_show_time: true })}
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.booking.cancelAdmin ?
                                                            <div className={"flex row"}>
                                                                <div>
                                                                    <label>ผู้ยกเลิก</label> : {' '} {this.state.booking.cancelAdmin.displayName}
                                                                </div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {Helper.getDateThai(this.state.booking.cancelAt, { is_show_time: true })}
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                    <div className={"divider"} />
                                                    <div className={"flex row"}>
                                                        <div><label>สมาชิก</label> : {this.state.booking.user.displayName} </div>
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            {this.state.booking.user && this.state.booking.user.phoneNumber ?
                                                                <><i className={"fas fa-phone"} /> {this.state.booking.user.phoneNumber.replace('+66', '0')}</>
                                                                : null
                                                            }
                                                            {this.state.booking.user && this.state.booking.user.guestPhoneNumber && !this.state.booking.user.phoneNumber ?
                                                                <><i className={"fas fa-phone"} /> {this.state.booking.user.guestPhoneNumber.replace('+66', '0')}</>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={"flex row"}>
                                                        <div><label>แม่บ้าน</label> :  {this.state.booking.providerUser.provideruserDataUID ?
                                                            this.state.booking.providerUser.displayName + ' '
                                                            : this.state.booking.providerUser.displayName ? this.state.booking.providerUser.displayName + ' ' : null}
                                                            {
                                                                !this.state.booking.providerUser.provideruserDataUID && this.state.booking.paymentStatus === 'success' ?
                                                                    <span className="badge badge-warning salary-custom-badge" style={{ marginRight: 5 }}>กำลังค้นหางาน</span>
                                                                    : null
                                                            }
                                                            {
                                                                !this.state.booking.providerUser.provideruserDataUID && this.state.booking.paymentStatus === 'wait' ?
                                                                    <span className="badge badge-info salary-custom-badge" style={{ marginRight: 5 }}>รอชำระเงิน</span>
                                                                    : null
                                                            }
                                                            {
                                                                !this.state.booking.providerUser.provideruserDataUID && this.state.booking.paymentStatus === 'fail' ?
                                                                    <span className="badge badge-danger salary-custom-badge" style={{ marginRight: 5 }}>ชำระเงินไม่สำเร็จ</span>
                                                                    : null
                                                            }
                                                        </div>
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            {this.state.booking.providerUser && this.state.booking.providerUser.phoneNumber ?
                                                                <>
                                                                    <i className={"fas fa-phone"} /> {this.state.booking.providerUser.phoneNumber.replace('+66', '0')}
                                                                </>
                                                                : null
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className={"divider"} />
                                                    <div>
                                                        <label>ระยะเวลาที่ให้บริการ</label> :  {' '}
                                                        {this.state.booking.address && this.state.booking.bookTime && this.state.booking.bookTime.name_th ?
                                                            this.state.booking.bookTime.name_th
                                                            : null
                                                        }
                                                    </div>
                                                    <div>
                                                        <label>วันเวลาที่จอง</label> :  {' '}
                                                        {
                                                            this.state.booking.createdAt ?
                                                                Helper.getDateThai(this.state.booking.createdAt, { is_show_time: true }) : null
                                                        }
                                                    </div>
                                                    <div>
                                                        <label>วันเวลาที่ให้บริการ</label> :  {' '}
                                                        {
                                                            this.state.booking.bookedAt ?
                                                                Helper.getDateThai(this.state.booking.bookedAt, { is_show_time: true }) : null
                                                        }

                                                    </div>
                                                    <div className={"divider"} />
                                                    <div>
                                                        {this.state.booking.address
                                                            && this.state.booking.address.name ?
                                                            <div><label>ชื่อสถานที่ : </label> {this.state.booking.address.name}</div>
                                                            : null
                                                        }
                                                        {this.state.booking.address
                                                            && this.state.booking.address.placeType
                                                            && this.state.booking.address.placeType.name_th ?
                                                            <div><label>ประเภท : </label> {this.state.booking.address.placeType.name_th}</div>
                                                            : null
                                                        }
                                                        {this.state.booking.address
                                                            && this.state.booking.address.placeType
                                                            && this.state.booking.address.placeType.place_sub_type
                                                            && this.state.booking.address.placeType.place_sub_type.description_th ?
                                                            <div><label>พืนที่ : </label> {this.state.booking.address.placeType.place_sub_type.description_th}</div>
                                                            : null
                                                        }

                                                        {this.state.booking.address
                                                            && this.state.booking.address.province
                                                            && this.state.booking.address.amphoe ?
                                                            <div>{this.state.booking.address.addressDetail}
                                                                {this.state.booking.address.province == '10' ? " เขต" : " อำเภอ"}
                                                                {thailandJson[this.state.booking.address.province].amphoes[this.state.booking.address.amphoe].name.th}
                                                                {" จังหวัด"}{thailandJson[this.state.booking.address.province].name.th} {" "} {this.state.booking.address.note}
                                                                {/* {this.state.booking.address.address} */}
                                                            </div>
                                                            : this.state.booking.address && this.state.booking.address.address ? this.state.booking.address.address
                                                                : null
                                                        }
                                                        <div className={"divider mt-2"} />
                                                        <div className='mt-1'>
                                                            <label>หมายเหตุ : </label> {this.state.booking.note ? this.state.booking.note : '-'}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ProviderPaymentCard payment={this.state.payment} booking={this.state.booking} disableButton={true} />
                                        </div>
                                        <div className={"col-6 col-md-6"}>
                                            {
                                                this.state.payment
                                                    && (this.state.booking.paymentStatus === "successful"
                                                        || this.state.booking.paymentStatus === "success"
                                                        || this.state.booking.paymentStatus === "finish") ?
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title" id="myModalLabel">สรุปผลค่าบริการ</h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <div>
                                                                <div className={"flex row"}>
                                                                    <div><label>ค่าบริการเรียกเก็บจาก สมาชิก</label></div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {parseFloat(plustime_total_price) > 0 ? Helper.numberFormat(plustime_total_price, { decimal: 2 }) : 0} บาท
                                                                    </div>
                                                                </div>
                                                                <div className={"flex row"}>
                                                                    <div><label>ค่าบริการที่แม่บ้านได้รับ</label></div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {parseFloat(plustime_provider_price) > 0 ? Helper.numberFormat(plustime_provider_price, { decimal: 2 }) : 0} บาท
                                                                    </div>
                                                                </div>
                                                                <div className={"flex row"}>
                                                                    <div><label>ค่าบริการที่ APP ได้รับ</label></div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {parseFloat(plustime_app_price) > 0 ? Helper.numberFormat(plustime_app_price, { decimal: 2 }) : 0} บาท
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title" id="myModalLabel">ค่าบริการ</h4>
                                                </div>
                                                <div className="card-body">
                                                    <div>
                                                        <div className={"flex row"}>
                                                            <div><label>ค่าบริการเรียกเก็บจาก สมาชิก</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {parseFloat(this.state.booking.price) > 0 ? Helper.numberFormat(this.state.booking.price, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ส่วนลด</label> {this.state.booking.promotion ? <>({this.state.booking.promotion.voucherCode})</> : null}</div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                -{parseFloat(total_discount) > 0 ? Helper.numberFormat(total_discount, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ค่าบริการหลังหักส่วนลด</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {parseFloat(this.state.booking.price) - parseFloat(this.state.booking.priceDiscount) > 0 ? Helper.numberFormat((this.state.booking.price - this.state.booking.priceDiscount), { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>อื่นๆ</label>
                                                                {
                                                                    this.state.booking.isHavePet || this.state.booking.isUseBeeClean ?
                                                                        <> ({this.state.booking.isHavePet ? "มีสัตว์เลี้ยง" : null}
                                                                            {this.state.booking.isHavePet && this.state.booking.isUseBeeClean ? "," : null}
                                                                            {this.state.booking.isUseBeeClean ? "ผลิตภัณฑ์จาก BeeClean" : null}
                                                                            )</>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(0, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ภาษี 7%</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {parseFloat(this.state.booking.chargePrice) > 0 ? Helper.numberFormat(this.state.booking.chargePrice, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>รวม</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {total_price > 0 ? Helper.numberFormat(total_price, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"divider"} />
                                                    <div>
                                                        <div className={"flex row"}>
                                                            <div><label>ค่าบริการที่แม่บ้านได้รับ</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(this.state.booking.priceProvider, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>อื่นๆ</label>
                                                                {
                                                                    this.state.booking.isHavePet || this.state.booking.isUseBeeClean ?
                                                                        <> ({this.state.booking.isHavePet ? "มีสัตว์เลี้ยง" : null}
                                                                            {this.state.booking.isHavePet && this.state.booking.isUseBeeClean ? "," : null}
                                                                            {this.state.booking.isUseBeeClean ? "ผลิตภัณฑ์จาก BeeClean" : null}
                                                                            )
                                                                        </>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(0, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>รวม</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat((this.state.booking.priceProvider + 0), { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"divider"} />
                                                    <div>
                                                        <div className={"flex row"}>
                                                            <div><label>ส่วนต่างที่ APP ได้รับ</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {((this.state.booking.price - this.state.booking.priceDiscount) - this.state.booking.priceProvider) > 0 ? Helper.numberFormat(((this.state.booking.price - this.state.booking.priceDiscount) - this.state.booking.priceProvider), { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>อื่นๆ</label>
                                                                {
                                                                    this.state.booking.isHavePet || this.state.booking.isUseBeeClean ?
                                                                        <> ({this.state.booking.isHavePet ? "มีสัตว์เลี้ยง" : null}
                                                                            {this.state.booking.isHavePet && this.state.booking.isUseBeeClean ? "," : null}
                                                                            {this.state.booking.isUseBeeClean ? "ผลิตภัณฑ์จาก BeeClean" : null}
                                                                            )
                                                                        </>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(0, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ภาษี 7% จากสมาชิก</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {this.state.booking.chargePrice > 0 ? Helper.numberFormat(this.state.booking.chargePrice, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>รวม</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {(((this.state.booking.price - this.state.booking.priceDiscount) - this.state.booking.priceProvider) + this.state.booking.chargePrice) > 0 ? Helper.numberFormat((((this.state.booking.price - this.state.booking.priceDiscount) - this.state.booking.priceProvider) + this.state.booking.chargePrice), { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && !this.state.booking ?
                                <>
                                    <h4>ไม่มีข้อมูลหรือถูกลบไปแล้วโปรดติดต่อผู้ดูแลระบบ</h4>
                                </>
                                : null
                        }
                    </div>
                </section>
            </>
        );
    }
}
