import React from 'react';
import { Link } from '@reach/router';
import { Helper } from './../Helper';
import Pagination from './../Components/Pagination';
import { db } from '../firebase';

export default class TrainingDayStatus extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : 'date',
            orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : 'desc',
            is_show_next_button: false,
        }
    }
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        let tranningDateStatusRef = db.collection("training_date_status");
        let tranningDateStatusData = await tranningDateStatusRef.get();
        let data = [];
        let prepare_data = [];
        let count = 0;
        let get_data = await Promise.all(
            tranningDateStatusData.docs.map(async (element) => {
                let provider_users = await db.collection('provider_users_data').where('tranningStatus', '==', element.id).get();
                let provider_users_data = await Promise.all(
                    provider_users.docs.map(async (doc) => {
                        return ({ ...doc.data(), id: doc.id })
                    })
                );
                return {
                    ...element.data(),
                    id: element.id,
                    provider_users_data: provider_users_data
                };

            })
        );

        get_data = get_data.filter((_data) => {
            return _data !== false
        })
        get_data.forEach((_data, _data_i) => {
            let match_search = 0;
            if (this.state.name && this.state.name !== "") {
                match_search = _data.name.indexOf(this.state.name);
                if (match_search !== -1) {
                    count++;
                    prepare_data.push({ ..._data });
                }
            } else {
                count++;
                prepare_data.push({ ..._data });
            }

        })
        prepare_data.forEach((__data, __data_i) => {
            if (__data_i < (this.state.limit * this.state.page)
                && __data_i > (((this.state.limit * this.state.page) - this.state.limit) - 1)
            ) {
                data.push(__data)
            }
        })
        this.setState({
            data: data,
            is_loading: false,
            all_items: count,
        })
    }

    delete(data) {
        let documentRef = db.doc(`training_date_status/` + data.id);
        documentRef.delete().then(() => {
            this.fetchData();
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
        });
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">สถานะการอบรม</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/educate-date">วันอบรม</Link></li>
                                    <li className="breadcrumb-item active">สถานะการอบรม</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">

                            <div className="card-header">
                                <h3 className="card-title">รายการสถานะการอบรม</h3>
                                <div className="card-tools">
                                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#addModal"
                                        onClick={() => {
                                            Helper.reactToDom(window.$('body'),
                                                <TrainingDayStatusForm
                                                    mode={"create"}
                                                    onSuccess={() => {
                                                        this.fetchData();
                                                    }}
                                                />
                                            )
                                        }}
                                    >
                                        เพิ่มสถานะอบรม
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                สถานะ
                                            </th>
                                            <th style={{ width: 200 }} >ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {_data.status_name}
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <button className={"btn btn-default"}
                                                                        onClick={() => {
                                                                            Helper.reactToDom(window.$('body'),
                                                                                <TrainingDayStatusProvider
                                                                                    data={_data}
                                                                                />
                                                                            )
                                                                        }}
                                                                    >
                                                                        ดูข้อมูล
                                                                    </button>
                                                                    <button
                                                                        className={"btn btn-default"}
                                                                        onClick={() => {
                                                                            Helper.reactToDom(window.$('body'),
                                                                                <TrainingDayStatusForm
                                                                                    onSuccess={() => {
                                                                                        this.fetchData();
                                                                                    }}
                                                                                    mode={"edit"}
                                                                                    data={_data}
                                                                                />
                                                                            )
                                                                        }}
                                                                    >
                                                                        แก้ไข
                                                                    </button>
                                                                    <button type="button"
                                                                        className="btn btn-danger"
                                                                        // disabled={_data.provider_users_data.length > 0}
                                                                        ref={(ref) => {
                                                                            window.$(ref).off().on('click', () => {
                                                                                if (_data.provider_users_data.length > 0) {
                                                                                    window.$(document).Toasts('create', {
                                                                                        title: 'ไม่สามารถลบได้',
                                                                                        body: 'เนื่องจากมีแม่บ้านใช้สถานะนี้อยู่',
                                                                                        icon: 'fas fa-ban',
                                                                                        autohide: true,
                                                                                        delay: 3000,
                                                                                        class: "bg-danger",
                                                                                    })
                                                                                    window.$(ref).removeClass('loading');
                                                                                    return;
                                                                                }
                                                                                if (window.$(ref).hasClass('loading')) { return; }
                                                                                window.$(ref).addClass('loading');
                                                                                Helper.confirmModal({
                                                                                    title: "ยืนยันลบรายการอบรม",
                                                                                    description: "ต้องการลบรายการอบรมหรือไม่ ?",
                                                                                    onConfirm: () => {
                                                                                        window.$(ref).removeClass('loading');
                                                                                        this.delete(_data);
                                                                                    },
                                                                                    onCancel: () => {
                                                                                        window.$(ref).removeClass('loading');
                                                                                    }
                                                                                });
                                                                            })
                                                                        }}
                                                                    >ลบ</button>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
class TrainingDayStatusProvider extends React.Component {
    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        data.createAt = new Date().getTime();
                        if (this.props.mode === 'create') {
                            this.add(data);
                        }
                        if (this.props.mode === 'edit') {
                            this.update(data);
                        }
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                รายการแม่บ้าน
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                            style={{ padding: 0 }}
                        >
                            <ul class="list-group list-group-flush">
                                {
                                    this.props.data.provider_users_data.map((provider_users_data) => {
                                        return (
                                            <li class="list-group-item">
                                                <div className={"flex row"}>
                                                    <div>{provider_users_data.prefix} {provider_users_data.firstName} {provider_users_data.lastName}</div>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <Link to={'/provider-users/' + provider_users_data.id}
                                                            onClick={() => {
                                                                this.$modal.modal('hide')
                                                            }}
                                                            className={"btn btn-default"}
                                                        >
                                                            ดูข้อมูลแม่บ้าน
                                                        </Link>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
class TrainingDayStatusForm extends React.Component {


    update(data) {
        db.doc('training_date_status/' + this.props.data.id).set({ ...data });
        window.$(document).Toasts('create', {
            title: 'แก้ไขสถานะสำเร็จ',
            body: 'ทำการแก้ไขสถานะเรียบร้อยแล้ว',
            icon: 'far fa-calendar-check',
            class: 'bg-success',
            autohide: true,
            delay: 3000
        })
        this.$submitBtn.removeClass('loading');
        this.$modal.modal('hide');
        this.props.onSuccess();
    }


    add(data) {
        db.collection('training_date_status').add(data).then(() => {
            this.setState({
                training_date: null
            }, () => {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มสถานะสำเร็จ',
                    body: 'ทำการเพิ่มสถานะเรียบร้อยแล้ว',
                    icon: 'far fa-calendar-check',
                    class: 'bg-success',
                    autohide: true,
                    delay: 3000
                })
                this.$submitBtn.removeClass('loading');
                this.$modal.modal('hide');
                this.props.onSuccess();
            })
        })
    }

    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        data.createAt = new Date().getTime();
                        if (this.props.mode === 'create') {
                            this.add(data);
                        }
                        if (this.props.mode === 'edit') {
                            this.update(data);
                        }
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                {
                                    this.props.mode === 'create' ?
                                        "สถานะใหม่" : "แก้ไขสถานะ"
                                }
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            <div className="form-group">
                                <input name={"status_name"}
                                    className={"form-control"}
                                    placeholder={"สถานะ"}
                                    required={true}
                                    defaultValue={this.props.data && this.props.data.status_name ? this.props.data.status_name : ''}
                                />
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                            <button type="submit" className="btn btn-primary"
                                ref={(ref) => { this.$submitBtn = window.$(ref) }}
                            >
                                {
                                    this.props.mode === "create" ?
                                        "เพิ่ม" : "แก้ไข"
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}