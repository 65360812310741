let bank = [
    { name: "ธนาคารกรุงเทพ", code: "002" },
    { name: "ธนาคารกสิกรไทย", code: "004" },
    { name: "ธนาคารกรุงศรีอยุธยา", code: "025" },
    { name: "ธนาคารกรุงไทย", code: "006" },
    { name: "ธนาคารทหารไทย", code: "011" },
    { name: "ธนาคารไทยพาณิชย์", code: "014" },
    { name: "ธนาคารเกียรตินาคินภัทร", code: "069" },
    { name: "ธนาคารเกียรตินาคิน", code: "069" },
    { name: "ธนาคารซีไอเอ็มบีไทย", code: "" },
    { name: "ธนาคารทิสโก้", code: "067" },
    { name: "ธนาคารธนชาต", code: "065" },
    { name: "ธนาคารยูโอบี", code: "024" },
    { name: "ธนาคารไทยเครดิตเพื่อรายย่อย", code: "071" },
    { name: "ธนาคารแลนด์แอนด์ เฮ้าส์", code: "073" },
    { name: "ธนาคารไอซีบีซี (ไทย)", code: "070" },
    { name: "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย", code: "098" },
    { name: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", code: "034" },
    { name: "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย", code: "035" },
    { name: "ธนาคารออมสิน", code: "030" },
    { name: "ธนาคารอาคารสงเคราะห์", code: "033" },
    { name: "ธนาคารอิสลามแห่งประเทศไทย", code: "066" },
]
export default bank;