import React, { Component } from 'react'
import { Link } from '@reach/router'
import { db } from '../../firebase';
import { Helper } from '../../Helper';
import { QRCode } from 'react-qrcode-logo';
// const logo = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAR1SURBVFhH7VdpKKZdGB5SdiLKkqHIGkKWGjUppfiB0kSNGIk/QnZZItl3Yy/bWLKFIdmGGMlS0kS2/KCQJeSnP3cz1/3N8/Y+Znre+eabMl956vK+5zr3Oed673MvjxfKysr0t0BFRYVefHvw568ARH37/PnkU+BZkCI8C1KE/6egwMBAio+PJ319fYaqqirz0dHRND4+Tvn5+TwOCwujqKgo0dqYmBjy9/cXcVL4JUFv376l4+NjWllZobm5OfLy8qK4uDi6uLhgoWtra1RWVkbFxcXU1tYmWvvx40eZ4F+BpCA7Ozt69eoVKSkpkbq6Ouno6JCPjw95eHhQdXU1z8HOxMSENjc3qaWlhaqqqkR79Pb2UkZGhoiTgqSgnJwcmpycFHENDQ18sDxnZGREi4uL1NHRQRUVFaI5CEpJSeHvmZmZNDY2Rs3NzRQaGiqyEyApKDU1lQYGBkRcTU0N1dbWijhTU1MW1N7eTpWVlaK57u5uSkhI4B8h/yC25O0EKBTU398v4v6toK6uLvbQ+vr6dyn/PI+DX4CkINw9XC7PlZeXU319vYhD5i0tLfHhpaWlojkEeVZWFotdWFigz58/08bGBr17905kJ0BSENIamSXPDQ8PU0FBgWxsbm5Or1+/pk+fPrHnBgcHRfbg4SGUh6urK7q+vqbb29vfE6Snp0cnJydUVFRETk5OlJSURDc3N2RhYSGzwRUtLy8zHBwc6Pz8nAXAHkmBJzY29ocrAyd/lgBJQYCxsTHNzMzQzs4Ou9vd3V00Hx4eznUGmYPxy5cvOZO2trZodHSU3r9/TwEBAfx5f39Pp6enXMv8/PxE+wiQFGRpacm/+mdziBs3NzcOaFdXV+a0tbUpJCSEgoKCSEtLizlcqZmZGX/39fVlr9vY2JCBgYFsL3lICsrOzuaYQDHENSDr0tLSGBMTE3R5eclVurW1lT15dHTEgYuMg4d0dXWpp6eHr05+XyRARESEiBMgKQgCkB3BwcFc9Pr6+viAxsZGHkMIMg4eQqDX1dXJ1g4NDVFubi6XiceVenZ2ltuRPCdAoaDOzk4WZG1tzRzaSGJioswGGfbmzRtZwxWQnJzMVR0t5o8KwqZIdTRTcBB2dnZGmpqaPEaqf/jwgWPGysqKbG1tmYcIzP1xDzU1NbGXUJPAIdCRcRoaGjxGh0dZKCkp4QxaXV1lHjH3JIIgprCwkCsyetb3/6u4kUIQgCQQ9gSmp6f/myC0D2EDpPn29rawkPLy8ridoG1ERkbK1qKIIvhRfx57CJn4W4LwyxBDOBBdGxxqDB5PT0/2Bq4InRv9CgVPTU2N4ws8Xt5gj3IgxJyzszM9PDwwL3+WAElB6enpLARF8MuXL4yDgwNObxyyu7vLVRwZhiucmpqivb092t/fp5GREVnmIegPDw9pfn6e193d3fFr8ePzAElBhoaGsr4Fb3h7e/PLGMa4OhcXF5E94OjoSPb29j/wWIs2g/2wFhX7sQ0gKegp8CxIEZ4FKcKzIEVQVlamrxQGF7K1Nqs9AAAAAElFTkSuQmCC';
export default class List extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            is_loading: true,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let ref = await db.collection('refferalLink').get();
        let data = [];
        if (!ref.empty) {
            for (const doc of ref.docs) {
                let _data = doc.data();
                _data.uid = doc.id;
                data.push(_data);
            }
        }

        this.setState({
            data: data,
            is_loading: false,
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Refferal link</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={`/admin-detail/${this.props.adminUid}`}>ข้อมูลผู้ใช้งาน</Link></li>
                                    <li className="breadcrumb-item active">Refferal link</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <button className='btn btn-primary form-group'
                                    onClick={() => {
                                        Helper.reactToDom(window.$('body'),
                                            <FormModal
                                                mode={"create"}
                                                onSuccess={() => {
                                                    this.fetch();
                                                }}
                                            />
                                        )
                                    }}
                                >
                                    เพิ่ม
                                </button>
                                <div className='card'>
                                    <div className='card-body'>


                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>ชื่อ</th>
                                                    <th>Code</th>
                                                    <th>Qr Code</th>
                                                    <th>Preview</th>
                                                    <th style={{ width: 100 }}>ระบบ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !this.state.is_loading
                                                        && this.state.data.length > 0 ?
                                                        this.state.data.map((_data, _data_i) => {
                                                            return (
                                                                <tr key={_data_i}>
                                                                    <td>
                                                                        {_data.name}
                                                                    </td>
                                                                    <td>
                                                                        <a target='_blank' href={`https://beeclean.app/download?refferal=${_data.code}`}>{_data.code}</a>
                                                                    </td>
                                                                    <td>
                                                                        <QRCode value={`https://beeclean.app/download?refferal=${_data.code}`}
                                                                        // logoImage={logo}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        {_data.view || 0}
                                                                    </td>
                                                                    <td>
                                                                        <div
                                                                            ref={(ref) => {
                                                                                let node = window.$(ref);
                                                                                node.off().on('click', async () => {
                                                                                    let confirm = window.confirm('ยืนยันการลบ')
                                                                                    if (confirm) {
                                                                                        let ref = await db.collection('refferalLink').doc(_data.uid).get();
                                                                                        if (ref.exists) {
                                                                                            ref.ref.delete();
                                                                                        }
                                                                                        this.fetch();
                                                                                    }
                                                                                })
                                                                            }}
                                                                            className='link'>ลบ</div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                        : null
                                                }
                                                {
                                                    !this.state.is_loading
                                                        && this.state.data.length === 0 ?
                                                        <tr >
                                                            <td colSpan="6" style={{ textAlign: 'center' }}>
                                                                ไม่มีข้อมูล
                                                            </td>
                                                        </tr>
                                                        : null
                                                }
                                                {
                                                    this.state.is_loading ?
                                                        <tr >
                                                            <td colSpan="4" style={{ textAlign: 'center' }}>
                                                                <div className="ui active inline loader small" ></div>
                                                            </td>
                                                        </tr>
                                                        : null
                                                }


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
class FormModal extends React.Component {
    async create(data) {
        await db.collection('refferalLink').add(data);
        this.props.onSuccess();
        this.$modal.modal('hide')
    }

    async update(data) {

    }

    render() {
        return (<>
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                }}
            >
                <form className="modal-dialog modal-sm" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.$form = this.$modal.find('form');
                        let $submit = this.$form.find('button[type="submit"]');
                        if ($submit.hasClass('loading')) { return false; }
                        $submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        // return;
                        if (this.props.mode === 'edit') {
                            this.update(data);
                        }
                        if (this.props.mode === 'create') {
                            this.create(data);
                        }

                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">เพิ่ม Refferal link</h4>
                        </div>
                        <div className="modal-body">
                            <div className='form-group'>
                                <label>ชื่อ</label>
                                <input name="name" className='form-control' />
                            </div>
                            <div className='form-group'>
                                <label>Refferal Code</label>
                                <input name="code" className='form-control' />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >ยกเลิก</button>
                            <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                        </div>
                    </div>
                </form>
            </div>
        </>)
    }
}