import React from 'react';
import { Link } from '@reach/router';
// const NavLink = props => (
//   <Link
//     {...props}
//     getProps={({ isCurrent }) => {
//       return {
//         className: isCurrent ? "item active" : "item"
//       };
//     }}
//   />
// );
export default class SideBar extends React.Component {
  componentDidMount() {
    window.$(document).ready(() => {
      window.$('[data-widget="treeview"]').Treeview('init')
    });
  }

  componentDidUpdate() {
  }

  currentLink = ({ isCurrent }) => {
    return {
      className: isCurrent ? "nav-link active" : "nav-link"
    };
  }

  render() {
    // console.log(this.props.user)
    return (
      <aside className="main-sidebar elevation-4 sidebar-dark-warning ">
        <Link to={"/dashboard"} className="brand-link navbar-warning">
          <img src={window.location.origin + "/assets/images/logo-horizontal.png"} alt="Beeclean Logo" style={{ maxWidth: '100%', maxHeight: 19 }} />
        </Link>
        <div className="sidebar ">
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column nav-legacy nav-compact nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link"
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent || window.location.pathname === "/" || window.location.pathname === "" ? "nav-link active" : "nav-link"
                    };
                  }}
                >
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>หน้าหลัก</p>
                </Link>
              </li>
              {/* {
                this.props.user._fieldsProto.isCanEditAdmin.booleanValue ?
                  <li className="nav-item">
                    <Link to="/agents" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent || window.location.pathname.indexOf('/agent/') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon far fas fa-user-secret"></i>
                      <p>ตัวแทน</p>
                    </Link>
                  </li>
                  : null
              } */}
              {
                this.props.user._fieldsProto.isCanEditUsers.booleanValue ?
                  <li className="nav-item">
                    <Link to="/users" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent || window.location.pathname.indexOf('/user/') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon far fas fa-user-friends"></i>
                      <p>สมาชิก</p>
                    </Link>
                  </li>
                  : null
              }
              {
                this.props.user._fieldsProto.isCanEditProvider.booleanValue ?
                  <li className="nav-item">
                    <Link to="/provider-users" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent || window.location.pathname.indexOf('/provider-users/') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon fas fa-users"></i>
                      <p>แม่บ้าน</p>
                    </Link>
                  </li>
                  : null
              }
              {
                this.props.user._fieldsProto.isCanEditBooking.booleanValue ?
                  <li className="nav-item">
                    <Link to="/booking" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent || window.location.pathname.indexOf('/add-booking') !== -1 || window.location.pathname.indexOf('/booking/') !== -1 || window.location.pathname.indexOf('/booking-report') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon fas fa-clipboard-check"></i>
                      <p>รายการ Booking</p>
                    </Link>
                  </li>
                  : null
              }
              {
                this.props.user._fieldsProto.isCanEditTraningDay.booleanValue ?
                  <li className="nav-item">
                    <Link to="/educate-date" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent || window.location.pathname.indexOf('/trainningday-status') !== -1 || window.location.pathname.indexOf('/educate-date') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon far fa-calendar-check"></i>
                      <p>จัดการวันอบรม</p>
                    </Link>
                  </li>
                  : null
              }
              {
                this.props.user._fieldsProto.isCanEditPromotion.booleanValue ?
                  <li className="nav-item">
                    <Link to="/promotion" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent || window.location.pathname.indexOf('/promotion') !== -1 || window.location.pathname.indexOf('/marketing-users') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon fas fa-percentage"></i>
                      <p>จัดการโปรโมชั่น</p>
                    </Link>
                  </li>
                  : null
              }
              {
                this.props.user._fieldsProto.isCanEditSalary.booleanValue ?
                  <>
                    <li className={window.location.pathname.indexOf('/salary') !== -1 || window.location.pathname.indexOf("/provider-salary") !== -1 || window.location.pathname.indexOf('/agent-bill-cycle') !== -1 || window.location.pathname.indexOf('/csv-salary') !== -1 || window.location.pathname.indexOf("/csv-provider-salary") !== -1 ? "nav-item menu-open" : "nav-item"}>
                      <div className="nav-link">
                        <i className="nav-icon fas fa-file-invoice-dollar"></i>
                        <p >จัดการรายได้<i className="right fas fa-angle-left"></i></p>
                      </div>
                      <ul className="nav nav-treeview" style={{ display: window.location.pathname.indexOf('/salary') !== -1 || window.location.pathname.indexOf("/provider-salary") !== -1 || window.location.pathname.indexOf('/agent-bill-cycle') !== -1 || window.location.pathname.indexOf('/csv-salary') !== -1 || window.location.pathname.indexOf("/csv-provider-salary") !== -1 ? 'block' : 'none' }}>
                        <li className="nav-item">
                          <Link to="/salary" className="nav-link"
                            getProps={({ isCurrent }) => {
                              return {
                                className: isCurrent || window.location.pathname.indexOf('/salary') !== -1 || window.location.pathname.indexOf("/provider-salary") !== -1 ? "nav-link active" : "nav-link"
                              };
                            }}
                          >
                            <i className="far fa-circle nav-icon" style={{ fontSize: 14 }}></i>
                            <p>แม่บ้าน</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/agent-bill-cycle" className="nav-link"
                            getProps={({ isCurrent }) => {
                              return {
                                className: isCurrent || window.location.pathname.indexOf('/agent-bill-cycle') !== -1 ? "nav-link active" : "nav-link"
                              };
                            }}
                          >
                            <i className="far fa-circle nav-icon" style={{ fontSize: 14 }}></i>
                            <p>นายหน้า</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/csv-salary" className="nav-link"
                            getProps={({ isCurrent }) => {
                              return {
                                className: isCurrent || window.location.pathname.indexOf('/csv-salary') !== -1 || window.location.pathname.indexOf("/csv-provider-salary") !== -1 ? "nav-link active" : "nav-link"
                              };
                            }}
                          >
                            <i className="far fa-circle nav-icon" style={{ fontSize: 14 }}></i>
                            <p>แม่บ้าน (CSV)</p>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </>
                  : null
              }
              {
                this.props.user._fieldsProto.isCanEditServiceArea
                  && this.props.user._fieldsProto.isCanEditServiceArea.booleanValue ?
                  <li className="nav-item">
                    <Link to="/service-area" className="nav-link"
                      getProps={({ isCurrent }) => {
                        return {
                          className: isCurrent || window.location.pathname.indexOf('/service-area') !== -1 ? "nav-link active" : "nav-link"
                        };
                      }}
                    >
                      <i className="nav-icon fas fa-map-marked-alt"></i>
                      <p>จัดการพื้นที่ให้บริการ</p>
                    </Link>
                  </li>
                  : null
              }
              {
                this.props.user._fieldsProto.isCanEditApp
                  && this.props.user._fieldsProto.isCanEditApp.booleanValue ?
                  <li className={window.location.pathname.indexOf('/manage-promotion-slide') !== -1 ? "nav-item menu-open" : "nav-item"}>
                    <div className="nav-link">
                      <i className="nav-icon fas fa-mobile-alt"></i>
                      <p >จัดการข้อมูล APP<i className="right fas fa-angle-left"></i></p>
                    </div>
                    <ul className="nav nav-treeview" style={{ display: window.location.pathname.indexOf('/manage-promotion-slide') !== -1 ? 'block' : 'none' }}>
                      <li className="nav-item">
                        <Link className="nav-link" to={"/manage-promotion-slide"}
                          getProps={({ isCurrent }) => {
                            return {
                              className: isCurrent || window.location.pathname.indexOf('/manage-promotion-slide') !== -1 ? "nav-link active" : "nav-link"
                            };
                          }}
                        >
                          <i className="far fa-circle nav-icon" style={{ fontSize: 14 }}></i>
                          <p>จัดการสไลด์โปรโมชั่น</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  : null
              }
              <li className="nav-item">
                <Link to="/report" className="nav-link"
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent || window.location.pathname.indexOf('/report') !== -1 ? "nav-link active" : "nav-link"
                    };
                  }}
                >
                  <i className="nav-icon fas fa-file-alt"></i>
                  <p>รายงาน</p>
                </Link>
              </li>
              {
                this.props.user._fieldsProto.isCanEditAdmin
                  && this.props.user._fieldsProto.isCanEditAdmin.booleanValue ?
                  <li className="nav-item">
                    <Link to="/admin-setting" className="nav-link"
                      getProps={this.currentLink}
                    >
                      <i className="nav-icon fas fa-file"></i>
                      <p>
                        ผู้ดูแลและสิทธิในการใช้งาน
                      </p>
                    </Link>
                  </li>
                  : null
              }
              {/* {
                <li className={window.location.pathname.indexOf('/salary-dev') !== -1 ? "nav-item menu-open" : "nav-item"}>
                  <div className="nav-link">
                    <i className="nav-icon fas fa-mobile-alt"></i>
                    <p >Dev<i className="right fas fa-angle-left"></i></p>
                  </div>
                  <ul className="nav nav-treeview" style={{ display: window.location.pathname.indexOf('/salary-dev') !== -1 ? 'block' : 'none' }}>
                    <li className="nav-item">
                      <Link to="/salary-dev" className="nav-link"
                        getProps={({ isCurrent }) => {
                          return {
                            className: isCurrent || window.location.pathname.indexOf('/salary-dev') !== -1 || window.location.pathname.indexOf("/provider-salary-dev") !== -1 ? "nav-link active" : "nav-link"
                          };
                        }}
                      >
                        <i className="far fa-circle nav-icon" style={{ fontSize: 14 }}></i>
                        <p>income</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              } */}
              {/* {
                this.props.user._fieldsProto.displayName.stringValue ?
                  <>
                    <li className="nav-item">
                      <Link to={"/admin-detail/" + this.props.user._fieldsProto.id.stringValue} className="nav-link"
                        getProps={this.currentLink}
                      >
                        <i className="nav-icon fas fa-user-check"></i>
                        <p>
                          {this.props.user._fieldsProto.displayName.stringValue}
                        </p>
                      </Link>
                    </li>
                  </>
                  : null
              } */}
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}