import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../../Helper';
import DatePicker from 'react-datepicker';
import CustomDateInput from './../../Components/CustomDateInput';
import { db } from './../../firebase';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { orderBy } from '@progress/kendo-data-query';
import BankCode from './../../bankCode';
import '@progress/kendo-theme-default/dist/all.css';
const axios = require('axios').default;
export default class AdjustReport extends React.Component {
    constructor() {
        super();
        let defaultStatus = 'finish';
        let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
        let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
        let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : false;
        let sortField = _sortField || "bookedAt";
        if (!_sortField) {
            if (type == 'all') {
                if (booking_type && booking_type == 'admin_created') {
                    sortField = 'createdAt';
                    defaultStatus = 'all'
                }
            }
            if (type == 'user') {
                sortField = 'createdAt';
                defaultStatus = 'all'
            }

        }
        let sort_data = {
            sortField: sortField,
            // sortField: "bookedAt",
            sortDir: 'desc',
            sort: [{ field: 'bookedAt', dir: 'desc' }]
        };
        // if (type === 'all') {
        //     sort_data = {
        //         sortField: 'createdAt',
        //         sortDir: 'desc',
        //         sort: [{ field: 'createdAt', dir: 'desc' }]
        //     }
        // }
        this.state = {
            data: [],
            items_response: [],
            is_loading: true,
            type: type,
            render_start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
            render_end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
            start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
            end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
            booking_type: Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : `all`,
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : defaultStatus,
            isClearDevAccount: Helper.getParameterByName('isClearDevAccount') && Helper.getParameterByName('isClearDevAccount') == `false` ? false : true,
            finishedFilter: Helper.getParameterByName('finishedFilter') ? Helper.getParameterByName('finishedFilter') : 'all',
            ...sort_data
        }
    }

    componentDidUpdate(prevProps) {
        let defaultStatus = 'finish';
        let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
        let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
        let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : false;
        let sortField = _sortField || "bookedAt";
        if (!_sortField) {
            if (
                (type == 'all' && (booking_type && booking_type == 'admin_created'))
                || type == 'user'
            ) {
                sortField = 'createdAt';
                defaultStatus = 'all'
            }

        }
        let sort_data = {
            sortField: sortField,
            // sortField: "bookedAt",
            sortDir: 'desc',
            sort: [{ field: 'bookedAt', dir: 'desc' }]
        };
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                data: [],
                items_response: [],
                is_loading: true,
                render_start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
                render_end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
                start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
                end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
                isClearDevAccount: Helper.getParameterByName('isClearDevAccount') && Helper.getParameterByName('isClearDevAccount') == `false` ? false : true,
                booking_type: Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : `all`,
                finishedFilter: Helper.getParameterByName('finishedFilter') ? Helper.getParameterByName('finishedFilter') : 'all',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : defaultStatus,
                ...sort_data
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                data: [],
                items_response: [],
                is_loading: true,
                render_start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
                render_end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
                start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
                end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
                isClearDevAccount: Helper.getParameterByName('isClearDevAccount') && Helper.getParameterByName('isClearDevAccount') == `false` ? false : true,
                booking_type: Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : `all`,
                finishedFilter: Helper.getParameterByName('finishedFilter') ? Helper.getParameterByName('finishedFilter') : 'all',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : defaultStatus,
                ...sort_data
            }, () => {
                this.fetchData();
            })
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        // console.log(this.state.sortField, this.state.sortDir);
        let qs = {}
        qs.sortField = this.state.sortField;
        qs.sortDir = this.state.sortDir;
        // qs.start_date = new Date(parseFloat(this.state.start_date));
        // qs.start_date.setHours(0, 0, 1);
        // qs.end_date = new Date(parseFloat(this.state.end_date));
        // qs.end_date.setHours(23, 59, 0);
        // qs.start_date = qs.start_date.getTime() + (7 * 60 * 60 * 1000);
        // qs.end_date = qs.end_date.getTime() + (7 * 60 * 60 * 1000);
        // qs.start_date = qs.start_date.getTime();
        // qs.end_date = qs.end_date.getTime();

        let start_date = new Date(parseFloat(this.state.start_date));
        let end_date = new Date(parseFloat(this.state.end_date));
        qs.start_date = `${start_date.getDate()}-${start_date.getMonth()}-${start_date.getFullYear()}`;
        qs.end_date = `${end_date.getDate()}-${end_date.getMonth()}-${end_date.getFullYear()}`;
        qs.type = this.state.type;
        qs.status = this.state.status;
        qs.booking_type = this.state.booking_type;
        qs.adminUid = this.props.adminUid;
        qs.onlyAdjust = true
        // console.log(qs)
        try {
            let res = await axios.get(`${window.api_host}/booking-report?${window.$.param(qs)}`);
            console.log(res);
            let _data = [];
            let count = 0;
            let provider_all = 0;
            let repeated_count = 0;
            let count_plusTime = 0;
            if (res.data.success) {
                for (const data of res.data.data) {
                    if (this.state.isClearDevAccount && data.providerUser.devAccount) {
                        continue;
                    }
                    let prepare = {};
                    let summary = Helper.bookingSummary({ booking: data, payment: data.payment });
                    prepare.incomeDate = Helper.getDateNumber(data.createdAt, { is_show_time: false })
                    prepare.bookServiceDate = Helper.getDateNumber(data.bookedAt, { is_show_time: false })
                    prepare.priceUrgentProvider = data.priceUrgentProvider ? parseFloat(data.priceUrgentProvider) : 0;
                    prepare.price = (parseFloat(data.price))
                    prepare.plustime_total_price = parseFloat(0);
                    // prepare.priceApp = (parseFloat(data.priceApp) - parseFloat(summary.tax_total_price));
                    prepare.priceApp = parseFloat(data.priceApp) <= 0 ? 0 : parseFloat(data.priceApp);
                    prepare.priceDiscount = parseFloat(data.priceDiscount) > parseFloat(data.price) ? parseFloat(data.price) : parseFloat(data.priceDiscount);
                    prepare.totalPriceProvider = parseFloat(data.priceProvider) > 0 ? parseFloat(data.priceProvider) : (350);
                    prepare.chargePrice = parseFloat(data.chargePrice) <= 0 ? 0 : parseFloat(data.chargePrice);
                    if (prepare.chargePrice < 0) {
                        prepare.chargePrice = 0;
                    }
                    prepare.priceAgentCommissionFromProvider = parseFloat(data.priceAgentCommissionFromProvider) || 0;
                    prepare.priceAgentCommissionFromApp = parseFloat(data.priceAgentCommissionFromApp) || 0;
                    prepare.priceProvider = parseFloat(data.priceProviderWithoutAgent) ? parseFloat(data.priceProviderWithoutAgent) : parseFloat(data.priceProvider) > 0 ? parseFloat(data.priceProvider) : (350);
                    prepare.totalUserPrice = ((parseFloat(data.price) + parseFloat(prepare.chargePrice)) - parseFloat(prepare.priceDiscount)).toFixed(2)
                    prepare.priceUserAfterDiscount = (parseFloat(prepare.price) - parseFloat(prepare.priceDiscount));
                    let work_time = parseFloat(data.bookTime.time);
                    let _plusTime = 0;
                    let priceProviderPlusTime = 0;
                    prepare.priceProviderPlusTime = priceProviderPlusTime;
                    prepare.plusTime = _plusTime;
                    prepare.bookTime = work_time;
                    prepare.totalPriceApp = (parseFloat(prepare.priceApp) + parseFloat(prepare.chargePrice)).toFixed(2);
                    if (data.providerUser && data.providerUser.bank) {
                        let getBank = BankCode.filter((e) => e.name.indexOf(data.providerUser.bank) != -1)[0];
                        if (getBank) {
                            data.providerUser.bankCode = getBank.code;
                        }
                    }
                    prepare.paymentMethod = ""
                    if (data.payment) {
                        if (data.payment.paymentBy == 'บัตรเครดิต') {
                            prepare.paymentMethod = "บัตรเครดิต";
                        }
                    }
                    if (data.type && data.type == 'wegate') {
                        if (data.paymentStatus == 'success') {
                            prepare.payment = {
                                statusText: 'สำเร็จ',

                            }
                        }
                        let channel = data.channel;
                        prepare.payment = { ...prepare.payment }
                        if (channel == 'PP') {
                            prepare.payment.paymentBy = "Ksher (PromptPay)";
                            prepare.paymentMethod = "Ksher (PromptPay)";
                        }
                        if (channel == 'TM') {
                            prepare.payment.paymentBy = "Ksher (True Money)";
                            prepare.paymentMethod = "Ksher (True Money)";
                        }
                        if (channel == 'AL') {
                            prepare.payment.paymentBy = "Ksher (Alipay)";
                            prepare.paymentMethod = "Ksher (Alipay)";
                        }
                        if (channel == 'SP') {
                            prepare.payment.paymentBy = "Ksher (ShopeePay)";
                            prepare.paymentMethod = "Ksher (ShopeePay)";
                        }
                        if (channel == 'LP') {
                            prepare.payment.paymentBy = "Ksher (LINE Pay)";
                            prepare.paymentMethod = "Ksher (LINE Pay)";
                        }
                        if (channel == 'WC') {
                            prepare.payment.paymentBy = "Ksher (WeChat Pay)";
                            prepare.paymentMethod = "Ksher (WeChat Pay)";
                        }
                    }
                    prepare.bookPaymentInfo = "แอพพลิเคชั่น";
                    prepare.totalUserPrice = parseFloat(prepare.totalUserPrice)
                    prepare.totalPriceApp = parseFloat(prepare.totalPriceApp)
                    if (!data.user_data) {
                        prepare.user_data = {
                            displayName: data.user.displayName,
                            phoneNumber: data.user.guestPhoneNumber,
                        }
                    }
                    if (data.createdAdmin) {
                        prepare.paymentMethod = "โอนเงิน";
                        prepare.bookPaymentInfo = "ระบบหลังบ้าน";
                        prepare.payment = {
                            statusText: 'สำเร็จ',
                            paymentBy: 'โอนเงิน'
                        }
                    }

                    if (prepare.priceUrgentProvider > 0) {
                        prepare.bookPaymentInfo = "เร่งด่วน"
                    }
                    prepare.bookAt = Helper.getDateNumber(data.createdAt);
                    prepare.deductions = 0;
                    prepare.specialPrice = 0;
                    let deductionsNotes = [];
                    let specialPriceNotes = [];
                    if (data.adjustSalary && data.adjustSalary.length > 0) {
                        for (const adj of data.adjustSalary) {
                            if (adj.providerUserUid == data.providerUserUid) {
                                if (adj.type == 'withhold_wallet') {
                                    prepare.deductions += parseFloat(adj.amount)
                                    if (adj.reason) {
                                        deductionsNotes.push(adj.reason)
                                    }
                                } else {
                                    prepare.specialPrice += parseFloat(adj.amount)
                                    if (adj.reason) {
                                        specialPriceNotes.push(adj.reason)
                                    }
                                }
                            }
                        }
                    }
                    prepare.deductionsNote = deductionsNotes.join()
                    prepare.specialPriceNote = specialPriceNotes.join()
                    prepare.rentOutOfAppPrice = 0;
                    prepare.transferPrice = (parseFloat(prepare.totalPriceProvider) - parseFloat(prepare.deductions)) + parseFloat(prepare.specialPrice);
                    let count_bookNo = _data.filter((e) => { return e.bookOn == data.bookOn });
                    // console.log("count_bookNo",count_bookNo)
                    if (count_bookNo.length > 0) {
                        repeated_count++;
                        // console.log("ซ้ำ")
                        // console.log(count_bookNo)
                        // console.log(data)
                        // console.log("<----------------------------->")
                    }
                    if (prepare.specialPrice > 0 || prepare.deductions > 0 || prepare.priceUrgentProvider > 0) {
                        _data.push({
                            ...data,
                            ...prepare
                        })
                    }
                    if (data.plusTime && data.plusTime.length > 0) {
                        let plus_time_no = 1;
                        for (const plusTime of data.plusTime) {
                            let _status = ``
                            if (plusTime.status == 'success') {
                                _status = `สำเร็จ`;
                            }
                            if (plusTime.status == 'wait') {
                                _status = `รอชำระเงิน`;
                            }
                            if (plusTime.status == 'cancel') {
                                _status = `ยกเลิก`;
                            }
                            if (plusTime.status == 'fail') {
                                _status = `ไม่สำเร็จ`;
                            }
                            prepare.bookPaymentInfo = `เพิ่มชั่วโมงครั้งที่ ${plus_time_no} (${_status})`;
                            count_plusTime += 1;
                            let plustimePrepare = { ...prepare };
                            plustimePrepare.totalPriceProvider = plusTime.priceProvider;
                            if (this.state.type === 'provider') {
                                plustimePrepare.priceProvider = plusTime.priceProvider;
                            } else {
                                plustimePrepare.priceProviderPlusTime = plusTime.priceProvider;
                            }
                            plustimePrepare.bookTime = plusTime.time;
                            plustimePrepare.price = parseFloat(plusTime.priceTotal);
                            plustimePrepare.plustime_total_price = parseFloat(0);
                            plustimePrepare.priceApp = (parseFloat(plusTime.priceApp));
                            plustimePrepare.priceDiscount = 0;
                            plustimePrepare.totalPriceProvider = parseFloat(plusTime.priceProvider) > 0 ? parseFloat(plusTime.priceProvider) : (350);
                            plustimePrepare.chargePrice = parseFloat(plusTime.vat);
                            plustimePrepare.totalUserPrice = ((parseFloat(plusTime.priceTotal) - parseFloat(plustimePrepare.priceDiscount)) + parseFloat(plustimePrepare.chargePrice)).toFixed(2)
                            plustimePrepare.totalUserPrice = parseFloat(plustimePrepare.totalUserPrice)
                            plustimePrepare.priceUserAfterDiscount = (parseFloat(plustimePrepare.price) - parseFloat(plustimePrepare.priceDiscount));

                            _plusTime = 0;

                            plustimePrepare.payment = {
                                statusText: _status,
                                paymentBy: 'บัตรเครดิต',
                            }

                            plustimePrepare.totalPriceApp = (parseFloat(plusTime.priceApp) + parseFloat(plusTime.vat)).toFixed(2);
                            plustimePrepare.totalPriceApp = parseFloat(plustimePrepare.totalPriceApp);
                            plustimePrepare.bookOn = data.bookOn;
                            if (plusTime.paymentBy && plusTime.paymentBy == "omise" && plusTime.omise) {
                                plustimePrepare.payment.card = plusTime.omise.card;
                            }
                            if (plusTime.paymentBy && plusTime.paymentBy == "banktransfer") {
                                plustimePrepare.paymentMethod = "โอนเงิน";
                                plustimePrepare.payment.paymentBy = 'โอนเงิน';
                            }
                            plustimePrepare.createdAt = plusTime.updatedAt;
                            plustimePrepare.specialPriceNote = "";
                            // plustimePrepare.time = "";
                            plustimePrepare.deductions = 0;
                            plustimePrepare.specialPrice = plustimePrepare.totalPriceProvider || 0;
                            plustimePrepare.priceUrgentProvider = 0;

                            _data.push({
                                ...data,
                                ...plustimePrepare
                            })
                            plus_time_no++;
                            // }
                        }
                    }
                    if (data.changeProviderData) {
                        let changeProviderData_index = 0;
                        for (const pData of data.changeProviderData) {
                            let _pData = {
                                ...pData,
                                providerUser: { ...pData.currentProvider },
                            }
                            if (_pData.currentProviderUid != data.providerUserUid) {
                                if (_pData?.providerUser?.phoneNumber) {
                                    _pData.providerUser.phoneNumber = pData.currentProvider?.phoneNumber || `ไม่มีข้อมูล`
                                } else {
                                    _pData.providerUser.phoneNumber = `ไม่มีข้อมูล`
                                }
                                if (_pData?.providerUser?.displayName) {
                                    _pData.providerUser.displayName = pData.currentProvider?.displayName || `ไม่มีข้อมูล`
                                } else {
                                    _pData.providerUser.displayName = `ไม่มีข้อมูล`
                                }
                                _pData.bookPaymentInfo = `เปลี่ยนแม่บ้านครั้งที่ ${changeProviderData_index + 1}`;
                                _pData.bookTime = work_time;
                                _pData.price = parseFloat(0);
                                _pData.plustime_total_price = parseFloat(0);
                                _pData.priceApp = parseFloat(0);
                                _pData.priceDiscount = parseFloat(0);
                                _pData.totalPriceProvider = parseFloat(0);
                                _pData.chargePrice = parseFloat(0);
                                _pData.totalUserPrice = parseFloat(0).toFixed(2)
                                _pData.totalUserPrice = parseFloat(0)
                                _pData.priceUserAfterDiscount = parseFloat(0)
                                _pData.priceAgentCommissionFromProvider = 0;
                                _pData.priceAgentCommissionFromApp = 0;
                                _pData.priceProvider = _pData.totalPriceProvider;
                                _pData.transferPrice = _pData.totalPriceProvider;
                                _pData.chargePrice = parseFloat(0);
                                _pData.totalPriceApp = (0).toFixed(2);
                                _pData.deductions = 0;
                                _pData.specialPrice = 0;
                                _pData.isChangeProvider = true

                                _pData.totalPriceUrgent = 0;
                                _pData.totalPriceUrgentProvider = 0;
                                _pData.totalPriceUrgentApp = 0;
                                _pData.rentOutOfAppPrice = 0;
                                _pData.bookAt = Helper.getDateNumber(data.createdAt);
                                _pData.bookedAt = data.bookedAt
                                _pData.bookServiceDate = prepare.bookServiceDate;
                                _pData.finishAt = data.finishAt;
                                _pData.deductions = 0;
                                _pData.specialPrice = 0;
                                let deductionsNotes = [];
                                let specialPriceNotes = [];
                                let adjust_count = 0;
                                if (data.adjustSalary && data.adjustSalary.length > 0) {
                                    for (const adj of data.adjustSalary) {
                                        if (adj.providerUserUid == pData.currentProviderUid) {
                                            if (adj.type == 'withhold_wallet') {
                                                _pData.deductions += parseFloat(adj.amount)
                                                if (adj.reason) {
                                                    deductionsNotes.push(adj.reason)
                                                }
                                                adjust_count++;
                                            } else {
                                                _pData.specialPrice += parseFloat(adj.amount)
                                                if (adj.reason) {
                                                    specialPriceNotes.push(adj.reason)
                                                }
                                                adjust_count++;
                                            }
                                        }
                                    }
                                }
                                _pData.deductionsNote = deductionsNotes.join()
                                _pData.specialPriceNote = specialPriceNotes.join()

                                _pData.transferPrice += _pData.specialPrice;
                                _pData.transferPrice -= _pData.deductions;
                                _pData.priceUrgentProvider = 0;
                                if (_pData.transferPrice < 0) {
                                    _pData.transferPrice = 0;
                                }
                                if (_pData.providerUser && _pData.providerUser.bank) {
                                    let _getBank = BankCode.filter((e) => e.name.indexOf(_pData.providerUser.bank) != -1)[0];
                                    if (_getBank) {
                                        _pData.providerUser.bankCode = _getBank.code;
                                    }
                                    // _pData.providerUser.bankAccountNo = data.providerUser.bankAccountNo.replaceAll('-', '').replaceAll(' ', '').trim()
                                }
                                if (this.state.isClearDevAccount && pData.currentProvider.devAccount) {
                                    continue;
                                }

                                if (adjust_count > 0) {
                                    _data.push({
                                        ...data,
                                        ...prepare,
                                        ..._pData
                                    })
                                }
                                changeProviderData_index++;
                            }
                        }
                    }
                    // console.log(_data)

                }
                count = res.data.count_all;
                provider_all = res.data.provider_all;
            }
            // console.log(`_data.length`, _data.length);
            let prepareData = [];


            if (this.state.finishedFilter == 'halfday') {
                for (const data of _data) {
                    let isCanPush = true;
                    let logdata = {};
                    let serviceDate = new Date(data.bookedAt)
                    let dateFinished = new Date(data.finishAt - (7 * 3600 * 1000));
                    if (!data.finishAt) {
                        console.log(data.bookOn)
                    }
                    logdata.dateFinished = Helper.getDateNumber(dateFinished, { is_show_time: true })
                    logdata.dateFinishedGetTime = dateFinished.getTime()

                    // prepare.dateFinished = Helper.getDateThai(dateFinished, { is_show_time: true })
                    // console.log(`serviceDate`, Helper.getDateNumber(serviceDate, { is_show_time: true }), `dateFinished`, Helper.getDateNumber(dateFinished, { is_show_time: true }))
                    // console.log(`start_date`, Helper.getDateNumber(start_date, { is_show_time: true }), `end_date`, Helper.getDateNumber(end_date, { is_show_time: true }))
                    if (dateFinished) {
                        if (start_date.getDate() == end_date.getDate()
                            && start_date.getMonth() == end_date.getMonth()
                            && start_date.getFullYear() == end_date.getFullYear()
                            && end_date.getDate() == serviceDate.getDate()
                        ) { // ถ้าเลือกวันเดียวกัน
                            if (dateFinished.getHours() > 12
                                || (
                                    dateFinished.getHours() == 12
                                    && dateFinished.getMinutes() > 0
                                )
                            ) { // ถ้าเกินเที่ยง
                                // console.log(`เกินเที่ยง - วันเดียวกัน`)
                                isCanPush = false;
                            }
                        } else {

                            let startHalfDay = new Date(parseFloat(this.state.start_date));
                            let endHalfDay = new Date(parseFloat(this.state.end_date));
                            startHalfDay.setHours(12, 0, 0);
                            endHalfDay.setHours(12, 0, 0);
                            logdata.startHalfDay = Helper.getDateNumber(startHalfDay, { is_show_time: true })
                            logdata.endHalfDay = Helper.getDateNumber(endHalfDay, { is_show_time: true })
                            logdata.startHalfDayGetTime = startHalfDay.getTime();
                            logdata.endHalfDayGetTime = endHalfDay.getTime();
                            if (startHalfDay.getTime() >= dateFinished.getTime()) {
                                isCanPush = false;
                            }
                            if (endHalfDay.getTime() <= dateFinished.getTime()) {
                                isCanPush = false;
                            }
                        }
                    }
                    logdata.isCanPush = isCanPush;
                    // console.log(logdata)
                    if (isCanPush) {
                        prepareData.push(data);
                    }
                }

            } else {
                prepareData = _data;
            }
            // console.log(`prepareData.length`, prepareData.length);
            this.setState({
                data: prepareData,
                // items_response: _response,
                is_loading: false,
                all_items: count,
                provider_all: provider_all,
                logId: res.data.logId,
            }, () => {
                if (repeated_count > 0) {
                    window.$(document).Toasts('create', {
                        title: 'เกิดข้อผิดพลาด',
                        body: "มีข้อมูลซ้ำกรุณาตรวจสอบอีกครั้ง",
                        icon: 'fas fa-exclamation',
                        autohide: true,
                        delay: 3000,
                        class: "bg-warning",
                    })
                }
            })
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: "ไม่สามารถดึงข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.setState({
                data: [],
                is_loading: false,
                all_items: 0,
                provider_all: 0,
            })
        }

    }

    render() {
        let page_title = "รายงานปรับรายได้แม่บ้าน"
        let fileName = 'รายงานปรับรายได้แม่บ้าน';
        let page_sub_title = "";
        page_sub_title += ' ณ วันที่ ' + Helper.getDateThai(parseFloat(this.state.render_start_date)) + ' - ' + Helper.getDateThai(parseFloat(this.state.render_end_date))

        fileName += ' ' + Helper.getDateThai(parseFloat(this.state.start_date)) + ' - ' + Helper.getDateThai(parseFloat(this.state.end_date));
        // let _response = this.state.items_response;
        // console.log(_response);
        // console.log(this.state.data);
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">{page_title}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/provider-users">แม่บ้าน</Link></li>
                                    <li className="breadcrumb-item active">
                                        {page_title}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " >
                                <h3 className="card-title" style={{ display: 'flex', alignItems: 'center' }}>รายการ Booking  {page_sub_title}</h3>
                            </div>

                            <div className="card-body">
                                <div className={"flex row"}
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <form
                                        className="row flex"
                                        ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                ...data
                                            })
                                            navigate(url)
                                        }}
                                    >
                                        <input type="hidden" name="isClearDevAccount" value={this.state.isClearDevAccount} />
                                        <input type="hidden" name="type" value={this.state.type} />
                                        <div style={{ marginRight: '0.5rem' }}>
                                            <DatePicker
                                                disabled={this.state.is_loading}
                                                onChange={date => {

                                                    let start = new Date(date);
                                                    start.setHours(0, 0, 0, 0);
                                                    this.setState({
                                                        start_date: start.getTime()
                                                    }
                                                        // , () => {
                                                        //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                        // }
                                                    )
                                                }}
                                                selectsStart
                                                startDate={this.state.start_date}
                                                endDate={this.state.end_date}
                                                locale="th-TH"
                                                selected={Helper.getDateToEdit(this.state.start_date)}
                                                placeholderText={"วัน / เดือน / ปี"}
                                                name={"start_date"}
                                                dateFormat="MMMM d, yyyy 00:00:00"
                                                withPortal
                                                onCalendarOpen={() => { Helper.renderDate() }}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => {
                                                    date = new Date(date);
                                                    let now = new Date();
                                                    // months_th
                                                    const years = [];
                                                    let i;
                                                    for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                        years.push(i)
                                                    }
                                                    return (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className={"btn btn-primary "}
                                                                type={"button"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                            >
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <select
                                                                className={"form-control"}
                                                                value={date.getFullYear()}
                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {years.map(option => (
                                                                    <option key={option} value={option} >
                                                                        {option + 543}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className={"form-control"}
                                                                value={window.months_th[date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(window.months_th.indexOf(value))
                                                                }
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {window.months_th.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                className={"btn btn-primary"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                type={"button"}
                                                            >
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                    )
                                                }}
                                                customInput={<CustomDateInput />}
                                            />
                                            <input type="hidden" id="start_date" name="start_date" value={this.state.start_date} onChange={() => { console.log('change') }} readOnly={true} />
                                        </div>
                                        <div style={{ marginRight: '0.5rem' }}>
                                            <DatePicker
                                                disabled={this.state.is_loading}
                                                onChange={date => {
                                                    let end = new Date(date);
                                                    end.setHours(23, 59, 0, 0);
                                                    this.setState({
                                                        end_date: end.getTime()
                                                    }
                                                        // , () => {
                                                        //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                        // }
                                                    )
                                                }}
                                                selectsEnd
                                                startDate={this.state.start_date}
                                                endDate={this.state.end_date}
                                                locale="th-TH"
                                                selected={Helper.getDateToEdit(this.state.end_date)}
                                                placeholderText={"วัน / เดือน / ปี"}
                                                name={"end_date"}
                                                dateFormat="MMMM d, yyyy 23:59:00"
                                                minDate={this.state.start_date}
                                                withPortal
                                                onCalendarOpen={() => { Helper.renderDate() }}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => {
                                                    date = new Date(date);
                                                    let now = new Date();
                                                    // months_th
                                                    const years = [];
                                                    let i;
                                                    for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                        years.push(i)
                                                    }
                                                    return (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className={"btn btn-primary "}
                                                                type={"button"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                            >
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <select
                                                                className={"form-control"}
                                                                value={date.getFullYear()}
                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {years.map(option => (
                                                                    <option key={option} value={option} >
                                                                        {option + 543}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className={"form-control"}
                                                                value={window.months_th[date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(window.months_th.indexOf(value))
                                                                }
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {window.months_th.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                className={"btn btn-primary"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                type={"button"}
                                                            >
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                    )
                                                }}
                                                customInput={<CustomDateInput />}
                                            />
                                            <input type="hidden" id="end_date" name="end_date" value={this.state.end_date} onChange={() => { console.log('change') }} readOnly={true} />
                                        </div>
                                        {

                                            (this.state.booking_type !== 'admin_created') ?
                                                <div style={{ marginRight: '0.5rem' }}>
                                                    <select className='form-control' name='status'
                                                        defaultValue={this.state.status}>
                                                        <option value="all">แสดงทั้งหมด</option>
                                                        {/* <option value="wait">รอ</option> */}
                                                        <option value="accept">ยอมรับ</option>
                                                        <option value="going">กำลังเดินทาง</option>
                                                        <option value="working">กำลังทำงาน</option>
                                                        <option value="arrive">ถึงแล้ว</option>
                                                        <option value="finish">สำเร็จ</option>
                                                        <option value="refund">คืนเงิน</option>
                                                        <option value="cancel">ยกเลิก</option>
                                                    </select>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.type == 'all'
                                                || this.state.type == 'user' ?

                                                <div>
                                                    <select className='form-control' name='sortField' defaultValue={this.state.sortField || false}>
                                                        <option value="createdAt">วันจอง</option>
                                                        <option value="bookedAt">วันให้บริการ</option>
                                                    </select>
                                                </div>
                                                : null
                                        }
                                        <div
                                            style={{ marginRight: '0.5rem' }}>
                                            <select
                                                className='form-control'
                                                name='finishedFilter'
                                                defaultValue={this.state.finishedFilter || false}
                                            >
                                                <option value="all">ทั้งหมด</option>
                                                <option value="halfday">หลังเที่ยง - ก่อนเที่ยง</option>
                                            </select>
                                        </div>
                                        {/* <div>
                                            <select className='form-control' name='booking_type'>
                                                <option value="all">แสดงทั้งหมด</option>
                                                <option value="app_created">สร้างโดยแอป</option>
                                                <option value="admin_created">สร้างโดยระบบหลังบ้าน</option>
                                            </select>
                                        </div> */}
                                        <div className="input-group-append" style={{ marginLeft: 5 }}>
                                            <input type="hidden" name="booking_type" value={this.state.booking_type} />
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i> ค้นหา
                                            </button>
                                        </div>
                                    </form>
                                    <p style={{ marginLeft: 10, marginBottom: 0, display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>อ้างอิงจาก{this.state.sortField == 'createdAt' ? 'วันจอง' : 'วันให้บริการ'}</p>
                                    {
                                        this.props.is_super_admin ?
                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}>
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox"
                                                        className="custom-control-input"
                                                        id="isClearDevAccount"
                                                        name={"isClearDevAccount"}
                                                        defaultValue={"on"}
                                                        onChange={(e) => {
                                                            let data = Helper.getFormInputObject(this.$searchForm);
                                                            if (window.$(e.target).prop("checked")) {
                                                                data.isClearDevAccount = `true`
                                                            } else {
                                                                data.isClearDevAccount = `false`
                                                            }
                                                            let url = Helper.getQueryStringLinkFromObject({
                                                                ...data
                                                            })
                                                            navigate(url)
                                                        }}
                                                        defaultChecked={this.state.isClearDevAccount}
                                                    />
                                                    <label className="custom-control-label" htmlFor="isClearDevAccount">ปิดแสดงผล dev account</label>
                                                </div>
                                            </div>
                                            : null
                                    }
                                    {
                                        !this.state.is_loading ?
                                            <div style={{ marginLeft: 'auto' }}>
                                                <button
                                                    title="Export Excel"
                                                    className="btn btn-default"
                                                    onClick={async () => {
                                                        this._export.save()
                                                    }}
                                                >
                                                    โหลดรายงาน
                                                </button>
                                            </div>
                                            : null
                                    }
                                </div>


                                {
                                    this.state.is_loading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                {
                                    !this.state.is_loading
                                        && this.state.type !== 'all' ?
                                        <ExcelExport
                                            fileName={fileName}
                                            data={this.state.data}
                                            ref={exporter => this._export = exporter}
                                        >
                                            <Grid data={this.state.data} style={{ height: '650px' }}>
                                                {/* <GridColumn field="bookAt" title="วันที่บันทึก" width="150" /> */}
                                                {/* <GridColumn field="incomeDate" title="รายได้วันที่" width="150" /> */}
                                                <GridColumn field="bookServiceDate" title="วันที่ให้บริการ" width="150" />
                                                <GridColumn
                                                    field="providerUser.displayName"
                                                    title="แม่บ้าน"
                                                    width="200"
                                                />
                                                <GridColumn field="bookPaymentInfo" title="สถานะ" width="150" />
                                                <GridColumn field="bookOn" title="เลข Booking" width="150" />
                                                <GridColumn field="priceUrgentProvider" title="เร่งด่วน" width="150" />
                                                <GridColumn field="deductions" title="ปรับเงิน" width="150" />
                                                <GridColumn field="deductionsNote" title="หมายเหตุ" width="150" />
                                                <GridColumn field="specialPrice" title="เงินพิเศษ" width="150" />
                                                <GridColumn field="specialPriceNote" title="หมายเหตุ" width="250" />
                                                <GridColumn field="bookStatusth"
                                                    title="สถานะ"
                                                    width="100"

                                                />
                                            </Grid>
                                        </ExcelExport>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}