import React from 'react';
import { db } from './../firebase';
import { Link } from '@reach/router';
import Pagination from './../Components/Pagination';
import { Helper } from './../Helper'
// const axios = require('axios').default;

export default class ViewMarketingUser extends React.Component {
    constructor(props) {
        super(props);
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        this.state = {
            user: false,
            is_loading: true,
            all_items: 0,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            timerage: Helper.getParameterByName('timerage') ? Helper.getParameterByName('timerage') : 'รายเดือน',
            years: years,
            year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
            month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
        }
    }

    componentDidMount() {
        this.fetchUser();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                timerage: Helper.getParameterByName('timerage') ? Helper.getParameterByName('timerage') : 'รายเดือน',
                year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
                month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            }, () => {
                this.fetchUser();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                timerage: Helper.getParameterByName('timerage') ? Helper.getParameterByName('timerage') : 'รายเดือน',
                year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
                month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
            }, () => {
                this.fetchUser();
            })
        }
    }

    async fetchUser() {
        let market = db.doc("market_users/" + this.props.market_id);
        let market_data = await market.get();
        let promotion = [];
        let count = 0;
        let qs = {};
        qs._page = this.state.page;
        qs._limit = parseFloat(this.state.limit);
        qs.market_user_id = this.props.market_id;
        let promotionRef = await db.collection('promotions').where('marketUid', '==', market_data.id).get();
        for (const doc of promotionRef.docs) {
            let __data = {...doc.data()};
            __data.id = doc.id;
            let history = await db.collection('promotions/' + __data.id + '/history').get();
            let _history = history.docs;
            promotion.push({
                ...__data,
                history: _history,
            })
        }
        let data = {
            ...market_data.data(),
            id: market_data.id,

        }
        let promotion_data = [];
        promotion.forEach((__data, __data_i) => {
            count++;
            if (__data_i < (this.state.limit * this.state.page)
                && __data_i > (((this.state.limit * this.state.page) - this.state.limit) - 1)
            ) {
                promotion_data.push(__data)
            }
        })
        this.setState({
            data: data,
            is_loading: false,
            all_items: count,
            promotion: promotion
        })
    }


    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ข้อมูลผู้รับผิดชอบ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/marketing-users">ผู้รับผิดชอบ</Link></li>
                                    <li className="breadcrumb-item active">ข้อมูลผู้รับผิดชอบ</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"content"}>
                    <div className="container-fluid" >

                        <div className="card">

                            {
                                this.state.data ?
                                    <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <h3 className="card-title">
                                            {this.state.data.name}
                                        </h3>
                                        {/* <div style={{ marginLeft: 'auto' }}>
                                                <form className="input-group input-group-sm"

                                                    ref={(ref) => { this.$searchForm = window.$(ref) }}
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        let data = Helper.getFormInputObject(this.$searchForm);
                                                        let url = Helper.getQueryStringLinkFromObject({
                                                            ...data
                                                        })
                                                        navigate(url)
                                                    }}
                                                >

                                                    <select className={"form-control form-control-sm"}
                                                        name={"timerage"}
                                                        ref={(ref) => { this.$timeRage = window.$(ref) }}
                                                        onChange={(e) => {
                                                            this.$searchForm.find('[type="submit"]').trigger('click');
                                                        }}
                                                        defaultValue={this.state.timerage}
                                                    >
                                                        <option value={"รายเดือน"}>รายเดือน</option>
                                                        <option value={"รายปี"}>รายปี</option>
                                                    </select>
                                                    {
                                                        this.state.timerage === 'รายเดือน' ?
                                                            <select className={"form-control form-control-sm"}
                                                                name={"month"}
                                                                ref={(ref) => { this.$month = window.$(ref) }}
                                                                onChange={(e) => {
                                                                    this.$searchForm.find('[type="submit"]').trigger('click');
                                                                }}
                                                                defaultValue={this.state.month}
                                                            >
                                                                {
                                                                    window.months_th.map((month, month_i) => (
                                                                        <option value={month_i} key={month_i}>{month}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            : null
                                                    }

                                                    {
                                                        this.state.timerage === 'รายปี' ?
                                                            <select className={"form-control form-control-sm"}
                                                                name={"year"}
                                                                ref={(ref) => { this.$year = window.$(ref) }}
                                                                onChange={(e) => {
                                                                    this.$searchForm.find('[type="submit"]').trigger('click');
                                                                }}
                                                                defaultValue={this.state.year}
                                                            >
                                                                {
                                                                    this.state.years.map((years) => (
                                                                        <option value={years} key={years}>{years}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            : null
                                                    }
                                                    <div className="input-group-append" >
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div> */}
                                    </div>

                                    : null
                            }
                            <div className={"card-body"}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>โปรโมชั่น</th>
                                            <th>ประเภท</th>
                                            <th>เงื่อนไข</th>
                                            <th>ช่วงเวลา</th>
                                            <th>จำนวนสิทธิ์</th>
                                            <th>รหัสโปรโมชั่น</th>
                                            <th style={{ width: 200 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.promotion.length > 0 ?
                                                this.state.promotion.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {_data.name}
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.promotionType === 'event' ?
                                                                        "แสดงในหน้า app"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionType === 'unique' ?
                                                                        "สำหรับการตลาด"
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.promotionCondition === 'all' ?
                                                                        "ใช้ได้ทุกที่"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionCondition === 'only_first_code' ?
                                                                        "ใช้ครั้งแรกใน code ชุดนั้นๆ"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionCondition === 'only_first_time' ?
                                                                        "ใช้ครั้งแรกใน app"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionCondition === 'only_first_time_plus_code' ?
                                                                        "ใช้ code ครั้งแรกและ ใช้ code ชุดนั้นครั้งแรก"
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.isAllTime ?
                                                                        "ไม่กำหนดระยะเวลา"
                                                                        :
                                                                        <>
                                                                            <div>{Helper.getDateThai(new Date(_data.startAt), { is_show_time: false })}</div>
                                                                            <div>{Helper.getDateThai(new Date(_data.EndAt), { is_show_time: false })}</div>
                                                                        </>
                                                                }
                                                            </td>

                                                            <td>
                                                                {
                                                                    _data.isUnLimit ?
                                                                        <>{_data.history.length + '/'}<i className="fas fa-infinity" style={{ color: '#ffc107' }}></i></>
                                                                        // _data.history.length + '/ ไม่จำกัดการใช้งาน' 
                                                                        :
                                                                        _data.history.length + '/' + _data.limit
                                                                }
                                                            </td>
                                                            <td>
                                                                {_data.voucherCode}
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <Link className="btn btn-default" to={"/promotion/" + _data.id}>ดูข้อมูล</Link>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.promotion.length === 0 ?
                                                <tr >
                                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

// class ProviderTranningDateForm extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             training_date: props.training_date || [],
//             user: props.user || false,
//         }
//     }


//     async update(data) {
//         // data.traningUID
//         if (this.state.user.traningUID === data.traningUID) {
//             window.$(document).Toasts('create', {
//                 title: 'แก้ไขข้อมูลสำเร็จ',
//                 body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
//                 icon: 'fas fa-check',
//                 autohide: true,
//                 delay: 3000,
//                 class: "bg-success",
//             })
//             this.props.onUpdateSuccess();
//             this.$modal.modal('hide');
//             return;
//         }
//         const old_participants = await db.collection(`training_date/${this.state.user.traningUID}/participants`).get();
//         const traningDate = await db.doc(`training_date/${data.traningUID}`).get();
//         const _participants = db.collection(`training_date/${data.traningUID}/participants`);
//         const participants = await _participants.get();
//         const count_participants = participants.docs.length;
//         //    เช็คจำนวนผู้เข้าอบรม
//         if (parseFloat(traningDate.data().maxParticipants) <= parseFloat(count_participants)) {
//             window.$(document).Toasts('create', {
//                 title: 'ผู้เข้าร่วมอบรมเต็ม',
//                 body: 'ผู้เข้าร่วมอบรมในวันนี้เต็มแล้ว กรุณาเลือกวันอบรมอื่น',
//                 icon: 'fas fa-check',
//                 autohide: true,
//                 delay: 3000,
//                 class: "bg-danger",
//             })
//             this.$submit.removeClass("loading");
//             return;
//         }
//         // ลบผู้เข้าอบรมจากวันเก่า
//         old_participants.docs.map(async (doc) => {
//             if (doc.data().providerUid === this.props.provider_id) {
//                 await db.doc(`training_date/${this.state.user.traningUID}/participants/` + doc.id).delete();
//             }
//         })
//         // เพิ่มผู้เข้าอบรมในวันใหม่
//         await _participants.add({ providerUid: this.props.provider_id });
//         await db.doc("provider_users_data/" + this.props.provider_id).update({ ...data }).then(() => {
//             window.$(document).Toasts('create', {
//                 title: 'แก้ไขข้อมูลสำเร็จ',
//                 body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
//                 icon: 'fas fa-check',
//                 autohide: true,
//                 delay: 3000,
//                 class: "bg-success",
//             })
//             this.$modal.modal('hide');
//             this.props.onUpdateSuccess();
//         })
//     }

//     render() {
//         return (
//             <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
//                 ref={(ref) => {
//                     this.$modal = window.$(ref);
//                     this.$modal.modal('show');
//                     this.$modal.on('hidden.bs.modal', () => {
//                         let settime = window.setTimeout(() => {
//                             this.$modal.remove();
//                             clearTimeout(settime);
//                         }, 500)
//                     });
//                     this.$form = this.$modal.find('form');
//                     this.$submit = this.$form.find('[type="submit"]');
//                 }}
//             >
//                 <form className="modal-dialog" role="document"
//                     onSubmit={(e) => {
//                         e.preventDefault();
//                         let submit_btn = this.$submit;
//                         if (submit_btn.hasClass('loading')) { return false; }
//                         submit_btn.addClass('loading');
//                         let data = Helper.getFormInputObject(this.$form);
//                         this.update(data);
//                     }}
//                 >
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลธนาคาร</h4>
//                         </div>
//                         <div className="modal-body">
//                             <div className="form-group">
//                                 <label>เลือกวันเข้าอบรม</label>
//                                 <select className="form-control"
//                                     name={"traningUID"}
//                                     defaultValue={this.state.user.traningUID}
//                                     onChange={(e) => {
//                                         // console.log(e.target.value);
//                                     }}
//                                 >
//                                     {
//                                         this.state.training_date.map((t_date, t_date_i) => {
//                                             return (
//                                                 <option key={t_date_i} value={t_date.id}>{Helper.getDateThai(t_date.date)}</option>
//                                             )
//                                         })
//                                     }
//                                 </select>
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" type={"button"} className="btn btn-default"
//                                 onClick={() => {
//                                     this.$modal.modal('hide');
//                                 }}
//                             >ยกเลิก</button>
//                             <button type="button" type={"submit"} className="btn btn-primary">บันทึก</button>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//         )
//     }
// }

// class BankForm extends React.Component {

//     async update(data) {
//         await db.doc("provider_users_data/" + this.props.provider_id).update({ ...data }).then(() => {
//             window.$(document).Toasts('create', {
//                 title: 'แก้ไขข้อมูลสำเร็จ',
//                 body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
//                 icon: 'fas fa-check',
//                 autohide: true,
//                 delay: 3000,
//                 class: "bg-success",
//             })
//             this.$modal.modal('hide');
//             this.props.onSuccess();
//         })
//     }

//     render() {
//         return (
//             <div className="modal fade"
//                 id="edit_bank"
//                 tabIndex="-1"
//                 role="dialog"
//                 aria-labelledby="myModalLabel"
//                 ref={(ref) => {
//                     this.$modal = window.$(ref);
//                     this.$modal.modal('show');
//                     this.$modal.on('hidden.bs.modal', () => {
//                         let settime = window.setTimeout(() => {
//                             this.$modal.remove();
//                             clearTimeout(settime);
//                         }, 500)
//                     });
//                     this.$form = this.$modal.find('form');
//                     this.$submit = this.$form.find('[type="submit"]');
//                 }}
//             >
//                 <form className="modal-dialog" role="document"
//                     onSubmit={(e) => {
//                         e.preventDefault();
//                         let submit_btn = this.$submit;
//                         if (submit_btn.hasClass('loading')) { return false; }
//                         submit_btn.addClass('loading');
//                         let data = Helper.getFormInputObject(this.$form);
//                         this.update(data);
//                     }}
//                 >
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลธนาคาร</h4>
//                         </div>
//                         <div className="modal-body">
//                             <div className="form-group">
//                                 <label>ธนาคาร</label>
//                                 <select className="form-control" name={"bank"} defaultValue={this.props.user.bank || ''}>
//                                     <option value={""}>เลือกธนาคาร</option>
//                                     {
//                                         this.props.bank_list.map((bank, bank_i) => {
//                                             return (
//                                                 <option key={bank_i} value={bank}>{bank}</option>
//                                             )
//                                         })
//                                     }
//                                 </select>
//                             </div>
//                             <div className="form-group">
//                                 <label>สาขา</label>
//                                 <input name="bankBranch" defaultValue={this.props.user.bankBranch || ""} className="form-control" />
//                             </div>
//                             <div className="form-group">
//                                 <label>ชื่อบัญชี</label>
//                                 <input name="bankAccountName" defaultValue={this.props.user.bankAccountName || ""} className="form-control" />
//                             </div>
//                             <div className="form-group">
//                                 <label>เลขบัญชี</label>
//                                 <input name="bankAccountNo" defaultValue={this.props.user.bankAccountNo || ""} className="form-control" />
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" type={"button"} className="btn btn-default"
//                                 onClick={() => {
//                                     this.$modal.modal('hide');
//                                 }}
//                             >ยกเลิก</button>
//                             <button type="button" type={"submit"} className="btn btn-primary">บันทึก</button>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//         )
//     }
// }