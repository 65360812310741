import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../../Helper';
import { db, storage } from './../../firebase';
import { CrateBillCycleForm } from './BillCycle'
import Pagination from './../../Components/Pagination';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { agentSalaryService } from '../../Services/agentSalaryService';
import BankCode from './../../bankCode';
// import CustomDateInput from './../Components/CustomDateInput';
// import DatePicker from "react-datepicker";
// import { count } from '@progress/kendo-data-query/dist/npm/array.operators';

const axios = require('axios').default;

export default class BillCycleDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            salary: false,
            all_items: 0,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : '',
            orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : '',
            is_show_next_button: false,
            maid_count: 0,
            total_payment: 0,
            booking_count: 0,
            agent_count: 0,
            data_count: 0,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                data: [],
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : '',
                orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : '',
                is_show_next_button: false,
                data_count: 0,
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetch();
            })
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let all_items_count = 0;
        let booking_count = 0;
        let agent_count = 0;
        let total_payment = 0;
        let count = 0;
        let data = [];

        const res = await agentSalaryService.get(this.props.id);
        let salary_data = false;

        salary_data = res.salaryData;
        data = res.salaryDetailData;
        agent_count = res.salaryDetailData?.length || 0;
        // console.log(`salary_data`, salary_data)
        if (salary_data?.totalPrice) {
            total_payment = salary_data.totalPrice;
        }

        this.setState({
            salary: salary_data,
            data: data,
            all_items: all_items_count,
            now_item: count,
            maid_count: all_items_count,
            total_payment: total_payment,
            booking_count: booking_count,
            agent_count: agent_count,
            is_loading: false,
            is_absolute_loading: false,
        })
    }

    async delete(_data) {
        // let data = {};
        // console.log(_data);
        await agentSalaryService.payDelete({ agentUid: _data.id, salaryAgentUid: this.props.id });
        // call api
        this.setState({
            is_loading: true,
        }, () => {
            this.fetch();
        })

    }

    async cancelOrder(item, options) {
        // console.log(item)
        let data = {
            agentUid: item.agentUid,
            salaryUid: this.props.id
        }
        // console.log(data)
        // do something
        let res = await agentSalaryService.payCancel({ agentUid: item.agentUid, salaryAgentUid: this.props.id });

        this.setState({
            is_loading: true,
        }, () => {
            this.fetch();
        })
    }


    render() {
        let export_data = [];
        if (this.state.data && this.state.data.length > 0) {
            this.state.data.map((_data) => {
                
                let _status = 'ไม่มีสถานะ';
                if (_data.status === 'pending') {
                    _status = 'รอ';
                }
                if (_data.status === 'pending' && !_data.bank) {
                    _status = 'ไม่พบธนาคาร';
                }
                if (_data.status === 'success') {
                    _status = 'โอนแล้ว';
                }
                if (_data.bank) {
                    let getBank = BankCode.filter((e) => e.name.indexOf(_data.bank) != -1)[0];
                    if (getBank) {
                        _data.bankCode = getBank.code;
                    }
                }
                let item = {
                    displayName: _data.agent.name,
                    phoneNumber: _data.agent.phoneNumber,
                    wallet: Helper.numberFormat(_data?.wallet ? _data.wallet : 0),
                    priceAfterTransfer: Helper.numberFormat(_data && _data.priceAfterTransfer ? _data.priceAfterTransfer : 0),
                    bank: _data.bank,
                    bankCode: _data.bankCode,
                    bankBranch: _data.bankBranch,
                    bankAccountName: _data.bankAccountName,
                    bankAccountNo: _data.bankAccountNo,
                    status: _status,
                    transferPrice: Helper.numberFormat(_data.transferPrice ? _data.transferPrice : 0),
                }
                export_data.push(item);
                return true
            })

        }
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการรายได้นายหน้า</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/agent-bill-cycle"}>จัดการรายได้</Link></li>
                                    <li className="breadcrumb-item active">จัดการรายได้นายหน้า</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายการ</h3>
                                <div className={"card-right"} />
                                <div className="card-tools">
                                    <form className="input-group input-group-sm"
                                        ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let obj = {};
                                            obj.page = 1;
                                            obj.name = data.name;
                                            let url = Helper.getQueryStringLinkFromObject({ ...obj }, '/salary-provider/' + this.props.id)
                                            navigate(url)
                                        }}
                                    >
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากชื่อนายหน้า" defaultValue={this.state.name} />
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card card-widget widget-user">
                                            <div className="widget-user-header bg-info" style={{ height: 'auto' }}>
                                                <h3 className="widget-user-username">Summary</h3>
                                                {/* <h5 className="widget-user-desc">Founder &amp; CEO</h5> */}
                                            </div>
                                            <div className="card-footer" style={{ paddingTop: '1rem' }}>
                                                <div className="row">
                                                    <div className="col-sm-6 border-right">
                                                        <div className="description-block">
                                                            <span className="description-text">Agents</span>
                                                            <h5 className="description-header">{Helper.numberFormat(this.state.agent_count)}</h5>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-sm-4 border-right">
                                                        <div className="description-block">
                                                            <span className="description-text">Booking</span>
                                                            <h5 className="description-header">
                                                                <div>
                                                                    {Helper.numberFormat(this.state.booking_count)}
                                                                </div>
                                                            </h5>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-sm-6">
                                                        <div className="description-block">
                                                            <span className="description-text">Total Payment</span>
                                                            <h5 className="description-header">
                                                                <div>
                                                                    {Helper.numberFormat(this.state.salary.totalPrice)}
                                                                </div>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div><label>วันเวลาที่ทำรายการ : </label> {Helper.getDateThai(this.state.salary.createdAt, { is_show_time: true })}</div>
                                        <div><label>ยอดรายได้มากกว่า : </label> {Helper.numberFormat(this.state.salary.minPrice)}</div>
                                        {
                                            this.state.salary.admin ?
                                                <div><label>ผู้สร้างรายการโอนเงิน : </label> {this.state.salary.admin.displayName}</div>
                                                : null
                                        }
                                        {
                                            this.state.salary.note ?
                                                <div><label>หมายเหตุ : </label> {this.state.salary.note}</div>
                                                : null
                                        }
                                    </div>
                                    <div className="col-md-4" style={{ textAlign: 'right' }}>
                                        <h3><span>สถานะ : </span>
                                            {
                                                this.state.salary.status === 'unsuccessful' ?
                                                    <label style={{ color: 'red' }}>ไม่สำเร็จ</label>
                                                    : null
                                            }
                                            {
                                                this.state.salary.status === 'successful' ?
                                                    <label style={{ color: 'green' }}>สำเร็จ</label>
                                                    : null
                                            }
                                            {
                                                !this.state.salary.status
                                                    && !this.state.salary.status ?
                                                    <label>ไม่ระบุ</label>
                                                    : null
                                            }
                                        </h3>
                                        <div>
                                            <button type="button"
                                                className={this.state.is_loading ? "btn btn-default loading" : "btn btn-default"}
                                                onClick={() => {
                                                    if (this.state.data.length <= 0) { return false }
                                                    this._export.save(export_data);
                                                }}
                                                disabled={this.state.data.length <= 0}
                                            >
                                                <i className="far fa-file-excel"></i> ดาวน์โหลดรายงาน
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <ExcelExport
                                    data={export_data}
                                    ref={exporter => this._export = exporter}
                                    fileName={"จัดการรายได้นายหน้า " + this.state.salary.title + " (" + Helper.getDateThai(this.state.salary.createdAt) + ")"}
                                >
                                    <ExcelExportColumn field="displayName" title="ชื่อ-นามสกุล" width={200} />
                                    <ExcelExportColumn field="phoneNumber" title="ข้อมูลติดต่อ" width={130} />
                                    <ExcelExportColumn field="wallet" title="ยอดเงินในกระเป๋าทั้งหมด" width={150} />
                                    <ExcelExportColumn field="priceAfterTransfer" title="เงินกระเป๋าหลังทำการโอน" width={150} />
                                    <ExcelExportColumn field="bankCode" title="รหัสธนาคาร" width={130} />
                                    <ExcelExportColumn field="bank" title="ธนาคาร" width={130} />
                                    <ExcelExportColumn field="bankBranch" title="สาขา" width={130} />
                                    <ExcelExportColumn field="bankAccountName" title="ชื่อบัญชี" width={150} />
                                    <ExcelExportColumn field="bankAccountNo" title="เลขบัญชี" width={150} />
                                    <ExcelExportColumn field="status" title="สถานะ" width={150} />
                                    <ExcelExportColumn field="transferPrice" title="จำนวนเงินโอน" width={150} />
                                </ExcelExport>
                                {
                                    this.state.is_absolute_loading ?
                                        <div className="absolute_loading">
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>นายหน้า</th>
                                            <th>ข้อมูลการติดต่อ</th>
                                            <th>เงินกระเป๋าปัจจุบัน</th>
                                            <th>บัญชีธนาคาร</th>
                                            <th>สถานะการโอน</th>
                                            <th style={{ width: 180 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                <Link to={"/agent/" + _data.agentUid} >{_data.agent.name}</Link><br />
                                                                <i className="fas fa-star" style={{ color: '#ffa200' }}></i> {_data.agent && _data.agent.starFloat ? _data.agent.starFloat.toFixed(2) : '5.00'}
                                                            </td>
                                                            <td>
                                                                {_data.agent.phoneNumber}
                                                            </td>
                                                            <td>
                                                                ฿{Helper.numberFormat(_data.defaultPrice ? _data.defaultPrice : 0)}
                                                                {
                                                                    _data.status !== "wait" && _data.transferBank && _data.priceAfterTransfer ?
                                                                        <span className="badge badge-info salary-custom-badge" style={{ marginLeft: 5 }}>
                                                                            ฿{Helper.numberFormat(_data.priceAfterTransfer || 0)}
                                                                        </span>
                                                                        : null
                                                                }

                                                            </td>
                                                            <td>
                                                                {_data.transferBank ?
                                                                    <>
                                                                        <div>{_data.transferBank + ' ' + _data.bankBranch}</div>
                                                                        <div>{_data.bankAccountName + ' ' + _data.bankAccountNo}</div>
                                                                    </>
                                                                    : null
                                                                }
                                                                {!_data.bank ?
                                                                    <>
                                                                        <div className={"link"}
                                                                            onClick={() => {
                                                                                Helper.reactToDom(
                                                                                    window.$('body'),
                                                                                    <CrateBillCycleForm
                                                                                        data={_data}
                                                                                        admin={this.props.admin}
                                                                                        onSuccess={async (bank) => {
                                                                                            let update_data = {};
                                                                                            if (bank.bankAccountName) {
                                                                                                update_data.providerBankName = bank.bankAccountName;
                                                                                            }
                                                                                            if (bank.bankBranch) {
                                                                                                update_data.providerBankBranch = bank.bankBranch;
                                                                                            }
                                                                                            if (bank.bankAccountNo) {
                                                                                                update_data.providerBankAccountNo = bank.bankAccountNo;
                                                                                            }
                                                                                            if (bank.bank) {
                                                                                                update_data.transferBank = bank.bank;
                                                                                            }
                                                                                            await db.collection('salary').doc(this.props.id).collection('provider').doc(_data.providerUID).update({ ...update_data });
                                                                                            this.setState({
                                                                                                is_absolute_loading: true
                                                                                            }, () => {
                                                                                                this.fetch();
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            }}
                                                                        >
                                                                            แก้ไขข้อมูลธนาคาร
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <div>
                                                                        {_data.bank}
                                                                    </div>
                                                                }

                                                            </td>
                                                            <td>
                                                                <div className="flex row">
                                                                    <div>
                                                                        {
                                                                            _data.status === "wait" && !_data.transferBank ?
                                                                                <div className={"text-muted"}>รอ</div>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === "pending" && _data.transferBank ?
                                                                                <div><span style={{ color: 'orange' }}>รอยืนยัน</span>
                                                                                    {
                                                                                        _data.transferPrice ?
                                                                                            <span className="badge badge-info salary-custom-badge" style={{ marginLeft: 5 }}>
                                                                                                ฿ {Helper.numberFormat(_data.transferPrice)}
                                                                                            </span>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === "success" ?
                                                                                <div><span style={{ color: 'green' }}>โอนแล้ว</span>
                                                                                    {
                                                                                        _data.transferPrice ?
                                                                                            <span className="badge badge-success salary-custom-badge" style={{ marginLeft: 5 }}>
                                                                                                ฿ {Helper.numberFormat(_data.transferPrice)}
                                                                                            </span>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                                : null
                                                                        }
                                                                        {/* {
                                                                            !_data.transferBank ?
                                                                                <>
                                                                                    <div>ไม่พบธนาคาร</div>
                                                                                </>
                                                                                : null
                                                                        } */}
                                                                    </div>


                                                                    {
                                                                        _data.status === "pending" && _data.bank ?
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                {/* <button className={"btn btn-default"}
                                                                                    style={{ marginRight: 5 }}
                                                                                    onClick={() => {
                                                                                        Helper.reactToDom(window.$('body'),
                                                                                            <AddSalaryForm
                                                                                                mode="edit"
                                                                                                defaultPrice={this.state.salary.minPrice}
                                                                                                data={_data}
                                                                                                id={this.props.id}
                                                                                                onCancel={() => {
                                                                                                    this.setState({
                                                                                                        is_absolute_loading: true
                                                                                                    }, () => {
                                                                                                        this.fetch();
                                                                                                    })
                                                                                                }}
                                                                                                onSuccess={async (e) => {
                                                                                                    this.setState({
                                                                                                        is_absolute_loading: true
                                                                                                    }, () => {
                                                                                                        this.fetch();
                                                                                                    })
                                                                                                }}
                                                                                            />)
                                                                                    }}
                                                                                >
                                                                                    แก้ไข
                                                                                </button> */}

                                                                                <button
                                                                                    ref={(ref) => {
                                                                                        let node = window.$(ref);
                                                                                        node.off().on('click', () => {
                                                                                            if (node.hasClass('loading')) { return; }
                                                                                            node.addClass('loading')
                                                                                            Helper.confirmModal({
                                                                                                title: "ยืนยันยกเลิกการโอน",
                                                                                                description: "ต้องการยืนยันยกเลิกการโอนนี้หรือไม่ ?",
                                                                                                onConfirm: async () => {
                                                                                                    this.cancelOrder(_data, {
                                                                                                        clearButton: () => {
                                                                                                            node.removeClass('loading')
                                                                                                        }
                                                                                                    })
                                                                                                },
                                                                                                onCancel: () => {
                                                                                                    node.removeClass('loading')
                                                                                                }
                                                                                            });
                                                                                        })

                                                                                    }}
                                                                                    className={"btn btn-danger"}
                                                                                    type={"button"}
                                                                                >ยกเลิก</button>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>


                                                            </td>
                                                            <td>
                                                                <div className="flex row">
                                                                    {
                                                                        _data.status === "wait" && _data.bank ?
                                                                            <button className={"btn btn-default"}
                                                                                style={{ marginRight: 5 }}
                                                                                onClick={() => {
                                                                                    Helper.reactToDom(window.$('body'),
                                                                                        <AddSalaryForm
                                                                                            mode="create"
                                                                                            defaultPrice={this.state.salary.minPrice}
                                                                                            data={_data}
                                                                                            id={this.props.id}
                                                                                            onSuccess={async () => {
                                                                                                this.setState({
                                                                                                    is_absolute_loading: true
                                                                                                }, () => {
                                                                                                    this.fetch();
                                                                                                })
                                                                                            }}
                                                                                        />)
                                                                                }}
                                                                            >โอนเงิน</button>
                                                                            : null
                                                                    }
                                                                    {
                                                                        _data.status === "pending" && _data.bank ?
                                                                            <button className={"btn btn-success"}
                                                                                style={{ marginRight: 5 }}
                                                                                onClick={() => {
                                                                                    Helper.reactToDom(window.$('body'),
                                                                                        <SalaryDetail
                                                                                            mode="confirm"
                                                                                            defaultPrice={this.state.salary.minPrice}
                                                                                            data={_data}
                                                                                            id={this.props.id}
                                                                                            salary={this.state.salary}
                                                                                            onSuccess={async () => {
                                                                                                this.setState({
                                                                                                    is_absolute_loading: true
                                                                                                }, () => {
                                                                                                    this.fetch();
                                                                                                })
                                                                                            }}
                                                                                            adminUid={this.props.adminUid}
                                                                                        />)
                                                                                }}
                                                                            >
                                                                                ยืนยัน
                                                                            </button>
                                                                            : null
                                                                    }
                                                                    {
                                                                        _data.status !== "wait" ?
                                                                            <button className={"btn btn-default"}
                                                                                style={{ marginRight: 5 }}
                                                                                onClick={() => {
                                                                                    Helper.reactToDom(window.$('body'),
                                                                                        <SalaryDetail
                                                                                            data={_data}
                                                                                        />)
                                                                                }}
                                                                            >
                                                                                ดูข้อมูล
                                                                            </button>
                                                                            : null
                                                                    }
                                                                    {
                                                                        _data.status === "wait" ?
                                                                            <button className={"btn btn-danger"}
                                                                                style={{ marginRight: 5 }}
                                                                                ref={(ref) =>
                                                                                    window.$(ref).on('click', () => {
                                                                                        if (window.$(ref).hasClass("loading")) { return; }
                                                                                        window.$(ref).addClass("loading");
                                                                                        Helper.confirmModal({
                                                                                            title: "ยืนยันลบรายการ",
                                                                                            description: "ต้องการลบนายหน้าออกจาก รายการ" + this.state.salary.title + ' หรือไม่ ?',
                                                                                            onConfirm: async () => {
                                                                                                this.delete(_data, {
                                                                                                    onError: () => {
                                                                                                        window.$(ref).removeClass('loading')
                                                                                                    }
                                                                                                });
                                                                                            },
                                                                                            onCancel: () => {
                                                                                                window.$(ref).removeClass('loading')
                                                                                            },
                                                                                            submit_text: 'ลบ'
                                                                                        })
                                                                                    })
                                                                                }
                                                                            >
                                                                                ลบ
                                                                            </button>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class AddSalaryForm extends React.Component {
    constructor(props) {
        super(props);
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        let defaultPrice = this.props.data.defaultPrice || 0;
        if (props.mode === 'edit') {
            defaultPrice = this.props.data.transferPrice;
        }
        let now_date = new Date();
        this.state = {
            day: now_date.getDate(),
            month: now_date.getMonth(),
            year: now_date.getFullYear(),
            date: now_date.getTime(),
            years: years,
            is_loading: true,
            selected_books: [],
            provider_books: [],
            file_stack: [],
            remove_image: [],
            image_paths: [],
            uploaded_images: [],
            vat_price: 0,
            total_price: defaultPrice || 0,
            price: defaultPrice || 0,
            vat: 0 //3,
        }
    }

    componentDidMount() {
        this.fetch()
    }

    async fetch() {
        let provider_books = [];
        let image_paths = [];
        let selected_books = [];

        const res = await agentSalaryService.getBookWithoutSalary(this.props.data.id)
        provider_books = res.data;
        if (this.props.data?.slipImageURL?.length > 0) {
            let index = 0;
            for (const image of this.props.data.slipImageURL) {
                image_paths.push({
                    imageURL: image,
                    storagePath: this.props.data.slipUrl[index]
                })
                index++;
            }
        }
        if (this.props.data.bookSelected && this.props.data.bookSelected.length > 0) {
            selected_books = [...this.props.data.bookSelected];
        }

        this.setState({
            provider_books,
            image_paths: image_paths,
            selected_books: selected_books,
            file_stack: [],
            remove_image: [],
            uploaded_images: [],
            is_loading: false,
        })
    }

    readURL(input) {
        if (input.files && input.files.length > 0) {
            let prepare_file_stack = [...this.state.file_stack];
            for (var key of Object.keys(input.files)) {
                prepare_file_stack.push(input.files[key]);
            }
            let file_stack = [];
            file_stack = [...prepare_file_stack]
            // file_stack.push(prepare_file_stack[prepare_file_stack.length - 1]);
            this.setState({
                file_stack: file_stack
            }, () => {
                this.$file.val('');
            })
        }
    }


    async cancelPay() {
        let uid = this.props.data.id;
        let data = {}
        // data.slipUrl = null;
        // data.priceAfterTransfer = this.props.data.defaultPrice;
        // data.slipImageURL = null;
        // data.status = 'wait';
        // data.transferPrice = 0;
        // data.note = null;
        // data.bookingPaid = [];
        // data.bookSelected = [];
        // data.allImages = [];
        Helper.confirmModal({
            title: "ยืนยันยกเลิกการโอน",
            description: "ต้องการยืนยันยกเลิกการโอนนี้หรือไม่ ?",
            onConfirm: async () => {
                // for (const image of this.state.image_paths) {
                //     await storage.ref(image.storagePath).delete();
                // }
                // await db.doc('salary/' + this.props.id + '/provider/' + uid).update({ ...data });
                // if (this.props.onSuccess) {
                //     this.props.onSuccess();
                // }
                // if (this.props.data.bookSelected) {
                //     for (const bookSelected of this.props.data.bookSelected) {
                //         for (const image of bookSelected.images) {
                //             await storage.ref(image.storagePath).delete();
                //         }
                //     }
                // }
                this.$modal.modal('hide');
            },
            onCancel: () => {
                this.$submit.removeClass('loading')
            }
        });

    }

    async clearStorageImage() {

        for (const uploaded_image of this.state.uploaded_images) {
            await storage.ref().child(uploaded_image.storagePath).delete();
        }
        let selected_books = [...this.state.selected_books];
        let index = 0;
        for (const select_book of this.state.selected_books) {
            selected_books[index].uploaded_images = [];
            if (select_book.uploaded_images) {
                for (const uploaded_image of select_book.uploaded_images) {
                    await storage.ref().child(uploaded_image.storagePath).delete();
                }
            }
            index++;
        }
        this.setState({
            is_loading: false,
            file_stack: [],
            remove_image: [],
            image_paths: [],
            uploaded_images: [],
            selected_books: selected_books,
            data: false,
        }, () => {
            this.$submit.removeClass('loading');
            this.$form.find('button').attr('disabled', false);
        })
    }


    async uploadFile(upload_index, data, options) {
        let file = this.state.file_stack[upload_index];
        const filename = Helper.setNewImageFileName(file);
        let reszieFile = await Helper.resizeImageFn(file, { maxWidth: 600, maxHeight: 600 });
        var uploadTask = storage.ref().child('agentSalary/' + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {

        }, async (error) => {
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            this.clearStorageImage();
            return;
        }, async () => {
            let url = 'agentSalary/' + filename;
            const photoURL = await storage.ref(url).getDownloadURL();
            let uploaded_images = [...this.state.uploaded_images];
            uploaded_images.push({
                imageURL: photoURL,
                storagePath: url,
            })
            this.setState({
                uploaded_images: uploaded_images,
            }, async () => {
                upload_index++;
                if (upload_index >= this.state.file_stack.length) {

                    if (this.props.mode === 'create') {
                        this.create(data);
                    }
                    if (this.props.mode === 'edit') {
                        this.update(data);
                    }
                    return;
                }
                if (upload_index < this.state.file_stack.length) {
                    this.uploadFile(upload_index, data);
                }
            })
        });
    }

    async create(_data) {

        let data = Helper.getFormInputObject(this.$form);
        data.priceAfterTransfer = parseFloat(this.props.data.defaultPrice) - parseFloat(data.transferPrice);
        data.allImages = [];
        data.slipUrl = [];
        data.slipImageURL = [];
        if (this.state.uploaded_images.length > 0) {
            for (const image of this.state.uploaded_images) {
                data.slipImageURL.push(image.imageURL)
                data.slipUrl.push(image.storagePath);
                data.allImages.push({
                    imageURL: image.imageURL,
                    storagePath: image.storagePath,
                })
            }
        }
        data.status = 'pending';
        data.transferPrice = parseFloat(data.transferPrice);
        data.transferAt = new Date().getTime();
        data.bookSelected = []
        if (this.state.selected_books.length > 0) {
            for (const book of this.state.selected_books) {
                data.bookSelected.push(book.bookUid)//bookUid
            }
        }
        let res = await agentSalaryService.pay({ agentUid: this.props.data.id, salaryAgentUid: this.props.id, ...data });
        // call api for save transfer payment
        // await db.doc('salary/' + this.props.id + '/provider/' + this.props.data.providerUID).update({ ...data });  // example data
        // console.log(res)
        if (res.code == 200) {
            // if success
            if (this.props.onSuccess) {
                this.props.onSuccess();
            }
            window.$(document).Toasts('create', {
                title: 'บันทึกการโอนเงินสำเร็จ',
                body: 'บันทึกการโอนเงินสำเร็จ กรุณากดปุ่ม "ยืนยัน" เพื่อทำการยืนยันการโอนเงิน',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.$modal.modal('hide');
            return;
        }
        else {
            // // if error
            let error_message = "เกิดข้อผิดพลายกรุณาลองใหม่อีกครั้ง"
            if (res
                && res.data
                && res.data.code === -2
            ) {
                error_message = "ไม่มีบัญชีธนาคาร";
            }
            window.$(document).Toasts('create', {
                title: 'บันทึกการโอนเงินไม่สำเร็จ',
                body: error_message,
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submit.removeClass('loading');
            this.$form.find('button').attr('disabled', false);
        }


    }

    async update(_data) {
        let data = Helper.getFormInputObject(this.$form);
        data.priceAfterTransfer = parseFloat(this.props.data.defaultPrice) - parseFloat(data.transferPrice);
        data.status = 'pending';
        data.transferPrice = parseFloat(data.transferPrice);
        data.transferAt = new Date().getTime();

        data.bookSelected = []
        if (this.state.selected_books.length > 0) {
            for (const book of this.state.selected_books) {
                data.bookSelected.push(book.bookUid)//bookUid
            }
        }
        data.slipUrl = [];
        data.slipImageURL = [];

        let uploaded_images = [...this.state.uploaded_images, ...this.state.image_paths];
        data.allImages = []

        if (uploaded_images.length > 0) {
            for (const image of uploaded_images) {
                data.slipImageURL.push(image.imageURL)
                data.slipUrl.push(image.storagePath)
                data.allImages.push({
                    imageURL: image.imageURL,
                    storagePath: image.storagePath,
                })
            }
        }

        if (this.state.remove_image && this.state.remove_image.length > 0) {
            for (const remove_img of this.state.remove_image) {
                storage.ref().child(remove_img).delete();
            }
        }

        data.status = 'pending';
        data.transferPrice = parseFloat(data.transferPrice);
        data.transferAt = new Date().getTime();
        agentSalaryService.pay({ agentUid: this.props.data.id, salaryAgentUid: this.props.id, ...data });
        // call api for update
        // await db.doc('salary/' + this.props.id + '/provider/' + this.props.data.providerUID).update({ ...data });
        if (this.props.onSuccess) {
            this.props.onSuccess();
        }
        window.$(document).Toasts('create', {
            title: 'บันทึกการโอนเงินสำเร็จ',
            body: 'บันทึกการโอนเงินสำเร็จ กรุณากดปุ่ม "ยืนยัน" เพื่อทำการยืนยันการโอนเงิน',
            icon: 'fas fa-check',
            autohide: true,
            delay: 3000,
            class: "bg-success",
        })
        this.$modal.modal('hide');
        // return;
        // // if error
        // let error = {}; // mockup
        // let error_message = "เกิดข้อผิดพลายกรุณาลองใหม่อีกครั้ง"
        // if (error.response
        //     && error.response.data
        //     && error.response.data.code === -2
        // ) {
        //     error_message = "ไม่มีบัญชีธนาคาร";
        // }
        // window.$(document).Toasts('create', {
        //     title: 'บันทึกการโอนเงินไม่สำเร็จ',
        //     body: error_message,
        //     icon: 'fas fa-times',
        //     autohide: true,
        //     delay: 3000,
        //     class: "bg-danger",
        // })
        // this.$submit.removeClass('loading');
        // this.$form.find('button').attr('disabled', false);
    }
    render() {
        let _data = this.props.data;
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard="false" data-backdrop="static"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        if (this.props.onCancel) {
                            this.props.onCancel();
                        }
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog modal-lg"
                    style={{ maxWidth: 1000 }}
                    role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) {
                            return;
                        }
                        this.$submit.addClass('loading');
                        this.$form.find('button').attr('disabled', true);
                        let wallet = _data.agent && _data.agent.wallet ? _data.agent.wallet : 0;
                        if (parseFloat(this.state.price) > parseFloat(wallet)) {
                            window.$(document).Toasts('create', {
                                title: 'การโอนเงินไม่สำเร็จ',
                                body: "จำนวนเงินเกิน",
                                icon: 'fas fa-times',
                                autohide: true,
                                delay: 3000,
                                class: "bg-danger",
                            })
                            this.$submit.removeClass('loading');
                            this.$form.find('button').attr('disabled', false);
                            return;
                        }
                        let is_upload_file_already = true;
                        if (!is_upload_file_already) {
                            window.$(document).Toasts('create', {
                                title: 'การโอนเงินไม่สำเร็จ',
                                body: "กรุณาอัพโหลดสลิป",
                                icon: 'fas fa-times',
                                autohide: true,
                                delay: 3000,
                                class: "bg-danger",
                            })
                            this.$submit.removeClass('loading')
                            this.$form.find('button').attr('disabled', false);
                            return;
                        }
                        if (this.state.file_stack.length > 0) {
                            this.uploadFile(0, { ..._data });
                        }
                        if (this.state.file_stack.length === 0) {
                            if (this.props.mode === 'create') {
                                this.create(_data);
                            }
                            if (this.props.mode === 'edit') {
                                this.update(_data);
                            }
                        }

                    }}
                >
                    {
                        this.state.is_loading ?
                            <div className="absolute_loading">
                                <div className="ui active inline loader" ></div>
                            </div>
                            : null
                    }
                    <div className="modal-content">
                        <div className="modal-header" style={{ display: "flex", alignItems: 'center' }}>
                            <h4 className="modal-title" id="myModalLabel">
                                {
                                    this.props.mode === "edit" ?
                                        "แก้ไขการโอนเงิน" : "โอนเงิน"
                                }
                            </h4>
                            <div style={{ marginLeft: 'auto' }}>
                                {_data.prefix} {_data.firstName} {_data.lastName}
                            </div>
                        </div>

                        <div className="modal-body">
                            <div className="row">
                                <div className="col-5">
                                    <div className={"form-group"} style={{ textAlign: 'center' }}>
                                        <label>กรอกจำนวนเงินที่ต้องการโอน</label>
                                        <input name={"transferPrice"}
                                            className={"form-control"}
                                            style={{ textAlign: 'center' }}
                                            defaultValue={this.state.price || 0}
                                            onChange={(e) => {
                                                let price = 0;
                                                let vat_price = 0;
                                                let total_price = 0;
                                                if (!isNaN(e.target.value)) {
                                                    price = parseFloat(e.target.value);
                                                } else {
                                                    price = this.state.price;
                                                    window.$(`input[name="transferPrice"]`).val(price)
                                                }

                                                let wallet = _data.agent && _data.agent.wallet ? _data.agent.wallet : 0;
                                                if (parseFloat(price) > parseFloat(wallet)) {
                                                    price = parseFloat(wallet);
                                                    window.$(`input[name="transferPrice"]`).val(price)
                                                }
                                                vat_price = (parseFloat(price) * this.state.vat) / 100;
                                                total_price = parseFloat(price) - parseFloat(vat_price);

                                                this.setState({
                                                    price: price,
                                                    vat_price: vat_price,
                                                    total_price: total_price,
                                                })
                                            }}
                                        />

                                    </div>
                                    <div className={"row"} style={{ justifyContent: "center" }}>
                                        {
                                            this.state.vat > 0 ?
                                                <div className={"col-sm-12 col-lg-6"}>
                                                    <div className={"salary-transfer-price"}>
                                                        <div className={"title"}>หักภาษี {this.state.vat}%</div>
                                                        <div className={"price"}>
                                                            {Helper.numberFormat(this.state.vat_price)}
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        <div className={"col-sm-12 col-lg-6"}>
                                            <div className={"salary-transfer-price"}>
                                                <div className={"title"}>จำนวนเงินที่ต้องโอน</div>
                                                <div className={"price"} style={{ color: 'green' }}>
                                                    {Helper.numberFormat(this.state.total_price)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="divider" style={{ margin: 0 }} />
                                    <div className="modal-body">
                                        <div className={"form-group"}>
                                            <label>รูปภาพเพิ่มเติม</label>
                                            <input name="image"
                                                type={"file"}
                                                accept="image/*"
                                                ref={(ref) => this.$file = window.$(ref)}
                                                style={{ display: 'none' }}
                                                id="file_image"
                                                multiple="multiple"
                                                onChange={(e) => {
                                                    if (this.$file.parent().find('.btn').hasClass('loading')) {
                                                        return;
                                                    }
                                                    this.readURL(this.$file[0]);
                                                }}
                                            />
                                            <label htmlFor="file_image"
                                                type={"button"}
                                                className="btn btn-default"
                                                style={{ width: '100%', marginBottom: 0 }}
                                            >อัพโหลด</label>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                {
                                                    this.state.file_stack
                                                        && this.state.file_stack.length > 0 ?
                                                        this.state.file_stack.map((file_stack, file_stack_i) => {
                                                            var reader = new FileReader();
                                                            reader.onload = (e) => {
                                                                window.$('#images_file_upload_' + file_stack_i).html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
                                                            };
                                                            reader.readAsDataURL(file_stack);
                                                            return (
                                                                <div className="col-6" key={file_stack_i} >
                                                                    <div className="mutiple_image_frame card" style={{ height: 'auto' }}>
                                                                        <div className="promotion-delete">
                                                                            <button className="btn btn-danger"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    let file_stack = [...this.state.file_stack];
                                                                                    file_stack.splice(file_stack_i, 1);
                                                                                    this.setState({
                                                                                        file_stack: file_stack
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-trash-alt"></i>
                                                                            </button>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div id={"images_file_upload_" + file_stack_i} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                        : null
                                                }
                                                {
                                                    this.state.image_paths
                                                        && this.state.image_paths.length > 0 ?
                                                        this.state.image_paths.map((photoPath, photoPath_index) => {

                                                            return (
                                                                <div className="col-6" key={photoPath_index} >
                                                                    <div className="mutiple_image_frame card" style={{ height: 'auto' }}>
                                                                        <div className="promotion-delete">
                                                                            <button className="btn btn-danger"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    let remove_image = [...this.state.remove_image];
                                                                                    let image_paths = [...this.state.image_paths];
                                                                                    image_paths.splice(photoPath_index, 1);
                                                                                    remove_image.push(photoPath.storagePath);
                                                                                    this.setState({
                                                                                        remove_image: remove_image,
                                                                                        image_paths: image_paths,
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-trash-alt"></i>
                                                                            </button>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <img src={photoPath.imageURL} alt={"EventAndPromotion"} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className={"form-group"}>
                                            <label>บันทึกเพิ่มเติม</label>
                                            <textarea className="form-control" name="note" defaultValue={this.props.data.note}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className={this.state.selected_books.length > 0 ? "form-group" : null}>
                                        <div className="row">
                                            <div className="col-6 full-date">
                                                <label>เลือกเดือน</label>
                                                <div className="flex row merge-selectbox">
                                                    <select className={"form-control"}
                                                        style={{ flex: 1 }}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                month: parseFloat(e.target.value),
                                                            })
                                                        }}
                                                        defaultValue={this.state.month}
                                                    >
                                                        {
                                                            window.months_th.map((month, month_i) => (
                                                                <option value={month_i} key={month_i}>{month}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <select className={"form-control "}
                                                        style={{ flex: 1 }}
                                                        name={"year"}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                year: parseFloat(e.target.value),
                                                            })
                                                        }}
                                                        defaultValue={this.state.year}
                                                    >
                                                        {
                                                            this.state.years.map((years) => (
                                                                <option value={years} key={years}>{years}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <label>โอนเงินของ Booking</label>
                                                {
                                                    this.state.provider_books
                                                        && this.state.provider_books.length > 0 ?
                                                        <select
                                                            ref={(ref) => {
                                                                window.$(ref).select2();
                                                                window.$(ref).off().on('select2:select', (e) => {
                                                                    let selected_uid = e.target.value;
                                                                    if (!selected_uid) { return; }
                                                                    let get_selected_books = this.state.provider_books.filter((e) => { return e.id === selected_uid });
                                                                    let selected_books = [...this.state.selected_books];
                                                                    if (get_selected_books.length > 0) {
                                                                        selected_books.push({
                                                                            ...get_selected_books[0],
                                                                            bookUid: get_selected_books[0].id
                                                                        });
                                                                    }
                                                                    this.setState({
                                                                        selected_books: selected_books
                                                                    })
                                                                });
                                                            }}
                                                            className="form-control"
                                                            style={{ width: '100%' }}
                                                            value={''}
                                                        >
                                                            <option>เลือก BookNo</option>
                                                            {
                                                                this.state.provider_books.map((book, book_i) => {
                                                                    let count = this.state.selected_books.filter((e) => { return e.bookUid === book.id });
                                                                    if (count.length > 0) { return null }
                                                                    let date = new Date(book.bookedAt);
                                                                    if (date.getMonth() !== this.state.month
                                                                        || date.getFullYear() !== this.state.year) {
                                                                        return null
                                                                    }
                                                                    return (
                                                                        <option key={book_i} value={book.id}>{book.bookOn} : ({Helper.getDateNumber(book.bookedAt)})</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        : <input type="text" className="form-control" readOnly={true} disabled={true} value="ไม่มีข้อมูล Books ของแม่บ้าน" />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.selected_books.length > 0 ?
                                            <div className="row">
                                                {
                                                    this.state.selected_books.map((book, book_i) => {
                                                        return (
                                                            <div className="col-6" key={book_i}>
                                                                <div className="card" >
                                                                    <div className="card-header">
                                                                        <h3 className="card-title">
                                                                            <a href={window.origin + '/booking/' + book.bookUid} target={"_blank"}><i className="fas fa-clipboard-check" /> {book.bookOn}</a>
                                                                        </h3>
                                                                        <div className="card-tools">
                                                                            <button type="button" className="btn btn-tool"

                                                                                onClick={() => {
                                                                                    let selected_books = this.state.selected_books.filter((e) => { return e.bookUid !== book.bookUid });
                                                                                    this.setState({
                                                                                        selected_books: selected_books
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-times" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p className='mb-0'><i className="far fa-calendar-alt" /> {Helper.getDateThai(book.bookedAt, { is_show_time: true })}</p>
                                                                    </div>

                                                                    {
                                                                        book.adjustSalary && book.adjustSalary && book.adjustSalary.length > 0 ?
                                                                            <div className='card-footer mb-0'>
                                                                                <p
                                                                                    onClick={() => {
                                                                                        window.$(`#bookAdjustSalary` + book.bookOn).toggle();
                                                                                    }}
                                                                                    className={"d-flex mb-1"}>
                                                                                    <strong>ข้อมูลการหักเงิน</strong>
                                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                                        <i className='fas fa-sort-down' />
                                                                                    </div>
                                                                                </p>
                                                                                <div id={`bookAdjustSalary` + book.bookOn} style={{ display: `none` }}>
                                                                                    {
                                                                                        book.adjustSalary.map((item, i) => {
                                                                                            return (
                                                                                                <div className={"flex"} key={i}>
                                                                                                    {i > -1 ? <div className="divider"></div> : null}
                                                                                                    <div className='d-flex'>
                                                                                                        <div>
                                                                                                            <div>
                                                                                                                <label className='mb-0'>{item.type == 'special_wallet' ? 'เพิ่มเงินพิเศษ' : 'หักเงิน'}</label> :  {' '}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                                                            {Helper.numberFormat(item.amount, { decimal: 2 })} บาท
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <label>หมายเหตุ</label> :  {' '}
                                                                                                        {item.reason}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="flex row" style={{ width: '100%' }}>
                                {
                                    this.props.mode === "edit" ?
                                        <div>
                                            <button className={"btn btn-danger"} type={"button"} onClick={() => {
                                                this.cancelPay();
                                            }}>ยกเลิก</button>
                                        </div>
                                        : null
                                }
                                <div style={{ marginLeft: 'auto' }}>
                                    <button type="button"
                                        style={{ marginRight: 5 }}
                                        className="btn btn-default"
                                        onClick={() => {
                                            let $submit = this.$form.find('button[type="submit"]');
                                            $submit.removeClass('loading');
                                            this.$modal.modal('hide');
                                        }}
                                    >
                                        ปิด
                                    </button>
                                    <button className={"btn btn-primary"} type={"submit"}>บันทึก</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}



class SalaryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: false,
            is_absolute_loading: false,
            selected_books: [],
            provider_books: [],
            vat_price: 0,
            total_price: this.props.data.transferPrice || 0,
            price: this.props.data.transferPrice || 0,
            vat: 0 //3,
        }
    }


    readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.$file.parent().find('.button').text('เปลี่ยนรูป');
                window.$('#show_image').html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
                window.$('#show_image').html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    async confirmPay() {
        let res = await agentSalaryService.payConfirm({ agentUid: this.props.data.agentUid, salaryAgentUid: this.props.id });
        // console.log(res)
        if (res.code == 200) {
            window.$(document).Toasts('create', {
                title: 'การโอนเงินสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 10000,
                class: "bg-success",
            })
            if (this.props.onSuccess) {
                this.props.onSuccess();
            }
            this.$modal.modal('hide');
        } else {
            let error_message = "เกิดข้อผิดพลายกรุณาลองใหม่อีกครั้ง"
            // alert(error_message);
            window.$(document).Toasts('create', {
                title: 'การโอนเงินไม่สำเร็จ',
                body: error_message,
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submit.removeClass('loading')
        }
    }


    render() {
        let _data = this.props.data;
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard={this.props.mode === 'confirm' ? "false" : "true"} data-backdrop={this.props.mode === 'confirm' ? "static" : "true"}
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >

                <form className="modal-dialog modal-lg"
                    style={{ maxWidth: 1000 }}
                    role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) {
                            return;
                        }
                        this.$submit.addClass('loading');
                        this.confirmPay()

                    }}
                >
                    {
                        this.state.is_loading ?
                            <div className="absolute_loading">
                                <div className="ui active inline loader" ></div>
                            </div>
                            : null
                    }
                    <div className="modal-content">
                        <div className="modal-header" style={{ display: "flex", alignItems: 'center' }}>
                            <h4 className="modal-title" id="myModalLabel">
                                {
                                    this.props.mode === 'confirm' ? "ยืนยันการโอนเงิน" : "ดูข้อมูลการโอนเงิน"
                                }
                            </h4>
                            <div style={{ marginLeft: 'auto' }}>
                                {_data.name}
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-5">
                                    <div className={"row"} style={{ justifyContent: "center" }}>
                                        {
                                            this.state.vat > 0 ?
                                                <div className={"col-sm-12 col-lg-6"}>
                                                    <div className={"salary-transfer-price"}>
                                                        <div className={"title"}>หักภาษี {this.state.vat}%</div>
                                                        <div className={"price"}>
                                                            {Helper.numberFormat(this.state.vat_price)}
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        <div className={"col-sm-12 col-lg-6"}>
                                            <div className={"salary-transfer-price"}>
                                                <div className={"title"}>จำนวนเงิน</div>
                                                <div className={"price"} style={{ color: 'green' }}>
                                                    {Helper.numberFormat(this.state.total_price)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="divider" style={{ margin: 0 }} />
                                    <div className="modal-body">
                                        <div>
                                            <label>รูปภาพเพิ่มเติม</label>
                                            <input name="image"
                                                type={"file"}
                                                ref={(ref) => this.$file = window.$(ref)}
                                                style={{ display: 'none' }}
                                                id="file_image_bank"
                                                onChange={(e) => {
                                                    this.readURL(this.$file[0]);
                                                }}
                                            />
                                            <div id="show_image" className="row">
                                                {
                                                    this.props.data
                                                        && this.props.data.image_paths
                                                        && this.props.data.image_paths.length > 0 ?
                                                        this.props.data.image_paths.map((image, image_i) => {
                                                            return (
                                                                <div className={this.props.data.image_paths.length === 1 ? "col-12" : "col-6"} key={image_i}>
                                                                    <div className="mutiple_image_frame card" style={{ height: 'auto' }}>
                                                                        <div className="card-body">
                                                                            <div>
                                                                                <img src={image.imageURL} alt={"PromotionPic"} style={{ maxWidth: '100%' }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : <div className="col-12"><p>-</p></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.props.data.note ?
                                            <>
                                                <div className="divider" style={{ margin: 0 }} />
                                                <div className="modal-body">
                                                    <div>
                                                        <label>บันทึกเพิ่มเติม</label>
                                                        <div>
                                                            {this.props.data.note || '-'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }
                                </div>

                                <div className="col-7">
                                    {
                                        this.props.data.bookSelected
                                            && this.props.data.bookSelected.length > 0 ?
                                            <div className="row">
                                                {
                                                    this.props.data.bookSelected.map((book, book_i) => {
                                                        return (
                                                            <div className="col-6" key={book_i}>
                                                                <div className="card" >
                                                                    <div className="card-header">
                                                                        <h3 className="card-title"><Link to={'/booking/' + book.bookUid} onClick={() => { this.$modal.modal('hide') }}><i className="fas fa-clipboard-check" /> {book.bookOn}</Link></h3>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p><i className="far fa-calendar-alt" /> {Helper.getDateThai(book.bookedAt, { is_show_time: true })}</p>
                                                                        <div className="row">
                                                                            {
                                                                                book.images && book.images.length > 0 ?
                                                                                    book.images.map((image, image_i) => {
                                                                                        return (
                                                                                            <div className="col-6" key={image_i} >
                                                                                                <div className="mutiple_image_frame" style={{ height: 'auto' }}>
                                                                                                    <a href={image.imageURL} data-fancybox={"book_slip_" + book_i}>
                                                                                                        <img src={image.imageURL} alt={"BookingSlipImg"} />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }) : null
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : <div className="text-center">ไม่มีข้อมูล Book</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {
                                this.props.mode === "confirm" ?
                                    <p>*กรุณาตรวจสอบข้อมูลให้ครบถ้วนและถูกต้องก่อนทำการยืนยันการโอนเงิน</p>
                                    : null
                            }
                            <button type="button"
                                className="btn btn-default"
                                onClick={() => {
                                    let $submit = this.$form.find('button[type="submit"]');
                                    $submit.removeClass('loading');
                                    this.$modal.modal('hide');
                                }}
                            >
                                {
                                    this.props.mode === "confirm" ? "ยกเลิก" : "ปิด"
                                }
                            </button>
                            {
                                this.props.mode === "confirm" ?
                                    <button className={"btn btn-primary"} type={"submit"}>ยืนยัน</button>
                                    : null
                            }
                        </div>

                    </div>
                </form>
            </div>
        );
    }
}