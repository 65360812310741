import React, { Component } from 'react'
import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Helper } from '../Helper';

export default class ReportProviderWallet extends Component {
    constructor() {
        super();
        this.state = {
            providers: false,
        }
    }

    getData() {
        let datas = this.props.data;
        let providers = [];
        for (const data of datas) {
            let prepare = {
                providerUserUid: data.providerUserUid,
                providerUser: data.providerUser
            }
            let checkProvider = providers.findIndex((e) => e.providerUserUid == data.providerUserUid);
            if (checkProvider == -1) {
                providers.push(prepare)
            } else {
                console.log(data.providerUserUid)
            }
        }
        this.setState({
            providers: providers,
        }, () => {
            this._export_providers.save();
            this.$btn.removeClass('loading');
        })

    }

    render() {
        let date = new Date();
        return (
            <>
                {
                    this.state.providers ?
                        <ExcelExport
                            data={this.state.providers}
                            ref={exporter => this._export_providers = exporter}
                            fileName={`รายงานรายได้แม่บ้าน  ${Helper.getDateThai(parseFloat(this.props.start_date))} - ${Helper.getDateThai(parseFloat(this.props.end_date))} ณ วันที่ ${Helper.getDateThai(date.getTime())}`}
                        >
                            <ExcelExportColumn field="providerUserUid" title="providerUserUid" width={0} />
                            <ExcelExportColumn
                                field="providerUser.displayName"
                                title="แม่บ้าน"
                                width={200}
                            />
                            <ExcelExportColumn
                                field="providerUser.wallet"
                                title="wallet"
                                width={200}
                            />
                        </ExcelExport>
                        : null
                }
                <button className='btn btn-warning mr-5'
                    ref={(ref) => {
                        this.$btn = window.$(ref);
                        this.$btn.off().on('click', () => {
                            if (this.$btn.hasClass('loading')) { return };
                            this.$btn.addClass('loading');
                            this.getData();
                        })
                    }}
                >รายงานรายได้แม่บ้าน</button>
            </>
        )
    }
}
