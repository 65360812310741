import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import { db } from '../firebase';
// import { AmphurList } from './ProviderUsers'
import Pagination from '../Components/Pagination';
import thailand from "./../thailand.json";
const axios = require('axios').default;

export default class ServiceArea extends React.Component {
    constructor() {
        super();

        this.state = {
            is_loading: true,
            data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            userUID: Helper.getParameterByName('userUID') ? Helper.getParameterByName('userUID') : '',
            is_show_next_button: false,
            provinces_count: 0,
        }
    }

    componentDidMount() {
        this.fetchData();
        // console.log(this.state.provinces);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                userUID: Helper.getParameterByName('userUID') ? Helper.getParameterByName('userUID') : '',
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        let data = [];
        let prepare_data = [];
        let service_areaRef = await db.collection('/service_area').get();
        let service_area_data = await service_areaRef.docs;
        let service_area = await Promise.all(
            service_area_data.map(async (service_area_data) => {
                return {
                    ...service_area_data.data(),
                    code: service_area_data.id,
                    // amphoe: amphoe,
                    // provinces: provinces,
                }
            })
        );
        let provinces_count = 0;
        service_area.forEach((_data, _data_i) => {
            // console.log(_data);
            let match_search = 0;
            if (this.state.name && this.state.name !== "") {
                match_search = _data.name.th.indexOf(this.state.name);
                if (match_search !== -1) {
                    provinces_count++;
                    prepare_data.push({ ..._data });
                }
            } else {
                provinces_count++;
                prepare_data.push({ ..._data });
            }
        })
        data = prepare_data.filter((__data, __data_i) => {
            return __data_i < (this.state.limit * this.state.page)
                && __data_i > (((this.state.limit * this.state.page) - this.state.limit) - 1)
        })

        let data_add_details = await Promise.all(
            data.map(async (_data) => {
                let return_data = {
                    ..._data
                }
                let amphoe = [];
                let amphoeRef = await db.collection('/service_area/' + _data.code + '/amphoes').get();
                if (amphoeRef.docs.length > 0) {
                    amphoe = await Promise.all(
                        amphoeRef.docs.map((amphoe_data) => {
                            return {
                                ...amphoe_data.data(),
                                code: amphoe_data.id
                            }
                        })
                    );
                }
                let provinces = false;
                for (const [key, value] of Object.entries(thailand)) {
                    let amphoes = [];
                    if (key === _data.code) {
                        for (const [_key, _value] of Object.entries(value.amphoes)) {
                            amphoes.push({ code: _key, amphoe: _value.name.th });
                            // console.log(`${_key}: ${_value.name.th}`);
                        }
                        provinces = { code: key, province: value.name.th, amphoes: amphoes }
                        // console.log(`${key}: ${value.name.th}`);
                    }
                }
                return_data.amphoe = amphoe;
                return_data.provinces = provinces;
                return return_data;
            })
        );

        this.setState({
            data: data_add_details,
            provinces_count: provinces_count,
            is_loading: false
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการพื้นที่ให้บริการ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">จัดการพื้นที่ให้บริการ</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายการจังหวัดที่ให้บริการ</h3>
                                <div className={"card-right"}>
                                    <div style={{ marginRight: 10 }}>ทั้งหมด {this.state.provinces_count} จังหวัด</div>
                                </div>
                                <div className="card-tools">
                                    <form className="input-group input-group-sm" ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                page: 1,
                                                name: data.name,
                                            }, '/service-area')
                                            navigate(url)
                                        }}
                                    >
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากชื่อ" defaultValue={this.state.name} />

                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="flex row form-group">
                                    <div>
                                        <button className={"btn btn-primary"}
                                            onClick={() => {
                                                Helper.reactToDom(
                                                    window.$('body'),
                                                    <AddServiceArea
                                                        onSuccess={(id) => {
                                                            navigate('/service-area/edit/' + id)
                                                        }}
                                                    />
                                                )
                                            }}
                                        >
                                            เพิ่มพื้นที่ให้บริการ
                                            </button>
                                    </div>
                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: 300 }}>จังหวัด</th>
                                            <th>เขต/อำเภอ</th>
                                            <th style={{ width: 250 }}>หมายเหตุ</th>
                                            <th style={{ width: 180 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    let amphoe = [];
                                                    _data.amphoe.map((_amphoe, _amphoe_i) => {
                                                        amphoe.push(_amphoe.name.th)
                                                        return true
                                                    })
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                <div className={"flex row"}>
                                                                    <div>
                                                                        <div>{_data.name.th}</div>
                                                                        <div><i className={"fas fa-circle"} style={{ color: _data.isActive ? 'green' : 'red' }} /> {_data.isActive ? "เปิดใช้งาน" : "ระงับการใช้งาน"}</div>
                                                                        
                                                                        <div><i className={"fas fa-circle"} style={{ color: _data.isRegisterActive ? 'green' : 'red' }} /> {_data.isRegisterActive ? "เปิดรับสมัคร" : "ปิดรับสมัคร"}</div>
                                                                    </div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        <div>
                                                                            {
                                                                                _data.provinces.amphoes.length === _data.amphoe.length ?
                                                                                    "ครอบคลุมจังหวัด" : "เฉพาะเขต/อำเภอ"
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {_data.amphoe.length} เขต/อำเภอ
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="tag-label">
                                                                    {
                                                                        _data.amphoe ?
                                                                            _data.amphoe.map((_amphoe, _amphoe_i) => {
                                                                                // if (_amphoe_i < 3) {
                                                                                return (<div key={_amphoe_i}>{_amphoe.name.th}</div>)
                                                                                // }
                                                                                // return true
                                                                            })
                                                                            : null
                                                                    }
                                                                </div>
                                                                {/* {
                                                                    _data.amphoe.length > 3 ?
                                                                        <div className={"btn btn-default btn-sm"}
                                                                            onClick={() => {
                                                                                Helper.reactToDom(window.$('body'),
                                                                                    <AmphurList
                                                                                        amphoe={amphoe}
                                                                                        province={_data.name.th}
                                                                                    />
                                                                                )
                                                                            }}
                                                                        >
                                                                            ข้อมูล{_data.province === 'กรุงเทพมหานคร' ? 'เขต' : 'อำเภอ'}ทั้งหมด
                                                                        </div>
                                                                        : null
                                                                } */}
                                                            </td>
                                                            <td>
                                                                {_data.note}
                                                            </td>
                                                            <td>
                                                                <div className="flex row">
                                                                    <Link className="btn btn-default" to={"/service-area/" + _data.code} >ดูข้อมูล</Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.provinces_count}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}


class AddServiceArea extends React.Component {
    constructor() {
        super();
        let provinces = [];
        for (const [key, value] of Object.entries(thailand)) {
            let amphoes = [];

            for (const [_key, _value] of Object.entries(value.amphoes)) {
                amphoes.push({ code: _key, amphoe: _value.name.th });
                // console.log(`${_key}: ${_value.name.th}`);
            }
            provinces.push({ code: key, province: value.name.th, amphoes: amphoes })
            // console.log(`${key}: ${value.name.th}`);
        }
        this.state = {
            provinces: provinces,
        }
    }

    async AddServiceArea(data) {
        try {
            await axios.post(window.api_host + '/service-area', { provincesCode: data.provincesCode, isActive: true }).then(async (res) => {
                let res_data = res.data;
                if (res_data.code === 200) {
                    window.$(document).Toasts('create', {
                        title: 'เพิ่มพื้นที่บริการสำเร็จ',
                        body: 'ระบบได้ทำการเพิ่มจังหวัดเรียบร้อยแล้ว กรุณาเลือก เขต/อำเภอ ที่จะเปิดทำการให้บริการ',
                        icon: 'fas fa-check',
                        autohide: true,
                        delay: 3000,
                        class: "bg-success",
                    })
                    this.props.onSuccess(data.provincesCode[0]);
                    this.$modal.modal('hide');
                }
            });
        } catch (error) {
            return;
        }
    }

    render() {
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog"
                    role="document"
                    ref={(ref) => { this.$form = window.$(ref) }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) {
                            return;
                        }
                        let data = Helper.getFormInputObject(this.$form);
                        let provincesCode = [];
                        if (data.provincesCode) {
                            provincesCode.push(data.provincesCode);
                        }
                        let _data = {};
                        _data.provincesCode = provincesCode;
                        this.AddServiceArea(_data)
                        this.$submit.addClass('loading');
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header" style={{ display: "flex", alignItems: 'center' }}>
                            <h4 className="modal-title" id="myModalLabel">
                                เพิ่มพื้นที่ให้บริการ
                            </h4>
                            <div style={{ marginLeft: 'auto' }}>
                                กรุณาเลือกจังหวัด
                            </div>
                        </div>
                        <div className="modal-body">
                            <div >
                                <label>เลือกจังหวัด</label>
                                <select
                                    ref={(ref) => { window.$(ref).select2(); }}
                                    className={"form-control select2"}
                                    name={"provincesCode"}
                                    style={{ width: '100%' }}
                                >
                                    {
                                        this.state.provinces.map((provinces, provinces_i) => {
                                            return (
                                                <option value={provinces.code} key={provinces_i}>
                                                    {provinces.province}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default"
                                onClick={() => {
                                    let $submit = this.$form.find('button[type="submit"]');
                                    $submit.removeClass('loading');
                                    this.$modal.modal('hide');
                                }}
                            >ยกเลิก</button>
                            <button className={"btn btn-primary"} type={"submit"}>บันทึก</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}