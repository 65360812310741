import React from "react";
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import { db } from "../firebase";
import DatePicker from 'react-datepicker';
import CustomDateInput from "../Components/CustomDateInput";
import Pagination from "../Components/Pagination";
const axios = require('axios').default;
export default class LogsReport extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            page: 1,
            limit: 10,
            count_all: 0,
            logs: [],
            adminList: [],
            start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : false,
            end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : false,
            call_report_date: Helper.getParameterByName('call_report_date') ? Helper.getParameterByName('call_report_date') : false,
            adminUid: Helper.getParameterByName('adminUid') ? Helper.getParameterByName('adminUid') : false,
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                count_all: 0,
                logs: [],
                adminList: [],
                start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : false,
                end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : false,
                call_report_date: Helper.getParameterByName('call_report_date') ? Helper.getParameterByName('call_report_date') : false,
                adminUid: Helper.getParameterByName('adminUid') ? Helper.getParameterByName('adminUid') : false,
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                count_all: 0,
                logs: [],
                adminList: [],
                start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : false,
                end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : false,
                call_report_date: Helper.getParameterByName('call_report_date') ? Helper.getParameterByName('call_report_date') : false,
                adminUid: Helper.getParameterByName('adminUid') ? Helper.getParameterByName('adminUid') : false,
            }, () => {
                this.fetch();
            })
        }
    }
    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let adminList = [...this.state.adminList];
        if (adminList.length >= 0) {
            let adminRef = await db.collection('admin').get();
            if (!adminRef.empty) {
                for (const admin_doc of adminRef.docs) {
                    let adminData = admin_doc.data();
                    adminData.uid = admin_doc.id;
                    adminList.push(adminData);
                }
            }
        }
        let filter = {
            page: this.state.page,
            limit: this.state.limit,
        };
        if (this.state.start_date) { filter.start_date = parseFloat(this.state.start_date) + (7 * 3600 * 1000) }
        if (this.state.call_report_date) { filter.call_report_date = parseFloat(this.state.call_report_date) + (7 * 3600 * 1000) }
        if (this.state.end_date) { filter.end_date = parseFloat(this.state.end_date) + (7 * 3600 * 1000) }
        if (this.state.adminUid) { filter.adminUid = this.state.adminUid }
        let count_all = 0;
        let logs = [];
        let api_path = `${window.api_host}/logs-booking-report`;
        if (filter) {
            api_path += `?${window.$.param(filter)}`;
        }
        let res = await axios.get(api_path);
        if (res.status == 200) {
            logs = res.data.logs;
            count_all = res.data.count_all;
        }
        this.setState({
            logs: logs,
            count_all: count_all,
            adminList: adminList,
            is_loading: false
        })
    }


    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Report Logs</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">Report Logs</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-3" style={{ zIndex: 2 }}>
                                <form
                                    className="card"
                                    ref={(ref) => {
                                        this.$searchForm = window.$(ref)
                                        this.$filterSubmit = this.$searchForm.find('button[type="submit"]')
                                    }}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        let data = Helper.getFormInputObject(this.$searchForm);
                                        let url = Helper.getQueryStringLinkFromObject({
                                            ...data
                                        })
                                        navigate(url)
                                    }}
                                >
                                    {/* <div className="card-header">
                                        <div className="card-title">Filter</div>
                                    </div> */}
                                    <div className="card-body" >
                                        {
                                            this.state.is_loading ?
                                                <div className="ui active inline loader small" ></div>
                                                :
                                                <div className="row">

                                                    <div className="col-12 col-md-12 form-group">
                                                        <label>Admin</label>
                                                        <select className="form-control" name="adminUid" defaultValue={this.state.adminUid}>
                                                            <option value={""}>เลือก Admin</option>
                                                            {
                                                                this.state.adminList.map((admin, index) => {
                                                                    return <option value={admin.uid} key={index}>{admin.displayName}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-md-12 form-group">

                                                        <div className="d-flex" style={{ width: '100%' }}>
                                                            <label>Start</label>
                                                            {
                                                                this.state.start_date ?
                                                                    <div style={{ marginLeft: "auto" }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                start_date: false,
                                                                            })
                                                                        }}
                                                                    >
                                                                        <span className="link">ยกเลิก</span>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <DatePicker
                                                            disabled={this.state.is_loading}
                                                            onChange={date => {

                                                                let start = new Date(date);
                                                                start.setHours(0, 0, 0, 0);
                                                                this.setState({
                                                                    start_date: start.getTime()
                                                                }
                                                                    // , () => {
                                                                    //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                                    // }
                                                                )
                                                            }}
                                                            selectsStart
                                                            startDate={this.state.start_date || ''}
                                                            endDate={this.state.end_date}
                                                            locale="th-TH"
                                                            selected={this.state.start_date ? Helper.getDateToEdit(this.state.start_date) : false}
                                                            placeholderText={"วัน / เดือน / ปี"}
                                                            name={"start_date"}
                                                            dateFormat="MMMM d, yyyy 00:00:00"
                                                            withPortal
                                                            onCalendarOpen={() => { Helper.renderDate() }}
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled
                                                            }) => {
                                                                date = new Date(date);
                                                                let now = new Date();
                                                                // months_th
                                                                const years = [];
                                                                let i;
                                                                for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                                    years.push(i)
                                                                }
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            margin: 10,
                                                                            display: "flex",
                                                                            justifyContent: "center"
                                                                        }}
                                                                    >
                                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                            className={"btn btn-primary "}
                                                                            type={"button"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                        >
                                                                            <i className="fas fa-chevron-left" />
                                                                        </button>
                                                                        <select
                                                                            className={"form-control"}
                                                                            value={date.getFullYear()}
                                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {years.map(option => (
                                                                                <option key={option} value={option} >
                                                                                    {option + 543}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <select
                                                                            className={"form-control"}
                                                                            value={window.months_th[date.getMonth()]}
                                                                            onChange={({ target: { value } }) =>
                                                                                changeMonth(window.months_th.indexOf(value))
                                                                            }
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {window.months_th.map(option => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                            className={"btn btn-primary"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                            type={"button"}
                                                                        >
                                                                            <i className="fas fa-chevron-right" />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }}
                                                            customInput={<CustomDateInput value={this.state.start_date} />}
                                                        />
                                                        <input type="hidden" id="start_date" name="start_date" value={this.state.start_date} onChange={() => { console.log('change') }} readOnly={true} />
                                                    </div>
                                                    <div className="col-12 col-md-12 form-group">
                                                        <div className="d-flex" style={{ width: '100%' }}>
                                                            <label>End</label>
                                                            {
                                                                this.state.end_date ?
                                                                    <div style={{ marginLeft: "auto" }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                end_date: false,
                                                                            })
                                                                        }}
                                                                    >
                                                                        <span className="link">ยกเลิก</span>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <DatePicker
                                                            disabled={this.state.is_loading}
                                                            onChange={date => {
                                                                let end = new Date(date);
                                                                end.setHours(23, 59, 0, 0);
                                                                this.setState({
                                                                    end_date: end.getTime()
                                                                }
                                                                    // , () => {
                                                                    //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                                    // }
                                                                )
                                                            }}
                                                            selectsEnd
                                                            startDate={this.state.start_date}
                                                            endDate={this.state.end_date}
                                                            locale="th-TH"
                                                            selected={this.state.end_date ? Helper.getDateToEdit(this.state.end_date) : false}
                                                            placeholderText={"วัน / เดือน / ปี"}
                                                            name={"end_date"}
                                                            dateFormat="MMMM d, yyyy 23:59:00"
                                                            minDate={this.state.start_date}
                                                            withPortal
                                                            onCalendarOpen={() => { Helper.renderDate() }}
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled
                                                            }) => {
                                                                date = new Date(date);
                                                                let now = new Date();
                                                                // months_th
                                                                const years = [];
                                                                let i;
                                                                for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                                    years.push(i)
                                                                }
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            margin: 10,
                                                                            display: "flex",
                                                                            justifyContent: "center"
                                                                        }}
                                                                    >
                                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                            className={"btn btn-primary "}
                                                                            type={"button"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                        >
                                                                            <i className="fas fa-chevron-left" />
                                                                        </button>
                                                                        <select
                                                                            className={"form-control"}
                                                                            value={date.getFullYear()}
                                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {years.map(option => (
                                                                                <option key={option} value={option} >
                                                                                    {option + 543}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <select
                                                                            className={"form-control"}
                                                                            value={window.months_th[date.getMonth()]}
                                                                            onChange={({ target: { value } }) =>
                                                                                changeMonth(window.months_th.indexOf(value))
                                                                            }
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {window.months_th.map(option => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                            className={"btn btn-primary"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                            type={"button"}
                                                                        >
                                                                            <i className="fas fa-chevron-right" />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }}
                                                            customInput={<CustomDateInput />}
                                                        />
                                                        <input type="hidden" id="end_date" name="end_date" value={this.state.end_date} onChange={() => { console.log('change') }} readOnly={true} />
                                                    </div>
                                                    <div className="col-12 col-md-12 form-group">
                                                        <div className="d-flex" style={{ width: '100%' }}>
                                                            <label>วันที่ค้นหา</label>
                                                            {
                                                                this.state.call_report_date ?
                                                                    <div style={{ marginLeft: "auto" }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                call_report_date: false,
                                                                            })
                                                                        }}
                                                                    >
                                                                        <span className="link">ยกเลิก</span>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <DatePicker
                                                            disabled={this.state.is_loading}
                                                            onChange={date => {
                                                                let call_report_date = new Date(date);
                                                                call_report_date.setHours(23, 59, 0, 0);
                                                                this.setState({
                                                                    call_report_date: call_report_date.getTime()
                                                                }
                                                                    // , () => {
                                                                    //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                                    // }
                                                                )
                                                            }}
                                                            selectsEnd
                                                            locale="th-TH"
                                                            selected={this.state.call_report_date ? Helper.getDateToEdit(this.state.call_report_date) : false}
                                                            placeholderText={"วัน / เดือน / ปี"}
                                                            name={"end_date"}
                                                            dateFormat="MMMM d, yyyy 23:59:00"
                                                            minDate={this.state.start_date}
                                                            withPortal
                                                            onCalendarOpen={() => { Helper.renderDate() }}
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled
                                                            }) => {
                                                                date = new Date(date);
                                                                let now = new Date();
                                                                // months_th
                                                                const years = [];
                                                                let i;
                                                                for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                                    years.push(i)
                                                                }
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            margin: 10,
                                                                            display: "flex",
                                                                            justifyContent: "center"
                                                                        }}
                                                                    >
                                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                            className={"btn btn-primary "}
                                                                            type={"button"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                        >
                                                                            <i className="fas fa-chevron-left" />
                                                                        </button>
                                                                        <select
                                                                            className={"form-control"}
                                                                            value={date.getFullYear()}
                                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {years.map(option => (
                                                                                <option key={option} value={option} >
                                                                                    {option + 543}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <select
                                                                            className={"form-control"}
                                                                            value={window.months_th[date.getMonth()]}
                                                                            onChange={({ target: { value } }) =>
                                                                                changeMonth(window.months_th.indexOf(value))
                                                                            }
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {window.months_th.map(option => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                            className={"btn btn-primary"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                            type={"button"}
                                                                        >
                                                                            <i className="fas fa-chevron-right" />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }}
                                                            customInput={<CustomDateInput />}
                                                        />
                                                        <input type="hidden" id="call_report_date" name="call_report_date" value={this.state.call_report_date} onChange={() => { console.log('change') }} readOnly={true} />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    {
                                        !this.state.is_loading ?
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">Search</button>
                                            </div>
                                            : null
                                    }
                                </form>
                            </div>
                            <div className="col-12 col-md-9" style={{ zIndex: 1 }}>
                                <div className="card" >
                                    <div className="card-header " >
                                        <h3 className="card-title">Booking report logs</h3>
                                        <div className={"card-right"}>
                                            <div style={{ marginRight: 10 }}>{this.state.count_all} logs</div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th >date</th>
                                                    <th >qs</th>
                                                    <th >get data</th>
                                                    <th >render data</th>
                                                    <th >download data</th>
                                                    <th >admin</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !this.state.is_loading
                                                        && this.state.logs.length > 0 ?
                                                        this.state.logs.map((_data, _data_i) => {
                                                            let admin = false;
                                                            let get_admin = this.state.adminList.filter((e) => { return e.uid == _data.qs.adminUid });
                                                            if (get_admin.length > 0) {
                                                                admin = get_admin[0]
                                                            }
                                                            return (
                                                                <tr key={_data_i}>
                                                                    <td>
                                                                        <div><strong>วันที่เรียกรายงาน</strong> </div>
                                                                        <div>{Helper.getDateThai(_data.created, { is_show_time: true })}</div>
                                                                        <div><strong>ค้นหาจากวันที่</strong></div>
                                                                        <div>{Helper.getDateThai(parseFloat(_data.qs.start_date), { is_show_time: true, minus_hours: 7 })}</div>
                                                                        <div>{Helper.getDateThai(parseFloat(_data.qs.end_date), { is_show_time: true, minus_hours: 7 })}</div>
                                                                    </td>
                                                                    <td>
                                                                        {_data.qs ?
                                                                            Object.keys(_data.qs).map(function (key) {
                                                                                if (key == 'start_date' || key == 'end_date') {
                                                                                    return null
                                                                                }
                                                                                return <div>{key} : <strong>{_data.qs[key]}</strong></div>
                                                                            })
                                                                            : null
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <div>count : <strong>{_data.count_all || 0}</strong></div>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            _data.render ?
                                                                                <>
                                                                                    <div>get from server : <strong>{_data.render.count || 0}</strong></div>
                                                                                    <div>count_plustime : <strong>{_data.render.count_plustime || 0}</strong></div>
                                                                                    <div>prepare_data : <strong>{_data.render.data || 0}</strong></div>
                                                                                    <div>renderResponse : <strong>{_data.render.renderResponse || 0}</strong></div>
                                                                                </>
                                                                                : null
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            _data.download ?
                                                                                <>
                                                                                    <div>dataCount : <strong>{_data.download.dataCount || 0}</strong></div>
                                                                                    <div>fileName : <strong>{_data.download.filename || 0}</strong></div>
                                                                                </>
                                                                                : null
                                                                        }
                                                                    </td>
                                                                    <td>{admin ? admin.displayName : '-'}</td>
                                                                </tr>
                                                            );
                                                        })
                                                        : null
                                                }
                                                {
                                                    !this.state.is_loading
                                                        && this.state.logs.length === 0 ?
                                                        <tr >
                                                            <td colSpan="6" style={{ textAlign: 'center' }}>
                                                                ไม่มีข้อมูล
                                                            </td>
                                                        </tr>
                                                        : null
                                                }
                                                {
                                                    this.state.is_loading ?
                                                        <tr >
                                                            <td colSpan="6" style={{ textAlign: 'center' }}>
                                                                <div className="ui active inline loader small" ></div>
                                                            </td>
                                                        </tr>
                                                        : null
                                                }


                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer clearfix">
                                        {
                                            !this.state.is_loading
                                                && this.state.logs.length !== 0 ?
                                                <Pagination
                                                    now={this.state.page}
                                                    all_items={this.state.count_all}
                                                    limit={this.state.limit}
                                                    location={this.props.location}
                                                />
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}