import React from "react";
import { Helper } from "../../Helper";
import { Link } from "@reach/router";
import CustomDateInput from "../../Components/CustomDateInput";
import DatePicker from 'react-datepicker';
const axios = require('axios').default;

export default class Customer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filter: {
                page: Helper.getParameterByName("page") ? parseFloat(Helper.getParameterByName('page')) : 1,
                limit: Helper.getParameterByName("limit") ? parseFloat(Helper.getParameterByName('limit')) : 15,
                rating: Helper.getParameterByName("rating") ? parseFloat(Helper.getParameterByName('rating')) : 5,
                start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
                end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
            },
            is_loading: true,
            count_all: 0,
            renderCard: {
                showProviderStar: true,
                showUserStar: true,
                showProviderComment: true,
                showUserComment: true,
                showUserImage: true,
                showUserName: true,
                showBookNo: true,
                onlyComment: true,
                showDate: true
            }
        }
    }

    componentDidMount() {
        this.getReview();
    }

    async getReview() {
        let qs = this.state.filter;
        qs.review = false;
        if (this.state.renderCard.onlyComment) {
            qs.review = "show";
        }
        let res = await axios.get(`${window.api_host}/review-customer?${window.$.param(qs)}`);
        console.log(res)
        let data = this.state.data;
        if (res?.data?.data) {
            data = data.concat(res.data.data)
        }
        this.setState({
            is_loading: false,
            data: data,
            count_all: res?.data?.count_all
        }, () => {
            if (this.$nextPageBtn && this.$nextPageBtn.hasClass('loading')) {
                this.$nextPageBtn.removeClass('loading')
                window.$("html, body").animate({ scrollTop: window.$(document).height() }, 1000);
            }
            if (window.ratingInput) {
                window.ratingInput.attr("disabled", false)
            }
            if (window.onlyCommentInput) {
                window.onlyCommentInput.attr("disabled", false)
            }
            if (window.showUserNameInput) {
                window.showUserNameInput.attr("disabled", false)
            }
            if (window.showDateNoInput) {
                window.showDateNoInput.attr("disabled", false)
            }
        })
    }
    nextPage() {
        window.ratingInput.attr("disabled", true)
        window.onlyCommentInput.attr("disabled", true)
        window.showUserNameInput.attr("disabled", true)
        window.showDateNoInput.attr("disabled", true)
        this.$nextPageBtn.addClass('loading');
        let filter = this.state.filter;
        filter.page = this.state.filter.page + 1
        this.setState({
            filter: filter
        }, () => {
            this.getReview();
        })
    }

    submitChangeDate(state) {
        console.log(state)
        let qs = this.state.filter;
        if (qs.start_date == state.start_date && qs.end_date == state.end_date) return;
        qs.start_date = state.start_date;
        qs.end_date = state.end_date;
        this.setState({
            filter: qs,
            data: [],
            is_loading: true,
            count_all: 0
        }, () => {
            this.getReview();
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รีวิวลูกค้า</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">รีวิวลูกค้า</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid pb-4">
                        <div className="card" style={{ zIndex: 2 }}>
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-2">
                                        {/* <label>เลือก Rating</label> */}
                                        <select
                                            ref={(ref) => { window.ratingInput = window.$(ref) }}
                                            name="rating"
                                            className="form-control"
                                            defaultValue={this.state.filter.rating}
                                            disabled={this.state.is_loading}
                                            onChange={(e) => {
                                                window.ratingInput.attr("disabled", true)
                                                let value = e.target.value;
                                                let filter = this.state.filter;
                                                filter.rating = value != 'none' ? parseFloat(value) : value;
                                                this.setState({
                                                    filter: filter,
                                                    data: [],
                                                    is_loading: true,
                                                    count_all: 0
                                                }, () => {
                                                    this.getReview();
                                                })
                                            }}
                                        >
                                            {/* <option value="none">ทั้งหมด</option> */}
                                            <option value="5">★★★★★</option>
                                            <option value="4">★★★★</option>
                                            <option value="3">★★★</option>
                                            <option value="2">★★</option>
                                            <option value="1">★</option>
                                        </select>
                                    </div>
                                    <div className="col-3">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox"
                                                ref={(ref) => { window.onlyCommentInput = window.$(ref) }}
                                                disabled={this.state.is_loading}
                                                className="custom-control-input"
                                                id="onlyComment"
                                                name={"onlyComment"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.renderCard.onlyComment}
                                                onChange={(e) => {
                                                    window.onlyCommentInput.attr("disabled", true)
                                                    let renderCard = this.state.renderCard;
                                                    if (window.$(e.target).prop("checked")) {
                                                        renderCard.onlyComment = true;
                                                    } else {
                                                        renderCard.onlyComment = false;
                                                    }

                                                    this.setState({
                                                        renderCard: renderCard,
                                                        data: [],
                                                        is_loading: true,
                                                        count_all: 0
                                                    }, () => {
                                                        this.getReview();
                                                    })
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="onlyComment">แสดงเฉพาะ book ที่มีคอมเม้น(รีวิว)</label>
                                        </div>

                                    </div>
                                    <div className="col-2">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox"
                                                ref={(ref) => { window.showUserImageInput = window.$(ref) }}
                                                disabled={this.state.is_loading}
                                                className="custom-control-input"
                                                id="showUserImage"
                                                name={"showUserImage"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.renderCard.showUserImage}
                                                onChange={(e) => {
                                                    window.showUserImageInput.attr("disabled", true)
                                                    let renderCard = this.state.renderCard;
                                                    if (window.$(e.target).prop("checked")) {
                                                        renderCard.showUserImage = true;
                                                    } else {
                                                        renderCard.showUserImage = false;
                                                    }
                                                    this.setState({
                                                        renderCard: renderCard
                                                    }, () => {
                                                        window.showUserImageInput.attr("disabled", false)
                                                    })
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="showUserImage">แสดงรูปภาพ</label>
                                        </div>
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox"
                                                ref={(ref) => { window.showUserNameInput = window.$(ref) }}
                                                disabled={this.state.is_loading}
                                                className="custom-control-input"
                                                id="showUserName"
                                                name={"showUserName"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.renderCard.showUserName}
                                                onChange={(e) => {
                                                    window.showUserNameInput.attr("disabled", true)
                                                    let renderCard = this.state.renderCard;
                                                    if (window.$(e.target).prop("checked")) {
                                                        renderCard.showUserName = true;
                                                    } else {
                                                        renderCard.showUserName = false;
                                                    }
                                                    this.setState({
                                                        renderCard: renderCard
                                                    }, () => {
                                                        window.showUserNameInput.attr("disabled", false)
                                                    })
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="showUserName">แสดงชื่อ</label>
                                        </div>

                                        <div className="custom-control custom-switch">
                                            <input type="checkbox"
                                                ref={(ref) => { window.showBookNoInput = window.$(ref) }}
                                                disabled={this.state.is_loading}
                                                className="custom-control-input"
                                                id="showBookNo"
                                                name={"showBookNo"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.renderCard.showBookNo}
                                                onChange={(e) => {
                                                    window.showBookNoInput.attr("disabled", true)
                                                    let renderCard = this.state.renderCard;
                                                    if (window.$(e.target).prop("checked")) {
                                                        renderCard.showBookNo = true;
                                                    } else {
                                                        renderCard.showBookNo = false;
                                                    }
                                                    this.setState({
                                                        renderCard: renderCard
                                                    }, () => {
                                                        window.showBookNoInput.attr("disabled", false)
                                                    })
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="showBookNo">แสดงเลข Book</label>
                                        </div>

                                        <div className="custom-control custom-switch">
                                            <input type="checkbox"
                                                ref={(ref) => { window.showDateNoInput = window.$(ref) }}
                                                disabled={this.state.is_loading}
                                                className="custom-control-input"
                                                id="showDate"
                                                name={"showDate"}
                                                defaultValue={"on"}
                                                defaultChecked={this.state.renderCard.showDate}
                                                onChange={(e) => {
                                                    window.showDateNoInput.attr("disabled", true)
                                                    let renderCard = this.state.renderCard;
                                                    if (window.$(e.target).prop("checked")) {
                                                        renderCard.showDate = true;
                                                    } else {
                                                        renderCard.showDate = false;
                                                    }
                                                    this.setState({
                                                        renderCard: renderCard
                                                    }, () => {
                                                        window.showDateNoInput.attr("disabled", false)
                                                    })
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="showDate">แสดงวันที่</label>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <DateRangePicker {...this.state.filter}
                                            submitChangeDate={(state) => this.submitChangeDate(state)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            !this.state.is_loading
                                && this.state.data.length > 0 ?
                                <div className="row" style={{ position: 'relative', zIndex: 1 }}>
                                    {
                                        this.state.data.map((item, i) => {
                                            return <div className="col-4 d-flex flex-column" key={i}>
                                                <ReviewCard {...item} renderCard={this.state.renderCard} />
                                            </div>
                                        })
                                    }
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.data.length === 0 ?
                                <h3 cstyle={{ textAlign: 'center', position: 'relative', zIndex: 1 }}>
                                    ไม่มีข้อมูล
                                </h3>
                                : null
                        }
                        {
                            this.state.is_loading ?
                                <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative', zIndex: 1 }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            this.state.data.length > 0
                                && this.state.data.length < this.state.count_all
                                && !this.state.is_loading ?
                                <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative', zIndex: 1 }}>
                                    <button
                                        ref={(ref) => this.$nextPageBtn = window.$(ref)}
                                        className="btn btn-default"
                                        onClick={() => {
                                            this.nextPage();
                                        }}
                                    >โหลดเพิ่ม</button>
                                </div>
                                : null
                        }

                    </div>
                </section>
            </>
        )
    }
}


function ReviewCard(props) {
    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex pb-2 align-items-center">
                    {
                        props.renderCard?.showUserImage ?
                            <a href={`/user/${props.user.uid}`} target="_blank" className="profile-frame mr-2">
                                <img src={props.user?.photoURL || '/assets/images/no_image.png'} />
                            </a>
                            : null
                    }
                    <div>
                        {
                            props.renderCard?.showUserName ?
                                <a href={`/user/${props.user.uid}`} target="_blank" style={{ color: '#212529' }} className="mb-1">{props.user?.displayName || "ไม่ระบุ"}</a>
                                : null
                        }
                        <RenderStar rating={props.starFromUser} />
                    </div>
                    {
                        props.renderCard?.showBookNo
                            && props.id && props.bookNo ?
                            <div className="ml-auto h-100">
                                <a href={`/booking/${props.id}`} target="_blank" style={{ color: '#212529' }} className="mb-1">{props.bookNo}</a>
                            </div>
                            : null
                    }
                </div>
                {/* <hr className="mt-0 mb-1"/> */}
                {props.reportFromUser || 'ไม่มีรีวิว'}

                {
                    props.renderCard?.showDate
                        && props.bookedAt ?
                        <div >
                            <small className="text-muted">{Helper.getDateThai(props.bookedAt)}</small>
                        </div>
                        : null
                }
            </div>
        </div>
    )
}
function RenderStar(props) {
    let allStar = 5;
    let filledStar = parseFloat(props.rating);
    let emptyStar = allStar - filledStar;

    let filledStarArr = [];
    if (filledStar > 0) {
        for (let index = 0; index < filledStar; index++) {
            filledStarArr.push(index)
        }
    }


    let emptyStarArr = [];
    if (emptyStar > 0) {
        for (let index = 0; index < emptyStar; index++) {
            emptyStarArr.push(index)
        }
    }


    return (
        <div className="d-flex">
            {
                filledStarArr.map((item) => {
                    return <i key={`filledStar-${item}`} className="fas fa-star" style={{ color: "rgb(255, 162, 0)" }}></i>
                })
            }
            {
                emptyStarArr.map((item) => {
                    return <i key={`emptyStar-${item}`} className="fas fa-star" style={{ color: "#ccc" }}></i>
                })
            }
        </div >
    )
}

class DateRangePicker extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            start_date: props.start_date,
            end_date: props.end_date,
        }
    }
    render() {
        return (
            <>
                <label>ค้นหาจากวันที่</label>
                <div className="d-flex">
                    <div style={{ marginRight: '0.5rem' }}>
                        <DatePicker
                            disabled={this.props.is_loading}
                            onChange={date => {

                                let start = new Date(date);
                                start.setHours(0, 0, 0, 0);
                                this.setState({
                                    start_date: start.getTime()
                                }
                                    // , () => {
                                    //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                    // }
                                )
                            }}
                            selectsStart
                            startDate={this.state.start_date}
                            endDate={this.state.end_date}
                            locale="th-TH"
                            selected={Helper.getDateToEdit(this.state.start_date)}
                            placeholderText={"วัน / เดือน / ปี"}
                            name={"start_date"}
                            dateFormat="MMMM d, yyyy 00:00:00"
                            withPortal
                            onCalendarOpen={() => { Helper.renderDate() }}
                            renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled
                            }) => {
                                date = new Date(date);
                                let now = new Date();
                                // months_th
                                const years = [];
                                let i;
                                for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                    years.push(i)
                                }
                                return (
                                    <div
                                        style={{
                                            margin: 10,
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                            className={"btn btn-primary "}
                                            type={"button"}
                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                        >
                                            <i className="fas fa-chevron-left" />
                                        </button>
                                        <select
                                            className={"form-control"}
                                            value={date.getFullYear()}
                                            onChange={({ target: { value } }) => changeYear(value)}
                                            style={{ borderRadius: 0 }}
                                        >
                                            {years.map(option => (
                                                <option key={option} value={option} >
                                                    {option + 543}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            className={"form-control"}
                                            value={window.months_th[date.getMonth()]}
                                            onChange={({ target: { value } }) =>
                                                changeMonth(window.months_th.indexOf(value))
                                            }
                                            style={{ borderRadius: 0 }}
                                        >
                                            {window.months_th.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                            className={"btn btn-primary"}
                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                            type={"button"}
                                        >
                                            <i className="fas fa-chevron-right" />
                                        </button>
                                    </div>
                                )
                            }}
                            customInput={<CustomDateInput />}
                        />
                        <input type="hidden" id="start_date" name="start_date" value={this.state.start_date} onChange={() => { console.log('change') }} readOnly={true} />
                    </div>
                    <div style={{ marginRight: '0.5rem' }}>
                        <DatePicker
                            disabled={this.props.is_loading}
                            onChange={date => {
                                let end = new Date(date);
                                end.setHours(23, 59, 0, 0);
                                this.setState({
                                    end_date: end.getTime()
                                }
                                    // , () => {
                                    //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                    // }
                                )
                            }}
                            selectsEnd
                            startDate={this.state.start_date}
                            endDate={this.state.end_date}
                            locale="th-TH"
                            selected={Helper.getDateToEdit(this.state.end_date)}
                            placeholderText={"วัน / เดือน / ปี"}
                            name={"end_date"}
                            dateFormat="MMMM d, yyyy 23:59:00"
                            minDate={this.state.start_date}
                            withPortal
                            onCalendarOpen={() => { Helper.renderDate() }}
                            renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled
                            }) => {
                                date = new Date(date);
                                let now = new Date();
                                // months_th
                                const years = [];
                                let i;
                                for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                    years.push(i)
                                }
                                return (
                                    <div
                                        style={{
                                            margin: 10,
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                            className={"btn btn-primary "}
                                            type={"button"}
                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                        >
                                            <i className="fas fa-chevron-left" />
                                        </button>
                                        <select
                                            className={"form-control"}
                                            value={date.getFullYear()}
                                            onChange={({ target: { value } }) => changeYear(value)}
                                            style={{ borderRadius: 0 }}
                                        >
                                            {years.map(option => (
                                                <option key={option} value={option} >
                                                    {option + 543}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            className={"form-control"}
                                            value={window.months_th[date.getMonth()]}
                                            onChange={({ target: { value } }) =>
                                                changeMonth(window.months_th.indexOf(value))
                                            }
                                            style={{ borderRadius: 0 }}
                                        >
                                            {window.months_th.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                            className={"btn btn-primary"}
                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                            type={"button"}
                                        >
                                            <i className="fas fa-chevron-right" />
                                        </button>
                                    </div>
                                )
                            }}
                            customInput={<CustomDateInput />}
                        />
                        <input type="hidden" id="end_date" name="end_date" value={this.state.end_date} onChange={() => { console.log('change') }} readOnly={true} />
                    </div>
                    <button type="button" className="btn btn-default"
                        onClick={() => {
                            this.props.submitChangeDate(this.state)
                        }}
                    >ยืนยัน</button>
                </div>
            </>
        )
    }
}