import React from 'react';
import { db } from './../firebase';
import { Link, navigate } from '@reach/router';
import CustomDateInput from './../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import { Helper } from './../Helper';
import InputMask from 'react-input-mask';
const axios = require('axios').default;

export default class AddProviderUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            is_loading: true,
            birth_day: null,
            educate_day: new Date(),
            slip_day: new Date(),
            data: {},
            training_date: [],
            selected_province: false,
            selected_amphoe: [],
            db: []
        }
    }

    componentDidMount() {
        this.fetchTrainingDate();
    }
    fetchTrainingDate() {
        db.collection("training_date").orderBy('date', 'asc').get().then(async (doc) => {
            let data = [];
            data = await Promise.all(
                doc.docs.map(async (element) => {
                    let participants = await db.collection('training_date/' + element.id + '/participants').get();
                    // console.log(participants);
                    // console.log(participants.docs);
                    let date = new Date(element.data().date);
                    let now = new Date();
                    if (date >= now) {
                        return {
                            ...element.data(),
                            date: date,
                            id: element.id,
                            participants_count: participants.docs.length,
                        };
                    }
                    return null;

                })
            );
            let province = await axios.get(window.api_host + "/service-area").then(async (res) => {
                let _data = res.data.data.filter((resp) => {
                    return resp.amphoe.length > 0
                });
             //    console.log(_data)
                 return _data
             })
            this.setState({
                training_date: data,
                educate_day: data[0],
                is_loading: false,
                db: province,
                selected_province: province[0].provinceCode
            })
        })
    }


    async add(data) {
        let $submit = this.$form.find('button[type="submit"]');
        // let personalNo = "";
        if (!data.personalNo) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มข้อมูลไม่สำเร็จ',
                body: "ระบุหมายเลขประจำตัวประชาชน",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            $submit.removeClass('loading');
            return;
        }
        // data.personalNo.split(" ").map((no) => {
        //     personalNo += no;
        //     return true;
        // })

        if (data.phoneNumber && data.phoneNumber.length !== 10) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มข้อมูลไม่สำเร็จ',
                body: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            $submit.removeClass('loading');
            return;
        }
        if (this.state.selected_amphoe.length === 0) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มข้อมูลไม่สำเร็จ',
                body: 'ระบุเขต/อำเภอ',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            $submit.removeClass('loading');
            return;
        }
        // if (!personalNo || (personalNo && !Helper.chkDigitPid(personalNo))) {
        //     window.$(document).Toasts('create', {
        //         title: 'เพิ่มข้อมูลไม่สำเร็จ',
        //         body: 'ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง',
        //         icon: 'fas fa-times',
        //         autohide: true,
        //         delay: 3000,
        //         class: "bg-danger",
        //     })
        //     $submit.removeClass('loading');
        //     return;
        // }
        if (data.iscongenitalDisease === 'มี' && (data.congenitalDisease === '' || !data.congenitalDisease)) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มข้อมูลไม่สำเร็จ',
                body: 'ระบุโรคประจำตัว',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            $submit.removeClass('loading');
            return;
        }

        let phoneNumber = '+66' + data.phoneNumber.substr(1, 9);
        let check_phone_number = await db.collection('provider_users_data').where('phoneNumber', '==', phoneNumber).get();
        if (check_phone_number.docs.length > 0) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มข้อมูลไม่สำเร็จ',
                body: 'มีผู้ใช้งานเบอร์โทรศัพทนี้แล้ว',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            $submit.removeClass('loading');
            return;
        }
        let check_p_id = await db.collection('provider_users_data').where('personalNo', '==', data.personalNo).get();
        if (check_p_id.docs.length > 0) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มข้อมูลไม่สำเร็จ',
                body: 'เลขบัตรประจำตัวประชาชนนี้มีผู้ใช้งานแล้ว',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            $submit.removeClass('loading');
            return;
        }


        data.registerBy = "admin";
        data.registerAdmin = {};
        data.registerAdmin.adminUID = this.props.admin.id.stringValue;
        data.registerAdmin.adminName = this.props.admin.displayName.stringValue;
        data.status = "waitForApprove";
        data.createdAt = new Date().getTime();
        let emergencyContactNumber = '+66' + data.emergencyContactNumber.substr(1, 9);
        data.phoneNumber = phoneNumber;
        data.emergencyContactNumber = emergencyContactNumber;
        const displayName = data.firstName + ' ' + data.lastName;
        const traningUID = data.traningUID;
        data.provincesCode = [data.province];

        data.amphoe = [];
        data.amphoesCode = [];
        for (const amphoe of this.state.selected_amphoe) {
            data.amphoe.push(amphoe.name);
            data.amphoesCode.push(amphoe.code);
        }
        // console.log(data);
        // return;
        
    }

    getAmphoe() {
        let apm = [];
        this.state.db.forEach((db) => {
            if (db.provinceCode === this.state.selected_province) {
                db.amphoe.forEach((amph, amph_i) => {
                    apm.push({ name: amph, code: db.amphoesCode[amph_i] });
                })
            }
        });
        if (this.state.selected_amphoe.length > 0) {
            apm = apm.filter((_apm) => {
                let count = 0;
                this.state.selected_amphoe.forEach((selected_amphoe) => {
                    if (_apm.code === selected_amphoe.code) {
                        count = 1;
                    }
                })
                return count === 0
            })
        }
        apm.sort()
        return apm;
    }



    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">เพิ่มแม่บ้าน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/provider-users">แม่บ้าน</Link></li>
                                    <li className="breadcrumb-item active">เพิ่ม</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form className={"content"}
                    ref={(ref) => this.$form = window.$(ref)}
                    onSubmit={(e) => {
                        e.preventDefault();
                        let $submit = this.$form.find('button[type="submit"]');
                        if ($submit.hasClass('loading')) { return false; }
                        $submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        // console.log(data);
                        this.add(data);
                    }}
                >
                    <div className="container-fluid" >
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.user ?
                                <>
                                    <div className={"row"}>
                                        <div className="col-lg-4">

                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">ข้อมูลแม่บ้าน</h3>
                                                </div>
                                                <div className="card-body" >
                                                    <div className="form-group">
                                                        <label htmlFor="personalNo">เลขบัตรประชาชน</label>
                                                        <InputMask
                                                            mask="9 9999 99999 99 9"
                                                            placeholder={"เลขบัตรประชาชน"}
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                let personalNo = e.target.value;
                                                                this.$personalNo.val(personalNo)
                                                            }}
                                                        />
                                                        <input name={"personalNo"} type={"hidden"} ref={(ref) => { this.$personalNo = window.$(ref) }} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>คำนำหน้านาม</label>
                                                        <select className="form-control placeholder_font" name="prefix" >
                                                            {/* <option value="นาย" >นาย</option> */}
                                                            <option value="นาง">นาง</option>
                                                            <option value="นางสาว">นางสาว</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="firstName">ชื่อ</label>
                                                        <input type="text" className="form-control" id="firstName" name="firstName" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="lastName">นามสกุล</label>
                                                        <input type="text" className="form-control" id="lastName" name="lastName" />
                                                        <input type={"hidden"} name="gender" value={"หญิง"} />
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label htmlFor="gender">เพศ</label>
                                                        <select className="form-control placeholder_font" name="gender" defaultValue={this.state.user.gender}>
                                                            <option value="ชาย">ชาย</option>
                                                            <option value="หญิง">หญิง</option>
                                                        </select>
                                                    </div> */}
                                                    <div className="form-group">
                                                        <label htmlFor="birthDay">วันเดือนปีเกิด</label>
                                                        <DatePicker
                                                            onChange={date => {
                                                                this.setState({
                                                                    birth_day: date
                                                                })
                                                            }}
                                                            locale="th-TH"
                                                            selected={this.state.birth_day}
                                                            placeholderText={"วัน / เดือน / ปี"}
                                                            name={"birthDay"}
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            withPortal
                                                            onCalendarOpen={() => { Helper.renderDate() }}
                                                            maxDate={new Date()}
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled
                                                            }) => {
                                                                let now = new Date();
                                                                // months_th
                                                                let selected_date = new Date(date);
                                                                const years = [];
                                                                let i;
                                                                for (i = 1960; i <= now.getFullYear(); i++) {
                                                                    years.push(i)
                                                                }
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            margin: 10,
                                                                            display: "flex",
                                                                            justifyContent: "center"
                                                                        }}
                                                                    >
                                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                            className={"btn btn-primary "}
                                                                            type={"button"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                        >
                                                                            <i className="fas fa-chevron-left" />
                                                                        </button>
                                                                        <select
                                                                            className={"form-control"}
                                                                            value={selected_date.getFullYear()}
                                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {years.map(option => (
                                                                                <option key={option} value={option} >
                                                                                    {option + 543}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <select
                                                                            className={"form-control"}
                                                                            onChange={({ target: { value } }) =>
                                                                                changeMonth(window.months_th.indexOf(value))
                                                                            }
                                                                            value={window.months_th[selected_date.getMonth()]}
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {window.months_th.map(option => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                            className={"btn btn-primary"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                            type={"button"}
                                                                        >
                                                                            <i className="fas fa-chevron-right" />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }}
                                                            customInput={<CustomDateInput />}
                                                        />
                                                        <input type="hidden" className="form-control" id="birthDay" name="birthDay" value={new Date(this.state.birth_day).getTime()} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">ข้อมูลที่อยู่</h3>
                                                </div>
                                                <div className="card-body" >
                                                    <div className="form-group">
                                                        <label>จังหวัดที่ต้องการสมัคร</label>
                                                        <select className="form-control"
                                                            name={"province"}
                                                            defaultValue={this.state.selected_province}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    selected_province: e.target.value,
                                                                    selected_amphoe: [],
                                                                })
                                                            }}
                                                        >
                                                            {
                                                                this.state.db.map((item, item_i) => (
                                                                    <option value={item.provinceCode} key={item_i}>{item.province}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>พื้นที่เขตที่สะดวกรับงาน</label>
                                                        {
                                                            !this.state.selected_province ?
                                                                <input type={"test"} defaultValue={"เลือกจังหวัด"} disabled={true} className={"form-control"} />
                                                                : null
                                                        }
                                                        {
                                                            this.state.selected_province ?
                                                                <select className="form-control"
                                                                    name={"amphoe"}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === "" || !e.target.value) { return; }
                                                                        let selected_amphoe = [...this.state.selected_amphoe];
                                                                        let _data = false;
                                                                        this.getAmphoe().map((amp, amp_i) => {
                                                                            if (amp.code === e.target.value) {
                                                                                _data = { ...amp }
                                                                            }
                                                                            return true
                                                                        })
                                                                        selected_amphoe.push(_data);
                                                                        this.setState({
                                                                            selected_amphoe: selected_amphoe,
                                                                        })
                                                                    }}
                                                                    value={""}
                                                                >
                                                                    <option value="">เลือกเขต/อำเภอ{this.state.selected_amphoe.length > 0 ? "เพิ่ม" : null}</option>
                                                                    {
                                                                        this.getAmphoe().map((amp, amp_i) => (
                                                                            <option value={amp.code} key={amp_i}
                                                                                onClick={() => {

                                                                                }}
                                                                            >{amp.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                : null
                                                        }
                                                    </div>
                                                    {
                                                        this.state.selected_amphoe.length > 0 ?
                                                            <div className="form-group tag-label">
                                                                {
                                                                    this.state.selected_amphoe.map((selected_amphoe, selected_amphoe_i) => {
                                                                        return (
                                                                            <div key={selected_amphoe_i}>
                                                                                <i className="fas fa-times"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        let _selected_amphoe = [...this.state.selected_amphoe];
                                                                                        _selected_amphoe.splice(selected_amphoe_i, 1);
                                                                                        this.setState({
                                                                                            selected_amphoe: _selected_amphoe
                                                                                        })
                                                                                    }}
                                                                                ></i> {selected_amphoe.name}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    <div className="form-group">
                                                        <label htmlFor="currentOccupation">อาชีพ</label>
                                                        <input type="text" className="form-control" id="currentOccupation" name="currentOccupation" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="currentWorkplace">สถานที่ทำงาน</label>
                                                        <input type="text" className="form-control" id="currentWorkplace" name="currentWorkplace" />
                                                    </div>
                                                    <div className="form-group">
                                                        <div>
                                                            <label>โรคประจำตัว</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="iscongenitalDisease"
                                                                id="iscongenitalDisease1"
                                                                value="ไม่มี"
                                                                defaultChecked={true}
                                                                onChange={(e) => {
                                                                    this.congenitalDisease.hide();
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="iscongenitalDisease1">ไม่มี</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="iscongenitalDisease"
                                                                id="iscongenitalDisease2"
                                                                value="มี"
                                                                onChange={(e) => {
                                                                    this.congenitalDisease.show();
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="iscongenitalDisease2">มี</label>
                                                        </div>
                                                        <div className="form-group"
                                                            ref={(ref) => { this.congenitalDisease = window.$(ref) }}
                                                            style={{ display: 'none' }}>
                                                            <input type="text" className="form-control" placeholder="ระบุโรคประจำตัว" name="congenitalDisease" defaultValue={this.state.user.congenitalDisease} />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">ข้อมูลติดต่อ</h3>
                                                </div>
                                                <div className="card-body" >
                                                    <div className="form-group">
                                                        <label htmlFor="phoneNumber">เบอร์โทรศัพท์</label>
                                                        <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="lineID">Line ID</label>
                                                        <input type="text" className="form-control" id="lineID" name="lineID" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="emergencyContactNumber">เบอร์ติดต่อฉุกเฉิน</label>
                                                        <input type="text" className="form-control" id="emergencyContactNumber" name="emergencyContactNumber" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">ข้อมูลวันอบรม</h3>
                                                </div>
                                                <div className="card-body" >
                                                    <div>
                                                        <label>เลือกวันเข้าอบรม</label>
                                                        <select className="form-control"
                                                            name={"traningUID"}
                                                            onChange={(e) => {
                                                                console.log(e.target.value);
                                                            }}
                                                        >
                                                            {
                                                                this.state.training_date.map((t_date, t_date_i) => {
                                                                    if (t_date) {
                                                                        return (
                                                                            <option key={t_date_i} value={t_date.id}>{Helper.getDateThai(t_date.date)}</option>
                                                                        )
                                                                    }
                                                                    return true
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <input type="hidden" name="traningUID" value={"9OdBztgSPOwmm7mYIfEd"}/>

                                            <div className="flex row m-0" >
                                                <div style={{ marginLeft: 'auto' }}>
                                                    <Link to={"/provider-users/"} className={"btn btn-default"}>ยกเลิก</Link>
                                                    <button type="submit" className={"btn btn-primary"} style={{ marginLeft: '1rem' }}>เพิ่ม</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </div>


                </form>
            </>
        );
    }
}