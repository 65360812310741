import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import thailand from "./../thailand.json";
import { BanProviderUserForm } from './ViewProviderUsers';
import { ProvincesAndAmphursList } from './ProviderUsers';
import { db, getProviderUserByProderviderUserDataUid } from './../firebase';
import Pagination from './../Components/Pagination';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
const axios = require('axios').default;

const today = new Date();
today.setDate(today.getDate() - 1);
today.setHours(0, 0, 1);
export default class ReportList extends React.Component {
    constructor() {
        super();
        this.state = {
            page: 1,
            limit: 10,
            is_loading: true,
            data: [],
            all_data: [],
            training_date_status: [],
            page: Helper.getParameterByName('page') ? parseFloat(Helper.getParameterByName('page')) : 1,
            provinceCode: Helper.getParameterByName('provinceCode') ? parseFloat(Helper.getParameterByName('provinceCode')) : false,
            amphoeCode: Helper.getParameterByName('amphoeCode') ? Helper.getParameterByName('amphoeCode') : false,
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            tranningStatus: Helper.getParameterByName('tranningStatus') ? Helper.getParameterByName('tranningStatus') : '',
            date: Helper.getParameterByName('date') ? Helper.getParameterByName('date') : '',
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? parseFloat(Helper.getParameterByName('page')) : 1,
                provinceCode: Helper.getParameterByName('provinceCode') ? parseFloat(Helper.getParameterByName('provinceCode')) : false,
                amphoeCode: Helper.getParameterByName('amphoeCode') ? Helper.getParameterByName('amphoeCode') : false,
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                tranningStatus: Helper.getParameterByName('tranningStatus') ? Helper.getParameterByName('tranningStatus') : '',
                date: Helper.getParameterByName('date') ? Helper.getParameterByName('date') : '',
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
            }, () => {
                this.fetch();
            })
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let date = today;
        const dateStart = new Date(today);
        if (this.state.date == 'thisMonth') {
            dateStart.setMonth(today.getMonth());
        } else {
            dateStart.setDate(dateStart.getDate() - this.state.date);
        }
        let ref = db.collection("provider_users_data");
        if (this.state.provinceCode && !this.state.amphoeCode) {
            ref = ref.where('provincesCode', 'array-contains', this.state.provinceCode.toString());
        }
        if (this.state.amphoeCode) {
            ref = ref.where('amphoesCode', 'array-contains', this.state.amphoeCode.toString());
        }
        if (this.state.status && this.state.status !== "") {
            ref = ref.where('status', '==', this.state.status);
        }
        if (this.state.tranningStatus && this.state.tranningStatus !== "") {
            ref = ref.where('tranningStatus', '==', this.state.tranningStatus);
        }
        if (this.state.data && this.state.date !== '') {
            ref = ref.where('createdAt', "<=", today.getTime()).where('createdAt', '>=', dateStart.getTime()).orderBy('createdAt', 'desc')
        }

        ref = await ref.get();
        // console.log(ref)
        let get_data = await Promise.all(
            ref.docs.map(async (element) => {
                let element_data = { ...element.data() };
                if (element_data.tranningStatus) {
                    let _tranningStatusData = await db.doc('training_date_status/' + element.data().tranningStatus).get();
                    element_data.tranningStatusData = { ..._tranningStatusData.data() };
                }

                return {
                    ...element_data,
                    id: element.id,
                };


            })
        );
        get_data = get_data.filter((_data) => {
            return _data !== false
        });
        let data = get_data.filter((__data, __data_i) => {
            return __data_i < (this.state.limit * this.state.page)
                && __data_i > (((this.state.limit * this.state.page) - this.state.limit) - 1)
        })
        let data_filter = await Promise.all(
            data.map(async (element_data) => {
                let _element_data = {
                    ...element_data,
                }
                if (element_data.traningUID) {
                    let _educateDate = await db.doc('training_date/' + element_data.traningUID).get();
                    element_data.educateDate = _educateDate.data() && _educateDate.data().date ? _educateDate.data().date : new Date().getTime();
                }
                if (element_data.tranningStatus) {
                    let _tranningStatusData = await db.doc('training_date_status/' + element_data.tranningStatus).get();
                    element_data.tranningStatusData = { ..._tranningStatusData.data() };
                }
                const providerUser = await getProviderUserByProderviderUserDataUid(element_data.id);
                if (providerUser) {
                    let provider_user = await db.doc("provider_users/" + providerUser.id).get();
                    _element_data.provider_user = provider_user.data();
                }
                return _element_data;
            })
        )
        let training_date_status = await db.collection('training_date_status').get();
        let _training_date_status = await Promise.all(
            training_date_status.docs.map(async (element) => {
                let element_data = { ...element.data() };
                return {
                    ...element_data,
                    id: element.id,
                };

            })
        );
        this.setState({
            all_data: get_data,
            data: data_filter,
            is_loading: false,
            training_date_status: _training_date_status,
        })
    }


    render() {
        let export_data = [];
        this.state.all_data.map((_data, i) => {
            let prepare = {};
            prepare.name = `${_data.prefix} ${_data.firstName} ${_data.lastName}`;
            prepare.phoneNumber = `${_data.phoneNumber}`;
            prepare.lineID = `${_data.lineID}`;
            prepare.tranningStatusData = _data.tranningStatusData ? _data.tranningStatusData.status_name : 'ไม่มีสถานะ';
            let status = "-";
            if (_data.status === 'waitForApprove') {
                status = "รอการยืนยัน"
            }
            if (_data.status === 'approved') {
                status = "ยืนยันแล้ว"
            }
            if (_data.status === 'avaliable') {
                status = "ใช้งานได้"
            }
            if (_data.status === 'ban') {
                status = "ระงับการใช้งาน"
            }
            prepare.status = status;
            prepare.bank = _data.bank;
            prepare.bankBranch = _data.bankBranch;
            prepare.bankAccountNo = _data.bankAccountNo;
            prepare.bankAccountName = _data.bankAccountName;
            prepare.created = Helper.getDateThai(_data.createdAt, { is_show_time: true });
            export_data.push(prepare)
        });
        let exportTitle = "รายงานแม่บ้าน";
        if (this.state.provinceCode) {
            if (this.state.amphoeCode) {
                let amphoetitle = ""
                if (this.state.provinceCode == '10') {
                    amphoetitle = " เขต";
                } else {
                    amphoetitle = " อำเภอ";
                }
                exportTitle += " " + amphoetitle + thailand[this.state.provinceCode].amphoes[this.state.amphoeCode].name.th;
            }
            exportTitle += " " + thailand[this.state.provinceCode].name.th;

        }
        let now = new Date();
        exportTitle += " (" + Helper.getDateThai(now.getTime()) + ")";
        return (
            <>
                <ExcelExport
                    data={export_data}
                    ref={exporter => this._export = exporter}
                    fileName={exportTitle}
                >
                    <ExcelExportColumn field="name" title="ชื่อ-นามสกุล" width={200} />
                    <ExcelExportColumn field="phoneNumber" title="เบอร์โทรศัพท์" width={130} />
                    <ExcelExportColumn field="lineID" title="Line ID" width={150} />
                    <ExcelExportColumn field="bank" title="ธนาคาร" width={130} />
                    <ExcelExportColumn field="bankBranch" title="สาขา" width={130} />
                    <ExcelExportColumn field="bankAccountName" title="ชื่อบัญชี" width={150} />
                    <ExcelExportColumn field="bankAccountNo" title="เลขบัญชี" width={150} />
                    <ExcelExportColumn field="tranningStatusData" title="ข้อมูลอบรม" width={130} />
                    <ExcelExportColumn field="status" title="สถานะ App" width={130} />
                    <ExcelExportColumn field="created" title="" width={130} />
                </ExcelExport>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายงานแม่บ้าน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/report">รายงาน</Link></li>
                                    <li className="breadcrumb-item active">แม่บ้าน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className='card'>
                            <div className="card-header">
                                <h3 className="card-title">รายชื่อแม่บ้าน</h3>
                                <div className="card-tools">
                                    <form className="input-group input-group-sm" ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let navigateObj = {
                                                page: 1,
                                                ...data
                                            }

                                            let url = Helper.getQueryStringLinkFromObject(navigateObj)
                                            navigate(url);
                                        }}
                                    >
                                        <select
                                            className="form-control placeholder_font"
                                            name="date"
                                            defaultValue={this.state.date}
                                            onChange={() => {
                                                this.$searchForm.find('[type="submit"]').trigger('click');
                                            }}
                                        >
                                            <option value="">ระยะเวลาทั้งหมด</option>
                                            <option value="7">ย้อนหลัง 7 วัน</option>
                                            <option value="30">ย้อนหลัง 30 วัน</option>
                                            <option value="thisMonth">เดือนนี้</option>

                                        </select>
                                        <select
                                            className="form-control placeholder_font"
                                            name="tranningStatus"
                                            defaultValue={this.state.tranningStatus}
                                            onChange={() => {
                                                this.$searchForm.find('[type="submit"]').trigger('click');
                                            }}
                                        >
                                            <option value="">ค้นหาจากสถานะอบรม</option>
                                            {
                                                this.state.training_date_status.map((training_date_status, training_date_status_i) => (
                                                    <option value={training_date_status.id} key={training_date_status_i}>{training_date_status.status_name}</option>
                                                ))
                                            }

                                        </select>
                                        <select
                                            className="form-control placeholder_font"
                                            name="status"
                                            defaultValue={this.state.status}
                                            onChange={() => {
                                                this.$searchForm.find('[type="submit"]').trigger('click');
                                            }}
                                        >
                                            <option value="">ค้นหาจากสถานะ</option>
                                            <option value="waitForApprove">รอการยืนยัน</option>
                                            <option value="approved">ยืนยันแล้ว</option>
                                            <option value="avaliable">ใช้งานได้ </option>
                                            <option value="ban">ระงับการใช้งาน</option>
                                            <option value="cancel">ยกเลิก</option>
                                        </select>

                                        <div className="input-group-append">
                                            {
                                                this.state.provinceCode ?
                                                    <input type="hidden" name="provinceCode" defaultValue={this.state.provinceCode} />
                                                    : null
                                            }
                                            {
                                                this.state.amphoeCode ?
                                                    <input type="hidden" name="amphoeCode" defaultValue={this.state.amphoeCode} />
                                                    : null
                                            }
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='mb-3'>
                                    <button type="button"
                                        className={this.state.is_loading ? "btn btn-default loading" : "btn btn-default"}
                                        onClick={() => {
                                            if (this.state.data.length <= 0) { return false }
                                            this._export.save(export_data);
                                        }}
                                        disabled={this.state.data.length <= 0}
                                    >
                                        <i className="far fa-file-excel"></i> ดาวน์โหลดรายงาน
                                    </button>
                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "20%", minWidth: 230 }}>ชื่อ</th>
                                            <th>เขต</th>
                                            <th style={{ minWidth: 150 }}>ข้อมูลการติดต่อ</th>
                                            <th style={{ minWidth: 150 }}>ข้อมูลอบรม</th>
                                            <th style={{ minWidth: 130 }}>สถานะ App</th>
                                            <th style={{ minWidth: 130 }}>วันที่</th>
                                            <th style={{ minWidth: 95 }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {_data.gender === "ชาย" ? <i className="fas fa-mars"></i> : null}
                                                                {_data.gender === "หญิง" ? <i className="fas fa-venus"></i> : null}
                                                                {' '}
                                                                {_data.prefix} {_data.firstName} {_data.lastName}<br />
                                                                <i className="fas fa-star" style={{ color: '#ffa200' }}></i> {_data.provider_user && _data.provider_user.starFloat !== undefined ? _data.provider_user.starFloat.toFixed(2) : '5.00'}
                                                                {
                                                                    _data.lastActive ?
                                                                        <div>ใช้งานล่าสุด {Helper.getDateThai(_data.lastActive, { is_show_time: true })}</div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.provincesCode && _data.amphoesCode
                                                                        && _data.provincesCode.length > 0 && _data.amphoesCode.length > 0 ?
                                                                        <>
                                                                            {
                                                                                Helper.showProvinceWithAmphoe(_data.provincesCode, _data.amphoesCode).map((pv, pv_i) => {
                                                                                    return (
                                                                                        <React.Fragment key={pv_i}>
                                                                                            {pv_i > 0 ? ", " : null}
                                                                                            {pv.province}
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <div>
                                                                                <div className={"btn btn-default btn-sm"}
                                                                                    onClick={() => {
                                                                                        Helper.reactToDom(window.$('body'),
                                                                                            <ProvincesAndAmphursList
                                                                                                provinces={Helper.showProvinceWithAmphoe(_data.provincesCode, _data.amphoesCode)}
                                                                                            />
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    ดูข้อมูลพื้นที่ให้บริการ
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :

                                                                        null
                                                                }

                                                            </td>
                                                            <td>
                                                                <i className="fas fa-phone"></i> {_data.phoneNumber}
                                                                {
                                                                    _data.lineID ?
                                                                        <div><i className="fab fa-line"></i> {_data.lineID}</div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className={"flex row"}>
                                                                    <div>{_data.tranningStatusData ? _data.tranningStatusData.status_name : 'ไม่มีสถานะ'}</div>
                                                                </div>
                                                                {_data.educateDate ? <div>{Helper.getDateThai(Helper.getDateToEdit(_data.educateDate))}</div> : '-'}
                                                            </td>
                                                            <td>
                                                                <div className={"flex row"}>
                                                                    <div>

                                                                        {
                                                                            _data.status === 'waitForApprove' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: '#ffa200' }} /> รอการยืนยัน</>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === 'approved' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: 'blue' }} /> ยืนยันแล้ว</>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === 'avaliable' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: 'green' }} /> ใช้งานได้</>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === 'ban' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: 'red' }} /> ระงับการใช้งาน</>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {Helper.getDateThai(_data.createdAt, { is_show_time: true })}
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <Link to={'/provider-users/' + _data.id} type="button" className="btn btn-default">ดูข้อมูล</Link>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_data.length}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                            onReturnPage={(page) => {
                                                let data = Helper.getFormInputObject(this.$searchForm);
                                                let navigateObj = {
                                                    page: page,
                                                    ...data
                                                }
                                                let url = Helper.getQueryStringLinkFromObject(navigateObj)
                                                navigate(url);
                                            }}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
