import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../../Helper';
import Pagination from '../../Components/Pagination';
import { db } from '../../firebase';
import DatePicker from 'react-datepicker';
import CustomDateInput from '../../Components/CustomDateInput';
import { salaryDevService } from '../../Services/salaryDevService';

const collectionSalary = "salary-dev";
const collectionSalaryProvider = "salary-provider-dev";
export default class DevSalary extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            all_items: 0,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : 'createdAt',
            orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : 'desc',
            is_show_next_button: false,
            data_count: 0,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                data: [],
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : 'createdAt',
                orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : 'desc',
                is_show_next_button: false,
                data_count: 0,
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetch();
            })
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let prepare_data = [];
        let count = 0;
        let all_items = 0;
        let orderByType = 'asc';
        if (this.state.orderByType) {
            orderByType = this.state.orderByType;
        }
        let salaryRef = db.collection(collectionSalary);
        if (this.state.orderBy) {
            salaryRef = salaryRef.orderBy(this.state.orderBy, orderByType)
        }
        let salary = await salaryRef.get();
        let salary_data = await Promise.all(
            salary.docs.map(async (doc) => {
                let providerRef = db.collection(`${collectionSalary}/` + doc.id + '/provider').get();
                let is_can_delete = true;
                let count_success = 0;
                (await providerRef).docs.map((_doc) => {
                    if (_doc.data().status === 'success') {
                        count_success++;
                    }
                    return true;
                })
                if (count_success > 0 || doc.data().status === 'successful') {
                    is_can_delete = false;
                }
                return { ...doc.data(), id: doc.id, is_can_delete: is_can_delete };
            })
        );

        salary_data = salary_data.filter((_data) => {
            return _data !== false
        })
        all_items = salary_data.length;
        if (this.state.status && this.state.status !== '') {
            salary_data = salary_data.filter((_data) => {
                return _data.status === this.state.status
            })
        }

        salary_data.forEach((_data, _data_i) => {
            let match_search = 0;
            if (this.state.name && this.state.name !== "") {
                match_search = _data.title.toLowerCase().indexOf(this.state.name.toLowerCase());
                if (match_search !== -1) {
                    count++;
                    prepare_data.push({ ..._data });
                }
            } else {
                count++;
                prepare_data.push({ ..._data });
            }
        })


        prepare_data = prepare_data.filter((__data, __data_i) => {
            return __data_i < (this.state.limit * this.state.page)
                && __data_i > (((this.state.limit * this.state.page) - this.state.limit) - 1)
        })


        this.setState({
            data: prepare_data,
            all_items: all_items,
            data_count: count,
            is_loading: false,
        })
    }

    setOrderBy(orderBy) {
        let orderByType = 'asc';
        if (this.state.orderByType && this.state.orderByType === 'asc') {
            orderByType = 'desc';
        }
        this.setState({
            orderBy: orderBy,
            orderByType: orderByType
        }, () => {
            this.$searchForm.find('[type="submit"]').trigger('click');
        })
    }

    async delete(data) {
        await db.doc(`${collectionSalary}/` + data.id).delete();
        this.fetch();
        window.$(document).Toasts('create', {
            title: 'ลบข้อมูลสำเร็จ',
            // body: 'ทำการลบข้อมูลสำเร็จ',
            icon: 'fas fa-check',
            autohide: true,
            delay: 3000,
            class: "bg-success",
        })
    }

    async onRevert(options) {
        try {
            let res = await salaryDevService.revert();// api here;
            if (!res.error) {
                window.$(document).Toasts('create', {
                    title: 'Revert ข้อมูลสำเร็จ',
                    // body: 'ทำการลบข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                this.setState({
                    is_loading: true,
                }, () => {
                    this.fetch();
                })
            } else {
                if (options?.clearLoadingButton) {
                    options.clearLoadingButton()
                }
                window.$(document).Toasts('create', {
                    title: 'เกิดข้อผิดพลาด!',
                    body: 'กรุณาลองใหม่อีกครั้ง',
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
        } catch (error) {
            if (options?.clearLoadingButton) {
                options.clearLoadingButton()
            }
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด!',
                body: 'กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
        }

    }


    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการรายได้ DEV</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">จัดการรายได้</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายการโอนเงิน {this.state.all_items} รายการ</h3>
                                <div className={"card-right"} />
                                <div className="card-tools">
                                    <form className="input-group input-group-sm"
                                        ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let obj = {};
                                            obj.page = 1;
                                            obj.status = data.status;
                                            obj.name = data.name;
                                            if (data.orderBy) {
                                                obj.orderBy = data.orderBy
                                            }
                                            if (data.orderByType) {
                                                obj.orderByType = data.orderByType
                                            }
                                            let url = Helper.getQueryStringLinkFromObject({ ...obj }, `/${collectionSalary}`)
                                            navigate(url)
                                        }}
                                    >
                                        <select
                                            className="form-control placeholder_font"
                                            name="status"
                                            defaultValue={this.state.orderBy}
                                            onChange={() => {
                                                this.$searchForm.find('[type="submit"]').trigger('click');
                                            }}
                                        >
                                            <option value="">ทั้งหมด</option>
                                            <option value="successful">สำเร็จ</option>
                                            <option value="unsuccessful">ไม่สำเร็จ</option>
                                        </select>
                                        {
                                            this.state.orderBy ?
                                                <>
                                                    <input name="orderBy" type="hidden" value={this.state.orderBy} />
                                                    <input name="orderByType" type="hidden" value={this.state.orderByType} />
                                                </>
                                                : null
                                        }
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากหัวข้อ" defaultValue={this.state.name} />
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <div className="flex row">
                                        <div>
                                            <button className="btn btn-primary"
                                                disabled={this.state.is_loading}
                                                onClick={() => {
                                                    if (this.state.is_loading) { return }
                                                    Helper.reactToDom(window.$('body'),
                                                        <CreateSalaryForm
                                                            data={this.state.data}
                                                            admin={this.props.admin}
                                                            onSuccess={(id) => {
                                                                navigate(`${collectionSalaryProvider}/` + id)
                                                            }}
                                                        />
                                                    )
                                                }}
                                            >
                                                สร้างรายการโอนเงิน
                                            </button>
                                        </div>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <button className="btn btn-danger"
                                                disabled={this.state.is_loading}
                                                ref={(ref) => {
                                                    window.$(ref).off().on('click', () => {
                                                        if (window.$(ref).hasClass('loading')) { return; }
                                                        window.$(ref).addClass('loading');
                                                        Helper.confirmModal({
                                                            title: "ยืนยันการกู้ข้อมูล",
                                                            description: "ถ้าหากทำการกู้ข้อมูล, จะย้อนค่าของรอบบิลล่าสุดและต้องทำรายการชำระเงินแม่ใหม่เท่านั้น ?",
                                                            submit_text: 'ลบ',
                                                            onConfirm: () => {

                                                                this.onRevert({
                                                                    clearLoadingButton: () => { window.$(ref).removeClass('loading') }
                                                                });
                                                            },
                                                            onCancel: () => {
                                                                window.$(ref).removeClass('loading');
                                                            }
                                                        });
                                                    })
                                                }}
                                            >
                                                Revert
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className={"flex row"}>
                                                    วันที่
                                                </div>
                                            </th>
                                            <th >
                                                <div className={"flex row"}>
                                                    ยอดเงินทั้งหมด
                                                </div>
                                            </th>
                                            <th>สถานะ</th>
                                            <th>หมายเหตุ</th>
                                            <th style={{ width: 180 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                <div>{_data.title}</div>
                                                                <div>{Helper.getDateThai(_data.createdAt, { is_show_time: true })}</div>
                                                            </td>
                                                            <td><div>{Helper.numberFormat(_data.totalPrice)}</div></td>
                                                            <td>
                                                                <div>
                                                                    {
                                                                        _data.status === 'unsuccessful' ?
                                                                            <div style={{ color: 'red' }}>ยังไม่สำเร็จ</div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        _data.status === 'successful' ?
                                                                            <div style={{ color: 'green' }}>สำเร็จ</div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        _data.status === 'revert' ?
                                                                            <div style={{ color: '#ccc' }}>กู้ข้อมูล</div>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {
                                                                        _data.note ?
                                                                            _data.note
                                                                            : '-'
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="flex row">
                                                                    <div className="btn-group">
                                                                        <Link className="btn btn-default" to={`/${collectionSalaryProvider}/` + _data.id} >ดูข้อมูล</Link>
                                                                        {
                                                                            _data.is_can_delete ?
                                                                                <button type="button"
                                                                                    className="btn btn-danger"
                                                                                    ref={(ref) => {
                                                                                        window.$(ref).off().on('click', () => {
                                                                                            if (window.$(ref).hasClass('loading')) { return; }
                                                                                            window.$(ref).addClass('loading');
                                                                                            Helper.confirmModal({
                                                                                                title: "ยืนยันการลบ",
                                                                                                description: "ต้องการลบรายการโอนเงินนี้หรือไม่ ?",
                                                                                                submit_text: 'ลบ',
                                                                                                onConfirm: () => {
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                    this.delete(_data);
                                                                                                },
                                                                                                onCancel: () => {
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                }
                                                                                            });
                                                                                        })
                                                                                    }}
                                                                                >ลบ</button>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class CreateSalaryForm extends React.Component {
    constructor(props) {
        super(props);
        let is_can_add = props.data.filter((_data) => { return _data.status === "unsuccessful" }).length === 0;

        this.state = {
            disable_all: false,
            is_can_add: is_can_add,
            start_date: new Date().getTime(),
            end_date: new Date().getTime(),
        }
    }

    async add(data) {

        console.log(data)
        const res = await salaryDevService.create(data)
        this.$modal.modal('hide');
        if (res.error) {
            window.$(document).Toasts('create', {
                title: res.response.data.code,
                body: res.response.data.message,
                icon: 'fas fa-check',
                autohide: true,
                delay: 10000,
                class: "bg-danger",
            });
            return;
        }

        if (this.props.onSuccess) {
            this.props.onSuccess(res.uid);
        }
        // await db.collection('salary-dev').add({ ...data }).then(async (documentReference) => {
        //     provider_data.map(async (_provider_data, _provider_data_i) => {
        //         await db.doc(`salary-dev/` + documentReference.id + '/provider/' + _provider_data.providerUID).set({ ..._provider_data });
        //     })
        //     window.$(document).Toasts('create', {
        //         title: 'สร้างรายการโอนเงินสำเร็จ',
        //         // body: 'ทำการลบข้อมูลสำเร็จ',
        //         icon: 'fas fa-check',
        //         autohide: true,
        //         delay: 10000,
        //         class: "bg-success",
        //     });
        // });

    }
    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard="false" data-backdrop="static"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) { return; }
                        this.setState({
                            disable_all: true
                        }, () => {
                            this.$submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$searchForm);
                            data.createdAt = new Date().getTime();
                            data.createdAdminUid = this.props.admin.id.stringValue;
                            this.add(data);
                        })
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                สร้างรายการโอนเงิน
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>หัวข้อ</label>
                                <input name="title" className="form-control" placeholder="ระบุหัวข้อ" required={true} readOnly={this.state.disable_all} />
                            </div>
                            <div className='row'>
                                <div className='col-12 col-lg-6 form-group'>
                                    <label>ตั้งแต่วันที่</label>
                                    <DatePicker
                                        disabled={this.state.is_loading}
                                        onChange={date => {

                                            let start = new Date(date);
                                            start.setHours(0, 0, 0, 0);
                                            this.setState({
                                                start_date: start.getTime()
                                            }
                                                // , () => {
                                                //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                // }
                                            )
                                        }}
                                        selectsStart
                                        startDate={this.state.start_date}
                                        endDate={this.state.end_date}
                                        locale="th-TH"
                                        selected={Helper.getDateToEdit(this.state.start_date)}
                                        placeholderText={"วัน / เดือน / ปี"}
                                        name={"start_date"}
                                        dateFormat="MMMM d, yyyy 00:00:00"
                                        withPortal
                                        onCalendarOpen={() => { Helper.renderDate() }}
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => {
                                            date = new Date(date);
                                            let now = new Date();
                                            // months_th
                                            const years = [];
                                            let i;
                                            for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                years.push(i)
                                            }
                                            return (
                                                <div
                                                    style={{
                                                        margin: 10,
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                        className={"btn btn-primary "}
                                                        type={"button"}
                                                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                    >
                                                        <i className="fas fa-chevron-left" />
                                                    </button>
                                                    <select
                                                        className={"form-control"}
                                                        value={date.getFullYear()}
                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                        style={{ borderRadius: 0 }}
                                                    >
                                                        {years.map(option => (
                                                            <option key={option} value={option} >
                                                                {option + 543}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <select
                                                        className={"form-control"}
                                                        value={window.months_th[date.getMonth()]}
                                                        onChange={({ target: { value } }) =>
                                                            changeMonth(window.months_th.indexOf(value))
                                                        }
                                                        style={{ borderRadius: 0 }}
                                                    >
                                                        {window.months_th.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                        className={"btn btn-primary"}
                                                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                        type={"button"}
                                                    >
                                                        <i className="fas fa-chevron-right" />
                                                    </button>
                                                </div>
                                            )
                                        }}
                                        customInput={<CustomDateInput />}
                                    />
                                    <input type="hidden" id="start_date" name="start_date" value={this.state.start_date} onChange={() => { console.log('change') }} readOnly={true} />
                                </div>
                                <div className='col-12 col-lg-6 form-group'>
                                    <label>ถึงวันที่</label>
                                    <DatePicker
                                        disabled={this.state.is_loading}
                                        onChange={date => {
                                            let end = new Date(date);
                                            end.setHours(23, 59, 0, 0);
                                            this.setState({
                                                end_date: end.getTime()
                                            }
                                                // , () => {
                                                //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                // }
                                            )
                                        }}
                                        selectsEnd
                                        startDate={this.state.start_date}
                                        endDate={this.state.end_date}
                                        locale="th-TH"
                                        selected={Helper.getDateToEdit(this.state.end_date)}
                                        placeholderText={"วัน / เดือน / ปี"}
                                        name={"end_date"}
                                        dateFormat="MMMM d, yyyy 23:59:00"
                                        minDate={this.state.start_date}
                                        withPortal
                                        onCalendarOpen={() => { Helper.renderDate() }}
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => {
                                            date = new Date(date);
                                            let now = new Date();
                                            // months_th
                                            const years = [];
                                            let i;
                                            for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                years.push(i)
                                            }
                                            return (
                                                <div
                                                    style={{
                                                        margin: 10,
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                        className={"btn btn-primary "}
                                                        type={"button"}
                                                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                    >
                                                        <i className="fas fa-chevron-left" />
                                                    </button>
                                                    <select
                                                        className={"form-control"}
                                                        value={date.getFullYear()}
                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                        style={{ borderRadius: 0 }}
                                                    >
                                                        {years.map(option => (
                                                            <option key={option} value={option} >
                                                                {option + 543}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <select
                                                        className={"form-control"}
                                                        value={window.months_th[date.getMonth()]}
                                                        onChange={({ target: { value } }) =>
                                                            changeMonth(window.months_th.indexOf(value))
                                                        }
                                                        style={{ borderRadius: 0 }}
                                                    >
                                                        {window.months_th.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                        className={"btn btn-primary"}
                                                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                        type={"button"}
                                                    >
                                                        <i className="fas fa-chevron-right" />
                                                    </button>
                                                </div>
                                            )
                                        }}
                                        customInput={<CustomDateInput />}
                                    />
                                    <input type="hidden" id="end_date" name="end_date" value={this.state.end_date} onChange={() => { console.log('change') }} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>หมายเหตุ</label>
                                <textarea className="form-control" name="note" readOnly={this.state.disable_all}></textarea>
                            </div>
                            <input type="hidden" name="status" value="unsuccessful" />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal" disabled={this.state.disable_all}>ยกเลิก</button>
                            <button type="submit" className="btn btn-primary">สร้าง</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}