import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import { db, storage } from '../firebase';
import Pagination from '../Components/Pagination';
const axios = require('axios').default;
const CancelToken = axios.CancelToken;
let cancel;

export default class Users extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            phoneNumber: Helper.getParameterByName('phoneNumber') ? Helper.getParameterByName('phoneNumber') : '',
            email: Helper.getParameterByName('email') ? Helper.getParameterByName('email') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            is_show_next_button: false,
            users_count: 0,
            all_items: 0,
            user_type: Helper.getParameterByName('user_type') ? Helper.getParameterByName('user_type') : 'all',
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                phoneNumber: Helper.getParameterByName('phoneNumber') ? Helper.getParameterByName('phoneNumber') : '',
                email: Helper.getParameterByName('email') ? Helper.getParameterByName('email') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                user_type: Helper.getParameterByName('user_type') ? Helper.getParameterByName('user_type') : 'all',
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: '',
                phoneNumber: '',
                email: '',
                user_type: 'all',
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        if (cancel !== undefined) cancel();
        let filter = {
            page: this.state.page,
            limit: this.state.limit,
        };
        if (this.state.name) {
            filter.name = this.state.name;
        }
        if (this.state.status) {
            filter.status = this.state.status;
        }
        if (this.state.user_type) {
            filter.user_type = this.state.user_type;
        }
        if (this.state.phoneNumber) {
            let phone = this.state.phoneNumber;
            let phoneCheck = phone.charAt(0);
            if (phoneCheck == '0') {
                phone = '+66' + phone.substr(1, 9);
            }
            filter.phoneNumber = phone;
        }
        if (this.state.email) {
            filter.email = this.state.email;
        }
        if (this.state.all_items == 0) {
            filter.requestCountAll = true
        }
        filter.requestCountItems = true
        let res = await axios.get(`${window.api_host}/users?${window.$.param(filter)}`, {
            cancelToken: new CancelToken(c => cancel = c),
        });
        let users = [];
        let users_count = this.state.users_count;
        let count = this.state.all_items;
        if (res.data.success) {
            users = res.data.users;
            if (res.data.count_all != undefined) {
                users_count = res.data.count_all;
                if (this.state.all_items == 0) {
                    count = res.data.count_all;
                }
            }
            if (res.data.count_items) {
                count = res.data.count_items;
            }
        }
        this.setState({
            data: users,
            is_loading: false,
            all_items: count,
            users_count: users_count,
        })
    }

    delete(data) {
        if (data.transferImageUrl) {
            storage.ref(data.transferImageUrl).delete();
        }
        let documentRef = db.doc(`users/` + data.id);
        documentRef.delete().then(() => {
            this.fetchData();
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
            })
        });
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">สมาชิก</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">สมาชิก</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex', alignItems: 'center' }}>
                                <h3 className="card-title">รายชื่อสมาชิก</h3>
                                <div className="btn-group" style={{ marginLeft: 15 }}>
                                    <button
                                        className={this.state.user_type === 'all' ? "btn btn-default active" : "btn btn-default"}
                                        type={'button'}
                                        onClick={() => {
                                            let obj = {
                                                page: 1,
                                            }
                                            if (this.state.name) {
                                                obj.name = this.state.name
                                            }
                                            obj.user_type = 'all';
                                            let url = Helper.getQueryStringLinkFromObject({ ...obj }, '/users')
                                            navigate(url)
                                        }}
                                    >ทั้งหมด</button>
                                    <button
                                        className={this.state.user_type === 'formal' ? "btn btn-default active" : "btn btn-default"}
                                        type={'button'}
                                        onClick={() => {
                                            let obj = {
                                                page: 1,
                                            }
                                            if (this.state.name) {
                                                obj.name = this.state.name
                                            }
                                            obj.user_type = 'formal';
                                            let url = Helper.getQueryStringLinkFromObject({ ...obj }, '/users')
                                            navigate(url)
                                        }}
                                    >ในระบบ</button>
                                    <button
                                        className={this.state.user_type === 'informal' ? "btn btn-default active" : "btn btn-default"}
                                        type={'button'}
                                        onClick={() => {
                                            let obj = {
                                                page: 1,
                                            }
                                            if (this.state.name) {
                                                obj.name = this.state.name
                                            }
                                            obj.user_type = 'informal';
                                            let url = Helper.getQueryStringLinkFromObject({ ...obj }, '/users')
                                            navigate(url)
                                        }}
                                    >นอกระบบ</button>
                                </div>
                                <div className={"card-right"}>
                                    <div style={{ marginRight: 10 }}>จำนวน {this.state.all_items} / {this.state.users_count} คน</div>
                                </div>
                                <div className="card-tools">
                                    <form className="input-group input-group-sm" ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let redirect_data = {
                                                page: 1,
                                                ...data
                                            }
                                            let url = Helper.getQueryStringLinkFromObject(redirect_data, '/users')
                                            navigate(url)
                                        }}
                                    >
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากชื่อ" defaultValue={this.state.name} />
                                        <input type="text" name="phoneNumber" className="form-control float-right" placeholder="ค้นหาจากเบอร์โทรศัพท์" defaultValue={this.state.phoneNumber} />
                                        <input type="text" name="email" className="form-control float-right" placeholder="ค้นหาจากอีเมล์" defaultValue={this.state.email} />

                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className='mb-3'>
                                    <Link className='btn btn-default ml-0' to="/suspend-phonenumber">ระงับใช้งานเบอร์โทรศัพท์</Link>
                                    <Link className='btn btn-default ml-3' to="/users/report">รายงานสมาชิก</Link>
                                    <Link className='btn btn-default ml-3' to="/review/customer">รีวิวสมชิกทั้งหมด</Link>
                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>ชื่อ</th>
                                            <th>อีเมล</th>
                                            <th>ข้อมูลการติดต่อ</th>
                                            <th style={{ width: 100 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {_data.displayName}
                                                                {
                                                                    _data.guestPhoneNumber ?
                                                                        <>{' '}<span className={"badge badge-info"}>นอกระบบ</span></>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.lastActive ?
                                                                        <div>ใช้งานล่าสุด {Helper.getDateThai(_data.lastActive, { is_show_time: true })}</div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {_data.email || null}
                                                                {_data.guestEmail || null}
                                                            </td>
                                                            <td>
                                                                {_data.phoneNumber || null}
                                                                {_data.guestPhoneNumber || null}
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <Link to={'/user/' + _data.uid} type="button" className="btn btn-default">ดูข้อมูล</Link>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="4" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}