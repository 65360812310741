import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import { db } from '../firebase';
import Pagination from '../Components/Pagination';
// const axios = require('axios').default;

export default class MarketingUsers extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            is_show_next_button: false,
            users_count: 0,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    fetchData() {

        db.collection("market_users").orderBy('name', 'asc').get().then(async (doc) => {
            let data = [];
            let prepare_data = [];
            let count = 0;
            let users_count = 0;
            let get_data = await Promise.all(
                doc.docs.map(async (element) => {
                    return {
                        ...element.data(),
                        id: element.id
                    };

                })
            );
            users_count = get_data.length;
            get_data = get_data.filter((_data) => {
                return _data !== false && _data.status !== 'ban'
            })
            if (this.state.status && this.state.status !== "") {
                get_data = get_data.filter((_data) => {
                    return _data.status === this.state.status
                })
            }

            get_data.forEach((_data, _data_i) => {
                let match_search = 0;
                if (this.state.name && this.state.name !== "") {
                    match_search = _data.name.indexOf(this.state.name);
                    if (match_search !== -1) {
                        count++;
                        prepare_data.push({ ..._data });
                    }
                } else {
                    prepare_data.push({ ..._data });
                }
            })
            data = prepare_data.filter((__data, __data_i) => {
                return __data_i < (this.state.limit * this.state.page)
                    && __data_i > (((this.state.limit * this.state.page) - this.state.limit) - 1)
            })
    
            let data_filter = await Promise.all(
                data.map(async (element_data)=> {
                    let _element_data = {
                            ...element_data,
                    }
                    let promotion = [];
                    let promotionRef =  await db.collection('promotions').get();
                    for (const doc of promotionRef.docs) {
                        if (doc.data().marketUid === element_data.id) {
                            promotion.push(doc.data());
                        }
                    }
                    element_data.promotion = promotion;
                    return _element_data;
                })
            )

            this.setState({
                data: data_filter,
                is_loading: false,
                all_items: count,
                users_count: users_count,
            })
        })
    }

    delete(data) {
        let documentRef = db.doc(`market_users/` + data.id);
        if (data.promotion && data.promotion.length > 0) {
            documentRef.update({ status: 'ban' }).then(() => {
                this.fetchData();
                window.$(document).Toasts('create', {
                    title: 'ลบข้อมูลสำเร็จ',
                    body: 'ทำการลบข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
            });
        }
        if ((data.promotion && data.promotion.length === 0) || !data.promotion) {
            documentRef.delete().then(() => {
                this.fetchData();
                window.$(document).Toasts('create', {
                    title: 'ลบข้อมูลสำเร็จ',
                    body: 'ทำการลบข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
            });
        }
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ผู้รับผิดชอบ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/promotion">จัดการโปรโมชั่น</Link></li>
                                    <li className="breadcrumb-item active">ผู้รับผิดชอบ</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายชื่อผู้รับผิดชอบ</h3>
                                <div style={{ marginLeft: 15 }}>
                                    <button className={"btn btn-primary btn-xs"}
                                        onClick={() => {
                                            Helper.reactToDom(
                                                window.$('body'),
                                                <MarketingUsersForm
                                                    mode={"create"}
                                                    onDone={() => {
                                                        this.fetchData();
                                                    }}
                                                />
                                            );
                                        }}
                                    >เพิ่มผู้รับผิดชอบ</button>
                                </div>
                                <div className={"card-right"}>
                                    <div style={{ marginRight: 10 }}>ทั้งหมด {this.state.all_items} คน</div>
                                </div>
                                <div className="card-tools">
                                    <form className="input-group input-group-sm" ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                page: 1,
                                                name: data.name,
                                            }, '/users')
                                            navigate(url)
                                        }}
                                    >
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากชื่อ" defaultValue={this.state.name} />

                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>ชื่อ</th>
                                            <th style={{ width: 150 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>

                                                                {_data.name}
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">

                                                                    <Link to={"/marketing-users/" + _data.id} className={"btn btn-default"}>
                                                                        ดูข้อมูล
                                                                    </Link>
                                                                    <button type="button" className="btn btn-danger"
                                                                        ref={(ref) => {
                                                                            window.$(ref).off().on('click', () => {
                                                                                if (window.$(ref).hasClass('loading')) { return; }
                                                                                window.$(ref).addClass('loading');
                                                                                Helper.confirmModal({
                                                                                    title: "ยืนยันลบผู้รับผิดชอบ",
                                                                                    description: "ต้องการลบผู้รับผิดชอบท่านนี้หรือไม่ ?",
                                                                                    onConfirm: () => {
                                                                                        window.$(ref).removeClass('loading');
                                                                                        this.delete(_data);
                                                                                    },
                                                                                    onCancel: () => {
                                                                                        window.$(ref).removeClass('loading');
                                                                                    }
                                                                                });
                                                                            })
                                                                        }
                                                                        }
                                                                    >ลบ</button>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="4" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
class MarketingUsersForm extends React.Component {
    create(data) {
        db.collection('market_users').add({ ...data }).then(() => {
            this.$modal.modal('hide');
            let $submit = this.$form.find('button[type="submit"]');
            $submit.removeClass('loading');
            let settime = window.setTimeout(() => {
                this.$modal.remove();
                clearTimeout(settime);
            }, 500)
            if (this.props.onDone) {
                this.props.onDone();
            }
        })
    }


    render() {
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.$form = this.$modal.find('form');
                        let $submit = this.$form.find('button[type="submit"]');
                        if ($submit.hasClass('loading')) { return false; }
                        $submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        // console.log(data);
                        this.create(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                เพิ่มผู้รับผิดชอบ
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className={"form-group"}>
                                <label>ชื่อผู้รับผิดชอบ</label>
                                <input name={"name"} className={"form-control"} />
                                <input name={"status"} type="hidden" value="avaliable"/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default"
                                onClick={() => {
                                    let $submit = this.$form.find('button[type="submit"]');
                                    $submit.removeClass('loading');
                                    this.$modal.modal('hide');
                                }}
                            >ยกเลิก</button>
                            {
                                this.props.mode === 'create' || this.props.mode === 'edit' ?
                                    <button className={"btn btn-primary"} type={"submit"}>บันทึก</button>
                                    : null
                            }
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}