import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../Helper';
import { db } from '../firebase';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Pagination from '../Components/Pagination';

export default class ViewRatingUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            user: {},
            user_id: props.user_id,
        }
    }

    componentDidMount() {
        this.fetchUser();
    }
    async fetchUser() {
        let user = await db.doc("users/" + this.state.user_id).get();
        let report = await db.collection("users/" + this.state.user_id + "/report").orderBy('createdAt', 'desc').get();
        let report_data = [];
        for (const _report of report.docs) {
            let providerRef = await db.doc("provider_users_data/" + _report.data().providerUid).get();
            let provider_user = {}
            provider_user = providerRef.data();
            report_data.push({ ..._report.data(), provider_user: provider_user });
        }
        let _user = {
            ...user.data(),
            id: user.id,
            report: report_data
        };
        this.setState({
            user: _user,
            is_loading: false,
        }, () => {
            window.$.Thailand({
                $amphoe: this.amphoe, // input ของอำเภอ
                $province: this.province, // input ของจังหวัด
                templates: {
                    empty: ' ',
                    suggestion: function (data) {
                        console.log(data);
                        return '<div>' + data.amphoe + ' » ' + data.province + '</div>';
                    }
                }
            });
        })
    }


    render() {
        
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รีวิวสมาชิก</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/users">สมาชิก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/user/" + this.state.user_id}>ข้อมูลสมาชิก</Link></li>
                                    <li className="breadcrumb-item active">รีวิวสมาชิก</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.user ?
                                <>
                                    <div className={"row"}>
                                        <div className="col-lg-4">
                                            <div className="card">
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h3 className="card-title">
                                                        สมาชิก
                                                    </h3>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        ทั้งหมด {
                                                            this.state.user &&
                                                                this.state.user.report ?
                                                                this.state.user.report.length
                                                                : 0
                                                        } รีวิว
                                                    </div>
                                                </div>
                                                <div className="card-body" >
                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-6">
                                                            <img src={this.state.user.photoURL ? this.state.user.photoURL : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                        </div>
                                                        <div className="col-sm-12 col-lg-6">
                                                            <div className={"rating_ui right"}>
                                                                <span>
                                                                    {
                                                                        this.state.user
                                                                            && this.state.user.starFloat !== undefined ?
                                                                            this.state.user.starFloat.toFixed(1)
                                                                            : '5.0'
                                                                    }
                                                                </span>
                                                                <i className="fas fa-star"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {this.state.user.displayName}
                                                    </div>
                                                    <div className={"form-group"}>
                                                        {this.state.user.phoneNumber}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            {
                                                this.state.user && this.state.user.report ?
                                                    this.state.user.report.map((report, report_i) => {
                                                        return (
                                                            (
                                                                <div className="card">
                                                                    <div className="card-header"
                                                                        style={{ display: 'flex', flexDirection: 'row' }}
                                                                        key={report_i}
                                                                    >
                                                                        <h3 className="card-title">
                                                                            Booking No : {report ? report.id : null} {report.createdAt ? <div className={"beeclean-block"}>{Helper.getDateThai(new Date(report.createdAt), { is_show_time: true })}</div> : null}
                                                                        </h3>
                                                                    </div>
                                                                    <div className="card-body" >
                                                                        <div className="row">
                                                                            <div className="col-sm-12 col-lg-2">
                                                                                <img src={report.provider_user && report.provider_user.profileImage ? report.provider_user.profileImage : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                                            </div>
                                                                            <div className="col-sm-12 col-lg-6">
                                                                                <div>{report.provider_user && report.provider_user.firstName ? report.provider_user.prefix + ' ' + report.provider_user.firstName + ' ' + report.provider_user.lastName : '-'}</div>
                                                                                <div>{report.provider_user && report.provider_user.phoneNumber ? report.provider_user.phoneNumber : null}</div>
                                                                                <div>{report.report || null}</div>
                                                                                <div className={"rating_ui"}>
                                                                                    <span>
                                                                                        {report.star ? report.star.toFixed(1) : "5.0"}
                                                                                    </span>
                                                                                    <i className="fas fa-star"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12 col-lg-4">

                                                                                {
                                                                                    report.imageUris ?
                                                                                        <>
                                                                                            <Slider {...settings} style={{ marginBottom: '1rem' }}>
                                                                                                {
                                                                                                    report.imageUris.map((photoURL, photoURL_i) => (
                                                                                                        <div key={photoURL_i}>
                                                                                                            <a href={photoURL} data-fancybox={"gallery" + report_i}>
                                                                                                                <img src={photoURL} alt={"BeeCleanphotoURL"} style={{ maxWidth: '100%', height: 300, margin: 'auto' }} />
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    ))
                                                                                                }
                                                                                            </Slider>
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                    })
                                                    : null
                                            }

                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                </section>
            </>
        );
    }
}