import React from 'react';
import { Link } from '@reach/router';
import { db, storage } from './../firebase';
import { Helper, SortableContainer, SortableItem, DragHandle } from './../Helper';

export default class ManagePromotionSlide extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            is_sequence_loading: false,
            promotion_slides: [],
            max_sequence: 1,
        }

    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let promotion_slide_ref = db.collection('promotion_slides').orderBy('sequence', 'asc');
        let max_sequence = this.state.max_sequence;
        let promotion_slides = await (await promotion_slide_ref.get()).docs.map((doc) => {
            if (max_sequence < doc.data().sequence) {
                max_sequence = doc.data().sequence
            }
            return { ...doc.data(), id: doc.id };
        });
        // console.log(max_sequence);
        // console.log(promotion_slides);
        this.setState({
            promotion_slides: promotion_slides,
            is_loading: false,
            is_sequence_loading: false,
            max_sequence: parseFloat(max_sequence) + 1,
        })
    }

    async uploadFile(node, options) {
        let id = this.state.provider_id;
        let upload_btn = node.parent().find('.btn');
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        const filename = Helper.setNewImageFileName(file);
        let reszieFile = await Helper.resizeImageFn(file, { maxWidth: 1280, maxHeight: 768 });
        var uploadTask = storage.ref().child('provider_users_data/' + id + '/' + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log(snapshot);
        }, (error) => {
            console.log(error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            upload_btn.removeClass('loading');
        }, async () => {
            let url = 'provider_users_data/' + id + '/' + filename;
            if (options.onSuccess) {
                options.onSuccess(url);
            }
        });
    }

    async delete(data) {
        if (data.imagePath) {
            storage.ref(data.imagePath).delete();
        }
        let documentRef = db.doc(`promotion_slides/` + data.id);
        documentRef.delete().then(async () => {
            this.resetSequence();
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
        });
    }

    async resetSequence() {
        let promotion_slide_ref = await db.collection('promotion_slides').orderBy('sequence', 'asc').get();
        if (!promotion_slide_ref.empty) {
            let i = 1;
            for (const doc of promotion_slide_ref.docs) {
                await db.collection('promotion_slides').doc(doc.id).update({ sequence: i });
                i++;
                if (i > promotion_slide_ref.size) {
                    this.fetch();
                }
            }
        }
    }

    setSequence(oldIndex, newIndex) {
        if (oldIndex === newIndex) { return; }
        // alert("setSequence");
        let update_sequence = [];
        let before_move = parseFloat(this.state.promotion_slides[oldIndex].sequence);
        let after_move = parseFloat(this.state.promotion_slides[newIndex].sequence);
        let move_slide_id = this.state.promotion_slides[oldIndex].id;
        update_sequence.push({
            id: move_slide_id,
            sequence: after_move,
        })
        let move_type = 'moveup';
        if (after_move < before_move) {
            move_type = 'movedown';
        }
        this.state.promotion_slides.map((promotion_slide) => {
            let sq = parseFloat(promotion_slide.sequence);
            if (move_type === 'movedown'
                && sq >= after_move
                && sq < before_move
                && promotion_slide.id !== move_slide_id
            ) {
                update_sequence.push({
                    id: promotion_slide.id,
                    sequence: sq + 1,
                })
            }
            if (move_type === 'moveup'
                && sq <= after_move
                && sq > before_move
                && promotion_slide.id !== move_slide_id
            ) {
                update_sequence.push({
                    id: promotion_slide.id,
                    sequence: sq - 1,
                })
            }

            return true;
        });



        this.setState({
            is_sequence_loading: true,
        }, async () => {
            await update_sequence.map(async (_update_sequence, update_sequence_i) => {
                await db.doc('promotion_slides/' + _update_sequence.id).update({ sequence: _update_sequence.sequence });
                if ((update_sequence_i + 1) === update_sequence.length) {
                    await this.resetSequence()
                }
                return true;
            });
        })

    }

    render() {
        // console.log(this.state)
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการสไลด์โปรโมชั่น</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">จัดการสไลด์โปรโมชั่น</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <h4 className="card-title" id="myModalLabel">จัดการสไลด์โปรโมชั่น</h4>
                            </div>
                            <div className={"card-body"}>
                                {/* <div className={"form-group"}>
                                    <input name="slideImage"
                                        type={"file"}
                                        ref={(ref) => this.$slideImage = window.$(ref)}
                                        style={{ display: 'none' }}
                                        id="file_image_idcard"
                                        onChange={(e) => {
                                            if (!this.$slideImage[0].files[0]) { return false; }
                                            let upload_btn = this.$slideImage.parent().find('.btn');
                                            this.uploadFile(this.$slideImage, {
                                                onSuccess: async (url) => {
                                                    let slideImageUrl = url;
                                                    let slideImage = await storage.ref(url).getDownloadURL();
                                                    let data = {
                                                        createAt: new Date().getTime(),
                                                        updateAt: new Date().getTime(),
                                                    }
                                                    data.imageURL = slideImage;
                                                    data.imagePath = slideImageUrl;
                                                    data.sequence = this.state.promotion_slides.length + 1;
                                                    db.collection('promotion_slides').add({ ...data }).then((res) => {
                                                        upload_btn.removeClass('loading');
                                                        window.$(document).Toasts('create', {
                                                            title: 'อัพโหลดรูปสไลด์สำเร็จ',
                                                            body: 'ทำการอัพโหลดรูปสไลด์สำเร็จ',
                                                            icon: 'fas fa-file-upload',
                                                            autohide: true,
                                                            delay: 3000,
                                                            class: "bg-success",
                                                        })
                                                        this.fetch()
                                                    })
                                                }
                                            });
                                        }}
                                    />
                                    <div id="show_image" />
                                    <label htmlFor="file_image_idcard"
                                        className="btn btn-default"
                                        style={{ marginBottom: 0 }}
                                    >
                                        อัพโหลดรูปสไลด์
                                    </label>
                                </div> */}

                                <div className={"form-group"}>
                                    <button
                                        ref={(ref) => {
                                            this.$addBtn = window.$(ref);
                                            this.$addBtn.off().on('click', async () => {
                                                if (this.$addBtn.hasClass('loading')) { return; }
                                                await this.$addBtn.addClass('loading');
                                                await Helper.reactToDom(window.$('body'),
                                                    <AddSlide
                                                        id={this.state.provider_id}
                                                        onSuccess={() => {
                                                            this.resetSequence();
                                                        }}
                                                        promotion_slides={this.state.promotion_slides}
                                                        mode="create"
                                                    />
                                                )
                                                this.$addBtn.removeClass('loading')
                                            })
                                        }}
                                        className="btn btn-default"
                                    >เพิ่มสไลด์</button>
                                </div>
                                <SortableContainer key={"promotion-slider"}
                                    onSortEnd={({ oldIndex, newIndex }) => {
                                        this.setSequence(oldIndex, newIndex);
                                    }}
                                    useDragHandle
                                    axis={'xy'}
                                >
                                    <div className={"row"}>

                                        {
                                            this.state.is_loading ?
                                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                                    <div className="ui active inline loader" ></div>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.is_sequence_loading ?
                                                <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                                                : null
                                        }
                                        {
                                            !this.state.is_loading ?
                                                this.state.promotion_slides.map((promotion_slide, promotion_slide_i) => {
                                                    return (
                                                        <SortableItem
                                                            key={promotion_slide_i}
                                                            index={promotion_slide_i}
                                                        >
                                                            <div
                                                                className={"col-sm-12 col-lg-3"}
                                                                key={promotion_slide_i}
                                                            >
                                                                <div className={"card"}>
                                                                    <div className={"card-body"} style={{ padding: '0.5rem' }}>
                                                                        <div className={"promotion-slide-frame"}>
                                                                            <div className="promotion-drag-btn">
                                                                                <DragHandle key={`item-${promotion_slide_i}`}>
                                                                                    <button className={"btn btn-default"}>
                                                                                        <i className="fas fa-arrows-alt" />
                                                                                    </button>
                                                                                </DragHandle>
                                                                            </div>
                                                                            <div className={"promotion-delete"}>
                                                                                <button
                                                                                    className={"btn btn-default"}
                                                                                    style={{ marginRight: 10 }}
                                                                                    ref={(ref) => {
                                                                                        window.$(ref).off().on('click', async () => {
                                                                                            if (window.$(ref).hasClass('loading')) { return; }
                                                                                            await window.$(ref).addClass('loading');
                                                                                            await Helper.reactToDom(window.$('body'),
                                                                                                <AddSlide
                                                                                                    id={this.state.provider_id}
                                                                                                    onSuccess={() => {
                                                                                                        this.resetSequence();
                                                                                                        window.$(ref).removeClass('loading')
                                                                                                    }}
                                                                                                    promotion_slides={this.state.promotion_slides}
                                                                                                    promotion_slide={promotion_slide}
                                                                                                    mode="edit"
                                                                                                />
                                                                                            )
                                                                                            window.$(ref).removeClass('loading')
                                                                                        })
                                                                                    }}
                                                                                >แก้ไข Link</button>
                                                                                <button className={"btn btn-danger"}
                                                                                    ref={(ref) => {
                                                                                        window.$(ref).off().on('click', () => {
                                                                                            if (window.$(ref).hasClass('loading')) { return; }
                                                                                            window.$(ref).addClass('loading');
                                                                                            Helper.confirmModal({
                                                                                                title: "ยืนยันลบรูปสไลด์",
                                                                                                description: "ต้องการลบรูปสไลด์นี้หรือไม่ ?",
                                                                                                onConfirm: () => {
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                    this.delete(promotion_slide);
                                                                                                },
                                                                                                onCancel: () => {
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                }
                                                                                            });
                                                                                        })
                                                                                    }
                                                                                    }
                                                                                >
                                                                                    <i className={"fas fa-trash-alt"} />
                                                                                </button>
                                                                            </div>
                                                                            <a href={promotion_slide.imageURL ? promotion_slide.imageURL : null} data-fancybox="gallery">
                                                                                <img src={promotion_slide.imageURL ? promotion_slide.imageURL : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </SortableItem>
                                                    );
                                                })
                                                : null
                                        }
                                    </div>
                                </SortableContainer>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}

class AddSlide extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            file_stack: [],
            selected_star: 5
        }
    }

    async componentDidMount() {

        this.setState({
            is_loading: false,
        })
    }

    async uploadFile(node, options) {
        // let id = options.PromotionId;
        let upload_btn = node.parent().find('.btn');
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        const filename = Helper.setNewImageFileName(file);
        let reszieFile = await Helper.resizeImageFn(file, { maxWidth: 1280, maxHeight: 768 });
        var uploadTask = storage.ref().child('provider_users_data/' + this.props.id + '/' + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log(snapshot);
        }, (error) => {
            console.log(error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            this.$submit.removeClass('loading');
            upload_btn.removeClass('loading');
        }, async () => {
            let url = 'provider_users_data/' + this.props.id + '/' + filename;
            if (options.onSuccess) {
                options.onSuccess(url);
            }
        });
    }


    readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.$file.parent().find('.button').text('เปลี่ยนรูป');
                window.$('#show_image').html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
                window.$('#show_image').html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    onCreate(data) {
        let upload_btn = this.$file.parent().find('.btn')
        if ((!this.$file || !this.$file[0].files[0])) {
            Helper.messageTop({ message: 'กรุณาอัพโหลดรูปไสลด์' })
            this.$submit.removeClass('loading');
            this.$file.parent().find('.btn').removeClass('loading')
            return;
        }
        this.uploadFile(this.$file, {
            onSuccess: async (url) => {
                let slideImageUrl = url;
                let slideImage = await storage.ref(url).getDownloadURL();
                let update_data = {
                    ...data,
                    createAt: new Date().getTime(),
                    updateAt: new Date().getTime(),
                }
                update_data.imageURL = slideImage;
                update_data.imagePath = slideImageUrl;
                update_data.sequence = this.props.promotion_slides.length + 1;
                db.collection('promotion_slides').add({ ...update_data }).then((res) => {
                    upload_btn.removeClass('loading');
                    window.$(document).Toasts('create', {
                        title: 'อัพโหลดรูปสไลด์สำเร็จ',
                        body: 'ทำการอัพโหลดรูปสไลด์สำเร็จ',
                        icon: 'fas fa-file-upload',
                        autohide: true,
                        delay: 3000,
                        class: "bg-success",
                    })
                    this.$modal.modal('hide');
                    if (this.props.onSuccess) {
                        this.props.onSuccess()
                    }
                })
            }
        })
    }
    onUpdate(data) {


        let update_data = {
            ...data,
            updateAt: new Date().getTime(),
        }
        db.collection('promotion_slides').doc(this.props.promotion_slide.id).update({ ...update_data }).then((res) => {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้่อมูลสำเร็จ',
                icon: 'fas fa-file-upload',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.$modal.modal('hide');
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
        })
    }

    onSubmit(data) {
        if (data.haveLink) {
            data.haveLink = true
        } else {
            data.haveLink = false
        }
        if (data.haveLink && (!data.link || data.link === '')) {
            Helper.messageTop({ message: 'กรุณากรอก Link' })
            this.$submit.removeClass('loading');
            return;
        }
        let validURL = Helper.validURL(data.link);
        if (data.haveLink && !validURL) {
            Helper.messageTop({ message: 'Link ไม่ถูกต้อง' })
            this.$submit.removeClass('loading');
            return;
        }
        if (!data.haveLink) {
            data.link = '';
        }
        if (this.props.mode === 'create') {
            this.onCreate(data);
        }
        if (this.props.mode === 'edit') {
            this.onUpdate(data);
        }
    }



    render() {
        // console.log(this.state.history);
        let star = [1, 2, 3, 4, 5];
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) { return; }
                        this.$submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        this.onSubmit(data)

                    }}
                >
                    <div className="modal-content">

                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            {
                                this.state.is_loading ?
                                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                                        <div className="ui active inline loader" ></div>
                                    </div>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        {
                                            this.props.mode === 'create' ?
                                                <div className={"form-group"}>
                                                    <label>รูปสไลด์</label>
                                                    <input name="image"
                                                        type={"file"}
                                                        ref={(ref) => this.$file = window.$(ref)}
                                                        style={{ display: 'none' }}
                                                        id="file_image_bank"
                                                        onChange={(e) => {
                                                            this.readURL(this.$file[0]);
                                                        }}
                                                    />
                                                    <div id="show_image">
                                                        {
                                                            this.props.data && this.props.data.slipImageURL ?
                                                                <img src={this.props.data.slipImageURL} alt={"PromotionPic"} style={{ maxWidth: '100%' }} />
                                                                : null
                                                        }
                                                    </div>
                                                    <label htmlFor="file_image_bank"
                                                        type={"button"}
                                                        className="btn btn-default"
                                                        style={{ width: '100%' }}
                                                    >
                                                        {
                                                            this.props.data && this.props.data.slipImageURL ?
                                                                "เปลี่ยนรูป"
                                                                : "อัพโหลด"
                                                        }
                                                    </label>
                                                </div>
                                                : null
                                        }

                                        <div className={"form-group"}>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox"
                                                    ref={(ref) => { this.$checkboxHaveLink = window.$(ref) }}
                                                    className="custom-control-input"
                                                    id="haveLink"
                                                    name={"haveLink"}
                                                    defaultValue={"on"}
                                                    defaultChecked={this.props.promotion_slide && this.props.promotion_slide.haveLink}
                                                    onChange={() => {
                                                        if (this.$checkboxHaveLink.prop("checked")) {
                                                            window.$('#link').show()
                                                        } else {
                                                            window.$('#link').hide()
                                                        }
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor="haveLink">เพิ่ม Link</label>
                                            </div>
                                        </div>
                                        <div className={"form-group"} id={"link"} style={{ display: this.props.promotion_slide && this.props.promotion_slide.haveLink ? 'block' : 'none' }}>
                                            <label>Link</label>
                                            <textarea className={'form-control'} name="link" defaultValue={this.props.promotion_slide && this.props.promotion_slide.link}></textarea>
                                        </div>
                                    </>
                                    : null
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                            <button type="submit" className="btn btn-primary">บันทึก</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

}