import React from 'react';
import { Helper } from './../Helper';
import { Link, navigate } from '@reach/router';
import { db, storage } from './../firebase';
import GoogleMap from './../Components/GoogleMap'
import thailand from "./../thailand.json";
import CustomDateInput from './../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'


const axios = require('axios').default;
const formSize = { maxWidth: '100%', width: 500 }
export default class AddBooking extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            qs: Helper.getAllUrlParams(),
            step: Helper.getParameterByName('step') || 1,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setup()
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setup()
        }
    }

    setup() {
        this.setState({
            qs: Helper.getAllUrlParams(),
            step: Helper.getParameterByName('step') || 1,
        })
    }

    onClickLink(toStep) {
        if (toStep > 1 && !this.state.qs.userUid) {
            Helper.messageTop({
                message: 'กรุณาเลือกสมาชิก'
            })
            return;
        }
        if (toStep > 2 && !this.state.qs.providerUid) {
            Helper.messageTop({
                message: 'กรุณาเลือกแม่บ้าน'
            })
            return;
        }
        if (toStep > 3
            && (
                !this.state.qs.bookingDate
                || !this.state.qs.bookingDuration
                || !this.state.qs.bookingTime
                || !this.state.qs.place
            )
        ) {
            Helper.messageTop({
                message: 'กรุณากรอกข้อมูลให้ครบถ้วน'
            })
            return;
        }
        let data = { ...this.state.qs };
        data.step = toStep;
        this.setState({
            qs: data,
            step: toStep,
        })
        // let url = Helper.getQueryStringLinkFromObject({ ...data });
        // navigate(url);
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">เพิ่ม Booking</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/booking">รายการ Booking</Link></li>
                                    <li className="breadcrumb-item active">เพิ่ม Booking</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"content"}>
                    {
                        this.state.is_loading ?
                            <div className="absolute_loading">
                                <div className="ui active inline loader" ></div>
                            </div>
                            : null
                    }
                    <ul id="progressbar">
                        <li className={parseFloat(this.state.step) >= 1 ? "active" : null} onClick={() => { this.onClickLink(1) }}><div className="icon"><i className="fas fa-user" /></div><strong>เลือกสมาชิก</strong></li>
                        <li className={parseFloat(this.state.step) >= 2 ? "active" : null} onClick={() => { this.onClickLink(2) }}><div className="icon"><i className="fas fa-broom" /></div><strong>เลือกแม่บ้าน</strong></li>
                        <li className={parseFloat(this.state.step) >= 3 ? "active" : null} onClick={() => { this.onClickLink(3) }}><div className="icon"><i className="fas fa-map-marker-alt" /></div><strong>เลือกสถานที่</strong></li>
                        <li className={parseFloat(this.state.step) >= 4 ? "active" : null} onClick={() => { this.onClickLink(4) }}><div className="icon"><i className="fas fa-receipt" /></div><strong>อัพโหลดสลิป</strong></li>
                    </ul>

                    <div>
                        <div style={{ position: 'relative' }}>
                            <UserSelector
                                isShow={parseFloat(this.state.step) === 1}
                                adminUid={this.props.adminUid}
                                userUid={this.state.qs && this.state.qs.userUid ? this.state.qs.userUid : false}
                                data={this.state.qs}
                                onSubmit={(userUid) => {
                                    let qs = { ...this.state.qs };
                                    qs.userUid = userUid;
                                    delete qs.bookingDate;
                                    delete qs.bookingDuration;
                                    delete qs.bookingTime;
                                    delete qs.place;
                                    this.setState({
                                        qs: qs,
                                        is_loading: userUid && !this.state.qs.providerUid,
                                    }, () => {
                                        this.onClickLink(2)
                                    })
                                }}
                                currentStep={this.state.step}
                                is_loading={this.state.is_loding}
                                setLoading={(type) => {
                                    let loading = false;
                                    if (type) { loading = true }
                                    this.setState({ is_loading: loading })
                                }}
                            />
                            <ProviderSelector
                                isShow={parseFloat(this.state.step) === 2}
                                providerUid={this.state.qs && this.state.qs.providerUid ? this.state.qs.providerUid : false}
                                data={this.state.qs}
                                currentStep={this.state.step}
                                onSubmit={(providerUid) => {
                                    let qs = { ...this.state.qs };
                                    qs.providerUid = providerUid;
                                    delete qs.bookingDate;
                                    delete qs.bookingDuration;
                                    delete qs.bookingTime;
                                    delete qs.place;
                                    this.setState({
                                        qs: qs,
                                    }, () => {
                                        this.onClickLink(3)
                                    })
                                }}
                                is_loading={this.state.is_loding}
                                setLoading={(type) => {
                                    let loading = false;
                                    if (type) { loading = true }
                                    this.setState({ is_loading: loading })
                                }}
                            />
                            <UserLocation
                                userUid={this.state.qs && this.state.qs.userUid ? this.state.qs.userUid : false}
                                isShow={parseFloat(this.state.step) === 3}
                                data={this.state.qs}
                                currentStep={this.state.step}
                                onSubmit={(data) => {
                                    let qs = { ...this.state.qs, ...data };
                                    this.setState({
                                        qs: qs,
                                    }, () => {
                                        this.onClickLink(4)
                                    })
                                }}
                                adminUid={this.props.adminUid}
                            />

                            <SlipUpload
                                data={this.state.qs}
                                currentStep={this.state.step}
                                isShow={parseFloat(this.state.step) === 4}
                                adminUid={this.props.adminUid}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

class UserSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: props.is_loding,
            fecth_data_loading: true,
            users: [],
            selected_uid: props.userUid,
            provider: false,
            selected_index: false,
        }
    }

    componentDidMount() {
        this.props.setLoading(false)
        // this.fetch()
    }

    async fetch(val) {
        if (val.length != 10) {
            Helper.messageTop({ message: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง' })
            this.Btn.removeClass('loading');
            return;
        }
        let phone = val.replace('-', '');
        phone = phone.replace(' ', '');
        phone = '+66' + phone.substr(1, 9);
        let users = [];
        let provider = false;
        let selected_uid = false;
        let selected_index = false;
        let usersRef = db.collection("users").where('phoneNumber', '==', phone);
        let usersData = await usersRef.get();
        if (!usersData.empty) {
            let getUserData = false;
            for (const doc of usersData.docs) {
                if (!doc.data().delete && !getUserData) {
                    getUserData = doc;
                }
                if (!doc.data().delete) {
                    users.push({ ...doc.data(), uid: doc.id, id: doc.id })
                }
            }
            if (!provider) {
                provider = getUserData ? getUserData.data() : false;
                if (provider) {
                    provider.id = getUserData ? getUserData.id : false;
                }
                selected_uid = getUserData ? getUserData.id : false;
                selected_index = users.findIndex((e) => e.id == getUserData.id);
            }
        }

        let guestRef = db.collection("users").where('guestPhoneNumber', '==', phone);
        let guestData = await guestRef.get();
        if (!guestData.empty) {
            let _getUserData = false;
            for (const doc of guestData.docs) {
                if (!doc.data().delete && !_getUserData) {
                    _getUserData = doc;
                }
                if (!doc.data().delete) {
                    users.push({ ...doc.data(), uid: doc.id, id: doc.id })
                }
            }
            if (!provider) {
                provider = _getUserData ? _getUserData.data() : false;
                if (provider) {
                    provider.id = _getUserData?.id ? _getUserData.id : false;
                }
                selected_uid = _getUserData?.id ? _getUserData.id : false;
                selected_index = users.findIndex((e) => e.id == _getUserData.id);
            }
        }
        console.log(`users`, users, selected_index)
        if (!provider) {
            Helper.messageTop({ message: 'ไม่พบข้อมูลสมาชิก' })
        }
        this.setState({
            fecth_data_loading: false,
            provider: provider,
            selected_uid: selected_uid,
            selected_index: selected_index,
            users: users,
        }, () => {
            this.Btn.removeClass('loading');
        })
    }

    async createUser(data) {
        if (!data.displayName) {
            Helper.messageTop({ message: 'กรุณากรอกชื่อ' })
            this.$submit.removeClass('loading');
            return;
        }
        if (!data.guestPhoneNumber) {
            Helper.messageTop({ message: 'กรุณากรอกเบอร์โทร' })
            this.$submit.removeClass('loading');
            return;
        }

        if (data.guestPhoneNumber && data.guestPhoneNumber.length != 10) {
            Helper.messageTop({ message: 'กรุณากรอกเบอร์โทรให้ถูกต้อง' })
            this.$submit.removeClass('loading');
            return;
        }
        let phone = data.guestPhoneNumber.replaceAll('-', '');
        phone = phone.replaceAll(' ', '');
        phone = '+66' + phone.substr(1, 9);
        data.guestPhoneNumber = phone;
        data.createdAdminUid = this.props.adminUid
        data.acceptTerms = false;
        let ref = await db.collection('users').where('phoneNumber', '==', data.guestPhoneNumber).get();
        let ref2 = await db.collection('users').where('guestPhoneNumber', '==', data.guestPhoneNumber).get();
        if (!ref.empty || !ref2.empty) {
            Helper.messageTop({ message: 'มีผู้ใช้เบอร์นี้แล้ว' })
            this.$submit.removeClass('loading');
            return;
        }
        if (data.guestEmail) {
            let eref = await db.collection('users').where('email', '==', data.guestEmail).get();
            if (!eref.empty) {
                Helper.messageTop({ message: 'มีผู้ใช้อีเมลนี้แล้ว' })
                this.$submit.removeClass('loading');
                return;
            }
        }


        let res = await axios.post(`${window.api_host}/create-informal-user`, { ...data });
        if (res.data.success) {
            this.$submit.removeClass('loading');
            let guestData = await db.collection('users').where('guestPhoneNumber', '==', data.guestPhoneNumber).get();
            let provider = false;
            let selected_uid = false;
            if (!guestData.empty) {
                provider = guestData.docs[0].data();
                provider.id = guestData.docs[0].id;
                selected_uid = guestData.docs[0].id;
            }
            this.setState({
                mode: false,
                provider: provider,
                selected_uid: selected_uid,
            })
        }
        if (res.data.error) {
            Helper.messageTop({ message: ',มีผู้ใช้เบอร์นี้แล้ว' });
            this.$submit.removeClass('loading');
            return;
        }
    }

    renderCreateUsersForm() {
        return (
            <div className={"row"}>
                <div className="col-12 col-md-4 offset-md-4">
                    <div className="form-group">
                        <button className={'btn btn-default'}
                            type="button"
                            onClick={() => {
                                this.setState({
                                    mode: false
                                })
                            }}
                        >กลับ</button>
                    </div>
                    <form
                        ref={(ref) => {
                            this.$form = window.$(ref)
                        }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (this.$submit.hasClass('loading')) { return false; }
                            this.$submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            // console.log(data);
                            this.createUser(data);
                        }}
                    >
                        <div className="form-group">
                            <input type="text" name="displayName" className="form-control" placeholder="ชื่อ นามสกุล" required={true} />
                        </div>
                        <div className="form-group">
                            <input type="text" name="guestPhoneNumber" className="form-control" placeholder="เบอร์โทรศัพท์" required={true} />
                        </div>
                        <div className="form-group">
                            <input type="email" name="guestEmail" className="form-control" placeholder="อีเมล์" />
                        </div>
                        <button
                            ref={(ref) => { this.$submit = window.$(ref) }}
                            className="btn btn-success btn-lg inverted"
                            style={{ width: '100%' }}
                            type={"submit"}
                        >ลงทะเบียน</button>
                    </form>
                </div>
            </div>
        );
    }

    render() {
        // console.log(this.state.selected_uid)
        if (this.state.mode === 'add') {
            return this.renderCreateUsersForm();
        }
        return (
            <>
                <div style={{ display: this.props.isShow ? 'block' : 'none', alignItems: 'center', flexDirection: 'column' }}>

                    <div className={"row"}>
                        <div className="col-12 col-md-3 offset-md-1">
                            {
                                this.state.users.length > 0 ?
                                    <div className='row'>
                                        {
                                            this.state.users.map((user, index) => {
                                                return (
                                                    <div className='col-12' key={index}>
                                                        <div
                                                            className="card form-group"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    provider: user,
                                                                    selected_uid: user.uid,
                                                                    selected_index: index,
                                                                })
                                                            }}
                                                        >
                                                            <div className="card-body" style={{ position: 'relative' }}>
                                                                {
                                                                    this.state.selected_index == index ?
                                                                        <div style={{ position: 'absolute', right: 10, top: 10, }}>
                                                                            <i className='fas fa-check' style={{ color: 'green', fontSize: 20 }} />
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-lg-3">
                                                                        <img src={user.photoURL ? user.photoURL : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                                    </div>
                                                                    <div className="col-sm-12 col-lg-9">
                                                                        <div>
                                                                            <label>ชื่อ</label> : {user.displayName}
                                                                            {
                                                                                user.guestPhoneNumber ?
                                                                                    <span class="ml-2 badge badge-info">นอกระบบ</span>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            <label>อีเมล</label> :  {user.email} {user.guestEmail}
                                                                        </div>
                                                                        <div>
                                                                            <label>เบอร์โทรศัพท์</label> :  {user.phoneNumber} {user.guestPhoneNumber}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <form className="input-group"
                                    onSubmit={async (e) => {
                                        e.preventDefault();
                                        if (this.Btn.hasClass('loading')) { return; }
                                        this.Btn.addClass('loading');
                                        let val = this.Btn.parents('.input-group').find('input[name="phoneNumber"]').val();
                                        await this.fetch(val);
                                    }}
                                >
                                    <input name="phoneNumber" className="form-control" placeholder="ค้นหาจากเบอร์โทรศัพท์" />
                                    <span className="input-group-append">
                                        <button
                                            ref={(ref) => {
                                                this.Btn = window.$(ref);
                                            }}
                                            type="submit"
                                            className="btn btn-info btn-flat inverted"
                                        >ค้นหา</button>
                                    </span>
                                </form>
                            </div>

                            {
                                this.state.provider ?
                                    <div className="card form-group">
                                        <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <h3 className="card-title">
                                                {this.state.provider.displayName}
                                                {
                                                    this.state.provider.guestPhoneNumber && !this.state.provider.acceptTerms ?
                                                        <>{' '}<span className={"badge badge-info"}>นอกระบบ</span></>
                                                        : null
                                                }
                                            </h3>
                                        </div>
                                        <div className="card-body" >
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-5">
                                                    <img src={this.state.provider.photoURL ? this.state.provider.photoURL : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                </div>
                                                <div className="col-sm-12 col-lg-7">
                                                    <div>
                                                        <label>ชื่อ</label> : {this.state.provider.displayName}
                                                    </div>
                                                    <div>
                                                        <label>อีเมล</label> :  {this.state.provider.email} {this.state.provider.guestEmail}
                                                    </div>
                                                    <div>
                                                        <label>เบอร์โทรศัพท์</label> :  {this.state.provider.phoneNumber} {this.state.provider.guestPhoneNumber}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    :

                                    <>
                                        {/* <div className="form-group">ไม่มีข้อมูลสมาชิก</div> */}
                                        <div className="form-group">
                                            <button className="btn btn-default"
                                                onClick={() => { this.setState({ mode: 'add' }) }}
                                            >เพิ่มข้อมูลสมาชิกนอกระบบ</button>
                                        </div>
                                    </>
                            }
                            <input type="hidden" name="userUid" value={this.state.selected_uid} />
                            <button
                                ref={(ref) => {
                                    window.$(ref).off().on('click', () => {
                                        if (window.$(ref).hasClass('loading')) { return; }
                                        window.$(ref).addClass('loading')
                                        if (this.props.userUid && this.props.userUid !== this.state.selected_uid) {
                                            Helper.confirmModal({
                                                title: "ยืนยันเปลี่ยนสมาชิก",
                                                description: "ต้องการเปลี่ยนสมาชิกหรือไม่ ?",
                                                onConfirm: () => {
                                                    this.props.onSubmit(this.state.selected_uid);
                                                    window.$(ref).removeClass('loading');
                                                },
                                                onCancel: () => {
                                                    window.$(ref).removeClass('loading');
                                                }
                                            });
                                        } else {
                                            this.props.onSubmit(this.state.selected_uid);
                                            window.$(ref).removeClass('loading');
                                        }
                                    })

                                }}
                                className="btn btn-success btn-lg inverted"
                                style={{ width: '100%' }}
                            >ต่อไป <i className="fas fa-arrow-right" /></button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

class ProviderSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            data: [],
            selected_uid: props.providerUid,
        }
    }


    componentDidUpdate(prevProps) {
        if (this.props.currentStep === 2 && prevProps.currentStep === 1 && !this.props.data.providerUid) {
            this.fetch();
        }
    }

    async fetch() {
        let Ref = db.collection("provider_users");
        // Ref = Ref.orderBy('displayName', 'asc');
        let _data = await Ref.get();
        let list = [];
        let p = [];
        for (const doc of _data.docs) {
            if (doc.exists && doc.data().phoneNumber) {
                let _Ref = db.collection('provider_users_data').where('phoneNumber', '==', doc.data().phoneNumber).get();
                p.push(_Ref);
                let data = { ...doc.data() };
                data.uid = doc.id;
                list.push({
                    ...data
                });
            }
        }
        let prepare = await Promise.all(p);
        let i = 0;
        for (const doc of prepare) {
            if (!doc.empty) {
                let _doc = doc.docs[0].data();
                _doc.id = doc.docs[0].id;
                _doc.displayName = _doc.firstName + ' ' + _doc.lastName;
                list[i] = { ...list[i], ..._doc }
            }
            i++;
        }
        list.sort(function (a, b) {
            if (a.firstName < b.firstName) { return -1; }
            if (a.firstName > b.firstName) { return 1; }
            return 0;
        })
        this.setState({
            is_loading: false,
            data: list,
        }, () => {
            this.props.setLoading(false)
        })
    }

    async fetchSelectedProvider(phoneNumber) {
        let data = false;
        let ref = await db.collection('provider_users_data').where('phoneNumber', '==', phoneNumber).get();
        if (!ref.empty) {
            let doc = ref.docs[0];
            data = { ...doc.data() };
            data.providerUserDateUid = doc.id;
        }
        if (this.state.selected_uid) {
            let filter_user = this.state.data.filter((e) => { return e.uid === this.state.selected_uid });
            if (filter_user.length > 0) {
                data = { ...data, ...filter_user[0] }
            }
        }
        this.setState({
            selected_data: data,
            is_loading_selected: false,
        }, () => {
            this.props.setLoading(false);
        })
    }

    render() {
        if (this.state.is_loading) {
            return null
        }
        return (
            <>
                <div style={{ display: this.props.isShow ? 'block' : 'none' }}>

                    <div className={"row"}>
                        <div className="col-12 col-md-4 offset-md-4">
                            <div className="form-group">
                                <select
                                    ref={(ref) => {
                                        window.$(ref).select2();
                                        window.$(ref).on('select2:select', (e) => {
                                            let selected_uid = e.target.value;
                                            if (!selected_uid) { return; }
                                            this.setState({
                                                selected_uid: selected_uid,
                                                is_loading_selected: true
                                            }, () => {
                                                let selected_data = false;
                                                if (this.state.selected_uid) {
                                                    let filter_data = this.state.data.filter((e) => { return e.uid === this.state.selected_uid });
                                                    if (filter_data.length > 0) {
                                                        selected_data = filter_data[0]
                                                    }
                                                }
                                                this.fetchSelectedProvider(selected_data.phoneNumber)
                                            })
                                        });
                                    }}
                                    style={formSize}
                                    className={"form-control"}
                                    name={"providerUid"}
                                    defaultValue={this.state.selected_uid || ''}
                                >
                                    <option value="">เลือกแม่บ้าน</option>
                                    {
                                        this.state.data.map((item, item_i) => <option key={item_i} value={item.uid}>{item.displayName} - {item.phoneNumber ? item.phoneNumber.replace('+66', '0') : null}</option>)
                                    }
                                </select>
                            </div>

                            {
                                this.state.selected_data ?
                                    <div className="card form-group">
                                        <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <h3 className="card-title">
                                                {this.state.selected_data.displayName}
                                            </h3>
                                        </div>
                                        <div className="card-body" >
                                            {
                                                this.state.is_loading_selected ?
                                                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                                                        <div className="ui active inline loader" ></div>
                                                    </div>
                                                    :
                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-5">
                                                            <img src={this.state.selected_data.profileImage ? this.state.selected_data.profileImage : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                        </div>
                                                        <div className="col-sm-12 col-lg-7">
                                                            <div>
                                                                <label>ชื่อ</label> : {this.state.selected_data.displayName}
                                                            </div>
                                                            <div>
                                                                <label>อีเมล</label> :  {this.state.selected_data.email}
                                                            </div>
                                                            <div>
                                                                <label>เบอร์โทรศัพท์</label> :  {this.state.selected_data.phoneNumber}
                                                            </div>
                                                        </div>

                                                    </div>
                                            }

                                        </div>
                                    </div>
                                    : null
                            }
                            <input type="hidden" name="providerUid" value={this.state.selected_uid} />
                            <button
                                ref={(ref) => {
                                    window.$(ref).off().on('click', () => {
                                        if (window.$(ref).hasClass('loading')) { return; }
                                        window.$(ref).addClass('loading')
                                        if (this.props.providerUid && this.props.providerUid !== this.state.selected_uid) {
                                            Helper.confirmModal({
                                                title: "ยืนยันเปลี่ยนแม่บ้าน",
                                                description: "ต้องการเปลี่ยนแม่บ้านหรือไม่ ?",
                                                onConfirm: () => {
                                                    this.props.onSubmit(this.state.selected_uid);
                                                    window.$(ref).removeClass('loading');
                                                },
                                                onCancel: () => {
                                                    window.$(ref).removeClass('loading');
                                                }
                                            });
                                        } else {
                                            this.props.onSubmit(this.state.selected_uid);
                                            window.$(ref).removeClass('loading');
                                        }
                                    })

                                }}
                                className="btn btn-success btn-lg inverted"
                                style={{ width: '100%' }}
                            >ต่อไป <i className="fas fa-arrow-right" /></button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export class UserLocation extends React.Component {
    constructor(props) {
        super(props);
        let provinces = [];
        for (const [key, value] of Object.entries(thailand)) {
            let amphoes = [];

            for (const [_key, _value] of Object.entries(value.amphoes)) {
                amphoes.push({ code: _key, amphoe: _value.name.th });
                // console.log(`${_key}: ${_value.name.th}`);
            }
            provinces.push({ code: key, province: value.name.th, amphoes: amphoes })
            // console.log(`${key}: ${value.name.th}`);
        }
        this.state = {
            mode: props.mode ? props.mode : false,
            location: false,
            place_type: [],
            place_type_selected: false,
            place_sub_type_selected: 0,
            provinces: provinces,
            places: [],
            selected_amphoe: false,
            booking_time: [],
            time: [],
            place_selected: props.data.placeUid || false,
            selected_booking_time: props.data.bookingDuration || false,
            selected_booking_date: props.data.bookingDate || false,
            selected_time: props.data.bookingTime || false,
            service_area: [],
            is_loading_service_area: false,
        }
    }
    componentDidMount() {
        this.fetch();
    }
    componentDidUpdate(prevProps) {
        if (this.props.userUid !== prevProps.userUid
            || this.props.providerUid !== prevProps.providerUid) {
            this.setState({
                location: false,
                place_type: [],
                place_type_selected: false,
                place_sub_type_selected: 0,
                places: [],
                selected_amphoe: false,
                booking_time: [],
                time: [],
                place_selected: false,
                selected_booking_time: false,
                selected_booking_date: false,
                selected_time: false,
                service_area: [],
                is_loading_service_area: false,
            }, () => {
                this.fetch();
            })
        }
    }


    async fetchServiceArea() {
        let service_area = [...this.state.service_area];
        if (service_area.length === 0) {
            let res = await db.collection('service_area').get();
            if (!res.empty) {
                for (const doc of res.docs) {
                    let _service_area_data = { ...doc.data() }
                    _service_area_data.id = doc.id;
                    service_area.push(_service_area_data);
                }
            }
        }
        this.setState({
            service_area: service_area,
            is_loading_service_area: false,
        })
    }

    getAmphoe() {
        let apm = [];
        this.state.provinces.forEach((db) => {
            if (db.code === this.state.selected_province) {
                apm = db.amphoes;
            }
        });
        apm.sort()
        return apm;
    }

    async fetch() {
        let res = await db.collection('place_type').get();
        let data = [];
        let place_type_selected = false;
        if (!res.empty) {
            for (const doc of res.docs) {
                let _data = { ...doc.data() };
                _data.uid = doc.id;
                data.push(_data);
            }
        }
        if (data.length > 0) {
            place_type_selected = data[0]
        }
        let places = [];
        let place_selected = false;
        if (this.props.userUid) {
            let placesRef = await db.collection('users').doc(this.props.userUid).collection('places').get();
            if (!placesRef.empty) {
                for (const doc of placesRef.docs) {
                    let place = { ...doc.data() };
                    place.uid = doc.id;
                    // console.log(place)
                    places.push(place);
                }
                place_selected = places[0]
            }
        }
        let bookTimeRef = await db.collection('book_time').get();
        let book_time = [];
        if (!bookTimeRef.empty) {
            for (const doc of bookTimeRef.docs) {
                book_time.push({ ...doc.data(), uid: doc.id });
            }
        }
        book_time.sort(function (a, b) { return a.sequence - b.sequence });
        let timeRef = await db.collection('constants').doc('bookSelectHalfTime').get();
        let time = [];
        if (timeRef.exists) {
            time = [...timeRef.data().value];
        }
        this.setState({
            places: places,
            place_type: data,
            place_type_selected: place_type_selected,
            selected_province: false,
            selected_amphoe: false,
            place_selected: place_selected,
            booking_time: book_time,
            time: time,
        })
    }

    async addPlace(data) {
        let _data = { ...data };
        _data.placeType = { ...this.state.place_type_selected };
        _data.placeSubTypeUid = parseFloat(data.placeSubTypeUid);
        _data.createdAdminUid = this.props.adminUid
        // _data.coordinate = await firestore(parseFloat(data.lat), parseFloat(data.lng));
        let res = await axios.post(`${window.api_host}/user-places`, { ..._data });
        if (res.data.success) {
            this.$submit.removeClass('loading');
            this.setState({
                mode: false,
                place_type_selected: false,
                place_sub_type_selected: false,
                location: false,
                name: false,
                addressDetail: false,
                selected_province: false,
                selected_amphoe: false,
                note: false,
            }, () => {
                this.fetch()
            })
        }
        if (res.data.error) {
            Helper.messageTop({ message: res.data.message });
            this.$submit.removeClass('loading');
            return;
        }
    }

    async editPlace(data) {
        let _data = { ...data };
        _data.placeType = { ...this.state.place_type_selected };
        _data.placeSubTypeUid = parseFloat(data.placeSubTypeUid);
        // _data.coordinate = await firestore(parseFloat(data.lat), parseFloat(data.lng));
        let res = await axios.put(`${window.api_host}/user-places`, { ..._data });
        if (res.data.success) {
            this.$submit.removeClass('loading');
            this.setState({
                mode: false,
                place_type_selected: false,
                place_sub_type_selected: false,
                location: false,
                name: false,
                addressDetail: false,
                selected_province: false,
                selected_amphoe: false,
                note: false,
            }, () => {
                this.fetch()
            })
        }
        if (res.data.error) {
            Helper.messageTop({ message: res.data.message });
            this.$submit.removeClass('loading');
            return;
        }
    }

    async deletePlace(options) {
        let delete_uid = this.state.place_selected.uid;
        let res = await axios.delete(`${window.api_host}/user-places?userUid=${this.props.userUid}&id=${delete_uid}`);
        if (res.data.success) {
            this.setState({
                mode: false,
            }, () => {
                this.fetch()
            })
            options.onSuccess();
        } else {
            Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' })
            options.onError();
        }

    }



    renderLocationDetail() {
        return (<>
            <div className="card">
                <div className="card-body">
                    <GoogleMap
                        mode="view"
                        lat={this.state.place_selected.coordinate.latitude}
                        lng={this.state.place_selected.coordinate.longitude}
                        height={300}
                    />
                    <div className="divider" />
                    <div><strong>{this.state.place_selected.name}</strong>{this.state.place_selected.addressDetail ? ', ' + this.state.place_selected.addressDetail : null}</div>
                    <div><strong>ประเภท </strong>: {this.state.place_selected.placeType.name_th} {this.state.place_selected.placeType.place_sub_type[this.state.place_selected.placeSubTypeUid].name_th} {this.state.place_selected.placeType.place_sub_type[this.state.place_selected.placeSubTypeUid].description_th}</div>
                    <div>{this.state.place_selected.address}</div>
                    {
                        this.state.place_selected.phoneNumber ?
                            <div><strong>เบอร์โทรศัพท์ </strong> : {this.state.place_selected.phoneNumber}</div>
                            : null
                    }
                    {
                        this.state.place_selected.note ?
                            <div><strong>หมายเหตุ </strong> : {this.state.place_selected.note}</div>
                            : null
                    }
                    <div className="divider" />
                    <div className="flex row">
                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => {
                                let place_type_selected = false;
                                for (const place_type of this.state.place_type) {
                                    if (this.state.place_selected.placeType.uid === place_type.uid) {
                                        place_type_selected = place_type
                                    }
                                }
                                this.setState({
                                    mode: 'edit',
                                    place_type_selected: place_type_selected,
                                    place_sub_type_selected: this.state.place_selected.placeSubTypeUid,
                                    location: {
                                        lat: this.state.place_selected.coordinate.latitude,
                                        lng: this.state.place_selected.coordinate.longitude,
                                        address: this.state.place_selected.address,
                                    },
                                    name: this.state.place_selected.name,
                                    addressDetail: this.state.place_selected.addressDetail,
                                    selected_province: this.state.place_selected.province,
                                    selected_amphoe: this.state.place_selected.amphoe,
                                    note: this.state.place_selected.note,
                                    is_loading_service_area: true,
                                }, () => {
                                    this.fetchServiceArea();
                                })
                            }}
                        >แก้ไข</button>
                        <div style={{ marginLeft: 'auto' }}>
                            <button
                                className="btn btn-danger"
                                type={"button"}
                                ref={(ref) => {
                                    window.$(ref).off().on('click', () => {
                                        if (window.$(ref).hasClass('loading')) { return; }
                                        window.$(ref).addClass('loading');
                                        Helper.confirmModal({
                                            title: "ยืนยันลบ",
                                            description: "ต้องการลบสถานที่นี้หรือไม่ ?",
                                            onConfirm: () => {
                                                this.deletePlace({
                                                    onSuccess: () => {
                                                        window.$(ref).removeClass('loading');
                                                    },
                                                    onError: () => {
                                                        window.$(ref).removeClass('loading');
                                                    },
                                                })
                                            },
                                            onCancel: () => {
                                                window.$(ref).removeClass('loading');
                                            }
                                        });
                                    })
                                }}
                            >ลบ</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }

    renderAddForm() {
        return (

            <div className={"row"} style={{ display: this.props.isShow ? 'flex' : 'none' }}>
                <div className="col-12 col-md-2 offset-md-1">
                    {
                        this.state.is_loading_service_area ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="ui active inline loader small" ></div>
                            </div>
                            : null
                    }
                    {
                        !this.state.is_loading_service_area && this.state.service_area.length === 0 ?
                            <>ไม่มีข้อมูล</>
                            : null
                    }
                    {
                        !this.state.is_loading_service_area && this.state.service_area.length > 0 ?
                            <>
                                <h5>พื้นที่ให้บริการ</h5>
                                <ul class="list-group">
                                    {
                                        this.state.service_area.map((service_area, i) => {
                                            return (
                                                <li class="list-group-item" key={i}>
                                                    {service_area.name.th}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </>
                            : null
                    }
                </div>
                <div className="col-12 col-md-4 offset-md-1">
                    <form
                        ref={(ref) => {
                            this.$form = window.$(ref)
                        }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (this.$submit.hasClass('loading')) { return false; }
                            this.$submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            // console.log(data);
                            if (!JSON.parse(data.lat) || !JSON.parse(data.lng) || !data.address) {
                                Helper.messageTop({ message: 'เลือกแผนที่' });
                                this.$submit.removeClass('loading');
                                return;
                            }
                            if (!data.placeTypeUid
                                || !data.placeSubTypeUid
                                || !data.amphoe
                                || !data.province
                                || !data.name
                                || !data.addressDetail
                                || !data.phoneNumber
                            ) {
                                Helper.messageTop({ message: 'กรอกข้อมูลให้ครบถ้วน' });
                                this.$submit.removeClass('loading');
                                return;
                            }
                            if (data.phoneNumber && data.phoneNumber.indexOf('+66') == -1) {
                                data.phoneNumber = '+66' + data.phoneNumber.substr(1, 9);
                            }
                            if (this.state.mode === 'add') {
                                this.addPlace(data);
                            }
                            if (this.state.mode === 'edit') {
                                this.editPlace(data);
                            }
                        }}
                    >
                        <div className="form-group">
                            <button
                                className="btn btn-default"
                                onClick={() => {
                                    this.setState({
                                        mode: false
                                    })
                                }}
                            >ยกเลิก</button>
                        </div>
                        {
                            this.state.mode === 'edit' ?
                                <>
                                    <input type="hidden" name="createdAdminUid" value={this.props.userUid} />
                                    <input type="hidden" name="placeUid" value={this.state.place_selected.uid} />
                                </>
                                : null
                        }
                        <input type="hidden" name="userUid" value={this.props.userUid} />
                        {
                            this.state.place_type && this.state.place_type.length ?
                                <>
                                    <div className="row">
                                        {
                                            this.state.place_type.map((place, place_i) => {
                                                return (
                                                    <div key={place_i}
                                                        className="col-12 col-md-6"
                                                    >
                                                        <div
                                                            className={"card"}
                                                            style={this.state.place_type_selected
                                                                && this.state.place_type_selected.uid === place.uid ? { border: '2px solid green' } : null}
                                                            onClick={() => {
                                                                if (this.state.place_type_selected
                                                                    && this.state.place_type_selected.uid === place.uid) { return; }
                                                                this.setState({
                                                                    place_type_selected: place,
                                                                    place_sub_type_selected: 0,
                                                                })
                                                            }}
                                                        >
                                                            <div className="card-body" style={{ textAlign: 'center' }}>
                                                                {place.name_th}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        this.state.place_type_selected ?
                                            <div className={"form-group"}>
                                                <select className="form-control"
                                                    value={this.state.place_sub_type_selected || 0}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            place_sub_type_selected: e.target.value
                                                        })
                                                    }}
                                                >
                                                    {
                                                        this.state.place_type_selected.place_sub_type.map((place_sub_type, place_sub_type_i) => {
                                                            return (
                                                                <option key={place_sub_type_i} value={place_sub_type_i}>
                                                                    {place_sub_type.name_th} ไม่เกิน {place_sub_type.description_th}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            : null
                                    }
                                    <input type="hidden" name="placeTypeUid" value={this.state.place_type_selected.uid} />
                                    <input type="hidden" name="placeSubTypeUid" value={this.state.place_sub_type_selected} />
                                </>
                                : null
                        }
                        {
                            this.state.location && !this.state.map_loading ?
                                <div className="form-group">
                                    <GoogleMap
                                        mode="view"
                                        height={300}
                                        lat={this.state.location && this.state.location.lat ? parseFloat(this.state.location.lat) : false}
                                        lng={this.state.location && this.state.location.lng ? parseFloat(this.state.location.lng) : false}
                                    />
                                </div>
                                : null
                        }
                        <div className={"form-group"}>
                            <button
                                onClick={() => {
                                    let geo = { lat: false, lng: false };
                                    if (this.lat.val()) {
                                        geo.lat = this.lat.val()
                                    }
                                    if (this.lat.val()) {
                                        geo.lng = this.lng.val()
                                    }
                                    Helper.reactToDom(window.$('body'),
                                        <GoogleMap
                                            modalTitle={"ปักหมุดสถานที่"}
                                            mode="modal"
                                            height={300}
                                            onSubmit={(location) => {
                                                this.setState({
                                                    map_loading: true,
                                                }, () => {
                                                    this.setState({
                                                        location: location,
                                                        map_loading: false,
                                                    })
                                                })

                                            }}
                                            lat={this.state.location && this.state.location.lat ? parseFloat(this.state.location.lat) : false}
                                            lng={this.state.location && this.state.location.lng ? parseFloat(this.state.location.lng) : false}
                                        />)
                                }}
                                type={"button"}
                                className="btn btn-default"
                                style={{ width: '100%' }}
                            >{this.state.location ? "เปลี่ยนตำแหน่ง" : "ระบุตำแหน่ง"}</button>
                        </div>
                        <input type="hidden" ref={(ref) => { this.lat = window.$(ref) }} name="lat" value={this.state.location && this.state.location.lat ? parseFloat(this.state.location.lat) : false} />
                        <input type="hidden" ref={(ref) => { this.lng = window.$(ref) }} name="lng" value={this.state.location && this.state.location.lng ? parseFloat(this.state.location.lng) : false} />
                        <input type="hidden" ref={(ref) => { this.lng = window.$(ref) }} name="address" value={this.state.location && this.state.location.address ? this.state.location.address : false} />
                        <div className="form-group">
                            <input className="form-control" name="name" placeholder="ชื่อสถานที่" defaultValue={this.state.name || ''} />
                        </div>
                        <div className="form-group">
                            <input className="form-control" name="addressDetail" placeholder="รายบละเอียดสถานที่" defaultValue={this.state.addressDetail || ''} />
                        </div>
                        <div className="form-group">
                            <select className="form-control"
                                name={"province"}
                                defaultValue={this.state.selected_province}
                                onChange={(e) => {
                                    this.setState({
                                        selected_province: e.target.value,
                                        selected_amphoe: false,
                                    })
                                }}
                            >
                                <option value="">เลือกจังหวัด</option>
                                {
                                    this.state.provinces.map((item, item_i) => (
                                        <option value={item.code} key={item_i}>{item.province}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            {
                                !this.state.selected_province ?
                                    <input type={"test"} defaultValue={"เลือกจังหวัด"} disabled={true} className={"form-control"} />
                                    : null
                            }
                            {
                                this.state.selected_province ?
                                    <select className="form-control"
                                        name={"amphoe"}
                                        onChange={(e) => {
                                            if (e.target.value === "" || !e.target.value) { return; }
                                            this.setState({
                                                selected_amphoe: e.target.value,
                                            })
                                        }}
                                        defaultValue={this.state.selected_amphoe}
                                    >
                                        <option value="">เลือกเขต/อำเภอ</option>
                                        {
                                            this.getAmphoe().map((amp, amp_i) => (
                                                <option value={amp.code} key={amp_i}
                                                >{amp.amphoe}</option>
                                            ))
                                        }
                                    </select>
                                    : null
                            }
                        </div>
                        <div className="form-group">
                            <input className="form-control" name="phoneNumber" placeholder="เบอร์โทรศัพท์" defaultValue={this.state.phoneNumber ? this.state.phoneNumber.replace('+66', '0') : ''} />
                        </div>
                        <div className="form-group">
                            <input className="form-control" name="note" placeholder="หมายเหตุ" defaultValue={this.state.note || ''} />
                        </div>
                        <button
                            ref={(ref) => { this.$submit = window.$(ref) }}
                            className="btn btn-success btn-lg inverted"
                            style={{ width: '100%' }}
                            type="submit"
                        >{this.state.mode === 'add' ? "เพิ่มสถานที่" : "บันทึก"}</button>
                    </form>
                </div>
            </div>
        )
    }

    async checkAcceptedInSameTime(data) {
        if (
            !data.bookingDate
            || !data.bookingDuration
            || !data.bookingTime
            || !data.place
        ) {
            Swal.fire({
                icon: 'error',
                title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                // text: 'แม่บ้านได้มีการรับงานในช่วงเวลาที่กำหนดแล้ว, ต้องการดำเนินการต่อหรือไม่ ?',
                showConfirmButton: true,
                confirmButtonText: 'ปิด',
                reverseButtons: true,
            })
            return;
        }
        let date = new Date(data.bookingDate);
        let book_time = this.state.booking_time.filter((e) => e.uid == data.bookingDuration)[0] || false;
        // console.log("book_time", book_time)
        let datetime = {
            day: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear(),
            timeRageOfService: data.bookingTime
        }
        const _dateTime = Helper.sumBookedAt(datetime)
        const bookStart = _dateTime.bookAt;
        let prepare_BookEnd = (_dateTime.bookAt + (parseFloat(book_time.time) * 60 * 60 * 1000)) + (1 * 60 * 60 * 1000)
        const bookEnd = prepare_BookEnd;
        // console.log(`_dateTime`, _dateTime, Helper.getDateThai(_dateTime.bookAt, { is_show_time: true }))
        // console.log(`bookStart`, bookStart, Helper.getDateThai(bookStart, { is_show_time: true }))
        // console.log(`bookEnd`, bookEnd, Helper.getDateThai(bookEnd, { is_show_time: true }))
        // console.log("this.props.data.providerUid", this.props.data.providerUid)


        let res = await db.collection('books').where('providerUserUid', '==', this.props.data.providerUid).where('bookStatus', '==', 'accept').get();
        let bookSize = 0;
        if (!res.empty) {
            for (const doc of res.docs) {
                let dat = doc.data();
                let bookedAt = dat.bookedAt;
                let bookedEndAt = dat.bookedEndAt + (1 * 60 * 60 * 1000);
                // console.log(bookedAt, bookStart, bookedEndAt)
                if (bookStart >= bookedAt && bookStart < bookedEndAt) { bookSize++ }
                // else if (bookedAt >= bookStart && bookedAt <= bookEnd) { bookSize++ };
            }
        }
        let response = { success: true, message: res.size > 0 ? 'พบการจองในช่วงเวลานี้' : 'ไม่มีการจองในช่วงเวลานี้', bookSize: bookSize };
        console.log(response);
        if (response.bookSize > 0) {
            Swal.fire({
                icon: 'warning',
                title: "แม่บ้านรับงานแล้ว",
                text: 'แม่บ้านได้มีการรับงานในช่วงเวลาที่กำหนดแล้ว, ต้องการดำเนินการต่อหรือไม่ ?',
                showConfirmButton: true,
                confirmButtonText: 'ดำเนินการต่อ',
                denyButtonText: 'ยกเลิก',
                showDenyButton: true,
                reverseButtons: true,
            }).then((e) => {
                if (e.isConfirmed) this.props.onSubmit(data)
            })
        } else {
            this.props.onSubmit(data)
        }
        // this.props.onSubmit(data)
    }

    render() {
        if (this.state.mode === 'add' || this.state.mode === 'edit') {
            return this.renderAddForm();
        }
        let current_date = new Date();
        let end_date_select = new Date();
        end_date_select.setDate(end_date_select.getDate() + 7);
        return (
            <div style={{ display: this.props.isShow ? 'block' : 'none', alignItems: 'center', flexDirection: 'column' }}>
                <div className={"row"}>
                    <div className="col-12 col-md-4 offset-md-2">
                        {
                            this.state.places.length > 0 ?
                                <div className="row">
                                    {
                                        this.state.places.map((place, place_i) => {
                                            return (
                                                <div className="col-12 col-md-6" key={place_i}>
                                                    <div className="card"
                                                        onClick={() => {
                                                            if (this.state.place_selected
                                                                && this.state.place_selected.uid === place.uid) { return; }
                                                            this.setState({
                                                                place_selected: place
                                                            })
                                                        }}
                                                    >
                                                        <div className="card-body"
                                                            style={this.state.place_selected
                                                                && this.state.place_selected.uid === place.uid ? { border: '2px solid green' } : null}
                                                        >
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                                <div><strong>{place.placeType.name_th}</strong></div>
                                                                <div>{place.name}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                : <div className="form-group">ไม่มีข้อมูลสถานที่</div>
                        }
                        <div className="form-group">
                            <button
                                className="btn btn-default"
                                onClick={() => {
                                    this.setState({
                                        mode: 'add',
                                        is_loading_service_area: true,
                                    }, () => {
                                        this.fetchServiceArea();
                                    })
                                }}
                            >เพิ่มสถานที่</button>
                        </div>
                        {
                            this.state.booking_time.length > 0 ?
                                <div className="form-group">
                                    <select className="form-control"
                                        name={"amphoe"}
                                        onChange={(e) => {
                                            if (e.target.value === "" || !e.target.value) { return; }
                                            this.setState({
                                                selected_booking_time: e.target.value,
                                            })
                                        }}
                                        defaultValue={this.state.selected_booking_time}
                                    >
                                        <option value="">เลือกระยะเวลาในการทำงาน</option>
                                        {
                                            this.state.booking_time.map((time, i) => {
                                                if (time.uid === 'j9ShqVGbZZvDiqDErY33') { return null };
                                                return (
                                                    <option value={time.uid} key={i}
                                                    >{time.name_th}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                : null
                        }
                        <div className="form-group">
                            <DatePicker
                                onChange={date => {
                                    this.setState({
                                        selected_booking_date: date
                                    })
                                }}
                                locale="th-TH"
                                selected={this.state.selected_booking_date}
                                placeholderText={"วัน / เดือน / ปี"}
                                name={"booking_date"}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                withPortal
                                onCalendarOpen={() => { Helper.renderDate() }}
                                // maxDate={end_date_select}
                                minDate={current_date}
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => {
                                    let now = new Date();
                                    // months_th
                                    let selected_date = new Date(date);
                                    const years = [];
                                    let i;
                                    for (i = 1960; i <= now.getFullYear() + 1; i++) {
                                        years.push(i)
                                    }
                                    return (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                className={"btn btn-primary "}
                                                type={"button"}
                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                            >
                                                <i className="fas fa-chevron-left" />
                                            </button>
                                            <select
                                                className={"form-control"}
                                                value={selected_date.getFullYear()}
                                                onChange={({ target: { value } }) => changeYear(value)}
                                                style={{ borderRadius: 0 }}
                                            >
                                                {years.map(option => (
                                                    <option key={option} value={option} >
                                                        {option + 543}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                className={"form-control"}
                                                onChange={({ target: { value } }) =>
                                                    changeMonth(window.months_th.indexOf(value))
                                                }
                                                value={window.months_th[selected_date.getMonth()]}
                                                style={{ borderRadius: 0 }}
                                            >
                                                {window.months_th.map(option => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                className={"btn btn-primary"}
                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                type={"button"}
                                            >
                                                <i className="fas fa-chevron-right" />
                                            </button>
                                        </div>
                                    )
                                }}
                                customInput={<CustomDateInput empty_title={'เลือกวันที่จอง'} />}
                            />
                        </div>
                        {
                            this.state.time.length > 0 ?
                                <div className="form-group">
                                    <select className="form-control"
                                        name={"amphoe"}
                                        onChange={(e) => {
                                            if (e.target.value === "" || !e.target.value) { return; }
                                            this.setState({
                                                selected_time: e.target.value,
                                            })
                                        }}
                                        defaultValue={this.state.selected_time}
                                    >
                                        <option value="">เลือกเวลาจอง</option>
                                        {
                                            this.state.time.map((time, i) => (
                                                <option value={time.value} key={i}
                                                >{time.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                : null
                        }

                        <button
                            className="btn btn-success btn-lg"
                            style={{ width: '100%' }}
                            onClick={() => {
                                let data = {}
                                data.place = this.state.place_selected;
                                data.bookingDuration = this.state.selected_booking_time;
                                data.bookingDate = this.state.selected_booking_date;
                                data.bookingTime = this.state.selected_time;
                                // console.log(data);return;
                                this.checkAcceptedInSameTime(data)
                            }}
                        >ต่อไป <i className="fas fa-arrow-right" /></button>
                    </div>

                    {
                        this.state.place_selected ?
                            <div className="col-md-4">
                                {this.renderLocationDetail()}
                            </div>
                            : null
                    }
                </div>
            </div>
        )
    }
}

class SlipUpload extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            provider: false,
            user: false,
            summary: {
                fee: 0,
                other_fee: 0,
                vat: 0,
                total: 0,
                discount: 0,
                price_with_out_vat: 0,
            },
            promotionCode: false,
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.data.providerUid !== this.props.data.providerUid
            || prevProps.data.userUid !== this.props.data.userUid
        ) {
            this.setState({
                is_loading: true,
                provider: false,
                user: false,
                summary: {
                    fee: 0,
                    other_fee: 0,
                    vat: 0,
                    total: 0,
                    discount: 0,
                    price_with_out_vat: 0,
                },
                promotionCode: false,
            }, () => {
                this.fetch();
            })
        }
        if (prevProps.data.step !== this.props.data.step && this.props.data.step === 4) {
            this.summary()
        }
    }

    async promotionCodeChecking(promotionCode) {
        if (!promotionCode) {
            Helper.messageTop({
                message: 'กรุณากรอกรหัสโปรโมชั่น'
            })
            return false;
        }
        let ref = await db.collection('promotions').where('promotionType', '!=', 'done').where('voucherCode', '==', promotionCode).get();
        if (ref.empty) {
            Helper.messageTop({
                message: 'ไม่พบโปรโมชั่น'
            })
            return false;
        }
        for (const doc of ref.docs) {
            console.log(`promotions`, doc)
        }
        if (!ref.empty) {
            let promotion = ref.docs[0].data();
            promotion.uid = ref.docs[0].id;
            let historyRef = await db.collection('promotions').doc(promotion.uid).collection("history").get();
            let history_count = 0;
            history_count = historyRef.size;
            if (promotion.EndAt
                && new Date(promotion.EndAt) < new Date()
                && !promotion.isAllTime) {
                Helper.messageTop({
                    message: 'โปรโมชั่นหมดอายุ'
                })
                return false;
            }
            if (history_count >= promotion.limit
                && !promotion.isUnLimit) {
                Helper.messageTop({
                    message: 'โปรโมชั่นครบจำนวนสิทธิ์'
                })
                return false;
            }
            let historyUser = await db.collection('promotions').doc(promotion.uid).collection("history").where('userUid', '==', this.props.data.userUid).get();
            let history_user_count = 0;
            history_user_count = historyUser.size;

            if ((promotion.promotionCondition === 'only_first_code' || promotion.promotionCondition === 'only_first_time_plus_code') && history_user_count > 0) {
                Helper.messageTop({
                    message: 'สมาชิกท่านนี้เคยใช้โปรโมชั่นนี้แล้ว'
                })
                return false;
            }
            if ((promotion.promotionCondition === 'only_first_time' || promotion.promotionCondition === 'only_first_time_plus_code') && this.state.user.useFirstCode) {
                Helper.messageTop({
                    message: 'สมาชิกท่านนี้เคยใช้โปรโมชั่นนี้แล้ว'
                })
                return false;
            }

            this.setState({
                promotionCode: promotionCode,
                promotion: promotion,
            }, () => {
                this.summary()
            })
            return true
        }
    }

    async summary() {
        const bookTimeDoc = await db.doc(`book_time/${this.props.data.bookingDuration}`).get();
        const calculateTimeDoc = await db.collection('calculate_time').orderBy('sequence').get();
        const vatDoc = await db.doc('constants/vatUser').get();
        let countHour = 0;
        let priceProvider = 0;
        let priceApp = 0;
        let priceDiscount = 0;
        let priceTotal = bookTimeDoc.data().price;
        let priceWithOutVat = 0;

        calculateTimeDoc.docs.forEach(element => {
            while (countHour < element.data().count_hour || element.data().infinite) {
                if (countHour >= bookTimeDoc.data().time) {
                    break;
                }
                priceProvider += element.data().price;
                countHour++;
            }
        })

        if (this.state.promotionCode) {
            const promotions = await db.collection('promotions').doc(this.state.promotion.uid).get();
            if (promotions.exists) {
                let promo_data = promotions.data();
                if (promo_data.valueType === 'raw') {
                    priceDiscount = parseFloat(promo_data.value);
                }
                if (promo_data.valueType === 'percent') {
                    priceDiscount = (priceTotal * parseFloat(promo_data.value)) / 100;
                }
            }
        }

        priceApp = priceTotal - priceProvider;
        priceWithOutVat = parseFloat(priceTotal) - parseFloat(priceDiscount);
        let vat = (priceWithOutVat * parseFloat(vatDoc.data().value)) / 100;



        let total = (priceTotal + vat)
        total = total - priceDiscount;
        if (total < 0) {
            total = 0;
        }
        if (priceWithOutVat < 0) {
            priceWithOutVat = 0;
        }
        if (vat < 0) {
            vat = 0;
        }
        total = total.toFixed(2)
        // console.log(parseFloat(total))
        let summary = {
            fee: priceTotal,
            other_fee: 0,
            vat: vat,
            total: parseFloat(total),
            vat_unit: vatDoc.data().value + '%',
            discount: priceDiscount,
            price_with_out_vat: priceWithOutVat,
        }
        // console.log(summary)

        this.setState({
            summary: summary
        })
    }

    async fetch() {
        // alert('fetch')
        let provider = false;
        if (this.props.data.providerUid) {
            let providerRef = await db.collection('provider_users').doc(this.props.data.providerUid).get();
            // console.log("providerUid", providerRef)
            if (providerRef.exists) {
                provider = { ...providerRef.data() }
                let providerDataRef = await db.collection('provider_users_data').where('phoneNumber', '==', provider.phoneNumber).get();
                if (!providerDataRef.empty) {
                    let providerData = providerDataRef.docs[0].data();
                    provider.displayName = providerData.firstName + ' ' + providerData.lastName;
                    provider = { ...provider, ...providerData };
                }
            }
        }
        let user = false;
        if (this.props.data.userUid) {
            let userRef = await db.collection('users').doc(this.props.data.userUid).get()
            if (userRef.exists) {
                user = { ...userRef.data() };
            }
        }
        this.setState({
            user: user,
            provider: provider,
            is_loading: false,
        })
    }

    async uploadFile(node, options) {
        // let id = options.PromotionId;
        let upload_btn = node.parent().find('.btn');
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        const filename = Helper.setNewImageFileName(file);
        let reszieFile = await Helper.resizeImageFn(file, { maxWidth: 800, maxHeight: 800 });
        var uploadTask = storage.ref().child('bank_transfer/' + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log(snapshot);
        }, (error) => {
            console.log(error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            upload_btn.removeClass('loading');
        }, async () => {
            let url = 'bank_transfer/' + filename;
            if (options.onSuccess) {
                options.onSuccess(url);
            }

        });
    }
    readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.$file.parent().find('.button').text('เปลี่ยนรูป');
                window.$('#show_image').html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
                window.$('#show_image').html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    async createBooking(data) {
        if ((!this.$file || !this.$file[0].files[0])) {
            Helper.messageTop({ message: 'กรุณาอัพโหลดหลักฐานการโอนเงิน' })
            this.$submit.removeClass('loading');
            this.$file.parent().find('.btn').removeClass('loading')
            return;
        }
        data.adminUid = this.props.adminUid;
        this.uploadFile(this.$file, {
            onSuccess: async (url) => {
                data.slipImageURL = await storage.ref(url).getDownloadURL();
                data.slipStoragePath = url;
                try {
                    let res = await axios.post(`${window.api_host}/books-create-new-backend`, { ...data });
                    if (res.data.success) {
                        navigate('/booking/' + res.data.bookUid);
                    }
                    if (res.data.error) {
                        Helper.messageTop({ message: res.data.message })
                        storage.ref(data.slipStoragePath).delete();
                        this.$submit.removeClass('loading');
                        this.$file.parent().find('.btn').removeClass('loading')
                    }
                } catch (error) {
                    this.$submit.removeClass('loading');
                    this.$file.parent().find('.btn').removeClass('loading')
                    if (data.slipStoragePath) {
                        storage.ref(data.slipStoragePath).delete();
                    }
                    if (error && error.response && error.response.data && error.response.data.message) {
                        const str = error.response.data.message;
                        const arr = str.split(" ");
                        for (var i = 0; i < arr.length; i++) {
                            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

                        }
                        const str2 = arr.join(" ");
                        Helper.messageTop({ message: str2 })
                    } else {
                        Helper.messageTop({ message: "Server Error Please Contact Developer" })
                    }
                }
            }
        })

    }

    render() {
        let selected_date = false;
        if (this.props.data.bookingDate) {
            selected_date = new Date(this.props.data.bookingDate)
        }
        return (
            <div style={{ display: this.props.isShow ? 'block' : 'none', alignItems: 'center', flexDirection: 'column' }}>
                <div className={"row"}>
                    <div className="col-12 col-md-8 offset-md-2">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                {
                                    this.state.is_loading ?
                                        <>
                                            <div style={{ padding: '1rem', textAlign: 'center' }}>
                                                <div className="ui active inline loader" ></div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="form-group">
                                                {
                                                    this.state.promotionCode ?
                                                        <button className="btn btn-default" onClick={() => { this.setState({ promotionCode: false }, () => { this.summary() }) }}>เปลี่ยนโปรโมชั่น</button>
                                                        :
                                                        <div className="input-group">
                                                            <input name="promotionCode" className="form-control" placeholder="รหัสโปรโมชั่น" />
                                                            <span className="input-group-append">
                                                                <button
                                                                    ref={(ref) => {
                                                                        let thisBtn = window.$(ref);
                                                                        thisBtn.off().on('click', async () => {
                                                                            if (thisBtn.hasClass('loading')) { return; }
                                                                            thisBtn.addClass('loading');
                                                                            let val = thisBtn.parents('.input-group').find('input[name="promotionCode"]').val();
                                                                            let res = await this.promotionCodeChecking(val);
                                                                            thisBtn.removeClass('loading');
                                                                        })
                                                                    }}
                                                                    type="button"
                                                                    className="btn btn-info btn-flat"
                                                                >ใช้</button>
                                                            </span>
                                                        </div>
                                                }
                                            </div>
                                            <div className="card form-group">
                                                <div className="card-body">
                                                    <div>
                                                        <div className="form-group">
                                                            <div className="flex row">
                                                                <div>วันที่</div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {
                                                                        this.props.data.bookingDate ?
                                                                            Helper.getDateThai(new Date(this.props.data.bookingDate))
                                                                            : '-'
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="flex row">
                                                                <div>เวลา</div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {
                                                                        this.props.data.bookingTime ?
                                                                            this.props.data.bookingTime
                                                                            : '-'
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="divider form-group" />
                                                        <div className="form-group">
                                                            <div className="flex row">
                                                                <div>ค่าบริการทำความสะอาด</div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {Helper.numberFormat(this.state.summary.fee, { decimal: 2 })} บาท
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="flex row">
                                                                <div>ค่าบริการอื่นๆ</div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {Helper.numberFormat(this.state.summary.other, { decimal: 2 })} บาท
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.summary.discount ?
                                                                <>
                                                                    <div className="form-group">
                                                                        <div className="flex row">
                                                                            <div>ส่วนลด {this.state.promotionCode}</div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                {Helper.numberFormat(this.state.summary.discount, { decimal: 2 })} บาท
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.promotionCode ?
                                                                            <div className="form-group">
                                                                                <div className="flex row">
                                                                                    <div>ค่าบริการไม่รวมภาษี</div>
                                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                                        {Helper.numberFormat(this.state.summary.price_with_out_vat, { decimal: 2 })} บาท
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            : null
                                                                    }
                                                                </>
                                                                : null
                                                        }
                                                        <div className="form-group">
                                                            <div className="flex row">
                                                                <div>ภาษี {this.state.summary.vat_unit || null}</div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {Helper.numberFormat(this.state.summary.vat, { decimal: 2 })} บาท
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="divider form-group" />
                                                        <div className="form-group">
                                                            <div className="flex row">
                                                                <div>รวมทั้งหมด</div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {Helper.numberFormat(this.state.summary.total, { decimal: 2 })} บาท
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <form
                                                ref={(ref) => {
                                                    this.$form = window.$(ref)
                                                }}
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    if (this.$submit.hasClass('loading')) { return false; }
                                                    this.$submit.addClass('loading');
                                                    let data = Helper.getFormInputObject(this.$form);
                                                    if (data.havePet) {
                                                        data.havePet = true;
                                                    } else {
                                                        data.havePet = false;
                                                    }
                                                    data.isUseBCProduct = false;
                                                    // console.log(data)
                                                    this.createBooking(data);
                                                    // this.$submit.removeClass('loading');
                                                }}
                                            >

                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input"
                                                            type="checkbox"
                                                            name="havePet"
                                                            id="havePet"
                                                            defaultValue="ไม่มี"
                                                            defaultChecked={false}
                                                        />
                                                        <label className="form-check-label" htmlFor="havePet">มีสัตว์เลี้ยง เช่น หมา,แมว</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input name="note" className="form-control" placeholder="หมายเหตุ/ข้อความถึงแม่บ้าน" />
                                                </div>
                                                {
                                                    this.state.promotionCode ?
                                                        <input type="hidden" name="voucherCode" value={this.state.promotionCode} readOnly={true} />
                                                        : null
                                                }
                                                {
                                                    this.props.data ?
                                                        <>
                                                            <input type="hidden" name="userUid" value={this.props.data.userUid} readOnly={true} />
                                                            <input type="hidden" name="providerUserUid" value={this.props.data.providerUid} readOnly={true} />
                                                            {
                                                                this.props.data.place ?
                                                                    <input type="hidden" name="placeUid" value={this.props.data.place.uid} readOnly={true} />
                                                                    : null
                                                            }
                                                            <input type="hidden" name="bookTimeUid" value={this.props.data.bookingDuration} readOnly={true} />
                                                            <input type="hidden" name="timeRageOfService" value={this.props.data.bookingTime} readOnly={true} />
                                                            {
                                                                selected_date ?
                                                                    <>
                                                                        <input type="hidden" name="day" value={selected_date.getDate()} readOnly={true} />
                                                                        <input type="hidden" name="month" value={selected_date.getMonth()} readOnly={true} />
                                                                        <input type="hidden" name="year" value={selected_date.getFullYear()} readOnly={true} />
                                                                    </>
                                                                    : null
                                                            }
                                                        </>
                                                        : null
                                                }
                                                <div className={"form-group"}>
                                                    <label>หลักฐานการโอนเงิน</label>
                                                    <input name="image"
                                                        type={"file"}
                                                        ref={(ref) => this.$file = window.$(ref)}
                                                        style={{ display: 'none' }}
                                                        id="file_image_bank"
                                                        onChange={(e) => {
                                                            this.readURL(this.$file[0]);
                                                        }}
                                                    />
                                                    <div id="show_image">
                                                        {
                                                            this.props.data && this.props.data.slipImageURL ?
                                                                <img src={this.props.data.slipImageURL} alt={"PromotionPic"} style={{ maxWidth: '100%' }} />
                                                                : null
                                                        }
                                                    </div>
                                                    <label htmlFor="file_image_bank"
                                                        type={"button"}
                                                        className="btn btn-default"
                                                        style={{ width: '100%' }}
                                                    >
                                                        {
                                                            this.props.data && this.props.data.slipImageURL ?
                                                                "เปลี่ยนรูป"
                                                                : "อัพโหลด"
                                                        }
                                                    </label>
                                                </div>
                                                <div className="form-group">
                                                    <input name="transfer_note" className="form-control" placeholder="หมายเหตุการชำระเงิน" />
                                                </div>
                                                <button
                                                    ref={(ref) => { this.$submit = window.$(ref) }}
                                                    className="btn btn-success btn-lg"
                                                    style={{ width: '100%' }}
                                                    type={"submit"}
                                                >
                                                    ดำเนินการ <i className="fas fa-arrow-right" />
                                                </button>

                                            </form>
                                        </>
                                }

                            </div>
                            <div className="col-12 col-md-8">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        {
                                            this.state.user ?
                                                <div className="card form-group">
                                                    <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <h3 className="card-title">
                                                            สมาชิก
                                                        </h3>
                                                    </div>
                                                    <div className="card-body" >
                                                        <div className="row">
                                                            <div className="col-sm-12 form-group">
                                                                <img src={this.state.user.photoURL ? this.state.user.photoURL : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div>
                                                                    <label>ชื่อ</label> : {this.state.user.displayName}
                                                                </div>
                                                                <div>
                                                                    <label>อีเมล</label> :  {this.state.user.email}  {this.state.user.guestEmail}
                                                                </div>
                                                                <div>
                                                                    <label>เบอร์โทรศัพท์</label> :  {this.state.user.phoneNumber} {this.state.user.guestPhoneNumber}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {
                                            this.state.provider ?
                                                <div className="card form-group">
                                                    <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <h3 className="card-title">
                                                            แม่บ้าน
                                                        </h3>
                                                    </div>
                                                    <div className="card-body" >
                                                        {
                                                            this.state.is_loading_selected ?
                                                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                                                    <div className="ui active inline loader" ></div>
                                                                </div>
                                                                :
                                                                <div className="row">
                                                                    <div className="col-sm-12 form-group">
                                                                        <img src={this.state.provider.profileImage ? this.state.provider.profileImage : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div>
                                                                            <label>ชื่อ</label> : {this.state.provider.displayName}
                                                                        </div>
                                                                        <div>
                                                                            <label>อีเมล</label> :  {this.state.provider.email}
                                                                        </div>
                                                                        <div>
                                                                            <label>เบอร์โทรศัพท์</label> :  {this.state.provider.phoneNumber}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                        }

                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    {
                                        this.props.data.place ?
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <GoogleMap
                                                            mode="view"
                                                            lat={this.props.data.place.coordinate.latitude}
                                                            lng={this.props.data.place.coordinate.longitude}
                                                            height={300}
                                                        />
                                                        <div className="divider" />
                                                        <div><strong>{this.props.data.place.name}</strong>{this.props.data.place.addressDetail ? ', ' + this.props.data.place.addressDetail : null}</div>
                                                        <div><strong>ประเภท </strong>: {this.props.data.place.placeType.name_th} {this.props.data.place.placeType.place_sub_type[this.props.data.place.placeSubTypeUid].name_th} {this.props.data.place.placeType.place_sub_type[this.props.data.place.placeSubTypeUid].description_th}</div>
                                                        <div>{this.props.data.place.address}</div>
                                                        {
                                                            this.props.data.place.note ?
                                                                <div><strong>หมายเหตุ </strong> : {this.props.data.place.note}</div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}