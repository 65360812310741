import React from 'react';
import { Helper } from './../Helper';
import { Link, navigate } from '@reach/router';
import Pagination from './../Components/Pagination';
import { db, auth } from './../firebase';
const axios = require('axios').default;

export default class AdminSetting extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            admin_count: 0,
            is_loading: true,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            userUID: Helper.getCookie('user') && Helper.getCookie('user') !== "" ? JSON.parse(Helper.getCookie('user'))._fieldsProto.id.stringValue : false,
        }
    }


    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        let qs = {
            limit: this.state.limit,
            page: this.state.page,
        }
        if (this.state.name) {
            qs.name = this.state.name;
        }
        let res = await axios.get(`${window.api_host}/admin?${window.$.param(qs)}`);
        // console.log(res)
        let data = [];
        let count = 0;
        if (res.data.code === 200) {
            data = res.data.data;
            count = res.data.count_all;
        }
        this.setState({
            data: data,
            is_loading: false,
            all_items: count,
            admin_count: count,
        })

    }



    async delete(data) {
        // auth.deleteUser(data.id);
        try {
            let _delete = await axios.post(`${window.api_host}/delete-user`, {
                userUID: this.state.userUID,
                deleteUserUID: data.id
            });
            if (_delete.data.code === 200) {
                this.fetchData();
                window.$(document).Toasts('create', {
                    title: 'ลบข้อมูลสำเร็จ',
                    body: 'ทำการลบข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
            }
        } catch (error) {
            console.log(error);
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ไม่สามารถลบได้',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
        }

    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ผู้ดูแลและสิทธิการเข้าถึง</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ผู้ดูแลและสิทธิการเข้าถึง</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายชื่อผู้ดูแล</h3>
                                <div style={{ marginLeft: 15 }}>
                                    <button className={"btn btn-primary btn-xs"}
                                        onClick={() => {
                                            Helper.reactToDom(
                                                window.$('body'),
                                                <AdminSettingForm
                                                    mode={"create"}
                                                    onCreate={(data) => {
                                                        this.fetchData();
                                                    }}
                                                />
                                            );
                                        }}
                                    >
                                        เพิ่มผู้ดูแล
                                    </button>
                                </div>
                                <div className={"card-right"}>
                                    <div style={{ marginRight: 10 }}>ทั้งหมด {this.state.admin_count} คน</div>
                                </div>
                                <div className="card-tools">
                                    <form className="input-group input-group-sm" ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                page: 1,
                                                name: data.name,
                                            })
                                            navigate(url)
                                        }}
                                    >
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากชื่อ" defaultValue={this.state.name} />

                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20%' }}>ชื่อ</th>
                                            <th>สิทธิการเข้าถึง</th>
                                            <th style={{ width: 200 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {_data.displayName}
                                                            </td>
                                                            <td className={"btn-space"}>
                                                                {
                                                                    _data.isCanEditAdmin ?
                                                                        <div className={_data.isCanEditAdmin ? "btn btn-success" : "btn btn-default"}>
                                                                            <i className="fas fa-user-cog" /> ผู้ดูแลและสิทธิในการใช้งาน
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.isCanEditUsers ?
                                                                        <div className={_data.isCanEditUsers ? "btn btn-success" : "btn btn-default"}>
                                                                            <i className="fas fas fa-user-friends" /> สมาชิก
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.isCanEditProvider ?
                                                                        <div className={_data.isCanEditProvider ? "btn btn-success" : "btn btn-default"}>
                                                                            <i className="fas fa-users" /> แม่บ้าน
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.isCanEditBooking ?
                                                                        <div className={_data.isCanEditBooking ? "btn btn-success" : "btn btn-default"}>
                                                                            <i className="fas fas fa-clipboard-check" /> Booking
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.isCanEditPromotion ?
                                                                        <div className={_data.isCanEditPromotion ? "btn btn-success" : "btn btn-default"}>
                                                                            <i className="fas fa-tags" /> โปรโมชั่น
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.isCanEditSalary ?
                                                                        <div className={_data.isCanEditSalary ? "btn btn-success" : "btn btn-default"}>
                                                                            <i className="fas fa-wallet" /> รายได้
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.isCanEditTraningDay ?
                                                                        <div className={_data.isCanEditTraningDay ? "btn btn-success" : "btn btn-default"}>
                                                                            <i className="fas fa-calendar-check" /> การอบรม
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.isCanEditServiceArea ?
                                                                        <div className={_data.isCanEditServiceArea ? "btn btn-success" : "btn btn-default"}>
                                                                            <i className="fas fa-map-marked-alt" /> จัดการพื้นที่ให้บริการ
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.isCanEditApp ?
                                                                        <div className={_data.isCanEditApp ? "btn btn-success" : "btn btn-default"}>
                                                                            <i className="fas fa-calendar-check" /> จัดการข้อมูล APP
                                                                        </div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    {
                                                                        !_data.admin ?
                                                                            <>

                                                                                <button className="btn btn-default"
                                                                                    onClick={() => {
                                                                                        Helper.reactToDom(
                                                                                            window.$('body'),
                                                                                            <AdminSettingForm
                                                                                                mode={"edit"}
                                                                                                data={_data}
                                                                                                onUpdate={(data) => {
                                                                                                    this.fetchData();
                                                                                                }}
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                >แก้ไขข้อมูลผู้ดูแล</button>
                                                                                {
                                                                                    this.props.is_super_admin ?
                                                                                        <button type="button" className="btn btn-danger"
                                                                                            ref={(ref) => {
                                                                                                window.$(ref).off().on('click', () => {
                                                                                                    if (window.$(ref).hasClass('loading')) { return; }
                                                                                                    window.$(ref).addClass('loading');
                                                                                                    Helper.confirmModal({
                                                                                                        title: "ยืนยันลบผู้ดูแล",
                                                                                                        description: "ต้องการลบผู้ดูแลนี้หรือไม่ ?",
                                                                                                        onConfirm: () => {
                                                                                                            window.$(ref).removeClass('loading');
                                                                                                            this.delete(_data);
                                                                                                        },
                                                                                                        onCancel: () => {
                                                                                                            window.$(ref).removeClass('loading');
                                                                                                        }
                                                                                                    });
                                                                                                })
                                                                                            }
                                                                                            }
                                                                                        >ลบ</button>
                                                                                        : null
                                                                                }
                                                                            </>
                                                                            : null
                                                                    }


                                                                    {/* <Link to={'/user/' + _data.id} type="button" className="btn btn-default">ดูข้อมูล</Link> */}
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="4" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class AdminSettingForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data || {},
        }
    }

    async create(data) {
        await auth.createUserWithEmailAndPassword(data.email, data.password).then(async (doc) => {
            await db.doc('admin/' + doc.user.uid).set({ ...data, id: doc.user.uid }).then(() => {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มข้อมูลสำเร็จ',
                    body: 'ทำการเพิ่มผู้ดแลเรียบร้อยแล้ว',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                this.$modal.modal('hide');
                this.props.onCreate()
            })
        }).catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            // ..
            console.log(errorMessage);
            console.log(errorCode)
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: errorMessage,
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            });
            this.$form.find('button[type="submit"]').removeClass('loading')
        });
    }

    async update(data) {
        await db.doc("admin/" + data.id).update({ ...data }).then(() => {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.$modal.modal('hide');
            this.props.onUpdate()
            this.$form.find('button[type="submit"]').removeClass('loading')
        })
    }

    render() {
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            let _data = Helper.getFormInputObject(this.$form);
                            data.isCanEditAdmin = _data.isCanEditAdmin ? true : false;
                            data.isCanEditUsers = _data.isCanEditUsers ? true : false;
                            data.isCanEditProvider = _data.isCanEditProvider ? true : false;
                            data.isCanEditBooking = _data.isCanEditBooking ? true : false;
                            data.isCanEditTraningDay = _data.isCanEditTraningDay ? true : false;
                            data.isCanEditSalary = _data.isCanEditSalary ? true : false;
                            data.isCanEditPromotion = _data.isCanEditPromotion ? true : false;
                            data.isCanEditApp = _data.isCanEditApp ? true : false;
                            data.isCanEditServiceArea = _data.isCanEditServiceArea ? true : false;
                            data.admin = _data.admin ? JSON.parse(_data.admin) : false;
                            console.log(data);
                            // return;
                            if (this.props.mode === 'edit') {
                                this.update(data);
                            }
                            if (this.props.mode === 'create') {
                                this.create(data);
                            }

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลผู้ดูแล</h4>
                            </div>
                            <div className="modal-body">
                                {
                                    this.state.data.id ?
                                        <input type="hidden" name="id" defaultValue={this.state.data.id} />
                                        : null
                                }
                                <input type="hidden" name="admin" defaultValue={this.state.data.admin || false} />
                                {
                                    this.props.mode === 'create' ?
                                        <>
                                            <div className={"form-group"}>
                                                <label>อีเมล์</label>
                                                <input type="hidden" name="admin" defaultValue={false} />
                                                <input className={"form-control"} name="email" defaultValue={this.state.data.email || ''} />
                                            </div>
                                            <div className={"form-group"}>
                                                <label>รหัสผ่าน</label>
                                                <input type={"password"} className={"form-control"} name="password" defaultValue={this.state.data.password || ''} />
                                            </div>
                                        </>
                                        : null
                                }
                                <div className={"form-group"}>
                                    <label>ชื่อ</label>
                                    <input className={"form-control"} name="displayName" defaultValue={this.state.data.displayName || ''} />
                                </div>
                                <div className={"form-group"}>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="isCanEditUsers"
                                            name={"isCanEditUsers"}
                                            defaultValue={"on"}
                                            defaultChecked={this.state.data.isCanEditUsers}
                                        />
                                        <label className="custom-control-label" htmlFor="isCanEditUsers">จัดการสมาชิก</label>
                                    </div>
                                </div>
                                <div className={"form-group"}>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="isCanEditProvider"
                                            name={"isCanEditProvider"}
                                            defaultValue={"on"}
                                            defaultChecked={this.state.data.isCanEditProvider}
                                        />
                                        <label className="custom-control-label" htmlFor="isCanEditProvider">จัดการแม่บ้าน</label>
                                    </div>
                                </div>
                                <div className={"form-group"}>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="isCanEditBooking"
                                            name={"isCanEditBooking"}
                                            defaultValue={"on"}
                                            defaultChecked={this.state.data.isCanEditBooking}
                                        />
                                        <label className="custom-control-label" htmlFor="isCanEditBooking">จัดการ Booking</label>
                                    </div>
                                </div>
                                <div className={"form-group"}>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="isCanEditTraningDay"
                                            name={"isCanEditTraningDay"}
                                            defaultValue={"on"}
                                            defaultChecked={this.state.data.isCanEditTraningDay}
                                        />
                                        <label className="custom-control-label" htmlFor="isCanEditTraningDay">จัดการวันอบรม</label>
                                    </div>
                                </div>
                                <div className={"form-group"}>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="isCanEditSalary"
                                            name={"isCanEditSalary"}
                                            defaultValue={"on"}
                                            defaultChecked={this.state.data.isCanEditSalary}
                                        />
                                        <label className="custom-control-label" htmlFor="isCanEditSalary">จัดการรายได้</label>
                                    </div>
                                </div>
                                <div className={"form-group"}>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="isCanEditPromotion"
                                            name={"isCanEditPromotion"}
                                            defaultValue={"on"}
                                            defaultChecked={this.state.data.isCanEditPromotion}
                                        />
                                        <label className="custom-control-label" htmlFor="isCanEditPromotion">จัดการโปรโมชั่น</label>
                                    </div>
                                </div>
                                <div className={"form-group"}>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="isCanEditAdmin"
                                            name={"isCanEditAdmin"}
                                            defaultValue={"on"}
                                            defaultChecked={this.state.data.isCanEditAdmin}
                                        />
                                        <label className="custom-control-label" htmlFor="isCanEditAdmin">จัดการผู้ดูแล</label>
                                    </div>
                                </div>
                                <div className={"form-group"}>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="isCanEditServiceArea"
                                            name={"isCanEditServiceArea"}
                                            defaultValue={"on"}
                                            defaultChecked={this.state.data.isCanEditServiceArea}
                                        />
                                        <label className="custom-control-label" htmlFor="isCanEditServiceArea">จัดการพื้นที่ให้บริการ</label>
                                    </div>
                                </div>
                                <div className={"form-group"}>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            id="isCanEditApp"
                                            name={"isCanEditApp"}
                                            defaultValue={"on"}
                                            defaultChecked={this.state.data.isCanEditApp}
                                        />
                                        <label className="custom-control-label" htmlFor="isCanEditApp">จัดการ APP</label>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}