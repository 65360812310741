import React, { Component } from 'react'
import { Link, navigate } from '@reach/router';
import { Helper } from '../../Helper';
import { agentBankService } from '../../Services/agentBankService';
import { storage } from './../../firebase';
import { agentService } from '../../Services/agentService';
import AgentProviderList from '../../Components/AgentProviderList';
const axios = require('axios').default;
const CancelToken = axios.CancelToken;
let cancel;

export default class View extends Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
            table_loading: true,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        if (cancel !== undefined) cancel();
        let data = this.state.data;
        if (!data) {
            let res = await axios.get(`${window.api_host_agent}/agents/${this.props.uid}`, {
                cancelToken: new CancelToken(c => cancel = c),
            });

            if (res.data.success) {
                data = res.data.agent;
            }
        }
        this.setState({
            data: data,
            is_loading: false,
        }, () => {
            cancel = undefined;
        })
    }

    async delete() {
        if (cancel !== undefined) cancel();

        try {
            let res = await axios.delete(`${window.api_host_agent}/agents/${this.props.uid}`, {
                cancelToken: new CancelToken(c => cancel = c),
            });
            if (res.data.code === 200) {
                window.$(document).Toasts('create', {
                    title: 'ยกเลิกสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                this.deleteBtn.removeClass('loading')
                navigate('/agents')
            } else {
                let error = "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง"
                if (res.data.message) {
                    error = res.data.message
                }
                window.$(document).Toasts('create', {
                    title: error,
                    // body: "ยกเลิกการชำระเงินไม่สำเร็จ",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'ลบ Booking ไม่สำเร็จ',
                // body: "ยกเลิกการชำระเงินไม่สำเร็จ",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.deleteBtn.removeClass('loading')
        }
    }

    async resetPassword(options) {
        // call api
        const res = await agentService.resetPasswordByAdmin(this.props.uid);
        let newPassword = res.password // set new password
        this.setState({
            newPassword: newPassword
        }, () => {
            if (options && options.clearLoadingButton) {
                options.clearLoadingButton();
            }
        })
    }

    render() {
        let agent = this.state.data;
        let totalCommission = agent?.wallet || 0;
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ตัวแทน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/agents">ตัวแทน</Link></li>
                                    <li className="breadcrumb-item active">ข้อมูลตัวแทน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }

                        {
                            !this.state.is_loading
                                && this.state.data ?
                                <>
                                    <div className='row'>
                                        <div className='col-12 col-md-3'>
                                            <div className="card">
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h3 className="card-title">
                                                        ข้อมูลตัวแทน
                                                    </h3>
                                                    <div className='card-tools' style={{ marginLeft: 'auto' }}>
                                                        <button
                                                            ref={(ref) => { this.deleteBtn = window.$(ref) }}
                                                            className='btn btn-default btn-sm'
                                                            onClick={() => {
                                                                Helper.reactToDom(window.$('body'),
                                                                    <UpdateInformationForm
                                                                        uid={this.props.uid}
                                                                        agent={agent}
                                                                        onSuccess={(data) => {
                                                                            let _data = { ...this.state.data, ...data };
                                                                            this.setState({
                                                                                data: _data,
                                                                            })
                                                                        }}
                                                                        type={"information"}
                                                                    />
                                                                )
                                                            }}
                                                        >แก้ไขข้อมูล</button>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div>
                                                        <label>ชื่อ</label> : {agent?.name}
                                                    </div>
                                                    {
                                                        agent.isAgentUpdatedInformation ?
                                                            <>
                                                                <div>
                                                                    <label>เลขบัตรประจำตัวประชาชน</label> : {agent?.personalNo}
                                                                </div>
                                                                <div>
                                                                    <label>ชื่อจริง</label> : {agent?.prefix} {agent?.firstName} {agent?.lastName}
                                                                </div>
                                                                <div>
                                                                    <label>เพศ</label> : {agent?.gender}
                                                                </div>
                                                                <div>
                                                                    <label>เบอร์โทรศัพท์ฉุกเฉิน</label> : {agent?.phoneNumber}
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                    <div>
                                                        <label>อีเมล</label> :  {agent?.email}
                                                    </div>
                                                    <div>
                                                        <label className='mb-2'>เบอร์โทรศัพท์ติดต่อ</label> :  {agent?.tel}
                                                    </div>
                                                    {/* <a target='_blank'
                                                        href={`https://beeclean-frontend.web.app/register?agent=${this.props.uid}`}
                                                        className='btn btn-default'
                                                    >
                                                        <i className='fas fa-link'/> สมัครแม่บ้าน
                                                    </a> */}
                                                    <button
                                                        className='btn btn-default'
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(`https://beeclean-frontend.web.app/register?agent=${this.props.uid}`);

                                                            // Alert the copied text
                                                            window.$(document).Toasts('create', {
                                                                title: 'คิดลอกลิ้งค์แล้ว',
                                                                icon: 'fas fa-clone',
                                                                autohide: true,
                                                                delay: 3000,
                                                                class: "bg-success",
                                                            })
                                                            // alert("Copied link: " + `https://beeclean-frontend.web.app/register?agent=${this.props.uid}`);
                                                        }}
                                                    >
                                                        <i className='fas fa-link' /> ลิงค์สมัครแม่บ้าน
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                agent.bank ?
                                                    <div className='card'>
                                                        <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <h3 className="card-title">
                                                                ข้อมูลธนาคาร
                                                            </h3>
                                                            <div className='card-tools' style={{ marginLeft: 'auto' }}>
                                                                <button
                                                                    ref={(ref) => { this.editBankBtn = window.$(ref) }}
                                                                    className={`btn btn-default btn-sm`}
                                                                    onClick={() => {
                                                                        Helper.reactToDom(window.$('body'),
                                                                            <BankForm
                                                                                uid={this.props.uid}
                                                                                agent={agent}
                                                                                onSuccess={(data) => {
                                                                                    let _data = { ...this.state.data, ...data };
                                                                                    this.setState({
                                                                                        data: _data,
                                                                                    })
                                                                                }}
                                                                            />
                                                                        )
                                                                    }}
                                                                >
                                                                    แก้ไขข้อมูล
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='card-body'>
                                                            <>
                                                                <div>
                                                                    <label>ธนาคาร</label> :  {agent.bank}
                                                                </div>
                                                                <div>
                                                                    <label>สาขา</label> :  {agent.bankBranch}
                                                                </div>
                                                                <div>
                                                                    <label>ชื่อบัญชี</label> :  {agent.bankAccountName}
                                                                </div>
                                                                <div>
                                                                    <label>เลขบัญชี</label> :  {agent.bankAccountNo}
                                                                </div>
                                                            </>
                                                        </div>
                                                    </div>
                                                    :
                                                    <button
                                                        ref={(ref) => { this.addBankBtn = window.$(ref) }}
                                                        className={`btn btn-primary`}
                                                        onClick={() => {
                                                            Helper.reactToDom(window.$('body'),
                                                                <BankForm
                                                                    uid={this.props.uid}
                                                                    agent={agent}
                                                                    onSuccess={(data) => {
                                                                        let _data = { ...this.state.data, ...data };
                                                                        this.setState({
                                                                            data: _data,
                                                                        })
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    >
                                                        เพิ่มข้อมูลธนาคาร
                                                    </button>
                                            }
                                            {
                                                agent.isAgentUpdatedInformation ?

                                                    <div className='card'>
                                                        <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <h3 className="card-title">
                                                                ข้อมูลที่อยู่
                                                            </h3>
                                                            <div className='card-tools' style={{ marginLeft: 'auto' }}>
                                                                <button
                                                                    ref={(ref) => { this.deleteBtn = window.$(ref) }}
                                                                    className='btn btn-default btn-sm'
                                                                    onClick={() => {
                                                                        Helper.reactToDom(window.$('body'),
                                                                            <UpdateInformationForm
                                                                                uid={this.props.uid}
                                                                                agent={agent}
                                                                                onSuccess={(data) => {
                                                                                    let _data = { ...this.state.data, ...data };
                                                                                    this.setState({
                                                                                        data: _data,
                                                                                    })
                                                                                }}
                                                                                type={"address"}
                                                                            />
                                                                        )
                                                                    }}
                                                                >แก้ไขข้อมูล</button>
                                                            </div>
                                                        </div>
                                                        <div className='card-body'>
                                                            <>
                                                                <div>
                                                                    <label>ที่อยู่ตามบัตรประชาชน</label> :  {agent.address}
                                                                </div>
                                                                <div>
                                                                    <label>เลขไปรษณีย์</label> :  {agent.zipcode}
                                                                </div>
                                                                <hr style={{ marginTop: 0 }} />
                                                                <div>
                                                                    <label>ที่อยู่จัดส่งเอกสาร</label> :  {agent.deliveryAddress}
                                                                </div>
                                                                <div>
                                                                    <label>เลขไปรษณีย์</label> :  {agent.deliveryZipcode}
                                                                </div>
                                                            </>
                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                        </div>
                                        <div className='col-12 col-md-3'>
                                            <div className="info-box mb-3">
                                                <span className={`info-box-icon ${totalCommission > 0 ?
                                                    "bg-success"
                                                    : totalCommission < 0 ?
                                                        "bg-danger"
                                                        :
                                                        "bg-info"
                                                    } elevation-1`}>
                                                    {
                                                        totalCommission > 0 ?
                                                            <i className="far fa-thumbs-up"></i>
                                                            : totalCommission < 0 ?
                                                                <i className="far fa-thumbs-down"></i>
                                                                :
                                                                <i className="fas fa-gem"></i>
                                                    }
                                                </span>
                                                <div className="info-box-content">
                                                    <span className="info-box-text text-muted">ค่าคอมมิชชั่น</span>
                                                    <span className="info-box-number" style={{ fontWeight: 'bold', fontSize: 22 }}>฿ {Helper.numberFormat(totalCommission, { decimal: 2 })}</span>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <Link to={`/agent/salary/${this.props.uid}`} style={{ marginLeft: 10 }}>
                                                        <button className="btn btn-default">รายละเอียด</button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className='card'>
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h3 className="card-title">
                                                        ข้อมูลค่าคอมมิชั่น
                                                    </h3>
                                                    <div className='card-tools' style={{ marginLeft: 'auto' }}>
                                                        <button
                                                            ref={(ref) => { this.editBankBtn = window.$(ref) }}
                                                            className={`btn btn-default btn-sm`}
                                                            onClick={() => {
                                                                Helper.reactToDom(window.$('body'),
                                                                    <CommissionForm
                                                                        uid={this.props.uid}
                                                                        agent={agent}
                                                                        onSuccess={(data) => {
                                                                            let _data = { ...this.state.data, ...data };
                                                                            this.setState({
                                                                                data: _data,
                                                                            })
                                                                        }}
                                                                    />
                                                                )
                                                            }}
                                                        >
                                                            แก้ไขข้อมูล
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='card-body'>
                                                    <>
                                                        <div>
                                                            <label>ค่าคอมมิชชั่นจากแม่บ้าน</label> :  {agent.agentCommissionRateProvider || 0}%
                                                        </div>
                                                        <div>
                                                            <label>ค่าคอมมิชชั่นจาก APP</label> :  {agent.agentCommissionRateApp || 0}%
                                                        </div>
                                                    </>
                                                </div>
                                            </div>
                                            {
                                                agent.isAgentUpdatedInformation ?

                                                    <div className='card'>
                                                        <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <h3 className="card-title">
                                                                ข้อมูลรูป
                                                            </h3>
                                                            <div className='card-tools' style={{ marginLeft: 'auto' }}>
                                                                <button
                                                                    ref={(ref) => { this.editBankBtn = window.$(ref) }}
                                                                    className={`btn btn-default btn-sm`}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editImage: !this.state.editImage,
                                                                        })
                                                                    }}
                                                                >
                                                                    แก้ไขรูปภาพ
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='card-body'>
                                                            <div className='row'>
                                                                {
                                                                    agent.images.map((image, i) => {
                                                                        let title = false;
                                                                        if (image.inputName == 'profileImage') {
                                                                            title = "รูปโปรไฟล์"
                                                                        }
                                                                        if (image.inputName == 'personalCardImage') {
                                                                            title = "รูปสำเนาบัตรประชาชน"
                                                                        }
                                                                        if (image.inputName == 'bookBankImage') {
                                                                            title = "รูปเล่มธนาคาร"
                                                                        }
                                                                        if (image.inputName == 'addressBookImage') {
                                                                            title = "รูปสำเนาทะเบียนบ้าน"
                                                                        }
                                                                        return (
                                                                            <div className='col-3' key={i}>
                                                                                <a data-fancybox href={image.imagePath ? image.imagePath : "/assets/images/no_image.png"}
                                                                                    data-caption={title}
                                                                                    style={{ display: 'block', marginBottom: 15, width: 100, height: 100, overflow: 'hidden', borderRadius: 1000 }}
                                                                                >
                                                                                    <img src={image.imagePath ? image.imagePath : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                                                </a>
                                                                                <p style={{ textAlign: 'center' }}>{title}</p>
                                                                                {
                                                                                    this.state.editImage ?
                                                                                        <div style={{ marginTop: 15 }}>
                                                                                            <input name="updateImage"
                                                                                                type={"file"}
                                                                                                ref={(ref) => this[image.inputName] = window.$(ref)}
                                                                                                style={{ display: 'none' }}
                                                                                                id={image.inputName}
                                                                                                onChange={(e) => {
                                                                                                    if (!this[image.inputName][0] || !this[image.inputName][0].files[0]) { return false; }
                                                                                                    let upload_btn = this[image.inputName].parent().find('.btn');
                                                                                                    this.uploadFile(this[image.inputName], {
                                                                                                        onSuccess: async (url) => {
                                                                                                            let data = {
                                                                                                                ...url,
                                                                                                                inputName: image.inputName
                                                                                                            }
                                                                                                            let images = [...agent.images];
                                                                                                            let getIndex = images.findIndex((e) => { return e.inputName == image.inputName });
                                                                                                            images[getIndex] = data;
                                                                                                            // call api
                                                                                                            let res = await axios.put(`${window.api_host_agent}/agents/${agent.uid}`, { images: images });
                                                                                                            if (res.data.success) {
                                                                                                                if (image.storagePath) {
                                                                                                                    await storage.ref(image.storagePath).delete(); // delete old image
                                                                                                                }

                                                                                                                let agentUpdate = agent;
                                                                                                                agentUpdate.images = images;
                                                                                                                this.setState({
                                                                                                                    data: agentUpdate
                                                                                                                }, () => {
                                                                                                                    upload_btn.removeClass('loading')
                                                                                                                });
                                                                                                            }
                                                                                                            else {
                                                                                                                Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
                                                                                                                upload_btn.removeClass('loading')
                                                                                                            }

                                                                                                        }
                                                                                                    });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor={image.inputName} className="btn btn-default" style={{ width: '100%' }}>
                                                                                                อัพโหลดเอกสาร
                                                                                            </label>
                                                                                        </div>
                                                                                        : null
                                                                                }

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            <button
                                                ref={(ref) => {
                                                    let btnRef = window.$(ref);
                                                    btnRef.off().on('click', () => {
                                                        if (btnRef.hasClass('loading')) { return; }
                                                        btnRef.addClass('loading');
                                                        Helper.confirmModal({
                                                            title: "ยืนยันรีเซ็ทรหัสผ่าน",
                                                            description: "ต้องการรีเซ็ทรหัสผ่านหรือไม่ ?",
                                                            onConfirm: () => {

                                                                this.resetPassword({
                                                                    clearLoadingButton: () => {
                                                                        btnRef.removeClass('loading');
                                                                    }
                                                                })
                                                                // btnRef.removeClass('loading');
                                                            },
                                                            onCancel: () => {
                                                                btnRef.removeClass('loading');
                                                            }
                                                        });
                                                    })
                                                }}
                                                className='btn btn-default'
                                            >รีเซ็ทรหัสผ่าน</button>
                                            {
                                                this.state.newPassword ?
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        className='alert alert-success mt-2 d-flex'
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(this.state.newPassword);

                                                            // Alert the copied text
                                                            window.$(document).Toasts('create', {
                                                                title: 'คัดลอกรหัสผ่านใหม่แล้ว ' + this.state.newPassword,
                                                                icon: 'fas fa-clone',
                                                                autohide: true,
                                                                delay: 3000,
                                                                class: "bg-success",
                                                            })
                                                            // alert("Copied link: " + `https://beeclean-frontend.web.app/register?agent=${this.props.uid}`);
                                                        }}
                                                    >
                                                        <div style={{ flex: 1 }}>
                                                            รหัสผ่านใหม่ : {this.state.newPassword}
                                                        </div>
                                                        <div><i className='fa fa-copy' /></div>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <AgentProviderList
                                                agentUid={this.props.uid}
                                            />
                                        </div>
                                    </div>
                                </>
                                : null
                        }

                        {
                            !this.state.is_loading
                                && !this.state.data ?
                                <>
                                    <h2 className='text-center'>ไม่มีข้อมูล</h2>
                                </>
                                : null
                        }
                    </div>
                </section>
            </>
        )
    }
    async uploadFile(node, options) {
        let upload_btn = node.parent().find('.btn');
        let path = 'agentInformation/'
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        const filename = Helper.setNewImageFileName(file);
        let reszieFile = file;
        if (options.resize) {
            reszieFile = await Helper.resizeImageFn(file)
        }
        var uploadTask = storage.ref().child(path + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log(snapshot);
        }, (error) => {
            console.log(error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            upload_btn.removeClass('loading');
        }, async () => {
            let url = {};
            url.storePath = path + filename;
            url.imagePath = await storage.ref(path + filename).getDownloadURL();

            if (options.onSuccess) {
                options.onSuccess(url);
            }

        });
    }
}

export class BankForm extends React.Component {
    constructor() {
        super();
        this.state = {
            bank_list: [
                "ธนาคารกรุงเทพ",
                "ธนาคารกสิกรไทย",
                "ธนาคารกรุงไทย",
                "ธนาคารทหารไทย",
                "ธนาคารไทยพาณิชย์",
                "ธนาคารกรุงศรีอยุธยา",
                "ธนาคารเกียรตินาคินภัทร",
                "ธนาคารซีไอเอ็มบีไทย",
                "ธนาคารทิสโก้",
                "ธนาคารธนชาต",
                "ธนาคารยูโอบี",
                "ธนาคารไทยเครดิตเพื่อรายย่อย",
                "ธนาคารแลนด์แอนด์ เฮ้าส์",
                "ธนาคารไอซีบีซี (ไทย)",
                "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
                "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
                "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
                "ธนาคารออมสิน",
                "ธนาคารอาคารสงเคราะห์",
                "ธนาคารอิสลามแห่งประเทศไทย"
            ]
        }
    }

    async update(data) {
        // update api
        const res = await agentBankService.update({ uid: this.props.uid, ...data });
        if (res.error) {
            // if error
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: 'กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submit.removeClass('loading');
            return;
        }

        // if success 
        window.$(document).Toasts('create', {
            title: 'เพิ่มข้อมูลธนาคารสำเร็จ',
            icon: 'fas fa-check',
            autohide: true,
            delay: 3000,
            class: "bg-success",
        })
        if (this.props.onSuccess) {
            this.props.onSuccess(data);
        }
        this.$modal.modal('hide');
        this.$submit.removeClass('loading');
        return;

    }

    render() {
        let agent = this.props?.agent || {};
        return (
            <div className="modal fade"
                id="edit_bank"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        let submit_btn = this.$submit;
                        if (submit_btn.hasClass('loading')) { return false; }
                        submit_btn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลธนาคาร</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>ธนาคาร</label>
                                <select className="form-control" name={"bank"} defaultValue={agent.bank || ''} required={true}>
                                    <option value={""}>เลือกธนาคาร</option>
                                    {
                                        this.state.bank_list.map((bank, bank_i) => {
                                            return (
                                                <option key={bank_i} value={bank}>{bank}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label>สาขา</label>
                                <input name="bankBranch" defaultValue={agent.bankBranch || ""} className="form-control" required={true} />
                            </div>
                            <div className="form-group">
                                <label>ชื่อบัญชี</label>
                                <input name="bankAccountName" defaultValue={agent.bankAccountName || ""} className="form-control" required={true} />
                            </div>
                            <div className="form-group">
                                <label>เลขบัญชี</label>
                                <input name="bankAccountNo" defaultValue={agent.bankAccountNo || ""} className="form-control" required={true} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type={"button"} className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >ยกเลิก</button>
                            <button type={"submit"} className="btn btn-primary">บันทึก</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export class CommissionForm extends React.Component {
    constructor() {
        super();
        this.state = {

        }
    }

    async update(data) {
        // update api


        let res = await axios.put(`${window.api_host_agent}/agents/${this.props.agent.uid}`, data);
        if (res.data.success) {

            window.$(document).Toasts('create', {
                title: 'เพิ่มข้อมูลธนาคารสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            if (this.props.onSuccess) {
                this.props.onSuccess(data);
            }
            this.$modal.modal('hide');
            this.$submit.removeClass('loading');
            return;
        } else {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: 'กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submit.removeClass('loading');
            return;
        }
    }

    render() {
        let agent = this.props?.agent || {};
        return (
            <div className="modal fade"
                id="edit_bank"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        let submit_btn = this.$submit;
                        if (submit_btn.hasClass('loading')) { return false; }
                        submit_btn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลธนาคาร</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>ค่าคอมมิชชั่นจากแม่บ้าน (%)</label>
                                <input name="agentCommissionRateProvider" defaultValue={agent.agentCommissionRateProvider || "0"} className="form-control" required={true} type="number" />
                            </div>
                            <div className="form-group">
                                <label>ค่าคอมมิชชั่นจาก APP (%)</label>
                                <input name="agentCommissionRateApp" defaultValue={agent.agentCommissionRateApp || "0"} className="form-control" required={true} type="number" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type={"button"} className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >ยกเลิก</button>
                            <button type={"submit"} className="btn btn-primary">บันทึก</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const default_required = false;
export class UpdateInformationForm extends React.Component {
    constructor() {
        super();
        this.state = {

        }
    }

    async update(data) {
        // update api
        let res = await axios.put(`${window.api_host_agent}/agents/${this.props.agent.uid}`, data);
        if (res.data.success) {

            window.$(document).Toasts('create', {
                title: 'เพิ่มข้อมูลธนาคารสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            if (this.props.onSuccess) {
                this.props.onSuccess(data);
            }
            this.$modal.modal('hide');
            this.$submit.removeClass('loading');
            return;
        } else {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: 'กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submit.removeClass('loading');
            return;
        }
    }

    render() {
        let agent = this.props?.agent || {};
        let title = "แก้ไขข้อมูลตัวแทน";
        if (this.props.type && this.props.type == 'address') { title = "แก้ไขข้อมูลที่อยู่" }
        return (
            <div className="modal fade"
                id="edit_bank"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        let submit_btn = this.$submit;
                        if (submit_btn.hasClass('loading')) { return false; }
                        submit_btn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">{title}</h4>
                        </div>
                        <div className="modal-body">
                            {
                                !this.props.type
                                    || (this.props.type && this.props.type == 'information') ?
                                    <>
                                        <div className="form-group">
                                            <label>ชื่อ</label>
                                            <input name="name" defaultValue={agent.name || ""} className="form-control" required={default_required} type="text" />
                                        </div>
                                        <div className="form-group">
                                            <label>เลขบัตรประจำตัวประชาชน</label>
                                            <input name="personalNo" defaultValue={agent.personalNo || ""} className="form-control" required={default_required} type="text" />
                                        </div>
                                        <div>
                                            <label>ชื่อจริง</label>
                                            <div className={"row"}>
                                                <div className={"col-sm-4"}>
                                                    <div className="form-group">
                                                        <select
                                                            name={"prefix"}
                                                            className="form-control placeholder_font"
                                                            ref={(ref) => { this.$prefix = window.$(ref) }}
                                                            onChange={(e) => {
                                                                if (e.target.value === "") {
                                                                    this.$prefix.addClass("placeholder_font");
                                                                }
                                                                if (e.target.value !== "") {
                                                                    this.$prefix.removeClass("placeholder_font");
                                                                }
                                                            }}
                                                            defaultValue={agent.prefix || false}
                                                        >
                                                            <option value="">คำนำหน้านาม</option>
                                                            <option value="นาย">นาย</option>
                                                            <option value="นาง">นาง</option>
                                                            <option value="นางสาว">นางสาว</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className={"col-sm-4"}>
                                                    <div className="form-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            placeholder={"ชื่อ"}
                                                            name={"firstName"}
                                                            defaultValue={agent.firstName || ''}
                                                            required={default_required}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"col-sm-4"}>
                                                    <div className="form-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            placeholder={"นามสกุล"}
                                                            name="lastName"
                                                            defaultValue={agent.lastName || ''}
                                                            required={default_required}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>เพศ</label>
                                            <select
                                                className="form-control placeholder_font"
                                                name={"gender"}
                                                ref={(ref) => { this.$gender = window.$(ref) }}
                                                onChange={(e) => {
                                                    if (e.target.value === "") {
                                                        this.$gender.addClass("placeholder_font");
                                                    }
                                                    if (e.target.value !== "") {
                                                        this.$gender.removeClass("placeholder_font");
                                                    }
                                                }}
                                                defaultValue={agent.gender || false}
                                            >
                                                <option value="">เพศ</option>
                                                <option value="ชาย">ชาย</option>
                                                <option value="หญิง">หญิง</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>เบอร์โทรศัพท์ฉุกเฉิน</label>
                                            <input name="phoneNumber" defaultValue={agent.phoneNumber || ""} className="form-control" required={default_required} type="text" />
                                        </div>
                                    </>
                                    : null
                            }

                            {
                                !this.props.type
                                    || (this.props.type && this.props.type == 'address') ?
                                    <>
                                        <label>ข้อมูลที่อยู่</label>
                                        <div className="mb-3">
                                            <textarea
                                                defaultValue={agent.address || ""}
                                                className={"form-control"}
                                                placeholder={"ที่อยู่ตามบัตรประชาชน"}
                                                name="address"
                                                required={default_required}
                                                onChange={(e) => {
                                                    let deliveryAddressRef = window.$('textarea[name="deliveryAddress"]');
                                                    if (this.$checkboxHaveLink.prop("checked")) {
                                                        deliveryAddressRef.val(e.target.value).attr('readonly', true);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                defaultValue={agent.zipcode || ""}
                                                type="number"
                                                className="form-control"
                                                placeholder={"เลขไปรษณีย์"}
                                                name={"zipcode"}
                                                required={default_required}
                                                onChange={(e) => {
                                                    let deliveryZipcodeRef = window.$('input[name="deliveryZipcode"]');
                                                    if (this.$checkboxHaveLink.prop("checked")) {
                                                        deliveryZipcodeRef.val(e.target.value).attr('readonly', true);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <hr />

                                        <div className={"mb-3"} style={{ display: 'none' }}>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox"
                                                    ref={(ref) => { this.$checkboxHaveLink = window.$(ref) }}
                                                    className="custom-control-input"
                                                    id="addressSame"
                                                    name={"addressSame"}
                                                    defaultValue={"on"}
                                                    defaultChecked={false}
                                                    onChange={() => {
                                                        let addressRef = window.$('textarea[name="address"]');
                                                        let zipcodeRef = window.$('input[name="zipcode"]');
                                                        let deliveryAddressRef = window.$('textarea[name="deliveryAddress"]');
                                                        let deliveryZipcodeRef = window.$('input[name="deliveryZipcode"]');
                                                        if (this.$checkboxHaveLink.prop("checked")) {
                                                            deliveryAddressRef.val(addressRef.val()).attr('readonly', true);
                                                            deliveryZipcodeRef.val(zipcodeRef.val()).attr('readonly', true);
                                                            // window.$('#deliveryAddress').show()
                                                        } else {
                                                            deliveryAddressRef.val("").attr('readonly', false);
                                                            deliveryZipcodeRef.val("").attr('readonly', false);
                                                            // window.$('#deliveryAddress').hide()
                                                        }
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor="addressSame">เหมือนที่อยู่ตามบัตรประชาชน</label>
                                            </div>
                                        </div>
                                        <label>ข้อมูลที่อยู่จัดส่งเอกสาร</label>
                                        <div id="deliveryAddress">
                                            <div className="mb-3">
                                                <textarea
                                                    defaultValue={agent.deliveryAddress || ""}
                                                    className={"form-control"}
                                                    placeholder={"ที่อยู่จัดส่งเอกสาร"}
                                                    name="deliveryAddress"
                                                    required={default_required}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <input
                                                    defaultValue={agent.deliveryZipcode || ""}
                                                    type="number"
                                                    className="form-control"
                                                    placeholder={"เลขไปรษณีย์"}
                                                    name={"deliveryZipcode"}
                                                    required={default_required}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }


                        </div>
                        <div className="modal-footer">
                            <button type={"button"} className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >ยกเลิก</button>
                            <button type={"submit"} className="btn btn-primary">บันทึก</button>
                        </div>
                    </div>
                </form >
            </div >
        )
    }
}