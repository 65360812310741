import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../../Helper';
import DatePicker from 'react-datepicker';
import CustomDateInput from '../../Components/CustomDateInput';
import { db } from '../../firebase';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { orderBy } from '@progress/kendo-data-query';
import BankCode from '../../bankCode';
import '@progress/kendo-theme-default/dist/all.css';
import moment from 'moment'
const axios = require('axios').default;
export default class PriceSummaryReport extends React.Component {
    constructor() {
        super();
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        this.state = {
            years: years,
            date: new Date().getTime(),
            data: [],
            is_loading: true,
            month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
            year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                data: [],
                items_response: [],
                is_loading: true,
                month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
                year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),

            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                data: [],
                items_response: [],
                is_loading: true,
                month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
                year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),

            }, () => {
                this.fetchData();
            })
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        // console.log(this.state.sortField, this.state.sortDir);
        let qs = {}
        qs.startDate = new Date(this.state.year, 0, 1);
        qs.startDate.setHours(0, 0, 1);
        let date_selected = moment(qs.startDate.getTime());
        let start_date = qs.startDate.getTime();
        let _end = date_selected.endOf('month')
        qs.endDate = new Date(this.state.year, 11, _end.format('DD'));
        qs.endDate.setHours(0, 0, 1);
        let end_date =  qs.endDate.getTime()
        qs.startDate = start_date;
        qs.endDate = end_date;
        console.log(moment(start_date).format(`DD-MM-YYYY`), moment(end_date).format(`DD-MM-YYYY`))
        // qs.onlyAdjust = true
        try {
            let res = await axios.get(`${window.api_host}/report-summary?${window.$.param(qs)}`);
            // console.log(res);
            // return;
            let _data = [];
            let count = 0;
            let provider_all = 0;
            let repeated_count = 0;
            let count_plusTime = 0;
            if (res.data.success) {
                for (const data of res.data.data) {
                    let prepare = {};
                    let summary = Helper.bookingSummary({ booking: data, payment: data.payment });
                    prepare.price = (parseFloat(data.price))
                    prepare.plustime_total_price = parseFloat(0);
                    // prepare.priceApp = (parseFloat(data.priceApp) - parseFloat(summary.tax_total_price));
                    prepare.priceApp = parseFloat(data.priceApp) <= 0 ? 0 : parseFloat(data.priceApp);
                    prepare.priceDiscount = parseFloat(data.priceDiscount) > parseFloat(data.price) ? parseFloat(data.price) : parseFloat(data.priceDiscount);
                    prepare.totalPriceProvider = parseFloat(data.priceProvider) > 0 ? parseFloat(data.priceProvider) : (350);
                    prepare.chargePrice = parseFloat(data.chargePrice) <= 0 ? 0 : parseFloat(data.chargePrice);
                    if (prepare.chargePrice < 0) {
                        prepare.chargePrice = 0;
                    }
                    prepare.priceAgentCommissionFromProvider = parseFloat(data.priceAgentCommissionFromProvider) || 0;
                    prepare.priceAgentCommissionFromApp = parseFloat(data.priceAgentCommissionFromApp) || 0;
                    prepare.priceProvider = parseFloat(data.priceProviderWithoutAgent) ? parseFloat(data.priceProviderWithoutAgent) : parseFloat(data.priceProvider) > 0 ? parseFloat(data.priceProvider) : (350);
                    prepare.totalUserPrice = ((parseFloat(data.price) + parseFloat(prepare.chargePrice)) - parseFloat(prepare.priceDiscount)).toFixed(2)
                    prepare.priceUserAfterDiscount = (parseFloat(prepare.price) - parseFloat(prepare.priceDiscount));
                    let work_time = parseFloat(data.bookTime.time);
                    let _plusTime = 0;
                    let priceProviderPlusTime = 0;
                    prepare.priceProviderPlusTime = priceProviderPlusTime;
                    prepare.plusTime = _plusTime;
                    prepare.bookTime = work_time;
                    prepare.totalPriceApp = (parseFloat(prepare.priceApp) + parseFloat(prepare.chargePrice)).toFixed(2);
                    if (data.providerUser && data.providerUser.bank) {
                        let getBank = BankCode.filter((e) => e.name.indexOf(data.providerUser.bank) != -1)[0];
                        if (getBank) {
                            data.providerUser.bankCode = getBank.code;
                        }
                    }
                    prepare.paymentMethod = ""
                    if (data.payment) {
                        if (data.payment.paymentBy == 'บัตรเครดิต') {
                            prepare.paymentMethod = "บัตรเครดิต";
                        }
                    }
                    if (data.type && data.type == 'wegate') {
                        if (data.paymentStatus == 'success') {
                            prepare.payment = {
                                statusText: 'สำเร็จ',

                            }
                        }
                        let channel = data.channel;
                        prepare.payment = { ...prepare.payment }
                        if (channel == 'PP') {
                            prepare.payment.paymentBy = "Ksher (PromptPay)";
                            prepare.paymentMethod = "Ksher (PromptPay)";
                        }
                        if (channel == 'TM') {
                            prepare.payment.paymentBy = "Ksher (True Money)";
                            prepare.paymentMethod = "Ksher (True Money)";
                        }
                        if (channel == 'AL') {
                            prepare.payment.paymentBy = "Ksher (Alipay)";
                            prepare.paymentMethod = "Ksher (Alipay)";
                        }
                        if (channel == 'SP') {
                            prepare.payment.paymentBy = "Ksher (ShopeePay)";
                            prepare.paymentMethod = "Ksher (ShopeePay)";
                        }
                        if (channel == 'LP') {
                            prepare.payment.paymentBy = "Ksher (LINE Pay)";
                            prepare.paymentMethod = "Ksher (LINE Pay)";
                        }
                        if (channel == 'WC') {
                            prepare.payment.paymentBy = "Ksher (WeChat Pay)";
                            prepare.paymentMethod = "Ksher (WeChat Pay)";
                        }
                    }
                    prepare.bookPaymentInfo = "แอพพลิเคชั่น";
                    prepare.totalUserPrice = parseFloat(prepare.totalUserPrice)
                    prepare.totalPriceApp = parseFloat(prepare.totalPriceApp)
                    if (!data.user_data) {
                        prepare.user_data = {
                            displayName: data.user.displayName,
                            phoneNumber: data.user.guestPhoneNumber,
                        }
                    }
                    if (data.createdAdmin) {
                        prepare.paymentMethod = "โอนเงิน";
                        prepare.bookPaymentInfo = "ระบบหลังบ้าน";
                        prepare.payment = {
                            statusText: 'สำเร็จ',
                            paymentBy: 'โอนเงิน'
                        }
                    }
                    prepare.bookAt = Helper.getDateNumber(data.createdAt);
                    prepare.deductions = 0;
                    prepare.specialPrice = 0;
                    prepare.adjustNote = '';
                    if (data.adjustSalary && data.adjustSalary.length > 0) {
                        let i = 0
                        for (const adj of data.adjustSalary) {
                            if (adj.reason) {
                                prepare.adjustNote += `${i > 0 ? ',' : ''}${adj.reason}`
                            }
                            if (adj.type == 'withhold_wallet') {
                                prepare.deductions += parseFloat(adj.amount)
                            } else {
                                prepare.specialPrice += parseFloat(adj.amount)
                            }
                            i++;
                        }
                    }
                    prepare.rentOutOfAppPrice = 0;
                    prepare.transferPrice = (parseFloat(prepare.totalPriceProvider) - parseFloat(prepare.deductions)) + parseFloat(prepare.specialPrice);
                    let count_bookNo = _data.filter((e) => { return e.bookOn == data.bookOn });
                    // console.log("count_bookNo",count_bookNo)
                    if (count_bookNo.length > 0) {
                        repeated_count++;
                        // console.log("ซ้ำ")
                        // console.log(count_bookNo)
                        // console.log(data)
                        // console.log("<----------------------------->")
                    }
                    _data.push({
                        ...data,
                        ...prepare
                    })
                    if (data.plusTime && data.plusTime.length > 0) {
                        let plus_time_no = 1;
                        for (const plusTime of data.plusTime) {
                            let _status = ``
                            if (plusTime.status == 'success') {
                                _status = `สำเร็จ`;
                            }
                            if (plusTime.status == 'wait') {
                                _status = `รอชำระเงิน`;
                            }
                            if (plusTime.status == 'cancel') {
                                _status = `ยกเลิก`;
                            }
                            if (plusTime.status == 'fail') {
                                _status = `ไม่สำเร็จ`;
                            }
                            prepare.bookPaymentInfo = `เพิ่มชั่วโมงครั้งที่ ${plus_time_no} (${_status})`;
                            count_plusTime += 1;
                            let plustimePrepare = { ...prepare };
                            plustimePrepare.totalPriceProvider = plusTime.priceProvider;
                            if (this.state.type === 'provider') {
                                plustimePrepare.priceProvider = plusTime.priceProvider;
                            } else {
                                plustimePrepare.priceProviderPlusTime = plusTime.priceProvider;
                            }
                            plustimePrepare.bookTime = plusTime.time;
                            plustimePrepare.price = parseFloat(plusTime.priceTotal);
                            plustimePrepare.plustime_total_price = parseFloat(0);
                            plustimePrepare.priceApp = (parseFloat(plusTime.priceApp));
                            plustimePrepare.priceDiscount = 0;
                            plustimePrepare.totalPriceProvider = parseFloat(plusTime.priceProvider) > 0 ? parseFloat(plusTime.priceProvider) : (350);
                            plustimePrepare.chargePrice = parseFloat(plusTime.vat);
                            plustimePrepare.totalUserPrice = ((parseFloat(plusTime.priceTotal) - parseFloat(plustimePrepare.priceDiscount)) + parseFloat(plustimePrepare.chargePrice)).toFixed(2)
                            plustimePrepare.totalUserPrice = parseFloat(plustimePrepare.totalUserPrice)
                            plustimePrepare.priceUserAfterDiscount = (parseFloat(plustimePrepare.price) - parseFloat(plustimePrepare.priceDiscount));
                            _plusTime = 0;

                            plustimePrepare.payment = {
                                statusText: _status,
                                paymentBy: 'บัตรเครดิต',
                            }

                            plustimePrepare.totalPriceApp = (parseFloat(plusTime.priceApp) + parseFloat(plusTime.vat)).toFixed(2);
                            plustimePrepare.totalPriceApp = parseFloat(plustimePrepare.totalPriceApp);
                            plustimePrepare.bookOn = data.bookOn;
                            if (plusTime.paymentBy && plusTime.paymentBy == "omise" && plusTime.omise) {
                                plustimePrepare.payment.card = plusTime.omise.card;
                            }
                            if (plusTime.paymentBy && plusTime.paymentBy == "banktransfer") {
                                plustimePrepare.paymentMethod = "โอนเงิน";
                                plustimePrepare.payment.paymentBy = 'โอนเงิน';
                            }
                            plustimePrepare.createdAt = plusTime.updatedAt;
                            // plustimePrepare.time = "";
                            plustimePrepare.deductions = 0;
                            plustimePrepare.specialPrice = 0;
                            plustimePrepare.isPlustime = true;
                            _data.push({
                                ...data,
                                ...plustimePrepare
                            })
                            plus_time_no++;
                            // }
                        }
                    }

                    // console.log(_data)

                }
                count = res.data.count_all;
                provider_all = res.data.provider_all;
            }
            // console.log("_data", _data)
            let _response = [];
            _data.map((data) => {
                let prepare = {}
                prepare.bookStatusth = data.bookStatusth;
                prepare.bookedAt = data.bookedAt;
                prepare.createdAt = data.createdAt;
                prepare.bookOn = data.bookOn;
                prepare.userDisplayName = data.user && data.user.displayName ? data.user.displayName : null;
                prepare.userPhoneNumber = data.user && data.user.phoneNumber ? data.user.phoneNumber.replace('+66', '0') : null;
                if (!prepare.userPhoneNumber) {
                    prepare.userPhoneNumber = data.user && data.user.guestPhoneNumber ? data.user.guestPhoneNumber.replace('+66', '0') : null;
                }
                prepare.providerDisplayname = data.providerUser && data.providerUser.displayName ? data.providerUser.displayName : null;
                prepare.providerPhoneNumber = data.providerUser && data.providerUser.phoneNumber ? data.providerUser.phoneNumber.replace('+66', '0') : null;
                prepare.time = Helper.getTime(data.bookedAt);

                prepare.bookTime = data.bookTime;
                prepare.address = data.address.address;
                prepare.work_detail = data.address.placeType.name_th + ' ' + data.address.placeType.place_sub_type.description_th;

                prepare.price = parseFloat(data.price) || 0;
                prepare.priceAppPromotion = parseFloat(data.priceAppPromotion) || 0;
                prepare.priceDiscount = parseFloat(data.priceDiscount) || 0;
                prepare.priceUserAfterDiscount = parseFloat(data.priceUserAfterDiscount) || 0;
                prepare.chargePrice = parseFloat(data.chargePrice) || 0;
                prepare.totalUserPrice = parseFloat(data.totalUserPrice) || 0;
                prepare.priceApp = parseFloat(data.priceApp) || 0;
                prepare.totalPriceApp = parseFloat(data.totalPriceApp) || 0;
                prepare.totalPriceProvider = parseFloat(data.totalPriceProvider) || 0;
                prepare.priceAgentCommissionFromApp = parseFloat(data.priceAgentCommissionFromApp) || 0;
                prepare.priceAgentCommissionFromProvider = parseFloat(data.priceAgentCommissionFromProvider) || 0;
                prepare.priceAppWithoutAgent = parseFloat(data.priceAppWithoutAgent) || 0;
                prepare.priceProviderWithoutAgent = parseFloat(data.priceProviderWithoutAgent) || 0;
                prepare.bookBankNo = data.providerUser && data.providerUser.bankAccountNo ? data.providerUser.bankAccountNo : '';
                prepare.bookBankName = data.providerUser && data.providerUser.bankAccountName ? data.providerUser.bankAccountName : '';
                prepare.bank = data.providerUser && data.providerUser.bank ? data.providerUser.bank : '';
                prepare.paymentMethod = data.paymentMethod;
                prepare.bookPaymentInfo = data.bookPaymentInfo;

                // let summary = Helper.bookingSummary({ booking: data, payment: data.payment });
                // // console.log(summary)
                // prepare.price = parseFloat(summary.plustime_total_price);
                // prepare.priceApp = (parseFloat(summary.plustime_app_price) - parseFloat(summary.tax_total_price));
                // prepare.priceDiscount = parseFloat(summary.total_discount);
                // prepare.totalPriceProvider = parseFloat(summary.plustime_provider_price);
                // prepare.chargePrice = parseFloat(summary.tax_total_price);
                // prepare.totalPriceApp = (parseFloat(prepare.priceApp) + parseFloat(prepare.chargePrice));
                // prepare.totalUserPrice = ((parseFloat(prepare.price) - parseFloat(prepare.priceDiscount)) + parseFloat(prepare.chargePrice))
                // prepare.priceUserAfterDiscount = (parseFloat(prepare.price) - parseFloat(prepare.priceDiscount));

                prepare.rentOutOfAppPrice = 0;
                prepare.deductions = parseFloat(data.deductions);
                prepare.specialPrice = parseFloat(data.specialPrice);

                prepare.transferPrice = (parseFloat(prepare.totalPriceProvider) - parseFloat(prepare.deductions)) + parseFloat(prepare.specialPrice);
                prepare.transferStatus = '';
                prepare.slip = '';
                prepare.rentType = '';
                if (data.promotion) {
                    if (data.promotion.promotionType == "event") {
                        prepare.rentType = "หน้าแอป"
                    }
                    if (data.promotion.promotionType == "unique") {
                        prepare.rentType = "การตลาด"
                    }
                    if (data.promotion.promotionType == "package") {
                        prepare.rentType = "แพ็กเกจ"
                    }
                    if (data.promotion.promotionType == "agent") {
                        prepare.rentType = "ตัวแทน"
                    }
                    // prepare.rentType = data.promotion.promotionType
                }
                data.note = '';
                // prepare.note = data.note || "";
                // if(data.createdAdmin) {
                //     if(prepare.note) {
                //         prepare.note += ' / '
                //     }
                //     prepare.note += 'สร้างโดยระบบหลังบ้าน' 
                // } 

                if (this.state.booking_type === 'admin_created') {
                    prepare.bookStatus = data.bookStatus;
                    prepare.bookStatusth = data.bookStatusth;
                }
                if (prepare.bank) {
                    let getBank = BankCode.filter((e) => e.name.indexOf(data.providerUser.bank) != -1)[0];
                    if (getBank) {
                        prepare.bankCode = getBank.code;
                    }
                }
                _response.push(prepare)
                return true;
            })
            let monthDetail = monthData;
            console.log("_response", _response.length)
            for (let index = 0; index < monthDetail.length; index++) {
                // console.log(monthDetail[index].monthName)
                let getData = _response.filter((e) => {
                    let date = new Date(parseFloat(e.bookedAt));
                    let month = parseFloat(date.getMonth());
                    return month == index
                })

                // console.log('getData', getData)
                monthDetail[index].count = 0;
                for (const doc of getData) {
                    if (!doc.isPlustime) {
                        monthDetail[index].count++
                    }
                    let price = 0;
                    let chargePrice = 0;
                    let priceProvider = 0;
                    let priceApp = 0;
                    let pricePromotion = 0;
                    let priceDiscount = 0;
                    let netAppPrice = 0;
                    price = parseFloat(doc.price);
                    if (price > 0) {
                        monthDetail[index].price += price
                    }
                    chargePrice = parseFloat(doc.chargePrice);
                    if (chargePrice > 0) {
                        monthDetail[index].chargePrice += chargePrice
                    }
                    priceProvider = parseFloat(doc.totalPriceProvider);
                    if (priceProvider > 0) {
                        monthDetail[index].priceProvider += priceProvider
                    }
                    priceApp = parseFloat(doc.priceApp);
                    if (priceApp > 0) {
                        monthDetail[index].priceApp += priceApp
                    }
                    pricePromotion = parseFloat(doc.priceAppPromotion);
                    if (pricePromotion > 0) {
                        monthDetail[index].pricePromotion += pricePromotion
                    }
                    priceDiscount = parseFloat(doc.priceDiscount);
                    if (priceDiscount > 0) {
                        monthDetail[index].priceDiscount += priceDiscount
                    }
                    netAppPrice = parseFloat(doc.totalPriceApp);
                    if (netAppPrice > 0) {
                        monthDetail[index].netAppPrice += netAppPrice
                    }
                }
                monthDetail[index].price = Math.ceil(monthDetail[index].price)
                monthDetail[index].chargePrice = Math.ceil(monthDetail[index].chargePrice)
                monthDetail[index].priceProvider = Math.ceil(monthDetail[index].priceProvider)
                monthDetail[index].priceApp = Math.ceil(monthDetail[index].priceApp)
                monthDetail[index].pricePromotion = Math.ceil(monthDetail[index].pricePromotion)
                monthDetail[index].priceDiscount = Math.ceil(monthDetail[index].priceDiscount)
                monthDetail[index].netAppPrice = Math.ceil(monthDetail[index].netAppPrice)
                // console.log(monthDetail[index])
            }
            monthDetail = monthDetail.filter((e) => e.count != 0)
            this.setState({
                data: monthDetail,
                items_response: _response,
                is_loading: false,
                all_items: count,
                provider_all: provider_all,
                logId: res.data.logId,
            }, () => {
                if (repeated_count > 0) {
                    window.$(document).Toasts('create', {
                        title: 'เกิดข้อผิดพลาด',
                        body: "มีข้อมูลซ้ำกรุณาตรวจสอบอีกครั้ง",
                        icon: 'fas fa-exclamation',
                        autohide: true,
                        delay: 3000,
                        class: "bg-warning",
                    })
                }
            })
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: "ไม่สามารถดึงข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.setState({
                data: [],
                is_loading: false,
                all_items: 0,
                provider_all: 0,
            })
        }

    }

    render() {
        let page_title = "รายงานรายได้"
        let fileName = 'รายงานรายได้';
        let page_sub_title = "";
        page_sub_title += ' ปี ' + this.state.year

        fileName += ' ' + ' ปี ' + this.state.year
        // let _response = this.state.items_response;
        // console.log(_response);
        // console.log(this.state.data);
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">{page_title}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/provider-users">แม่บ้าน</Link></li>
                                    <li className="breadcrumb-item active">
                                        {page_title}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " >
                                <h3 className="card-title" style={{ display: 'flex', alignItems: 'center' }}>รายการ Booking  {page_sub_title}</h3>
                            </div>

                            <div className="card-body">
                                <div className={"flex row"}
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <form
                                        className="row flex"
                                        ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                ...data
                                            })
                                            navigate(url)
                                        }}
                                    >
                                        {/* <select className={"form-control form-control-sm"}
                                            name={"month"}
                                            style={{ flex: 1 }}
                                            onChange={(e) => {
                                                this.setState({
                                                    month: e.target.value,
                                                })
                                            }}
                                            defaultValue={this.state.month}
                                        >
                                            {
                                                window.months_th.map((month, month_i) => (
                                                    <option value={month_i} key={month_i}>{month}</option>
                                                ))
                                            }
                                        </select> */}
                                        <select className={"form-control form-control-sm"}

                                            style={{ flex: 1 }}
                                            name={"year"}
                                            onChange={(e) => {
                                                this.setState({
                                                    year: e.target.value,
                                                })
                                            }}
                                            defaultValue={this.state.year}
                                        >
                                            {
                                                this.state.years.map((years) => (
                                                    <option value={years} key={years}>{years}</option>
                                                ))
                                            }
                                        </select>
                                        <div className="input-group-append" style={{ marginLeft: 5 }}>
                                            <input type="hidden" name="booking_type" value={this.state.booking_type} />
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i> ค้นหา
                                            </button>
                                        </div>
                                    </form>
                                    <p style={{ marginLeft: 10, marginBottom: 0, display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>อ้างอิงจาก{this.state.sortField == 'createdAt' ? 'วันจอง' : 'วันให้บริการ'}</p>
                                    {
                                        !this.state.is_loading ?
                                            <div style={{ marginLeft: 'auto' }}>
                                                <button
                                                    title="Export Excel"
                                                    className="btn btn-default"
                                                    onClick={async () => {
                                                        this._export.save()
                                                    }}
                                                >
                                                    โหลดรายงาน
                                                </button>
                                            </div>
                                            : null
                                    }
                                </div>


                                {
                                    this.state.is_loading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                {
                                    !this.state.is_loading
                                        && this.state.type !== 'all' ?
                                        <ExcelExport
                                            fileName={fileName}
                                            data={this.state.data}
                                            ref={exporter => this._export = exporter}
                                        >
                                            <Grid data={this.state.data} style={{ height: '650px' }}>
                                                <GridColumn field="id" title="เดือน" width="50" />
                                                <GridColumn
                                                    field="monthName"
                                                    title="เดือน"
                                                    width="100"
                                                />
                                                <GridColumn field="count" title="จำนวน" width="150" />
                                                <GridColumn field="price" width="150" />
                                                <GridColumn field="chargePrice" width="150" />
                                                <GridColumn field="priceProvider" width="150" />
                                                <GridColumn field="priceApp" width="150" />
                                                <GridColumn field="pricePromotion" width="150" />
                                                <GridColumn field="priceDiscount" width="150" />
                                                <GridColumn field="netAppPrice" width="150" />
                                            </Grid>
                                        </ExcelExport>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const monthData = [
    {
        id: 1,
        monthName: 'มกราคม',
        price: 0,
        chargePrice: 0,
        priceProvider: 0,
        priceApp: 0,
        pricePromotion: 0,
        priceDiscount: 0,
        netAppPrice: 0,
    },
    {
        id: 2,
        monthName: 'กุมภาพันธ์',
        price: 0,
        chargePrice: 0,
        priceProvider: 0,
        priceApp: 0,
        pricePromotion: 0,
        priceDiscount: 0,
        netAppPrice: 0,
    },
    {
        id: 3,
        monthName: 'มีนาคม',
        price: 0,
        chargePrice: 0,
        priceProvider: 0,
        priceApp: 0,
        pricePromotion: 0,
        priceDiscount: 0,
        netAppPrice: 0,
    },
    {
        id: 4,
        monthName: 'เมษายน',
        price: 0,
        chargePrice: 0,
        priceProvider: 0,
        priceApp: 0,
        pricePromotion: 0,
        priceDiscount: 0,
        netAppPrice: 0,
    },
    {
        id: 5,
        monthName: 'พฤษภาคม',
        price: 0,
        chargePrice: 0,
        priceProvider: 0,
        priceApp: 0,
        pricePromotion: 0,
        priceDiscount: 0,
        netAppPrice: 0,
    },
    {
        id: 6,
        monthName: 'มิถุนายน',
        price: 0,
        chargePrice: 0,
        priceProvider: 0,
        priceApp: 0,
        pricePromotion: 0,
        priceDiscount: 0,
        netAppPrice: 0,
    },
    {
        id: 7,
        monthName: 'กรกฎาคม',
        price: 0,
        chargePrice: 0,
        priceProvider: 0,
        priceApp: 0,
        pricePromotion: 0,
        priceDiscount: 0,
        netAppPrice: 0,
    },
    {
        id: 8,
        monthName: 'สิงหาคม',
        price: 0,
        chargePrice: 0,
        priceProvider: 0,
        priceApp: 0,
        pricePromotion: 0,
        priceDiscount: 0,
        netAppPrice: 0,
    },
    {
        id: 9,
        monthName: 'กันยายน',
        price: 0,
        chargePrice: 0,
        priceProvider: 0,
        priceApp: 0,
        pricePromotion: 0,
        priceDiscount: 0,
        netAppPrice: 0,
    },
    {
        id: 10,
        monthName: 'ตุลาคม',
        price: 0,
        chargePrice: 0,
        priceProvider: 0,
        priceApp: 0,
        pricePromotion: 0,
        priceDiscount: 0,
        netAppPrice: 0,
    },
    {
        id: 11,
        monthName: 'พฤศจิกายน',
        price: 0,
        chargePrice: 0,
        priceProvider: 0,
        priceApp: 0,
        pricePromotion: 0,
        priceDiscount: 0,
        netAppPrice: 0,
    },
    {
        id: 12,
        monthName: 'ธันวาคม',
        price: 0,
        chargePrice: 0,
        priceProvider: 0,
        priceApp: 0,
        pricePromotion: 0,
        priceDiscount: 0,
        netAppPrice: 0,
    },
]