import React from 'react';
import { Helper } from './../Helper';
import { db, storage } from './../firebase';
const axios = require('axios').default;

export default class AddProviderSalaryImage extends React.Component {
    constructor(props) {
        super(props);
        let image_paths = [];
        if (props.image_path && props.image_paths.length > 0) {
            image_paths = [...props.image_paths];
        }
        this.state = {
            file_stack: [],
            remove_image: [],
            image_paths: image_paths,
            uploaded_images: [],
        }
    }
    componentDidMount() {
        this.$modal.modal('show');
    }

    readURL(input) {
        if (input.files && input.files.length > 0) {
            let prepare_file_stack = [...this.state.file_stack];
            for (var key of Object.keys(input.files)) {
                prepare_file_stack.push(input.files[key]);
            }
            let file_stack = [];
            file_stack = [...prepare_file_stack]
            // file_stack.push(prepare_file_stack[prepare_file_stack.length - 1]);
            this.setState({
                file_stack: file_stack
            }, () => {
                this.$file.val('');
            })
        }
    }

    async uploadFile(upload_index, data, options) {
        let file = this.state.file_stack[upload_index];
        // console.log(this.state.file_stack)
        // console.log(file)
        const filename = Helper.setNewImageFileName(file);
        let reszieFile = await Helper.resizeImageFn(file, { maxWidth: 600, maxHeight: 600 });
        var uploadTask = storage.ref().child('salary/' + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // console.log(snapshot);
        }, async (error) => {
            // console.log(error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            for (const uploaded_image of this.state.uploaded_images) {
                await storage.ref().child(uploaded_image.storagePath).delete();
            }
            this.clearButton();
            this.setState({
                is_loading: false,
                file_stack: [],
                remove_image: [],
                image_paths: [],
                uploaded_images: [],
                data: false,
            })
            return;
        }, async () => {
            let url = 'salary/' + filename;
            const photoURL = await storage.ref(url).getDownloadURL();
            let uploaded_images = [...this.state.uploaded_images];
            uploaded_images.push({
                imageURL: photoURL,
                storagePath: url,
            })
            this.setState({
                uploaded_images: uploaded_images,
            }, async () => {
                upload_index++;
                if (upload_index >= this.state.file_stack.length) {
                    if (this.props.mode === 'create') {
                        this.create(data);
                    }
                    if (this.props.mode === 'edit') {
                        this.update(data);
                    }
                    return;
                }
                if (upload_index < this.state.file_stack.length) {
                    this.uploadFile(upload_index, data);
                }
            })
        });
    }

    async create(_data) {
        let data = {};
        data.images = []
        if (this.state.uploaded_images.length > 0) {
            for (const image of this.state.uploaded_images) {
                data.images.push({
                    imageURL: image.imageURL,
                    storagePath: image.storagePath,
                })
            }
        }
        if (this.props.adminUid) {
            data.updateProviderSalaryAdminUid = this.props.adminUid;
            let admin = await db.collection('admin').doc(this.props.adminUid).get();
            if (admin.exists) {
                data.updateProviderSalaryAdmin = admin.data();
            }
        }
        data.updatedAt = new Date().getTime();
        // get walletUid
        let walletUid = _data.walletUid;
        //do update wallet
        let getWalletRef = await db.collection('provider_users').doc(this.props.providerUid).collection('wallets').where('bookUid', '==', this.props.bookingUid).get();
        if (!getWalletRef.empty) {
            let walletDepositUid = getWalletRef.docs[0].id;
            await db.collection('provider_users').doc(this.props.providerUid).collection('wallets').doc(walletDepositUid).update({ ...data });
        }
        await db.collection('provider_users').doc(this.props.providerUid).collection('wallets').doc(walletUid).update({ ...data });
        //get current wallet
        let wallet = await db.collection('provider_users').doc(this.props.providerUid).collection('wallets').doc(walletUid).get();
        //get transection
        let transectionUid = wallet.data().transactionUid;
        let transection = await db.collection('transactions').doc(transectionUid).get();
        //get salary
        let salaryUid = transection.data().salaryUid;
        let booking_data = {};
        booking_data.providerSalary = {
            walletUid: walletUid,
            ...data
        };
        if (salaryUid) {
            let salary = await db.collection('salary').doc(salaryUid).get();
            let salaryProvider = await db.collection('salary').doc(salaryUid).collection('provider').doc(this.props.providerUid).get();
            booking_data.providerSalary = {
                salaryUid: salary.id,
                ...salary.data(),
                ...booking_data.providerSalary,
                ...salaryProvider.data()
            }
        }
        //do update booking
        let res = await axios.put(window.api_host + '/booking', { id: this.props.bookingUid, update: { ...booking_data } });
        // await db.collection('booking').doc(this.props.bookingUid).update({ ...booking_data });
        if (res.error) {
            Helper.messageTop({ message: 'เพิ่มข้อมูลไม่สำเร็จ' });
            this.clearButton();
            return;
        }

        if (this.props.onSuccess) {
            this.props.onSuccess();
        }
        window.$(document).Toasts('create', {
            title: 'บันทึกการโอนเงินสำเร็จ',
            body: 'บันทึกการโอนเงินสำเร็จ กรุณากดปุ่ม "ยืนยัน" เพื่อทำการยืนยันการโอนเงิน',
            icon: 'fas fa-check',
            autohide: true,
            delay: 3000,
            class: "bg-success",
        })
        this.clearButton();
        this.$modal.modal('hide');
    }

    clearButton() {
        this.$btn.removeClass("loading");
        this.$btnCancel.attr('disabled', false);
    }

    async onSubmit() {
        if (this.$btn.hasClass('loading')) { return; }
        this.$btn.addClass("loading");
        this.$btnCancel.attr('disabled', true);
        let data = {
            providerUid: this.props.providerUid,
            bookingUid: this.props.bookingUid,
            adminUid: this.props.adminUid,
        }
        if (this.state.file_stack.length === 0) {
            window.$(document).Toasts('create', {
                title: 'บันทึกไม่สำเร็จ',
                body: 'กรุณาอัพโหลดหลักฐานการโอนเงินให้แม่บ้าน',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.clearButton();
            return;
        }
        let countWallet = await db.collection('provider_users').doc(this.props.providerUid).collection('wallets').where('bookingPaid', 'array-contains', this.props.bookingUid).get();
        if (countWallet.empty) {
            window.$(document).Toasts('create', {
                title: 'บันทึกไม่สำเร็จ',
                body: 'Book นี่ยังไม่เคยมีการโอนเงิน',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.clearButton();
            return;
        }
        data.walletUid = countWallet.docs[0].id;
        this.uploadFile(0, { ...data });
    }

    render() {
        return (
            <div ref={(ref) => {
                this.$modal = window.$(ref);
                this.$modal.modal('show');
                this.$modal.on('hidden.bs.modal', () => {
                    if (this.props.onCancel) {
                        this.props.onCancel();
                    }
                    let settime = window.setTimeout(() => {
                        this.$modal.remove();
                        clearTimeout(settime);
                    }, 500)
                });
            }}
                className="modal fade" tabIndex="-1" role="dialog"
                data-keyboard="false" data-backdrop="static"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">อัพโหลดรูปหลักฐานการจ่ายเงินให้แม่บ้าน</h4>
                        </div>
                        <div className="modal-body">

                            <div className={"form-group"}>
                                <label>รูปภาพ</label>
                                <input name="image"
                                    type={"file"}
                                    accept="image/*"
                                    ref={(ref) => this.$file = window.$(ref)}
                                    style={{ display: 'none' }}
                                    id="file_image"
                                    multiple="multiple"
                                    onChange={(e) => {
                                        if (this.$file.parent().find('.btn').hasClass('loading')) {
                                            return;
                                        }
                                        this.readURL(this.$file[0]);
                                    }}
                                />
                                <label htmlFor="file_image"
                                    type={"button"}
                                    className="btn btn-default"
                                    style={{ width: '100%', marginBottom: 0 }}
                                >
                                    อัพโหลด
                                </label>
                            </div>
                            <div>
                                <div className="row">
                                    {
                                        this.state.file_stack
                                            && this.state.file_stack.length > 0 ?
                                            this.state.file_stack.map((file_stack, file_stack_i) => {
                                                var reader = new FileReader();
                                                reader.onload = (e) => {
                                                    window.$('#images_file_upload_' + file_stack_i).html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
                                                };
                                                reader.readAsDataURL(file_stack);
                                                return (
                                                    <div className="col-6" key={file_stack_i} >
                                                        <div className="mutiple_image_frame card" style={{ height: 'auto' }}>
                                                            <div className="promotion-delete">
                                                                <button className="btn btn-danger"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        let file_stack = [...this.state.file_stack];
                                                                        file_stack.splice(file_stack_i, 1);
                                                                        this.setState({
                                                                            file_stack: file_stack
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                            <div className="card-body">
                                                                <div id={"images_file_upload_" + file_stack_i} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : null
                                    }
                                    {
                                        this.state.image_paths
                                            && this.state.image_paths.length > 0 ?
                                            this.state.image_paths.map((photoPath, photoPath_index) => {

                                                return (
                                                    <div className="col-4" key={photoPath_index} >
                                                        <div className="mutiple_image_frame card" style={{ height: 'auto' }}>
                                                            <div className="promotion-delete">
                                                                <button className="btn btn-danger"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        // console.log(photoPath_index);
                                                                        let remove_image = [...this.state.remove_image];
                                                                        let image_paths = [...this.state.image_paths];
                                                                        image_paths.splice(photoPath_index, 1);
                                                                        remove_image.push(photoPath.storagePath);
                                                                        this.setState({
                                                                            remove_image: remove_image,
                                                                            image_paths: image_paths,
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                            <div className="card-body">
                                                                <img src={photoPath.imageURL} alt={"EventAndPromotion"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                ref={ref => { this.$btnCancel = window.$(ref) }}
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={() => {
                                    if (this.props.onCancel) {
                                        this.props.onCancel();
                                    }
                                    this.$modal.modal('hide');
                                }}
                            >{this.props.cancle_text || "ยกเลิก"}</button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                ref={ref => { this.$btn = window.$(ref) }}
                                onClick={() => {
                                    this.onSubmit();
                                }}
                            >{this.props.submit_text || "เพิ่ม"}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}