import React from 'react';
import { db, storage, getProviderUserByProderviderUserDataUid } from '../firebase';
import { Link, navigate } from '@reach/router';
// import CustomDateInput from './../Components/CustomDateInput';
// import DatePicker from "react-datepicker";
import { Helper } from '../Helper';
import AddProviderSalaryImage from '../Components/AddProviderSalaryImage'
import AdjustForm from '../Components/AdjustForm'

const collectionSalary = "salary-dev";
const collectionSalaryProvider = "salary-provider-dev";
const collectionProviderUser = 'provider_users-dev';
const collectionProviderUserData = 'provider_users_data-dev';
export default class DevViewSalary extends React.Component {
    constructor(props) {
        super(props);
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        this.state = {
            user: false,
            is_loading: true,
            provider_id: props.provider_id,
            books: [],
            withdraw_wallets: [],
            deposit_wallets: [],
            adjust_wallets: [],
            select_withdraw: false,
            timerage: Helper.getParameterByName('timerage') ? Helper.getParameterByName('timerage') : 'รายเดือน',
            years: years,
            year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
            month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
            vat: 0,
        }
    }

    componentDidMount() {
        this.fetchUser();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                timerage: Helper.getParameterByName('timerage') ? Helper.getParameterByName('timerage') : 'รายเดือน',
                year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
                month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
                select_withdraw: false,
            }, () => {
                this.fetchUser();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                timerage: Helper.getParameterByName('timerage') ? Helper.getParameterByName('timerage') : 'รายเดือน',
                year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
                month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
                select_withdraw: false,
            }, () => {
                this.fetchUser();
            })
        }
    }

    async fetchUser() {
        const providerUser = await getProviderUserByProderviderUserDataUid(this.state.provider_id);
        let user = {};
        await db.doc(`${collectionProviderUserData}/` + this.state.provider_id).get().then(async (doc) => {
            user = { ...doc.data() };
        })
        let provider_user = {};
        let _user = {};
        let wallets = {};
        let withdraw_wallets = [];
        let deposit_wallets = [];
        let adjust_wallets = [];
        let urgent_wallets = [];
        // let salaryRef = db.collection('salary');
        // let salary = await salaryRef.get();
        // console.log(this.state.provider_id);
        // await Promise.all(
        //     salary.docs.map(async (doc) => {
        //         let providerRef = db.collection('salary/' + doc.id + '/provider').get();
        //         (await providerRef).docs.map((_doc) => {
        //             if (_doc.data().providerUID === this.state.provider_id) {
        //                 withdraw_wallets.push({
        //                     ..._doc.data(),
        //                     id: _doc.id,
        //                 });
        //             }
        //         })
        //     })
        // );
        if (providerUser) {
            provider_user = await db.doc(`${collectionProviderUser}/` + providerUser.id).get();
            _user = { ...user, provider_user: provider_user.data() };
            _user.birthDay = Helper.getDateToEdit(user.birthDay);
            _user.educateDate = Helper.getDateToEdit(user.educateDate);
            _user.transferDate = Helper.getDateToEdit(user.transferDate);
            wallets = db.collection(`${collectionProviderUser}/` + providerUser.id + "/wallets");
            withdraw_wallets = await wallets.where("status", '==', 'withdraw').get();
            deposit_wallets = await wallets.where("status", '==', 'deposit').get();
            adjust_wallets = await wallets.where("status", '==', 'adjust').get();
            urgent_wallets = await wallets.where("status", '==', 'urgent').get();

            withdraw_wallets = await Promise.all(
                withdraw_wallets.docs.map(async (doc) => {
                    let _doc_data = doc.data();
                    _doc_data.id = doc.id;
                    if (
                        (this.state.timerage === 'รายเดือน'
                            && new Date(_doc_data.createdAt).getMonth() === parseFloat(this.state.month)
                            && new Date(_doc_data.createdAt).getFullYear() === parseFloat(this.state.year))
                        ||
                        (this.state.timerage === 'รายปี' && new Date(_doc_data.createdAt).getFullYear() === parseFloat(this.state.year))
                    ) {
                        let return_salary_data = false;
                        let salary_data = await (await db.doc(`${collectionSalary}/` + _doc_data.salaryUid + '/provider/' + this.state.provider_id).get()).data();
                        let salary = await (await db.doc(`${collectionSalary}/` + _doc_data.salaryUid).get()).data();
                        if (salary_data) {
                            return_salary_data = salary_data;
                            let image_paths = [];
                            if (return_salary_data.slipImageURL) {
                                let slipImageType = typeof return_salary_data.slipImageURL;
                                if (slipImageType === 'object') {
                                    let i = 0;
                                    for (const image of return_salary_data.slipImageURL) {
                                        image_paths.push({
                                            imageURL: image,
                                            storagePath: return_salary_data.slipUrl[i]
                                        })
                                        i++;
                                    }
                                }
                                if (slipImageType === 'string') {
                                    image_paths.push({
                                        imageURL: return_salary_data.slipImageURL,
                                        storagePath: return_salary_data.slipUrl
                                    })
                                }
                            }
                            return_salary_data.image_paths = image_paths;
                        }
                        return {
                            ..._doc_data,
                            salary_data: return_salary_data,
                            salary: salary,
                        };
                    }
                    return null;
                })
            );
            withdraw_wallets = withdraw_wallets.filter((withdraw_wallet) => { return withdraw_wallet })
            withdraw_wallets = withdraw_wallets.sort(function (x, y) {
                if (!y.createdAt) { return 0 }
                if (!x.createdAt) { return 0 }
                return y.createdAt - x.createdAt;
            })
            let get_withdraw_ref = [];

            deposit_wallets = deposit_wallets.docs.map((doc) => {
                let _doc_data = doc.data();
                _doc_data.id = doc.id;
                let return_data = null;
                let withdraw_ref = false;
                if (_doc_data.bookUid) {
                    if (this.state.timerage === 'รายเดือน'
                        && new Date(_doc_data.createdAt).getMonth() === parseFloat(this.state.month)
                        && new Date(_doc_data.createdAt).getFullYear() === parseFloat(this.state.year)
                    ) {
                        withdraw_ref = db.collection(`${collectionProviderUser}`).doc(this.state.provider_id).collection('wallets').where('status','==','withdraw').where('bookingPaid', 'array-contains', _doc_data.bookUid).get()
                        // get_withdraw_ref.push(withdraw_ref)
                        return_data = _doc_data;
                    }
                    if (this.state.timerage === 'รายปี' && new Date(_doc_data.createdAt).getFullYear() === parseFloat(this.state.year)) {
                        withdraw_ref = db.collection(`${collectionProviderUser}`).doc(this.state.provider_id).collection('wallets').where('status','==','withdraw').where('bookingPaid', 'array-contains', _doc_data.bookUid).get()
                        // get_withdraw_ref.push(withdraw_ref)
                        return_data = _doc_data;
                    }
                    get_withdraw_ref.push(withdraw_ref)
                } else {
                    console.log("error withdraw bookNO: " + _doc_data.bookNo)
                }
                return return_data;
            })

            deposit_wallets = deposit_wallets.filter((deposit_wallet) => { return deposit_wallet })
            deposit_wallets = deposit_wallets.sort(function (x, y) {
                return y.createdAt - x.createdAt;
            })

            let deposit_wallet_i = 0;
            for (const deposit_wallet of deposit_wallets) {
                let _doc_data = deposit_wallet;

                if (_doc_data.bookUid) {
                    let withdraw_ref = await db.collection(`${collectionProviderUser}`).doc(this.state.provider_id).collection('wallets').where('status','==','withdraw').where('bookingPaid', 'array-contains', _doc_data.bookUid).get();
                    if (!withdraw_ref.empty) {
                        let withdraw_data = withdraw_ref.docs[0].data();
                        if (withdraw_data.status != 'withdraw') {
                            console.log(withdraw_data)
                        }
                        let salary = await (await db.doc(`${collectionSalary}/` + withdraw_data.salaryUid).get()).data();
                        let salary_data = await (await db.doc(`${collectionSalary}/` + withdraw_data.salaryUid + '/provider/' + this.state.provider_id).get()).data();
                        deposit_wallets[deposit_wallet_i].withdraw_wallet = {};
                        deposit_wallets[deposit_wallet_i].withdraw_wallet = withdraw_data;
                        deposit_wallets[deposit_wallet_i].withdraw_wallet.id = withdraw_ref.docs[0].id;
                        deposit_wallets[deposit_wallet_i].withdraw_wallet.salary = salary;
                        deposit_wallets[deposit_wallet_i].withdraw_wallet.salary_data = salary_data;
                    }
                    deposit_wallet_i++;
                } else {
                    console.log("error deposite bookNO: " + _doc_data.bookNo)
                }
            }

            adjust_wallets = await Promise.all(
                adjust_wallets.docs.map(async (doc) => {
                    let _doc_data = doc.data();
                    _doc_data.id = doc.id;
                    if (
                        (this.state.timerage === 'รายเดือน'
                            && new Date(_doc_data.createdAt).getMonth() === parseFloat(this.state.month)
                            && new Date(_doc_data.createdAt).getFullYear() === parseFloat(this.state.year))
                        ||
                        (this.state.timerage === 'รายปี' && new Date(_doc_data.createdAt).getFullYear() === parseFloat(this.state.year))
                    ) {
                        return {
                            ..._doc_data
                        };
                    }
                    return null;
                })
            );
            adjust_wallets = adjust_wallets.filter((withdraw_wallet) => { return withdraw_wallet })
            urgent_wallets = await Promise.all(
                urgent_wallets.docs.map(async (doc) => {
                    let _doc_data = doc.data();
                    _doc_data.id = doc.id;
                    if (
                        (this.state.timerage === 'รายเดือน'
                            && new Date(_doc_data.createdAt).getMonth() === parseFloat(this.state.month)
                            && new Date(_doc_data.createdAt).getFullYear() === parseFloat(this.state.year))
                        ||
                        (this.state.timerage === 'รายปี' && new Date(_doc_data.createdAt).getFullYear() === parseFloat(this.state.year))
                    ) {
                        return {
                            ..._doc_data
                        };
                    }
                    return null;
                })
            );
            urgent_wallets = urgent_wallets.filter((withdraw_wallet) => { return withdraw_wallet })
            console.log(`urgent_wallets`, urgent_wallets)
            adjust_wallets = adjust_wallets.concat(urgent_wallets);
            adjust_wallets = adjust_wallets.sort(function (x, y) {
                if (!y.createdAt) { return 0 }
                if (!x.createdAt) { return 0 }
                return y.createdAt - x.createdAt;
            })

            withdraw_wallets = withdraw_wallets.filter((w) => { return w !== null });
            deposit_wallets = deposit_wallets.filter((w) => { return w !== null });
            adjust_wallets = adjust_wallets.filter((w) => { return w !== null });
        }
        this.setState({
            providerUser: providerUser,
            user: _user,
            is_loading: false,
            is_deposit_loading: false,
            deposit_wallets: deposit_wallets,
            withdraw_wallets: withdraw_wallets,
            adjust_wallets: adjust_wallets,
        }, () => {
            window.$.Thailand({
                $amphoe: this.amphoe, // input ของอำเภอ
                $province: this.province, // input ของจังหวัด
                templates: {
                    empty: ' ',
                    suggestion: function (data) {
                        return '<div>' + data.amphoe + ' » ' + data.province + '</div>';
                    }
                }
            });
        })
    }

    async fetchDeposit() {
        // console.log(this.state.select_withdraw)
        let array = [];
        let get_withdraw_ref = [];
        if (this.state.select_withdraw.bookingPaid) {
            for (const bookUid of this.state.select_withdraw.bookingPaid) {
                let get = await db.collection(`${collectionProviderUser}`).doc(this.state.provider_id).collection('wallets').where('status', '==', 'deposit').where('bookUid', '==', bookUid).get();
                if (!get.empty) {
                    let withdraw_ref = db.collection(`${collectionProviderUser}`).doc(this.state.provider_id).collection('wallets').where('status','==','withdraw').where('bookingPaid', 'array-contains', get.docs[0].data().bookUid).get()
                    get_withdraw_ref.push(withdraw_ref)
                    array.push({
                        id: get.docs[0].id,
                        ...get.docs[0].data(),
                        // salary: salary,
                    })
                }
            }
            let get_withdraw_arr = await Promise.all(get_withdraw_ref);
            if (array.length > 0) {
                let deposit_wallet_i = 0;
                for (const get_withdraw of get_withdraw_arr) {

                    if (get_withdraw && !get_withdraw.empty) {
                        let salary = await (await db.doc(`${collectionSalary}/` + get_withdraw.docs[0].data().salaryUid).get()).data();
                        let salary_data = await (await db.doc(`${collectionSalary}/` + get_withdraw.docs[0].data().salaryUid + '/provider/' + this.state.provider_id).get()).data();
                        array[deposit_wallet_i].withdraw_wallet = {};
                        array[deposit_wallet_i].withdraw_wallet = get_withdraw.docs[0].data();
                        array[deposit_wallet_i].withdraw_wallet.id = get_withdraw.docs[0].id;
                        array[deposit_wallet_i].withdraw_wallet.salary = salary;
                        array[deposit_wallet_i].withdraw_wallet.salary_data = salary_data;
                    }
                    deposit_wallet_i++;
                }
            }
        }
        console.log(array)
        this.setState({
            deposit_wallets: array,
            is_deposit_loading: false,
        })
    }

    async updateStatus(status) {
        await db.doc(`${collectionProviderUserData}/` + this.state.provider_id).update({ status: status }).then(() => {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                body: 'เปลี่ยนสถานะสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.fetchUser();
        })
    }

    uploadFile(node, options) {
        let id = this.state.provider_id;
        let upload_btn = node.parent().find('.btn');
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        const filename = Helper.setNewImageFileName(file);
        var uploadTask = storage.ref().child(`${collectionProviderUserData}/` + id + '/' + filename).put(file);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log(snapshot);
        }, (error) => {
            console.log(error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            upload_btn.removeClass('loading');
        }, async () => {
            let url = `${collectionProviderUserData}/` + id + '/' + filename;
            if (options.onSuccess) {
                options.onSuccess(url);
            }

        });
    }

    async onSelectWithDraw(withdraw_wallet) {
        console.log(withdraw_wallet)
        if (this.state.is_deposit_loading) { return; }
        if (this.state.select_withdraw && this.state.select_withdraw.salaryUid === withdraw_wallet.salaryUid) {
            this.setState({
                is_deposit_loading: true,
                select_withdraw: false,
            }, () => {
                this.fetchUser();
            })
            return;
        }
        this.setState({
            is_deposit_loading: true,
            select_withdraw: withdraw_wallet
        }, () => {
            this.fetchDeposit()
        })
    }
    render() {
        // let withdraw_count = this.state.withdraw_wallets.length;
        let withdraw_wallets_total_vat = 0;
        let withdraw_wallets_total_price = 0;
        this.state.withdraw_wallets.map((withdraw_wallet) => {
            // withdraw_wallets_total_vat += parseFloat((withdraw_wallet.transferPrice * 3) / 100);
            // withdraw_wallets_total_price += parseFloat(withdraw_wallet.transferPrice);
            withdraw_wallets_total_vat += parseFloat((withdraw_wallet.price * this.state.vat) / 100);
            withdraw_wallets_total_price += parseFloat(withdraw_wallet.price);
            return true
        })
        withdraw_wallets_total_price = withdraw_wallets_total_price - withdraw_wallets_total_vat
        let deposit_total_price = 0;
        this.state.deposit_wallets.map((deposit_wallet) => {
            deposit_total_price += parseFloat(deposit_wallet.price);
            return true
        })
        let adjust_total_price = 0;
        this.state.adjust_wallets.map((adjust_wallet) => {
            if (adjust_wallet.adjustType == "withhold_wallet") {
                adjust_total_price -= parseFloat(adjust_wallet.price);
            } else {
                if (adjust_wallet.status == 'urgent') {
                    adjust_total_price += parseFloat(adjust_wallet.priceUrgentProvider);
                } else {
                    adjust_total_price += parseFloat(adjust_wallet.price);
                }
            }
            return true
        })
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายละเอียดรายได้ DEV</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/salary">จัดการรายได้</Link></li>
                                    <li className="breadcrumb-item active">รายละเอียดรายได้</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"content"}>
                    <div className="container-fluid" >
                        {
                            this.state.is_loading
                                && !this.state.user ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            this.state.user ?
                                <>
                                    <div className="card">
                                        <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div>
                                                <h3 className="card-title">
                                                    <Link to={"/provider-users/" + this.state.provider_id}>
                                                        {this.state.user.prefix} {this.state.user.firstName} {this.state.user.lastName}
                                                    </Link>
                                                </h3>
                                                <div><label style={{ marginBottom: 0, marginTop: 5 }}>รายได้ปัจจุบัน : </label> {' '}
                                                    <span style={{ color: 'green' }}>
                                                        {
                                                            this.state.user.provider_user
                                                                && this.state.user.provider_user.wallet ?
                                                                Helper.numberFormat(this.state.user.provider_user.wallet)
                                                                : 0
                                                        }
                                                    </span> บาท

                                                </div>

                                            </div>
                                            <div style={{ marginLeft: 'auto' }}>

                                                <form className="input-group input-group-sm"

                                                    ref={(ref) => { this.$searchForm = window.$(ref) }}
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        let data = Helper.getFormInputObject(this.$searchForm);
                                                        let url = Helper.getQueryStringLinkFromObject({
                                                            ...data
                                                        })
                                                        navigate(url)
                                                    }}
                                                >

                                                    <select className={"form-control form-control-sm"}
                                                        name={"timerage"}
                                                        ref={(ref) => { this.$timeRage = window.$(ref) }}
                                                        onChange={(e) => {
                                                            this.$searchForm.find('[type="submit"]').trigger('click');
                                                        }}
                                                        defaultValue={this.state.timerage}
                                                    >
                                                        <option value={"รายเดือน"}>รายเดือน</option>
                                                        <option value={"รายปี"}>รายปี</option>
                                                    </select>
                                                    {
                                                        this.state.timerage === 'รายเดือน' ?
                                                            <select className={"form-control form-control-sm"}
                                                                name={"month"}
                                                                ref={(ref) => { this.$month = window.$(ref) }}
                                                                onChange={(e) => {
                                                                    this.$searchForm.find('[type="submit"]').trigger('click');
                                                                }}
                                                                defaultValue={this.state.month}
                                                            >
                                                                {
                                                                    window.months_th.map((month, month_i) => (
                                                                        <option value={month_i} key={month_i}>{month}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            : null
                                                    }

                                                    {
                                                        this.state.timerage === 'รายปี'
                                                            || this.state.timerage === 'รายเดือน' ?
                                                            <select className={"form-control form-control-sm"}
                                                                name={"year"}
                                                                ref={(ref) => { this.$year = window.$(ref) }}
                                                                onChange={(e) => {
                                                                    this.$searchForm.find('[type="submit"]').trigger('click');
                                                                }}
                                                                defaultValue={this.state.year}
                                                            >
                                                                {
                                                                    this.state.years.map((years) => (
                                                                        <option value={years} key={years}>{years + 543}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            : null
                                                    }
                                                    <div className="input-group-append" >
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-sm-12 col-lg-4">
                                            <div className="card">
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h3 className="card-title">
                                                        รายการโอนเงิน
                                                    </h3>
                                                </div>
                                                <div className={"card-body"}>
                                                    <div className={"flex row"}>
                                                        <div><label>ยอดโอนทั้งหมด</label> : <span style={{ color: 'green' }}>{Helper.numberFormat(withdraw_wallets_total_price)}</span> บาท</div>

                                                        <div style={{ marginLeft: 'auto' }}>
                                                            {
                                                                withdraw_wallets_total_vat > 0 ?
                                                                    <><label>ยอดหัก ภาษี 3%</label> : {withdraw_wallets_total_vat}</>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>

                                                    {
                                                        this.state.is_loading ?
                                                            <div style={{ padding: '1rem', textAlign: 'center' }}>
                                                                <div className="ui active inline loader" ></div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        !this.state.is_loading && this.state.withdraw_wallets ?
                                                            this.state.withdraw_wallets.map((withdraw_wallet, withdraw_wallet_i) => {
                                                                // console.log(withdraw_wallet)
                                                                let all_images = [];
                                                                if (withdraw_wallet.salary_data && withdraw_wallet.salary_data.image_paths) {
                                                                    for (const image of withdraw_wallet.salary_data.image_paths) {
                                                                        all_images.push(image);
                                                                    }
                                                                }
                                                                if (withdraw_wallet.images) {
                                                                    for (const image of withdraw_wallet.images) {
                                                                        all_images.push(image);
                                                                    }
                                                                }
                                                                if (withdraw_wallet.bookSelected) {
                                                                    for (const book of withdraw_wallet.bookSelected) {
                                                                        if (book.images) {
                                                                            for (const image of book.images) {
                                                                                all_images.push(image);
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                return (
                                                                    <div className={this.state.select_withdraw && this.state.select_withdraw.salaryUid === withdraw_wallet.salaryUid ? "info-box bg-green with-hover" : "info-box bg-yellow with-hover"} key={withdraw_wallet_i}
                                                                    >
                                                                        <span className="info-box-icon" style={{ cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                this.onSelectWithDraw(withdraw_wallet)
                                                                            }}>
                                                                            <i className="fas fa-hand-holding-usd" style={{ fontSize: 40 }} />
                                                                        </span>

                                                                        <div className="info-box-content">
                                                                            <div className={"flex row"}>
                                                                                <div className={"col-sm-12 col-lg-5"} style={{ cursor: 'pointer' }} onClick={() => { this.onSelectWithDraw(withdraw_wallet) }}>
                                                                                    {
                                                                                        withdraw_wallet.salary
                                                                                            && withdraw_wallet.salary.title ?
                                                                                            <span className="info-box-text">{withdraw_wallet.salary.title}</span>
                                                                                            : null
                                                                                    }
                                                                                    <span className="info-box-text">{Helper.getDateThai(withdraw_wallet.salary && withdraw_wallet.salary.createdAt ? withdraw_wallet.salary.createdAt : withdraw_wallet.createdAt, { is_show_time: true })}</span>
                                                                                    <span className="info-box-text"><strong>ยอดโอน</strong> : {Helper.numberFormat(withdraw_wallet.price)}</span>
                                                                                    {
                                                                                        this.state.vat > 0 ?
                                                                                            <span className="info-box-text"><strong>ภาษี 3%</strong> : {Helper.numberFormat((withdraw_wallet.price * 3) / 100)}</span>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        withdraw_wallet.salary_data
                                                                                            && withdraw_wallet.salary_data.note ?
                                                                                            <span className="info-box-text"><strong>บันทึกเพิ่มเติม</strong> : {withdraw_wallet.salary_data.note}</span>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                                <div className={"col-sm-12 col-lg-5"} style={{ cursor: 'pointer' }} onClick={() => { this.onSelectWithDraw(withdraw_wallet) }}>
                                                                                    <span className="progress-description">
                                                                                        {
                                                                                            withdraw_wallet.providerBankName ?
                                                                                                <div>{withdraw_wallet.providerBankName}</div>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            withdraw_wallet.providerBankBranch ?
                                                                                                <div>{withdraw_wallet.providerBankBranch}</div>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            withdraw_wallet.providerBankAccountNo ?
                                                                                                <div>{withdraw_wallet.providerBankAccountNo}</div>
                                                                                                : null
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                {
                                                                                    all_images
                                                                                        && all_images.length > 0 ?
                                                                                        <div className={"col-sm-12 col-lg-2"} style={{ position: 'relative' }}>
                                                                                            <div className="promotion-delete" style={{ top: 0 }}>
                                                                                                <span className="badge badge-primary" >{all_images.length}</span>
                                                                                            </div>
                                                                                            <a href={all_images[0].imageURL} data-fancybox={'slip_' + withdraw_wallet_i}>
                                                                                                <div className="card" style={{ marginBottom: 0 }}>
                                                                                                    <div className="card-body" style={{ padding: '0.5rem' }}>
                                                                                                        <div className="transfer-frame">
                                                                                                            <img src={all_images[0].imageURL} alt="Slip" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </a>
                                                                                            {
                                                                                                all_images.map((img, img_i) => {
                                                                                                    if (img_i === 0) return null;
                                                                                                    return (
                                                                                                        <a href={img.imageURL} data-fancybox={'slip_' + withdraw_wallet_i} style={{ display: "none" }} key={img_i}>
                                                                                                            <div className="card" style={{ marginBottom: 0 }}>
                                                                                                                <div className="card-body" style={{ padding: '0.5rem' }}>
                                                                                                                    <div className="transfer-frame">
                                                                                                                        <img src={img.imageURL} alt="Slip" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </a>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            <div className='col-12'>
                                                                                <span className="info-box-text"><strong>วันที่บันทึก</strong> : {Helper.getDateThai(withdraw_wallet.createdAt, { is_show_time: true })}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  col-lg-4">
                                            <div className="card">
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h3 className="card-title">
                                                        รายได้จากการจอง
                                                    </h3>
                                                </div>
                                                <div className={"card-body"}>
                                                    <div className={"flex row"}>
                                                        <div><label>รายได้ทั้งหมด</label> : <span style={{ color: 'green' }}>{Helper.numberFormat(deposit_total_price)}</span> บาท</div>

                                                    </div>
                                                    {
                                                        this.state.is_loading ?
                                                            <div style={{ padding: '1rem', textAlign: 'center' }}>
                                                                <div className="ui active inline loader" ></div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.is_deposit_loading ?
                                                            <div className="absolute_loading">
                                                                <div className="ui active inline loader" ></div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        !this.state.is_loading && this.state.deposit_wallets ?
                                                            this.state.deposit_wallets.map((deposit_wallet, deposit_wallet_i) => {
                                                                let price = parseFloat(deposit_wallet.price)
                                                                let totalPrice = parseFloat(deposit_wallet.price);
                                                                if (deposit_wallet.priceUrgentProvider) {
                                                                    price -= parseFloat(deposit_wallet.priceUrgentProvider)
                                                                }
                                                                if (price < 0) {
                                                                    price = 0;
                                                                }
                                                                return (
                                                                    <div className="info-box bg-yellow" key={deposit_wallet_i}>
                                                                        <span className="info-box-icon">
                                                                            <i className="fas fa-wallet" style={{ fontSize: 40 }} />
                                                                        </span>

                                                                        <div className="info-box-content">
                                                                            <div className={"flex row"}>
                                                                                <div className={"col-sm-12 col-lg-10"}>
                                                                                    <span className="info-box-text">{Helper.getDateThai(deposit_wallet.createdAt, { is_show_time: true })}</span>
                                                                                    <span className="info-box-text"><strong>Booking</strong> : <Link to={"/booking-dev/" + deposit_wallet.bookUid} className="common-link">{deposit_wallet.bookNo}</Link></span>
                                                                                    {
                                                                                        deposit_wallet.withdraw_wallet
                                                                                            && deposit_wallet.withdraw_wallet.salary ?
                                                                                            <span className="info-box-text">
                                                                                                <strong>รายการโอน </strong> : <Link to={"/salary-provider/" + deposit_wallet.withdraw_wallet.salaryUid + '?page=1&name=' + deposit_wallet.withdraw_wallet.salary_data.displayName} className="common-link">
                                                                                                    {deposit_wallet.withdraw_wallet.salary.title || 'รอบบิล'}
                                                                                                </Link>
                                                                                            </span>
                                                                                            : null
                                                                                    }
                                                                                    <span className="progress-description">
                                                                                        {
                                                                                            deposit_wallet.priceUrgentProvider ?
                                                                                                <>
                                                                                                    <label style={{ margin: 0 }}>ค่าบริการ </label> : {Helper.numberFormat(price)}
                                                                                                    <br />
                                                                                                    <label style={{ margin: 0 }}>เร่งด่วน </label> : {Helper.numberFormat(deposit_wallet.priceUrgentProvider)}
                                                                                                    <br />
                                                                                                </>
                                                                                                : null
                                                                                        }
                                                                                        <label style={{ margin: 0 }}>{deposit_wallet.priceUrgentProvider ? `รวมค่าบริการ` : `ค่าบริการ`} </label> : {Helper.numberFormat(totalPrice)}
                                                                                        {
                                                                                            deposit_wallet.isPaid ?
                                                                                                <>
                                                                                                    <br />
                                                                                                    <span className="badge badge-success"><i className="fas fa-check" /> โอนแล้ว</span>
                                                                                                </>
                                                                                                : null
                                                                                        }
                                                                                    </span>
                                                                                </div>

                                                                                <div className={"col-sm-12 col-lg-2"} style={{ position: 'relative' }}>

                                                                                    {
                                                                                        deposit_wallet.images
                                                                                            && deposit_wallet.images.length > 0 ?
                                                                                            <>
                                                                                                <div className="promotion-delete" style={{ top: 0 }}>
                                                                                                    <span className="badge badge-primary" >{deposit_wallet.images.length}</span>
                                                                                                </div>
                                                                                                <a href={deposit_wallet.images[0].imageURL} data-fancybox={'slip_deposit_' + deposit_wallet_i}>
                                                                                                    <div className="card" style={{ marginBottom: 0 }}>
                                                                                                        <div className="card-body" style={{ padding: '0.5rem' }}>
                                                                                                            <div className="transfer-frame">
                                                                                                                <img src={deposit_wallet.images[0].imageURL} alt="Slip" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </a>
                                                                                                {
                                                                                                    deposit_wallet.images.map((img, img_i) => {
                                                                                                        if (img_i === 0) return null;
                                                                                                        return (
                                                                                                            <a href={img.imageURL} data-fancybox={'slip_deposit_' + deposit_wallet_i} style={{ display: "none" }} key={img_i}>
                                                                                                                <div className="card" style={{ marginBottom: 0 }}>
                                                                                                                    <div className="card-body" style={{ padding: '0.5rem' }}>
                                                                                                                        <div className="transfer-frame">
                                                                                                                            <img src={img.imageURL} alt="Slip" />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </a>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-4">
                                            <div className="card">
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h3 className="card-title">
                                                        การปรับเงิน
                                                    </h3>
                                                </div>
                                                <div className={"card-body"}>
                                                    <div className={"flex row mb-3"}>
                                                        <div><label>ยอดปรับทั้งหมด</label> : <span style={{ color: adjust_total_price > 0 ? 'green' : adjust_total_price < 0 ? 'red' : 'black' }}>{Helper.numberFormat(adjust_total_price)}</span> บาท</div>

                                                        <div style={{ marginLeft: 'auto' }}>
                                                            <button
                                                                className={withdraw_wallets_total_vat > 0 ? 'btn btn-sm btn-default mr-3' : 'btn btn-sm btn-default'}
                                                                // to={`/refund/${deposit_wallet.bookUid}`}
                                                                onClick={() => {
                                                                    Helper.reactToDom(window.$('body'),
                                                                        <AdjustForm
                                                                            // deposit_wallet={deposit_wallet}
                                                                            providerUid={this.state.provider_id}
                                                                            onSuccess={() => {
                                                                                this.fetchUser()
                                                                            }}
                                                                            admin={this.props.admin}
                                                                        />
                                                                    )
                                                                }}
                                                            >ปรับเงิน</button>

                                                        </div>
                                                    </div>

                                                    {
                                                        this.state.is_loading ?
                                                            <div style={{ padding: '1rem', textAlign: 'center' }}>
                                                                <div className="ui active inline loader" ></div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        !this.state.is_loading && this.state.adjust_wallets ?
                                                            this.state.adjust_wallets.map((adjust_wallet, adjust_wallet_i) => {
                                                                let all_images = [];
                                                                if (adjust_wallet.salary_data && adjust_wallet.salary_data.image_paths) {
                                                                    for (const image of adjust_wallet.salary_data.image_paths) {
                                                                        all_images.push(image);
                                                                    }
                                                                }
                                                                if (adjust_wallet.images) {
                                                                    for (const image of adjust_wallet.images) {
                                                                        all_images.push(image);
                                                                    }
                                                                }
                                                                if (adjust_wallet.bookSelected) {
                                                                    for (const book of adjust_wallet.bookSelected) {
                                                                        if (book.images) {
                                                                            for (const image of book.images) {
                                                                                all_images.push(image);
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                return (
                                                                    <div className={"info-box bg-yellow"}
                                                                        key={adjust_wallet_i}
                                                                    >
                                                                        <span className="info-box-icon" style={{ cursor: 'pointer' }}>
                                                                            {
                                                                                adjust_wallet.adjustType == "special_wallet" ?
                                                                                    <i className="fas fa-hand-holding-usd" style={{ fontSize: 40, color: 'green' }} />
                                                                                    :
                                                                                    adjust_wallet.status == `urgent` ?
                                                                                        <i className="fas fa-hourglass-half" style={{ fontSize: 40, color: 'green' }} />
                                                                                        :
                                                                                        <i className="fas fa-frown" style={{ fontSize: 40, color: 'red' }} />
                                                                            }
                                                                        </span>

                                                                        <div className="info-box-content">
                                                                            <div className={"flex row"}>
                                                                                <div className={"col-sm-12 col-lg-ๅ/"} style={{ cursor: 'pointer' }} >
                                                                                    {
                                                                                        adjust_wallet.reason ?
                                                                                            <span className="info-box-text">{adjust_wallet.reason}</span>
                                                                                            : null
                                                                                    }
                                                                                    <span className="info-box-text">{Helper.getDateThai(adjust_wallet.salary && adjust_wallet.salary.createdAt ? adjust_wallet.salary.createdAt : adjust_wallet.createdAt, { is_show_time: true })}</span>
                                                                                    {
                                                                                        adjust_wallet.status == 'adjust' ?
                                                                                            <span className="info-box-text"><strong>{adjust_wallet.adjustType == "special_wallet" ? "เพิ่มเงินพิเศษ" : "หักเงิน"}</strong> : {Helper.numberFormat(adjust_wallet.price)}</span>
                                                                                            :
                                                                                            <>
                                                                                                <span className="info-box-text">เร่งด่วน</span>
                                                                                                <span className="info-box-text"><strong>เพิ่มเงินพิเศษ</strong> : {Helper.numberFormat(adjust_wallet.priceUrgentProvider)}</span>
                                                                                            </>
                                                                                    }

                                                                                    {
                                                                                        this.state.vat > 0 ?
                                                                                            <span className="info-box-text"><strong>ภาษี 3%</strong> : {Helper.numberFormat((adjust_wallet.price * 3) / 100)}</span>
                                                                                            : null
                                                                                    }
                                                                                    <span className="info-box-text"><strong>Booking</strong> : <Link to={"/booking-dev/" + adjust_wallet.bookUid} className="common-link">{adjust_wallet.bookNo}</Link></span>
                                                                                    {
                                                                                        adjust_wallet.salary_data
                                                                                            && adjust_wallet.salary_data.note ?
                                                                                            <span className="info-box-text"><strong>บันทึกเพิ่มเติม</strong> : {adjust_wallet.salary_data.note}</span>
                                                                                            : null
                                                                                    }{
                                                                                        adjust_wallet.updateBy
                                                                                            && adjust_wallet.updateBy.name ?
                                                                                            <span className="info-box-text"><strong>ผู้ทำรายการ</strong> : {adjust_wallet.updateBy.name}</span>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    all_images
                                                                                        && all_images.length > 0 ?
                                                                                        <div className={"col-sm-12 col-lg-2"} style={{ position: 'relative' }}>
                                                                                            <div className="promotion-delete" style={{ top: 0 }}>
                                                                                                <span className="badge badge-primary" >{all_images.length}</span>
                                                                                            </div>
                                                                                            <a href={all_images[0].imageURL} data-fancybox={'slip_' + adjust_wallet_i}>
                                                                                                <div className="card" style={{ marginBottom: 0 }}>
                                                                                                    <div className="card-body" style={{ padding: '0.5rem' }}>
                                                                                                        <div className="transfer-frame">
                                                                                                            <img src={all_images[0].imageURL} alt="Slip" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </a>
                                                                                            {
                                                                                                all_images.map((img, img_i) => {
                                                                                                    if (img_i === 0) return null;
                                                                                                    return (
                                                                                                        <a href={img.imageURL} data-fancybox={'slip_' + adjust_wallet_i} style={{ display: "none" }} key={img_i}>
                                                                                                            <div className="card" style={{ marginBottom: 0 }}>
                                                                                                                <div className="card-body" style={{ padding: '0.5rem' }}>
                                                                                                                    <div className="transfer-frame">
                                                                                                                        <img src={img.imageURL} alt="Slip" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </a>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            {
                                                                                adjust_wallet.createdAt ?
                                                                                    <div className='col-12'>
                                                                                        <span className="info-box-text"><strong>วันที่บันทึก</strong> : {Helper.getDateThai(adjust_wallet.createdAt, { is_show_time: true })}</span>
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                </div>
            </>
        );
    }
}

// class ProviderTranningDateForm extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             training_date: props.training_date || [],
//             user: props.user || false,
//         }
//     }


//     async update(data) {
//         // data.traningUID
//         if (this.state.user.traningUID === data.traningUID) {
//             window.$(document).Toasts('create', {
//                 title: 'แก้ไขข้อมูลสำเร็จ',
//                 body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
//                 icon: 'fas fa-check',
//                 autohide: true,
//                 delay: 3000,
//                 class: "bg-success",
//             })
//             this.props.onUpdateSuccess();
//             this.$modal.modal('hide');
//             return;
//         }
//         const old_participants = await db.collection(`training_date/${this.state.user.traningUID}/participants`).get();
//         const traningDate = await db.doc(`training_date/${data.traningUID}`).get();
//         const _participants = db.collection(`training_date/${data.traningUID}/participants`);
//         const participants = await _participants.get();
//         const count_participants = participants.docs.length;
//         //    เช็คจำนวนผู้เข้าอบรม
//         if (parseFloat(traningDate.data().maxParticipants) <= parseFloat(count_participants)) {
//             window.$(document).Toasts('create', {
//                 title: 'ผู้เข้าร่วมอบรมเต็ม',
//                 body: 'ผู้เข้าร่วมอบรมในวันนี้เต็มแล้ว กรุณาเลือกวันอบรมอื่น',
//                 icon: 'fas fa-check',
//                 autohide: true,
//                 delay: 3000,
//                 class: "bg-danger",
//             })
//             this.$submit.removeClass("loading");
//             return;
//         }
//         // ลบผู้เข้าอบรมจากวันเก่า
//         old_participants.docs.map(async (doc) => {
//             if (doc.data().providerUid === this.props.provider_id) {
//                 await db.doc(`training_date/${this.state.user.traningUID}/participants/` + doc.id).delete();
//             }
//         })
//         // เพิ่มผู้เข้าอบรมในวันใหม่
//         await _participants.add({ providerUid: this.props.provider_id });
//         await db.doc("provider_users_data/" + this.props.provider_id).update({ ...data }).then(() => {
//             window.$(document).Toasts('create', {
//                 title: 'แก้ไขข้อมูลสำเร็จ',
//                 body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
//                 icon: 'fas fa-check',
//                 autohide: true,
//                 delay: 3000,
//                 class: "bg-success",
//             })
//             this.$modal.modal('hide');
//             this.props.onUpdateSuccess();
//         })
//     }

//     render() {
//         return (
//             <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
//                 ref={(ref) => {
//                     this.$modal = window.$(ref);
//                     this.$modal.modal('show');
//                     this.$modal.on('hidden.bs.modal', () => {
//                         let settime = window.setTimeout(() => {
//                             this.$modal.remove();
//                             clearTimeout(settime);
//                         }, 500)
//                     });
//                     this.$form = this.$modal.find('form');
//                     this.$submit = this.$form.find('[type="submit"]');
//                 }}
//             >
//                 <form className="modal-dialog" role="document"
//                     onSubmit={(e) => {
//                         e.preventDefault();
//                         let submit_btn = this.$submit;
//                         if (submit_btn.hasClass('loading')) { return false; }
//                         submit_btn.addClass('loading');
//                         let data = Helper.getFormInputObject(this.$form);
//                         this.update(data);
//                     }}
//                 >
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลธนาคาร</h4>
//                         </div>
//                         <div className="modal-body">
//                             <div className="form-group">
//                                 <label>เลือกวันเข้าอบรม</label>
//                                 <select className="form-control"
//                                     name={"traningUID"}
//                                     defaultValue={this.state.user.traningUID}
//                                     onChange={(e) => {
//                                         // console.log(e.target.value);
//                                     }}
//                                 >
//                                     {
//                                         this.state.training_date.map((t_date, t_date_i) => {
//                                             return (
//                                                 <option key={t_date_i} value={t_date.id}>{Helper.getDateThai(t_date.date)}</option>
//                                             )
//                                         })
//                                     }
//                                 </select>
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" type={"button"} className="btn btn-default"
//                                 onClick={() => {
//                                     this.$modal.modal('hide');
//                                 }}
//                             >ยกเลิก</button>
//                             <button type="button" type={"submit"} className="btn btn-primary">บันทึก</button>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//         )
//     }
// }

// class BankForm extends React.Component {

//     async update(data) {
//         await db.doc("provider_users_data/" + this.props.provider_id).update({ ...data }).then(() => {
//             window.$(document).Toasts('create', {
//                 title: 'แก้ไขข้อมูลสำเร็จ',
//                 body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
//                 icon: 'fas fa-check',
//                 autohide: true,
//                 delay: 3000,
//                 class: "bg-success",
//             })
//             this.$modal.modal('hide');
//             this.props.onSuccess();
//         })
//     }

//     render() {
//         return (
//             <div className="modal fade"
//                 id="edit_bank"
//                 tabIndex="-1"
//                 role="dialog"
//                 aria-labelledby="myModalLabel"
//                 ref={(ref) => {
//                     this.$modal = window.$(ref);
//                     this.$modal.modal('show');
//                     this.$modal.on('hidden.bs.modal', () => {
//                         let settime = window.setTimeout(() => {
//                             this.$modal.remove();
//                             clearTimeout(settime);
//                         }, 500)
//                     });
//                     this.$form = this.$modal.find('form');
//                     this.$submit = this.$form.find('[type="submit"]');
//                 }}
//             >
//                 <form className="modal-dialog" role="document"
//                     onSubmit={(e) => {
//                         e.preventDefault();
//                         let submit_btn = this.$submit;
//                         if (submit_btn.hasClass('loading')) { return false; }
//                         submit_btn.addClass('loading');
//                         let data = Helper.getFormInputObject(this.$form);
//                         this.update(data);
//                     }}
//                 >
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลธนาคาร</h4>
//                         </div>
//                         <div className="modal-body">
//                             <div className="form-group">
//                                 <label>ธนาคาร</label>
//                                 <select className="form-control" name={"bank"} defaultValue={this.props.user.bank || ''}>
//                                     <option value={""}>เลือกธนาคาร</option>
//                                     {
//                                         this.props.bank_list.map((bank, bank_i) => {
//                                             return (
//                                                 <option key={bank_i} value={bank}>{bank}</option>
//                                             )
//                                         })
//                                     }
//                                 </select>
//                             </div>
//                             <div className="form-group">
//                                 <label>สาขา</label>
//                                 <input name="bankBranch" defaultValue={this.props.user.bankBranch || ""} className="form-control" />
//                             </div>
//                             <div className="form-group">
//                                 <label>ชื่อบัญชี</label>
//                                 <input name="bankAccountName" defaultValue={this.props.user.bankAccountName || ""} className="form-control" />
//                             </div>
//                             <div className="form-group">
//                                 <label>เลขบัญชี</label>
//                                 <input name="bankAccountNo" defaultValue={this.props.user.bankAccountNo || ""} className="form-control" />
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" type={"button"} className="btn btn-default"
//                                 onClick={() => {
//                                     this.$modal.modal('hide');
//                                 }}
//                             >ยกเลิก</button>
//                             <button type="button" type={"submit"} className="btn btn-primary">บันทึก</button>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//         )
//     }
// }