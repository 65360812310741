import React from 'react';
import ReactDOM from 'react-dom';
import ComfirmModal from './Components/ConfirmModal';
import MessageTop from './Components/MessageTop';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import thailand from './thailand.json'

const Compress = require('compress.js');
const compress = new Compress();

export const DragHandle = sortableHandle(({ children }) => (<React.Fragment key={children.key}>{children}</React.Fragment>));

export const SortableItem = sortableElement(({ children }) => (<React.Fragment key={children.key}>{children}</React.Fragment>));

export const SortableContainer = sortableContainer(({ children }) => (<React.Fragment key={children.key}>{children}</React.Fragment>));
export const Helper = {
  sumBookedAt({ day, month, year, timeRageOfService }) {
    // console.log(day, month, year, timeRageOfService)
    var bookAt = new Date().getTime();
    var str;

    if (typeof timeRageOfService == 'number') {
      str = timeRageOfService.toString().split(".");
    } else if (typeof timeRageOfService == 'string') {
      str = timeRageOfService.split(".");
    }

    var hourMin = 0;
    if (str.length == 1) {
      hourMin = str[0];
    } else {
      hourMin = str[0] + (str[1] / 100);
    }



    if (str.length == 1) {
      bookAt = parseInt(new Date(year, month, day, str[0], 0).getTime());
    } else {
      bookAt = parseInt(new Date(year, month, day, str[0], str[1]).getTime());
    }
    // if (str.length == 1) {
    //   bookAt = parseInt(new Date(year, month, day, str[0], 0).getTime()) - (3600 * 1000 * 7);
    // } else {
    //   bookAt = parseInt(new Date(year, month, day, str[0], str[1]).getTime()) - (3600 * 1000 * 7);
    // }
    return { success: true, bookAt }
  },
  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  },
  bookingSummary(_data, options) {
    let plustime_price = 0;
    let plustime_total_price = 0;
    let plustime_total_with_tax_price = _data.booking.price;
    let plustime_app_price = 0;
    let plustime_provider_price = 0;
    let plustime_app_price_only = 0;
    let plustime_provider_only = 0;
    let plustime_price_no_tax = 0;
    let tax_price = 0;
    let hours = 0;
    let total_price = 0;
    let tax_total_price = 0;
    let total_discount = 0;
    let chargePrice = parseFloat(_data.booking.chargePrice) > 0 ? parseFloat(_data.booking.chargePrice) : 0;
    if (_data.booking && _data.payment) {
      tax_total_price = chargePrice;
      total_discount = _data.booking.priceDiscount >= _data.booking.price ? _data.booking.price : _data.booking.priceDiscount;
      total_price = ((_data.booking.price - _data.booking.priceDiscount) + chargePrice);
      if (options && options.pure_total_price) {
        total_price = _data.booking.price;
      }
      if (total_price < 0) {
        total_price = 0;
      }
      plustime_price = 0;
      plustime_total_price = total_price;
      plustime_app_price = (((_data.booking.price - _data.booking.priceDiscount) - _data.booking.priceProvider) + chargePrice);

      plustime_provider_price = _data?.booking?.priceProviderWithoutAgent || _data?.booking?.priceProvider || 0;
      if (_data.booking.changeBookPrice) {
        plustime_provider_price = _data.booking.priceProvider;
      }

      hours = 0;
      if (_data.payment
        && _data.payment.tranPlusTime
        && _data.payment.tranPlusTime.length > 0
      ) {
        _data.payment.tranPlusTime.map((_pt, _pt_i) => {

          if (_pt.status === 'success') {
            hours += _pt.time;
            let priceProvider = _pt.priceProvider || 0;
            let priceApp = _pt.priceApp || 0;
            let vat = _pt.vat || 0;
            plustime_total_price += (_pt.priceTotal + vat);
            tax_total_price += vat;
            plustime_app_price += priceApp;
            plustime_provider_price += priceProvider;
            plustime_price += (_pt.priceTotal + vat);
            plustime_price_no_tax += _pt.priceTotal;
            plustime_app_price_only += priceApp;
            plustime_provider_only += priceProvider;
            tax_price += vat;
          }
          return true;
        })
      }
      if (_data.booking
        && _data.booking.plusTime
        && _data.booking.plusTime.length > 0
      ) {
        _data.booking.plusTime.map((_pt, _pt_i) => {
          if (_pt.status === 'success') {
            hours += _pt.time;
            let priceProvider = _pt.priceProvider || 0;
            let priceApp = _pt.priceApp || 0;
            let vat = _pt.vat || 0;
            plustime_total_price += (_pt.priceTotal + vat);
            tax_total_price += vat;
            plustime_app_price += priceApp;
            plustime_provider_price += priceProvider;
            plustime_price += (_pt.priceTotal + vat);
            plustime_price_no_tax += _pt.priceTotal;
            plustime_app_price_only += priceApp;
            plustime_provider_only += priceProvider;
            tax_price += vat;
          }
          return true;
        })
      }
    }
    let totalAdjust = 0;
    // if (_data.booking && _data.booking.adjustSalary) {
    //   for (const adj of _data.booking.adjustSalary) {
    //     if (adj.type == 'special_wallet' && adj.providerUserUid == _data.booking.providerUserUid) {
    //       totalAdjust += parseFloat(adj.amount)
    //     }
    //     if (adj.type == 'withhold_wallet' && adj.providerUserUid == _data.booking.providerUserUid) {
    //       totalAdjust -= parseFloat(adj.amount)
    //     }
    //   }
    // }
    // console.log(`totalAdjust`,totalAdjust)
    plustime_total_price += totalAdjust;
    let priceUrgent = _data?.booking?.priceUrgent || 0;
    let priceUrgentVat = _data?.booking?.priceUrgentVat || 0;
    let priceUrgentApp = _data?.booking?.priceUrgentApp || 0;
    let priceUrgentProvider = _data?.booking?.priceUrgentProvider || 0;
    let priceAgentCommissionFromProvider = _data?.booking?.priceAgentCommissionFromProvider || 0;
    plustime_total_price += priceUrgent;
    plustime_total_price += priceUrgentVat;

    // plustime_provider_price += parseFloat(priceUrgentProvider);
    // plustime_provider_price += parseFloat(priceAgentCommissionFromProvider);

    plustime_app_price += parseFloat(priceUrgentApp)
    plustime_app_price += parseFloat(priceUrgentVat)

    plustime_app_price += tax_price;
    if (plustime_total_price < 0) {
      plustime_total_price = 0;
    }
    if (plustime_app_price < 0) {
      plustime_app_price = 0;
    }
    if (plustime_provider_price < 0) {
      plustime_provider_price = 0;
    }
    if (total_discount < 0) {
      total_discount = 0
    }
    return {
      plustime_price: plustime_price,
      plustime_total_price: plustime_total_price,
      plustime_app_price: plustime_app_price,
      plustime_provider_price: plustime_provider_price,
      hours: hours,
      plustime_price_no_tax: plustime_price_no_tax,
      total_price: total_price,
      tax_total_price: tax_total_price,
      total_discount: total_discount,
      plustime_total_with_tax_price: plustime_total_with_tax_price,
      plustime_app_price_only: plustime_app_price_only,
      plustime_provider_only: plustime_provider_only,
      tax_price: tax_price
    }
  },
  callProvince(str) {
    if (typeof str != "string") { return str }
    if (!isNaN(str) && !isNaN(parseFloat(str))) {
      let province = false;
      for (const [key, value] of Object.entries(thailand)) {
        if (key === str) {
          province = value.name.th;
        }
      }
      str = province
    }
    return str
  },
  callAmphoe(str) {
    if (typeof str != "string") { return str }
    if (!isNaN(str) && !isNaN(parseFloat(str))) {
      let amphoe = false;
      for (const [key, value] of Object.entries(thailand)) {
        for (const [_key, _value] of Object.entries(value.amphoes)) {
          if (_key === str) {
            amphoe = _value.name.th
          }
        }
      }
      str = amphoe
    }
    return str
  },
  providerUpdateData(data, admin, options) {
    let _data = { ...data }
    _data.updateBy = [];
    if (data.updateBy) {
      _data.updateBy = [...data.updateBy];
    }
    let prepareUpdateData = {
      adminUID: admin.id.stringValue,
      adminName: admin.displayName.stringValue,
      updateAt: new Date().getTime(),
      ...options,
      updateType: options && options.updateType ? options.updateType : 'None',
    }
    _data.updateBy.push(prepareUpdateData)
    _data.updatedAt = new Date().getTime();
    return _data;
  },
  async resizeImageFn(file, options) {
    const resizedImage = await compress.compress([file], {
      size: 20, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth: options && options.maxWidth ? options.maxWidth : 500, // the max width of the output image, defaults to 1920px
      maxHeight: options && options.maxHeight ? options.maxHeight : 500, // the max height of the output image, defaults to 1920px
      resize: true // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0];
    // return img;
    const base64str = img.data
    const imgExt = img.ext
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
    return resizedFiile;
  },
  renderDate(options) {
    let days = window.$('.react-datepicker__day-names').find(".react-datepicker__day-name");
    let thai_days = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์'];
    if (options && options.short_day) {
      thai_days = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'];
    }
    for (let index = 0; index <= days.length; index++) {
      window.$(days[index]).text(thai_days[index])
    }
  },
  timeDifference(date1, date2) {
    // var difference = date1.getTime() - date2.getTime();
    var difference = date1 - date2;

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60

    var secondsDifference = Math.floor(difference / 1000);
    let data = {};
    data.day = daysDifference;
    data.hour = hoursDifference;
    data.minute = minutesDifference;
    data.second = secondsDifference;
    return data;
  },
  getDateThai(_date, options) {
    let date = new Date(_date);
    let time = '';
    if (options && options.plus_hours) {
      date.setTime(date.getTime() + (options.plus_hours * 60 * 60 * 1000));
    }
    if (options && options.minus_hours) {
      date.setTime(date.getTime() - (options.minus_hours * 60 * 60 * 1000));
    }
    if (options && options.is_show_time) {
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hour < 10) {
        hour = '0' + date.getHours()
      }
      if (minute < 10) {
        minute = '0' + date.getMinutes()
      }
      if (second < 10) {
        second = '0' + date.getSeconds()
      }
      // time = ' - ' + hour + ':' + minute + ':' + second;
      time = ' - ' + hour + ':' + minute;
    }
    if (isNaN(date.getDate()) || isNaN(date.getMonth()) || isNaN(date.getFullYear())) {
      return "";
    }
    return date.getDate() + ' ' + window.months_th[parseFloat(date.getMonth())] + ' ' + (date.getFullYear() + 543) + time;
  },
  getDateNumber(_date, options) {
    let date = new Date(_date);
    let time = '';
    if (options && options.plus_hours) {
      date.setTime(date.getTime() + (options.plus_hours * 60 * 60 * 1000));
    }
    if (options && options.minus_hours) {
      date.setTime(date.getTime() - (options.minus_hours * 60 * 60 * 1000));
    }
    let day = date.getDate();
    let month = parseFloat(date.getMonth()) + 1;
    if (options && options.is_show_time) {
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (parseFloat(hour) < 10) {
        hour = '0' + date.getHours()
      }
      if (parseFloat(minute) < 10) {
        minute = '0' + date.getMinutes()
      }
      if (parseFloat(second) < 10) {
        second = '0' + date.getSeconds()
      }
      // time = ' - ' + hour + ':' + minute + ':' + second;
      time = ' - ' + hour + ':' + minute;
    }
    if (isNaN(date.getDate()) || isNaN(date.getMonth()) || isNaN(date.getFullYear())) {
      return "";
    }

    if (month < 10) {
      month = '0' + month
    }
    if (parseFloat(day) < 10) {
      day = '0' + day
    }
    return day + '/' + month + '/' + (date.getFullYear()) + time;
  },
  getTime(_date, options) {
    if (!_date) { return null }
    let date = new Date(_date);
    let time = '';
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    if (hour < 10) {
      hour = '0' + date.getHours()
    }
    if (minute < 10) {
      minute = '0' + date.getMinutes()
    }
    if (second < 10) {
      second = '0' + date.getSeconds()
    }
    let mark = ":";
    if (options?.mark) {
      mark = options.mark
    }
    time = hour + mark + minute;

    if (options && options.bookSelectHalfTime) {
      if (parseFloat(minute) === 0) {
        time = parseFloat(hour);
      }
      if (parseFloat(minute) > 0) {
        time = parseFloat(hour) + mark + minute;
      }
    }
    return time;
  },
  getDateToEdit(date) {
    // MMMM d, yyyy h:mm aa
    var timestampInSeconds = Math.floor(date / 1000);
    var _date = new Date(timestampInSeconds * 1000);
    return _date;
  },
  dataURLToBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

  },
  getFormInputObject(form) {
    let data = {};
    form.serializeArray().map((item) => {
      if (item.value && item.value != 'false' && item.value != 'undefined' && item.value != '') {
        data[item.name] = item.value;
      }
    })
    return data;
  },
  setNewImageFileName(file) {
    let date = new Date();
    let file_type = file.name.substr(file.name.indexOf('.'), file.name.indexOf('.') + 3);
    let file_name = file.name.substr(0, file.name.indexOf('.'));
    return file_name + Date.parse(date) + file_type;
  },
  reactToDom($dom, Component, props) {
    // console.log(typeof Component);
    if (typeof Component === 'function') {
      ReactDOM.render(React.createElement(Component, props, null), document.createElement('div'), function () {
        $dom.append(ReactDOM.findDOMNode(this));
      });
    }
    if (typeof Component === 'object') {
      ReactDOM.render(Component, document.createElement('div'), function () {
        $dom.append(ReactDOM.findDOMNode(this));
      });
    }
  },
  messageTop(options) {
    Helper.reactToDom(window.$('body'), <MessageTop {...options} />)
  },
  confirmModal(options) {
    Helper.reactToDom(window.$('body'),
      <ComfirmModal  {...options} />
    );
  },
  setCookie(cname, cvalue, exdays) {

    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    // cvalue = window.escape(cvalue);
    cvalue = window.escape(cvalue);
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        // c = window.unescape(c);
        c = window.unescape(c);
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  getQueryStringLinkFromObject(query_string, url) {
    var q = [];
    window.$.each(query_string, function (key, value) {
      q.push(key + '=' + encodeURIComponent(value));
    });
    var page_name = window.location.pathname;
    if (url) page_name = url;
    if (q.length) {
      page_name += '?' + q.join('&');
    }
    return page_name;
  },
  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  getAllUrlParams(url) {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];

      // split our query string into its component parts
      var arr = queryString.split('&');

      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');

        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

        // (optional) keep case consistent
        // paramName = paramName.toLowerCase();
        // if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {

          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];

          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string') {
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    return obj;
  },
  MonthTh() {
    return ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน.", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
  },
  Month() {
    return ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  },
  Today() {
    let today = new Date();
    // let dd = String(today.getDate()).padStart(2, '0');
    // let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // let yyyy = today.getFullYear();

    // today = yyyy + '-' + mm + '-' + dd;

    // today = mm + '-' + dd + '-' + yyyy;
    return today;
  },
  FormatDateNumber(data) {
    let today = new Date();
    let current_datetime = new Date(data);
    let dd = String(current_datetime.getDate()).padStart(2, '0');
    let mm = String(current_datetime.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = current_datetime.getFullYear();
    if (current_datetime.getFullYear() > today.getFullYear()) {
      yyyy = today.getFullYear()
    }
    let formatted_date = yyyy + '-' + mm + '-' + dd + ' 00:00:00';
    return formatted_date;
  },
  FormatDate(data) {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    let current_datetime = new Date(data.replace(' ', 'T'))
    let formatted_date = current_datetime.getDate() + " " + months[current_datetime.getMonth()] + " " + current_datetime.getFullYear()
    return formatted_date;
  },
  FormatDateTh(data) {
    const months = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    let current_datetime = new Date(data.replace(' ', 'T'));
    let formatted_date = current_datetime.getDate() + " " + months[current_datetime.getMonth()] + " " + (current_datetime.getFullYear() + 543)
    return formatted_date;
  },
  FormatTime(data) {

    let current_datetime = new Date(data.replace(' ', 'T'))
    let Hour = current_datetime.getHours() < 10 ? '0' + current_datetime.getHours() : current_datetime.getHours();
    let Minute = current_datetime.getMinutes() < 10 ? '0' + current_datetime.getMinutes() : current_datetime.getMinutes();
    let formatted_date = Hour + ":" + Minute;
    return formatted_date;
  },
  dataDiff(start_date, end_date, diff_type) {
    const date1 = new Date(start_date.replace(' ', 'T'));
    const date2 = new Date(end_date.replace(' ', 'T'));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diff_type === 'time') {
      return diffTime;
    }
    return diffDays;
  },
  summaryItems(cart) {
    let summary = {};
    summary.total_price = 0;
    summary.total_deposit = 0;
    summary.total_discount = 0;
    summary.shipment_price = cart && cart.shipment_price ? cart.shipment_price : 0;
    summary.shipment_discount = 0;
    summary.voucher_discount = 0;
    summary.grand_total_price = 0;
    summary.items_amount = 0;


    if (cart && cart.items && cart.items.length > 0) {
      cart.items.forEach((item) => {
        if (item.is_select) {
          summary.total_price = summary.total_price + parseFloat(item.price);
          summary.total_discount = summary.total_discount + parseFloat(item.discount);
          summary.items_amount = summary.items_amount + parseFloat(item.quantity);
          summary.total_deposit = summary.total_deposit + parseFloat(item.deposit);
        }
      });
    }

    summary.grand_total_price = (summary.total_price - summary.total_discount) + parseFloat(summary.shipment_price) + parseFloat(summary.total_deposit);
    if (cart && cart.voucher_group) {
      if (parseFloat(cart.voucher_group.vg_type) === 1) {
        summary.voucher_discount = cart.voucher_group.vg_value + '%';
        summary.grand_total_price = (parseFloat(summary.grand_total_price) * parseFloat(cart.voucher_group.vg_value)) / 100;
      }
      if (parseFloat(cart.voucher_group.vg_type) === 2) {
        summary.voucher_discount = '฿' + Helper.numberFormat(cart.voucher_group.vg_value);
        summary.grand_total_price = parseFloat(summary.grand_total_price) - parseFloat(cart.voucher_group.vg_value);
      }
    }


    return summary;
  },
  numberFormat(number, options) {
    if (!number) { return 0 }
    number = parseFloat(number);
    if (options && options.decimal) {
      number = number.toFixed(options.decimal);
    }
    if (isNaN(number)) {
      number = 0;
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  objToGetParameter(obj) {
    let str = "";
    for (let key in obj) {
      if (str !== "") {
        str += "&";
      }
      str += key + "=" + obj[key];
    }
    return str;
  },
  chkDigitPid(p_iPID) {
    var total = 0;
    var iPID;
    var chk;
    var Validchk;
    iPID = p_iPID.replace(/-/g, "");
    Validchk = iPID.substr(12, 1);
    var j = 0;
    var pidcut;
    for (var n = 0; n < 12; n++) {
      pidcut = parseInt(iPID.substr(j, 1));
      total = (total + ((pidcut) * (13 - n)));
      j++;
    }

    chk = 11 - (total % 11);

    if (chk === 10) {
      chk = 0;
    } else if (chk === 11) {
      chk = 1;
    }
    if (chk === Validchk) {
      // alert("ระบุหมายเลขประจำตัวประชาชนถูกต้อง");
      return true;
    } else {
      // alert("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
      return false;
    }

  },
  showProvinceWithAmphoe(provincesCode, amphoesCode) {
    let provinces = [];
    if (provincesCode) {
      provincesCode.forEach((provinceCode) => {
        for (const [key, value] of Object.entries(thailand)) {
          let amphoes = [];
          if (key === provinceCode) {
            for (const [_key, _value] of Object.entries(value.amphoes)) {
              if (amphoesCode && amphoesCode.length > 0) {
                amphoesCode.forEach((amphoeCode) => {
                  if (_key === amphoeCode) {
                    amphoes.push({ code: _key, amphoe: _value.name.th });
                  }
                })
              }
            }
            provinces.push({ code: key, province: value.name.th, amphoes: amphoes })
            // console.log(`${key}: ${value.name.th}`);
          }
        }
      })
    }
    return provinces;
  },
}