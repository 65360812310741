import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import { storage } from '../firebase';
import Pagination from '../Components/Pagination';
const axios = require('axios').default;

export default class PromotionHistory extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            market_user_id: Helper.getParameterByName('market_user_id') ? Helper.getParameterByName('market_user_id') : '',
            is_show_next_button: false,
            users_count: 0,
            margin_users: [],
        }
    }
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                market_user_id: Helper.getParameterByName('market_user_id') ? Helper.getParameterByName('market_user_id') : '',
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    fetchData() {
        let qs_data = {};
        qs_data._page = parseFloat(this.state.page);
        qs_data._limit = parseFloat(this.state.limit);
        // if (this.state.type) {
        qs_data.type = 'done';
        // }
        axios.get(window.api_host + '/promotionAPI?' + window.$.param(qs_data)).then(async (res) => {
            // console.log(res)
            let data = [];
            let count = res.data.count_all;
            let users_count = 0;
            let get_data = res.data.data;
            users_count = get_data.length;


            data = get_data;
            let export_datas = [];
            for (const data_doc of data) {
                let export_data = {};
                export_data.name = data_doc.name;
                export_data.code = data_doc.voucherCode;
                export_data.used = data_doc.history.length;
                export_data.limit = data_doc.limit;
                export_datas.push(export_data)
            }

            this.setState({
                export_data: export_datas,
                data: data,
                is_loading: false,
                all_items: count,
                users_count: users_count,
            })
        }).catch(error => {
            console.log(error);
            if (error.response) {
                Helper.messageTop({ message: error.response.message });
            }
            this.setState({
                is_loading: false,
            })
        });
    }

    delete(data) {
        if (data.imageUrl) {
            storage.ref(data.imageUrl).delete();
        }
        axios.post(window.api_host + '/deletePromotionAPI', { promotionUid: data.id, isCanDelete: true }).then(async (res) => {
            this.fetchData();
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
        }).catch(error => {
            console.log(error);
            if (error.response) {
                Helper.messageTop({ message: error.response.message });
            }
        });
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ประวัติโปรโมชั่น</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/promotion">จัดการโปรโมชั่น</Link></li>
                                    <li className="breadcrumb-item active">ประวัติโปรโมชั่น</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">ประวัติโปรโมชั่น</h3>
                                <div className={"card-right"}>
                                    <div style={{ marginRight: 10 }}>ทั้งหมด {this.state.all_items} โปรโมชั่น</div>
                                </div>
                                <div className="card-tools">
                                    <form className="input-group input-group-sm"
                                        style={{ alignItems: 'center' }}
                                        ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                page: 1,
                                                market_user_id: data.market_user_id,
                                            }, '/promotion')
                                            navigate(url)
                                        }}
                                    >
                                        <div style={{ marginRight: 10 }}>
                                            ผู้รับผิดชอบ
                                        </div>
                                        <select
                                            className="form-control placeholder_font"
                                            name="market_user_id"
                                            defaultValue={this.state.market_user_id}
                                            onChange={() => {
                                                this.$searchForm.find('[type="submit"]').trigger('click')
                                            }}
                                        >
                                            <option value="">ทั้งหมด</option>
                                            {
                                                this.state.margin_users.map((margin_user, margin_user_i) => (
                                                    <option value={margin_user.id} key={margin_user_i}>{margin_user.name}</option>
                                                ))
                                            }
                                        </select>
                                        <div className="input-group-append" style={{ display: 'none' }}>
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>โปรโมชั่น</th>
                                            <th>ประเภท</th>
                                            <th>เงื่อนไข</th>
                                            <th>ช่วงเวลา</th>
                                            <th>จำนวนสิทธิ์</th>
                                            <th>ผู้รับผิดชอบ</th>
                                            <th style={{ width: 200 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                <div>
                                                                    {_data.name}
                                                                </div>
                                                                <div className={"table-label"}>
                                                                    {
                                                                        this.state.voucherListCode
                                                                            && _data.voucherCode ?
                                                                            <span className="badge badge-info" style={{ marginRight: 5 }}>{_data.voucherCode}</span>

                                                                            : null
                                                                    }
                                                                    {
                                                                        _data.history
                                                                            && _data.history.length >= _data.limit
                                                                            && !_data.isUnLimit ?
                                                                            <span className="badge badge-danger" style={{ marginRight: 5 }}>ครบจำนวนสิทธิ์</span>
                                                                            : null
                                                                    }
                                                                    {
                                                                        new Date(_data.EndAt) < new Date()
                                                                            && !_data.isAllTime ?
                                                                            <span className="badge badge-warning">หมดอายุ</span>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.promotionType === 'event' ?
                                                                        "แสดงในหน้า app"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionType === 'unique' ?
                                                                        "สำหรับการตลาด"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionType === 'package' ?
                                                                        "แพ็กเกจ"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionType === 'agent' ?
                                                                        "ตัวแทน"
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.promotionCondition === 'all' ?
                                                                        "ใช้ได้ทุกที่"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionCondition === 'only_first_code' ?
                                                                        "ใช้ครั้งแรกใน code ชุดนั้นๆ"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionCondition === 'only_first_time' ?
                                                                        "ใช้ครั้งแรกใน app"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionCondition === 'only_first_time_plus_code' ?
                                                                        "ใช้ code ครั้งแรกและ ใช้ code ชุดนั้นครั้งแรก"
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.isAllTime ?
                                                                        "ไม่กำหนดระยะเวลา"
                                                                        :
                                                                        <>
                                                                            <div>{Helper.getDateThai(new Date(_data.startAt), { is_show_time: false })}</div>
                                                                            <div>{Helper.getDateThai(new Date(_data.EndAt), { is_show_time: false })}</div>
                                                                        </>
                                                                }
                                                            </td>

                                                            <td>
                                                                {
                                                                    _data.isUnLimit ?
                                                                        <>{_data.history.length + '/'}<i className="fas fa-infinity" style={{ color: '#ffc107' }}></i></>
                                                                        // _data.history.length + '/ ไม่จำกัดการใช้งาน' 
                                                                        :
                                                                        _data.history.length + '/' + _data.limit
                                                                }
                                                            </td>
                                                            <td>
                                                                {_data.market_user.name}
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <Link className="btn btn-default" to={"/promotion/" + _data.id}>ดูข้อมูล</Link>
                                                                    {
                                                                        _data.history.length === 0 ?
                                                                            <button type="button"
                                                                                className="btn btn-danger"
                                                                                ref={(ref) => {
                                                                                    window.$(ref).off().on('click', () => {
                                                                                        if (window.$(ref).hasClass('loading')) { return; }
                                                                                        window.$(ref).addClass('loading');
                                                                                        Helper.confirmModal({
                                                                                            title: "ยืนยันลบโปรโมชั่น",
                                                                                            description: "ต้องการลบโปรโมชั่นท่านนี้หรือไม่ ?",
                                                                                            onConfirm: () => {
                                                                                                window.$(ref).removeClass('loading');
                                                                                                this.delete(_data);
                                                                                            },
                                                                                            onCancel: () => {
                                                                                                window.$(ref).removeClass('loading');
                                                                                            }
                                                                                        });
                                                                                    })
                                                                                }}
                                                                            >
                                                                                ลบ
                                                                            </button>
                                                                            : null
                                                                    }
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
