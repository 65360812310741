import React from 'react';
import { Helper } from '../Helper';
import { storage } from '../firebase';

const axios = require('axios').default;
export default class ChangeRefundSlip extends React.Component {
    constructor() {
        super();
        this.state = {
            cancal_modal: false
        }
    }
    async wegateRefund(data) {
        let postData = { refundSlipURL: data.slipURL };
        // delete postData.storagePath;
        try {
            let res = await axios.put(`${window.api_host}/booking`, {
                update: {
                    ...postData
                },
                id: this.props.uid
            });
            window.$(document).Toasts('create', {
                title: 'อัพเดทข้อมูลสำเร็จ',
                // body: "ระบบได้ทำการยื่นขอคือเงิน Booking เรียบร้อยแล้ว",
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            if (this.props.onChangeSuccess) {
                this.props.onChangeSuccess(data.slipURL);
            }
            this.$modal.modal('hide');
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'อัพโหลดรูปไม่สำเร็จ',
                body: `กรุณาลองใหม่อีกครั้ง`,
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            if (this.$file) {
                let upload_btn = this.$file.parent().find('.btn');
                upload_btn.removeClass('loading');
            }
            this.$confim.removeClass('loading');
        }

    }
    readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.$file.parent().find('.button').text('เปลี่ยนรูป');
                window.$('#show_image').html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
                window.$('#show_image').html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
            };

            reader.readAsDataURL(input.files[0]);
        }
    }
    uploadFile(node, options) {
        // let id = options.PromotionId;
        let upload_btn = node.parent().find('.btn');
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        const filename = Helper.setNewImageFileName(file);
        console.log('before upload image', filename);
        var uploadTask = storage.ref().child('bookingRefund/' + filename).put(file);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log('uploading image', snapshot);
        }, (error) => {
            console.log('upload image error', error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            upload_btn.removeClass('loading');
            this.$confim.removeClass('loading');
        }, async () => {
            let url = 'bookingRefund/' + filename;
            console.log('upload image success', url);
            if (options.onSuccess) {
                options.onSuccess(url);
            }

        });
    }

    render() {
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                }}
            >
                <div className="modal-dialog modal-md" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div>
                                <label>รูปภาพ</label>
                                <input name="image"
                                    type={"file"}
                                    ref={(ref) => this.$file = window.$(ref)}
                                    style={{ display: 'none' }}
                                    id="file_image"
                                    onChange={(e) => {
                                        this.readURL(this.$file[0]);
                                    }}
                                />
                                <div id="show_image" />
                                <label htmlFor="file_image"
                                    type={"button"}
                                    className="btn btn-default"
                                    style={{ width: '100%' }}
                                >
                                    อัพโหลด
                                </label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className={"flex row"}>
                                <div style={{ marginLeft: 'auto' }}>
                                    <button className={"btn btn-default"}
                                        onClick={() => {
                                            this.$modal.modal('hide')
                                        }}
                                    >ไม่ดำเนิการ</button>
                                    <button className={"btn btn-primary"}
                                        style={{ marginLeft: '1rem' }}
                                        ref={(ref) => {
                                            this.$confim = window.$(ref);
                                        }}
                                        onClick={() => {
                                            if (this.$confim.hasClass('loading')) {
                                                return;
                                            }
                                            this.$confim.addClass('loading');
                                            if (this.$file && this.$file[0] && this.$file[0].files[0]) {

                                                let data = {};
                                                this.uploadFile(this.$file, {
                                                    onSuccess: async (url) => {
                                                        data.storagePath = url;
                                                        data.slipURL = await storage.ref(url).getDownloadURL();
                                                        this.wegateRefund(data);
                                                    }
                                                })
                                            } else {
                                                window.$(document).Toasts('create', {
                                                    title: 'อัพโหลดไม่สำเร็จ',
                                                    body: "กรุณาอัพโหลดสลิป",
                                                    icon: 'fas fa-times',
                                                    autohide: true,
                                                    delay: 3000,
                                                    class: "bg-danger",
                                                })
                                                this.$confim.removeClass('loading');
                                                return;
                                            }
                                        }}
                                    >
                                        บันทึก
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        );
    }
}