import React, { Component } from 'react'
import { navigate, Link } from '@reach/router';
import { db } from '../firebase';
import { Helper } from '../Helper';
export default class AgentProviderList extends Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            is_absolute_loading: false,
            data: [],
            countAll: 0
        }
    }

    componentDidMount() {
        this.fetchProvider();
    }

    async fetchProvider() {
        let ref = await db.collection('provider_users_data').where('agentUid', '==', this.props.agentUid).get();
        let maids = [];
        let countAll = 0
        if (!ref.empty) {
            for (const doc of ref.docs) {
                let _data = doc.data();
                _data.uid = doc.id;
                maids.push(_data)
            }
            countAll = ref.size;
        }
        console.log(maids)
        this.setState({
            data: maids,
            is_loading: false,
            countAll: countAll
        })
    }

    render() {
        return (
            <>
                <div className="card">
                    <form className="card-header "
                        style={{ display: 'flex', alignItems: 'center' }}
                        ref={(ref) => { this.$searchForm = window.$(ref) }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            let data = Helper.getFormInputObject(this.$searchForm);
                            let url = Helper.getQueryStringLinkFromObject({
                                page: 1,
                                ...data
                            })
                            navigate(url)
                        }}
                    >
                        <div className='mb-0'>
                            <h3 className="card-title" style={{ display: 'flex', alignItems: 'center' }}>รายชื่อแม่บ้าน</h3>
                            <p className='mb-0'>จำนวน {this.state.countAll} คน</p>
                        </div>
                    </form>
                    <div className="card-body">
                        {
                            this.state.is_absolute_loading ?
                                <div className="absolute_loading">
                                    <div className="ui active inline loader small" ></div>
                                </div>
                                : null
                        }
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ width: "20%", minWidth: 230 }}>ชื่อ</th>
                                    <th style={{ minWidth: 150 }}>ข้อมูลการติดต่อ</th>
                                    <th style={{ minWidth: 130 }}>วันที่สมัคร</th>
                                    <th style={{ minWidth: 130 }}>สถานะ App</th>
                                    <th style={{ minWidth: 95 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !this.state.is_loading
                                        && this.state.data.length > 0 ?
                                        this.state.data.map((_data, _data_i) => {
                                            console.log(_data)
                                            return (
                                                <tr key={_data_i}>
                                                    <td>
                                                        {_data.gender === "ชาย" ? <i className="fas fa-mars"></i> : null}
                                                        {_data.gender === "หญิง" ? <i className="fas fa-venus"></i> : null}
                                                        {' '}
                                                        {_data.prefix} {_data.firstName} {_data.lastName}<br />
                                                        <i className="fas fa-star" style={{ color: '#ffa200' }}></i> {_data.provider_user && _data.provider_user.starFloat !== undefined ? _data.provider_user.starFloat.toFixed(2) : '5.00'}

                                                    </td>
                                                    <td>
                                                        <i className="fas fa-phone"></i> {_data.phoneNumber}
                                                        {
                                                            _data.lineID ?
                                                                <div><i className="fab fa-line"></i> {_data.lineID}</div>
                                                                : null
                                                        }
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {Helper.getDateThai(_data.createdAt, { is_show_time: true })}
                                                        </div>
                                                        {
                                                            _data.lastActive ?
                                                                <div><strong>ใช้งานล่าสุด :</strong> {Helper.getDateThai(_data.lastActive, { is_show_time: true })}</div>
                                                                : null
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className={"flex row"}>
                                                            <div>

                                                                {
                                                                    _data.status === 'waitForApprove' ?
                                                                        <><i className={"fas fa-circle"} style={{ color: '#ffa200' }} /> รอการยืนยัน</>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.status === 'approved' ?
                                                                        <><i className={"fas fa-circle"} style={{ color: 'blue' }} /> ยืนยันแล้ว</>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.status === 'avaliable' ?
                                                                        <><i className={"fas fa-circle"} style={{ color: 'green' }} /> ใช้งานได้</>
                                                                        : null
                                                                }
                                                                {
                                                                    _data.status === 'ban' || _data.status == 'cancel' ?
                                                                        <><i className={"fas fa-circle"} style={{ color: 'red' }} /> ระงับการใช้งาน</>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="Basic example">
                                                            <Link to={'/provider-users/' + _data.uid} type="button" className="btn btn-default">ดูข้อมูล</Link>
                                                        </div>

                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : null
                                }
                                {
                                    !this.state.is_loading
                                        && this.state.data.length === 0 ?
                                        <tr >
                                            <td colSpan="6" style={{ textAlign: 'center' }}>
                                                ไม่มีข้อมูล
                                            </td>
                                        </tr>
                                        : null
                                }
                                {
                                    this.state.is_loading ?
                                        <tr >
                                            <td colSpan="6" style={{ textAlign: 'center' }}>
                                                <div className="ui active inline loader small" ></div>
                                            </td>
                                        </tr>
                                        : null
                                }


                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}
