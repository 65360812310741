import React, { Component } from 'react';
import { Helper } from '../../Helper';
import { storage } from '../../firebase';
import { bookService } from '../../Services/bookService';
const axios = require('axios').default;

export default class UrgentForm extends Component {
    constructor() {
        super();
        this.state = {
            is_loading: false,
            priceUrgent: 0,
            priceUrgentApp: 0,
            priceUrgentProvider: 0,
            vatPrice: 0,
        }
    }

    componentDidMount() {
        let urgent = this.props.urgent || false;
        if (urgent) {
            setTimeout(() => {
                let price = parseFloat(urgent?.priceUrgent || 0)
                let priceUrgentApp = parseFloat(urgent?.priceUrgentApp || 0)
                let priceUrgentProvider = parseFloat(urgent?.priceUrgentProvider || 0)
                const sharePercent = 50
                let vat = parseFloat(urgent?.priceUrgentVat || 0);
                let vatNode = window.$('#vatPrice')
                this.state.vatPrice = vat;
                let renderTotal = window.$('#Total')

                renderTotal.html(Helper.numberFormat((price + vat), { decimal: 2 }))
                vatNode.html(Helper.numberFormat(vat, { decimal: 2 }))

                let renderAppTotalNode = window.$('#AppTotal')
                let renderProviderTotalNode = window.$('#ProviderTotal')
                renderAppTotalNode.html(Helper.numberFormat(priceUrgentApp + this.state.vatPrice, { decimal: 2 }))
                renderProviderTotalNode.html(Helper.numberFormat(priceUrgentProvider, { decimal: 2 }))
            }, 200)

        }

    }
    readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.$file.parent().find('.button').text('เปลี่ยนรูป');
                window.$('#show_image').html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
                window.$('#show_image').html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    uploadFile(node, options) {
        // let id = options.PromotionId;
        let upload_btn = node.parent().find('.btn');
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        const filename = Helper.setNewImageFileName(file);
        console.log('before upload image', filename);
        var uploadTask = storage.ref().child('bookingUrgent/' + filename).put(file);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log('uploading image', snapshot);
        }, (error) => {
            console.log('upload image error', error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            upload_btn.removeClass('loading');
            this.$confim.removeClass('loading');
        }, async () => {
            let url = 'bookingUrgent/' + filename;
            console.log('upload image success', url);
            if (options.onSuccess) {
                options.onSuccess(url);
            }

        });
    }

    async update(data) {
        let urgent = this.props.urgent || false;
        let res = await bookService.urgent(
            data.adminUid,
            data.bookUid,
            data.noteUrgent || "",
            data.priceUrgent,
            data.priceUrgentApp,
            data.priceUrgentProvider,
            data.urgentImage || urgent?.urgentImage || null
        )
        if (res.code == 200) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มชั่วโมงเร่งด่วนสำเร็จ',
                body: 'ทำการเพิ่มชั่วโมงเร่งด่วนเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.props.onSuccess();
            this.$modal.modal('hide');
        }
        if (res.error) {
            let error = res.error;
            let title = "ไม่สามารถทำรายการได้" // defult error title
            let description = "กรุณาติดต่อผู้จัดทำระบบ" // defult error description
            // error ตาม response ที่ส่งมา เช็คเอง
            // if (error.response.data.code === -1) {
            //     title = "Error code -1";
            //     description = "Error code description -1";
            // }
            // if (error.response.data.code === -2) {
            //     title = "Error code -2";
            //     description = "Error code description -2";
            // }
            window.$(document).Toasts('create', {
                title: title,
                body: description,
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submitBtn.removeClass('loading');
            return;
        }
    }
    calculatePrice(price) {
        price = parseFloat(price)
        const sharePercent = 50
        let vatNode = window.$('#vatPrice')
        let appNode = window.$(`input[name="priceUrgentApp"]`)
        let providerNode = window.$(`input[name="priceUrgentProvider"]`)

        let vat = (price * 7) / 100;
        this.state.vatPrice = vat;

        let renderTotal = window.$('#Total')
        renderTotal.html(Helper.numberFormat((price + vat), { decimal: 2 }))
        vatNode.html(Helper.numberFormat(vat, { decimal: 2 }))
        appNode.val((price * sharePercent / 100))
        providerNode.val((price * sharePercent / 100))
    }

    calculateApp(pinType) {
        let renderAppTotalNode = window.$('#AppTotal')
        let renderProviderTotalNode = window.$('#ProviderTotal')

        let piceNode = window.$(`input[name="priceUrgent"]`)
        let appNode = window.$(`input[name="priceUrgentApp"]`)
        let providerNode = window.$(`input[name="priceUrgentProvider"]`)
        let priceVal = parseFloat(piceNode.val())
        let appVal = parseFloat(appNode.val());
        let providerVal = parseFloat(providerNode.val());
        if (pinType == 'app') {
            providerVal = priceVal - appVal
        } else if (pinType == 'provider') {
            appVal = priceVal - providerVal
        }

        appNode.val(appVal)
        providerNode.val(providerVal)
        renderAppTotalNode.html(Helper.numberFormat(appVal + this.state.vatPrice, { decimal: 2 }))
        renderProviderTotalNode.html(Helper.numberFormat(providerVal, { decimal: 2 }))
    }

    render() {
        let urgent = this.props.urgent || false;
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard="false" data-backdrop="static"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading') || this.state.is_loading) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        if (this.$file && this.$file[0] && this.$file[0].files[0]) {
                            this.uploadFile(this.$file, {
                                onSuccess: async (url) => {
                                    data.urgentImage = {}
                                    data.urgentImage.storagePath = url;
                                    data.urgentImage.imagePath = await storage.ref(url).getDownloadURL();
                                    this.update(data);
                                }
                            })
                            return;
                        } else {
                            this.update(data);
                        }
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                ชั่วโมงเร่งด่วน
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            <>
                                <div>
                                    <label>เร่งด่วน</label>
                                    <input type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                            let priceUrgent = 0;
                                            if (!isNaN(e.target.value)) {
                                                priceUrgent = e.target.value;
                                            } else {
                                                priceUrgent = this.state.priceUrgent;
                                                window.$(`input[name="priceUrgent"]`).val(priceUrgent)
                                            }
                                            this.state.priceUrgent = priceUrgent;
                                            this.calculatePrice(priceUrgent);
                                            this.calculateApp();

                                        }}
                                        name='priceUrgent'
                                        defaultValue={urgent?.priceUrgent || ''}
                                    />
                                </div>
                                <div className="form-group">
                                    <small>ภาษี 7%</small><span id="vatPrice" style={{ marginLeft: 10 }}>0</span> <small>บาท</small>
                                </div>
                                <div className={"salary-transfer-price"}>
                                    <div className={"title"}>รวมทั้งหมด</div>
                                    <div className={"price"} id="Total" style={{ color: 'green' }}>0</div>
                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-12 col-lg-6 form-group'>
                                        <label>App</label>
                                        <input step="0.01" type="number"
                                            className="form-control"
                                            onChange={(e) => {
                                                let value = 0;
                                                if (!isNaN(e.target.value)) {
                                                    value = e.target.value;
                                                } else {
                                                    value = this.state.priceUrgentApp;
                                                    window.$(`input[name="priceUrgentApp"]`).val(value)
                                                }
                                                this.state.priceUrgentApp = value;

                                                this.calculateApp('app');
                                            }}
                                            name='priceUrgentApp'
                                            defaultValue={urgent?.priceUrgentApp || ''}
                                        />
                                        <div className={"salary-transfer-price"}>
                                            <div className={"title"}>App ได้รับ</div>
                                            <div className={"price"} id="AppTotal" style={{ color: 'green', fontSize: 26 }}>0</div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-6 form-group'>
                                        <label>แม่บ้าน</label>
                                        <input step="0.01" type="number"
                                            className="form-control"
                                            onChange={(e) => {
                                                let value = 0;
                                                if (!isNaN(e.target.value)) {
                                                    value = e.target.value;
                                                } else {
                                                    value = this.state.priceUrgentProvider;
                                                    window.$(`input[name="priceUrgentProvider"]`).val(value)
                                                }
                                                this.state.priceUrgentProvider = value;

                                                this.calculateApp('provider');
                                            }}
                                            name='priceUrgentProvider'
                                            defaultValue={urgent?.priceUrgentProvider || ''}
                                        />
                                        <div className={"salary-transfer-price"}>
                                            <div className={"title"}>แม่บ้านได้รับ</div>
                                            <div className={"price"} id="ProviderTotal" style={{ color: 'green', fontSize: 26 }}>0</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label>รูปภาพ</label>
                                    <input name="image"
                                        type={"file"}
                                        ref={(ref) => this.$file = window.$(ref)}
                                        style={{ display: 'none' }}
                                        id="file_image"
                                        onChange={(e) => {
                                            this.readURL(this.$file[0]);
                                        }}
                                    />
                                    <div id="show_image" >
                                        {
                                            urgent?.urgentImage?.imagePath ?
                                                <img src={urgent?.urgentImage?.imagePath} alt={urgent?.urgentImage?.storagePath} />
                                                : null
                                        }
                                    </div>
                                    <label htmlFor="file_image"
                                        type={"button"}
                                        className="btn btn-default"
                                        style={{ width: '100%' }}
                                    >
                                        อัพโหลด
                                    </label>
                                </div>
                                <hr />
                                <div className={"form-group"}>
                                    <label>หมายเหตุ</label>
                                    <textarea name={"noteUrgent"} className={"form-control"} defaultValue={urgent.noteUrgent || ''}></textarea>
                                </div>
                                <input type="hidden" name={"bookUid"} defaultValue={this.props.bookUid} />
                                <input type="hidden" name={"adminUid"} defaultValue={this.props.adminUid} />
                            </>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal" disabled={this.state.is_loading}>ยกเลิก</button>
                            <button type="submit" className="btn btn-primary"
                                disabled={this.state.is_loading}
                                ref={(ref) => { this.$submitBtn = window.$(ref) }}
                            >
                                บันทึก
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
