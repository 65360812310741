import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../Helper';
const axios = require('axios').default;

export default class Invoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            booking: false,
            booking_id: props.booking_id,
            is_payment_loading: true,
            is_loading_time_plus: true,
        }
    }

    async componentDidMount() {
        this.fetch();
    }
    async fetch() {
        let qs = {};
        qs.bookingID = this.state.booking_id;
        let res = await axios.get(`${window.api_host}/booking-detail?${window.$.param(qs)}`);
        // console.log(res)
        let _booking = false;
        let payment = false;
        if (res.data.success) {
            _booking = res.data.booking;
            payment = res.data.payment;
        }
        this.setState({
            booking: _booking,
            payment: payment,
            is_loading: false,
            is_loading_time_plus: false,
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ใบเสร็จรับเงิน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/booking">Booking</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/booking/" + this.props.booking_id}>ข้อมูล Booking</Link></li>
                                    <li className="breadcrumb-item active">ใบเสร็จรับเงิน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.booking ?
                                <InvoiceForm
                                    booking={this.state.booking}
                                    payment={this.state.payment}
                                />
                                : null
                        }

                    </div>
                </section>
            </>
        )
    }
}

class InvoiceForm extends React.Component {
    constructor() {
        super();
        this.state = {
            edit: false,
        }
    }
    printElement() {
        // window.$('#print').print();
        var divToPrint = document.getElementById('print');

        var newWin = window.open('', 'Print-Window');

        newWin.document.open();

        newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');
        window.$(newWin.document).find('button').remove();

        newWin.document.close();

        setTimeout(function () { newWin.close(); }, 10);
    }

    render() {
        let text = {
            fontSize: 14,
            color: '#5b5b5b',
            // fontFamily: 'Open Sans, sans-serif',
            lineHeight: '18px',
            verticalAlign: 'top',
            textAlign: 'left'
        }
        let productLine = {
            borderBottom: '1px solid #e4e4e4'
        }
        let booking = this.props.booking;
        console.log(this.props.booking)
        let summary = Helper.bookingSummary({ booking: this.props.booking, payment: this.props.payment });
        let total_discount = summary.total_discount;
        let plustime_price_no_tax = summary.plustime_price_no_tax;
        let plustime_total_price = summary.plustime_total_price;
        let tax_total_price = summary.tax_total_price;
        let plustime_total_with_tax_price = summary.plustime_total_with_tax_price;
        let hours = summary.hours;
        let total_price = summary.total_price;
        let count_plus_time = 0;

        let plusTimeItem = false;

        booking.plusTime.map((plusTime) => {
            if (plusTime.status === 'success') {
                if (!plusTimeItem) {
                    plusTimeItem = {
                        title: 'เพิ่มชั่วโมง',
                        time: plusTime.time,
                        price: plusTime.priceTotal
                    }
                } else {
                    plusTimeItem.time += plusTime.time;
                    plusTimeItem.price += plusTime.priceTotal;
                }
            }
        });


        let table_width = 700;
        let table_content_width = 580
        return (
            <>
                <div className='flex-row'>
                    <div style={{ textAlign: 'center' }}>
                        <button onClick={() => { this.printElement() }} className='btn btn-info'>พิมพ์ใบเสร็จรับเงิน</button>
                    </div>
                </div>
                <div id="print">

                    {/* HEADER */}
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable">
                        <tr>
                            <td height="20"></td>
                        </tr>
                        <tr>
                            <td>
                                <table width={table_width} border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="#ffffff" style={{ borderRadius: '10px 10px 0 0' }}>
                                    <tr class="hiddenMobile">
                                        <td height="40"></td>
                                    </tr>
                                    <tr class="visibleMobile">
                                        <td height="30"></td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <table width={table_content_width} border="0" cellpadding="0" cellspacing="0" align="center" class="fullPadding">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <table width="190" border="0" cellpadding="0" cellspacing="0" align="left" className="inv-col">
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="left"> <img src={window.location.origin + "/assets/images/logo-horizontal.png"} height="26" alt="logo" border="0" /></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <table width="310" border="0" cellpadding="0" cellspacing="0" align="right" className="inv-col">
                                                                <tbody>
                                                                    <tr class="visibleMobile">
                                                                        <td height="10"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td height="5"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td
                                                                            style={{
                                                                                fontSize: 18,
                                                                                color: '#ffc107',
                                                                                letterSpacing: 1,
                                                                                lineHeight: 1,
                                                                                verticalAlign: 'top',
                                                                                textAlign: 'right',
                                                                                fontWeight: 'bold',
                                                                            }}
                                                                        >
                                                                            ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <table width="250" border="0" cellpadding="0" cellspacing="0" align="left" className="inv-col">
                                                                <tbody>
                                                                    <tr class="hiddenMobile">
                                                                        <td height="40"></td>
                                                                    </tr>
                                                                    <tr class="visibleMobile">
                                                                        <td height="20"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td
                                                                            style={text}
                                                                        >
                                                                            <label style={{ margin: 0 }}>บริษัท วี ดีเวลลอป จำกัด (สำนักงานใหญ่)</label><br />
                                                                            เลขที่ 12/12-13 ซ.วิภาวดีรังสิต 17
                                                                            ถ.วิภาวดีรังสิต แขวงจตุจักร
                                                                            เขตจตุจักร กรุงเทพฯ 10900<br />
                                                                            <label style={{ margin: 0 }}>เลขประจำตัวผู้เสียภาษี</label> 0105562183420<br />
                                                                            <label style={{ margin: 0 }}>โทรศัพท์</label> 0-2027-7848
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <table width="270" border="0" cellpadding="0" cellspacing="0" align="right" className="inv-col">
                                                                <tbody>
                                                                    <tr>
                                                                        <tr class="hiddenMobile">
                                                                            <td height="40"></td>
                                                                        </tr>
                                                                        <tr class="visibleMobile">
                                                                            <td height="20"></td>
                                                                        </tr>
                                                                        <tr style={{ display: 'block' }}>
                                                                            <td style={{ ...text, width: '100%', display: 'block', textAlign: 'right' }}>
                                                                                <strong>ORDER</strong> #{booking.bookOn}<br />
                                                                                {/* <small>{Helper.getDateThai(booking.createdAt)}</small> */}
                                                                                <small>
                                                                                    {
                                                                                        this.props.booking.paymentBy
                                                                                            && this.props.booking.paymentBy == 'banktransfer' ?
                                                                                            Helper.getDateThai(this.props.booking.createdAt, { is_show_time: false })
                                                                                            : this.props.payment.omise ?
                                                                                                Helper.getDateThai(this.props.payment.omise.created_at, { is_show_time: false })
                                                                                                : this.props.payment.type == 'wegate' && this.props.payment.paymentAt ?
                                                                                                    Helper.getDateThai(this.props.payment.paymentAt, { is_show_time: false })
                                                                                                    : this.props.payment.type == 'wegate' && !this.props.payment.paymentAt ?
                                                                                                        Helper.getDateThai(this.props.payment.createdAt, { is_show_time: false })
                                                                                                        : '-'
                                                                                    }
                                                                                </small>
                                                                            </td>
                                                                        </tr>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    {/* DETAIL */}
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable">
                        <tbody>
                            <tr>
                                <td>
                                    <table width={table_width} border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="#ffffff">
                                        <tbody>
                                            <tr class="hiddenMobile">
                                                <td height="30"></td>
                                            </tr>
                                            <tr class="visibleMobile">
                                                <td height="20"></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table width={table_content_width} border="0" cellpadding="0" cellspacing="0" align="center" class="fullPadding">
                                                        <tbody>
                                                            <tr>
                                                                <th style={{ ...text, fontWeight: 'normal', padding: '0 10px 7px 0' }} width="52%" align="left">
                                                                    รายการ
                                                                </th>
                                                                <th style={{ ...text, fontWeight: 'normal', padding: '0 0 7px', textAlign: 'center' }} align="center">
                                                                    ชั่วโมง
                                                                </th>
                                                                <th style={{ ...text, fontWeight: 'normal', padding: '0 0 7px', textAlign: 'right' }} align="right">
                                                                    ราคา
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td height="1" style={{ backgroundColor: '#bebebe' }} colspan="4"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="10" colspan="4"></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ ...text, fontWeight: 'normal', padding: '10px 0', color: '#646a6e' }} class="article">
                                                                    {booking.address
                                                                        && booking.address.name ?
                                                                        <span>{booking.address.name}</span>
                                                                        : null
                                                                    }
                                                                    {booking.address
                                                                        && booking.address.placeType
                                                                        && booking.address.placeType.name_th ?
                                                                        <span>{booking.address.placeType.name_th}</span>
                                                                        : null
                                                                    }
                                                                    {booking.address
                                                                        && booking.address.placeType
                                                                        && booking.address.placeType.place_sub_type
                                                                        && booking.address.placeType.place_sub_type.description_th ?
                                                                        <span>{booking.address.placeType.place_sub_type.description_th}</span>
                                                                        : null
                                                                    }
                                                                </td>
                                                                <td style={{ ...text, fontWeight: 'normal', padding: '10px 0', color: '#646a6e', textAlign: 'center' }} align="center">
                                                                    {booking.address && booking.bookTime && booking.bookTime.name_th ?
                                                                        booking.bookTime.name_th
                                                                        : null
                                                                    }

                                                                </td>
                                                                <td style={{ ...text, fontWeight: 'normal', padding: '10px 0', color: '#1e2b33', textAlign: 'right' }} align="right">
                                                                    ฿{parseFloat(plustime_total_with_tax_price) > 0 ? Helper.numberFormat(plustime_total_with_tax_price, { decimal: 2 }) : 0}
                                                                </td>
                                                            </tr>
                                                            {
                                                                plusTimeItem ?
                                                                    <>
                                                                        <tr>
                                                                            <td height="1" colspan="4" style={productLine}></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ ...text, fontWeight: 'normal', padding: '10px 0', color: '#ffc107' }} class="article">
                                                                                {plusTimeItem.title}
                                                                            </td>
                                                                            <td style={{ ...text, fontWeight: 'normal', padding: '10px 0', color: '#646a6e', textAlign: 'center' }} align="center">{plusTimeItem.time ? plusTimeItem.time : 0} ชม.</td>
                                                                            <td style={{ ...text, fontWeight: 'normal', padding: '10px 0', color: '#1e2b33', textAlign: 'right' }} align="right"> ฿{Helper.numberFormat(plusTimeItem.price, { decimal: 2 })}</td>
                                                                        </tr>
                                                                    </>
                                                                    : null
                                                            }
                                                            <tr>
                                                                <td height="1" colspan="4" style={productLine} ></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td height="20"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* TOTAL */}
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable">
                        <tbody>
                            <tr>
                                <td>
                                    <table width={table_width} border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="#ffffff">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table width={table_content_width} border="0" cellpadding="0" cellspacing="0" align="center" class="fullPadding">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ ...text, textAlign: 'right', lineHeight: '22px', color: '#646a6e' }}>
                                                                    ค่าบริการ
                                                                </td>
                                                                <td style={{ ...text, textAlign: 'right', lineHeight: '22px', whiteSpace: 'nowrap', color: '#646a6e' }} width="80">
                                                                    ฿{parseFloat(plustime_total_with_tax_price) > 0 ? Helper.numberFormat(plustime_total_with_tax_price, { decimal: 2 }) : 0}

                                                                </td>
                                                            </tr>
                                                            {
                                                                total_discount
                                                                    && total_discount !== ''
                                                                    && parseFloat(total_discount) > 0 ?
                                                                    <tr>
                                                                        <td style={{ ...text, textAlign: 'right', lineHeight: '22px', color: '#646a6e' }}>
                                                                            ส่วนลด
                                                                        </td>
                                                                        <td style={{ ...text, textAlign: 'right', lineHeight: '22px', whiteSpace: 'nowrap', color: '#646a6e' }} width="80">
                                                                            ฿{parseFloat(total_discount) > 0 ? Helper.numberFormat(total_discount, { decimal: 2 }) : 0}

                                                                        </td>
                                                                    </tr>
                                                                    : null
                                                            }
                                                            {
                                                                hours ?
                                                                    <tr>
                                                                        <td style={{ ...text, textAlign: 'right', lineHeight: '22px', color: '#646a6e' }} >
                                                                            เพิ่มชั่วโมง ({hours} ชม.)
                                                                        </td>
                                                                        <td style={{ ...text, textAlign: 'right', lineHeight: '22px', whiteSpace: 'nowrap', color: '#646a6e' }}>
                                                                            ฿{parseFloat(plustime_price_no_tax) > 0 ? Helper.numberFormat(plustime_price_no_tax, { decimal: 2 }) : 0}
                                                                        </td>
                                                                    </tr>
                                                                    : null
                                                            }
                                                            {
                                                                tax_total_price ?
                                                                    <tr>
                                                                        <td style={{ ...text, textAlign: 'right', lineHeight: '22px', color: '#646a6e' }} >
                                                                            ภาษี 7%
                                                                        </td>
                                                                        <td style={{ ...text, textAlign: 'right', lineHeight: '22px', whiteSpace: 'nowrap', color: '#646a6e' }}>
                                                                            ฿{parseFloat(tax_total_price) > 0 ? Helper.numberFormat(tax_total_price, { decimal: 2 }) : 0}
                                                                        </td>
                                                                    </tr>
                                                                    : null
                                                            }
                                                            <tr>
                                                                <td style={{ ...text, textAlign: 'right', lineHeight: '22px', color: '#000' }}>
                                                                    <strong>ราคารวมทั้งหมด</strong>
                                                                </td>
                                                                <td style={{ ...text, textAlign: 'right', lineHeight: '22px', whiteSpace: 'nowrap', color: '#000' }}>
                                                                    <strong>฿{parseFloat(plustime_total_price) > 0 ? Helper.numberFormat(plustime_total_price, { decimal: 2 }) : 0}</strong>
                                                                </td>
                                                            </tr>
                                                            {/* {
                                                                tax_total_price ?
                                                                    <tr>
                                                                        <td style={{...text, textAlign: 'right', lineHeight: '22px', color: '#b0b0b0' }}><small>ภาษี 7%</small></td>
                                                                        <td style={{...text, textAlign: 'right', lineHeight: '22px', whiteSpace: 'nowrap', color: '#b0b0b0' }}>
                                                                            <small>฿{parseFloat(tax_total_price) > 0 ? Helper.numberFormat(tax_total_price, { decimal: 2 }) : 0}</small>
                                                                        </td>
                                                                    </tr>
                                                                    : null
                                                            } */}
                                                        </tbody>
                                                    </table>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* INFORMATION */}
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable">
                        <tbody>
                            <tr>
                                <td>
                                    <table width={table_width} border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="#ffffff">
                                        <tbody>
                                            <tr class="hiddenMobile">
                                                <td height="30"></td>
                                            </tr>
                                            <tr class="visibleMobile">
                                                <td height="20"></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table width={table_content_width} border="0" cellpadding="0" cellspacing="0" align="center" class="fullPadding">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <table width="300" border="0" cellpadding="0" cellspacing="0" align="left" className="inv-col">

                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ ...text, color: '#000', fontSize: 13, lineHeight: 1 }}>
                                                                                    <strong>ลูกค้า</strong>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td width="100%" height="10"></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ ...text, color: '#5b5b5b', lineHeight: '20px' }}>
                                                                                    {
                                                                                        this.state.edit ?
                                                                                            <>
                                                                                                <div>{this.state.edit.name}</div>
                                                                                                <div>{this.state.edit.address}</div>
                                                                                                <div><label style={{ margin: 0 }}>เลขประจำตัวผู้เสียภาษี/เลขประจำตัวประชาชน</label> {this.state.edit.card_id}</div>
                                                                                                <div><label style={{ margin: 0 }}>โทรศัพท์</label> {this.state.edit.tel}</div>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <div>ลูกค้าเงินสด</div>
                                                                                            </>
                                                                                    }

                                                                                    <button
                                                                                        className='btn btn-sm btn-primary'
                                                                                        style={{ marginTop: 5 }}
                                                                                        onClick={() => {
                                                                                            Helper.reactToDom(
                                                                                                window.$('body'),
                                                                                                <InformationForm
                                                                                                    edit={this.state.edit}
                                                                                                    onSubmit={(data) => {
                                                                                                        this.setState({
                                                                                                            edit: data
                                                                                                        })
                                                                                                    }}
                                                                                                />
                                                                                            )
                                                                                        }}
                                                                                    >แก้ไขข้อมูล</button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <table width="260" border="0" cellpadding="0" cellspacing="0" align="right" className="inv-col">
                                                                        <tbody>
                                                                            <tr class="visibleMobile">
                                                                                <td height="20"></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ ...text, color: '#000', fontSize: 13, lineHeight: 1 }}>
                                                                                    <strong>วิธีการชำระเงิน</strong>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td width="100%" height="10"></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ ...text, color: '#5b5b5b', lineHeight: '15px' }}>
                                                                                    {
                                                                                        this.props.payment.status !== 'refund'
                                                                                            && this.props.payment.status !== 'reverse'
                                                                                            && this.props.payment.status !== 'fail' ?
                                                                                            <>
                                                                                                <div style={{ marginBottom: 5 }}>
                                                                                                    <label>ช่องทาง</label> :  {' '}
                                                                                                    {
                                                                                                        this.props.payment.card ?
                                                                                                            "บัตรเครดิต"
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        plustime_total_price == 0 &&
                                                                                                            !this.props.payment.omise_id
                                                                                                            && (!this.props.booking.paymentBy
                                                                                                                && this.props.booking.paymentBy !== 'banktransfer'
                                                                                                                && this.props.booking.type !== 'wegate') ?
                                                                                                            "ได้รับการยกเว้นชำระเงิน เนื่องจากโปรโมชั่นที่ใช้ เกินค่าบริการ"
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        plustime_total_price > 0 &&
                                                                                                            !this.props.payment.omise_id
                                                                                                            && (!this.props.booking.paymentBy
                                                                                                                && this.props.booking.paymentBy !== 'banktransfer'
                                                                                                                && this.props.booking.type !== 'wegate') ?
                                                                                                            "ได้รับการยกเว้นชำระเงิน เนื่องจากโปรโมชั่นที่ใช้ เกินค่าบริการ (จำนวนเงินไม่ถูกต้อง)"
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        !this.props.payment.omise_id
                                                                                                            && this.props.booking.paymentBy
                                                                                                            && this.props.booking.paymentBy === 'banktransfer' ?
                                                                                                            "โอนเงินผ่านธนาคาร"
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        this.props.payment.type
                                                                                                            && this.props.payment.type == 'wegate' ?
                                                                                                            <>
                                                                                                                <div style={{ display: 'inline-block', verticalAlign: 'center' }}>
                                                                                                                    {this.renderWeGatePaymentChannel(this.props.payment.channel)}

                                                                                                                </div>
                                                                                                            </>
                                                                                                            : null
                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        this.props.payment
                                                                                            && this.props.payment.card ?
                                                                                            <>

                                                                                                <div>
                                                                                                    <label>ประเภทบัตร</label> :  {' '}
                                                                                                    {
                                                                                                        this.props.payment.card
                                                                                                            && this.props.payment.card.brand ?
                                                                                                            this.props.payment.card.brand
                                                                                                            : null
                                                                                                    }
                                                                                                </div>
                                                                                                <div>
                                                                                                    <label>เลขลงท้าย</label> :  {' '}
                                                                                                    {
                                                                                                        this.props.payment.card
                                                                                                            && this.props.payment.card.last_digits ?
                                                                                                            this.props.payment.card.last_digits
                                                                                                            : null
                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                    <div>
                                                                                        <label>เมื่อเวลา</label> :  {' '}
                                                                                        {
                                                                                            this.props.booking.paymentBy
                                                                                                && this.props.booking.paymentBy == 'banktransfer' ?
                                                                                                Helper.getDateThai(this.props.booking.createdAt, { is_show_time: true }) + ' น.'
                                                                                                : this.props.payment.omise ?
                                                                                                    Helper.getDateThai(this.props.payment.omise.created_at, { is_show_time: true }) + ' น.'
                                                                                                    : this.props.payment.type == 'wegate' && this.props.payment.paymentAt ?
                                                                                                        Helper.getDateThai(this.props.payment.paymentAt, { is_show_time: true }) + ' น.'
                                                                                                        : this.props.payment.type == 'wegate' && !this.props.payment.paymentAt ?
                                                                                                            Helper.getDateThai(this.props.payment.updatedAt, { is_show_time: true }) + ' น.'
                                                                                                            : '-'
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            {/* <tr>
                                            <td>
                                                <table width={table_content_width} border="0" cellpadding="0" cellspacing="0" align="center" class="fullPadding">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <table width="220" border="0" cellpadding="0" cellspacing="0" align="left" className="inv-col">
                                                                    <tbody>
                                                                        <tr class="hiddenMobile">
                                                                            <td height="35"></td>
                                                                        </tr>
                                                                        <tr class="visibleMobile">
                                                                            <td height="20"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ ...text, color: '#5b5b5b', fontSize: 11 }}>
                                                                                <strong>SHIPPING INFORMATION</strong>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="100%" height="10"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ ...text, color: '#5b5b5b', lineHeight: '20px' }}>
                                                                                Sup Inc<br /> Another Place, Somewhere<br /> New York NY<br /> 4468, United States<br /> T: 202-555-0171
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                                <table width="220" border="0" cellpadding="0" cellspacing="0" align="right" className="inv-col">
                                                                    <tbody>
                                                                        <tr class="hiddenMobile">
                                                                            <td height="35"></td>
                                                                        </tr>
                                                                        <tr class="visibleMobile">
                                                                            <td height="20"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ ...text, color: '#5b5b5b', fontSize: 11, lineHeight: 1 }}>
                                                                                <strong>SHIPPING METHOD</strong>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="100%" height="10"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ ...text, color: '#5b5b5b', lineHeight: '20px' }}>
                                                                                UPS: U.S. Shipping Services
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr> */}
                                            <tr class="hiddenMobile">
                                                <td height="60"></td>
                                            </tr>
                                            <tr class="visibleMobile">
                                                <td height="30"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable">
                        <tr>
                            <td>
                                <table width={table_width} border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="#ffffff" style={{ borderRadius: '0 0 10px 10px' }}>
                                    <tr>
                                        <td>
                                            <table width={table_content_width} border="0" cellpadding="0" cellspacing="0" align="center" class="fullPadding">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ ...text }}>
                                                            ขอบคุณที่ใช้บริการ Beeclean
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr class="spacer">
                                        <td height="50"></td>
                                    </tr>

                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td height="20"></td>
                        </tr>
                    </table>
                </div>
            </>
        )
    }

    renderWeGatePaymentChannel(channel) {
        console.log(channel)
        if (channel == 'PP') {
            return <>PromptPay</>
        }
        if (channel == 'TM') {
            return <>True Money</>
        }
        if (channel == 'AL') {
            return <>Alipay</>
        }
        if (channel == 'SP') {
            return <>ShopeePay</>
        }
        if (channel == 'LP') {
            return <>LINE Pay</>
        }
        if (channel == 'WC') {
            return <>WeChat Pay</>
        }
    }
}

class InformationForm extends React.Component {
    componentDidMount() {
        this.$modal.modal('show');
    }
    render() {
        return (
            <div ref={(ref) => {
                this.$modal = window.$(ref);
                this.$modal.modal('show');
                this.$modal.on('hidden.bs.modal', () => {
                    if (this.props.onCancel) {
                        this.props.onCancel();
                    }
                    let settime = window.setTimeout(() => {
                        this.$modal.remove();
                        clearTimeout(settime);
                    }, 500)
                });
            }}
                className="modal fade" tabIndex="-1" role="dialog">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        let data = Helper.getFormInputObject(window.$(e.target));
                        this.props.onSubmit(data);
                        this.$modal.modal('hide');
                    }}
                    className="modal-dialog"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">แก้ไขข้อมูลการเรียกเก็บเงิน</h4>
                        </div>
                        <div className="modal-body">
                            <div className='form-group'>
                                <label>ชื่อ</label>
                                <input className='form-control' name="name" defaultValue={this.props.edit ? this.props.edit.name : ''} />
                            </div>
                            <div className='form-group'>
                                <label>ที่อยู่</label>
                                <input className='form-control' name="address" defaultValue={this.props.edit ? this.props.edit.address : ''} />
                            </div>
                            <div className='form-group'>
                                <label>เลขประจำตัวผู้เสียภาษี/เลขประจำตัวประชาชน </label>
                                <input className='form-control' name="card_id" defaultValue={this.props.edit ? this.props.edit.card_id : ''} />
                            </div>
                            <div className='form-group'>
                                <label>เบอร์โทรศัพท์</label>
                                <input className='form-control' name="tel" defaultValue={this.props.edit ? this.props.edit.tel : ''} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={() => {
                                    if (this.props.onCancel) {
                                        this.props.onCancel();
                                    }
                                    this.$modal.modal('hide');
                                }}
                            >{this.props.cancle_text || "ยกเลิก"}</button>
                            <button type="submit" className="btn btn-primary"
                                ref={ref => { this.$btn = window.$(ref) }}

                            >{this.props.submit_text || "แก้ไข"}</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const paymentImage = {
    width: 25,
    height: 25,
    marginRight: 2
}