import { Helper } from '../Helper';

const axios = require('axios').default;

let api_host = process.env.REACT_APP_API_HOST_AGENT;
export const serverUrl = api_host;

const httpClient = axios.create();
let user = Helper.getCookie("user") ? JSON.parse(Helper.getCookie("user")) : false;

let header = {}
if (user && user._fieldsProto) {
    header.uid = user._fieldsProto.id.stringValue
}
httpClient.interceptors.request.use(async (config) => {
    config.headers = header

    return config;
},
    error => {
        Promise.reject(error)
    });
export default httpClient