import React from 'react';
import { Helper } from '../../../Helper';
import { storage } from '../../../firebase';
const axios = require('axios').default;


function ReverseButton({ bookUid, onSuccess }) {
    return (
        <>
            <button className="btn btn-default btn-sm"
                onClick={() => {
                    Helper.reactToDom(
                        window.$('body'),
                        <ReverseStatusModal
                            bookUid={bookUid}
                            onSuccess={() => {
                                if (onSuccess) onSuccess();
                            }}
                        />
                    )
                }}
            >ย้อนสถานะ</button>
        </>
    )
}

export default ReverseButton;

const statusList = [
    { id: 1, text: `ยอมรับ`, value: `accept` },
    { id: 2, text: `กำลังเดินทาง`, value: `going` },
    { id: 3, text: `ถึงแล้ว`, value: `arrive` },
    { id: 4, text: `กำลังทำงาน`, value: `working` },
]
class ReverseStatusModal extends React.Component {
    constructor() {
        super();
        this.state = {
            file_stack: [],
            selected_star: 5,
            uploaded: {
                tempImages: [],
                tempPathImages: [],
            }
        }
    }
    reverseStatus(data) {
        // console.log(data)
        if (!data.status) {
            window.$(document).Toasts('create', {
                title: 'เปลี่ยนสถานะไม่สำเร็จ',
                body: "กรุณาเลือกสถานะ",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            return;
        }
        let admin = Helper.getCookie('user') ? JSON.parse(Helper.getCookie('user')) : false;
        if (!admin?._fieldsProto?.id?.stringValue) {
            window.$(document).Toasts('create', {
                title: 'เปลี่ยนสถานะไม่สำเร็จ',
                body: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            window.location.href = window.location.href;
            return;
        }
        data.adminUid = admin._fieldsProto.id.stringValue;
        Helper.confirmModal({
            title: "แก้ไขสถานะ",
            description: "ยืนยันการแก้ไขสถานะ Booking",
            onConfirm: async () => {
                try {
                    if (this.state.file_stack.length == 0) {
                        this.callApi(data)
                    } else {
                        this.uploadImages(0, data)
                    }
                } catch (error) {
                    window.$(document).Toasts('create', {
                        title: 'เปลี่ยนสถานะไม่สำเร็จ',
                        body: "ไม่พบหมายเลข Booking นี้ในระบบ หรือเกิดข้อผิดพลาดในการส่งข้อมูล",
                        icon: 'fas fa-times',
                        autohide: true,
                        delay: 3000,
                        class: "bg-danger",
                    })
                    return;
                }
            },
            onCancel: () => {
                console.log('onCancel')
                this.$submitBtn.removeClass('loading');
                return false;
            }
        });
    }

    async uploadImages(index, data) {
        this.$submitBtn.addClass('loading');
        let setIndex = index + 1;
        let file = this.state.file_stack[index];
        const filename = Helper.setNewImageFileName(file);
        let reszieFile = await Helper.resizeImageFn(file, { maxWidth: 1280, maxHeight: 768 });
        var uploadTask = storage.ref().child('reverse_booking/' + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // console.log(snapshot);
        }, (error) => {
            console.log(error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
        }, async () => {
            let url = 'reverse_booking/' + filename;
            const photoURL = await storage.ref(url).getDownloadURL();

            let tempImages = [...this.state.uploaded.tempImages];
            let tempPathImages = [...this.state.uploaded.tempPathImages];
            tempImages.push(photoURL)
            tempPathImages.push(url);
            let uploaded = {
                tempImages: tempImages,
                tempPathImages: tempPathImages,
            }
            this.setState({
                uploaded: uploaded,
            }, () => {
                if (this.state.uploaded.tempImages.length === this.state.file_stack.length) {
                    this.callApi(data)
                }
                if (this.state.uploaded.tempImages.length < this.state.file_stack.length) {
                    this.uploadImages(setIndex, data)
                }
            })
        });
    }
    async callApi(data) {
        if (!this.$submitBtn.hasClass('loading')) this.$submitBtn.addClass('loading');
        data.images = {
            photoURLs: this.state.uploaded.tempImages,
            storagePath: this.state.uploaded.tempPathImages
        }
        let res = await axios.put(window.api_host + '/booking-reverse-status', data)
        if (res.data.code == 200) {
            window.$(document).Toasts('create', {
                title: 'เปลี่ยนสถานะสำเร็จ',
                body: 'เปลี่ยนสถานะเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.$modal.modal('hide')
            if (this.props.onSuccess) this.props.onSuccess();
        } else {
            window.$(document).Toasts('create', {
                title: 'เปลี่ยนสถานะไม่สำเร็จ',
                body: res.data.message,
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submitBtn.removeClass('loading')
        }
    }

    readURL(input) {
        if (input.files && input.files.length > 0) {
            let file_stack = [...this.state.file_stack];
            for (var key of Object.keys(input.files)) {
                file_stack.push(input.files[key]);
            }
            this.setState({
                file_stack: file_stack
            })
        }
    }
    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard="false" data-backdrop="static"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        let data = Helper.getFormInputObject(this.$searchForm);
                        this.reverseStatus(data);
                        // this.uploadImages(0, data)
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                ย้อนสถานะ
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >

                            <div className={"form-group"}>
                                <label>รูปภาพ</label>
                                <input name="image"
                                    type={"file"}
                                    ref={(ref) => this.$file = window.$(ref)}
                                    style={{ display: 'none' }}
                                    id="file_image"
                                    multiple="multiple"
                                    onChange={(e) => {
                                        this.readURL(this.$file[0]);
                                    }}
                                />
                                <div className="row" id="show_image">
                                    {
                                        this.state.file_stack
                                            && this.state.file_stack.length > 0 ?
                                            this.state.file_stack.map((file_stack, file_stack_i) => {
                                                var reader = new FileReader();
                                                reader.onload = (e) => {
                                                    window.$('#images_file_upload_' + file_stack_i).html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
                                                };
                                                reader.readAsDataURL(file_stack);
                                                return (
                                                    <div className="col-4" key={file_stack_i} >
                                                        <div className="mutiple_image_frame card">
                                                            <div className="promotion-delete">
                                                                <button className="btn btn-danger"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        let file_stack = [...this.state.file_stack];
                                                                        file_stack.splice(file_stack_i, 1);
                                                                        this.setState({
                                                                            file_stack: file_stack
                                                                        }, () => {
                                                                            this.$file.val('')
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                            <div className="card-body">
                                                                <div id={"images_file_upload_" + file_stack_i} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : null
                                    }
                                </div>
                                <label htmlFor="file_image"
                                    type={"button"}
                                    className="btn btn-default"
                                    style={{ width: '100%', marginBottom: 0 }}
                                >
                                    อัพโหลด
                                </label>
                            </div>
                            <div className={"form-group"}>
                                <label>สถานะ</label>
                                <select className="form-control" defaultValue={''} name="status">
                                    <option value="" disabled={true}>เลือกสถานะ</option>
                                    {
                                        statusList.map((item, i) => {
                                            return <option value={item.value} key={i}>{item.text}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label>ข้อความเพิ่มเติม</label>
                                <textarea className='form-control' name={"note"}></textarea>
                            </div>
                            <input type='hidden' name="bookUid" value={this.props.bookUid} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal" >ยกเลิก</button>
                            <button type="submit" className="btn btn-primary"
                                ref={(ref) => { this.$submitBtn = window.$(ref) }}
                            >
                                บันทึก
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}