import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../Helper';
import { db } from '../firebase';
// import { AmphurList } from './ProviderUsers'
import Pagination from '../Components/Pagination';
// import thailand from "./../thailand.json";
// const axios = require('axios').default;

export default class ServiceArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_loading: true,
            data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            userUID: Helper.getParameterByName('userUID') ? Helper.getParameterByName('userUID') : '',
            is_show_next_button: false,
            provinces_count: 0,
            provider_id: props.provider_id,
        }
    }

    componentDidMount() {
        this.fetchData();
        // console.log(this.state.provinces);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                userUID: Helper.getParameterByName('userUID') ? Helper.getParameterByName('userUID') : '',
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        let user = {};
        await db.doc("provider_users_data/" + this.state.provider_id).get().then(async (doc) => {
            user = { ...doc.data() };
            if (user.traningUID) {
                await db.doc("training_date/" + user.traningUID).get().then(async (doc) => {
                    user.educateDate = doc.data() && doc.data().date ? doc.data().date : new Date().getTime();
                })
            }
            if (user.tranningStatus) {
                await db.doc("training_date_status/" + user.tranningStatus).get().then(async (doc) => {
                    user.tranningStatusData = { ...doc.data() };
                })
            }
        })
        let _user = { ...user };
        let provinces_count = 0;
        if (_user.provincesCode && _user.provincesCode.length > 0) {
            provinces_count = _user.provincesCode.length;
        }
        this.setState({
            user: _user,
            is_loading: false,
            provinces_count: provinces_count
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการพื้นที่ให้บริการ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    {
                                        this.state.user ?
                                        <li className="breadcrumb-item"><Link to={"/provider-users/" + this.props.provider_id}>{this.state.user.prefix + ' ' + this.state.user.firstName + ' ' + this.state.user.lastName}</Link></li>
                                        : null
                                    }
                                    <li className="breadcrumb-item active">จัดการพื้นที่ให้บริการ</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายการจังหวัดที่ให้บริการ{this.state.user ? 'ของ ' + this.state.user.prefix + ' ' + this.state.user.firstName + ' ' + this.state.user.lastName : null}</h3>
                                <div className={"card-right"}>
                                    <div>ทั้งหมด {this.state.provinces_count} จังหวัด</div>
                                </div>
                            </div>
                            <div className="card-body">
                                {/* <div className="flex row form-group">
                                    <div>
                                        <button className={"btn btn-primary"}
                                            onClick={() => {
                                                Helper.reactToDom(
                                                    window.$('body'),
                                                    <AddServiceArea
                                                        provider_id={this.state.provider_id}
                                                        provinces={this.state.user.provincesCode}
                                                        onSuccess={(id)=> {
                                                            navigate('/provider-users/edit-service-area/' + this.state.provider_id + '/' + id)
                                                            // this.fetchData();
                                                        }}
                                                    />
                                                )
                                            }}
                                        >
                                            เพิ่มพื้นที่ให้บริการ
                                            </button>
                                    </div>
                                </div> */}
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: 200 }}>จังหวัด</th>
                                            <th>เขต/อำเภอ</th>
                                            <th style={{ width: 180 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.provinces_count > 0 ?
                                                Helper.showProvinceWithAmphoe(this.state.user.provincesCode, this.state.user.amphoesCode).map((_data, _data_i) => {
                                                    let amphoes = [];
                                                    _data.amphoes.map((_amphoe, _amphoe_i) => {
                                                        amphoes.push(_amphoe.amphoe)
                                                        return true
                                                    })
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                <div>{_data.province}</div>
                                                            </td>
                                                            <td>
                                                                <div className="tag-label">
                                                                    {
                                                                        _data.amphoes ?
                                                                            _data.amphoes.map((_amphoe, _amphoe_i) => {
                                                                                return (<div key={_amphoe_i}>{_amphoe.amphoe}</div>)
                                                                            })
                                                                            : null
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="flex row">
                                                                    <Link className="btn btn-default" to={"/provider-users/edit-service-area/" + this.state.provider_id + '/' + _data.code} >แก้ไขเขต/อำเภอ</Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.provinces_count === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.provinces_count}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}


// class AddServiceArea extends React.Component {
//     constructor() {
//         super();
//         this.state = {
//             data: [],
//             provinces_count: 0,
//             is_loading: true,
//         }
//     }

//     componentDidMount() {
//         this.fetchServiceArea();
//     }

//     async fetchServiceArea() {
//         let prepare_data = [];
//         let service_areaRef = await db.collection('/service_area').get();
//         let service_area_data = await service_areaRef.docs;
//         let service_area = await Promise.all(
//             service_area_data.map(async (service_area_data) => {
//                 let amphoe = [];
//                 let amphoeRef = await db.collection('/service_area/' + service_area_data.id + '/amphoes').get();
//                 if (amphoeRef.docs.length > 0) {
//                     amphoe = await Promise.all(
//                         amphoeRef.docs.map((amphoe_data) => {
//                             return {
//                                 ...amphoe_data.data(),
//                                 code: amphoe_data.id
//                             }
//                         })
//                     );
//                 }
//                 let provinces = false;
//                 for (const [key, value] of Object.entries(thailand)) {
//                     let amphoes = [];
//                     if (key === service_area_data.id) {
//                         for (const [_key, _value] of Object.entries(value.amphoes)) {
//                             amphoes.push({ code: _key, amphoe: _value.name.th });
//                             // console.log(`${_key}: ${_value.name.th}`);
//                         }
//                         provinces = { code: key, province: value.name.th, amphoes: amphoes }
//                         // console.log(`${key}: ${value.name.th}`);
//                     }
//                 }
//                 return {
//                     ...service_area_data.data(),
//                     code: service_area_data.id,
//                     amphoe: amphoe,
//                     provinces: provinces,
//                 }
//             })
//         );
//         let provinces_count = 0;
//         service_area.forEach((_data, _data_i) => {
//             // console.log(_data);
//             let match_search = 0;
//             if (this.state.name && this.state.name !== "") {
//                 match_search = _data.name.th.indexOf(this.state.name);
//                 if (match_search !== -1) {
//                     provinces_count++;
//                     prepare_data.push({ ..._data });
//                 }
//             } else {
//                 provinces_count++;
//                 prepare_data.push({ ..._data });
//             }
//         })
//         // console.log(prepare_data);
//         this.setState({
//             data: prepare_data,
//             provinces_count: provinces_count,
//             is_loading: false
//         })
//     }

//     async AddServiceArea(data) {
//         let provinceCode = data.provincesCode;
//         let count_provinces = 0 
//         if(this.props.provinces) {
//             count_provinces = this.props.provinces.filter((province) => { return parseFloat(province) === parseFloat(provinceCode) }).length
//         }
//         if (count_provinces > 0) {
//             console.log(this.$submit);
//             this.$submit.removeClass('loading');
//             window.$(document).Toasts('create', {
//                 title: 'มีจังหวัดที่เลือกแล้ว',
//                 body: 'จังหวัดที่เลือกได้เคยมีการบันทึกไปแล้ว',
//                 icon: 'fas fa-times',
//                 autohide: true,
//                 delay: 5000,
//                 class: "bg-danger",
//             })
//             return;
//         }
//         if(count_provinces === 0) {
//             let _data = {};
//             _data.provincesCode = [];
//             if(this.props.provinces) {
//                 _data.provincesCode = [...this.props.provinces];
//             }
//             _data.provincesCode.push(data.provincesCode);
//             await db.doc("provider_users_data/" + this.props.provider_id).update({..._data});
//             window.$(document).Toasts('create', {
//                 title: 'เพิ่มจังหวัดสำเร็จ',
//                 body: 'เพิ่มจังหวัดให้บริการสำหรับแม่บ้านท่านนี้แล้ว',
//                 icon: 'fas fa-check',
//                 autohide: true,
//                 delay: 3000,
//                 class: "bg-success",
//             })
//             this.$modal.modal('hide');
//             this.props.onSuccess(provinceCode);
//         }
//     }

//     render() {
//         return (
//             <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
//                 ref={(ref) => {
//                     this.$modal = window.$(ref);
//                     this.$modal.modal('show');
//                     this.$modal.on('hidden.bs.modal', () => {
//                         let settime = window.setTimeout(() => {
//                             this.$modal.remove();
//                             clearTimeout(settime);
//                         }, 500)
//                     });
//                     this.$form = this.$modal.find('form');
//                     this.$submit = this.$form.find('[type="submit"]');
//                 }}
//             >
//                 <form className="modal-dialog"
//                     role="document"
//                     ref={(ref) => { this.$form = window.$(ref) }}
//                     onSubmit={(e) => {
//                         e.preventDefault();
//                         if (this.$submit.hasClass('loading')) {
//                             return;
//                         }
//                         let data = Helper.getFormInputObject(this.$form);
//                         this.$submit.addClass('loading');
//                         this.AddServiceArea(data)
//                     }}
//                 >
//                     <div className="modal-content">
//                         <div className="modal-header" style={{ display: "flex", alignItems: 'center' }}>
//                             <h4 className="modal-title" id="myModalLabel">
//                                 เพิ่มพื้นที่ให้บริการ
//                             </h4>
//                             <div style={{ marginLeft: 'auto' }}>
//                                 กรุณาเลือกจังหวัด
//                             </div>
//                         </div>
//                         <div className="modal-body">
//                             {
//                                 !this.state.is_loading
//                                     && this.state.data.length === 0 ?
//                                     <tr >
//                                         <td colSpan="6" style={{ textAlign: 'center' }}>
//                                             ไม่มีข้อมูลพื้นที่ให้บริการ
//                                         </td>
//                                     </tr>
//                                     : null
//                             }
//                             {
//                                 this.state.is_loading ?
//                                     <tr >
//                                         <td colSpan="6" style={{ textAlign: 'center' }}>
//                                             <div className="ui active inline loader small" ></div>
//                                         </td>
//                                     </tr>
//                                     : null
//                             }
//                             {
//                                 !this.state.is_loading
//                                     && this.state.data.length > 0 ?
//                                     <div >
//                                         <label>เลือกจังหวัด</label>
//                                         <select
//                                             ref={(ref) => { window.$(ref).select2(); }}
//                                             className={"form-control select2"}
//                                             name={"provincesCode"}
//                                             style={{ width: '100%' }}
//                                         >
//                                             {
//                                                 this.state.data.map((_data, _data_i) => {
//                                                     return (
//                                                         <option value={_data.code} key={_data_i}>
//                                                             {_data.name.th}
//                                                         </option>
//                                                     );
//                                                 })
//                                             }
//                                         </select>
//                                     </div>
//                                     : null
//                             }

//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" className="btn btn-default"
//                                 onClick={() => {
//                                     let $submit = this.$form.find('button[type="submit"]');
//                                     $submit.removeClass('loading');
//                                     this.$modal.modal('hide');
//                                 }}
//                             >ยกเลิก</button>
//                             <button className={"btn btn-primary"} type={"submit"}>บันทึก</button>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//         );
//     }
// }