import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../Helper';
// import CustomDateInput from './../Components/CustomDateInput';
// import DatePicker from "react-datepicker";
import Pagination from './../Components/Pagination';
import { db } from '../firebase';
import { BanProviderUserForm } from './ViewProviderUsers';
import { ProvincesAndAmphursList } from './ProviderUsers';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
const axios = require('axios').default;

export default class EducateDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            data: [],
            training_date_status: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            tranningStatus: Helper.getParameterByName('tranningStatus') ? Helper.getParameterByName('tranningStatus') : '',
            is_show_next_button: false,
            educate_id: props.educate_id
        }
    }
    componentDidMount() {
        this.fetchData();
        this.fetchInfo();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                tranningStatus: Helper.getParameterByName('tranningStatus') ? Helper.getParameterByName('tranningStatus') : '',
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }
    prepareDownloadData(array) {
        let export_data = [];
        for (const _data of array) {
            let item = {
                traning_data: this.state.traning_data ? Helper.getDateThai(new Date(this.state.traning_data.date)) : null,
                prefix: _data.prefix,
                firstName: _data.firstName,
                lastName: _data.lastName,
                phoneNumber: _data.phoneNumber,
                lineID: _data.lineID,
                tranningStatusData: _data.tranningStatusData ? _data.tranningStatusData.status_name : 'ไม่มีสถานะ',
            }
            export_data.push(item);
        }
        return export_data
    }

    async getDownloadData() {
        this.downloadBtn.addClass('loading')
        let qs = {};
        qs.id = this.state.educate_id;
        qs.page = this.state.page;
        qs.limit = this.state.all_items;
        let res = await axios.get(`${window.api_host}/tranning-date-detail?${window.$.param(qs)}`);
        console.log(res)
        let export_data = [];
        if (res.data.code === 200) {
            export_data = this.prepareDownloadData(res.data.data)
        }
        this.setState({
            export_data: export_data
        }, () => {
            this._export.save(this.state.export_data);
            this.downloadBtn.removeClass('loading')
        })
    }

    async fetchInfo() {


        let provider_all = 0;
        let provider_wait_for_approve = 0;
        let provider_approved = 0;
        let provider_avaliable = 0;
        let provider_ban = 0;

        let participants = db.collection("training_date/" + this.props.educate_id + '/participants');
        let participants_data = await participants.get();
        for (const doc of participants_data.docs) {
            // let _doc_data = false;
            let provider_user = await db.doc("provider_users_data/" + doc.data().providerUid).get();

            if (provider_user.exists) {
                provider_all++;
                if (provider_user.data().status === 'waitForApprove') {
                    provider_wait_for_approve++;
                }
                if (provider_user.data().status === 'approved') {
                    provider_approved++;
                }
                if (provider_user.data().status === 'avaliable') {
                    provider_avaliable++;
                }
                if (provider_user.data().status === 'ban') {
                    provider_ban++;
                }
            }
        }
        this.setState({
            provider_all: provider_all,
            provider_wait_for_approve: provider_wait_for_approve,
            provider_approved: provider_approved,
            provider_avaliable: provider_avaliable,
            provider_ban: provider_ban,
        })
    }
    async fetchData() {
        let qs = {};
        qs.id = this.state.educate_id;
        qs.page = this.state.page;
        qs.limit = this.state.limit;
        if(this.state.status) {
            qs.status = this.state.status;
        }
        if(this.state.tranningStatus) {
            qs.tranningStatus = this.state.tranningStatus;
        }
        if(this.state.name) {
            qs.name = this.state.name;
        }
        let res = await axios.get(`${window.api_host}/tranning-date-detail?${window.$.param(qs)}`);
        // console.log(res);
        let traning = db.doc("training_date/" + qs.id);
        let traning_data = await traning.get();
        let _tranning_data = false;
        if (traning_data.exists) {
            _tranning_data = traning_data.data();
        }
        let training_date_status = await db.collection('training_date_status').get();
        let _training_date_status = await Promise.all(
            training_date_status.docs.map(async (element) => {
                let element_data = { ...element.data() };
                return {
                    ...element_data,
                    id: element.id,
                };

            })
        );

        let count = 0;
        let data = [];

        if (res.data.code === 200) {
            data = res.data.data;
            count = res.data.count_all
        }

        this.setState({
            traning_data: _tranning_data,
            data: data,
            is_loading: false,
            all_items: count,
            training_date_status: _training_date_status
        })
        return;

    }



    delete(data) {
        let documentRef = db.doc(`training_date/` + data.id);
        documentRef.delete().then(() => {
            this.fetchData();
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
        });
    }

    async updateStatus(id, data) {
        console.log("updateStatus");
        await db.doc("provider_users_data/" + id).update({ ...data }).then(() => {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                body: 'เปลี่ยนสถานะสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.fetchData();
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ข้อมูลวันอบรม</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/educate-date">วันอบรม</Link></li>
                                    <li className="breadcrumb-item active">ข้อมูลวันอบรม</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">วันอบรม {this.state.traning_data ? Helper.getDateThai(new Date(this.state.traning_data.date)) : null}</h3>
                                <div className={"card-right"}>
                                    <div style={{ marginRight: 10 }}>ทั้งหมด {this.state.provider_all} คน</div>
                                    <div style={{ marginLeft: 5 }}><i className={"fas fa-circle"} style={{ color: '#ffa200' }} /> {this.state.provider_wait_for_approve}</div>
                                    <div style={{ marginLeft: 5 }}><i className={"fas fa-circle"} style={{ color: 'blue' }} /> {this.state.provider_approved}</div>
                                    <div style={{ marginLeft: 5 }}><i className={"fas fa-circle"} style={{ color: 'green' }} /> {this.state.provider_avaliable}</div>
                                    <div style={{ marginLeft: 5 }}><i className={"fas fa-circle"} style={{ color: 'red' }} /> {this.state.provider_ban}</div>
                                </div>
                                <div className="card-tools">
                                    <form className="input-group input-group-sm" ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                page: 1,
                                                name: data.name,
                                                status: data.status,
                                                tranningStatus: data.tranningStatus,
                                            })
                                            navigate(url)
                                        }}
                                    >
                                        <select
                                            className="form-control placeholder_font"
                                            name="tranningStatus"
                                            defaultValue={this.state.tranningStatus}
                                            onChange={() => {
                                                this.$searchForm.find('[type="submit"]').trigger('click');
                                            }}
                                        >
                                            <option value="">ค้นหาจากสถานะอบรม</option>
                                            {
                                                this.state.training_date_status.map((training_date_status, training_date_status_i) => (
                                                    <option value={training_date_status.id} key={training_date_status_i}>{training_date_status.status_name}</option>
                                                ))
                                            }

                                        </select>
                                        <select
                                            className="form-control placeholder_font"
                                            name="status"
                                            defaultValue={this.state.status}
                                            onChange={() => {
                                                this.$searchForm.find('[type="submit"]').trigger('click');
                                            }}
                                        >
                                            <option value="">ค้นหาจากสถานะ</option>
                                            <option value="waitForApprove">รอการยืนยัน</option>
                                            <option value="approved">ยืนยันแล้ว</option>
                                            <option value="avaliable">ใช้งานได้ </option>
                                            <option value="ban">ระงับการใช้งาน</option>
                                        </select>
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากชื่อ" defaultValue={this.state.name} />

                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className={"flex row"}>
                                    <div>
                                        <div>55/632 โครงการสุโขทัยอเวนิว 99 ปากเกร็ด นนทบุรี 11120</div>
                                        <div>
                                            <button type="button"
                                                className={this.state.is_loading ? "btn btn-default loading" : "btn btn-default"}
                                                ref={(ref) => {
                                                    this.downloadBtn = window.$(ref);
                                                    this.downloadBtn.off().on('click', () => {
                                                        if (this.state.data.length <= 0 || this.downloadBtn.hasClass('loading')) { return false }
                                                        this.getDownloadData();
                                                    })
                                                }}
                                                disabled={this.state.data.length <= 0}
                                            >
                                                <i className="far fa-file-excel"></i> ดาวน์โหลดรายงานผู้เข้าอบรม
                                                </button>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
                                        ธนาคารกสิกรไทย เลขบัญชี 063-1-41389-7<br />
                                        บจก. วี ดีเวลลอป สาขาโลตัส วังหิน
                                    </div>
                                </div>
                                <ExcelExport
                                    data={this.state.export_data}
                                    ref={exporter => this._export = exporter}
                                    fileName={this.state.traning_data ? "รายงานผู้เข้าอบรม" + Helper.getDateThai(new Date(this.state.traning_data.date)) : "รายงานผู้เข้าอบรม"}
                                >
                                    <ExcelExportColumn field="traning_data" title="วันที่อบรม" width={130} />
                                    <ExcelExportColumn field="prefix" title="คำนำหน้า" width={80} />
                                    <ExcelExportColumn field="firstName" title="ชื่อ" width={130} />
                                    <ExcelExportColumn field="lastName" title="นามสกุล" width={130} />
                                    <ExcelExportColumn field="phoneNumber" title="เบอร์โทรศัพท์" width={150} />
                                    <ExcelExportColumn field="lineID" title="LINE ID" width={150} />
                                    <ExcelExportColumn field="tranningStatusData" title="สถานะการอบรม" width={150} />
                                </ExcelExport>
                                <table className="table table-bordered" style={{ marginTop: 15 }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "15%", minWidth: 230 }}>ชื่อ</th>
                                            <th>เขต</th>
                                            <th style={{ minWidth: 150 }}>ข้อมูลการติดต่อ</th>
                                            <th style={{ minWidth: 150 }}>ข้อมูลอบรม</th>
                                            <th style={{ minWidth: 130 }}>สถานะ App</th>
                                            <th style={{ minWidth: 60 }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {_data.gender === "ชาย" ? <i className="fas fa-mars"></i> : null}
                                                                {_data.gender === "หญิง" ? <i className="fas fa-venus"></i> : null}
                                                                {' '}
                                                                {_data.prefix} {_data.firstName} {_data.lastName}<br />
                                                                <i className="fas fa-star" style={{ color: '#ffa200' }}></i> 5.0
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.provincesCode && _data.amphoesCode
                                                                        && _data.provincesCode.length > 0 && _data.amphoesCode.length > 0 ?
                                                                        <>
                                                                            {
                                                                                Helper.showProvinceWithAmphoe(_data.provincesCode, _data.amphoesCode).map((pv, pv_i) => {
                                                                                    return (
                                                                                        <React.Fragment key={pv_i}>
                                                                                            {pv_i > 0 ? ", " : null}
                                                                                            {pv.province}
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <div>
                                                                                <div className={"btn btn-default btn-sm"}
                                                                                    onClick={() => {
                                                                                        Helper.reactToDom(window.$('body'),
                                                                                            <ProvincesAndAmphursList
                                                                                                provinces={Helper.showProvinceWithAmphoe(_data.provincesCode, _data.amphoesCode)}
                                                                                            />
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    ดูข้อมูลพื้นที่ให้บริการ
                                                                            </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </td>
                                                            <td>
                                                                <i className="fas fa-phone"></i> {_data.phoneNumber}
                                                                {
                                                                    _data.lineID ?
                                                                        <div><i className="fab fa-line"></i> {_data.lineID}</div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className={"flex row"}>
                                                                    <div>{_data.tranningStatusData ? _data.tranningStatusData.status_name : 'ไม่มีสถานะ'}</div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        <div className="dropdown">
                                                                            <button className="btn btn-default btn-xs dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <i className="far fa-edit"></i>
                                                                            </button>
                                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                {
                                                                                    this.state.training_date_status.map((training_date_status, training_date_status_i) => {
                                                                                        if (training_date_status.id === _data.tranningStatus) { return null }
                                                                                        return (
                                                                                            <div className="dropdown-item"
                                                                                                style={{ cursor: 'pointer' }}
                                                                                                key={training_date_status_i}
                                                                                                onClick={() => {
                                                                                                    let update = { tranningStatus: training_date_status.id }
                                                                                                    if (_data.updateBy) {
                                                                                                        update.updateBy = [..._data.updateBy];
                                                                                                    }
                                                                                                    update = Helper.providerUpdateData(update, this.props.admin, { updateType: 'tranningStatus' })
                                                                                                    this.updateStatus(_data.id, update);
                                                                                                }}
                                                                                            >
                                                                                                {training_date_status.status_name}
                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {_data.educateDate ? <div>{Helper.getDateThai(Helper.getDateToEdit(_data.educateDate))}</div> : '-'}
                                                            </td>
                                                            <td>
                                                                <div className={"flex row"}>
                                                                    <div>

                                                                        {
                                                                            _data.status === 'waitForApprove' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: '#ffa200' }} /> รอการยืนยัน</>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === 'approved' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: 'blue' }} /> ยืนยันแล้ว</>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === 'avaliable' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: 'green' }} /> ใช้งานได้</>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === 'ban' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: 'red' }} /> ระงับการใช้งาน</>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {
                                                                            _data.status !== 'cancel' ?
                                                                                <div style={{ marginLeft: 'auto' }}>
                                                                                    <div className="dropdown">
                                                                                        <button className="btn btn-default btn-xs dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                            <i className="far fa-edit"></i>
                                                                                        </button>
                                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                            {
                                                                                                _data.status === 'waitForApprove' ?
                                                                                                    null
                                                                                                    :
                                                                                                    <div className="dropdown-item" style={{ cursor: 'pointer' }}
                                                                                                        onClick={() => {
                                                                                                            let update = { status: "waitForApprove" }
                                                                                                            if (_data.updateBy) {
                                                                                                                update.updateBy = [..._data.updateBy];
                                                                                                            }
                                                                                                            // console.log(update);
                                                                                                            update = Helper.providerUpdateData(update, this.props.admin, { updateType: 'status' })
                                                                                                            // console.log(update);
                                                                                                            this.updateStatus(_data.id, update);
                                                                                                        }}
                                                                                                    ><i className={"fas fa-circle"} style={{ color: '#ffa200' }} /> รอการยืนยัน</div>
                                                                                            }
                                                                                            {
                                                                                                _data.status === 'approved' ?
                                                                                                    null
                                                                                                    :
                                                                                                    <div className="dropdown-item" style={{ cursor: 'pointer' }}
                                                                                                        onClick={() => {
                                                                                                            let update = { status: "approved" }
                                                                                                            if (_data.updateBy) {
                                                                                                                update.updateBy = [..._data.updateBy];
                                                                                                            }
                                                                                                            update = Helper.providerUpdateData(update, this.props.admin, { updateType: 'status' })
                                                                                                            this.updateStatus(_data.id, update);
                                                                                                        }}
                                                                                                    ><i className={"fas fa-circle"} style={{ color: 'blue' }} /> ยืนยันแล้ว</div>
                                                                                            }
                                                                                            {
                                                                                                _data.status === 'avaliable' ?
                                                                                                    null
                                                                                                    :
                                                                                                    <div className="dropdown-item" style={{ cursor: 'pointer' }}
                                                                                                        onClick={() => {
                                                                                                            let update = { status: "avaliable" }
                                                                                                            if (_data.updateBy) {
                                                                                                                update.updateBy = [..._data.updateBy];
                                                                                                            }
                                                                                                            update = Helper.providerUpdateData(update, this.props.admin, { updateType: 'status' })
                                                                                                            this.updateStatus(_data.id, update);
                                                                                                        }}
                                                                                                    ><i className={"fas fa-circle"} style={{ color: 'green' }} /> ใช้งานได้</div>
                                                                                            }
                                                                                            {
                                                                                                _data.status === 'ban' ?
                                                                                                    null
                                                                                                    :
                                                                                                    <div className="dropdown-item" style={{ cursor: 'pointer' }}
                                                                                                        onClick={() => {
                                                                                                            Helper.reactToDom(window.$('body'),
                                                                                                                <BanProviderUserForm
                                                                                                                    user={_data}
                                                                                                                    admin={this.props.admin}
                                                                                                                    onSuccess={() => {
                                                                                                                        this.fetchData()
                                                                                                                    }}
                                                                                                                    userUID={_data.id}
                                                                                                                />
                                                                                                            )
                                                                                                        }}
                                                                                                    ><i className={"fas fa-circle"} style={{ color: 'red' }} /> ระงับการใช้งาน</div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <Link to={'/provider-users/' + _data.providerUid} type="button" className="btn btn-default">ดูข้อมูล</Link>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="5" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
