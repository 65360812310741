import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../Helper';
import DatePicker from 'react-datepicker';
import CustomDateInput from './../Components/CustomDateInput';
import ReportProviderWallet from '../Components/ReportProviderWallet';
import { db } from './../firebase';
import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { orderBy } from '@progress/kendo-data-query';
import BankCode from './../bankCode';
import '@progress/kendo-theme-default/dist/all.css';
const axios = require('axios').default;
const prepareReportData = (defaultData) => {
    let data = { ...defaultData }
    data.serviceAt = Helper.getDateNumber(defaultData.bookedAt)
    data.serviceTime = Helper.getTime(defaultData.bookedAt);
    data.providerDisplayname = data.providerUser && data.providerUser.displayName ? data.providerUser.displayName : null;
    data.providerUser.bankAccountNo = defaultData.providerUser?.bankAccountNo ? defaultData.providerUser.bankAccountNo.replaceAll('-', "") : ''
    data.providerUser.personalNo = defaultData.providerUser?.personalNo ? defaultData.providerUser.personalNo.replaceAll(' ', "") : ''
    data.bookUid = defaultData.id;
    data.providerUserUid = defaultData.providerUserUid;
    return data
}
let abort
const defaultParams = () => {
    let defaultStatus = 'finish';
    let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
    let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
    let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : false;
    if (!_sortField) {
        if (type == 'all') {
            if (booking_type && booking_type == 'admin_created') {
                defaultStatus = 'all'
            }
        }
        if (type == 'user') {
            defaultStatus = 'all'
        }

    }
    let params = {
        isClearDevAccount: Helper.getParameterByName('isClearDevAccount') && Helper.getParameterByName('isClearDevAccount') == `false` ? false : true,
        render_start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
        render_end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
        start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
        end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
        booking_type: Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : `all`,
        status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : defaultStatus,
        finishedFilter: Helper.getParameterByName('finishedFilter') ? Helper.getParameterByName('finishedFilter') : 'all'
    }
    return params;
}
export default class BookingReport extends React.Component {
    constructor() {
        super();
        let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
        let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
        let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : false;
        let sortField = _sortField || "bookedAt";
        if (!_sortField) {
            if (type == 'all') {
                if (booking_type && booking_type == 'admin_created') {
                    sortField = 'createdAt';
                }
            }
            if (type == 'user') {
                sortField = 'createdAt';
            }

        }
        let sort_data = {
            sortField: sortField,
            // sortField: "bookedAt",
            sortDir: 'desc',
            sort: [{ field: 'bookedAt', dir: 'desc' }]
        };
        // if (type === 'all') {
        //     sort_data = {
        //         sortField: 'createdAt',
        //         sortDir: 'desc',
        //         sort: [{ field: 'createdAt', dir: 'desc' }]
        //     }
        // }
        this.state = {
            data: [],
            items_response: [],
            transfer_data: false,
            revenue_data: false,
            is_loading: true,
            type: type,
            columnSort: 'providerName',
            ...defaultParams(),
            ...sort_data
        }
    }

    componentDidUpdate(prevProps) {
        let defaultStatus = 'done';
        let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
        let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
        let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : false;
        let sortField = _sortField || "bookedAt";
        if (!_sortField) {
            if (
                (type == 'all' && (booking_type && booking_type == 'admin_created'))
                || type == 'user'
            ) {
                sortField = 'createdAt';
                defaultStatus = 'all'
            }

        }
        let sort_data = {
            sortField: sortField,
            // sortField: "bookedAt",
            sortDir: 'desc',
            sort: [{ field: 'bookedAt', dir: 'desc' }]
        };
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                data: [],
                items_response: [],
                transfer_data: false,
                revenue_data: false,
                is_loading: true,
                ...defaultParams(),
                ...sort_data
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                data: [],
                items_response: [],
                transfer_data: false,
                revenue_data: false,
                is_loading: true,
                ...defaultParams(),
                ...sort_data
            }, () => {
                this.fetchData();
            })
        }
    }

    async getRevenueReport() {
        let revenue_data = [];
        for (const doc of this.state.data) {
            // if (doc.providerSalary) { continue; }
            // if (parseFloat(doc.transferPriceRevenue) <= 0) { continue; }
            let data = prepareReportData(doc);
            revenue_data.push(data);

        }
        revenue_data = revenue_data.sort(function (a, b) {
            if (a.bookedAt < b.bookedAt) {
                return 1;
            }
            if (a.bookedAt > b.bookedAt) {
                return -1;
            }
            return 0;
        });
        revenue_data = revenue_data.sort(function (a, b) {
            if (a.providerDisplayname < b.providerDisplayname) {
                return -1;
            }
            if (a.providerDisplayname > b.providerDisplayname) {
                return 1;
            }
            return 0;
        });
        let summaryTotalPriceProvider = 0;
        let summaryTotalrentOutOfAppPrice = 0;
        let summaryDeductions = 0;
        let summarySpecialPrice = 0;
        let summaryTransferPrice = 0;
        let allSummaryPriceProvider = 0;
        let allSummaryVatPrice = 0;
        let allSummaryTotalPrice = 0;


        // revenue_data.sort(function (a, b) {
        //     if (a.bookedAt < b.bookedAt) {
        //         return -1;
        //     }
        //     if (a.bookedAt > b.bookedAt) {
        //         return 1;
        //     }
        //     return 0;
        // });
        // revenue_data.sort(function (a, b) {
        //     if (a.providerDisplayname < b.providerDisplayname) {
        //         return -1;
        //     }
        //     if (a.providerDisplayname > b.providerDisplayname) {
        //         return 1;
        //     }
        //     return 0;
        // });
        let i = 0;
        let currentData = {
            name: false,
            totalPriceProvider: 0,
        };
        const vatPercentage = 3;
        for (const r_data of revenue_data) {
            if (!currentData.name) {
                currentData.name = r_data.providerDisplayname;
            }
            if (r_data?.deductions
                && parseFloat(r_data?.deductions) > 0
                && parseFloat(r_data.transferPrice) == 0
            ) {
                r_data.transferPrice -= parseFloat(r_data?.deductions);
            }
            if (currentData?.name == r_data.providerDisplayname) {
                currentData.totalPriceProvider += parseFloat(r_data.transferPrice);
            }
            if (currentData?.name != r_data.providerDisplayname) {
                let totalPriceProvider = parseFloat(currentData.totalPriceProvider)
                let totalPriceVat = parseFloat((currentData.totalPriceProvider * vatPercentage) / 100)
                if (parseFloat(totalPriceProvider) < 1000) {
                    totalPriceVat = 0
                }
                revenue_data[i - 1].summaryPriceProvider = totalPriceProvider;
                revenue_data[i - 1].summaryVatPrice = totalPriceVat;
                revenue_data[i - 1].summaryTotalPrice = totalPriceProvider - totalPriceVat;
                allSummaryPriceProvider += totalPriceProvider || 0
                allSummaryVatPrice += totalPriceVat || 0
                allSummaryTotalPrice += (totalPriceProvider - totalPriceVat) || 0
                currentData = {
                    name: r_data.providerDisplayname,
                    totalPriceProvider: parseFloat(r_data.transferPrice),
                };
                // if (r_data.transferPrice < 0) {
                //     r_data.transferPrice = 0;
                // }
            }
            // console.log(r_data.providerDisplayname);
            i++
            if (i == revenue_data.length) {
                let totalPriceProvider = parseFloat(currentData.totalPriceProvider)
                let totalPriceVat = parseFloat((currentData.totalPriceProvider * vatPercentage) / 100)
                if (parseFloat(totalPriceProvider) < 1000) {
                    totalPriceVat = 0
                }
                revenue_data[i - 1].summaryPriceProvider = totalPriceProvider;
                revenue_data[i - 1].summaryVatPrice = totalPriceVat;
                revenue_data[i - 1].summaryTotalPrice = totalPriceProvider - totalPriceVat;
                allSummaryPriceProvider += totalPriceProvider || 0
                allSummaryVatPrice += totalPriceVat || 0
                allSummaryTotalPrice += (totalPriceProvider - totalPriceVat) || 0
            }
            summaryTotalPriceProvider += parseFloat(r_data.transferPrice)
            summaryTotalrentOutOfAppPrice += parseFloat(r_data.rentOutOfAppPrice)
            summaryDeductions += parseFloat(r_data.deductions)
            summarySpecialPrice += parseFloat(r_data.specialPrice)
            summaryTransferPrice += parseFloat(r_data.transferPrice)

        }
        revenue_data.push({
            totalPriceProvider: summaryTotalPriceProvider,
            rentOutOfAppPrice: summaryTotalrentOutOfAppPrice,
            deductions: summaryDeductions,
            specialPrice: summarySpecialPrice,
            transferPrice: summaryTransferPrice,
            summaryPriceProvider: allSummaryPriceProvider,
            summaryVatPrice: allSummaryVatPrice,
            summaryTotalPrice: allSummaryTotalPrice,
        });

        this.setState({
            revenue_data: revenue_data,
        }, () => {
            if (this.state.revenue_data.length <= 1) {
                window.$(document).Toasts('create', {
                    title: 'ไม่มีพบข้อมูลรายงานสำหรับส่งสรรพากร',
                    body: 'ทำการโอนเงินทั้งหมดแล้ว',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                return;
            }
            if (this._export_revenue) {
                this._export_revenue.save()
            }
        });
        if (this.$revenueReport) {
            this.$revenueReport.removeClass("loading");
        }
    }

    async getReportForTransfer() {
        let transfer_data = [];
        for (const doc of this.state.data) {
            if (doc.bookOn == '13082023Skh7I') {
                console.log(doc)
            }
            if (doc.providerSalary) { continue; }
            let data = prepareReportData(doc);
            if (doc.isPlustime) {
                delete data.deductionsNote;
                delete data.specialPriceNote;
            }
            transfer_data.push(data);
        }

        transfer_data.sort(function (a, b) {
            if (a.bookOn < b.bookOn) {
                return 1;
            }
            if (a.bookOn > b.bookOn) {
                return -1;
            }
            return 0;
        });
        transfer_data = transfer_data.filter((e) => e.bookStatus == 'finish' || e.bookStatus == 'done');
        this.setState({
            transfer_data: transfer_data,
        }, () => {
            if (this.state.transfer_data.length <= 0) {
                window.$(document).Toasts('create', {
                    title: 'ไม่มีพบข้อมูลรายงานสำหรับโอนเงิน ',
                    body: 'ทำการโอนเงินทั้งหมดแล้ว',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                return;
            }
            if (this._export_transfer) {
                this._export_transfer.save()
            }
        });
        if (this.$transferReport) {
            this.$transferReport.removeClass("loading");
        }

    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        // console.log(this.state.sortField, this.state.sortDir);
        let qs = {}
        qs.sortField = this.state.sortField;
        qs.sortDir = this.state.sortDir;
        // qs.start_date = new Date(parseFloat(this.state.start_date));
        // qs.start_date.setHours(0, 0, 1);
        // qs.end_date = new Date(parseFloat(this.state.end_date));
        // qs.end_date.setHours(23, 59, 0);
        // qs.start_date = qs.start_date.getTime() + (7 * 60 * 60 * 1000);
        // qs.end_date = qs.end_date.getTime() + (7 * 60 * 60 * 1000);
        // qs.start_date = qs.start_date.getTime();
        // qs.end_date = qs.end_date.getTime();
        let start_date = new Date(parseFloat(this.state.start_date));
        let end_date = new Date(parseFloat(this.state.end_date));
        qs.start_date = `${start_date.getDate()}-${start_date.getMonth()}-${start_date.getFullYear()}`;
        qs.end_date = `${end_date.getDate()}-${end_date.getMonth()}-${end_date.getFullYear()}`;
        qs.type = this.state.type;
        qs.status = this.state.status;
        qs.booking_type = this.state.booking_type;
        qs.adminUid = this.props.adminUid;
        // console.log(qs)
        try {
            let res = await axios.get(`${window.api_host}/booking-report?${window.$.param(qs)}`);
            // console.log(res);
            let _data = [];
            let count = 0;
            let provider_all = 0;
            let repeated_count = 0;
            let count_plusTime = 0;
            if (res.data.success) {
                for (const data of res.data.data) {
                    if (this.state.isClearDevAccount && data.providerUser.devAccount) {
                        continue;
                    }
                    let prepare = {};
                    let summary = Helper.bookingSummary({ booking: data, payment: data.payment });

                    prepare.price = (parseFloat(data.price))
                    prepare.plustime_total_price = parseFloat(0);
                    data.bookedAtDate = Helper.getDateNumber(data.bookedAt);
                    data.bookedAtDateText = Helper.getDateNumber(data.bookedAt);
                    data.bookedAtDateTime = Helper.getTime(data.bookedAt);
                    // prepare.priceApp = (parseFloat(data.priceApp) - parseFloat(summary.tax_total_price));
                    prepare.priceApp = !data.changeBookPrice && data.priceAppWithoutAgent ? parseFloat(data.priceAppWithoutAgent) : data.priceApp ? parseFloat(data.priceApp) : 0;
                    prepare.priceDiscount = parseFloat(data.priceDiscount) > parseFloat(data.price) ? parseFloat(data.price) : parseFloat(data.priceDiscount);
                    prepare.totalPriceProvider = parseFloat(data.priceProvider) > 0 ? parseFloat(data.priceProvider) : (350);
                    prepare.chargePrice = parseFloat(data.chargePrice) <= 0 ? 0 : parseFloat(data.chargePrice);
                    if (prepare.chargePrice < 0) {
                        prepare.chargePrice = 0;
                    }
                    prepare.priceAgentCommissionFromProvider = parseFloat(data.priceAgentCommissionFromProvider) || 0;
                    prepare.priceAgentCommissionFromApp = parseFloat(data.priceAgentCommissionFromApp) || 0;
                    prepare.priceProvider = !data.changeBookPrice && parseFloat(data.priceProviderWithoutAgent) ? parseFloat(data.priceProviderWithoutAgent) : parseFloat(data.priceProvider) > 0 ? parseFloat(data.priceProvider) : (350);
                    prepare.totalUserPrice = ((parseFloat(data.price) + parseFloat(prepare.chargePrice)) - parseFloat(prepare.priceDiscount)).toFixed(2)
                    prepare.priceUserAfterDiscount = (parseFloat(prepare.price) - parseFloat(prepare.priceDiscount));
                    let work_time = parseFloat(data.bookTime.time);
                    let _plusTime = 0;
                    let priceProviderPlusTime = 0;
                    prepare.priceProviderPlusTime = priceProviderPlusTime;
                    prepare.plusTime = _plusTime;
                    prepare.bookTime = work_time;

                    if (this.state.type == 'all') {
                        prepare.priceApp = prepare.price - prepare.priceDiscount - prepare.totalPriceProvider;
                    }
                    prepare.totalPriceApp = ((parseFloat(prepare.priceApp) + parseFloat(prepare.chargePrice)) - prepare.priceAgentCommissionFromApp).toFixed(2);
                    prepare.providerUser = data.providerUser;
                    if (data.providerUser && data.providerUser.bank) {
                        let getBank = BankCode.filter((e) => e.name.indexOf(data.providerUser.bank) != -1)[0];
                        if (getBank) {
                            data.providerUser.bankCode = getBank.code;
                        }
                        prepare.providerUser.bankAccountNo = data.providerUser.bankAccountNo.replaceAll('-', '').replaceAll(' ', '').trim()
                    }
                    if (data.providerUser) {
                        prepare.providerUser.displayName = data.providerUser.firstName ? `${data.providerUser.firstName} ${data.providerUser.lastName}` : data.providerUser.displayName

                    }
                    prepare.providerDisplayname = prepare.providerUser?.displayName || null;
                    prepare.paymentMethod = ""
                    if (data.payment) {
                        if (data.payment.paymentBy == 'บัตรเครดิต') {
                            prepare.paymentMethod = "บัตรเครดิต";
                        }
                    }
                    if (data.type && data.type == 'wegate') {
                        if (data.paymentStatus == 'success') {
                            prepare.payment = {
                                statusText: 'สำเร็จ',
                            }
                        }
                        let channel = data.channel;
                        prepare.payment = { ...prepare.payment }
                        if (channel == 'PP') {
                            prepare.payment.paymentBy = "Ksher (PromptPay)";
                            prepare.paymentMethod = "Ksher (PromptPay)";
                        }
                        if (channel == 'TM') {
                            prepare.payment.paymentBy = "Ksher (True Money)";
                            prepare.paymentMethod = "Ksher (True Money)";
                        }
                        if (channel == 'AL') {
                            prepare.payment.paymentBy = "Ksher (Alipay)";
                            prepare.paymentMethod = "Ksher (Alipay)";
                        }
                        if (channel == 'SP') {
                            prepare.payment.paymentBy = "Ksher (ShopeePay)";
                            prepare.paymentMethod = "Ksher (ShopeePay)";
                        }
                        if (channel == 'LP') {
                            prepare.payment.paymentBy = "Ksher (LINE Pay)";
                            prepare.paymentMethod = "Ksher (LINE Pay)";
                        }
                        if (channel == 'WC') {
                            prepare.payment.paymentBy = "Ksher (WeChat Pay)";
                            prepare.paymentMethod = "Ksher (WeChat Pay)";
                        }
                    }
                    prepare.bookPaymentInfo = "แอพพลิเคชั่น";
                    prepare.totalUserPrice = parseFloat(prepare.totalUserPrice)
                    prepare.totalPriceApp = parseFloat(prepare.totalPriceApp)
                    if (!data.user_data) {
                        prepare.user_data = {
                            displayName: data.user.displayName,
                            phoneNumber: data.user.guestPhoneNumber,
                        }
                    }
                    if (data.createdAdmin) {
                        prepare.paymentMethod = "โอนเงิน";
                        prepare.bookPaymentInfo = "ระบบหลังบ้าน";
                        prepare.payment = {
                            statusText: 'สำเร็จ',
                            paymentBy: 'โอนเงิน'
                        }
                    }
                    prepare.bookAt = Helper.getDateNumber(data.createdAt);
                    prepare.deductions = 0;
                    prepare.specialPrice = 0;
                    prepare.adjustUser = 0;
                    prepare.adjustUserMinus = 0;
                    prepare.adjustVat = 0;
                    let deductionsNotes = [];
                    let specialPriceNotes = [];
                    if (data.adjustSalary && data.adjustSalary.length > 0) {
                        for (const adj of data.adjustSalary) {
                            if (adj.providerUserUid == data.providerUserUid) {
                                if (adj.type == 'withhold_wallet') {
                                    prepare.deductions += parseFloat(adj.amount)
                                    if (adj.adjustType == 'customer') {
                                        prepare.adjustUser -= parseFloat(adj.amount)
                                        prepare.adjustUser += parseFloat(adj.amount)
                                    }
                                    if (adj.reason) {
                                        deductionsNotes.push(adj.reason)
                                    }
                                } else {
                                    prepare.specialPrice += parseFloat(adj.amount)
                                    if (adj.adjustType == 'customer') {
                                        prepare.adjustUser += parseFloat(adj.amount)
                                    }
                                    if (adj.reason) {
                                        specialPriceNotes.push(adj.reason)
                                    }
                                }
                                if (adj.adjustType == 'customer' && adj.vat) {
                                    prepare.adjustVat += parseFloat(adj.vat)
                                }
                            }
                        }
                    }
                    if (data.bookOn == '09072023ZYJaQ') {
                        console.log(prepare)
                    }
                    prepare.deductionsNote = deductionsNotes.join()
                    prepare.specialPriceNote = specialPriceNotes.join()

                    prepare.rentOutOfAppPrice = 0;
                    // Urgent calculate
                    prepare.priceUrgent = parseFloat(data.priceUrgent) || 0;
                    prepare.priceUrgentApp = data.priceUrgentApp && (data.bookStatus == 'done' || data.bookStatus == 'finish') ? parseFloat(data.priceUrgentApp) : 0;
                    prepare.priceUrgentProvider = data.priceUrgentProvider && (data.bookStatus == 'done' || data.bookStatus == 'finish') ? parseFloat(data.priceUrgentProvider) : 0;
                    prepare.priceUrgentVat = data.priceUrgentVat && (data.bookStatus == 'done' || data.bookStatus == 'finish') ? parseFloat(data.priceUrgentVat) : 0;
                    prepare.priceUrgentVatUser = data.priceUrgentVat ? parseFloat(data.priceUrgentVat) : 0;
                    prepare.priceUrgentSummaryApp = prepare.priceUrgentApp + prepare.priceUrgentVat;
                    prepare.totalPriceUrgent = prepare.priceUrgent + prepare.priceUrgentVat;
                    prepare.totalPriceUrgentProvider = prepare.priceUrgentProvider;
                    prepare.totalPriceUrgentApp = prepare.priceUrgentApp + prepare.priceUrgentVat;

                    prepare.totalPriceApp += prepare.totalPriceUrgentApp
                    prepare.totalPriceApp += prepare.adjustVat

                    prepare.totalUserPrice += prepare.adjustUser;
                    prepare.totalUserPrice += prepare.adjustVat;
                    prepare.totalUserPrice += prepare.priceUrgent;
                    prepare.totalUserPrice += prepare.priceUrgentVat;

                    if (data.paymentStatus == 'refund') {
                        prepare.priceProvider = 0;
                        prepare.totalPriceProvider = 0;
                        prepare.transferPrice = 0;
                    }
                    // prepare.transferPrice = (parseFloat(prepare.totalPriceProvider) - parseFloat(prepare.deductions)) + parseFloat(prepare.specialPrice);
                    // if (data.bookOn == "13082023Skh7I") {
                    //     console.log(`data`, data)
                    //     console.log(`prepare`, prepare)
                    // }
                    // prepare.totalPriceProvider = parseFloat(prepare.totalPriceProvider) + parseFloat(prepare.totalPriceUrgentProvider)
                    prepare.totalPriceProvider = parseFloat(prepare.totalPriceProvider);

                    prepare.transferPrice = (((parseFloat(prepare.totalPriceProvider) - parseFloat(prepare.deductions))) + parseFloat(prepare.specialPrice)) + parseFloat(prepare.totalPriceUrgentProvider);
                    // สูตรรวมที่ต้องโอนของแม่บ้าน ((แม่บ้านได้รับ - คอมมิชชั่น) - หักเงิน + เงินพิเศษ) + เร่งด่วน
                    prepare.transferPriceRevenue = prepare.transferPrice;
                    if (prepare.transferPrice < 0) {
                        prepare.transferPrice = 0;
                    }
                    let count_bookNo = _data.filter((e) => { return e.bookOn == data.bookOn });
                    // console.log("count_bookNo",count_bookNo)
                    if (count_bookNo.length > 0) {
                        repeated_count++;
                        // console.log("ซ้ำ")
                        // console.log(count_bookNo)
                        // console.log(data)
                        // console.log("<----------------------------->")
                    }
                    // console.log(prepare)
                    // let dateFinished = new Date(data.finishAt - (7 * 3600 * 1000));
                    // prepare.dateFinished = Helper.getDateThai(dateFinished, { is_show_time: true })
                    let isCanPush = true;
                    if (this.state.finishedFilter == 'halfday') {
                        let serviceDate = new Date(data.bookedAt)
                        let dateFinished = new Date(data.finishAt - (7 * 3600 * 1000));
                        // prepare.dateFinished = Helper.getDateThai(dateFinished, { is_show_time: true })
                        if (dateFinished) {
                            if (start_date.getDate() == end_date.getDate()
                                && start_date.getMonth() == end_date.getMonth()
                                && start_date.getFullYear() == end_date.getFullYear()
                                && end_date.getDate() == serviceDate.getDate()
                            ) { // ถ้าเลือกวันเดียวกัน
                                if (dateFinished.getHours() > 12
                                    || (
                                        dateFinished.getHours() == 12
                                        && dateFinished.getMinutes() > 0
                                    )
                                ) { // ถ้าเกินเที่ยง
                                    // console.log(`เกินเที่ยง - วันเดียวกัน`)
                                    isCanPush = false;
                                }
                            } else {

                                let startHalfDay = new Date(parseFloat(this.state.start_date));
                                let endHalfDay = new Date(parseFloat(this.state.end_date));
                                startHalfDay.setHours(12, 0, 0);
                                endHalfDay.setHours(12, 0, 0);


                                if (startHalfDay.getTime() >= dateFinished.getTime()) {
                                    isCanPush = false;
                                }
                                if (endHalfDay.getTime() <= dateFinished.getTime()) {
                                    isCanPush = false;
                                }
                            }
                        }
                    }

                    if (isCanPush) {

                        _data.push({
                            ...data,
                            ...prepare
                        })
                        if (data.plusTime && data.plusTime.length > 0) {
                            let plus_time_no = 1;
                            for (const plusTime of data.plusTime) {
                                let _status = ``
                                if (plusTime.status == 'success') {
                                    _status = `สำเร็จ`;
                                }
                                if (plusTime.status == 'wait') {
                                    _status = `รอชำระเงิน`;
                                }
                                if (plusTime.status == 'cancel') {
                                    _status = `ยกเลิก`;
                                }
                                if (plusTime.status == 'fail') {
                                    _status = `ไม่สำเร็จ`;
                                }
                                prepare.bookPaymentInfo = `เพิ่มชั่วโมงครั้งที่ ${plus_time_no} (${_status})`;
                                count_plusTime += 1;
                                let plustimePrepare = { ...prepare };
                                if (this.state.type === 'provider') {
                                    plustimePrepare.priceProvider = plusTime.priceProvider;
                                } else {
                                    plustimePrepare.priceProviderPlusTime = plusTime.priceProvider;
                                }
                                plustimePrepare.bookTime = plusTime.time;
                                plustimePrepare.price = parseFloat(plusTime.priceTotal);
                                plustimePrepare.plustime_total_price = parseFloat(0);
                                plustimePrepare.priceApp = (parseFloat(plusTime.priceApp));
                                plustimePrepare.priceDiscount = 0;
                                plustimePrepare.totalPriceProvider = parseFloat(plusTime.priceProvider) > 0 ? parseFloat(plusTime.priceProvider) : (150);
                                plustimePrepare.chargePrice = parseFloat(plusTime.vat);
                                plustimePrepare.totalUserPrice = ((parseFloat(plusTime.priceTotal) - parseFloat(plustimePrepare.priceDiscount)) + parseFloat(plustimePrepare.chargePrice)).toFixed(2)
                                plustimePrepare.totalUserPrice = parseFloat(plustimePrepare.totalUserPrice)
                                plustimePrepare.priceUserAfterDiscount = (parseFloat(plustimePrepare.price) - parseFloat(plustimePrepare.priceDiscount));
                                _plusTime = 0;
                                plustimePrepare.priceAgentCommissionFromProvider = 0;
                                plustimePrepare.priceAgentCommissionFromApp = 0;
                                plustimePrepare.payment = {
                                    statusText: _status,
                                    paymentBy: 'บัตรเครดิต',
                                }
                                plustimePrepare.priceProvider = plustimePrepare.totalPriceProvider;
                                plustimePrepare.transferPrice = plustimePrepare.totalPriceProvider;
                                plustimePrepare.transferPriceRevenue = plustimePrepare.transferPrice;
                                plustimePrepare.chargePrice = parseFloat(plusTime.vat);

                                plustimePrepare.totalPriceApp = (parseFloat(plusTime.priceApp) + parseFloat(plusTime.vat)).toFixed(2);
                                plustimePrepare.bookOn = data.bookOn;
                                if (plusTime.paymentBy && plusTime.paymentBy == "omise" && plusTime.omise) {
                                    plustimePrepare.payment.card = plusTime.omise.card;
                                }
                                if (plusTime.paymentBy && plusTime.paymentBy == "banktransfer") {
                                    plustimePrepare.paymentMethod = "โอนเงิน";
                                    plustimePrepare.payment.paymentBy = 'โอนเงิน';
                                }
                                plustimePrepare.createdAt = plusTime.updatedAt;
                                // plustimePrepare.time = "";
                                plustimePrepare.priceUrgent = 0;
                                plustimePrepare.deductions = 0;
                                plustimePrepare.specialPrice = 0;
                                plustimePrepare.isPlustime = true
                                delete plustimePrepare.deductionsNote
                                delete plustimePrepare.specialPriceNote
                                plustimePrepare.totalPriceUrgent = 0;
                                plustimePrepare.totalPriceUrgentProvider = 0;
                                plustimePrepare.totalPriceUrgentApp = 0;
                                plustimePrepare.adjustUser = 0;
                                plustimePrepare.adjustUserMinus = 0;
                                plustimePrepare.adjustVat = 0;
                                plustimePrepare.priceUrgentSummaryApp = plustimePrepare.priceUrgentApp + plustimePrepare.priceUrgentVat;
                                plustimePrepare.totalUserPrice += plustimePrepare.adjustUser;
                                plustimePrepare.totalUserPrice += plustimePrepare.priceUrgent;
                                _data.push({
                                    ...data,
                                    ...plustimePrepare
                                })
                                plus_time_no++;
                                // }
                            }
                        }
                        if (data.changeProviderData) {
                            let changeProviderData_index = 0;
                            for (const pData of data.changeProviderData) {
                                let _pData = {
                                    ...pData,
                                    providerUser: { ...pData.currentProvider }
                                }

                                if (_pData.currentProviderUid != data.providerUserUid) {
                                    if (_pData?.providerUser?.phoneNumber) {
                                        _pData.providerUser.phoneNumber = pData.currentProvider?.phoneNumber || `ไม่มีข้อมูล`
                                    } else {
                                        _pData.providerUser.phoneNumber = `ไม่มีข้อมูล`
                                    }
                                    if (_pData?.providerUser?.displayName) {
                                        _pData.providerUser.displayName = pData.currentProvider?.displayName || `ไม่มีข้อมูล`
                                    } else {
                                        _pData.providerUser.displayName = `ไม่มีข้อมูล`
                                    }
                                    _pData.providerDisplayname = _pData.providerUser.displayName;
                                    _pData.providerUserUid = _pData.currentProviderUid;
                                    _pData.bookPaymentInfo = `เปลี่ยนแม่บ้านครั้งที่ ${changeProviderData_index + 1}`;
                                    _pData.bookTime = work_time;
                                    _pData.price = parseFloat(0);
                                    _pData.plustime_total_price = parseFloat(0);
                                    _pData.priceApp = parseFloat(0);
                                    _pData.priceDiscount = parseFloat(0);
                                    _pData.totalPriceProvider = parseFloat(0);
                                    _pData.chargePrice = parseFloat(0);
                                    _pData.totalUserPrice = parseFloat(0).toFixed(2)
                                    _pData.totalUserPrice = parseFloat(0)
                                    _pData.priceUserAfterDiscount = parseFloat(0)
                                    _pData.priceAgentCommissionFromProvider = 0;
                                    _pData.priceAgentCommissionFromApp = 0;
                                    _pData.priceProvider = _pData.totalPriceProvider;
                                    _pData.transferPrice = _pData.totalPriceProvider;
                                    _pData.chargePrice = parseFloat(0);
                                    _pData.totalPriceApp = parseFloat((0).toFixed(2));
                                    _pData.isChangeProvider = true

                                    _pData.priceUrgent = 0;
                                    _pData.priceUrgentApp = 0;
                                    _pData.priceUrgentProvider = 0;
                                    _pData.priceUrgentVat = 0;
                                    _pData.priceUrgent = 0;
                                    _pData.totalPriceUrgent = 0;
                                    _pData.totalPriceUrgentProvider = 0;
                                    _pData.totalPriceUrgentApp = 0;
                                    _pData.rentOutOfAppPrice = 0;
                                    _pData.bookAt = Helper.getDateNumber(data.createdAt);

                                    _pData.deductions = 0;
                                    _pData.specialPrice = 0;
                                    _pData.adjustUser = 0;
                                    _pData.adjustUserMinus = 0;
                                    _pData.adjustVat = 0;
                                    let deductionsNotes = [];
                                    let specialPriceNotes = [];
                                    let adjust_count = 0;
                                    if (data.adjustSalary && data.adjustSalary.length > 0) {
                                        for (const adj of data.adjustSalary) {
                                            if (adj.providerUserUid == pData.currentProviderUid) {
                                                if (adj.type == 'withhold_wallet') {
                                                    _pData.deductions += parseFloat(adj.amount)
                                                    if (adj.adjustType == 'customer') {
                                                        _pData.adjustUser -= parseFloat(adj.amount)
                                                    }
                                                    if (adj.reason) {
                                                        deductionsNotes.push(adj.reason)
                                                    }
                                                    adjust_count++;
                                                } else {
                                                    _pData.specialPrice += parseFloat(adj.amount)
                                                    if (adj.adjustType == 'customer') {
                                                        _pData.adjustUser += parseFloat(adj.amount)
                                                    }
                                                    if (adj.reason) {
                                                        specialPriceNotes.push(adj.reason)
                                                    }
                                                    adjust_count++;
                                                }
                                                if (adj.adjustType == 'customer') {
                                                    _pData.adjustVat += parseFloat(adj.vat)
                                                }
                                            }
                                        }
                                    }
                                    // console.log(_pData.adjustUserMinus, _pData.adjustUser, _pData.totalUserPrice)
                                    _pData.totalUserPrice += _pData.adjustUser;
                                    _pData.totalUserPrice -= _pData.adjustUserMinus;
                                    _pData.totalUserPrice += _pData.priceUrgent;
                                    _pData.totalUserPrice += _pData.adjustVat;
                                    _pData.totalPriceApp += _pData.adjustVat;
                                    _pData.deductionsNote = deductionsNotes.join()
                                    _pData.specialPriceNote = specialPriceNotes.join()
                                    _pData.priceUrgentSummaryApp = _pData.priceUrgent + _pData.priceUrgentVat;

                                    _pData.transferPrice += _pData.specialPrice;
                                    _pData.transferPrice -= _pData.deductions;
                                    _pData.transferPriceRevenue = _pData.transferPrice;
                                    if (_pData.transferPrice < 0) {
                                        _pData.transferPrice = 0;
                                    }
                                    if (_pData.providerUser && _pData.providerUser.bank) {
                                        let _getBank = BankCode.filter((e) => e.name.indexOf(_pData.providerUser.bank) != -1)[0];
                                        if (_getBank) {
                                            _pData.providerUser.bankCode = _getBank.code;
                                        }
                                        // _pData.providerUser.bankAccountNo = data.providerUser.bankAccountNo.replaceAll('-', '').replaceAll(' ', '').trim()
                                    }
                                    if (this.state.isClearDevAccount && pData.currentProvider?.devAccount) {
                                        continue;
                                    }

                                    if (adjust_count > 0) {
                                        _data.push({
                                            ...data,
                                            ..._pData
                                        })
                                    }
                                    changeProviderData_index++;
                                }
                                // if (data.bookOn == `08072023fyWpi`) {
                                //     console.log(_pData)
                                // }
                            }
                        }

                    }

                }
                count = res.data.count_all;
                provider_all = res.data.provider_all;
            }
            let _response = [];
            _data.map((data) => {
                let prepare = {}
                // prepare.dateFinished = data.dateFinished;
                prepare.bookStatusth = data.bookStatusth;
                prepare.bookedAtDate = Helper.getDateNumber(data.bookedAt);
                prepare.bookedAt = data.bookedAt;
                prepare.createdAtDate = Helper.getDateNumber(data.createdAt);
                prepare.createdAt = data.createdAt;
                prepare.bookOn = data.bookOn;
                prepare.userDisplayName = data.user && data.user.displayName ? data.user.displayName : null;
                prepare.userPhoneNumber = data.user && data.user.phoneNumber ? data.user.phoneNumber.replace('+66', '0') : null;
                if (!prepare.userPhoneNumber) {
                    prepare.userPhoneNumber = data.user && data.user.guestPhoneNumber ? data.user.guestPhoneNumber.replace('+66', '0') : null;
                }
                prepare.providerDisplayname = data.providerUser && data.providerUser.displayName ? data.providerUser.displayName : null;
                prepare.providerPhoneNumber = data.providerUser && data.providerUser.phoneNumber ? data.providerUser.phoneNumber.replace('+66', '0') : null;
                prepare.time = Helper.getTime(data.bookedAt);

                prepare.bookTime = data.bookTime;
                prepare.address = data.address.address;
                prepare.work_detail = data.address.placeType.name_th + ' ' + data.address.placeType.place_sub_type.description_th;

                prepare.price = parseFloat(data.price) || 0;
                prepare.priceDiscount = parseFloat(data.priceDiscount) || 0;
                prepare.priceUserAfterDiscount = parseFloat(data.priceUserAfterDiscount) || 0;
                prepare.chargePrice = parseFloat(data.chargePrice) || 0;
                prepare.totalUserPrice = parseFloat(data.totalUserPrice) || 0;
                prepare.priceApp = parseFloat(data.priceApp) || 0;
                prepare.totalPriceApp = parseFloat(data.totalPriceApp) || 0;
                if (data.isPlustime || data.isChangeProvider) {
                    prepare.totalPriceProvider = parseFloat(data.totalPriceProvider) || 0;
                } else {

                    prepare.totalPriceProvider = !data.changeBookPrice && parseFloat(data.priceProviderWithoutAgent) ? parseFloat(data.priceProviderWithoutAgent) : parseFloat(data.priceProvider) > 0 ? parseFloat(data.priceProvider) : (350);
                }
                prepare.priceAgentCommissionFromApp = parseFloat(data.priceAgentCommissionFromApp) || 0;
                prepare.priceAgentCommissionFromProvider = parseFloat(data.priceAgentCommissionFromProvider) || 0;
                prepare.priceAppWithoutAgent = parseFloat(data.priceAppWithoutAgent) || 0;
                prepare.priceProviderWithoutAgent = parseFloat(data.priceProviderWithoutAgent) || 0;
                prepare.bookBankNo = data.providerUser && data.providerUser.bankAccountNo ? data.providerUser.bankAccountNo.replaceAll('-', '') : '';
                prepare.bookBankName = data.providerUser && data.providerUser.bankAccountName ? data.providerUser.bankAccountName : '';
                prepare.bank = data.providerUser && data.providerUser.bank ? data.providerUser.bank : '';
                prepare.paymentMethod = data.paymentMethod;
                prepare.bookPaymentInfo = data.bookPaymentInfo;
                prepare.priceUrgent = parseFloat(data.priceUrgent) || 0;
                prepare.priceUrgentApp = parseFloat(data.priceUrgentApp) || 0;
                prepare.priceUrgentProvider = parseFloat(data.priceUrgentProvider) || 0;
                prepare.priceUrgentVat = parseFloat(data.priceUrgentVat) || 0;
                prepare.priceUrgentVatUser = parseFloat(data.priceUrgentVatUser) || 0;
                prepare.totalPriceUrgent = parseFloat(data.totalPriceUrgent) || 0;
                prepare.totalPriceUrgentProvider = parseFloat(data.totalPriceUrgentProvider) || 0;
                prepare.totalPriceUrgentApp = parseFloat(data.totalPriceUrgentApp) || 0;
                prepare.rentOutOfAppPrice = 0;
                prepare.deductions = parseFloat(data.deductions);
                prepare.specialPrice = parseFloat(data.specialPrice);
                prepare.adjustVat = parseFloat(data.adjustVat) || 0;
                prepare.adjustUser = parseFloat(data.adjustUser) || 0;
                prepare.transferPrice = data.transferPrice;
                prepare.transferStatus = '';
                prepare.slip = '';
                prepare.rentType = '';
                prepare.appChargePrice = prepare.chargePrice
                if (data.promotion) {
                    if (data.promotion.promotionType == "event") {
                        prepare.rentType = "หน้าแอป"
                    }
                    if (data.promotion.promotionType == "unique") {
                        prepare.rentType = "การตลาด"
                    }
                    if (data.promotion.promotionType == "package") {
                        prepare.rentType = "แพ็กเกจ"
                    }
                    if (data.promotion.promotionType == "agent") {
                        prepare.rentType = "ตัวแทน"
                    }
                }
                data.note = '';

                if (this.state.booking_type === 'admin_created') {
                    prepare.bookStatus = data.bookStatus;
                    prepare.bookStatusth = data.bookStatusth;
                }
                if (prepare.bank) {
                    let getBank = BankCode.filter((e) => e.name.indexOf(data.providerUser.bank) != -1)[0];
                    if (getBank) {
                        prepare.bankCode = getBank.code;
                    }
                }
                _response.push(prepare)
                return true;
            })
            if (this.state.type == 'user') {
                _data = _data.sort(function (a, b) {
                    if (a.providerDisplayname < b.providerDisplayname) {
                        return -1;
                    }
                    if (a.providerDisplayname > b.providerDisplayname) {
                        return 1;
                    }
                    return 0;
                });
                _response.sort(function (a, b) {
                    if (a.providerDisplayname < b.providerDisplayname) {
                        return -1;
                    }
                    if (a.providerDisplayname > b.providerDisplayname) {
                        return 1;
                    }
                    return 0;
                });
            }
            _data = _data.sort(function (a, b) {
                if (a.bookedAt < b.bookedAt) {
                    return 1;
                }
                if (a.bookedAt > b.bookedAt) {
                    return -1;
                }
                return 0;
            });
            _response.sort(function (a, b) {
                if (a.bookedAt < b.bookedAt) {
                    return 1;
                }
                if (a.bookedAt > b.bookedAt) {
                    return -1;
                }
                return 0;
            });
            if (this.state.type != 'user') {
                if (this.state.columnSort == 'providerName') {
                    _data = _data.sort(function (a, b) {
                        if (a.providerDisplayname < b.providerDisplayname) {
                            return -1;
                        }
                        if (a.providerDisplayname > b.providerDisplayname) {
                            return 1;
                        }
                        return 0;
                    });
                    _response.sort(function (a, b) {
                        if (a.providerDisplayname < b.providerDisplayname) {
                            return -1;
                        }
                        if (a.providerDisplayname > b.providerDisplayname) {
                            return 1;
                        }
                        return 0;
                    });
                }
            }
            let logs = {};
            logs.render = {}
            logs.render.renderResponse = _response.length;
            logs.render.data = _data.length;
            logs.render.count = count;
            logs.render.count_plusTime = count_plusTime;
            await axios.put(`${window.api_host}/report-logs`, { id: res.data.logId, data: logs });

            this.setState({
                data: _data,
                items_response: _response,
                is_loading: false,
                all_items: count,
                provider_all: provider_all,
                logId: res.data.logId,
            }, () => {
                if (repeated_count > 0) {
                    window.$(document).Toasts('create', {
                        title: 'เกิดข้อผิดพลาด',
                        body: "มีข้อมูลซ้ำกรุณาตรวจสอบอีกครั้ง",
                        icon: 'fas fa-exclamation',
                        autohide: true,
                        delay: 3000,
                        class: "bg-warning",
                    })
                }
            })
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: "ไม่สามารถดึงข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.setState({
                data: [],
                is_loading: false,
                all_items: 0,
                provider_all: 0,
            })
        }

    }



    sortData() {
        console.log("sortData")
        let sortColumn = this.state.columnSort;
        let _response = this.state.items_response;
        let _data = this.state.data;
        _data = _data.sort(function (a, b) {
            if (a.bookedAt < b.bookedAt) {
                return 1;
            }
            if (a.bookedAt > b.bookedAt) {
                return -1;
            }
            return 0;
        });
        _response.sort(function (a, b) {
            if (a.bookedAt < b.bookedAt) {
                return 1;
            }
            if (a.bookedAt > b.bookedAt) {
                return -1;
            }
            return 0;
        });
        if (sortColumn == 'providerName') {
            _data = _data.sort(function (a, b) {
                console.log(a.providerDisplayname, b.providerDisplayname)
                if (a.providerDisplayname < b.providerDisplayname) {
                    return -1;
                }
                if (a.providerDisplayname > b.providerDisplayname) {
                    return 1;
                }
                return 0;
            });
            _response.sort(function (a, b) {
                if (a.providerDisplayname < b.providerDisplayname) {
                    return -1;
                }
                if (a.providerDisplayname > b.providerDisplayname) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState({
            data: _data,
            items_response: _response,
            is_loading: false,
        })
    }

    render() {
        let page_title = ""
        let fileName = 'รายงาน';
        if (this.state.type === 'user') {
            fileName += 'สมาชิก'
            page_title = "รายงานสมาชิก"
        }
        if (this.state.type === 'provider') {
            fileName += 'แม่บ้าน'
            page_title = "รายงานแม่บ้าน"
        }
        if (this.state.type === 'all') {
            if (this.state.booking_type == "admin_created") {
                page_title = "สร้างจากระบบหลังบ้าน"
                fileName += "สร้างจากระบบหลังบ้าน"
            } else {
                page_title = "รายงานทั้งหมด"
                fileName += "รายงานทั้งหมด"
            }
        }
        let page_sub_title = "";
        page_sub_title += ' ณ วันที่ ' + Helper.getDateThai(parseFloat(this.state.render_start_date)) + ' - ' + Helper.getDateThai(parseFloat(this.state.render_end_date))

        fileName += ' ' + Helper.getDateThai(parseFloat(this.state.start_date)) + ' - ' + Helper.getDateThai(parseFloat(this.state.end_date));
        // let _response = ;
        // console.log(_response);
        // console.log(this.state.data);
        return (
            <>
                {
                    this.state.transfer_data
                        && this.state.transfer_data.length > 0 ?

                        <ExcelExport
                            data={this.state.transfer_data}
                            ref={exporter => this._export_transfer = exporter}
                            fileName={`รายการโอนเงิน ${Helper.getDateThai(parseFloat(this.state.start_date))} - ${Helper.getDateThai(parseFloat(this.state.end_date))}`}
                        >
                            <ExcelExportColumn field="bookUid" title="bookUid" width={0} />
                            <ExcelExportColumn field="providerUserUid" title="providerUserUid" width={0} />
                            <ExcelExportColumn field="bookOn" title="Booking No" width={130} />
                            <ExcelExportColumn field="bookAt" title="รายได้วันที่" width={100} />
                            <ExcelExportColumn
                                field="providerUser.displayName"
                                title="แม่บ้าน"
                                width={200}
                            />
                            <ExcelExportColumn
                                field="providerUser.phoneNumber"
                                title="เบอร์โทรศัพท์แม่บ้าน"
                                width={150}
                            />
                            <ExcelExportColumn field="bookAt" title="จองวันที่" width={150} />
                            <ExcelExportColumn field="serviceAt" title="วันที่ให้บริการ" width={150} />
                            <ExcelExportColumn field="serviceTime" title="เวลาให้บริการ" width={100} />
                            <ExcelExportColumn field="bookTime" title="จำนวน/ชั่วโมง" width={50} />
                            <ExcelExportColumn field="totalPriceUrgentProvider" title="เร่งด่วน" width={50} />
                            <ExcelExportColumn field="priceProvider" title="แม่บ้านได้รับ" width={80} />
                            {
                                this.props.is_super_admin ?
                                    <ExcelExportColumn field="priceAgentCommissionFromProvider" title="คอมมิชชั่น" width={80} />
                                    : null
                            }
                            <ExcelExportColumn field="rentOutOfAppPrice" title="จองนอกแอป" width={80} />
                            <ExcelExportColumn field="deductions" title="ปรับเงิน" width={80} />
                            <ExcelExportColumn field="deductionsNote" title="หมายเหตุ" width={80} />
                            <ExcelExportColumn field="specialPrice" title="เงินพิเศษ" width={80} />
                            <ExcelExportColumn field="specialPriceNote" title="หมายเหตุ" width={80} />
                            <ExcelExportColumn field="transferPrice" title="รวมที่ต้องโอน" width={80} />
                            <ExcelExportColumn field="note" title="หมายเหตุ" width={150} />
                            <ExcelExportColumn field="providerUser.bankAccountNo" title="เลขบัญชี" width={150} />
                            <ExcelExportColumn field="providerUser.bankAccountName" title="ชื่อบัญชี" width={150} />
                            <ExcelExportColumn field="providerUser.bankCode" title="รหัสธนาคาร" width={150} />
                            <ExcelExportColumn field="providerUser.bank" title="ชื่อธนาคาร" width={150} />
                        </ExcelExport>
                        : null
                }
                {
                    this.state.revenue_data
                        && this.state.revenue_data.length > 0 ?

                        <ExcelExport
                            data={this.state.revenue_data}
                            ref={exporter => this._export_revenue = exporter}
                            fileName={`รายการโอนเงินสำหรับส่งสรรพากร ${Helper.getDateThai(parseFloat(this.state.start_date))} - ${Helper.getDateThai(parseFloat(this.state.end_date))}`}
                        >
                            <ExcelExportColumn field="bookAt" title="รายได้วันที่" width={100} />
                            <ExcelExportColumn field="bookOn" title="Booking No" width={130} />
                            <ExcelExportColumn
                                field="providerUser.displayName"
                                title="แม่บ้าน"
                                width={200}
                            />
                            <ExcelExportColumn
                                field="providerUser.phoneNumber"
                                title="เบอร์โทรศัพท์แม่บ้าน"
                                width={150}
                            />
                            <ExcelExportColumn field="bookAt" title="จองวันที่" width={150} />
                            <ExcelExportColumn field="serviceAt" title="วันที่ให้บริการ" width={150} />
                            <ExcelExportColumn field="serviceTime" title="เวลาให้บริการ" width={100} />
                            <ExcelExportColumn field="bookTime" title="จำนวน/ชั่วโมง" width={50} />
                            <ExcelExportColumn field="totalPriceUrgentProvider" title="เร่งด่วน" width={80} />
                            <ExcelExportColumn field="priceProvider" title="แม่บ้านได้รับ" width={80} />
                            <ExcelExportColumn field="rentOutOfAppPrice" title="จองนอกแอป" width={80} />
                            <ExcelExportColumn field="deductions" title="ปรับเงิน" width={80} />
                            <ExcelExportColumn field="specialPrice" title="เงินพิเศษ" width={80} />
                            <ExcelExportColumn field="transferPrice" title="รวมที่ต้องโอน" width={80} />
                            <ExcelExportColumn field="summaryPriceProvider" title="รวม" width={80} />
                            <ExcelExportColumn field="summaryVatPrice" title="ณ ที่จ่าย" width={80} />
                            <ExcelExportColumn field="summaryTotalPrice" title="ยอดโอน" width={80} />
                            <ExcelExportColumn field="providerUser.bankAccountNo" title="เลขบัญชี" width={150} />
                            <ExcelExportColumn field="providerUser.bankAccountName" title="ชื่อบัญชี" width={150} />
                            <ExcelExportColumn field="providerUser.bankCode" title="รหัสธนาคาร" width={150} />
                            <ExcelExportColumn field="providerUser.bank" title="ชื่อธนาคาร" width={150} />
                            <ExcelExportColumn field="providerUser.personalNo" title="เลขบัตรชาชน" width={150} />
                            <ExcelExportColumn field="providerUser.taxPayer.taxPayerNumber" title="เลขผู้เสียภาษี" width={150} />
                            <ExcelExportColumn field="providerUser.taxPayer.taxPayerAddress" title="ที่อยู่จัดส่งเอกสาร" width={150} />
                            <ExcelExportColumn field="providerUser.taxPayer.taxPayerZipCode" title="รหัสไปรษณีย์" width={150} />
                            <ExcelExportColumn field="note" title="หมายเหตุ" width={150} />

                        </ExcelExport>
                        : null
                }
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายงาน Booking {page_title}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/booking">รายการ Booking</Link></li>
                                    <li className="breadcrumb-item active">
                                        รายงาน Booking
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " >
                                <h3 className="card-title" style={{ display: 'flex', alignItems: 'center' }}>รายการ Booking  {page_sub_title}</h3>
                                {
                                    this.state.type == 'provider'
                                        && !this.state.is_loading ?
                                        <div className='card-tools'>
                                            {
                                                this.props.is_super_admin
                                                    && this.state.data ?
                                                    <>
                                                        <ReportProviderWallet
                                                            data={this.state.data}
                                                            start_date={this.state.start_date}
                                                            end_date={this.state.end_date}
                                                        />
                                                    </>
                                                    : null
                                            }
                                            {
                                                this.state.type === 'provider' ?
                                                    <button
                                                        ref={(ref) => {
                                                            this.$transferReport = window.$(ref)
                                                        }}
                                                        title="Export Excel"
                                                        className="btn btn-primary"
                                                        style={{ marginRight: 5 }}
                                                        onClick={async () => {
                                                            this.getReportForTransfer();
                                                        }}
                                                    >
                                                        โหลดรายงานสำหรับการโอนเงิน
                                                    </button>
                                                    : null
                                            }
                                            {
                                                this.state.type === 'provider' ?
                                                    <button
                                                        ref={(ref) => {
                                                            this.$revenueReport = window.$(ref)
                                                        }}
                                                        title="Export Excel"
                                                        className="btn btn-info"
                                                        style={{ marginRight: 5 }}
                                                        onClick={async () => {
                                                            this.getRevenueReport();
                                                        }}
                                                    >
                                                        โหลดรายงานสำหรับการส่งสรรพากร
                                                    </button>
                                                    : null
                                            }
                                        </div>
                                        : null
                                }
                            </div>

                            <div className="card-body">
                                <div className={"flex row"}
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <form
                                        className="row flex"
                                        ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                ...data
                                            })
                                            navigate(url)
                                        }}
                                    >
                                        <input type="hidden" name="isClearDevAccount" value={this.state.isClearDevAccount} />
                                        <input type="hidden" name="type" value={this.state.type} />
                                        <div style={{ marginRight: '0.5rem' }}>
                                            <DatePicker
                                                disabled={this.state.is_loading}
                                                onChange={date => {

                                                    let start = new Date(date);
                                                    start.setHours(0, 0, 0, 0);
                                                    let end_date = this.state.end_date
                                                    if (start.getTime() > end_date) {
                                                        end_date = start.getTime()
                                                    }
                                                    this.setState({
                                                        start_date: start.getTime(),
                                                        end_date: end_date
                                                    }
                                                        // , () => {
                                                        //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                        // }
                                                    )
                                                }}
                                                selectsStart
                                                startDate={this.state.start_date}
                                                endDate={this.state.end_date}
                                                locale="th-TH"
                                                selected={Helper.getDateToEdit(this.state.start_date)}
                                                placeholderText={"วัน / เดือน / ปี"}
                                                name={"start_date"}
                                                dateFormat="MMMM d, yyyy 00:00:00"
                                                withPortal
                                                onCalendarOpen={() => { Helper.renderDate() }}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => {
                                                    date = new Date(date);
                                                    let now = new Date();
                                                    // months_th
                                                    const years = [];
                                                    let i;
                                                    for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                        years.push(i)
                                                    }
                                                    return (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className={"btn btn-primary "}
                                                                type={"button"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                            >
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <select
                                                                className={"form-control"}
                                                                value={date.getFullYear()}
                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {years.map(option => (
                                                                    <option key={option} value={option} >
                                                                        {option + 543}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className={"form-control"}
                                                                value={window.months_th[date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(window.months_th.indexOf(value))
                                                                }
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {window.months_th.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                className={"btn btn-primary"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                type={"button"}
                                                            >
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                    )
                                                }}
                                                customInput={<CustomDateInput />}
                                            />
                                            <input type="hidden" id="start_date" name="start_date" value={this.state.start_date} onChange={() => { console.log('change') }} readOnly={true} />
                                        </div>
                                        <div style={{ marginRight: '0.5rem' }}>
                                            <DatePicker
                                                disabled={this.state.is_loading}
                                                onChange={date => {
                                                    let end = new Date(date);
                                                    end.setHours(23, 59, 0, 0);
                                                    let start_date = this.state.start_date
                                                    if (end.getTime() < start_date) {
                                                        start_date = end.getTime()
                                                    }
                                                    this.setState({
                                                        end_date: end.getTime(),
                                                        start_date: start_date,
                                                    }
                                                        // , () => {
                                                        //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                        // }
                                                    )
                                                }}
                                                selectsEnd
                                                startDate={this.state.start_date}
                                                endDate={this.state.end_date}
                                                locale="th-TH"
                                                selected={Helper.getDateToEdit(this.state.end_date)}
                                                placeholderText={"วัน / เดือน / ปี"}
                                                name={"end_date"}
                                                dateFormat="MMMM d, yyyy 23:59:00"
                                                minDate={this.state.start_date}
                                                withPortal
                                                onCalendarOpen={() => { Helper.renderDate() }}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => {
                                                    date = new Date(date);
                                                    let now = new Date();
                                                    // months_th
                                                    const years = [];
                                                    let i;
                                                    for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                        years.push(i)
                                                    }
                                                    return (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className={"btn btn-primary "}
                                                                type={"button"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                            >
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <select
                                                                className={"form-control"}
                                                                value={date.getFullYear()}
                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {years.map(option => (
                                                                    <option key={option} value={option} >
                                                                        {option + 543}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className={"form-control"}
                                                                value={window.months_th[date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(window.months_th.indexOf(value))
                                                                }
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {window.months_th.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                className={"btn btn-primary"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                type={"button"}
                                                            >
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                    )
                                                }}
                                                customInput={<CustomDateInput />}
                                            />
                                            <input type="hidden" id="end_date" name="end_date" value={this.state.end_date} onChange={() => { console.log('change') }} readOnly={true} />
                                        </div>
                                        {

                                            (this.state.booking_type !== 'admin_created') ?
                                                <div style={{ marginRight: '0.5rem' }}>
                                                    <select
                                                        className='form-control'
                                                        name='status'
                                                        defaultValue={this.state.status}
                                                        onChange={(e) => {
                                                            if (e.target.value != "finish") {
                                                                this.selector.hide();
                                                            } else {
                                                                this.selector.show();
                                                            }
                                                        }}
                                                    >
                                                        <option value="all">แสดงทั้งหมด</option>
                                                        {/* <option value="wait">รอ</option> */}
                                                        <option value="accept">ยอมรับ</option>
                                                        <option value="going">กำลังเดินทาง</option>
                                                        <option value="working">กำลังทำงาน</option>
                                                        <option value="arrive">ถึงแล้ว</option>
                                                        <option value="done">เสร็จสิ้น</option>
                                                        <option value="finish">สำเร็จ</option>
                                                        <option value="refund">คืนเงิน</option>
                                                        <option value="cancel">ยกเลิก</option>
                                                    </select>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.type == 'all'
                                                || this.state.type == 'user' ?
                                                <div style={{ marginRight: '0.5rem' }}>
                                                    <select className='form-control' name='sortField' defaultValue={this.state.sortField || false}>
                                                        <option value="createdAt">วันจอง</option>
                                                        <option value="bookedAt">วันให้บริการ</option>
                                                    </select>
                                                </div>
                                                : null
                                        }

                                        <div
                                            ref={(ref) => {
                                                this.selector = window.$(ref);
                                            }}
                                            style={{ marginRight: '0.5rem', display: this.state.status == 'finish' ? "block" : "none" }}>
                                            <select
                                                className='form-control'
                                                name='finishedFilter'
                                                defaultValue={this.state.finishedFilter || false}
                                            >
                                                <option value="all">ทั้งหมด</option>
                                                <option value="halfday">หลังเที่ยง - ก่อนเที่ยง</option>
                                            </select>
                                        </div>

                                        {/* <div>
                                            <select className='form-control' name='booking_type'>
                                                <option value="all">แสดงทั้งหมด</option>
                                                <option value="app_created">สร้างโดยแอป</option>
                                                <option value="admin_created">สร้างโดยระบบหลังบ้าน</option>
                                            </select>
                                        </div> */}
                                        <div className="input-group-append" style={{ marginLeft: 5 }}>
                                            <input type="hidden" name="booking_type" value={this.state.booking_type} />
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i> ค้นหา
                                            </button>
                                        </div>
                                    </form>
                                    <p style={{ marginLeft: 10, marginBottom: 0, display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>อ้างอิงการดึงข้อมูลจาก{this.state.sortField == 'createdAt' ? 'วันจอง' : 'วันให้บริการ'}</p>
                                    {
                                        this.state.type != 'user'
                                            && !this.state.is_loading ?
                                            <div style={{ marginLeft: 'auto' }}>
                                                <div className='flex row' style={{ alignItems: 'center' }}>
                                                    <p style={{ marginBottom: 0, marginRight: 5, fontWeight: 'bold' }}>เรียงข้อมูล : </p>
                                                    <div>
                                                        <select
                                                            className='form-control'
                                                            name='columnSort'
                                                            defaultValue={this.state.columnSort || false}
                                                            onChange={(e) => {
                                                                let value = e.target.value;
                                                                this.setState({
                                                                    columnSort: value,
                                                                    is_loading: true,
                                                                }, () => {
                                                                    this.sortData()
                                                                })
                                                            }}
                                                        >
                                                            <option value="providerName">ชื่อแม่บ้าน</option>
                                                            <option value="bookedAt">วันที่ให้บริการ</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                    {
                                        this.state.type === 'user' ?
                                            <div style={{ marginLeft: 'auto' }}>
                                                <Link
                                                    title="New user report"
                                                    className="btn btn-info"
                                                    style={{ marginRight: 5 }}
                                                    to={'/booking-report/user'}
                                                >
                                                    New Report
                                                </Link>
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className='flex row mb-2' style={{ alignItems: 'center' }}>
                                    {
                                        this.props.is_super_admin ?
                                            <div className={"form-group"}>
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox"
                                                        className="custom-control-input"
                                                        id="isClearDevAccount"
                                                        name={"isClearDevAccount"}
                                                        defaultValue={"on"}
                                                        onChange={(e) => {
                                                            let data = Helper.getFormInputObject(this.$searchForm);
                                                            if (window.$(e.target).prop("checked")) {
                                                                data.isClearDevAccount = `true`
                                                            } else {
                                                                data.isClearDevAccount = `false`
                                                            }
                                                            let url = Helper.getQueryStringLinkFromObject({
                                                                ...data
                                                            })
                                                            navigate(url)
                                                        }}
                                                        defaultChecked={this.state.isClearDevAccount}
                                                    />
                                                    <label className="custom-control-label" htmlFor="isClearDevAccount">ปิดแสดงผล dev account</label>
                                                </div>
                                            </div>
                                            : null
                                    }


                                    {
                                        !this.state.is_loading
                                            && this.state.data
                                            && this.state.items_response ?
                                            <div style={{ marginLeft: 'auto' }}>
                                                {
                                                    this.state.type !== 'all' ?
                                                        <Link to={"/transections-report/?type=" + this.state.type} style={{ marginRight: 5 }} className={"btn btn-default"}>Transections Report</Link>
                                                        : null
                                                }
                                                <button
                                                    title="Export Excel"
                                                    className="btn btn-default"
                                                    onClick={async () => {
                                                        this._export.save()

                                                        let logs = {};
                                                        logs.download = {
                                                            dataCount: this._export.props.data.length,
                                                            filename: this._export.props.fileName,
                                                        }
                                                        await axios.put(`${window.api_host}/report-logs`, { id: this.state.logId, data: logs });
                                                    }}
                                                >
                                                    โหลดรายงาน
                                                </button>
                                            </div>

                                            : null
                                    }
                                </div>

                                {
                                    this.state.is_loading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                {
                                    !this.state.is_loading
                                        && this.state.type !== 'all' ?
                                        <>
                                            <ExcelExport
                                                fileName={fileName}
                                                data={this.state.data}
                                                ref={exporter => this._export = exporter}
                                            >
                                                <Grid data={this.state.data} style={{ height: '650px' }}>
                                                    {/* <GridColumn field="dateFinished" title="เวลาส่งงาน" width="150" /> */}
                                                    <GridColumn field="bookAt" title="วันจอง" width="150" />
                                                    <GridColumn field="bookPaymentInfo" title="สถานะ" width="150" />
                                                    <GridColumn field="bookOn" title="เลข Booking" width="150" />
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn
                                                                field="user_data.displayName"
                                                                title="สมาชิก"
                                                                width="200"
                                                            />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn
                                                                field="user_data.phoneNumber"
                                                                title="เบอร์โทรศัพท์"
                                                                width="150"
                                                            />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="bookTime" title="เวลาที่ให้บริการ" width="120" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn
                                                                field="providerUser.displayName"
                                                                title="แม่บ้าน"
                                                                width="200"
                                                            />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn
                                                                field="providerUser.phoneNumber"
                                                                title="เบอร์โทรศัพท์"
                                                                width="150"
                                                            />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="bookedAtDateText" title="วันที่ให้บริการ" width="230" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="bookedAtDateText" title="วันที่ให้บริการ" width="230" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="bookedAtDateTime" title="เวลาให้บริการ" width="230" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="bookedAtDateTime" title="เวลาให้บริการ" width="230" />
                                                            : null
                                                    }
                                                    <GridColumn
                                                        field="address.name"
                                                        title="สถานที่บริการ"
                                                        width="250"
                                                    />
                                                    <GridColumn
                                                        field="address.placeType.name_th"
                                                        title="ประเภทสถานที่"
                                                        width="150"
                                                    />
                                                    <GridColumn
                                                        field="address.placeType.place_sub_type.description_th"
                                                        title="พืนที่"
                                                        width="150"
                                                    />
                                                    <GridColumn
                                                        field="bookTime"
                                                        title="กี่ชั่วโมง"
                                                        width="60"
                                                    />



                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="payment.statusText" title="ชำระเงิน" width="100" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="payment.paymentBy" title="ช่องทาง" width="100" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="payment.card.brand" title="ประเภทบัตร" width="120" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="payment.card.last_digits" title="ลงท้าย" width="100" />
                                                            : null
                                                    }


                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="priceProvider" title="รายได้แม่บ้าน" width="100" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="priceAgentCommissionFromProvider" title="คอมมิชชั่นแม่บ้าน" width="150" />
                                                            : null
                                                    }
                                                    {/* {
                                                    this.state.type === 'provider' ?
                                                        <GridColumn field="priceProviderPlusTime" title="รายเพิ่มชั่วโมง" width="100" />
                                                        : null
                                                } */}
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="priceProviderPlus" title="อื่นๆ" width="100" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="totalPriceUrgentProvider" title="ราคาเร่งด่วน" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="totalPriceProvider" title="รวม" width="100" />
                                                            : null
                                                    }



                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="price" title="ค่าบริการเรียกเก็บจาก สมาชิก" width="200" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="promotion.voucherCode" title="รหัสส่วนลด" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="priceDiscount" title="ส่วนลดราคา" width="100" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="priceUserAfterDiscount" title="ค่าบริการหลังหักส่วนลด" width="200" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="priceUserPlus" title="อื่นๆ" width="100" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="chargePrice" title="ภาษี 7%" width="100" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="priceUrgent" title="เร่งด่วน" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="priceUrgentVatUser" title="ภาษีเร่งด่วน" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field='adjustUser' title="เรียกเก็บจากลูกค้า" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field='adjustVat' title="ภาษี" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn field="totalUserPrice" title="รวม" width="100" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type == 'provider' ?
                                                            <GridColumn field="rentOutOfAppPrice" title="จองนอกแอป" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type == 'provider' ?
                                                            <GridColumn field="deductions" title="ปรับเงิน" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type == 'provider' ?
                                                            <GridColumn field="specialPrice" title="เงินพิเศษ" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type == 'provider' ?
                                                            <GridColumn field="transferPrice" title="รวมที่ต้องโอน" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type == 'provider' ?
                                                            <GridColumn field="providerUser.bankAccountName" title="ชื่อบัญชี" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type == 'provider' ?
                                                            <GridColumn field="providerUser.bankAccountNo" title="เลขบัญชี" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type == 'provider' ?
                                                            <GridColumn field="providerUser.bankCode" title="รหัสธนาคาร" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type == 'provider' ?
                                                            <GridColumn field="providerUser.bank" title="ชื่อธนาคาร" width="150" />
                                                            : null
                                                    }

                                                    {
                                                        this.state.type === 'user' ?
                                                            <GridColumn
                                                                field="providerUser.displayName"
                                                                title="แม่บ้าน"
                                                                width="200"
                                                            />
                                                            : null
                                                    }
                                                    <GridColumn field="starFromProvider" title="ความประทับใจต่อลูกค้า" width="200" />
                                                    <GridColumn field="user_report.report" title="รายงานจากลูกค้า" width="200" />
                                                    <GridColumn field="starFromUser" title="ความประทับใจต่อแม่บ้าน" width="200" />
                                                    <GridColumn field="provider_report.report" title="รายงานจากแม่บ้าน" width="200" />
                                                    <GridColumn field="bookStatusth"
                                                        title="สถานะ"
                                                        width="100"

                                                    />
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="priceApp" title="ส่วนต่างที่ app ไดรับ" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="priceAgentCommissionFromApp" title="คอมมิชชันแอพ" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="priceAppPlus" title="อื่นๆ" width="100" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="chargePrice" title="ภาษี 7% จากสมาชิก" width="100" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="totalPriceUrgentApp" title="ราคาเร่งด่วนรวมภาษี" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="adjustVat" title="ภาษีจากการปรับรายได้" width="150" />
                                                            : null
                                                    }
                                                    {
                                                        this.state.type === 'provider' ?
                                                            <GridColumn field="totalPriceApp" title="รวม" width="100" />
                                                            : null
                                                    }
                                                </Grid>
                                            </ExcelExport>
                                        </>
                                        : null
                                }
                                {
                                    !this.state.is_loading
                                        && this.state.type === 'all' ?
                                        <ExcelExport
                                            fileName={fileName}
                                            data={this.state.items_response}
                                            ref={exporter => this._export = exporter}
                                        >
                                            <Grid data={this.state.items_response}
                                                style={{ height: '650px' }}
                                            // sortable
                                            // sort={this.state.sort}
                                            // onSortChange={(e) => {
                                            //     console.log(e.sort)
                                            //     this.setState({
                                            //         sort: e.sort
                                            //     });
                                            //     // if(e.sort[0].field !== 'createdAt') {return;}
                                            //     // this.setState({
                                            //     //     sortField: e.sort[0].field,
                                            //     //     sortDir: e.sort[0].dir,
                                            //     //     is_loading: true
                                            //     // },()=> {
                                            //     //     this.fetchData()
                                            //     // })
                                            // }}
                                            >
                                                {/* <GridColumn field="dateFinished" title="เวลาส่งงาน" width="150" /> */}
                                                <GridColumn field="createdAtDate" title="รายได้วันที่" width="150"

                                                />
                                                <GridColumn field="bookPaymentInfo" title="รูปแบบ" width="150" />
                                                <GridColumn field="bookOn" title="Booking No" width="150" />

                                                <GridColumn field="userDisplayName" title="สมาชิก" width="150" />
                                                <GridColumn field="userPhoneNumber" title="เบอร์โทรสมาชิก" width="150" />
                                                <GridColumn field="providerDisplayname" title="แม่บ้าน" width="150" />
                                                <GridColumn field="providerPhoneNumber" title="เบอร์โทรแม่บ้าน" width="150" />

                                                <GridColumn field="createdAtDate" title="จองวันที่" width="150" />
                                                <GridColumn field="bookedAtDate" title="วันที่ให้บริการ" width="150" />
                                                <GridColumn field="time" title="เวลาให้บริการ" width="150" />
                                                <GridColumn field="bookTime" title="จำนวน/ชั่วโมง" width="150" />
                                                <GridColumn field="paymentMethod" title="วิธีการชำระเงิน" width="150" />
                                                <GridColumn field="rentType" title="ประเภทการจอง" width="150" />

                                                <GridColumn field="address" title="สถานที่" width="150" />
                                                <GridColumn field="work_detail" title="รายละเอียดงาน" width="150" />

                                                <GridColumn field="price" title="ค่าบริการ" width="150" />
                                                <GridColumn field="priceDiscount" title="ส่วนลด" width="150" />
                                                <GridColumn field="priceUserAfterDiscount" title="หักส่วนลด" width="150" />
                                                <GridColumn field="chargePrice" title="ภาษี 7%" width="150" />
                                                <GridColumn field="priceUrgent" title="เร่งด่วน" width="150" />
                                                <GridColumn field="priceUrgentVatUser" title="ภาษีเร่งด่วน" width="150" />
                                                <GridColumn field="adjustUser" title="เรียกเก็บจากลูกค้า" width="150" />
                                                <GridColumn field="adjustVat" title="ภาษีจากการปรับรายได้" width="150" />
                                                <GridColumn field="totalUserPrice" title="รวม" width="150" />

                                                <GridColumn field="priceApp" title="App ได้รับ" width="150" />
                                                <GridColumn field="totalPriceUrgentApp" title="เร่งด่วนรวมภาษี" width="150" />
                                                {/* <GridColumn field="priceUrgentApp" title="เร่งด่วน" width="150" />
                                                <GridColumn field="priceUrgentVat" title="ภาษีเร่งด่วน" width="150" /> */}
                                                <GridColumn field="priceAgentCommissionFromApp" title="คอมมิชชันแอพ" width="150" />
                                                <GridColumn field="appChargePrice" title="ภาษี 7%" width="150" />
                                                <GridColumn field="adjustVat" title="ภาษีจากการปรับรายได้" width="150" />
                                                <GridColumn field="totalPriceApp" title="รวม" width="150" />

                                                <GridColumn field="totalPriceProvider" title="แม่บ้านได้รับ" width="150" />
                                                <GridColumn field="totalPriceUrgentProvider" title="ราคาเร่งด่วน" width="150" />
                                                <GridColumn field="priceAgentCommissionFromProvider" title="คอมมิชชั่นแม่บ้าน" width="150" />

                                                <GridColumn field="rentOutOfAppPrice" title="จองนอกแอป" width="150" />
                                                <GridColumn field="deductions" title="ปรับเงิน" width="150" />
                                                <GridColumn field="specialPrice" title="เงินพิเศษ" width="150" />
                                                <GridColumn field="transferPrice" title="รวมที่ต้องโอน" width="150" />
                                                <GridColumn field="" title="สถานะไดฟ์" width="150" />
                                                <GridColumn field="bookStatusth" title="สถานะ" width="150" />
                                                <GridColumn field="" title="สถานะโอน" width="150" />
                                                <GridColumn field="" title="สลิป" width="150" />
                                                <GridColumn field="note" title="หมายเหตุ" width="150" />
                                                <GridColumn field="bookBankNo" title="เลขบัญชี" width="150" />
                                                <GridColumn field="bookBankName" title="ชื่อบัญชี" width="150" />
                                                <GridColumn field="bankCode" title="รหัสธนาคาร" width="150" />
                                                <GridColumn field="bank" title="ชื่อธนาคาร" width="150" />
                                                <GridColumn field="bookStatusth" title="สถานะ" width="150" />
                                            </Grid>
                                        </ExcelExport>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
