import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../Helper';
import DatePicker from 'react-datepicker';
import CustomDateInput from './../Components/CustomDateInput';
import { db } from './../firebase';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { orderBy } from '@progress/kendo-data-query';
import '@progress/kendo-theme-default/dist/all.css';
const axios = require('axios').default;

export default class UsersReport extends React.Component {
    constructor() {
        super();
        let defaultStatus = 'done';
        let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
        let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
        let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : false;
        let sortField = _sortField || "bookedAt";
        if (!_sortField) {
            if (type == 'all') {
                if (booking_type && booking_type == 'admin_created') {
                    sortField = 'createdAt';
                    defaultStatus = 'all'
                }
            }
            if (type == 'user') {
                sortField = 'createdAt';
                defaultStatus = 'all'
            }

        }
        let sort_data = {
            sortField: sortField,
            // sortField: "bookedAt",
            sortDir: 'desc',
            sort: [{ field: 'bookedAt', dir: 'desc' }]
        };

        this.state = {
            data: [],
            items_response: [],
            is_loading: true,
            type: type,
            start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
            end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
            user_type: Helper.getParameterByName('user_type') ? Helper.getParameterByName('user_type') : false,
            ...sort_data
        }
    }

    componentDidUpdate(prevProps) {
        let defaultStatus = 'done';
        let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
        let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
        let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : false;
        let sortField = _sortField || "bookedAt";
        if (!_sortField) {
            if (
                (type == 'all' && (booking_type && booking_type == 'admin_created'))
                || type == 'user'
            ) {
                sortField = 'createdAt';
                defaultStatus = 'all'
            }

        }
        let sort_data = {
            sortField: sortField,
            // sortField: "bookedAt",
            sortDir: 'desc',
            sort: [{ field: 'bookedAt', dir: 'desc' }]
        };
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                data: [],
                items_response: [],
                is_loading: true,
                start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
                end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
                user_type: Helper.getParameterByName('user_type') ? Helper.getParameterByName('user_type') : false,
                ...sort_data
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                data: [],
                items_response: [],
                is_loading: true,
                start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
                end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
                user_type: Helper.getParameterByName('user_type') ? Helper.getParameterByName('user_type') : false,
                ...sort_data
            }, () => {
                this.fetchData();
            })
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        let filter = {};
        filter.sortField = this.state.sortField;
        filter.sortDir = this.state.sortDir;
        filter.user_type = this.state.user_type;
        let start_date = new Date(parseFloat(this.state.start_date));
        let end_date = new Date(parseFloat(this.state.end_date));
        filter.start_date = `${start_date.getDate()}-${start_date.getMonth()}-${start_date.getFullYear()}`;
        filter.end_date = `${end_date.getDate()}-${end_date.getMonth()}-${end_date.getFullYear()}`;
        try {
            let res = await axios.get(`${window.api_host}/users-report?${window.$.param(filter)}`);
            let items = [];
            let count = 0;
            if (res && res.data && res.data.success) {
                for (const item of res.data.items) {
                    let _item = { ...item };
                    _item.userType = 'ในระบบ'
                    _item.createdDate = Helper.getDateThai(item.createdAt, { is_show_time: true })
                    if (!item.acceptTerms) {
                        _item.userType = 'นอกระบบ'
                        _item.phoneNumber = _item.guestPhoneNumber;
                        _item.email = _item.guestEmail;
                    }
                    items.push(_item);
                }
                count = res.data.count;
            }
            this.setState({
                data: items,
                count: count,
                is_loading: false,
            })
        } catch (error) {

        }
    }

    render() {
        let fileName = 'รายงานสมาชิก';
        fileName += ' ณ วันที่ ' + Helper.getDateThai(parseFloat(this.state.start_date)) + ' - ' + Helper.getDateThai(parseFloat(this.state.end_date))
        let page_title = 'รายงานสมาชิก';
        let page_sub_title = "";
        page_sub_title += ' ณ วันที่ ' + Helper.getDateThai(parseFloat(this.state.start_date)) + ' - ' + Helper.getDateThai(parseFloat(this.state.end_date))
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">{page_title}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/booking">รายการผู้ใช้งาน</Link></li>
                                    <li className="breadcrumb-item active">
                                        รายงาน Booking
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " >
                                <h3 className="card-title" style={{ display: 'flex', alignItems: 'center' }}>รายการสมาชิก  {page_sub_title} {!this.state.is_loading ? `จำนวน ${this.state.count} คน` : null}</h3>
                            </div>

                            <div className="card-body">
                                {/* {FILTER} */}
                                <div className={"flex row"}
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <form
                                        className="row flex"
                                        ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                ...data
                                            })
                                            navigate(url)
                                        }}
                                    >
                                        <input type="hidden" name="type" value={this.state.type} />
                                        <div style={{ marginRight: '0.5rem' }}>
                                            <DatePicker
                                                disabled={this.state.is_loading}
                                                onChange={date => {

                                                    let start = new Date(date);
                                                    start.setHours(0, 0, 0, 0);
                                                    this.setState({
                                                        start_date: start.getTime()
                                                    }
                                                        // , () => {
                                                        //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                        // }
                                                    )
                                                }}
                                                selectsStart
                                                startDate={this.state.start_date}
                                                endDate={this.state.end_date}
                                                locale="th-TH"
                                                selected={Helper.getDateToEdit(this.state.start_date)}
                                                placeholderText={"วัน / เดือน / ปี"}
                                                name={"start_date"}
                                                dateFormat="MMMM d, yyyy 00:00:00"
                                                withPortal
                                                onCalendarOpen={() => { Helper.renderDate() }}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => {
                                                    date = new Date(date);
                                                    let now = new Date();
                                                    // months_th
                                                    const years = [];
                                                    let i;
                                                    for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                        years.push(i)
                                                    }
                                                    return (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className={"btn btn-primary "}
                                                                type={"button"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                            >
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <select
                                                                className={"form-control"}
                                                                value={date.getFullYear()}
                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {years.map(option => (
                                                                    <option key={option} value={option} >
                                                                        {option + 543}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className={"form-control"}
                                                                value={window.months_th[date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(window.months_th.indexOf(value))
                                                                }
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {window.months_th.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                className={"btn btn-primary"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                type={"button"}
                                                            >
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                    )
                                                }}
                                                customInput={<CustomDateInput />}
                                            />
                                            <input type="hidden" id="start_date" name="start_date" value={this.state.start_date} onChange={() => { console.log('change') }} readOnly={true} />
                                        </div>
                                        <div style={{ marginRight: '0.5rem' }}>
                                            <DatePicker
                                                disabled={this.state.is_loading}
                                                onChange={date => {
                                                    let end = new Date(date);
                                                    end.setHours(23, 59, 0, 0);
                                                    this.setState({
                                                        end_date: end.getTime()
                                                    }
                                                        // , () => {
                                                        //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                        // }
                                                    )
                                                }}
                                                selectsEnd
                                                startDate={this.state.start_date}
                                                endDate={this.state.end_date}
                                                locale="th-TH"
                                                selected={Helper.getDateToEdit(this.state.end_date)}
                                                placeholderText={"วัน / เดือน / ปี"}
                                                name={"end_date"}
                                                dateFormat="MMMM d, yyyy 23:59:00"
                                                minDate={this.state.start_date}
                                                withPortal
                                                onCalendarOpen={() => { Helper.renderDate() }}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => {
                                                    date = new Date(date);
                                                    let now = new Date();
                                                    // months_th
                                                    const years = [];
                                                    let i;
                                                    for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                        years.push(i)
                                                    }
                                                    return (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className={"btn btn-primary "}
                                                                type={"button"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                            >
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <select
                                                                className={"form-control"}
                                                                value={date.getFullYear()}
                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {years.map(option => (
                                                                    <option key={option} value={option} >
                                                                        {option + 543}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className={"form-control"}
                                                                value={window.months_th[date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(window.months_th.indexOf(value))
                                                                }
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {window.months_th.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                className={"btn btn-primary"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                type={"button"}
                                                            >
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                    )
                                                }}
                                                customInput={<CustomDateInput />}
                                            />
                                            <input type="hidden" id="end_date" name="end_date" value={this.state.end_date} onChange={() => { console.log('change') }} readOnly={true} />
                                        </div>
                                        <div style={{ marginRight: '0.5rem' }}>
                                            <select className='form-control' name='user_type' defaultValue={this.state.user_type}>
                                                <option value="all">แสดงทั้งหมด</option>
                                                {/* <option value="wait">รอ</option> */}
                                                <option value="formal">ในระบบ</option>
                                                <option value="informal">นอกระบบ</option>
                                                {/* <option value="cancel">ยกเลิก</option> */}
                                            </select>
                                        </div>

                                        <div className="input-group-append" style={{ marginLeft: 5 }}>
                                            <input type="hidden" name="booking_type" value={this.state.booking_type} />
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i> ค้นหา
                                            </button>
                                        </div>
                                    </form>
                                    {
                                        !this.state.is_loading ?
                                            <div style={{ marginLeft: 'auto' }}>

                                                <button
                                                    title="Export Excel"
                                                    className="btn btn-default"
                                                    onClick={async () => {
                                                        this._export.save()

                                                        let logs = {};
                                                        logs.download = {
                                                            dataCount: this._export.props.data.length,
                                                            filename: this._export.props.fileName,
                                                        }
                                                        await axios.put(`${window.api_host}/report-logs`, { id: this.state.logId, data: logs });
                                                    }}
                                                >
                                                    โหลดรายงาน
                                                </button>
                                            </div>
                                            : null
                                    }
                                </div>


                                {
                                    this.state.is_loading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                {
                                    !this.state.is_loading ?
                                        <ExcelExport
                                            fileName={fileName}
                                            data={this.state.data}
                                            ref={exporter => this._export = exporter}
                                        >
                                            <Grid data={this.state.data} style={{ height: '650px' }}>
                                                <GridColumn field="userType" title="ประเภท" width={100} />
                                                <GridColumn field="displayName" title="ชื่อ" />
                                                <GridColumn field="phoneNumber" title="เบอร์โทรศัพท์" />
                                                <GridColumn field="email" title="อีเมล์" />
                                                <GridColumn field="starFloat" title="คะแนนรีวิว" />
                                                <GridColumn field="countBooking" title="จำนวน Booking" />
                                                <GridColumn field="createdDate" title="วันสร้าง" />
                                            </Grid>
                                        </ExcelExport>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}