import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../../Helper';
import DatePicker from 'react-datepicker';
import CustomDateInput from './../../Components/CustomDateInput';
import ReportProviderWallet from '../../Components/ReportProviderWallet';
import { db } from './../../firebase';
import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { orderBy } from '@progress/kendo-data-query';
import BankCode from './../../bankCode';
import '@progress/kendo-theme-default/dist/all.css';
import thailand from "./../../thailand.json";
const axios = require('axios').default;
const defaultOrder = "createdAt"
const prepareReportData = (defaultData) => {
    let data = { ...defaultData }
    data.serviceAt = Helper.getDateNumber(defaultData.bookedAt)
    data.serviceTime = Helper.getTime(defaultData.bookedAt);
    data.providerDisplayname = data.providerUser && data.providerUser.displayName ? data.providerUser.displayName : null;
    data.providerUser.bankAccountNo = defaultData.providerUser?.bankAccountNo ? defaultData.providerUser.bankAccountNo.replaceAll('-', "") : ''
    data.providerUser.personalNo = defaultData.providerUser?.personalNo ? defaultData.providerUser.personalNo.replaceAll(' ', "") : ''
    data.bookUid = defaultData.id;
    data.providerUserUid = defaultData.providerUserUid;
    return data
}
let abort
const defaultParams = () => {
    let defaultStatus = 'finish';
    let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
    let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
    let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : false;
    if (!_sortField) {
        if (type == 'all') {
            if (booking_type && booking_type == 'admin_created') {
                defaultStatus = 'all'
            }
        }
        if (type == 'user') {
            defaultStatus = 'all'
        }

    }

    let provinces = [];
    let amphoes = [];
    for (const [key, value] of Object.entries(thailand)) {
        for (const [_key, _value] of Object.entries(value.amphoes)) {
            amphoes.push({ code: _key, amphoe: _value.name.th });
            // console.log(`${_key}: ${_value.name.th}`);
        }
        provinces.push({
            code: key,
            province: value.name.th,
            // amphoes: amphoes
        })
    }
    let params = {
        all_provinces: provinces,
        all_amphoes: amphoes,
        isClearDevAccount: Helper.getParameterByName('isClearDevAccount') && Helper.getParameterByName('isClearDevAccount') == `false` ? false : true,
        render_start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
        render_end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
        start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
        end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
        booking_type: Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : `all`,
        status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : defaultStatus,
        finishedFilter: Helper.getParameterByName('finishedFilter') ? Helper.getParameterByName('finishedFilter') : 'all'
    }
    return params;
}
export default class BookingReport extends React.Component {
    constructor() {
        super();
        let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
        let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
        let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : defaultOrder;
        let sortField = _sortField || defaultOrder;
        if (!_sortField) {
            if (type == 'all') {
                if (booking_type && booking_type == 'admin_created') {
                    sortField = defaultOrder;
                }
            }
            if (type == 'user') {
                sortField = defaultOrder;
            }

        }
        let sort_data = {
            sortField: sortField,
            // sortField: "bookedAt",
            sortDir: 'desc',
            sort: [{ field: defaultOrder, dir: 'desc' }]
        };
        // if (type === 'all') {
        //     sort_data = {
        //         sortField: 'createdAt',
        //         sortDir: 'desc',
        //         sort: [{ field: 'createdAt', dir: 'desc' }]
        //     }
        // }
        this.state = {
            data: [],
            items_response: [],
            transfer_data: false,
            revenue_data: false,
            is_loading: true,
            type: type,
            columnSort: 'providerName',
            ...defaultParams(),
            ...sort_data
        }
    }

    componentDidUpdate(prevProps) {
        let defaultStatus = 'done';
        let type = Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false;
        let booking_type = Helper.getParameterByName('booking_type') ? Helper.getParameterByName('booking_type') : false;
        let _sortField = Helper.getParameterByName('sortField') ? Helper.getParameterByName('sortField') : false;
        let sortField = _sortField || defaultOrder;
        if (!_sortField) {
            if (
                (type == 'all' && (booking_type && booking_type == 'admin_created'))
                || type == 'user'
            ) {
                sortField = defaultOrder;
                defaultStatus = 'all'
            }

        }
        let sort_data = {
            sortField: sortField,
            // sortField: "bookedAt",
            sortDir: 'desc',
            sort: [{ field: defaultOrder, dir: 'desc' }]
        };
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                data: [],
                items_response: [],
                transfer_data: false,
                revenue_data: false,
                is_loading: true,
                ...defaultParams(),
                ...sort_data
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                data: [],
                items_response: [],
                transfer_data: false,
                revenue_data: false,
                is_loading: true,
                ...defaultParams(),
                ...sort_data
            }, () => {
                this.fetchData();
            })
        }
    }



    componentDidMount() {
        this.fetchData();
    }

    halfDayChecking(date) {
        let start_date = new Date(parseFloat(this.state.start_date));
        let end_date = new Date(parseFloat(this.state.end_date));
        let isCanPush = true;
        if (this.state.finishedFilter == 'halfday') {
            let serviceDate = new Date(date)
            // let dateFinished = new Date(date - (7 * 3600 * 1000));
            let dateFinished = new Date(date);
            // prepare.dateFinished = Helper.getDateThai(dateFinished, { is_show_time: true })
            if (dateFinished) {
                if (start_date.getDate() == end_date.getDate()
                    && start_date.getMonth() == end_date.getMonth()
                    && start_date.getFullYear() == end_date.getFullYear()
                    && end_date.getDate() == serviceDate.getDate()
                ) { // ถ้าเลือกวันเดียวกัน
                    if (dateFinished.getHours() > 12
                        || (
                            dateFinished.getHours() == 12
                            && dateFinished.getMinutes() > 0
                        )
                    ) { // ถ้าเกินเที่ยง
                        // console.log(`เกินเที่ยง - วันเดียวกัน`)
                        isCanPush = false;
                    }
                } else {

                    let startHalfDay = new Date(parseFloat(this.state.start_date));
                    let endHalfDay = new Date(parseFloat(this.state.end_date));
                    startHalfDay.setHours(12, 0, 0);
                    endHalfDay.setHours(12, 0, 0);


                    if (startHalfDay.getTime() >= dateFinished.getTime()) {
                        isCanPush = false;
                    }
                    if (endHalfDay.getTime() <= dateFinished.getTime()) {
                        isCanPush = false;
                    }
                }
            }
        }

        return isCanPush
    }

    async fetchData() {
        // console.log(this.state.all_provinces)
        // console.log(this.state.all_amphoes)
        // console.log(this.state.sortField, this.state.sortDir);
        let qs = {}
        qs.sortField = this.state.sortField;
        qs.sortDir = this.state.sortDir;
        // qs.start_date = new Date(parseFloat(this.state.start_date));
        // qs.start_date.setHours(0, 0, 1);
        // qs.end_date = new Date(parseFloat(this.state.end_date));
        // qs.end_date.setHours(23, 59, 0);
        // qs.start_date = qs.start_date.getTime() + (7 * 60 * 60 * 1000);
        // qs.end_date = qs.end_date.getTime() + (7 * 60 * 60 * 1000);
        // qs.start_date = qs.start_date.getTime();
        // qs.end_date = qs.end_date.getTime();
        let start_date = new Date(parseFloat(this.state.start_date));
        let end_date = new Date(parseFloat(this.state.end_date));
        qs.start_date = `${start_date.getDate()}-${start_date.getMonth()}-${start_date.getFullYear()}`;
        qs.end_date = `${end_date.getDate()}-${end_date.getMonth()}-${end_date.getFullYear()}`;
        qs.type = this.state.type;
        qs.status = this.state.status;
        qs.booking_type = this.state.booking_type;
        qs.adminUid = this.props.adminUid;
        // console.log(qs)
        try {
            let res = await axios.get(`${window.api_host}/booking-report?${window.$.param(qs)}`);
            // console.log(res);
            let _data = [];
            let count = 0;
            let provider_all = 0;
            let repeated_count = 0;
            let count_plusTime = 0;
            if (res.data.success) {
                for (const data of res.data.data) {
                    if (this.state.isClearDevAccount && data.providerUser.devAccount) {
                        continue;
                    }
                    // console.log(data)
                    let prepare = {};
                    let summary = Helper.bookingSummary({ booking: data, payment: data.payment });
                    prepare.firstBook = data.isFirstBook ? "ใช้งานครั้งแรก" : null;
                    prepare.price = (parseFloat(data.price))
                    prepare.plustime_total_price = parseFloat(0);
                    data.bookedAtDate = Helper.getDateNumber(data.bookedAt);
                    data.bookedAtDateText = Helper.getDateNumber(data.bookedAt, { is_show_time: false });
                    data.bookedAtDateTime = Helper.getTime(data.bookedAt);
                    prepare.bookedAtDateTime = Helper.getTime(data.bookedAt);
                    data.province = false;
                    if (data?.address?.province) {
                        data.province = this.GetProvince(data.address.province)
                    }
                    prepare.province = data.province;
                    // console.log(data.address)
                    data.amphoe = false;
                    if (data?.address?.amphoe) {
                        data.amphoe = this.GetAmphoe(data.address.amphoe)
                    }
                    prepare.amphoe = data.amphoe;
                    // prepare.priceApp = (parseFloat(data.priceApp) - parseFloat(summary.tax_total_price));
                    prepare.priceApp = !data.changeBookPrice && data.priceAppWithoutAgent ? parseFloat(data.priceAppWithoutAgent) : data.priceApp ? parseFloat(data.priceApp) : 0;
                    prepare.priceDiscount = parseFloat(data.priceDiscount) > parseFloat(data.price) ? parseFloat(data.price) : parseFloat(data.priceDiscount);
                    prepare.totalPriceProvider = parseFloat(data.priceProvider) > 0 ? parseFloat(data.priceProvider) : (350);
                    prepare.chargePrice = parseFloat(data.chargePrice) <= 0 ? 0 : parseFloat(data.chargePrice);
                    if (prepare.chargePrice < 0) {
                        prepare.chargePrice = 0;
                    }
                    prepare.priceAgentCommissionFromProvider = parseFloat(data.priceAgentCommissionFromProvider) || 0;
                    prepare.priceAgentCommissionFromApp = parseFloat(data.priceAgentCommissionFromApp) || 0;
                    prepare.priceProvider = !data.changeBookPrice && parseFloat(data.priceProviderWithoutAgent) ? parseFloat(data.priceProviderWithoutAgent) : parseFloat(data.priceProvider) > 0 ? parseFloat(data.priceProvider) : (350);
                    prepare.totalUserPrice = ((parseFloat(data.price) + parseFloat(prepare.chargePrice)) - parseFloat(prepare.priceDiscount)).toFixed(2)
                    prepare.priceUserAfterDiscount = (parseFloat(prepare.price) - parseFloat(prepare.priceDiscount));
                    let work_time = parseFloat(data.bookTime.time);
                    let _plusTime = 0;
                    let priceProviderPlusTime = 0;
                    prepare.priceProviderPlusTime = priceProviderPlusTime;
                    prepare.plusTime = _plusTime;
                    prepare.bookTime = work_time;
                    prepare.totalPriceApp = ((parseFloat(prepare.priceApp) + parseFloat(prepare.chargePrice)) - prepare.priceAgentCommissionFromApp).toFixed(2);
                    prepare.providerUser = data.providerUser;
                    if (data.providerUser && data.providerUser.bank) {
                        let getBank = BankCode.filter((e) => e.name.indexOf(data.providerUser.bank) != -1)[0];
                        if (getBank) {
                            data.providerUser.bankCode = getBank.code;
                        }
                        prepare.providerUser.bankAccountNo = data.providerUser.bankAccountNo.replaceAll('-', '').replaceAll(' ', '').trim()
                    }
                    if (data.providerUser) {
                        prepare.providerUser.displayName = data.providerUser.firstName ? `${data.providerUser.firstName} ${data.providerUser.lastName}` : data.providerUser.displayName

                    }
                    prepare.providerDisplayname = prepare.providerUser?.displayName || null;
                    prepare.paymentMethod = ""
                    if (data.payment) {
                        if (data.payment.paymentBy == 'บัตรเครดิต') {
                            prepare.paymentMethod = "บัตรเครดิต";
                        }
                    }
                    if (data.type && data.type == 'wegate') {
                        if (data.paymentStatus == 'success') {
                            prepare.payment = {
                                statusText: 'สำเร็จ',

                            }
                        }
                        let channel = data.channel;
                        prepare.payment = { ...prepare.payment }
                        if (channel == 'PP') {
                            prepare.payment.paymentBy = "Ksher (PromptPay)";
                            prepare.paymentMethod = "Ksher (PromptPay)";
                        }
                        if (channel == 'TM') {
                            prepare.payment.paymentBy = "Ksher (True Money)";
                            prepare.paymentMethod = "Ksher (True Money)";
                        }
                        if (channel == 'AL') {
                            prepare.payment.paymentBy = "Ksher (Alipay)";
                            prepare.paymentMethod = "Ksher (Alipay)";
                        }
                        if (channel == 'SP') {
                            prepare.payment.paymentBy = "Ksher (ShopeePay)";
                            prepare.paymentMethod = "Ksher (ShopeePay)";
                        }
                        if (channel == 'LP') {
                            prepare.payment.paymentBy = "Ksher (LINE Pay)";
                            prepare.paymentMethod = "Ksher (LINE Pay)";
                        }
                        if (channel == 'WC') {
                            prepare.payment.paymentBy = "Ksher (WeChat Pay)";
                            prepare.paymentMethod = "Ksher (WeChat Pay)";
                        }
                    }
                    prepare.bookPaymentInfo = "แอพพลิเคชั่น";
                    prepare.totalUserPrice = parseFloat(prepare.totalUserPrice)
                    prepare.totalPriceApp = parseFloat(prepare.totalPriceApp)
                    if (!data.user_data) {
                        prepare.user_data = {
                            displayName: data.user.displayName,
                            phoneNumber: data.user.guestPhoneNumber,
                        }
                    }
                    if (data.createdAdmin) {
                        prepare.paymentMethod = "โอนเงิน";
                        prepare.bookPaymentInfo = "ระบบหลังบ้าน";
                        prepare.payment = {
                            statusText: 'สำเร็จ',
                            paymentBy: 'โอนเงิน'
                        }
                    }


                    prepare.bookActionType = `ปกติ (${prepare.bookPaymentInfo})`;
                    prepare.actionDate = Helper.getDateNumber(data.createdAt);
                    prepare.actionDateSort = new Date(data.createdAt);
                    prepare.bookAt = Helper.getDateNumber(data.createdAt);


                    prepare.rentOutOfAppPrice = 0;
                    // Urgent calculate
                    prepare.priceUrgent = parseFloat(data.priceUrgent) || 0;
                    prepare.priceUrgentApp = data.priceUrgentApp && (data.bookStatus == 'done' || data.bookStatus == 'finish') ? parseFloat(data.priceUrgentApp) : 0;
                    prepare.priceUrgentProvider = data.priceUrgentProvider && (data.bookStatus == 'done' || data.bookStatus == 'finish') ? parseFloat(data.priceUrgentProvider) : 0;
                    prepare.priceUrgentVat = data.priceUrgentVat && (data.bookStatus == 'done' || data.bookStatus == 'finish') ? parseFloat(data.priceUrgentVat) : 0;
                    prepare.priceUrgentVatUser = data.priceUrgentVat ? parseFloat(data.priceUrgentVat) : 0;
                    prepare.priceUrgentSummaryApp = prepare.priceUrgentApp + prepare.priceUrgentVat;
                    prepare.totalPriceUrgent = prepare.priceUrgent + prepare.priceUrgentVat;
                    prepare.totalPriceUrgentProvider = prepare.priceUrgentProvider;
                    prepare.totalPriceUrgentApp = prepare.priceUrgentApp + prepare.priceUrgentVat;

                    prepare.totalPriceApp += prepare.totalPriceUrgentApp
                    prepare.totalPriceApp += prepare.adjustVat

                    // prepare.transferPrice = (parseFloat(prepare.totalPriceProvider) - parseFloat(prepare.deductions)) + parseFloat(prepare.specialPrice);

                    prepare.totalPriceProvider = parseFloat(prepare.totalPriceProvider) + parseFloat(prepare.totalPriceUrgentProvider)

                    prepare.transferPrice = (((parseFloat(data.totalPriceProvider) - parseFloat(prepare.deductions))) + parseFloat(prepare.specialPrice)) + parseFloat(prepare.totalPriceUrgentProvider);
                    // สูตรรวมที่ต้องโอนของแม่บ้าน ((แม่บ้านได้รับ - คอมมิชชั่น) - หักเงิน + เงินพิเศษ) + เร่งด่วน
                    prepare.transferPriceRevenue = prepare.transferPrice;

                    let isCanPush = true;
                    if (this.state.finishedFilter == 'halfday') {
                        let serviceDate = new Date(data.bookedAt)
                        let dateFinished = new Date(data.finishAt - (7 * 3600 * 1000));
                        // prepare.dateFinished = Helper.getDateThai(dateFinished, { is_show_time: true })
                        if (dateFinished) {
                            if (start_date.getDate() == end_date.getDate()
                                && start_date.getMonth() == end_date.getMonth()
                                && start_date.getFullYear() == end_date.getFullYear()
                                && end_date.getDate() == serviceDate.getDate()
                            ) { // ถ้าเลือกวันเดียวกัน
                                if (dateFinished.getHours() > 12
                                    || (
                                        dateFinished.getHours() == 12
                                        && dateFinished.getMinutes() > 0
                                    )
                                ) { // ถ้าเกินเที่ยง
                                    // console.log(`เกินเที่ยง - วันเดียวกัน`)
                                    isCanPush = false;
                                }
                            } else {

                                let startHalfDay = new Date(parseFloat(this.state.start_date));
                                let endHalfDay = new Date(parseFloat(this.state.end_date));
                                startHalfDay.setHours(12, 0, 0);
                                endHalfDay.setHours(12, 0, 0);


                                if (startHalfDay.getTime() >= dateFinished.getTime()) {
                                    isCanPush = false;
                                }
                                if (endHalfDay.getTime() <= dateFinished.getTime()) {
                                    isCanPush = false;
                                }
                            }
                        }
                    }
                    // console.log(`isCanPush`, isCanPush)
                    if (isCanPush) {

                        _data.push({
                            ...data,
                            ...prepare
                        })


                    }

                }
                count = res.data.count_all;
                provider_all = res.data.provider_all;
                if (this.state.sortField == 'createdAt') {
                    let adjustRef = await axios.get(`${window.api_host}/book-user-adjust-report?${window.$.param(qs)}`);
                    if (adjustRef.data.success) {
                        for (const adj of adjustRef.data.items) {
                            if (this.state.isClearDevAccount && adj.providerUser.devAccount) {
                                continue;
                            }
                            let adjPrepare = { ...adj }
                            adjPrepare.firstBook = adj.isFirstBook ? "ใช้งานครั้งแรก" : null;
                            adjPrepare.actionDateSort = new Date(adj.adjustUpdateAt);
                            adjPrepare.actionDate = Helper.getDateNumber(adj.adjustUpdateAt);
                            adjPrepare.bookedAtDateText = Helper.getDateNumber(adj.adjustUpdateAt, { is_show_time: false });
                            adjPrepare.bookedAtDateTime = Helper.getTime(adj.adjustUpdateAt);

                            adj.province = false;
                            if (adj?.address?.province) {
                                adj.province = this.GetProvince(adj.address.province)
                            }
                            adjPrepare.province = adj.province;
                            // console.log(data.address)
                            adj.amphoe = false;
                            if (adj?.address?.amphoe) {
                                adj.amphoe = this.GetAmphoe(adj.address.amphoe)
                            }
                            adjPrepare.amphoe = adj.amphoe;
                            let isCanPush = this.halfDayChecking(adj.adjustUpdateAt)
                            if (isCanPush) {
                                _data.push({
                                    ...adjPrepare
                                })
                            }
                        }
                    }
                    let plustimeRef = await axios.get(`${window.api_host}/book-user-plus-time-report?${window.$.param(qs)}`);
                    if (plustimeRef.data.success) {
                        for (const item of plustimeRef.data.items) {
                            if (this.state.isClearDevAccount && item.providerUser.devAccount) {
                                continue;
                            }
                            let plustimePrepare = { ...item }
                            plustimePrepare.firstBook = item.isFirstBook ? "ใช้งานครั้งแรก" : null;
                            plustimePrepare.actionDateSort = new Date(item.plusTimeUpdateAt);
                            plustimePrepare.actionDate = Helper.getDateNumber(item.plusTimeUpdateAt);
                            plustimePrepare.bookedAtDateText = Helper.getDateNumber(item.plusTimeUpdateAt, { is_show_time: false });
                            plustimePrepare.bookedAtDateTime = Helper.getTime(item.plusTimeUpdateAt);
                            item.province = false;
                            if (item?.address?.province) {
                                item.province = this.GetProvince(item.address.province)
                            }
                            plustimePrepare.province = item.province;
                            // console.log(data.address)
                            item.amphoe = false;
                            if (item?.address?.amphoe) {
                                item.amphoe = this.GetAmphoe(item.address.amphoe)
                            }
                            plustimePrepare.amphoe = item.amphoe;
                            let isCanPush = this.halfDayChecking(item.plusTimeUpdateAt)
                            if (isCanPush) {
                                _data.push({
                                    ...plustimePrepare
                                })
                            }
                        }
                    }
                    let urgentRef = await axios.get(`${window.api_host}/book-user-urgent-report?${window.$.param(qs)}`);
                    if (urgentRef.data.success) {
                        for (const item of urgentRef.data.items) {
                            if (this.state.isClearDevAccount && item.providerUser.devAccount) {
                                continue;
                            }
                            let urgentPrepare = { ...item }
                            urgentPrepare.firstBook = item.isFirstBook ? "ใช้งานครั้งแรก" : null;
                            urgentPrepare.actionDateSort = new Date(item.urgentUpdateAt);
                            urgentPrepare.actionDate = Helper.getDateNumber(item.urgentUpdateAt);
                            urgentPrepare.bookedAtDateText = Helper.getDateNumber(item.urgentUpdateAt, { is_show_time: false });
                            urgentPrepare.bookedAtDateTime = Helper.getTime(item.urgentUpdateAt); item.province = false;
                            if (item?.address?.province) {
                                item.province = this.GetProvince(item.address.province)
                            }
                            urgentPrepare.province = item.province;
                            // console.log(data.address)
                            item.amphoe = false;
                            if (item?.address?.amphoe) {
                                item.amphoe = this.GetAmphoe(item.address.amphoe)
                            }
                            urgentPrepare.amphoe = item.amphoe;
                            let isCanPush = this.halfDayChecking(item.urgentUpdateAt)

                            if (isCanPush) {
                                _data.push({
                                    ...urgentPrepare
                                })
                            }
                        }
                    }
                }
            }



            _data = _data.sort(function (a, b) {
                if (a.user_data.displayName < b.user_data.displayName) {
                    return -1;
                }
                if (a.user_data.displayName > b.user_data.displayName) {
                    return 1;
                }
                return 0;
            });
            _data = _data.sort(function (a, b) {
                if (a.actionDate < b.actionDate) {
                    return 1;
                }
                if (a.actionDate > b.actionDate) {
                    return -1;
                }
                return 0;
            });
            // console.log(_data)

            this.setState({
                data: _data,
                is_loading: false,
                all_items: count,
                provider_all: provider_all,
                logId: res.data.logId,
            }, () => {
                if (repeated_count > 0) {
                    window.$(document).Toasts('create', {
                        title: 'เกิดข้อผิดพลาด',
                        body: "มีข้อมูลซ้ำกรุณาตรวจสอบอีกครั้ง",
                        icon: 'fas fa-exclamation',
                        autohide: true,
                        delay: 3000,
                        class: "bg-warning",
                    })
                }
            })
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: "ไม่สามารถดึงข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.setState({
                data: [],
                is_loading: false,
                all_items: 0,
                provider_all: 0,
            })
        }

    }



    sortData() {
        console.log("sortData")
        let sortColumn = this.state.columnSort;
        let _data = this.state.data;
        _data = _data.sort(function (a, b) {
            if (a.bookedAt < b.bookedAt) {
                return 1;
            }
            if (a.bookedAt > b.bookedAt) {
                return -1;
            }
            return 0;
        });
        if (sortColumn == 'providerName') {
            _data = _data.sort(function (a, b) {
                console.log(a.providerDisplayname, b.providerDisplayname)
                if (a.providerDisplayname < b.providerDisplayname) {
                    return -1;
                }
                if (a.providerDisplayname > b.providerDisplayname) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState({
            data: _data,
            is_loading: false,
        })
    }

    render() {
        let page_title = ""
        let fileName = 'รายงาน';

        fileName += 'สมาชิก'
        page_title = "รายงานสมาชิก"

        let page_sub_title = "";
        page_sub_title += ' ณ วันที่ ' + Helper.getDateThai(parseFloat(this.state.render_start_date)) + ' - ' + Helper.getDateThai(parseFloat(this.state.render_end_date))

        fileName += ' ' + Helper.getDateThai(parseFloat(this.state.start_date)) + ' - ' + Helper.getDateThai(parseFloat(this.state.end_date));
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายงาน Booking {page_title}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/booking">รายการ Booking</Link></li>
                                    <li className="breadcrumb-item active">
                                        รายงาน Booking
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " >
                                <h3 className="card-title" style={{ display: 'flex', alignItems: 'center' }}>รายการ Booking  {page_sub_title}</h3>
                            </div>

                            <div className="card-body">
                                <div className={"flex row"}
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <form
                                        className="row flex"
                                        ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                ...data
                                            })
                                            navigate(url)
                                        }}
                                    >
                                        <input type="hidden" name="isClearDevAccount" value={this.state.isClearDevAccount} />
                                        <input type="hidden" name="type" value={this.state.type} />
                                        <div style={{ marginRight: '0.5rem' }}>
                                            <DatePicker
                                                disabled={this.state.is_loading}
                                                onChange={date => {

                                                    let start = new Date(date);
                                                    start.setHours(0, 0, 0, 0);
                                                    let end_date = this.state.end_date
                                                    if (start.getTime() > end_date) {
                                                        end_date = start.getTime()
                                                    }
                                                    this.setState({
                                                        start_date: start.getTime(),
                                                        end_date: end_date
                                                    }
                                                        // , () => {
                                                        //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                        // }
                                                    )
                                                }}
                                                selectsStart
                                                startDate={this.state.start_date}
                                                endDate={this.state.end_date}
                                                locale="th-TH"
                                                selected={Helper.getDateToEdit(this.state.start_date)}
                                                placeholderText={"วัน / เดือน / ปี"}
                                                name={"start_date"}
                                                dateFormat="MMMM d, yyyy 00:00:00"
                                                withPortal
                                                onCalendarOpen={() => { Helper.renderDate() }}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => {
                                                    date = new Date(date);
                                                    let now = new Date();
                                                    // months_th
                                                    const years = [];
                                                    let i;
                                                    for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                        years.push(i)
                                                    }
                                                    return (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className={"btn btn-primary "}
                                                                type={"button"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                            >
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <select
                                                                className={"form-control"}
                                                                value={date.getFullYear()}
                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {years.map(option => (
                                                                    <option key={option} value={option} >
                                                                        {option + 543}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className={"form-control"}
                                                                value={window.months_th[date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(window.months_th.indexOf(value))
                                                                }
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {window.months_th.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                className={"btn btn-primary"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                type={"button"}
                                                            >
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                    )
                                                }}
                                                customInput={<CustomDateInput />}
                                            />
                                            <input type="hidden" id="start_date" name="start_date" value={this.state.start_date} onChange={() => { console.log('change') }} readOnly={true} />
                                        </div>
                                        <div style={{ marginRight: '0.5rem' }}>
                                            <DatePicker
                                                disabled={this.state.is_loading}
                                                onChange={date => {
                                                    let end = new Date(date);
                                                    end.setHours(23, 59, 0, 0);
                                                    let start_date = this.state.start_date
                                                    if (end.getTime() < start_date) {
                                                        start_date = end.getTime()
                                                    }
                                                    this.setState({
                                                        end_date: end.getTime(),
                                                        start_date: start_date,
                                                    }
                                                        // , () => {
                                                        //     this.$searchForm.find('button[type="submit"]').trigger('click');
                                                        // }
                                                    )
                                                }}
                                                selectsEnd
                                                startDate={this.state.start_date}
                                                endDate={this.state.end_date}
                                                locale="th-TH"
                                                selected={Helper.getDateToEdit(this.state.end_date)}
                                                placeholderText={"วัน / เดือน / ปี"}
                                                name={"end_date"}
                                                dateFormat="MMMM d, yyyy 23:59:00"
                                                minDate={this.state.start_date}
                                                withPortal
                                                onCalendarOpen={() => { Helper.renderDate() }}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => {
                                                    date = new Date(date);
                                                    let now = new Date();
                                                    // months_th
                                                    const years = [];
                                                    let i;
                                                    for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                        years.push(i)
                                                    }
                                                    return (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className={"btn btn-primary "}
                                                                type={"button"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                            >
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <select
                                                                className={"form-control"}
                                                                value={date.getFullYear()}
                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {years.map(option => (
                                                                    <option key={option} value={option} >
                                                                        {option + 543}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className={"form-control"}
                                                                value={window.months_th[date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(window.months_th.indexOf(value))
                                                                }
                                                                style={{ borderRadius: 0 }}
                                                            >
                                                                {window.months_th.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                className={"btn btn-primary"}
                                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                type={"button"}
                                                            >
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                    )
                                                }}
                                                customInput={<CustomDateInput />}
                                            />
                                            <input type="hidden" id="end_date" name="end_date" value={this.state.end_date} onChange={() => { console.log('change') }} readOnly={true} />
                                        </div>
                                        {

                                            (this.state.booking_type !== 'admin_created') ?
                                                <div style={{ marginRight: '0.5rem' }}>
                                                    <select
                                                        className='form-control'
                                                        name='status'
                                                        defaultValue={this.state.status}
                                                        onChange={(e) => {
                                                            if (e.target.value != "finish") {
                                                                this.selector.hide();
                                                            } else {
                                                                this.selector.show();
                                                            }
                                                        }}
                                                    >
                                                        <option value="all">แสดงทั้งหมด</option>
                                                        {/* <option value="wait">รอ</option> */}
                                                        <option value="accept">ยอมรับ</option>
                                                        <option value="going">กำลังเดินทาง</option>
                                                        <option value="working">กำลังทำงาน</option>
                                                        <option value="arrive">ถึงแล้ว</option>
                                                        <option value="done">เสร็จสิ้น</option>
                                                        <option value="finish">สำเร็จ</option>
                                                        <option value="refund">คืนเงิน</option>
                                                        <option value="cancel">ยกเลิก</option>
                                                    </select>
                                                </div>
                                                : null
                                        }

                                        <div
                                            ref={(ref) => {
                                                this.selector = window.$(ref);
                                            }}
                                            style={{ marginRight: '0.5rem', display: this.state.status == 'finish' ? "block" : "none" }}>
                                            <select
                                                className='form-control'
                                                name='finishedFilter'
                                                defaultValue={this.state.finishedFilter || false}
                                            >
                                                <option value="all">ทั้งหมด</option>
                                                <option value="halfday">หลังเที่ยง - ก่อนเที่ยง</option>
                                            </select>
                                        </div>

                                        <div style={{ marginRight: '0.5rem' }}>
                                            <select className='form-control' name='sortField' defaultValue={this.state.sortField || false}>
                                                <option value="createdAt">วันจอง</option>
                                                <option value="bookedAt">วันให้บริการ</option>
                                            </select>
                                        </div>
                                        {/* <div>
                                            <select className='form-control' name='booking_type'>
                                                <option value="all">แสดงทั้งหมด</option>
                                                <option value="app_created">สร้างโดยแอป</option>
                                                <option value="admin_created">สร้างโดยระบบหลังบ้าน</option>
                                            </select>
                                        </div> */}
                                        <div className="input-group-append" style={{ marginLeft: 5 }}>
                                            <input type="hidden" name="booking_type" value={this.state.booking_type} />
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i> ค้นหา
                                            </button>
                                        </div>
                                    </form>
                                    <p style={{ marginLeft: 10, marginBottom: 0, display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>อ้างอิงการดึงข้อมูลจาก{this.state.sortField == defaultOrder ? 'วันจอง' : 'วันให้บริการ'}</p>
                                    {
                                        this.state.type === 'user' ?
                                            <div style={{ marginLeft: 'auto' }}>
                                                <Link
                                                    title="New user report"
                                                    className="btn btn-info"
                                                    style={{ marginRight: 5 }}
                                                    to={'/booking-report/user'}
                                                >
                                                    New Report
                                                </Link>
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className='flex row mb-2' style={{ alignItems: 'center' }}>
                                    {
                                        this.props.is_super_admin ?
                                            <div className={"form-group"}>
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox"
                                                        className="custom-control-input"
                                                        id="isClearDevAccount"
                                                        name={"isClearDevAccount"}
                                                        defaultValue={"on"}
                                                        onChange={(e) => {
                                                            let data = Helper.getFormInputObject(this.$searchForm);
                                                            if (window.$(e.target).prop("checked")) {
                                                                data.isClearDevAccount = `true`
                                                            } else {
                                                                data.isClearDevAccount = `false`
                                                            }
                                                            let url = Helper.getQueryStringLinkFromObject({
                                                                ...data
                                                            })
                                                            navigate(url)
                                                        }}
                                                        defaultChecked={this.state.isClearDevAccount}
                                                    />
                                                    <label className="custom-control-label" htmlFor="isClearDevAccount">ปิดแสดงผล dev account</label>
                                                </div>
                                            </div>
                                            : null
                                    }


                                    {
                                        !this.state.is_loading
                                            && this.state.data ?
                                            <div style={{ marginLeft: 'auto' }}>
                                                <button
                                                    title="Export Excel"
                                                    className="btn btn-default"
                                                    onClick={async () => {
                                                        this._export.save()

                                                        let logs = {};
                                                        logs.download = {
                                                            dataCount: this._export.props.data.length,
                                                            filename: this._export.props.fileName,
                                                        }
                                                        await axios.put(`${window.api_host}/report-logs`, { id: this.state.logId, data: logs });
                                                    }}
                                                >
                                                    โหลดรายงาน
                                                </button>
                                            </div>

                                            : null
                                    }
                                </div>

                                {
                                    this.state.is_loading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                {
                                    !this.state.is_loading ?
                                        <>
                                            <ExcelExport
                                                fileName={fileName}
                                                data={this.state.data}
                                                ref={exporter => this._export = exporter}
                                            >
                                                <Grid data={this.state.data} style={{ height: '650px' }}>
                                                    {/* <GridColumn field="dateFinished" title="เวลาส่งงาน" width="150" /> */}
                                                    <GridColumn field="actionDate" title="วันที่ทำรายการ" width="150" />
                                                    <GridColumn field="bookActionType" title="ประเภท" width="150" />
                                                    <GridColumn field="bookOn" title="เลข Booking" width="150" />
                                                    <GridColumn
                                                        field="user_data.displayName"
                                                        title="สมาชิก"
                                                        width="200"
                                                    />
                                                    <GridColumn
                                                        field="user_data.phoneNumber"
                                                        title="เบอร์โทรศัพท์"
                                                        width="150"
                                                    />
                                                    <GridColumn field="bookTime" title="เวลาที่ให้บริการ" width="120" />
                                                    <GridColumn
                                                        field="providerUser.displayName"
                                                        title="แม่บ้าน"
                                                        width="200"
                                                    />
                                                    <GridColumn
                                                        field="providerUser.phoneNumber"
                                                        title="เบอร์โทรศัพท์"
                                                        width="150"
                                                    />
                                                    {/* <GridColumn field="bookedAtDateText" title="วันที่ให้บริการ" width="230" /> */}
                                                    <GridColumn field="bookedAtDateText" title="วันที่ให้บริการ" width="230" />
                                                    <GridColumn field="bookedAtDateTime" title="เวลาให้บริการ" width="230" />
                                                    <GridColumn
                                                        field="address.name"
                                                        title="สถานที่บริการ"
                                                        width="250"
                                                    />

                                                    <GridColumn
                                                        field="address.address"
                                                        title="รายละเอียด"
                                                        width="150"
                                                    />
                                                    <GridColumn
                                                        field="amphoe"
                                                        title="เขต/อำเภอ"
                                                        width="150"
                                                    />

                                                    <GridColumn
                                                        field="province"
                                                        title="จังหวัด"
                                                        width="150"
                                                    />
                                                    <GridColumn
                                                        field="address.placeType.name_th"
                                                        title="ประเภทสถานที่"
                                                        width="150"
                                                    />
                                                    <GridColumn
                                                        field="address.placeType.place_sub_type.description_th"
                                                        title="พืนที่"
                                                        width="150"
                                                    />
                                                    <GridColumn
                                                        field="bookTime"
                                                        title="กี่ชั่วโมง"
                                                        width="60"
                                                    />
                                                    <GridColumn field="payment.statusText" title="ชำระเงิน" width="100" />
                                                    <GridColumn field="payment.paymentBy" title="ช่องทาง" width="100" />
                                                    <GridColumn field="payment.card.brand" title="ประเภทบัตร" width="120" />
                                                    <GridColumn field="payment.card.last_digits" title="ลงท้าย" width="100" />
                                                    <GridColumn field="price" title="ค่าบริการเรียกเก็บจาก สมาชิก" width="200" />
                                                    <GridColumn field="promotion.voucherCode" title="รหัสส่วนลด" width="150" />
                                                    <GridColumn field="priceDiscount" title="ส่วนลดราคา" width="100" />
                                                    <GridColumn field="priceUserAfterDiscount" title="ค่าบริการ" width="200" />
                                                    <GridColumn field="priceUserPlus" title="อื่นๆ" width="100" />
                                                    <GridColumn field="chargePrice" title="ภาษี 7%" width="100" />
                                                    <GridColumn field="totalUserPrice" title="รวม" width="100" />
                                                    <GridColumn
                                                        field="providerUser.displayName"
                                                        title="แม่บ้าน"
                                                        width="200"
                                                    />
                                                    <GridColumn field="starFromProvider" title="ความประทับใจต่อลูกค้า" width="200" />
                                                    <GridColumn field="user_report.report" title="รายงานจากลูกค้า" width="200" />
                                                    <GridColumn field="starFromUser" title="ความประทับใจต่อแม่บ้าน" width="200" />
                                                    <GridColumn field="provider_report.report" title="รายงานจากแม่บ้าน" width="200" />
                                                    <GridColumn field="bookStatusth"
                                                        title="สถานะ"
                                                        width="100"

                                                    />
                                                    <GridColumn field="firstBook"
                                                        title="หมายเหตุ"
                                                        width="100"

                                                    />
                                                </Grid>
                                            </ExcelExport>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
    GetProvince(code) {
        return this.state.all_provinces.filter((e) => e.code == code)[0]?.province || '';
    }
    GetAmphoe(code) {
        return this.state.all_amphoes.filter((e) => e.code == code)[0]?.amphoe || '';
    }

}
