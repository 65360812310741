import React from 'react';
import { Link, navigate } from '@reach/router';
import { db } from './../firebase';
import { Helper } from './../Helper';
import GoogleMap from './../Components/GoogleMap';
import thailand from "./../thailand.json";
const axios = require('axios').default;

export default class EditUserLocation extends React.Component {
    constructor(props) {
        super(props);
        let provinces = [];
        for (const [key, value] of Object.entries(thailand)) {
            let amphoes = [];

            for (const [_key, _value] of Object.entries(value.amphoes)) {
                amphoes.push({ code: _key, amphoe: _value.name.th });
                // console.log(`${_key}: ${_value.name.th}`);
            }
            provinces.push({ code: key, province: value.name.th, amphoes: amphoes })
            // console.log(`${key}: ${value.name.th}`);
        }
        this.state = {
            mode: props.mode ? props.mode : false,
            location: false,
            place_type: [],
            place_type_selected: false,
            place_sub_type_selected: 0,
            provinces: provinces,
            places: [],
            selected_amphoe: false,
            booking_time: [],
            time: [],
            place_selected: props.placeUid || false,
            // selected_booking_time: props.data.bookingDuration || false,
            // selected_booking_date: props.data.bookingDate || false,
            // selected_time: props.data.bookingTime || false,
            service_area: [],
            is_loading_service_area: false,
            is_loading: true,
        }
    }

    componentDidMount() {
        this.fetchServiceArea();
        this.fetch();
    }

    async fetch() {
        let res = await db.collection('place_type').get();
        let data = [];
        let place_type_selected = false;
        if (!res.empty) {
            for (const doc of res.docs) {
                let _data = { ...doc.data() };
                _data.uid = doc.id;
                data.push(_data);
            }
        }
        if (data.length > 0) {
            place_type_selected = data[0]
        }
        let places = [];
        let place_selected = false;
        if (this.props.userUid) {
            let placesRef = await db.collection('users').doc(this.props.userUid).collection('places').doc(this.props.placeUid).get();
            if (placesRef.exists) {
                place_selected = placesRef.data();
                place_selected.uid = placesRef.id
            }
        }
        if (!place_selected) {
            let bookUid = Helper.getParameterByName(`bookUid`) || false;
            if (bookUid) {
                let bookRef = await db.collection('books').doc(bookUid).get();
                if (bookRef.exists) {
                    if (bookRef.data().address) {
                        place_selected = bookRef.data().address;
                        let placeTypeRef = await db.collection('place_type').doc(bookRef.data().address.placeTypeUid).get();
                        if (placeTypeRef.exists) {
                            place_selected.placeType = {
                                ...placeTypeRef.data()
                            }
                        }
                    }
                }
            }
        }
        let bookTimeRef = await db.collection('book_time').get();
        let book_time = [];
        if (!bookTimeRef.empty) {
            for (const doc of bookTimeRef.docs) {
                book_time.push({ ...doc.data(), uid: doc.id });
            }
        }
        book_time.sort(function (a, b) { return a.sequence - b.sequence });
        let timeRef = await db.collection('constants').doc('bookSelectHalfTime').get();
        let time = [];
        if (timeRef.exists) {
            time = [...timeRef.data().value];
        }
        let place_sub_type_selected = 0;
        if (place_selected.placeSubTypeUid) {
            place_sub_type_selected = place_selected.placeSubTypeUid;
        }
        console.log(`place_sub_type_selected`, place_sub_type_selected)
        if (place_selected.placeType) {
            place_type_selected = place_selected.placeType;
            place_type_selected.uid = place_selected.placeTypeUid;
        }
        this.setState({
            ...place_selected,
            places: places,
            place_type: data,
            place_type_selected: place_type_selected,
            place_sub_type_selected: place_sub_type_selected,
            selected_province: false,
            selected_amphoe: false,
            place_selected: place_selected,
            location: {
                lat: place_selected?.coordinate?.latitude || 13.631787064094638,
                lng: place_selected?.coordinate?.longitude || 100.54314216378978,
                address: place_selected.address,
            },
            // place_type_selected: place_selected,
            selected_province: place_selected.province,
            selected_amphoe: place_selected.amphoe,
            booking_time: book_time,
            time: time,
            is_loading: false,
        })
    }

    async fetchServiceArea() {
        let service_area = [...this.state.service_area];
        if (service_area.length === 0) {
            let res = await db.collection('service_area').get();
            if (!res.empty) {
                for (const doc of res.docs) {
                    let _service_area_data = { ...doc.data() }
                    _service_area_data.id = doc.id;
                    service_area.push(_service_area_data);
                }
            }
        }
        this.setState({
            service_area: service_area,
            is_loading_service_area: false,
        })
    }

    getAmphoe() {
        let apm = [];
        this.state.provinces.forEach((db) => {
            if (db.code === this.state.selected_province) {
                apm = db.amphoes;
            }
        });
        apm.sort()
        return apm;
    }

    async editPlace(data) {
        let _data = { ...data };
        _data.placeType = { ...this.state.place_type_selected };
        _data.placeSubTypeUid = parseFloat(this.state.place_sub_type_selected);
        // _data.placeSubTypeUid = parseFloat(data.placeSubTypeUid);
        // _data.coordinate = await firestore(parseFloat(data.lat), parseFloat(data.lng));

        let bookUid = Helper.getParameterByName(`bookUid`) || false;
        if (bookUid) _data.bookUid = bookUid;

        let phone = data.phoneNumber.replaceAll('-', '');
        phone = phone.replaceAll(' ', '');
        if (phone.length == 10) {
            phone = '+66' + phone.substr(1, 9);
        }
        _data.phoneNumber = phone;
        // console.log(_data);
        // return;
        let res = await axios.put(`${window.api_host}/user-places`, { ..._data });
        if (res.data.success) {
            this.$submit.removeClass('loading');
            this.setState({
                mode: false,
                place_type_selected: false,
                place_sub_type_selected: false,
                location: false,
                name: false,
                addressDetail: false,
                selected_province: false,
                selected_amphoe: false,
                note: false,
            }, async () => {

                Helper.messageTop({ message: "อัพเดทข้อมูลสถานที่สำเร็จ", type_class: 'alert-success success' });
                // this.fetch();
                if (Helper.getParameterByName('bookUid')) {
                    navigate('/booking/' + Helper.getParameterByName('bookUid'))
                } else {
                    navigate('/user/' + this.props.userUid)
                }
            })
        }
        if (res.data.error) {
            Helper.messageTop({ message: res.data.message });
            this.$submit.removeClass('loading');
            return;
        }
    }



    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">แก้ไขข้อมูลพื้นที่</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/user/" + this.props.userUid}>ลูกค้า</Link></li>
                                    <li className="breadcrumb-item active">แก้ไข</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"content"}>
                    <div className="container-fluid" >
                        <div className={"row"}>
                            <div className="col-12 col-md-2 offset-md-1">
                                {
                                    this.state.is_loading_service_area ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className="ui active inline loader small" ></div>
                                        </div>
                                        : null
                                }
                                {
                                    !this.state.is_loading_service_area && this.state.service_area.length === 0 ?
                                        <>ไม่มีข้อมูล</>
                                        : null
                                }
                                {
                                    !this.state.is_loading_service_area && this.state.service_area.length > 0 ?
                                        <>
                                            <h5>พื้นที่ให้บริการ</h5>
                                            <ul className="list-group">
                                                {
                                                    this.state.service_area.map((service_area, i) => {
                                                        return (
                                                            <li className="list-group-item" key={i}>
                                                                {service_area.name.th}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                        : null
                                }
                            </div>
                            <div className="col-12 col-md-4 offset-md-1">

                                {
                                    this.state.is_loading ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className="ui active inline loader small" ></div>
                                        </div>
                                        : null
                                }
                                {
                                    !this.state.is_loading ?
                                        <form
                                            ref={(ref) => {
                                                this.$form = window.$(ref)
                                            }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                if (this.$submit.hasClass('loading')) { return false; }
                                                this.$submit.addClass('loading');
                                                let data = Helper.getFormInputObject(this.$form);
                                                if (!JSON.parse(data.lat) || !JSON.parse(data.lng) || !data.address) {
                                                    Helper.messageTop({ message: 'เลือกแผนที่' });
                                                    this.$submit.removeClass('loading');
                                                    return;
                                                }
                                                if (!data.placeTypeUid
                                                    || !data.placeSubTypeUid
                                                    || !data.amphoe
                                                    || !data.province
                                                    || !data.name
                                                    || !data.addressDetail
                                                ) {
                                                    Helper.messageTop({ message: 'กรอกข้อมูลให้ครบถ้วน' });
                                                    this.$submit.removeClass('loading');
                                                    return;
                                                }
                                                if (data.phoneNumber && data.phoneNumber.indexOf('+66') == -1) {
                                                    data.phoneNumber = '+66' + data.phoneNumber.substr(1, 9);
                                                }
                                                if (this.state.mode === 'edit') {
                                                    this.editPlace(data);
                                                }
                                            }}
                                            className='pb-4'
                                        >
                                            {
                                                this.state.mode === 'edit' ?
                                                    <>
                                                        <input type="hidden" name="placeUid" value={this.props.placeUid} />
                                                    </>
                                                    : null
                                            }
                                            <input type="hidden" name="userUid" value={this.props.userUid} />
                                            {
                                                this.state.place_type && this.state.place_type.length ?
                                                    <>
                                                        <div className="row">
                                                            {
                                                                this.state.place_type.map((place, place_i) => {
                                                                    return (
                                                                        <div key={place_i}
                                                                            className="col-12 col-md-6"
                                                                        >
                                                                            <div
                                                                                className={"card"}
                                                                                style={this.state.place_type_selected
                                                                                    && this.state.place_type_selected.uid == place.uid ? { border: '2px solid green' } : null}
                                                                                onClick={() => {
                                                                                    if (this.state.place_type_selected
                                                                                        && this.state.place_type_selected.uid == place.uid) { return; }
                                                                                    this.setState({
                                                                                        place_type_selected: place,
                                                                                        place_sub_type_selected: 0,
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <div className="card-body" style={{ textAlign: 'center' }}>
                                                                                    {place.name_th}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                            this.state.place_type_selected ?
                                                                <div className={"form-group"}>
                                                                    <select className="form-control"
                                                                        value={this.state.place_sub_type_selected || 0}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                place_sub_type_selected: e.target.value
                                                                            })
                                                                        }}
                                                                    >
                                                                        {
                                                                            this.state.place_type_selected.place_sub_type.map((place_sub_type, place_sub_type_i) => {
                                                                                return (
                                                                                    <option key={place_sub_type_i} value={place_sub_type_i}>
                                                                                        {place_sub_type.name_th} ไม่เกิน {place_sub_type.description_th}
                                                                                    </option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                : null
                                                        }
                                                        <input type="hidden" name="placeTypeUid" value={this.state.place_type_selected.uid} />
                                                        <input type="hidden" name="placeSubTypeUid" value={this.state.place_sub_type_selected} />
                                                    </>
                                                    : null
                                            }
                                            {
                                                this.state.location && !this.state.map_loading ?
                                                    <div className="form-group">
                                                        <GoogleMap
                                                            mode="view"
                                                            height={300}
                                                            lat={this.state.location && this.state.location.lat ? parseFloat(this.state.location.lat) : false}
                                                            lng={this.state.location && this.state.location.lng ? parseFloat(this.state.location.lng) : false}
                                                        />
                                                    </div>
                                                    : null
                                            }
                                            <div className={"form-group"}>
                                                <button
                                                    onClick={() => {
                                                        let geo = { lat: false, lng: false };
                                                        if (this.lat.val()) {
                                                            geo.lat = this.lat.val()
                                                        }
                                                        if (this.lat.val()) {
                                                            geo.lng = this.lng.val()
                                                        }
                                                        Helper.reactToDom(window.$('body'),
                                                            <GoogleMap
                                                                modalTitle={"ปักหมุดสถานที่"}
                                                                mode="modal"
                                                                height={300}
                                                                onSubmit={(location) => {
                                                                    this.setState({
                                                                        map_loading: true,
                                                                    }, () => {
                                                                        this.setState({
                                                                            location: location,
                                                                            map_loading: false,
                                                                        })
                                                                    })

                                                                }}
                                                                lat={this.state.location && this.state.location.lat ? parseFloat(this.state.location.lat) : false}
                                                                lng={this.state.location && this.state.location.lng ? parseFloat(this.state.location.lng) : false}
                                                            />)
                                                    }}
                                                    type={"button"}
                                                    className="btn btn-default"
                                                    style={{ width: '100%' }}
                                                >{this.state.location ? "เปลี่ยนตำแหน่ง" : "ระบุตำแหน่ง"}</button>
                                            </div>
                                            <input type="hidden" ref={(ref) => { this.lat = window.$(ref) }} name="lat" value={this.state.location && this.state.location.lat ? parseFloat(this.state.location.lat) : false} />
                                            <input type="hidden" ref={(ref) => { this.lng = window.$(ref) }} name="lng" value={this.state.location && this.state.location.lng ? parseFloat(this.state.location.lng) : false} />
                                            <input type="hidden" ref={(ref) => { this.lng = window.$(ref) }} name="address" value={this.state.location && this.state.location.address ? this.state.location.address : false} />
                                            <div className="form-group">
                                                <input className="form-control" name="name" placeholder="ชื่อสถานที่" defaultValue={this.state.name || ''} />
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" name="addressDetail" placeholder="รายบละเอียดสถานที่" defaultValue={this.state.addressDetail || ''} />
                                            </div>
                                            <div className="form-group">
                                                <select className="form-control"
                                                    name={"province"}
                                                    defaultValue={this.state.selected_province}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            selected_province: e.target.value,
                                                            selected_amphoe: false,
                                                        })
                                                    }}
                                                >
                                                    <option value="">เลือกจังหวัด</option>
                                                    {
                                                        this.state.provinces.map((item, item_i) => (
                                                            <option value={item.code} key={item_i}>{item.province}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                {
                                                    !this.state.selected_province ?
                                                        <input type={"test"} defaultValue={"เลือกจังหวัด"} disabled={true} className={"form-control"} />
                                                        : null
                                                }
                                                {
                                                    this.state.selected_province ?
                                                        <select className="form-control"
                                                            name={"amphoe"}
                                                            onChange={(e) => {
                                                                if (e.target.value === "" || !e.target.value) { return; }
                                                                this.setState({
                                                                    selected_amphoe: e.target.value,
                                                                })
                                                            }}
                                                            defaultValue={this.state.selected_amphoe}
                                                        >
                                                            <option value="">เลือกเขต/อำเภอ</option>
                                                            {
                                                                this.getAmphoe().map((amp, amp_i) => (
                                                                    <option value={amp.code} key={amp_i}
                                                                    >{amp.amphoe}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        : null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" name="phoneNumber" placeholder="เบอร์โทรศัพท์" defaultValue={this.state.phoneNumber ? this.state.phoneNumber.replace('+66', '0') : ''} />
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" name="note" placeholder="หมายเหตุ" defaultValue={this.state.note || ''} />
                                            </div>
                                            {
                                                Helper.getParameterByName(`bookUid`) ?
                                                    <div className="form-group">
                                                        <select className="form-control"
                                                            name={"editType"}
                                                            required={true}
                                                            defaultValue={''}
                                                        >
                                                            <option value="" disabled>เลือกการแก้ไข</option>
                                                            <option value="1">แก้ไขเฉพาะ Book นี้</option>
                                                            <option value="2">แก้ไขข้อมูลสถานที่ของ user และทุก Book ที่มีการใช้สถานที่นี้ (เฉพาะสถานะ รอ และ ยอมรับ)</option>
                                                            <option value="3">แก้ไขข้อมูลสถานที่เฉพาะของ user</option>
                                                        </select>
                                                    </div>
                                                    : <input type='hidden' value="3" name="editType" />
                                            }
                                            <button
                                                ref={(ref) => { this.$submit = window.$(ref) }}
                                                className="btn btn-success btn-lg inverted"
                                                style={{ width: '100%' }}
                                                type="submit"
                                            >{this.state.mode === 'add' ? "เพิ่มสถานที่" : "บันทึก"}</button>
                                        </form>
                                        : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
