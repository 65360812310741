import React from 'react';
import { Link } from '@reach/router';
import { Helper } from '../Helper';
import { db } from '../firebase';
const axios = require('axios').default;
// import Pagination from '../Components/Pagination';

export default class ViewUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            user: {},
            books: [],
            user_id: props.user_id,
        }
    }

    componentDidMount() {
        this.fetchUser();
    }
    async fetchUser() {
        let user = {};
        await db.doc("users/" + this.state.user_id).get().then((doc) => {
            user = { ...doc.data(), id: doc.id };
        })
        let _user = { ...user };
        let places = await (await db.collection("users/" + this.state.user_id + '/places').get());
        if (places.docs.length > 0) {
            _user.address = [];
            places.docs.map((place) => {
                _user.address.push({ ...place.data(), id: place.id });
                return true;
            })

        }
        let booking = await db.collection('books').where('userUid', '==', this.state.user_id).get();
        let _booking = await Promise.all(
            booking.docs.map(async (element) => {
                return {
                    ...element.data(),
                    id: element.id,
                };
            })
        );
        // console.log(_user);
        this.setState({
            user: _user,
            books: _booking,
            is_loading: false,
        })
    }



    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">สมาชิก</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/users">สมาชิก</Link></li>
                                    <li className="breadcrumb-item active">ข้อมูลสมาชิก</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.user ?
                                <>
                                    <div className={"row"}>
                                        <div className="col-lg-4">
                                            <div className="card">
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h3 className="card-title">
                                                        {this.state.user.displayName}
                                                        {
                                                            this.state.user.guestPhoneNumber ?
                                                                <>{' '}<span className={"badge badge-info"}>นอกระบบ</span></>
                                                                : null
                                                        }
                                                    </h3>
                                                    <div className='ml-auto'>
                                                        <strong>ID : </strong>
                                                        <span className='btn btn-default'
                                                            onClick={() => {
                                                                // Copy the text inside the text field
                                                                navigator.clipboard.writeText(this.state.user.id);

                                                                // Alert the copied text
                                                                window.$(document).Toasts('create', {
                                                                    title: 'Copied',
                                                                    body: "ID: " + this.state.user.id,
                                                                    icon: 'fas fa-check',
                                                                    autohide: true,
                                                                    delay: 3000,
                                                                    class: "bg-success",
                                                                })
                                                            }}
                                                        >
                                                            {this.state.user.id}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="card-body" >
                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-6">
                                                            <img src={this.state.user.photoURL ? this.state.user.photoURL : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                        </div>
                                                        <div className="col-sm-12 col-lg-6">
                                                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <Link className="row"
                                                                        to={"/rating-user/" + this.state.user.id}
                                                                    >
                                                                        <label>คะแนนรีวิว</label>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            <div className={"beeclean-block"}>
                                                                                {
                                                                                    this.state.user
                                                                                        && this.state.user.starFloat !== undefined ?
                                                                                        this.state.user.starFloat.toFixed(1)
                                                                                        : '5.0'
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                    <Link to={"/booking?userUID=" + this.state.user_id} className="row">
                                                                        <label>จำนวน Booking</label>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            <div className={"beeclean-block"}>
                                                                                {Helper.numberFormat(this.state.books.length)}
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                                <div>
                                                                    <div className='row'>
                                                                        <label>เบอร์โทรศัพท์ที่ใช้สมัคร</label>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            {this.state.user.firstTimePhoneNumber ? this.state.user.firstTimePhoneNumber : '-'}
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <label>วันที่สมัคร</label>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            {this.state.user.createdAt ? Helper.getDateThai(this.state.user.createdAt, { is_show_time: true, plus_hours: 7 }) : '-'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">ข้อมูลสมาชิก</h3>
                                                </div>
                                                <div className="card-body" >
                                                    <div>
                                                        <label>ชื่อ</label> : {this.state.user.displayName}
                                                    </div>
                                                    <div>
                                                        <label>อีเมล</label> :  {this.state.user.email} {this.state.user.guestEmail}
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div>
                                                            <label>เบอร์โทรศัพท์</label> :  {this.state.user.phoneNumber} {this.state.user.guestPhoneNumber}
                                                        </div>
                                                        {
                                                            this.state.user.guestPhoneNumber
                                                                ? null :
                                                                this.props.is_super_admin ?
                                                                    <div className='link' style={{ marginLeft: 'auto' }}
                                                                        onClick={() => {
                                                                            Helper.reactToDom(window.$('body'),
                                                                                <ChangePhoneNumberForm
                                                                                    onSuccess={(newPhoneNumber) => {
                                                                                        let user = this.state.user;
                                                                                        user.phoneNumber = newPhoneNumber;
                                                                                        this.setState({
                                                                                            user: user
                                                                                        })
                                                                                    }}
                                                                                    currentPhoneNumber={this.state.user.phoneNumber}
                                                                                />
                                                                            )
                                                                        }}
                                                                    >เปลี่ยนเบอร์โทรศัพท์</div>
                                                                    : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <div className={"col-lg-4"}>
                                            {
                                                this.state.user.address
                                                    && this.state.user.address.length > 0 ?
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h3 className="card-title">ตำแหน่งปักหมุด</h3>
                                                        </div>
                                                        <div className="card-body" >
                                                            {
                                                                this.state.user.address.map((address, address_i) => {
                                                                    return (
                                                                        <React.Fragment key={address_i}>
                                                                            {
                                                                                address_i > 0 ?
                                                                                    <div className={"divider"} style={{ marginTop: 15 }} />
                                                                                    : null
                                                                            }
                                                                            <div>
                                                                                {address
                                                                                    && address.name ?
                                                                                    <div><label>ชื่อสถานที่ : </label> {address.name}</div>
                                                                                    : null
                                                                                }
                                                                                {address
                                                                                    && address.placeType
                                                                                    && address.placeType.name_th ?
                                                                                    <div><label>ประเภท : </label> {address.placeType.name_th}</div>
                                                                                    : null
                                                                                }
                                                                                {address
                                                                                    && address.placeType
                                                                                    && address.placeType.place_sub_type
                                                                                    && address.placeType.place_sub_type.description_th ?
                                                                                    <div><label>พืนที่ : </label> {address.placeType.place_sub_type.description_th}</div>
                                                                                    : null
                                                                                }

                                                                                {address
                                                                                    && address.address ?
                                                                                    <div style={{ marginBottom: '0.5rem' }}>
                                                                                        <label style={{ marginBottom: 0 }}>ที่อยู่ : </label> {address.address}
                                                                                    </div>
                                                                                    : null
                                                                                }
                                                                                {address
                                                                                    && address.amphoe ?
                                                                                    <div style={{ marginBottom: '0.5rem' }}>
                                                                                        <label style={{ marginBottom: 0 }}>อำเภอ : </label> {Helper.callAmphoe(address.amphoe)}</div>
                                                                                    : null
                                                                                }
                                                                                {address
                                                                                    && address.province ?
                                                                                    <div style={{ marginBottom: '0.5rem' }}>
                                                                                        <label style={{ marginBottom: 0 }}>จังหวัด : </label> {Helper.callProvince(address.province)}</div>
                                                                                    : null
                                                                                }
                                                                                {address
                                                                                    && address.addressDetail ?
                                                                                    <div><label style={{ marginBottom: 0 }}>รายละเอียดที่อยู่ : </label><br /> <div style={{ marginBottom: '0.5rem' }}>{address.addressDetail}</div></div>
                                                                                    : null
                                                                                }
                                                                                <div><label>โทรศัพท์ : </label> {address
                                                                                    && address.phoneNumber ? address.phoneNumber : '-'
                                                                                }
                                                                                </div>
                                                                                <div><label>หมายเหตุ : </label> {address
                                                                                    && address.note ? address.note : '-'
                                                                                }
                                                                                </div>

                                                                                {
                                                                                    address
                                                                                        && address.coordinate
                                                                                        && address.coordinate.latitude
                                                                                        && address.coordinate.longitude ?
                                                                                        <div className={"flex row"}>
                                                                                            <a href={"https://maps.google.com?q=" + address.coordinate.latitude + ',' + address.coordinate.longitude}
                                                                                                className={"btn btn-default"}
                                                                                                target="_blank"
                                                                                                rel='noopener noreferrer'
                                                                                            >
                                                                                                ดูสถานะที่
                                                                                            </a>
                                                                                            {
                                                                                                this.props.is_can_edit_map ?
                                                                                                    <Link to={'/edit-location/' + this.state.user.id + '/place/' + address.id}
                                                                                                        className={"btn btn-default"}
                                                                                                        style={{ marginLeft: 'auto' }}
                                                                                                    >
                                                                                                        แก้ไข
                                                                                                    </Link>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                        </React.Fragment>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                </section>
            </>
        );
    }
}

class ChangePhoneNumberForm extends React.Component {
    async changePhoneNumber(data) {
        let update = {
            ...data,
            changeForWhat: "ineedtochange"
        }
        let phone = data.toNewPhoneNumber.replace('-', '');
        phone = phone.replace(' ', '');
        phone = '+66' + phone.substr(1, 9);

        update.toNewPhoneNumber = phone;
        try {
            let res = await axios.post(`${window.api_host}/changePhoneNumberUser`, update);
            console.log(res)
            this.props.onSuccess(update.toNewPhoneNumber);
            window.$(document).Toasts('create', {
                title: 'เปลี่ยนเบอร์โทรศัพท์สำเร็จ',
                body: 'ทำการเปลี่ยนเบอร์โทรศัพท์เรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })

            this.$modal.modal('hide');
        } catch (error) {
            console.log(error)
            Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' })
            this.$submit.removeClass('loading');
        }
    }
    renderPhoneNumber(number) {
        let phoneNumber = number.replace('+66', '0');
        return phoneNumber;
    }

    render() {
        let rendercurrentPhone = '';
        if (this.props.currentPhoneNumber) {
            rendercurrentPhone = this.renderPhoneNumber(this.props.currentPhoneNumber)
        }
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) { return false; }
                        this.$submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        this.changePhoneNumber(data)
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                เปลี่ยนเบอร์โทรศัพท์
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className='form-group'>
                                <label>เบอร์โทรศัพท์เดิม</label>
                                <input readOnly={true} className="form-control" value={rendercurrentPhone} />
                                <input type="hidden" name="phoneNumber" value={this.props.currentPhoneNumber} />
                            </div>
                            <div className='form-group'>
                                <label>เบอร์โทรศัพท์ใหม่</label>
                                <input className="form-control" name={"toNewPhoneNumber"} required />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >
                                ยกเลิก
                            </button>
                            <button className={"btn btn-primary"} type={"submit"}>เปลี่ยนเบอร์โทรศัพท์</button>
                        </div>
                    </div>
                </form>
            </div>
        );

    }
}