import httpClient, { serverUrl } from './HttpClient';
const _serverUrl = serverUrl;

const baseUrl = `${_serverUrl}`;

export const agentService = {
    get: async (agentUid, timeRange, month, year) => {
        const url = `${baseUrl}/salary?agentUid=${agentUid}&timeRange=${timeRange}&month=${month}&year=${year}`;
        try {
            const res = await httpClient.get(url)
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    resetPasswordByAdmin: async (agentUid) => {
        const url = `${baseUrl}/agent-admin-reset-password`;
        try {
            const res = await httpClient.post(url, { agentUid })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    //create and update use same api
    // update: async ({ uid, bank, bankBranch, bankAccountName, bankAccountNo }) => {
    //     const url = `${baseUrl}`;
    //     try {
    //         const res = await httpClient.post(url, { agentUid: uid, bank, bankBranch, bankAccountName, bankAccountNo })
    //         return res.data;
    //     } catch (error) {
    //         return { error: true, ...error };
    //     }
    // },
}