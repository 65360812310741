import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import thailand from "./../thailand.json";

export default class ReportList extends React.Component {
    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายงาน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">รายงาน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className='row'>
                            <div className="col-md-2">
                                <div className="card card-widget widget-user-2">
                                    <div className="card-footer p-0">
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                                <div
                                                    onClick={(e) => {
                                                        Helper.reactToDom(window.$('body'),
                                                            <ProvinceSelectorModal />
                                                        )
                                                    }}
                                                    className="nav-link"
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    รายงานแม่บ้านตามเขต/อำเภอ
                                                </div>
                                            </li>
                                            {/* <li className="nav-item">
                                                <a href="#" className="nav-link">
                                                    Followers <span className="float-right badge bg-danger">842</span>
                                                </a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


class ProvinceSelectorModal extends React.Component {
    constructor() {
        super();
        let provinces = [];
        for (const [key, value] of Object.entries(thailand)) {
            let amphoes = [];

            for (const [_key, _value] of Object.entries(value.amphoes)) {
                amphoes.push({ code: _key, amphoe: _value.name.th });
                // console.log(`${_key}: ${_value.name.th}`);
            }
            provinces.push({ code: key, province: value.name.th, amphoes: amphoes })
            // console.log(`${key}: ${value.name.th}`);
        }
        this.state = {
            provinces: provinces,
            provinceCode: false,
            amphoeCode: false,
            is_loading: true,
        }
    }

    componentDidMount() {
        this.setState({
            is_loading: false,
        })
    }

    getAmphoe() {
        let apm = [];
        this.state.provinces.forEach((db) => {
            if (db.code === this.state.provinceCode) {
                apm = db.amphoes;
            }
        });
        apm.sort()
        return apm;
    }

    render() {
        // console.log(this.state.history);
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        navigate('/report/provider?' + window.$.param(data))
                        this.$modal.modal('hide')
                        return;
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                เลือกจังหวัด
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            {
                                this.state.is_loading ?
                                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                                        <div className="ui active inline loader" ></div>
                                    </div>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className='form-group'>
                                            <label>จังหวัด</label>
                                            <select
                                                ref={(ref) => {
                                                    window.$(ref).select2();
                                                    window.$(ref).off().on('select2:select', (e) => {
                                                        let selected_uid = e.target.value;
                                                        if (!selected_uid) { return; }
                                                        this.setState({
                                                            provinceCode: selected_uid
                                                        })
                                                    });
                                                }}
                                                className='form-control select2'
                                                name="provinceCode"
                                                style={{ width: '100%' }}
                                            >
                                                <option value="">เลือกจังหวัด</option>
                                                {
                                                    this.state.provinces.map((province, province_i) => {
                                                        return (
                                                            <option key={province_i} value={province.code}>
                                                                {province.province}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>เขต/อำเภอ</label>
                                            {
                                                !this.state.provinceCode ?
                                                    <input type={"test"} defaultValue={"เลือกจังหวัด"} disabled={true} className={"form-control"} />
                                                    : null
                                            }
                                            {
                                                this.state.provinceCode ?
                                                    <select className="form-control"
                                                        name={"amphoeCode"}
                                                        ref={(ref) => {
                                                            window.$(ref).select2();
                                                            window.$(ref).off().on('select2:select', (e) => {
                                                                if (e.target.value === "" || !e.target.value) { return; }
                                                                this.setState({
                                                                    amphoeCode: e.target.value,
                                                                })
                                                            });
                                                        }}
                                                        defaultValue={this.state.amphoeCode}
                                                    >
                                                        <option value="">เลือกเขต/อำเภอ</option>
                                                        {
                                                            this.getAmphoe().map((amp, amp_i) => (
                                                                <option value={amp.code} key={amp_i}
                                                                >{amp.amphoe}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    : null
                                            }
                                        </div>
                                    </>
                                    : null
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ปิด</button>
                            <button ref={(ref) => { this.$submitBtn = window.$(ref) }} type="submit" className="btn btn-primary">ตกลง</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}