import React, { Component } from 'react'
import { db } from '../../firebase';
import { Helper } from '../../Helper';

export default class TaxPayerForm extends Component {
    constructor(props) {
        super(props);
        const taxPayer = this.props.taxPayer;
        this.state = {
            taxPayerNumber: taxPayer?.taxPayerNumber || '',
            taxPayerZipCode: taxPayer?.taxPayerZipCode || '',
        }
    }
    async update(data) {
        try {
            await db.collection('provider_users_data').doc(this.props.uid).update({
                taxPayer: { ...data }
            })
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            if (this.props.onUpdated) {
                this.props.onUpdated(data);
            }
            this.$modal.modal('hide');
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลไม่สำเร็จ',
                body: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submit.removeClass('loading');
        }
    }

    requireNumber(e) {
        let value = e.target.value;
        if (isNaN(value)) {
            e.target.value = this.state[e.target.name];
        } else {
            this.state[e.target.name] = e.target.value
        }
    }

    render() {
        let title = "";
        if (this.props.mode == 'edit') {
            title += `แก้ไข`
        }
        if (this.props.mode == 'add') {
            title += `เพิ่ม`
        }
        title += `ข้อมูลผู้เสียภาษี`;
        let taxPayer = this.props.taxPayer;
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        let submit_btn = this.$submit;
                        if (submit_btn.hasClass('loading')) { return false; }
                        submit_btn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">{title}</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>เลขผู้เสียภาษี</label>
                                <input
                                    className='form-control'
                                    name="taxPayerNumber"
                                    defaultValue={taxPayer?.taxPayerNumber || ''}
                                    onChange={(e) => {
                                        this.requireNumber(e)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label>ที่อยู่</label>
                                <textarea className='form-control' name="taxPayerAddress" defaultValue={taxPayer?.taxPayerAddress || ''} />
                            </div>
                            <div className="form-group">
                                <label>เลขไปรษณีย์</label>
                                <input
                                    className='form-control'
                                    name="taxPayerZipCode"
                                    defaultValue={taxPayer?.taxPayerZipCode || ''}
                                    onChange={(e) => {
                                        this.requireNumber(e)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >ยกเลิก</button>
                            <button type={"submit"} className="btn btn-primary">บันทึก</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
