import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../Helper';
import { db, storage } from '../firebase';
import Pagination from './../Components/Pagination';
import CustomDateInput from './../Components/CustomDateInput';
import DatePicker from "react-datepicker";
// import { AmphurList } from './ProviderUsers'
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
var moment = require('moment');
const axios = require('axios').default;
const CancelToken = axios.CancelToken;
let cancel;
let fetchcancel;
moment.locale('th');
const default_limit = 5;
let source = axios.CancelToken.source();
let statussource = axios.CancelToken.source();
const now_date = new Date();
now_date.setHours(0, 0, 0);
export default class Booking extends React.Component {
	constructor() {
		super();
		const years = [];
		let i;
		for (i = 1980; i <= new Date().getFullYear() + 1; i++) {
			years.push(i)
		}
		this.state = {
			is_loading: true,
			is_status_btn_loading: true,
			data: [],
			limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
			page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
			name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
			status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
			book_type: Helper.getParameterByName('book_type') ? Helper.getParameterByName('book_type') : '',
			date: Helper.getParameterByName('date') ? Helper.getParameterByName('date') : now_date.getTime(),
			is_show_next_button: false,
			timerage: Helper.getParameterByName('timerage') ? Helper.getParameterByName('timerage') : 'รายวัน',
			userUID: Helper.getParameterByName('userUID') ? Helper.getParameterByName('userUID') : '',
			providerUID: Helper.getParameterByName('providerUID') ? Helper.getParameterByName('providerUID') : '',
			provider_all: 0,
			now_count: 0,
			pre_count: 0,
			provideruserOnline_count: 0,
			order_day: 0,
			status_count: {
				accept: 0,
				arrive: 0,
				cancel: 0,
				done: 0,
				finish: 0,
				finish1: 0,
				finish2: 0,
				going: 0,
				wait: 0,
				working: 0,
				refund: 0,
				reverse: 0,
			},
			all_orders: 0,
			order_today: 0,
			all_wait: 0,
			all_complete: 0,
			all_fail: 0,
			last_update: false,
			years: years,
			year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
			month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
			day: Helper.getParameterByName('day') ? Helper.getParameterByName('day') : parseFloat(new Date().getDate()),
			is_info_loading: true,
		}
	}

	componentDidMount() {
		this.fetchDataInfo();
		this.fetchDayInfo();
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.location.search !== prevProps.location.search) {
			this.setState({
				is_loading: true,
				page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
				name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
				status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
				book_type: Helper.getParameterByName('book_type') ? Helper.getParameterByName('book_type') : '',
				limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : default_limit,
				timerage: Helper.getParameterByName('timerage') ? Helper.getParameterByName('timerage') : 'รายวัน',
				date: Helper.getParameterByName('date') ? Helper.getParameterByName('date') : now_date.getTime(),
				userUID: Helper.getParameterByName('userUID') ? Helper.getParameterByName('userUID') : '',
				providerUID: Helper.getParameterByName('providerUID') ? Helper.getParameterByName('providerUID') : '',
				year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
				month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
				day: Helper.getParameterByName('day') ? Helper.getParameterByName('day') : parseFloat(new Date().getDate()),
				provider_all: 0,
				// provideruserOnline_count: 0,
			}, () => {
				this.$timeRage.val(this.state.timerage)
				// this.fetchDayInfo();
				this.fetchData();
			})
		}
		if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
			this.setState({
				is_loading: true,
				page: 1,
				limit: default_limit,
				name: '',
				year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
				month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
				book_type: Helper.getParameterByName('book_type') ? Helper.getParameterByName('book_type') : '',
			}, () => {
				this.$timeRage.val(this.state.timerage)
				this.fetchDayInfo();
				this.fetchData();
			})
		}

		if (this.props.location.search !== prevProps.location.search
			&& (
				(Helper.getParameterByName('timerage') && Helper.getParameterByName('timerage') !== this.state.timerage)
				|| (Helper.getParameterByName('date') && Helper.getParameterByName('date') !== this.state.date)
				|| (Helper.getParameterByName('year') && Helper.getParameterByName('year') !== this.state.year)
				|| (Helper.getParameterByName('month') && Helper.getParameterByName('month') !== this.state.month)
				|| (Helper.getParameterByName('day') && Helper.getParameterByName('day') !== this.state.day)
				|| (Helper.getParameterByName('book_type') && Helper.getParameterByName('book_type') !== this.state.book_type)
				|| (Helper.getParameterByName('name') && Helper.getParameterByName('name') !== this.state.name)
			)
		) {

			this.setState({
				is_status_btn_loading: true,
			}, () => {
				this.fetchDayInfo();
			})
		}
	}


	async fetchDataInfo() {
		let now_count = 0;
		let pre_count = 0;
		let all_complete = 0;
		let all_fail = 0;
		let all_wait = 0;
		let all_orders = 0;
		let provideruserOnline_count = 0;
		let filter = {};
		if (this.state.timerage) {
			filter.timerage = this.state.timerage;
		}
		if (this.state.providerUID) {
			filter.providerUID = this.state.providerUID;
		}
		if (this.state.userUID) {
			filter.userUID = this.state.userUID;
		}
		if (this.state.day) {
			filter.day = this.state.day;
		}
		if (this.state.month) {
			filter.month = this.state.month;
		}
		if (this.state.year) {
			filter.year = this.state.year;
		}

		let res = await axios.get(`${window.api_host}/booking-status-report?${window.$.param(filter)}`);
		if (res.data.success) {
			now_count = res.data.now_count;
			pre_count = res.data.pre_count;
			all_complete = res.data.all_complete;
			all_fail = res.data.all_fail;
			all_wait = res.data.all_wait;
			provideruserOnline_count = res.data.provideruserOnline_count;
			all_orders = res.data.all_orders;
		}

		this.setState({
			provideruserOnline_count: provideruserOnline_count,
			pre_count: pre_count,
			now_count: now_count,
			all_orders: all_orders,
			all_wait: all_wait,
			all_complete: all_complete,
			all_fail: all_fail,
			is_info_loading: false,
		})
		return;
	}
	async fetchDayInfo() {
		if (this.state.statusCancel !== undefined) this.state.statusCancel();
		let order_today = 0;
		let order_day = 0;
		let status_count = {
			accept: 0,
			arrive: 0,
			cancel: 0,
			done: 0,
			finish: 0,
			finish1: 0,
			finish2: 0,
			going: 0,
			wait: 0,
			working: 0,
			refund: 0,
			reverse: 0,
		};

		let filter = {};
		if (this.state.timerage) {
			filter.timerage = this.state.timerage;
		}
		if (this.state.providerUID) {
			filter.providerUID = this.state.providerUID;
		}
		if (this.state.userUID) {
			filter.userUID = this.state.userUID;
		}
		if (this.state.day) {
			filter.day = this.state.day;
		}
		if (this.state.month) {
			filter.month = this.state.month;
		}
		if (this.state.year) {
			filter.year = this.state.year;
		}
		if (this.state.name) {
			filter.name = this.state.name;
		}
		if (this.state.book_type) {
			filter.book_type = this.state.book_type;
		}
		if (this.state.timerage === 'รายวัน') {
			let day = this.state.day;
			let month = parseFloat(this.state.month);
			let year = this.state.year;
			filter.startDate = new Date(year, month, day);
			filter.startDate.setHours(0, 0, 1);
			filter.endDate = new Date(filter.startDate)
			filter.endDate.setDate(filter.endDate.getDate());
			filter.endDate.setHours(23, 59, 0);
		}
		if (this.state.timerage === 'รายเดือน') {
			filter.startDate = new Date(this.state.year, parseFloat(this.state.month), 2);
			filter.startDate.setHours(0, 0, 1);
			filter.endDate = new Date(this.state.year, parseFloat(this.state.month) + 1, 2);
			filter.endDate.setHours(0, 0, 1);
		}
		if (this.state.timerage === 'รายปี') {
			filter.startDate = new Date(this.state.year, 0, 2);
			filter.startDate.setHours(0, 0, 1);
			filter.endDate = new Date(this.state.year + 1, 0, 2);
			filter.endDate.setHours(0, 0, 1);
		}

		if (filter.startDate) {
			filter.startDate = filter.startDate.getTime();
		}
		if (filter.endDate) {
			filter.endDate = filter.endDate.getTime();
		}
		let res = await axios.get(`${window.api_host}/booking-status?${window.$.param(filter)}`, {
			cancelToken: new CancelToken(c => this.state.statusCancel = c),
		});
		if (res.data.success) {
			order_today = res.data.order_today;
			order_day = res.data.order_day;
			status_count = res.data.status_count;
		}

		this.setState({
			status_count: status_count,
			order_today: order_today,
			order_day: order_day,
			is_status_btn_loading: false,
		})
	}

	async fetchData() {
		if (this.state.fetchCancel) { this.state.fetchCancel(); }
		let qs = {};
		qs.page = this.state.page;
		qs.limit = this.state.limit;
		qs.timerage = this.state.timerage;
		if (this.state.timerage === 'รายวัน') {
			let day = this.state.day;
			let month = parseFloat(this.state.month);
			let year = this.state.year;
			qs.startDate = new Date(year, month, day);
			qs.startDate.setHours(0, 0, 1);
			qs.endDate = new Date(qs.startDate)
			qs.endDate.setDate(qs.endDate.getDate());
			qs.endDate.setHours(23, 59, 0);
		}
		if (this.state.timerage === 'รายเดือน') {
			qs.startDate = new Date(this.state.year, parseFloat(this.state.month), 2);
			qs.startDate.setHours(0, 0, 1);
			qs.endDate = new Date(this.state.year, parseFloat(this.state.month) + 1, 2);
			qs.endDate.setHours(0, 0, 1);
		}
		if (this.state.timerage === 'รายปี') {
			qs.startDate = new Date(this.state.year, 0, 2);
			qs.startDate.setHours(0, 0, 1);
			qs.endDate = new Date(this.state.year + 1, 0, 2);
			qs.endDate.setHours(0, 0, 1);
		}
		if (qs.startDate) {
			qs.startDate = qs.startDate.getTime();
		}
		if (qs.endDate) {
			qs.endDate = qs.endDate.getTime();
		}
		if (this.state.status) {
			qs.status = this.state.status;
		}
		if (this.state.name) {
			qs.name = this.state.name;
		}
		if (this.state.book_type) {
			qs.book_type = this.state.book_type
		}
		if (this.state.providerUID) {
			qs.providerUID = this.state.providerUID
		}
		if (this.state.userUID) {
			qs.userUID = this.state.userUID
		}
		// console.log(qs);return
		let data = [];
		let count_all = 0;
		try {
			let res = await axios.get(`${window.api_host}/booking?${window.$.param(qs)}`, {
				cancelToken: new CancelToken(c => this.state.fetchCancel = c),
			});
			if (res.data.data) { data = res.data.data; }
			if (res.data.count_all) { count_all = res.data.count_all; }
			// console.log(data)
		} catch (error) {

		}

		this.setState({
			data: data,
			all_items: count_all,
			is_loading: false,
		})
	}

	async changeStatus(status, data) {
		Helper.confirmModal({
			title: "แก้ไขสถานะ",
			description: "ยืนยันการแก้ไขสถานะ Booking",
			onConfirm: async () => {
				this.setState({
					is_status_btn_loading: true
				}, async () => {
					try {
						await axios.post(window.api_host + '/change-books-status', {
							status: status,
							bookUid: data.id,
							adminUid: this.props.adminUid
						}).then(async (res) => {
							window.$(document).Toasts('create', {
								title: 'เปลี่ยนสถานะสำเร็จ',
								body: 'เปลี่ยนสถานะเรียบร้อยแล้ว',
								icon: 'fas fa-check',
								autohide: true,
								delay: 3000,
								class: "bg-success",
							})
							this.fetchDayInfo();
							this.fetchData();
						})
					} catch (error) {
						window.$(document).Toasts('create', {
							title: 'เปลี่ยนสถานะไม่สำเร็จ',
							body: "ไม่พบหมายเลข Booking นี้ในระบบ หรือเกิดข้อผิดพลาดในการส่งข้อมูล",
							icon: 'fas fa-times',
							autohide: true,
							delay: 3000,
							class: "bg-danger",
						})
						return;
					}
				})
			},
			onCancel: () => {
				return false;
			}
		});

	}


	delete(data) {
		if (data.transferImageUrl) {
			storage.ref(data.transferImageUrl).delete();
		}
		let documentRef = db.doc(`provider_users_data/` + data.id);
		documentRef.delete().then(() => {
			this.fetchData();
			window.$(document).Toasts('create', {
				title: 'ลบข้อมูลสำเร็จ',
				body: 'ทำการลบข้อมูลสำเร็จ',
				icon: 'fas fa-check',
				autohide: true,
				delay: 3000,
			})
		});
	}

	setStatusSearch(status) {
		if (this.state.is_loading) { return; }
		// this.$timeRage.val('alltime');
		this.setState({
			is_loading: true,
			status: status,
		}, () => {
			this.$searchForm.find('[type="submit"]').trigger('click');
		})
	}

	render() {
		let block_btn = this.state.is_status_btn_loading || this.state.is_loading ? true : false;
		return (
			<>
				<div className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h1 className="m-0 text-dark">รายการ Booking {
									this.state.status === 'cancel' ?
										"ยกเลิก"
										: null
								}</h1>
							</div>
							<div className="col-sm-6">
								<ol className="breadcrumb float-sm-right">
									<li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
									{
										this.state.status === 'cancel' ?
											<li className="breadcrumb-item"><Link to="/booking">รายการ Booking</Link></li>
											: null
									}
									<li className="breadcrumb-item active">
										{
											this.state.status === 'cancel' ?
												"รายการยกเลิก"
												: "รายการ Booking"
										}</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<section className={"content"}>
					<div className="container-fluid">
						<div className="card">
							<form className="card-header "
								style={{ display: 'flex', alignItems: 'center' }}
								ref={(ref) => { this.$searchForm = window.$(ref) }}
								onSubmit={(e) => {
									e.preventDefault();
									let data = Helper.getFormInputObject(this.$searchForm);
									let url = Helper.getQueryStringLinkFromObject({
										page: 1,
										...data
									})
									navigate(url)
								}}
							>
								<h3 className="card-title" style={{ display: 'flex', alignItems: 'center' }}>รายการ Booking</h3>
								{
									!this.state.userUID
										&& !this.state.providerUID ?
										<>
											<div style={{ marginLeft: 15 }}>
												<select className={"form-control form-control-sm"}
													name={"timerage"}
													ref={(ref) => { this.$timeRage = window.$(ref) }}
													onChange={(e) => {

														this.searchName.val("")
														// this.$timeRage.val(e.target.value);
														this.$searchForm.find('[type="submit"]').trigger('click');
													}}
													defaultValue={this.state.timerage}
												>
													<option value={"alltime"}>ทั้งหมด</option>
													<option value={"รายวัน"}>รายวัน</option>
													<option value={"รายเดือน"}>รายเดือน</option>
													<option value={"รายปี"}>รายปี</option>
												</select>
											</div>
											{
												this.state.timerage === 'รายวัน' ?
													<div style={{ marginLeft: 10 }}>
														<DatePicker
															onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
															onChange={date => {
																let _date = new Date(date)
																_date.setHours(0, 0, 0);
																this.setState({
																	day: _date.getDate(),
																	month: _date.getMonth(),
																	year: _date.getFullYear(),
																	date: _date.getTime(),
																}, () => {
																	this.searchName.val("")
																	this.$searchForm.find('[type="submit"]').trigger('click');
																})
															}}
															locale="th-TH"
															selected={Helper.getDateToEdit(this.state.date)}
															placeholderText={"วัน / เดือน / ปี"}
															name={"birthDay"}
															dateFormat="MMMM d, yyyy h:mm aa"
															// withPortal
															renderCustomHeader={({
																date,
																changeYear,
																changeMonth,
																decreaseMonth,
																increaseMonth,
																prevMonthButtonDisabled,
																nextMonthButtonDisabled
															}) => {
																let now = new Date();
																let selected_date = new Date(date);
																// months_th
																const years = [];
																let i;
																for (i = 1980; i <= now.getFullYear() + 1; i++) {
																	years.push(i)
																}
																return (
																	<div
																		style={{
																			margin: 10,
																			display: "flex",
																			justifyContent: "center"
																		}}
																	>
																		<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
																			className={"btn btn-primary "}
																			type={"button"}
																			style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
																		>
																			<i className="fas fa-chevron-left" />
																		</button>
																		<select
																			className={"form-control"}
																			value={selected_date.getFullYear()}
																			onChange={({ target: { value } }) => { console.log(value); changeYear(value) }}
																			style={{ borderRadius: 0 }}
																		>
																			{years.map(option => (
																				<option key={option} value={option} >
																					{option + 543}
																				</option>
																			))}
																		</select>

																		<select
																			className={"form-control"}
																			value={window.months_th[selected_date.getMonth()]}
																			onChange={({ target: { value } }) =>
																				changeMonth(window.months_th.indexOf(value))
																			}
																			style={{ borderRadius: 0 }}
																		>
																			{window.months_th.map(option => (
																				<option key={option} value={option}>
																					{option}
																				</option>
																			))}
																		</select>

																		<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
																			className={"btn btn-primary"}
																			style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
																			type={"button"}
																		>
																			<i className="fas fa-chevron-right" />
																		</button>
																	</div>
																)
															}}
															customInput={<CustomDateInput size={"sm"} />}
														/>
														<input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />
														<input type="hidden" id="day" name="day" value={this.state.day} onChange={() => { console.log('change') }} readOnly={true} />
														<input type="hidden" id="month" name="month" value={this.state.month} onChange={() => { console.log('change') }} readOnly={true} />
														<input type="hidden" id="year" name="year" defaultValue={this.state.year} onChange={() => { console.log('change') }} readOnly={true} />
													</div>
													: <input type="hidden" id="date" name="date" value={now_date.getTime()} onChange={() => { console.log('change') }} readOnly={true} />
											}
											{
												this.state.timerage === 'รายเดือน' ?
													<select className={"form-control form-control-sm"}
														style={{ width: 'auto', marginLeft: 10 }}
														name={"month"}
														ref={(ref) => { this.$month = window.$(ref) }}
														onChange={(e) => {
															this.$searchForm.find('[type="submit"]').trigger('click');
														}}
														defaultValue={this.state.month}
													>
														{
															window.months_th.map((month, month_i) => (
																<option value={month_i} key={month_i}>{month}</option>
															))
														}
													</select>
													: this.state.timerage !== 'รายวัน' ? <input type="hidden" id="month" name="month" value={new Date().getMonth()} onChange={() => { console.log('change') }} readOnly={true} />
														: null
											}
											{
												this.state.timerage === 'รายปี' ?
													<select className={"form-control form-control-sm"}
														style={{ width: 'auto', marginLeft: 10 }}
														name={"year"}
														ref={(ref) => { this.$year = window.$(ref) }}
														onChange={(e) => {
															this.$searchForm.find('[type="submit"]').trigger('click');
														}}
														defaultValue={this.state.year}
													>
														{
															this.state.years.map((years) => (
																<option value={years} key={years}>{parseFloat(years) + 543}</option>
															))
														}
													</select>
													: <input type="hidden" id="year" name="year" value={this.state.year} onChange={() => { console.log('change') }} readOnly={true} />
											}
										</>
										: <input name={"timerage"}
											type="hidden"
											ref={(ref) => { this.$timeRage = window.$(ref) }}
											defaultValue="alltime"
										/>
								}

								<select className={"form-control form-control-sm"}
									style={{ width: 'auto', marginLeft: 10 }}
									name={"book_type"}
									ref={(ref) => { this.$month = window.$(ref) }}
									onChange={(e) => {
										this.$searchForm.find('[type="submit"]').trigger('click');
									}}
									defaultValue={this.state.book_type}
								>
									<option value={""}>ประเภทการจองทั้งหมด</option>
									<option value={"now"}>จองเดี๋ยวนี้</option>
									<option value={"pre"}>จองล่วงหน้า</option>
								</select>
								<input type="hidden" name="status" value={this.state.status} />
								{
									this.state.userUID ?
										<input type="hidden" name="userUID" defaultValue={this.state.userUID} />
										: null
								}
								{
									this.state.providerUID ?
										<input type="hidden" name="providerUID" defaultValue={this.state.providerUID} />
										: null
								}
								{
									!this.state.userUID
										&& !this.state.providerUID ?
										<input type="hidden" name="name" className="form-control float-right" placeholder="ค้นหาจากชื่อ" value={this.state.name} />
										: null
								}
								<button type="submit" className="btn btn-default" style={{ display: 'none' }}>
									<i className="fas fa-search"></i>
								</button>
								<div className="card-tools" style={{ marginLeft: 'auto' }}>
									<div className={"flex row"} style={{ alignItems: 'center' }}>
										{
											this.state.status !== 'cancel' ?
												<div className="flex row" style={{ alignItems: 'center' }}>
													{
														!this.state.userUID
															&& !this.state.providerUID ?
															<div>
																<Link to={"/add-booking"} className={"btn btn-primary"} style={{ marginRight: '0.5rem', color: '#fff' }}>เพิ่ม Booking</Link>
																<Link to={"/booking-report?type=all&booking_type=admin_created"} className={"btn btn-default"} style={{ marginRight: '0.5rem' }}>รายงานสร้างจากระบบหลังบ้าน</Link>
																<Link to={"/booking-report?type=all"} className={"btn btn-default"} style={{ marginRight: '0.5rem' }}>รายงานทั้งหมด</Link>
																<Link to={"/booking-report?type=provider"} className={"btn btn-default"} style={{ marginRight: '0.5rem' }}>รายงานแม่บ้าน</Link>
																<Link to={"/booking-report?type=user"} className={"btn btn-default"} style={{ marginRight: '0.5rem' }}>รายงานสมาชิก</Link>
															</div>
															: null
													}
												</div>
												: null
										}
										<div style={{ textAlign: 'center' }}>
											( {this.state.order_today || 0} Orders Today )
											{/* {
												this.state.last_update ?
													<span style={{ marginLeft: 5 }}>
														Last Update : {Helper.getTime(this.state.last_update)}
													</span>
													: null
											} */}
										</div>
									</div>
								</div>
							</form>
							<div className="card-body">
								<div className="row">
									<div className="col-md-4">
										<div className="card card-widget widget-user" style={{ position: 'relative' }}>
											{
												this.state.is_info_loading ?
													<div className="absolute_loading">
														<div className="ui active inline loader small" ></div>
													</div>
													: null
											}
											<div className="widget-user-header bg-info" style={{ height: 'auto' }}>
												<h3 className="widget-user-username">Performance</h3>
												{/* <h5 className="widget-user-desc">Founder &amp; CEO</h5> */}
											</div>
											<div className="card-footer" style={{ paddingTop: '1rem' }}>
												<div className="row">
													<div className="col-sm-4 border-right">
														<div className="description-block">
															<span className="description-text">Orders</span>
															<h5 className="description-header">
																<div className={"link"} onClick={() => {
																	let data = {
																		page: 1,
																		status: "",
																		timerage: 'alltime'
																	}
																	if (this.state.providerUID && this.state.providerUID !== '') {
																		data.providerUID = this.state.providerUID
																	}
																	if (this.state.userUID && this.state.userUID !== '') {
																		data.userUID = this.state.userUID
																	}
																	let url = Helper.getQueryStringLinkFromObject({ ...data })
																	navigate(url);
																}}>
																	{Helper.numberFormat(this.state.all_orders)}
																</div>
															</h5>
														</div>
													</div>
													<div className="col-sm-4 border-right">
														<div className="description-block">
															<span className="description-text">Complete</span>
															<h5 className="description-header">
																<div className={"link"} onClick={() => {
																	let data = {
																		page: 1,
																		status: "finish",
																		timerage: 'alltime'
																	}
																	if (this.state.providerUID && this.state.providerUID !== '') {
																		data.providerUID = this.state.providerUID
																	}
																	if (this.state.userUID && this.state.userUID !== '') {
																		data.userUID = this.state.userUID
																	}
																	let url = Helper.getQueryStringLinkFromObject({ ...data })
																	navigate(url);
																}}>
																	{Helper.numberFormat(this.state.all_complete)}
																</div>
															</h5>
														</div>
													</div>
													<div className="col-sm-4">
														<div className="description-block">
															<span className="description-text">Failed</span>
															<h5 className="description-header">
																<div className={"link"} onClick={() => {
																	let data = {
																		page: 1,
																		status: "cancel",
																		timerage: 'alltime'
																	}
																	if (this.state.providerUID && this.state.providerUID !== '') {
																		data.providerUID = this.state.providerUID
																	}
																	if (this.state.userUID && this.state.userUID !== '') {
																		data.userUID = this.state.userUID
																	}
																	let url = Helper.getQueryStringLinkFromObject({ ...data })
																	navigate(url);
																}}>
																	{Helper.numberFormat(this.state.all_fail)}
																</div>
															</h5>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-8">
										<div className="card card-widget widget-user" style={{ position: 'relative' }}>
											{
												this.state.is_info_loading ?
													<div className="absolute_loading">
														<div className="ui active inline loader small" ></div>
													</div>
													: null
											}
											<div className="widget-user-header bg-warning" style={{ height: 'auto' }}>
												<h3 className="widget-user-username">In Progress</h3>
												{/* <h5 className="widget-user-desc">Founder &amp; CEO</h5> */}
											</div>
											<div className="card-footer" style={{ paddingTop: '1rem' }}>
												<div className="row">
													<div className="col-sm-3 border-right">
														<div className="description-block">
															<span className="description-text">Maid Online</span>
															<h5 className="description-header">{Helper.numberFormat(this.state.provideruserOnline_count)}</h5>
														</div>
													</div>
													<div className="col-sm-3 border-right">
														<div className="description-block">
															<span className="description-text">Order Waiting</span>
															<h5 className="description-header">
																<div className={"link"} onClick={() => {
																	let data = {
																		page: 1,
																		status: "wait",
																		timerage: 'alltime'
																	}
																	if (this.state.providerUID && this.state.providerUID !== '') {
																		data.providerUID = this.state.providerUID
																	}
																	if (this.state.userUID && this.state.userUID !== '') {
																		data.userUID = this.state.userUID
																	}
																	let url = Helper.getQueryStringLinkFromObject({ ...data })
																	navigate(url);
																}}>
																	{Helper.numberFormat(this.state.all_wait)}
																</div>
															</h5>
														</div>
													</div>
													<div className="col-sm-3 border-right">
														<div className="description-block">
															<span className="description-text">Order Now </span>
															<h5 className="description-header">
																<div className={"link"} onClick={() => {
																	let data = {
																		page: 1,
																		timerage: 'alltime',
																		book_type: 'now'
																	}
																	if (this.state.providerUID && this.state.providerUID !== '') {
																		data.providerUID = this.state.providerUID
																	}
																	if (this.state.userUID && this.state.userUID !== '') {
																		data.userUID = this.state.userUID
																	}
																	let url = Helper.getQueryStringLinkFromObject({ ...data })
																	navigate(url);
																}}>
																	{Helper.numberFormat(this.state.now_count)}
																</div>
															</h5>
														</div>
													</div>
													<div className="col-sm-3">
														<div className="description-block">
															<span className="description-text">Pre Order </span>
															<h5 className="description-header">
																<div className={"link"} onClick={() => {
																	let data = {
																		page: 1,
																		timerage: 'alltime',
																		book_type: 'pre'
																	}
																	if (this.state.providerUID && this.state.providerUID !== '') {
																		data.providerUID = this.state.providerUID
																	}
																	if (this.state.userUID && this.state.userUID !== '') {
																		data.userUID = this.state.userUID
																	}
																	let url = Helper.getQueryStringLinkFromObject({ ...data })
																	navigate(url);
																}}>
																	{Helper.numberFormat(this.state.pre_count)}
																</div>
															</h5>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
								<div className="flex row form-group" style={{ position: 'relative' }}>
									{
										this.state.is_status_btn_loading ?
											<div className="absolute_loading">
												<div className="ui active inline loader small" ></div>
											</div>
											: null
									}
									<button className={this.state.status === "" ? "btn btn-warning" : "btn btn-default"}
										disabled={block_btn}
										onClick={() => {
											this.setStatusSearch("");
										}}
									>ทั้งหมด <span className="badge">{this.state.order_day}</span></button>
									<button className={this.state.status === "wait" ? "btn btn-warning" : "btn btn-default"}
										style={{ marginLeft: '0.5rem' }}
										disabled={block_btn}
										onClick={() => {
											this.setStatusSearch("wait");
										}}
									>รอ <span className="badge bg-warning">{this.state.status_count.wait}</span></button>
									<button className={this.state.status === "accept" ? "btn btn-warning" : "btn btn-default"}
										style={{ marginLeft: '0.5rem' }}
										disabled={block_btn}
										onClick={() => {
											this.setStatusSearch("accept");
										}}
									>ยอมรับ <span className="badge " style={{ background: "blue", color: '#fff' }}>{this.state.status_count.accept}</span></button>
									<button className={this.state.status === "going" ? "btn btn-warning" : "btn btn-default"}
										style={{ marginLeft: '0.5rem' }}
										disabled={block_btn}
										onClick={() => {
											this.setStatusSearch("going");
										}}
									>กำลังเดินทาง <span className="badge " style={{ background: "orange", color: '#fff' }}>{this.state.status_count.going}</span></button>
									<button className={this.state.status === "arrive" ? "btn btn-warning" : "btn btn-default"}
										style={{ marginLeft: '0.5rem' }}
										disabled={block_btn}
										onClick={() => {
											this.setStatusSearch("arrive");
										}}
									>ถึงแล้ว <span className="badge " style={{ background: "purple", color: '#fff' }}>{this.state.status_count.arrive}</span></button>
									<button className={this.state.status === "working" ? "btn btn-warning" : "btn btn-default"}
										style={{ marginLeft: '0.5rem' }}
										disabled={block_btn}
										onClick={() => {
											this.setStatusSearch("working");
										}}
									>กำลังทำงาน <span className="badge " style={{ background: "darkblue", color: '#fff' }}>{this.state.status_count.working}</span></button>
									{/* <button className={this.state.status === "done" ? "btn btn-warning" : "btn btn-default"}
										style={{ marginLeft: '0.5rem' }}
										onClick={() => {
											this.setStatusSearch("done");
										}}
									>เสร็จแล้ว <span className="badge" style={{ background: 'lightgreen', color: '#000' }}>{this.state.status_count.done}</span></button> */}
									<button className={this.state.status === "finish1" ? "btn btn-warning" : "btn btn-default"}
										style={{ marginLeft: '0.5rem' }}
										disabled={block_btn}
										onClick={() => {
											this.setStatusSearch("finish1");
										}}
									>เสร็จงาน <span className="badge bg-success">{this.state.status_count.finish1}</span></button>
									<button className={this.state.status === "finish2" ? "btn btn-warning" : "btn btn-default"}
										style={{ marginLeft: '0.5rem' }}
										disabled={block_btn}
										onClick={() => {
											this.setStatusSearch("finish2");
										}}
									>ส่งงาน <span className="badge" style={{ background: 'darkgreen', color: '#fff' }}>{this.state.status_count.finish2}</span></button>
									<button className={this.state.status === "cancel" ? "btn btn-warning" : "btn btn-default"}
										style={{ marginLeft: '0.5rem' }}
										disabled={block_btn}
										onClick={() => {
											this.setStatusSearch("cancel");
										}}
									>ยกเลิก <span className="badge bg-danger">{this.state.status_count.cancel}</span></button>
									<button className={this.state.status === "refund" ? "btn btn-warning" : "btn btn-default"}
										style={{ marginLeft: '0.5rem' }}
										disabled={block_btn}
										onClick={() => {
											this.setStatusSearch("refund");
										}}
									>คืนเงิน <span className="badge bg-danger">{this.state.status_count.refund}</span></button>
									{
										!this.state.userUID
											&& !this.state.providerUID ?
											<form style={{ marginLeft: 'auto' }}
												onSubmit={(e) => {
													if (block_btn) { return; }
													e.preventDefault();
													if (this.searchName.val() !== "") {
														this.$timeRage.val('alltime')
													}
													this.$searchForm.find('[type="submit"]').trigger('click');
												}}
											>
												<div className="input-group input-group-sm" style={{ marginTop: 0 }}>
													<input type="text"
														name="name"
														ref={(ref) => this.searchName = window.$(ref)}
														className="form-control float-right"
														placeholder="ค้นหาจากเลข booking"
														defaultValue={this.state.name}
														style={{ minWidth: 260 }}
														disabled={block_btn}
														onChange={(e) => {
															this.setState({
																name: e.target.value,
															})
														}}
													/>
													<div className="input-group-append">
														<button type="submit"
															className="btn btn-default"
															disabled={block_btn}
														>
															<i className="fas fa-search"></i>
														</button>
													</div>
												</div>
											</form>
											: null
									}
								</div>
								<table className="table table-bordered">
									<thead>
										<tr>
											<th>เลข Booking</th>
											<th>สมาชิก</th>
											<th>แม่บ้าน</th>
											<th>เวลาและสถานที่บริการ</th>
											<th style={{ width: 110 }}>ค่าบริการ</th>
											<th style={{ width: 200 }}>สถานะ</th>
											<th style={{ width: 150 }}>ระบบ</th>
										</tr>
									</thead>
									<tbody>
										{
											!this.state.is_loading
												&& this.state.data.length > 0 ?
												this.state.data.map((_data, _data_i) => {
													let summary = Helper.bookingSummary({ booking: _data, payment: _data.payment });
													// console.log(summary)
													let plustime_app_price_only = summary.plustime_app_price_only || 0;
													let plustime_price = summary.plustime_price;
													let plustime_total_price = summary.plustime_total_price;
													let plustime_app_price = summary.plustime_app_price;
													let plustime_provider_price = summary.plustime_provider_price;
													let hours = summary.hours;
													let total_price = summary.total_price;
													let wait_count = 0;
													let total_discount = summary.total_discount;
													let plusTime = 0;
													if (_data.plusTime?.length > 0) {
														for (const ptime of _data.plusTime) {
															if (ptime.status == 'success') {
																plusTime += parseFloat(ptime.time)
															}
														}
													}
													if (_data?.priceUrgentProvider) {
														plustime_provider_price = parseFloat(plustime_provider_price) + parseFloat(_data.priceUrgentProvider)
													}
													let appPriceProvider = _data.priceProviderWithoutAgent || _data.priceProvider;
													if (_data.changeBookPrice) {
														appPriceProvider = _data.priceProvider;
													}

													let appTotal = 0;
													let appGrandTotal = 0;
													let appPrice = 0;
													let appCommittion = 0;
													let appGrandTotalWithUrgent = 0;
													let appGrandTotalWithVat = 0;
													if (_data) {
														appPrice = _data.priceAppWithoutAgent || _data.priceApp;
														if (_data.changeBookPrice) {
															appPrice = _data.priceApp;
														}
														if (_data?.priceAgentCommissionFromApp) {
															appCommittion = parseFloat(_data.priceAgentCommissionFromApp);
														}
														let discount = 0;
														if (_data?.priceDiscount) {
															discount = parseFloat(_data.priceDiscount);
														}
														let chargePrice = _data?.chargePrice || 0
														appTotal = ((parseFloat(appPrice)) - parseFloat(appCommittion)) - discount;
														appGrandTotal = (parseFloat(appPrice) - parseFloat(appCommittion)) - discount;

														let priceUrgentApp = _data?.priceUrgentApp ? parseFloat(_data?.priceUrgentApp) : 0;
														if (_data?.priceUrgentVat) {
															priceUrgentApp += parseFloat(_data?.priceUrgentVat)
														}
														appGrandTotalWithVat = (appGrandTotal > 0 ? appGrandTotal : 0) + chargePrice
														appGrandTotalWithUrgent = appGrandTotalWithVat + priceUrgentApp + plustime_app_price_only
													}
													return (
														<tr key={_data_i}>
															<td>
																{_data.bookOn} {_data.createdAdmin ? <div className="badge badge-warning">สร้างจากระบบหลังบ้าน</div> : null}
																<div>
																	<i className={"fas fa-circle"} style={{ color: _data.bookType === 'now' ? "green" : "blue" }}></i>  {" "}
																	{_data.bookType === 'now' ? "เดี๋ยวนี้" : null}
																	{_data.bookType === 'pre' ? "จองล่วงหน้า" : null}
																</div>
																{
																	_data.bookedAt ?
																		<div>{Helper.getDateThai(_data.bookedAt, { is_show_time: true })}</div>
																		: null
																}
															</td>
															<td>
																{
																	_data.user ?
																		<>
																			<div>{_data.user.displayName}</div>
																			{
																				_data.user.phoneNumber ?
																					<div><i className="fas fa-phone"></i> {_data.user.phoneNumber.replace('+66', '0')}</div>
																					: null
																			}
																			{
																				_data.user.guestPhoneNumber ?
																					<div><i className="fas fa-phone"></i> {_data.user.guestPhoneNumber.replace('+66', '0')}</div>
																					: null
																			}
																			{/* {
																				_data.bookedAt ?
																					Helper.getDateThai(_data.bookedAt, { is_show_time: true }) : null
																			} */}
																		</>
																		: '-'
																}
															</td>
															<td>
																{
																	_data.providerUser.uid ?
																		<>
																			<div>{_data.providerUser.displayName}
																				{
																					_data.bookStatus === 'wait' && _data.paymentStatus === 'success' ?
																						<span className="badge badge-info salary-custom-badge" style={{ marginLeft: 5 }}>รอรับงาน</span>
																						: null
																				}
																				{
																					_data.bookStatus === 'wait' && _data.paymentStatus === 'wait' ?
																						<span className="badge badge-info salary-custom-badge" style={{ marginLeft: 5 }}>รอชำระเงิน</span>
																						: null
																				}
																			</div>
																			{
																				_data.providerUser.phoneNumber ?
																					<div><i className="fas fa-phone"></i> {_data.providerUser.phoneNumber.replace('+66', '0')}</div>
																					: null
																			}

																		</>
																		: null
																}
																{
																	_data.providerUser?.agent ?
																		<>
																			<strong>ตัวแทน : </strong> <a href={`${window.location.origin}/agent/${_data.providerUser.agentUid}`} target={"_blank"}>{_data.providerUser.agent.name}</a>
																		</>
																		: null
																}
																{
																	!_data.providerUser.uid && _data.paymentStatus === 'success' ?
																		<span className="badge badge-warning salary-custom-badge" style={{ marginLeft: 5 }}>กำลังค้นหางาน</span>
																		: null
																}
																{
																	!_data.providerUser.uid && _data.paymentStatus === 'wait' ?
																		<span className="badge badge-info salary-custom-badge" style={{ marginLeft: 5 }}>รอชำระเงิน</span>
																		: null
																}
																{
																	!_data.providerUser.uid && _data.paymentStatus === 'fail' ?
																		<span className="badge badge-danger salary-custom-badge" style={{ marginRight: 5 }}>ชำระเงินไม่สำเร็จ</span>
																		: null
																}
															</td>
															<td>
																{_data.address && _data.address.name ?
																	<div><label>ชื่อสถานที่ : </label> {_data.address.name}</div>
																	: null
																}
																{_data.address && _data.address.placeType && _data.address.placeType.name_th ?
																	<div><label>ประเภท : </label> {_data.address.placeType.name_th}</div>
																	: null
																}
																{_data.address && _data.address.placeType && _data.address.placeType.place_sub_type && _data.address.placeType.place_sub_type.description_th ?
																	<div><label>พืนที่ : </label> {_data.address.placeType.place_sub_type.description_th}</div>
																	: null
																}
																{_data.address && _data.bookTime && _data.bookTime.name_th ?
																	<div><label>กี่ชั่วโมง : </label> {_data.bookTime.name_th}

																		{
																			plusTime > 0 ?
																				<span style={{ marginLeft: 5 }}>( <label>เพิ่มเวลา : </label> {plusTime} ชม. )</span>
																				: null
																		}
																	</div>
																	: null
																}
															</td>
															<td>
																<div style={{ color: '#000' }}>฿ {Helper.numberFormat(plustime_total_price, { decimal: 2 })}</div>
																<div style={{ color: '#ffc107' }}>฿ {Helper.numberFormat(plustime_provider_price, { decimal: 2 })}</div>
																<div style={{ color: 'blue' }}>฿ {Helper.numberFormat(appGrandTotalWithUrgent, { decimal: 2 })}</div>
															</td>
															<td>
																<div className={"flex row"}>
																	{
																		_data.bookStatus === 'accept' && _data.paymentStatus !== 'refund' && _data.paymentStatus !== 'reverse' ?
																			<div><i className={"fas fa-circle"} style={{ color: "blue" }}></i>  ยอมรับ</div>
																			: null
																	}
																	{
																		_data.bookStatus === 'arrive' && _data.paymentStatus !== 'refund' && _data.paymentStatus !== 'reverse' ?
																			<div><i className={"fas fa-circle"} style={{ color: "purple" }}></i>  ถึงแล้ว</div>
																			: null
																	}
																	{
																		_data.bookStatus === 'done' && _data.paymentStatus !== 'refund' && _data.paymentStatus !== 'reverse' ?
																			// <div><i className={"fas fa-circle"} style={{ color: "lightgreen" }}></i>  เสร็จสิ้น</div>
																			<div><i className={"fas fa-circle"} style={{ color: "green" }}></i>  เสร็จงาน</div>
																			: null
																	}
																	{
																		_data.bookStatus === 'finish' && parseFloat(_data.starFromProvider) === 0 && _data.paymentStatus !== 'refund' ?
																			<div><i className={"fas fa-circle"} style={{ color: "green" }}></i>  เสร็จงาน</div>
																			: null
																	}
																	{
																		_data.bookStatus === 'finish' && parseFloat(_data.starFromProvider) > 0 && _data.paymentStatus !== 'refund' ?
																			<div><i className={"fas fa-circle"} style={{ color: "darkgreen" }}></i>  ส่งงาน</div>
																			: null
																	}
																	{
																		_data.bookStatus === 'going' && _data.paymentStatus !== 'refund' && _data.paymentStatus !== 'reverse' ?
																			<div><i className={"fas fa-circle"} style={{ color: "orange" }}></i>  กำลังเดินทาง</div>
																			: null
																	}
																	{
																		_data.bookStatus === 'wait' && _data.paymentStatus !== 'refund' && _data.paymentStatus !== 'reverse' ?
																			<div><i className={"fas fa-circle"} style={{ color: "yellow" }}></i>  รอ</div>
																			: null
																	}
																	{
																		_data.bookStatus === 'working' && _data.paymentStatus !== 'refund' && _data.paymentStatus !== 'reverse' ?
																			<div><i className={"fas fa-circle"} style={{ color: "darkblue" }}></i>  กำลังทำงาน</div>
																			: null
																	}
																	{
																		_data.bookStatus === 'cancel' && _data.paymentStatus !== 'refund' ?
																			<div><i className={"fas fa-circle"} style={{ color: "red" }}></i>  ยกเลิก</div>
																			: null
																	}
																	{
																		_data.paymentStatus === 'refund' ?
																			<div><i className={"fas fa-circle"} style={{ color: "red" }}></i>  คืนเงิน</div>
																			: null
																	}
																	{
																		_data.bookStatus !== 'cancel'
																			&& _data.bookStatus !== 'finish'
																			&& (_data.bookStatus !== 'wait'
																				&& _data.paymentStatus !== 'refund'
																				&& _data.paymentStatus !== 'wait'
																				&& _data.paymentStatus !== 'fail')
																			|| ((_data.bookStatus === 'finish' && parseFloat(_data.starFromProvider) === 0 && _data.paymentStatus !== 'refund')
																				|| (_data.bookStatus === 'done' && _data.paymentStatus !== 'refund' && _data.paymentStatus !== 'reverse')) ?
																			<div style={{ marginLeft: 'auto' }}>
																				<div className="dropdown">
																					<button className="btn btn-default btn-xs dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																						<i className="far fa-edit"></i>
																					</button>
																					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
																						{
																							_data.bookStatus === 'accept' ?
																								null
																								:
																								<div className="dropdown-item" style={{ cursor: 'pointer' }}
																									onClick={() => {
																										this.changeStatus(1, _data);
																									}}
																								>
																									<i className={"fas fa-circle"} style={{ color: "blue" }}></i>  ยอมรับ
																								</div>
																						}
																						{
																							_data.bookStatus === 'going' ?
																								null
																								:
																								<div className="dropdown-item" style={{ cursor: 'pointer' }}
																									onClick={() => {
																										this.changeStatus(2, _data);
																									}}
																								>
																									<i className={"fas fa-circle"} style={{ color: "orange" }}></i>  กำลังเดินทาง
																								</div>
																						}
																						{
																							_data.bookStatus === 'arrive' ?
																								null
																								:
																								<div className="dropdown-item" style={{ cursor: 'pointer' }}
																									onClick={() => {
																										this.changeStatus(3, _data);
																									}}
																								>
																									<i className={"fas fa-circle"} style={{ color: "purple" }}></i>  ถึงแล้ว
																								</div>
																						}
																						{
																							_data.bookStatus === 'working' ?
																								null
																								:
																								<div className="dropdown-item" style={{ cursor: 'pointer' }}
																									onClick={() => {
																										this.changeStatus(4, _data);
																									}}
																								>
																									<i className={"fas fa-circle"} style={{ color: "darkblue" }}></i>  กำลังทำงาน
																								</div>
																						}
																						{/* 
																						{
																							_data.bookStatus === 'done' ?
																								null
																								:
																								<div className="dropdown-item" style={{ cursor: 'pointer' }}
																									onClick={() => {
																										this.changeStatus(5, _data);
																									}}
																								>
																									<i className={"fas fa-circle"} style={{ color: "lightgreen" }}></i>  เสร็จสิ้น
																								</div>
																						} */}

																						{
																							_data.bookStatus === 'accept'
																								|| _data.bookStatus === 'going'
																								|| _data.bookStatus === 'arrive'
																								|| _data.bookStatus === 'working'
																								|| _data.bookStatus === 'done'
																								|| (_data.bookStatus === 'finish' && parseFloat(_data.starFromProvider) === 0)
																								?
																								<div className="dropdown-item" style={{ cursor: 'pointer' }}
																									onClick={() => {
																										Helper.reactToDom(window.$('body'),
																											<FinishConfirmModal
																												data={_data}
																												adminUid={this.props.adminUid}
																												onSuccess={() => {
																													this.fetchDataInfo();
																													this.fetchData();
																												}}
																											/>
																										);
																									}}
																								>
																									<i className={"fas fa-circle"} style={{ color: "green" }}></i>  ส่งงาน
																								</div>
																								: null
																						}
																						{/* {
																							_data.bookStatus === 'finish' ?
																								null
																								:
																								<div className="dropdown-item" style={{ cursor: 'pointer' }}
																									onClick={() => {
																										this.changeStatus(6, _data);
																									}}
																								>
																									<i className={"fas fa-circle"} style={{ color: "green" }}></i>  ส่งงาน
																								</div>
																						} */}

																					</div>
																				</div>
																			</div>
																			: null
																	}
																</div>
																{
																	_data.updateAdmin ?
																		<div>{_data.updateAdmin.displayName}</div>
																		: null
																}
															</td>
															<td>
																<div className="btn-group" role="group" aria-label="Basic example">
																	{/* <button className="btn btn-default"
																	onClick={() => {
																		Helper.reactToDom(
																			window.$('body'),
																			<BookingDetails
																				data={_data}
																				onCancel={() => {
																					this.fetchData();
																				}}
																			/>
																		);
																	}}
																>ดูข้อมูล</button> */}
																	<Link to={"/booking/" + _data.id} className="btn btn-default">ดูข้อมูล</Link>
																	{
																		this.props.is_super_admin ?
																			<button className="btn btn-danger"
																				ref={(ref) => {
																					window.$(ref).on('click', () => {
																						if (window.$(ref).hasClass('loading')) { return };
																						window.$(ref).addClass('loading');
																						Helper.confirmModal({
																							title: "ลบ Booking",
																							description: 'ยืนยันการลบ Booking : ' + _data.bookOn,
																							submit_text: 'ลบ',
																							onConfirm: async () => {
																								try {
																									await axios.post(window.api_host + '/books-delete', {
																										booksUid: _data.id,
																										code: 'imsuretodeleteit'
																									}).then(async (res) => {
																										if (res.data.code === 200) {
																											window.$(document).Toasts('create', {
																												title: 'ลบ Booking : ' + _data.bookOn + 'สำเร็จ',
																												icon: 'fas fa-check',
																												autohide: true,
																												delay: 3000,
																												class: "bg-success",
																											})
																											window.$(ref).removeClass('loading')
																											this.fetchData();
																										}
																									})
																								} catch (error) {
																									window.$(document).Toasts('create', {
																										title: 'ลบ Booking ไม่สำเร็จ',
																										// body: "ยกเลิกการชำระเงินไม่สำเร็จ",
																										icon: 'fas fa-times',
																										autohide: true,
																										delay: 3000,
																										class: "bg-danger",
																									})
																									window.$(ref).removeClass('loading')
																								}
																							},
																							onCancel: () => {
																								window.$(ref).removeClass('loading')
																							}
																						})
																					})
																				}}
																			>
																				ลบ
																			</button>
																			: null
																	}
																</div>
															</td>
														</tr>
													);
												})
												: null
										}
										{
											!this.state.is_loading
												&& this.state.data.length === 0 ?
												<tr >
													<td colSpan="8" style={{ textAlign: 'center' }}>
														ไม่มีข้อมูล
													</td>
												</tr>
												: null
										}
										{
											this.state.is_loading ?
												<tr >
													<td colSpan="8" style={{ textAlign: 'center' }}>
														<div className="ui active inline loader small" ></div>
													</td>
												</tr>
												: null
										}


									</tbody>
								</table>
							</div>
							<div className="card-footer clearfix">
								{
									!this.state.is_loading
										&& this.state.data.length !== 0 ?
										<Pagination
											now={this.state.page}
											all_items={this.state.all_items}
											limit={this.state.limit}
											location={this.props.location}
										/>
										: null
								}
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

class FinishConfirmModal extends React.Component {
	constructor() {
		super();
		this.state = {
			is_loading: true,
			file_stack: [],
			selected_star: 5,
			uploaded: {
				tempImages: [],
				tempPathImages: [],
			}
		}
	}

	async componentDidMount() {

		this.setState({
			is_loading: false,
		})
	}

	readURL(input) {
		if (input.files && input.files.length > 0) {
			let file_stack = [...this.state.file_stack];
			for (var key of Object.keys(input.files)) {
				file_stack.push(input.files[key]);
			}
			this.setState({
				file_stack: file_stack
			})
		}
	}

	async uploadFile(file, options) {
		const filename = Helper.setNewImageFileName(file);
		let reszieFile = await Helper.resizeImageFn(file, { maxWidth: 1280, maxHeight: 768 });
		var uploadTask = storage.ref().child('provider_users/' + filename).put(reszieFile);
		uploadTask.on('state_changed', (snapshot) => {
			// Observe state change events such as progress, pause, and resume
			// console.log(snapshot);
		}, (error) => {
			console.log(error);
			Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
		}, async () => {
			let url = 'provider_users/' + filename;
			if (options.onSuccess) {
				options.onSuccess(url);
			}
		});
	}

	async callApi(data, tempImages, tempPathImages) {
		let _res = await axios.put(`${window.api_host}/booking`, {
			update: {
				photoURLs: tempImages,
			},
			id: this.props.data.id
		})
		if (_res.data.code === 200) {
			try {
				await axios.post(window.api_host + '/books-finish', { ...data }).then(async (res) => {
					let _data = res.data;
					if (_data.code === 200) {
						let res = await axios.put(`${window.api_host}/booking`, {
							update: {
								bookStatus: 'finish',
								photoURLs: tempImages,
								storagePath: {
									pathPhotoURLs: tempPathImages
								},
								finishAdminUid: this.props.adminUid,
							},
							id: this.props.data.id
						})
						if (res.data.code === 200) {
							window.$(document).Toasts('create', {
								title: 'เปลี่ยนสถานะสำเร็จ',
								// body: 'ทำการลบข้อมูลสำเร็จ',
								icon: 'fas fa-check',
								autohide: true,
								delay: 3000,
								class: "bg-success",
							})
							if (this.props.onSuccess) {
								this.props.onSuccess();
							}
							this.$modal.modal('hide')
						} else {
							window.$(document).Toasts('create', {
								title: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
								// body: 'ทำการลบข้อมูลสำเร็จ',
								icon: 'fas fa-times',
								autohide: true,
								delay: 3000,
								class: "bg-danger",
							})
							this.$submit.removeClass('loading');
						}
					}
					if (_data.code === -2) {
						window.$(document).Toasts('create', {
							title: 'ไม่สามารถทำการเปลรายนสถานะ',
							body: 'เนื่องจากรายการนี้เป็นสถานะส่งงานแล้ว',
							icon: 'fas fa-times',
							autohide: true,
							delay: 3000,
							class: "bg-danger",
						})
						this.$submit.removeClass('loading');
					}
					if (_data.code === -5) {
						window.$(document).Toasts('create', {
							title: 'มีการทำรายการไปแล้ว',
							// body: 'ทำการลบข้อมูลสำเร็จ',
							icon: 'fas fa-times',
							autohide: true,
							delay: 3000,
							class: "bg-danger",
						})
						this.$submit.removeClass('loading');
					}
				})
			} catch (error) {
				window.$(document).Toasts('create', {
					title: 'เปลี่ยนสถานะไม่สำเร็จ',
					// body: 'ทำการลบข้อมูลสำเร็จ',
					icon: 'fas fa-times',
					autohide: true,
					delay: 3000,
					class: "bg-danger",
				})
				await db.doc('books/' + this.props.data.id).update({
					photoURLs: [],
					storagePath: {
						pathPhotoURLs: []
					}
				}
				)
				tempPathImages.map((tempPathImage) => {
					storage.ref(tempPathImage).delete();
					return true;
				})
				this.$submit.removeClass('loading');
				return;
			}
		}
	}

	async uploadImages(index, data) {
		let setIndex = index + 1;
		console.log("index", index)
		console.log("setIndex", setIndex)
		let file = this.state.file_stack[index];
		const filename = Helper.setNewImageFileName(file);
		let reszieFile = await Helper.resizeImageFn(file, { maxWidth: 1280, maxHeight: 768 });
		var uploadTask = storage.ref().child('provider_users/' + filename).put(reszieFile);
		uploadTask.on('state_changed', (snapshot) => {
			// Observe state change events such as progress, pause, and resume
			// console.log(snapshot);
		}, (error) => {
			console.log(error);
			Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
		}, async () => {
			let url = 'provider_users/' + filename;
			const photoURL = await storage.ref(url).getDownloadURL();

			let tempImages = [...this.state.uploaded.tempImages];
			let tempPathImages = [...this.state.uploaded.tempPathImages];
			tempImages.push(photoURL)
			tempPathImages.push(url);
			let uploaded = {
				tempImages: tempImages,
				tempPathImages: tempPathImages,
			}
			this.setState({
				uploaded: uploaded,
			}, () => {
				if (this.state.uploaded.tempImages.length === this.state.file_stack.length) {
					this.callApi(data, this.state.uploaded.tempImages, this.state.uploaded.tempPathImages)
				}
				if (this.state.uploaded.tempImages.length < this.state.file_stack.length) {
					this.uploadImages(setIndex, data)
				}
			})
		});
	}




	render() {
		// console.log(this.state.history);
		// console.log(this.props.data)
		let star = [1, 2, 3, 4, 5];
		return (
			<div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
				ref={(ref) => {
					this.$modal = window.$(ref);
					this.$modal.modal('show');
					this.$modal.on('hidden.bs.modal', () => {
						let settime = window.setTimeout(() => {
							this.$modal.remove();
							clearTimeout(settime);
						}, 500)
					});
					this.$form = this.$modal.find('form');
					this.$submit = this.$form.find('[type="submit"]');
				}}
			>
				<form className="modal-dialog" role="document"
					ref={(ref) => {
						this.$searchForm = window.$(ref);
					}}
					onSubmit={(e) => {
						e.preventDefault();
						if (this.$submit.hasClass('loading')) { return; }
						this.$submit.addClass('loading');
						let data = Helper.getFormInputObject(this.$searchForm);
						data.starFromProvider = this.state.selected_star;
						if (!data.reportFromProvider) {
							data.reportFromProvider = "";
						}
						if (this.state.file_stack.length === 0) {
							window.$(document).Toasts('create', {
								title: 'กรุณาอัพโหลดรูปภาพ',
								// body: 'ทำการลบข้อมูลสำเร็จ',
								icon: 'fas fa-images',
								autohide: true,
								delay: 3000,
								class: "bg-danger",
							})
							this.$submit.removeClass('loading');
							return;
						}
						// console.log(data);
						// return;
						this.uploadImages(0, data)
					}}
				>
					<div className="modal-content">

						<div className="modal-body"
							ref={(ref) => {
								this.$searchForm = window.$(ref);
							}}
						>
							{
								this.state.is_loading ?
									<div style={{ padding: '1rem', textAlign: 'center' }}>
										<div className="ui active inline loader" ></div>
									</div>
									: null
							}
							{
								!this.state.is_loading ?
									<>
										<div className={"form-group"}>
											<label>รูปภาพ</label>
											<input name="image"
												type={"file"}
												ref={(ref) => this.$file = window.$(ref)}
												style={{ display: 'none' }}
												id="file_image"
												multiple="multiple"
												onChange={(e) => {
													this.readURL(this.$file[0]);
												}}
											/>
											<div className="row" id="show_image">
												{
													this.state.file_stack
														&& this.state.file_stack.length > 0 ?
														this.state.file_stack.map((file_stack, file_stack_i) => {
															var reader = new FileReader();
															reader.onload = (e) => {
																window.$('#images_file_upload_' + file_stack_i).html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
															};
															reader.readAsDataURL(file_stack);
															return (
																<div className="col-4" key={file_stack_i} >
																	<div className="mutiple_image_frame card">
																		<div className="promotion-delete">
																			<button className="btn btn-danger"
																				type="button"
																				onClick={() => {
																					let file_stack = [...this.state.file_stack];
																					file_stack.splice(file_stack_i, 1);
																					this.setState({
																						file_stack: file_stack
																					})
																				}}
																			>
																				<i className="fas fa-trash-alt"></i>
																			</button>
																		</div>
																		<div className="card-body">
																			<div id={"images_file_upload_" + file_stack_i} />
																		</div>
																	</div>
																</div>
															);
														})
														: null
												}
												{
													this.props.data && this.props.data.imageURL ?
														<img src={this.props.data.imageURL} alt={"PromotionPic"} style={{ maxWidth: '100%' }} />
														: null
												}
											</div>
											<label htmlFor="file_image"
												type={"button"}
												className="btn btn-default"
												style={{ width: '100%', marginBottom: 0 }}
											>
												อัพโหลด
											</label>
										</div>
										<div className={"form-group"}>
											<label>ความพึงพอใจ</label>
											<div className="flex row rating_star">
												{
													star.map((star_num) => {
														return (
															<div key={star_num}
																onClick={() => {
																	this.setState({ selected_star: star_num })
																}}
																className={this.state.selected_star >= star_num ? "active" : null}
															>
																<i className="fas fa-star" />
															</div>
														);
													})
												}

											</div>
										</div>
										<div className={"form-group"}>
											<label>Comment</label>
											<textarea className={'form-control'} name="reportFromProvider"></textarea>
										</div>
										<input type="hidden" name="booksUid" value={this.props.data.id} />
										<input type="hidden" name="providerUserUid" value={this.props.data.providerUserUid} />
									</>
									: null
							}

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
							<button type="submit" className="btn btn-primary">เปลี่ยนสถานะ</button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}