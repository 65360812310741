import React from "react";
import { Link } from '@reach/router';
import { auth, db } from "./../firebase";
import CustomDateInput from './../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import { Helper } from "../Helper";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import ReverseButton from "./Books/include/ReverseButton";
import Swal from "sweetalert2";
const axios = require('axios').default;
export default class Mockup extends React.Component {
    constructor() {
        super();
        this.state = {
            end_date: false,
            hour: 23,
            minute: 59
        }
    }

    async componentDidMount() {
        // let res = await db.collection('users').where('acceptTerms', '==', true).where('guestPhoneNumber', '!=', 'null').get();
        // let data = [];
        // if (!res.empty) {
        //     for (const doc of res.docs) {
        //         data.push(doc.data())
        //     }
        // }
        // console.log(data);
        // this.fetchBooks();
        // let res = await axios.get(`${window.api_host}/test`)

        // console.log(res)
    }

    async changeProvider() {
        const button = document.querySelector('#submit-change-p');
        if (button.classList.contains('loading')) return;
        button.classList.add('loading')
        button.innerHTML = 'Loading...'
        let qs = {};
        if (this.inputFrom.val()) {
            qs.from = this.inputFrom.val();
        }
        if (this.inputTo.val()) {
            qs.to = this.inputTo.val();
        }
        let res = await axios.post(`${window.api_host}/change-provider-user-data`, qs);
        console.log(res.data)

        button.innerHTML = 'Completed'
        setTimeout(() => {
            button.innerHTML = 'Submit'
            button.classList.remove('loading')
        }, 5000)
    }
    async changeProviderBookUid() {
        const button = document.querySelector('#submit-change-p');
        if (button.classList.contains('loading')) return;
        button.classList.add('loading')
        button.innerHTML = 'Loading...'
        let qs = {};
        if (this.inputFrom.val()) {
            qs.from = this.inputFrom.val();
        }
        if (this.inputTo.val()) {
            qs.to = this.inputTo.val();
        }
        let res = await axios.post(`${window.api_host}/change-provider-book-uid`, qs);
        console.log(res.data)

        button.innerHTML = 'Completed'
        setTimeout(() => {
            button.innerHTML = 'Submit'
            button.classList.remove('loading')
        }, 5000)
    }
    async changeUserUid() {
        const button = document.querySelector('#submit-change-p');
        if (button.classList.contains('loading')) return;
        button.classList.add('loading')
        button.innerHTML = 'Loading...'
        let qs = {};
        if (this.inputFrom.val()) {
            qs.from = this.inputFrom.val();
        }
        if (this.inputTo.val()) {
            qs.to = this.inputTo.val();
        }
        let res = await axios.post(`${window.api_host}/change-user-uid`, qs);
        console.log(res.data)

        button.innerHTML = 'Completed'
        setTimeout(() => {
            button.innerHTML = 'Submit'
            button.classList.remove('loading')
        }, 5000)
    }

    async getTest() {
        console.log(this.input);
        let qs = {};
        if (this.input.val()) {
            qs.phoneNumber = this.input.val();
        }
        let res = await axios.get(`${window.api_host}/get-promotion-test?${window.$.param(qs)}`);
        let data = [];
        if (res.data?.items) {
            for (const item of res.data.items) {
                if (item.adjustSalary?.length > 0) {
                    data.push(item)
                }
            }
        }
        console.log(data)
    }

    async onSubmit() {
        let data = {}
        data.uid = window.$('#uid').val();
        let res = await axios.post(`${window.api_host}/book-change-name`, data)

    }

    async fetchBooks(limit, offset) {
        let data = {}
        data.limit = limit;
        data.offset = offset;
        let res = await axios.post(`${window.api_host}/set-provider-data`, data)
        if (res.data.success) {
            setTimeout(() => {
                let newOffset = parseFloat(limit) + parseFloat(offset)
                this.fetchBooks(limit, newOffset)
            }, 2000)
        }
    }

    async updateProviderServiceArea() {
        let data = {};
        data.uid = this.input.val();
        data.bookUid = this.inputBook.val();
        let res = await axios.post(`${window.api_host}/set-provider-service-area`, data)
    }

    async getNoserviceArea() {
        console.log('get provider')
        let res = await db.collection(`provider_users_data`).where('status', "==", "avaliable").where('amphoesCode', '==', []).get();
        let data = [];
        if (!res.empty) {
            for (const doc of res.docs) {
                let p = doc.data();
                // let bookCountRef = await db.collection('books').where('providerUserUid', '==', doc.id).get();
                // p.bookCount = bookCountRef.size;
                data.push(p)
            }
        }
        this.setState({
            providerNoAmphoes: data
        }, () => {
            let export_data = [];
            if (this.state.providerNoAmphoes) {
                this.state.providerNoAmphoes.map((_data, i) => {
                    let prepare = {};
                    prepare.name = `${_data.prefix} ${_data.firstName} ${_data.lastName}`;
                    prepare.phoneNumber = `${_data.phoneNumber}`;
                    prepare.lineID = `${_data.lineID}`;
                    prepare.tranningStatusData = _data.tranningStatusData ? _data.tranningStatusData.status_name : 'ไม่มีสถานะ';
                    let status = "-";
                    if (_data.status === 'waitForApprove') {
                        status = "รอการยืนยัน"
                    }
                    if (_data.status === 'approved') {
                        status = "ยืนยันแล้ว"
                    }
                    if (_data.status === 'avaliable') {
                        status = "ใช้งานได้"
                    }
                    if (_data.status === 'ban') {
                        status = "ระงับการใช้งาน"
                    }
                    prepare.status = status;
                    prepare.bank = _data.bank;
                    prepare.bankBranch = _data.bankBranch;
                    prepare.bankAccountNo = _data.bankAccountNo;
                    prepare.bankAccountName = _data.bankAccountName;
                    prepare.created = Helper.getDateThai(_data.createdAt, { is_show_time: true });
                    // prepare.bookCount = prepare.bookCount;
                    export_data.push(prepare)
                });
            }
            this._export.save(export_data);
        })
    }


    async TestOTP() {
        const phoneNumber = '+66633433709';
        const OTP = `301714`;
        const isProvider = true;
        const button = document.querySelector('#submit-send-otp');
        if (button.classList.contains('loading')) return;
        button.classList.add('loading')
        button.innerHTML = 'Loading...'

        let res = await axios.post(`${window.api_host}/send-otp`, {
            phoneNumber,
            OTP,
            isProvider
        });
        const test = await auth.signInWithEmailAndPassword(res.data.user.email, res.data.password);
        console.log(test)
        // console.log(res.data)

        button.innerHTML = 'Completed'
        setTimeout(() => {
            button.innerHTML = 'Submit'
            button.classList.remove('loading')
        }, 5000)
    }

    render() {
        let export_data = [];
        if (this.state.providerNoAmphoes) {
            this.state.providerNoAmphoes.map((_data, i) => {
                let prepare = {};
                prepare.name = `${_data.prefix} ${_data.firstName} ${_data.lastName}`;
                prepare.phoneNumber = `${_data.phoneNumber}`;
                prepare.lineID = `${_data.lineID}`;
                prepare.tranningStatusData = _data.tranningStatusData ? _data.tranningStatusData.status_name : 'ไม่มีสถานะ';
                let status = "-";
                if (_data.status === 'waitForApprove') {
                    status = "รอการยืนยัน"
                }
                if (_data.status === 'approved') {
                    status = "ยืนยันแล้ว"
                }
                if (_data.status === 'avaliable') {
                    status = "ใช้งานได้"
                }
                if (_data.status === 'ban') {
                    status = "ระงับการใช้งาน"
                }
                prepare.status = status;
                prepare.bank = _data.bank;
                prepare.bankBranch = _data.bankBranch;
                prepare.bankAccountNo = _data.bankAccountNo;
                prepare.bankAccountName = _data.bankAccountName;
                // prepare.bookCount = prepare.bookCount;
                prepare.created = Helper.getDateThai(_data.createdAt, { is_show_time: true });
                export_data.push(prepare)
            });
        }
        let exportTitle = "รายงานแม่บ้าน";
        let now = new Date();
        exportTitle += " (" + Helper.getDateThai(now.getTime()) + ")";
        // return (
        //     <div style={{ padding: 15 }}>
        //         <div className="form-group">
        //             <input type={"text"} ref={(ref) => this.input = window.$(ref)} placeholder="providerUid" />
        //             <input type={"text"} ref={(ref) => this.inputBook = window.$(ref)} placeholder="bookUid" />
        //             <button onClick={() => { this.updateProviderServiceArea() }}>update provider service area from book</button>
        //         </div>
        //         <label>update avaliable provider</label>
        //         <button onClick={() => { this.fetchBooks(100, 0) }}>Update provider data</button>
        //         <div>
        //             <button onClick={() => { this.getNoserviceArea() }}>get provider data no service area</button>
        //             <ExcelExport
        //                 data={export_data}
        //                 ref={exporter => this._export = exporter}
        //                 fileName={exportTitle}
        //             >
        //                 <ExcelExportColumn field="name" title="ชื่อ-นามสกุล" width={200} />
        //                 <ExcelExportColumn field="phoneNumber" title="เบอร์โทรศัพท์" width={130} />
        //                 <ExcelExportColumn field="lineID" title="Line ID" width={150} />
        //                 <ExcelExportColumn field="bank" title="ธนาคาร" width={130} />
        //                 <ExcelExportColumn field="bankBranch" title="สาขา" width={130} />
        //                 <ExcelExportColumn field="bankAccountName" title="ชื่อบัญชี" width={150} />
        //                 <ExcelExportColumn field="bankAccountNo" title="เลขบัญชี" width={150} />
        //                 <ExcelExportColumn field="tranningStatusData" title="ข้อมูลอบรม" width={130} />
        //                 <ExcelExportColumn field="bookCount" title="จำนวน book" width={130} />
        //                 <ExcelExportColumn field="status" title="สถานะ App" width={130} />
        //                 <ExcelExportColumn field="created" title="" width={130} />
        //             </ExcelExport>
        //         </div>



        //     </div>
        // )
        // return null;

        // return (
        //     <>
        //         <button id="submit-send-otp" onClick={() => {
        //             this.TestOTP()
        //         }}>
        //             TestOTP
        //         </button>
        //     </>
        // )




        let user_backup = `gbRWQnnBz0c05J9y0aEEJXWFW7S2`;
        let user_from = `gbRWQnnBz0c05J9y0aEEJXWFW7S2`;
        let user_to = `dItCtgBClfP1RdUlIBvwYU9UoN93`
        return (

            <div className="form-group">
                <input ref={(ref) => this.inputFrom = window.$(ref)} placeholder="from uid" value={user_from} readOnly />
                <input ref={(ref) => this.inputTo = window.$(ref)} placeholder="to uid" value={user_to} readOnly />
                <button className="btn btn-default"
                    type="submit"
                    id="submit-change-p"
                    onClick={() => {
                        this.changeUserUid();
                        // this.changeProvider();
                    }}
                >Submit</button>
            </div>
        );

        let backup = `JvnYbvCzMpTPw3Jl1hSKWtxmuTJ2`;
        let from = `KG7aSFRufgglOB9Lxs68CjhOPbk1`;
        let to = `4yYxbqr277RXFTfM5lC3zvWqgbb2`
        return (

            <div className="form-group">
                <input ref={(ref) => this.inputFrom = window.$(ref)} placeholder="from uid" value={from} readOnly />
                <input ref={(ref) => this.inputTo = window.$(ref)} placeholder="to uid" value={to} readOnly />
                <button className="btn btn-default"
                    type="submit"
                    id="submit-change-p"
                    onClick={() => {
                        this.changeProviderBookUid();
                        // this.changeProvider();
                    }}
                >Submit</button>
            </div>
        );
        // return (
        //     <div style={{ padding: 15 }}>
        //         <ReverseButton
        //             bookUid={`yeqUWwJfllHHl19pfWBf`}
        //             onSuccess={() => {
        //                 alert('success')
        //             }}
        //         />
        //     </div>
        // )
        return (
            <>
                <div style={{ padding: 15 }}>
                    <div className="form-group">
                        <label>Provider Uid</label>
                        <input name="uid" className="form-control" id="uid" />
                        <button className="btn btn-default"
                            onClick={() => {
                                this.onSubmit();
                            }}
                        >Submit</button>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-2">
                            <label>ชั่วโมง</label>
                            <input type="number" name="hour" value={this.state.hour} onChange={(e) => {
                                this.setState({
                                    hour: parseFloat(e.target.value),
                                });
                            }} />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>นาที</label>
                            <input type="number" name="minute" value={this.state.minute} onChange={(e) => {
                                this.setState({
                                    minute: parseFloat(e.target.value),
                                });
                            }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <DatePicker
                                onChange={date => {
                                    date = new Date(date);
                                    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), this.state.hour, this.state.minute, 0));
                                    let end_date = date.getTime() - (3600 * 7 * 1000);
                                    console.log(end_date)
                                    this.setState({
                                        end_date: end_date
                                    })
                                }}
                                selectsEnd
                                startDate={this.state.start_date}
                                endDate={this.state.end_date}
                                locale="th-TH"
                                selected={this.state.end_date}
                                placeholderText={"วัน / เดือน / ปี"}
                                name={"birthDay"}
                                dateFormat="MMMM d, yyyy 23:59:00"
                                withPortal
                                onCalendarOpen={() => { Helper.renderDate() }}
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => {
                                    date = new Date(date);
                                    let now = new Date();
                                    // months_th
                                    const years = [];
                                    let i;
                                    for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                        years.push(i)
                                    }
                                    return (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                className={"btn btn-primary "}
                                                type={"button"}
                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                            >
                                                <i className="fas fa-chevron-left" />
                                            </button>
                                            <select
                                                className={"form-control"}
                                                value={date.getFullYear()}
                                                onChange={({ target: { value } }) => changeYear(value)}
                                                style={{ borderRadius: 0 }}
                                            >
                                                {years.map(option => (
                                                    <option key={option} value={option} >
                                                        {option + 543}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                className={"form-control"}
                                                value={window.months_th[date.getMonth()]}
                                                onChange={({ target: { value } }) =>
                                                    changeMonth(window.months_th.indexOf(value))
                                                }
                                                style={{ borderRadius: 0 }}
                                            >
                                                {window.months_th.map(option => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                className={"btn btn-primary"}
                                                style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                type={"button"}
                                            >
                                                <i className="fas fa-chevron-right" />
                                            </button>
                                        </div>
                                    )
                                }}
                                customInput={<CustomDateInput />}
                            />
                        </div>
                    </div>
                    {
                        this.state.end_date ?
                            <div className="row">
                                <div className="col-12">
                                    {Helper.getDateThai(this.state.end_date, { is_show_time: true })}
                                </div>
                                <div className="col-12">
                                    <code style={{ padding: 5, background: '#f4f4f4' }}>{this.state.end_date}</code>
                                </div>
                            </div>
                            : null
                    }
                    <div style={{ marginTop: 20 }}>
                        <input ref={(ref) => { this.dateGetTime = window.$(ref) }} name="date" className="form-control" />
                        <button type="button" className="btn btn-default"
                            onClick={() => {
                                let val = this.dateGetTime.val();
                                if (!val) return;
                                alert(Helper.getDateThai(parseFloat(val), { is_show_time: true }))
                            }}
                        >Preview</button>
                    </div>
                </div>
            </>
        );
    }
}

