import React, { useContext, useEffect } from "react";
import { Router } from "@reach/router";
import SignIn from "./SignIn";
// import SignUp from "./SignUp";
// import { Helper } from "../Helper";
// import UserProvider from "../providers/UserProvider";
// import ProfilePage from "./ProfilePage";
import Header from "./../Include/Header";
import SideBar from "./../Include/SideBar";
import Dashboard from "./../Page/Dashboard";
// import TestPage from './../Page/TestPage';
import EducateDate from './../Page/EducateDate';
import ViewEducateDate from './../Page/ViewEducateDate';
import ProviderUsers from './../Page/ProviderUsers';
import EditProviderUsers from './../Page/EditProviderUsers';
import Users from './../Page/Users';
import UsersReport from './../Page/UsersReport';
import ViewUser from './../Page/ViewUser';
import Booking from './../Page/Booking';
import ViewProviderUsers from './../Page/ViewProviderUsers';
import AdminSetting from './../Page/AdminSetting';
import AdminDetail from './../Page/AdminDetail';
import ProviderSalary from './../Page/SalaryBackup'
import Promotion from './../Page/Promotion';
import ViewPromotion from './../Page/ViewPromotion';
import MarketingUsers from './../Page/MarketingUsers';
import ViewRatingProviderUser from './../Page/ViewRatingProviderUser';
import AddProviderUsers from './../Page/AddProviderUsers';
import ViewRatingUser from './../Page/ViewRatingUser';
import ViewSalary from './../Page/ViewSalary';
import Mockup from './../Page/Mockup';
import TrainningDayStatus from './../Page/TrainningDayStatus';
import PromotionHistory from './../Page/PromotionHistory';
import ManagePromotionSlide from './../Page/ManagePromotionSlide';
import ViewMarketingUser from './../Page/ViewMarketingUser';
import ServiceArea from './../Page/ServiceArea';
import EditServiceArea from './../Page/EditServiceArea';
import ViewServiceArea from './../Page/ViewServiceArea';
import BookingReport from "./../Page/BookingReport";
import TransectionsReport from "./../Page/TransectionsReport";
import EditMaps from './../Page/EditMaps';
import EditProviderUsersServiceArea from './../Page/EditProviderUsersServiceArea';
import ProviderUsersServiceArea from './../Page/ProviderUsersServiceArea';
import { UserContext } from "../providers/UserProvider";
import ViewBooking from '../Page/ViewBooking';
import Voucher from '../Page/Voucher';
import AddBooking from '../Page/AddBooking';
import Invoice from '../Page/Invoice';
import ReportList from '../Page/ReportList';
import ReportProvider from '../Page/ReportProvider';
import EditUserLocation from '../Page/EditUserLocation';
import SuspendPhoneNumber from '../Page/SuspendPhoneNumber';
import LogsReport from '../Page/LogsReport';
import PrintPreview from './../Page/PrintPreview'
import ProviderUsersReport from './../Page/ProviderUsersReport'
import ReviewCustomer from "../Page/Review/Customer";

import Agents from './../Page/Agents/List'
import AgentsView from './../Page/Agents/View'
import BillCycle from './../Page/Agents/BillCycle'
import BillCycleDetail from './../Page/Agents/BillCycleDetail'
import AgentSalary from './../Page/Agents/Salary'

import RefferalLink from './../Page/RefferelLink/List'

import AdjustReport from "../Page/Report/AdjustReport";
import PriceSummaryReport from "../Page/Report/PriceSummaryReport";
import UserReport from "../Page/Report/UserReport";
import PromotionReport from '../Page/Report/PromotionReport'


import Salary from './../Page/Salary';
import SalaryProvider from './../Page/SalaryProvider';

import CSVSalary from './../Page/Salary/CSVSalary';
import CSVSalaryProvider from './../Page/Salary/CSVSalaryProvider';
// import newSalary from './../Page/Salary/Salary'
// import newSalaryProvider from './../Page/Salary/SalaryProvider'
// import Salary from './../Page/Salary/Salary'
// import SalaryProvider from './../Page/Salary/SalaryProvider'
import DevSalary from "../Page/Salary/DevSalary";
import DevSalaryProvider from "../Page/Salary/DevSalaryProvider";
import DevProviderUsers from "../Page/DevProviderUsers";
import DevViewProviderUsers from "../Page/DevViewProviderUsers";
import DevViewSalary from "../Page/DevViewSalary";
import DevViewBooking from "../Page/DevViewBooking";


window.months_th = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม",];
window.segment = window.location.pathname.split('/');
window.api_host = 'https://asia-southeast2-beeclean-app-66b97.cloudfunctions.net/app';
window.api_host_agent = "https://asia-southeast2-beeclean-app-66b97.cloudfunctions.net/agent";

let is_emulators = false;
if (window.location.origin.indexOf('localhost:') !== -1) {
  is_emulators = true;
}
if (is_emulators) {
  window.api_host = "http://localhost:5001/beeclean-app-66b97/asia-southeast2/appDev";
  window.api_host_agent = "http://localhost:5001/beeclean-app-66b97/asia-southeast2/agentDev";
}
//  window.api_host = "http://localhost:5001/beeclean-app/asia-southeast2/app";
function Application() {
  const user = useContext(UserContext);
  // const [isAPI, setIsAPI] = useState(false);
  useEffect(() => {
    // window.$("head").find('style').remove();
  }, []);

  return (
    user && user._fieldsProto ?
      <div className={"hold-transition sidebar-mini"}>
        <div className="wrapper">
          <Header user={user} />
          <SideBar user={user} />
          <Router className="content-wrapper">
            <Dashboard path="/" />
            <Dashboard path="/dashboard" />
            {
              user._fieldsProto.isCanEditUsers
                && user._fieldsProto.isCanEditUsers.booleanValue ?
                <>
                  <SuspendPhoneNumber path="/suspend-phonenumber" />
                  <Users path="/users" />
                  <ViewUser path="/user/:user_id" is_can_edit_map={user._fieldsProto.admin.booleanValue} is_super_admin={user._fieldsProto.admin.booleanValue} />
                  <ViewRatingUser path="/rating-user/:user_id" />
                  <EditMaps path="/edit-maps/:user_id/place/:id" />
                  <EditUserLocation path="edit-location/:userUid/place/:placeUid" mode="edit" />
                  <UsersReport path="/users/report" />
                </>
                : null
            }
            {
              user._fieldsProto.isCanEditProvider
                && user._fieldsProto.isCanEditProvider.booleanValue ?
                <>
                  <ProviderUsers path="/provider-users" admin={user._fieldsProto} />
                  <AddProviderUsers path="/provider-users/add" admin={user._fieldsProto} />
                  <EditProviderUsers path="/provider-users/edit/:provider_id" admin={user._fieldsProto} />
                  <EditProviderUsersServiceArea path="/provider-users/edit-service-area/:provider_id/:province_id" />
                  <ProviderUsersServiceArea path="/provider-users/provider-service-area/:provider_id" />
                  <ViewProviderUsers path="/provider-users/:provider_id" admin={user._fieldsProto} />
                  <ViewRatingProviderUser path="/rating-provider/:provider_id" />
                  <ProviderUsersReport path="/provider/report" />
                  <AdjustReport path="/provider/report/adjust" is_super_admin={user._fieldsProto.admin.booleanValue} />
                </>
                : null
            }
            {
              user._fieldsProto.isCanEditBooking
                && user._fieldsProto.isCanEditBooking.booleanValue ?
                <>
                  <Booking path="/booking" adminUid={user._fieldsProto.id.stringValue} is_super_admin={user._fieldsProto.admin.booleanValue} />
                  <ViewBooking path="/booking/:booking_id" adminUid={user._fieldsProto.id.stringValue} is_super_admin={user._fieldsProto.admin.booleanValue} admin={user._fieldsProto} />
                  <PrintPreview path="/print-preview/:booking_id" adminUid={user._fieldsProto.id.stringValue} is_super_admin={user._fieldsProto.admin.booleanValue} />
                  <BookingReport path="/booking-report" adminUid={user._fieldsProto.id.stringValue} is_super_admin={user._fieldsProto.admin.booleanValue} />
                  <TransectionsReport path="/transections-report" />
                  <AddBooking path={"/add-booking"} adminUid={user._fieldsProto.id.stringValue} />
                  <Invoice path={"/invoice/:booking_id"} />
                  <UserReport path="/booking-report/user" type={'user'} adminUid={user._fieldsProto.id.stringValue} is_super_admin={user._fieldsProto.admin.booleanValue} />
                </>
                : null
            }
            {
              user._fieldsProto.isCanEditAdmin
                && user._fieldsProto.isCanEditAdmin.booleanValue ?
                <>
                  <Agents path="/agents" />
                  <AgentsView path="/agent/:uid" />
                  <AgentSalary path="/agent/salary/:uid" />
                  <BillCycle path="/agent-bill-cycle" />
                  <BillCycleDetail path="/agent-bill-cycle/:id" />
                  <AdminSetting path="/admin-setting" is_super_admin={user._fieldsProto.admin.booleanValue} />
                  <RefferalLink path="/refferal-link" adminUid={user._fieldsProto.id.stringValue} />
                  <PriceSummaryReport path="report/price-summary" />
                </>
                : null
            }
            {
              user._fieldsProto.isCanEditTraningDay
                && user._fieldsProto.isCanEditTraningDay.booleanValue ?
                <>
                  <EducateDate path="/educate-date" admin={user._fieldsProto} />
                  <ViewEducateDate path="/educate-date/:educate_id" admin={user._fieldsProto} />
                  <TrainningDayStatus path="/trainningday-status" admin={user._fieldsProto} />
                </>
                : null
            }
            {
              user._fieldsProto.isCanEditSalary
                && user._fieldsProto.isCanEditSalary.booleanValue ?
                <>
                  <CSVSalary path="/csv-salary" admin={user._fieldsProto} />
                  <CSVSalaryProvider path="/csv-salary-provider/:salary_id" admin={user._fieldsProto} adminUid={user._fieldsProto.id.stringValue} />
                  <Salary path="/salary" admin={user._fieldsProto} />
                  <SalaryProvider path="/salary-provider/:salary_id" admin={user._fieldsProto} adminUid={user._fieldsProto.id.stringValue} />
                  <ProviderSalary path="/provider-salary" />
                </>
                : null
            }
            {
              user._fieldsProto.isCanEditServiceArea
                && user._fieldsProto.isCanEditServiceArea.booleanValue ?
                <>
                  <ServiceArea path="/service-area" />
                  <EditServiceArea path="/service-area/edit/:province_id" />
                  <ViewServiceArea path="/service-area/:province_id" />
                </>
                : null
            }
            {
              user._fieldsProto.isCanEditPromotion
                && user._fieldsProto.isCanEditPromotion.booleanValue ?
                <>
                  <Promotion path="/promotion" />
                  <PromotionReport path="/promotion/report/:promotion_id" />
                  <ViewPromotion path="/promotion/:promotion_id" />
                  <PromotionHistory path="/promotion-history" />
                  <MarketingUsers path="/marketing-users" />
                  <ViewMarketingUser path="/marketing-users/:market_id" />
                  <Voucher path="/voucher" />
                </>
                : null
            }
            {
              user._fieldsProto.isCanEditUsers
                && user._fieldsProto.isCanEditUsers.booleanValue ?
                <ViewSalary path="/salary/:provider_id" admin={user._fieldsProto} adminUid={user._fieldsProto.id.stringValue} />
                : null
            }
            {
              user._fieldsProto.isCanEditApp
                && user._fieldsProto.isCanEditApp.booleanValue ?
                <ManagePromotionSlide path="/manage-promotion-slide" />
                : null
            }
            {
              <>
                <DevSalary path="/salary-dev" admin={user._fieldsProto} />
                <DevSalaryProvider path="/salary-provider-dev/:salary_id" admin={user._fieldsProto} adminUid={user._fieldsProto.id.stringValue} />
                <DevProviderUsers path="/provider-users-dev" admin={user._fieldsProto} />
                <DevViewProviderUsers path="/provider-users-dev/:provider_id" admin={user._fieldsProto} />
                <DevViewSalary path="/salary-dev/:provider_id" admin={user._fieldsProto} adminUid={user._fieldsProto.id.stringValue} />
                <DevViewBooking path="/booking-dev/:booking_id" adminUid={user._fieldsProto.id.stringValue} is_super_admin={user._fieldsProto.admin.booleanValue} admin={user._fieldsProto} />
              </>
            }
            <ReviewCustomer path="/review/customer" />
            <ReportList path="/report" />
            <ReportProvider path="/report/provider" />
            {/* <Mockup path="/mock-up" /> */}
            <LogsReport path="/logs-report" />
            <AdminDetail path="admin-detail/:admin_id" />
          </Router>
        </div>
      </div>
      :
      <Router>
        <SignIn path="/" />
      </Router>
  );
}

export default Application;
