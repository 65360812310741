import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../Helper';
import CustomDateInput from './../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import Pagination from './../Components/Pagination';
import { db } from '../firebase';
const axios = require('axios').default;
export default class EducateDate extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : 'date',
            orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : 'desc',
            is_show_next_button: false,
        }
    }
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : 'date',
                orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : 'desc',
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        let qs = {};
        qs.page = this.state.page;
        qs.limit = this.state.limit;
        if (this.state.name) {
            qs.name = this.state.name
        }
        let res = await axios.get(`${window.api_host}/tranning-date?${window.$.param(qs)}`);
        console.log(res);
        let data = [];
        let count = 0;
        if (res.data.data && res.data.data.length > 0) {
            data = res.data.data
        }
        if (res.data.count_all && res.data.count_all > 0) {
            count = res.data.count_all
        }
        this.setState({
            data: data,
            is_loading: false,
            is_absolute_loading: false,
            all_items: count,
        })


    }



    delete(data) {
        this.setState({
            is_absolute_loading: true
        }, async () => {
            let qs = {}
            qs.id = data.id;
            let res = await axios.delete(`${window.api_host}/tranning-date?${window.$.param(qs)}`);
            // console.log(res)
            if (res.data.code === 200) {
                window.$(document).Toasts('create', {
                    title: 'ลบข้อมูลสำเร็จ',
                    body: 'ทำการลบข้อมูลสำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                this.fetchData();
            }
        })
    }



    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">วันอบรม</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">วันอบรม</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">

                            <div className="card-header">
                                <h3 className="card-title">รายการวันอบรม</h3>
                                <div className="card-tools">
                                    <Link className="btn btn-default" to="/trainningday-status" style={{ marginRight: '1rem' }}>สถานะการอบรม</Link>
                                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#addModal"
                                        onClick={() => {
                                            Helper.reactToDom(window.$('body'),
                                                <EdocateDateForm
                                                    mode={"create"}
                                                    onSuccess={() => {
                                                        this.setState({
                                                            is_absolute_loading: true,
                                                        }, () => {
                                                            this.fetchData();
                                                        })
                                                    }}
                                                />
                                            )
                                        }}
                                    >
                                        เพิ่มวันอบรม
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">

                                {
                                    this.state.is_absolute_loading ?
                                        <div className="absolute_loading">
                                            <div className="ui active inline loader small" ></div>
                                        </div>
                                        : null
                                }
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    let orderByType = this.state.orderByType;
                                                    if (this.state.orderByType === 'desc') {
                                                        orderByType = 'asc'
                                                    }
                                                    if (this.state.orderByType === 'asc') {
                                                        orderByType = 'desc'
                                                    }
                                                    let url = Helper.getQueryStringLinkFromObject({
                                                        orderByType: orderByType,
                                                        page: 1
                                                    })
                                                    navigate(url)
                                                }}
                                            >
                                                <div className={"flex row"}>
                                                    วันที่
                                                <div style={{ marginLeft: 'auto' }}>
                                                        <i className={"fas fa-sort"}></i>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div className={"flex row"}>
                                                    <div>หัวข้อ</div>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        ผู้เข้าอบรบ / จำนวนสูงสุด
                                                    </div>
                                                </div>
                                            </th>
                                            <th style={{ width: 200 }} >ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {Helper.getDateThai(new Date(_data.date), { is_show_time: true })}
                                                                <i className={"fas fa-arrow-right"} style={{ margin: '0 1rem', color: '#ccc', fontSize: 14 }}></i>
                                                                {_data.enddate ? Helper.getTime(new Date(_data.enddate)) : null}
                                                            </td>
                                                            <td>
                                                                <div className={"flex row"}>
                                                                    <div>{_data ? _data.description : '-'}</div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {_data.participants_count} / {_data.maxParticipants}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <Link className={"btn btn-default"}
                                                                        to={"/educate-date/" + _data.id}
                                                                    >
                                                                        ดูข้อมูล
                                                                    </Link>
                                                                    <button
                                                                        className={"btn btn-default"}
                                                                        onClick={() => {
                                                                            Helper.reactToDom(window.$('body'),
                                                                                <EdocateDateForm
                                                                                    onSuccess={() => {
                                                                                        this.setState({
                                                                                            is_absolute_loading: true,
                                                                                        }, () => {
                                                                                            this.fetchData();
                                                                                        })
                                                                                    }}
                                                                                    mode={"edit"}
                                                                                    data={_data}
                                                                                />
                                                                            )
                                                                        }}
                                                                    >
                                                                        แก้ไข
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger"
                                                                        ref={(ref) => {
                                                                            window.$(ref).off().on('click', () => {
                                                                                if (_data.participants_count > 0) {
                                                                                    window.$(document).Toasts('create', {
                                                                                        title: 'ไม่สามารถลบได้',
                                                                                        body: 'กรุณาย้ายแม่บ้านไปวันอาบรมอื่นก่อนทำการลบ',
                                                                                        icon: 'fas fa-ban',
                                                                                        autohide: true,
                                                                                        delay: 3000,
                                                                                        class: "bg-danger",
                                                                                    })
                                                                                    window.$(ref).removeClass('loading');
                                                                                    return;
                                                                                }
                                                                                if (window.$(ref).hasClass('loading')) { return; }
                                                                                window.$(ref).addClass('loading');
                                                                                Helper.confirmModal({
                                                                                    title: "ยืนยันลบรายการอบรม",
                                                                                    description: "ต้องการลบรายการอบรมหรือไม่ ?",
                                                                                    onConfirm: () => {
                                                                                        window.$(ref).removeClass('loading');
                                                                                        this.delete(_data);
                                                                                    },
                                                                                    onCancel: () => {
                                                                                        window.$(ref).removeClass('loading');
                                                                                    }
                                                                                });
                                                                            })
                                                                        }}
                                                                    >ลบ</button>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class EdocateDateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            training_date: props.data && props.data.date ? new Date(props.data.date) : '',
            start_hour: 0,
            start_minute: 0,
            end_hour: 0,
            end_minute: 0,
        }
    }
    update(data) {
        db.doc('training_date/' + this.props.data.id).set({ ...data });
        window.$(document).Toasts('create', {
            title: 'แก้ไขข้อมูลสำเร็จ',
            body: 'ทำการแก้ไขวันอบรมเรียบร้อยแล้ว',
            icon: 'far fa-calendar-check',
            class: 'bg-success',
            autohide: true,
            delay: 3000
        })
        this.$submitBtn.removeClass('loading');
        this.$modal.modal('hide');
        this.props.onSuccess();
    }


    add(data) {
        db.collection('training_date').add(data).then(() => {
            this.setState({
                training_date: null
            }, () => {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มข้อมูลสำเร็จ',
                    body: 'ทำการเพิ่มวันอบรมเรียบร้อยแล้ว',
                    icon: 'far fa-calendar-check',
                    class: 'bg-success',
                    autohide: true,
                    delay: 3000
                })
                this.$submitBtn.removeClass('loading');
                this.$modal.modal('hide');
                this.props.onSuccess();
            })
        })
    }

    setDateTime(date, hour, minute) {
        let _date = new Date(date);
        return new Date(_date.getFullYear(), _date.getMonth(), _date.getDate(), parseFloat(hour), parseFloat(minute), 0)
    }

    render() {
        let hours = []
        for (let index = 0; index < 24; index++) {
            let number = index;
            if (number < 10) {
                number = '0' + number;
            }
            hours.push(number + '');
        }
        let minutes = []
        for (let index = 0; index < 59; index++) {
            let number = index;
            if (number % 5 === 0 && number <= 60) {
                if (number < 10) {
                    number = '0' + number;
                }
                minutes.push(number + '');
            }
        }
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        let _data = {};
                        _data.date = this.setDateTime(this.state.training_date, data.start_hour, data.start_minute).getTime()
                        _data.enddate = this.setDateTime(this.state.training_date, data.end_hour, data.end_minute).getTime()
                        _data.maxParticipants = parseFloat(data.maxParticipants);
                        _data.description = data.description;
                        if (!_data.description || _data.description === '') {
                            window.$(document).Toasts('create', {
                                title: 'เพิ่มข้อมูลไม่สำเร็จ',
                                body: 'กรุณากรอกรายละเอียด',
                                icon: 'far fa-calendar-check',
                                class: 'bg-danger',
                                autohide: true,
                                delay: 3000
                            })
                            this.$submitBtn.removeClass('loading');
                            return;
                        }
                        if (!_data.maxParticipants || _data.maxParticipants <= 0) {
                            window.$(document).Toasts('create', {
                                title: 'เพิ่มข้อมูลไม่สำเร็จ',
                                body: 'กรุณากรอกจำนวนผู้เข้าอบรม',
                                icon: 'far fa-calendar-check',
                                class: 'bg-danger',
                                autohide: true,
                                delay: 3000
                            })
                            this.$submitBtn.removeClass('loading');
                            return;
                        }
                        if (!_data.date) {
                            window.$(document).Toasts('create', {
                                title: 'เพิ่มข้อมูลไม่สำเร็จ',
                                body: 'กรุณากรอกวันที่',
                                icon: 'far fa-calendar-check',
                                class: 'bg-danger',
                                autohide: true,
                                delay: 3000
                            })
                            this.$submitBtn.removeClass('loading');
                            return;
                        }
                        if (this.props.mode === 'create') {
                            this.add(_data);
                        }
                        if (this.props.mode === 'edit') {
                            this.update(_data);
                        }
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                {
                                    this.props.mode === 'create' ?
                                        "เพิ่มวันอบรม" : "แก้ไขวันอบรม"
                                }
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            <div className="form-group">
                                <input name={"description"} className={"form-control"} placeholder={"รายละเอียด"}
                                    defaultValue={this.props.data && this.props.data.description ? this.props.data.description : ''}
                                />
                            </div>
                            <div className="form-group">
                                <DatePicker
                                    onChange={date => {
                                        this.setState({
                                            training_date: date
                                        })
                                    }}
                                    onCalendarOpen={() => { Helper.renderDate() }}
                                    locale="th-TH"
                                    selected={this.state.training_date}
                                    placeholderText={"วัน / เดือน / ปี"}
                                    name={"birthDay"}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    withPortal
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled
                                    }) => {
                                        let now = new Date();
                                        let selected_date = new Date(date);
                                        // months_th
                                        const years = [];
                                        let i;
                                        for (i = 1980; i <= now.getFullYear(); i++) {
                                            years.push(i)
                                        }
                                        return (
                                            <div
                                                style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                    className={"btn btn-primary "}
                                                    type={"button"}
                                                    style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                >
                                                    <i className="fas fa-chevron-left" />
                                                </button>
                                                <select
                                                    className={"form-control"}
                                                    value={selected_date.getFullYear()}
                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                    style={{ borderRadius: 0 }}
                                                >
                                                    {years.map(option => (
                                                        <option key={option} value={option} >
                                                            {option + 543}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    className={"form-control"}
                                                    value={window.months_th[selected_date.getMonth()]}
                                                    onChange={({ target: { value } }) =>
                                                        changeMonth(window.months_th.indexOf(value))
                                                    }
                                                    style={{ borderRadius: 0 }}
                                                >
                                                    {window.months_th.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                    className={"btn btn-primary"}
                                                    style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                    type={"button"}
                                                >
                                                    <i className="fas fa-chevron-right" />
                                                </button>
                                            </div>
                                        )
                                    }}
                                    customInput={<CustomDateInput />}
                                />
                            </div>
                            <div className={"form-group row"}>
                                <div className={"col-sm-6"}>
                                    <label>เวลาเริ่มอบรม</label>
                                    <select className={"form-control"} name="start_hour"
                                        defaultValue={this.props.data && this.props.data.date ? new Date(this.props.data.date).getHours() : 0}
                                    >
                                        {
                                            hours.map((hour, hour_i) => (
                                                <option value={hour} key={hour_i}>{hour}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className={"col-sm-6"}>
                                    <label>&nbsp; </label>
                                    <select className={"form-control"} name="start_minute"
                                        defaultValue={this.props.data && this.props.data.date ? new Date(this.props.data.date).getMinutes() : 0}
                                    >
                                        {
                                            minutes.map((minute, minute_i) => (
                                                <option value={minute} key={minute_i}>{minute}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className={"form-group row"}>
                                <div className={"col-sm-6"}>
                                    <label>ถึง</label>
                                    <select className={"form-control"} name="end_hour"
                                        defaultValue={this.props.data && this.props.data.enddate ? new Date(this.props.data.enddate).getHours() : 0}
                                    >
                                        {
                                            hours.map((hour, hour_i) => (
                                                <option value={hour} key={hour_i}>{hour}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className={"col-sm-6"}>
                                    <label>&nbsp; </label>
                                    <select className={"form-control"} name="end_minute"
                                        defaultValue={this.props.data && this.props.data.enddate ? new Date(this.props.data.enddate).getMinutes() : 0}
                                    >
                                        {
                                            minutes.map((minute, minute_i) => (
                                                <option value={minute} key={minute_i}>{minute}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <input type={"number"} name={"maxParticipants"} className={"form-control"} placeholder={"จำนวนผู้อบรมสูงสุด"}
                                        defaultValue={this.props.data && this.props.data.maxParticipants ? this.props.data.maxParticipants : ''}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">คน</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                            <button type="submit" className="btn btn-primary"
                                ref={(ref) => { this.$submitBtn = window.$(ref) }}
                            >
                                {
                                    this.props.mode === "create" ?
                                        "เพิ่ม" : "แก้ไข"
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
