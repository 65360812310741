import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import { db } from '../firebase';
import thailand from "./../thailand.json";
const axios = require('axios').default;

export default class ViewServiceArea extends React.Component {
    constructor(props) {
        super(props);
        let amphoes = [];
        for (const [key, value] of Object.entries(thailand)) {
            if (key === props.province_id)
                for (const [_key, _value] of Object.entries(value.amphoes)) {
                    amphoes.push({ code: _key, amphoe: _value.name.th });
                    // console.log(`${_key}: ${_value.name.th}`);
                }
        }
        this.state = {
            is_loading: true,
            province: false,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            userUID: Helper.getParameterByName('userUID') ? Helper.getParameterByName('userUID') : '',
            amphoes: [],
            all_amphoes: amphoes,
            selected_amphoes: [],
        }
    }

    componentDidMount() {
        this.fetchData();
        // console.log(this.state.provinces);
    }

    async fetchData() {
        let provinceRef = await db.doc('/service_area/' + this.props.province_id).get();
        let province_data = await provinceRef.data();
        let amphoeRef = await db.collection('/service_area/' + this.props.province_id + '/amphoes').get();
        let amphoe_data = [];
        let selected_amphoes = [];
        if (amphoeRef.docs.length > 0) {
            amphoeRef.docs.map((amp) => {
                amphoe_data.push({ ...amp.data() })
                selected_amphoes.push(amp.id)
                return true;
            })
        }
        this.setState({
            province: province_data,
            amphoes: amphoe_data,
            selected_amphoes: selected_amphoes,
            is_loading: false
        })
    }

    async updateServiceArea() {
        let data = {};
        data.provincesCode = [this.props.province_id];
        data.amphoesCode = [];
        this.state.selected_amphoes.map((code) => {
            data.amphoesCode.push({
                provinceCode: this.props.province_id,
                amphoeCode: code,
            })
            return true;
        })
        data.note = "";
        if (this.$note) {
            data.note = this.$note.val();
        }
        try {
            await axios.post(window.api_host + '/service-area', { ...data }).then(async (res) => {
                let res_data = res.data;
                if (res_data.code === 200) {
                    window.$(document).Toasts('create', {
                        title: 'เพิ่มพื้นที่บริการสำเร็จ',
                        body: 'ระบบได้ทำการเพิ่มจังหวัดเรียบร้อยแล้ว กรุณาเลือก เขต/อำเภอ ที่จะเปิดทำการให้บริการ',
                        icon: 'fas fa-check',
                        autohide: true,
                        delay: 3000,
                        class: "bg-success",
                    })
                    this.fetchData();
                    this.$submit.removeClass("loading");
                }
            });
        } catch (error) {
            this.$submit.removeClass("loading");
            return;
        }
    }

    async delete() {
        let data = {};
        data.provincesCode = [this.props.province_id];
        try {
            await axios.delete(window.api_host + '/service-area', { data: { provincesCode: data.provincesCode } }).then(async (res) => {
                let res_data = res.data;
                if (res_data.status === "success") {
                    window.$(document).Toasts('create', {
                        title: 'ยกเลิกพื้นที่บริการสำเร็จ',
                        body: 'ระบบได้ทำการยกเลิกพื้นที่บริการเรียบร้อยแล้ว',
                        icon: 'fas fa-check',
                        autohide: true,
                        delay: 3000,
                        class: "bg-success",
                    })
                    navigate('/service-area');
                    // this.fetchData();
                    // this.$delete.removeClass("loading");
                }
            });
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'ยกเลิกพื้นที่บริการไม่สำเร็จ',
                body: 'กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.setState({
                is_loading: false
            }, () => {
                this.$delete.removeClass("loading");
            })

            return;
        }
    }

    render() {
        // console.log(this.state);
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการพื้นที่ให้บริการ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/service-area/"}>จัดการพื้นที่ให้บริการ</Link></li>
                                    <li className="breadcrumb-item active">ดูข้อมูล {this.state.province ? this.state.province.name.th : null}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-lg-4"}>
                                {
                                    this.state.province ?
                                        <div className="card">
                                            <div className="card-header">
                                                <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                    <h3 className="card-title">พื้นที่ให้บริการ : {this.state.province.name.th}</h3>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        {/* <button className={'btn btn-danger'}
                                                            style={{ marginRight: '0.5rem' }}
                                                            ref={(ref) => this.$delete = window.$(ref)}
                                                            onClick={() => {
                                                                if (this.$delete.hasClass('loading')) { return; }
                                                                this.$delete.addClass('loading');
                                                                Helper.confirmModal({
                                                                    title: "ยืนยันยกเลิกพื้นที่ให้บริการ",
                                                                    description: "ต้องการยกเลิกพื้นที่ให้บริการนี้หรือไม่ ?",
                                                                    onConfirm: () => {
                                                                        this.$delete.removeClass('loading');
                                                                        this.setState({
                                                                            is_loading: true
                                                                        }, () => {
                                                                            this.delete();
                                                                        })
                                                                    },
                                                                    onCancel: () => {
                                                                        this.$delete.removeClass('loading');
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            ยกเลิกการให้บริการ
                                                            </button> */}
                                                        <Link to={'/service-area/edit/' + this.props.province_id} className={"btn btn-default"}>แก้ไข</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                {
                                                    this.state.is_loading ?
                                                        <div className={"absolute_loading"}>
                                                            <div className="ui active inline loader small" ></div>
                                                        </div>
                                                        : null
                                                }
                                                <div>
                                                    <label>สถานะ </label> : {' '}
                                                    <i className={"fas fa-circle"} style={{ color: this.state.province.isActive ? 'green' : 'red' }} /> {this.state.province.isActive ? "เปิดใช้งาน" : "ระงับการใช้งาน"}
                                                </div>
                                                <div>
                                                    <label>สมัครแม่บ้าน </label> : {' '}
                                                    <i className={"fas fa-circle"} style={{ color: this.state.province.isRegisterActive ? 'green' : 'red' }} /> {this.state.province.isRegisterActive ? "เปิดรับสมัคร" : "ปิดรับสมัคร"}
                                                </div>
                                                <div>
                                                    <label>เขต/อำเภอ </label> : {' '}
                                                    {this.state.all_amphoes.length}
                                                </div>
                                                <div>
                                                    <label>จำนวนแม่บ้านที่รับบริการ </label> : {' '}

                                                </div>
                                                <div>
                                                    <label>หมายเหตุ </label> : {' '}
                                                    {this.state.province.note || '-'}
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className={"col-lg-8"}>
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            this.state.is_loading ?
                                                <div className={"absolute_loading"}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        <div className={'row pointer-label'}>
                                            {
                                                this.state.all_amphoes.map((amphoe, amphoe_i) => {
                                                    let selected = false;
                                                    this.state.selected_amphoes.map((selected_amphoe, selected_amphoe_i) => {
                                                        if (parseFloat(selected_amphoe) === parseFloat(amphoe.code)) {
                                                            selected = true
                                                        }
                                                        return true;
                                                    })
                                                    return (
                                                        <div className={"col-lg-3"} key={amphoe_i}>
                                                            <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                                <input name={"amphoe"}
                                                                    type="checkbox"
                                                                    value={"select-all"}
                                                                    id={amphoe.amphoe}
                                                                    checked={selected}
                                                                    onChange={() => {
                                                                        return;
                                                                    }}
                                                                    style={{ marginRight: 5 }}
                                                                />
                                                                <label style={{ marginBottom: 0 }} htmlFor={amphoe.amphoe}>{amphoe.amphoe}</label>
                                                            </div>

                                                        </div>
                                                    )

                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
