import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import { db, storage } from '../firebase';
import Pagination from '../Components/Pagination';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
// const axios = require('axios').default;

export default class Salary extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            export_data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            userUID: Helper.getParameterByName('userUID') ? Helper.getParameterByName('userUID') : '',
            orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : '',
            orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : '',
            is_show_next_button: false,
            users_count: 0,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                userUID: Helper.getParameterByName('userUID') ? Helper.getParameterByName('userUID') : '',
                orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : '',
                orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : '',
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        let get_data = [];
        let data = [];
        let prepare_data = [];
        let export_data = [];
        let prepare_export_data = [];
        let count = 0;
        let provider_all = 0;
        let provider_wait_for_approve = 0;
        let provider_approved = 0;
        let provider_avaliable = 0;
        let provider_ban = 0;
        let prepareProviderData = [];


        let providerUsersRef = await db.collection('provider_users').where('wallet', '>', 0).get();

        let providerUsersDateRefs = [];
        for (const doc of providerUsersRef.docs) {
            // console.log(doc.data(), doc.id)
            let return_data = {}
            return_data.provider_user = doc.data();
            return_data.provider_user.id = doc.id;
            return_data.provider_user.wallet = doc.data().wallet;
            let provider_data_ref = db.collection('provider_users_data').where('phoneNumber', '==', doc.data().phoneNumber).get();
            providerUsersDateRefs.push(provider_data_ref);
            get_data.push(return_data)
        };
        let getProviderUsersDateRefs = await Promise.all(providerUsersDateRefs);
        let i = 0;
        for (const doc of getProviderUsersDateRefs) {
            if (!doc.empty) {
                let pdata = doc.docs[0];
                get_data[i] = {
                    ...pdata.data(),
                    ...get_data[i],
                    id: pdata.id
                };
            }
            i++;
        }

        get_data = get_data.filter((_data) => {
            return _data !== false
        })
        get_data = get_data.filter((_data) => {
            return _data.phoneNumber
        })
        // export_data = get_data.sort((a, b) => { return parseFloat(b.provider_user.wallet) - parseFloat(a.provider_user.wallet) })
        // export_data = get_data.filter((_exp_data) => {
        //     return _exp_data.provider_user.wallet > 0
        // });
        export_data = get_data;
        prepare_export_data = export_data.map((_export_data) => {
            let return_export_data = {};
            return_export_data.displayName = _export_data.prefix + ' ' + _export_data.firstName + ' ' + _export_data.lastName;
            return_export_data.phoneNumber = _export_data.phoneNumber;
            return_export_data.wallet = _export_data.provider_user.wallet;
            return_export_data.bank = _export_data.bank;
            return_export_data.bankBranch = _export_data.bankBranch;
            return_export_data.bankAccountName = _export_data.bankAccountName;
            return_export_data.bankAccountNo = _export_data.bankAccountNo;
            return return_export_data;
        })

        if (this.state.orderBy === 'wallet' && this.state.orderByType === 'asc') {
            // get_data = get_data.sort(function (a, b) { return a.createdAt - b.createdAt })
            get_data = get_data.sort((a, b) => { return parseFloat(b.provider_user.wallet) - parseFloat(a.provider_user.wallet) })
        }
        if (this.state.orderBy === 'wallet' && this.state.orderByType === 'desc') {
            // get_data = get_data.sort(function (a, b) { return a.createdAt - b.createdAt })
            get_data = get_data.sort((a, b) => { return parseFloat(a.provider_user.wallet) - parseFloat(b.provider_user.wallet) })
        }

        get_data.forEach((_data, _data_i) => {
            let match_search = 0;
            if (this.state.name && this.state.name !== "" && !this.state.userUID) {
                match_search = _data.firstName.toLowerCase().indexOf(this.state.name.toLowerCase());
                if (match_search !== -1) {
                    count++;
                    prepare_data.push({ ..._data });
                }
            } else {
                count++;
                prepare_data.push({ ..._data });
            }
        })

        data = prepare_data.filter((__data, __data_i) => {
            return __data_i < (this.state.limit * this.state.page)
                && __data_i > (((this.state.limit * this.state.page) - this.state.limit) - 1)
        })
        let data_filter = data;
        // console.log(data_filter);
        this.setState({
            data: data_filter,
            export_data: prepare_export_data,
            is_loading: false,
            all_items: count,
            provider_all: provider_all,
            provider_wait_for_approve: provider_wait_for_approve,
            provider_approved: provider_approved,
            provider_avaliable: provider_avaliable,
            provider_ban: provider_ban,
        })
    }

    delete(data) {
        if (data.transferImageUrl) {
            storage.ref(data.transferImageUrl).delete();
        }
        let documentRef = db.doc(`users/` + data.id);
        documentRef.delete().then(() => {
            this.fetchData();
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
            })
        });
    }

    setOrderBy(orderBy) {
        let orderByType = 'asc';
        if (this.state.orderByType && this.state.orderByType === 'asc') {
            orderByType = 'desc';
        }
        this.setState({
            orderBy: orderBy,
            orderByType: orderByType
        }, () => {
            this.$searchForm.find('[type="submit"]').trigger('click');
        })
    }

    render() {
        // console.log(this.state.export_data);
        return (
            <>
                <ExcelExport
                    data={this.state.export_data}
                    ref={exporter => this._export = exporter}
                    fileName={"รายงานรายได้แม่บ้าน (" + Helper.getDateThai(new Date().getTime()) + ")"}
                >
                    <ExcelExportColumn field="displayName" title="ชื่อ-นามสกุล" width={200} />
                    <ExcelExportColumn field="phoneNumber" title="ข้อมูลติดต่อ" width={130} />
                    <ExcelExportColumn field="wallet" title="เงินกระเป๋าปุจจุบัน" width={150} />
                    <ExcelExportColumn field="bank" title="ธนาคาร" width={130} />
                    <ExcelExportColumn field="bankBranch" title="สาขา" width={130} />
                    <ExcelExportColumn field="bankAccountName" title="ชื่อบัญชี" width={150} />
                    <ExcelExportColumn field="bankAccountNo" title="เลขบัญชี" width={150} />
                </ExcelExport>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายได้แม่บ้าน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/salary"}>จัดการรายได้แม่บ้าน</Link></li>
                                    <li className="breadcrumb-item active">รายได้แม่บ้าน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายการแม่บ้าน</h3>
                                <div className={"card-right"}>
                                    <div style={{ marginRight: 10 }}>ทั้งหมด {this.state.users_count} คน</div>
                                </div>
                                <div className="card-tools">
                                    <form className="input-group input-group-sm" ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let url = Helper.getQueryStringLinkFromObject({
                                                page: 1,
                                                name: data.name,
                                                orderBy: data.orderBy,
                                                orderByType: data.orderByType,
                                            }, '/provider-salary')
                                            navigate(url)
                                        }}
                                    >
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากชื่อ" defaultValue={this.state.name} />
                                        {
                                            this.state.orderBy ?
                                                <>
                                                    <input name="orderBy" type="hidden" value={this.state.orderBy} />
                                                    <input name="orderByType" type="hidden" value={this.state.orderByType} />
                                                </>
                                                : null
                                        }
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <button type="button"
                                        style={{ marginRight: 10 }}
                                        className={this.state.is_loading ? "btn btn-default loading" : "btn btn-default"}
                                        onClick={() => {
                                            if (this.state.data.length <= 0) { return false }
                                            this._export.save(this.state.export_data);
                                        }}
                                        disabled={this.state.data.length <= 0}
                                    >
                                        <i className="far fa-file-excel"></i> ดาวน์โหลดรายงาน
                                    </button>
                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>แม่บ้าน</th>
                                            <th>ข้อมูลการติดต่อ</th>
                                            <th onClick={() => this.setOrderBy('wallet')}
                                                style={{ cursor: 'pointer' }}>
                                                <div className={"flex row"}>
                                                    เงินกระเป๋าปัจจุบัน
                                                <div style={{ marginLeft: 'auto' }}>
                                                        {
                                                            this.state.orderBy === 'wallet' ?
                                                                this.state.orderByType === 'asc' ?
                                                                    <i className="fas fa-sort-amount-down"></i>
                                                                    :
                                                                    <i className="fas fa-sort-amount-down-alt"></i>
                                                                : <i className="fas fa-arrows-alt-v"></i>
                                                        }
                                                    </div>
                                                </div>
                                            </th>
                                            <th>บัญชีธนาคาร</th>
                                            <th style={{ width: 180 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {_data.gender === "ชาย" ? <i className="fas fa-mars"></i> : null}
                                                                {_data.gender === "หญิง" ? <i className="fas fa-venus"></i> : null}
                                                                {' '}
                                                                {_data.prefix} {_data.firstName} {_data.lastName}<br />
                                                                <i className="fas fa-star" style={{ color: '#ffa200' }}></i> {_data.provider_user && _data.provider_user.starFloat ? _data.provider_user.starFloat.toFixed(2) : '5.00'}
                                                            </td>
                                                            <td>
                                                                {_data.phoneNumber}
                                                            </td>
                                                            <td>
                                                                ฿ {Helper.numberFormat(_data.provider_user && _data.provider_user.wallet ? _data.provider_user.wallet : 0)}
                                                            </td>
                                                            <td>
                                                                {_data.bank ?
                                                                    <>
                                                                        <div>{_data.bank + ' ' + _data.bankBranch}</div>
                                                                        <div>{_data.bankAccountName + ' ' + _data.bankAccountNo}</div>
                                                                    </>
                                                                    : '-'
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="flex row">
                                                                    <Link className="btn btn-default" to={"/salary/" + _data.id} >ดูข้อมูล</Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
