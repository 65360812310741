import httpClient, { serverUrl } from './HttpClient';
const _serverUrl = serverUrl;

const baseUrl = `${_serverUrl}/agent-bank`;

export const agentBankService = {
    get: async () => {
        const url = `${baseUrl}`;
        try {
            const res = await httpClient.get(url)
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    //create and update use same api
    update: async ({ uid, bank, bankBranch, bankAccountName, bankAccountNo }) => {
        const url = `${baseUrl}`;
        try {
            const res = await httpClient.post(url, { agentUid: uid, bank, bankBranch, bankAccountName, bankAccountNo })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
}