import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import { db } from '../firebase';
// import { AmphurList } from './ProviderUsers'
// import Pagination from '../Components/Pagination';
import thailand from "./../thailand.json";
const axios = require('axios').default;

export default class ServiceArea extends React.Component {
    constructor(props) {
        super(props);
        let amphoes = [];
        for (const [key, value] of Object.entries(thailand)) {
            if (key === props.province_id)
                for (const [_key, _value] of Object.entries(value.amphoes)) {
                    amphoes.push({ code: _key, amphoe: _value.name.th });
                    // console.log(`${_key}: ${_value.name.th}`);
                }
        }
        this.state = {
            is_loading: true,
            province: false,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            userUID: Helper.getParameterByName('userUID') ? Helper.getParameterByName('userUID') : '',
            amphoes: [],
            all_amphoes: amphoes,
            selected_amphoes: [],
        }
    }

    componentDidMount() {
        this.fetchData();
        // console.log(this.state.provinces);
    }

    async fetchData() {
        let provinceRef = await db.doc('/service_area/' + this.props.province_id).get();
        let province_data = await provinceRef.data();
        let amphoeRef = await db.collection('/service_area/' + this.props.province_id + '/amphoes').get();
        let amphoe_data = [];
        let selected_amphoes = [];
        if (amphoeRef.docs.length > 0) {
            amphoeRef.docs.map((amp) => {
                amphoe_data.push({ ...amp.data() })
                selected_amphoes.push(amp.id);
                return true;
            })
        }
        if (province_data && !province_data.isActive) {
            province_data.isActive = false;
        }
        let isActive = province_data.isActive;
        let isRegisterActive = province_data.isRegisterActive;
        this.setState({
            isActive: isActive,
            isRegisterActive: isRegisterActive,
            province: province_data,
            amphoes: amphoe_data,
            selected_amphoes: selected_amphoes,
            is_loading: false
        })
    }

    async updateServiceArea() {
        let data = {};
        data.provincesCode = [this.props.province_id];
        data.amphoesCode = [];
        this.state.selected_amphoes.map((code) => {
            data.amphoesCode.push({
                provinceCode: this.props.province_id,
                amphoeCode: code,
            })
            return true;
        })

        let _data = {};
        _data.note = "";
        _data.code = this.props.province_id
        if (this.$note) {
            _data.note = this.$note.val();
        }
        if (this.$status) {
            _data.isActive = this.$status.val() == `true`
        }
        if (this.$registerStatus) {
            _data.isRegisterActive = this.$registerStatus.val() == `true`;
        }
        if (!_data.isActive && !_data.isRegisterActive) {
            data.amphoesCode = []
        }
        // return;
        try {
            await axios.post(window.api_host + '/service-area-edit-province', { ..._data }).then(async () => {
                if (!_data.isActive && !_data.isRegisterActive) {
                    await axios.delete(window.api_host + '/service-area', { data: { ...data } }).then(async (res) => {
                        let res_data = res.data;
                        if (res_data.code === 200) {
                            window.$(document).Toasts('create', {
                                title: 'เพิ่มพื้นที่บริการสำเร็จ',
                                body: 'ระบบได้ทำการเพิ่มจังหวัดเรียบร้อยแล้ว กรุณาเลือก เขต/อำเภอ ที่จะเปิดทำการให้บริการ',
                                icon: 'fas fa-check',
                                autohide: true,
                                delay: 3000,
                                class: "bg-success",
                            })
                            this.$submit.removeClass("loading");
                            navigate('/service-area/' + this.props.province_id)
                        }
                    })
                }
                if (_data.isActive || _data.isRegisterActive) {
                    await axios.post(window.api_host + '/service-area', { ...data }).then(async (res) => {
                        let res_data = res.data;
                        if (res_data.code === 200) {
                            window.$(document).Toasts('create', {
                                title: 'เพิ่มพื้นที่บริการสำเร็จ',
                                body: 'ระบบได้ทำการเพิ่มจังหวัดเรียบร้อยแล้ว กรุณาเลือก เขต/อำเภอ ที่จะเปิดทำการให้บริการ',
                                icon: 'fas fa-check',
                                autohide: true,
                                delay: 3000,
                                class: "bg-success",
                            })
                            this.$submit.removeClass("loading");
                            navigate('/service-area/' + this.props.province_id)
                        }
                    })
                }

            });
        } catch (error) {
            this.setState({ is_loading: false }, () => { this.$submit.removeClass("loading"); })
            return;
        }
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการพื้นที่ให้บริการ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/service-area/"}>จัดการพื้นที่ให้บริการ</Link></li>
                                    {
                                        this.state.province ?
                                            <li className="breadcrumb-item"><Link to={"/service-area/" + this.props.province_id}>{this.state.province.name.th}</Link></li>
                                            : null
                                    }
                                    <li className="breadcrumb-item active">แก้ไข</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className={"col-lg-4"} style={{ zIndex: 1 }}>
                                {
                                    this.state.province ?
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">พื้นที่ให้บริการ : {this.state.province.name.th}</h3>
                                            </div>
                                            <div className="card-body" style={{ position: 'relative' }}>
                                                {
                                                    this.state.is_loading ?
                                                        <div className={"absolute_loading"}>
                                                            <div className="ui active inline loader small" ></div>
                                                        </div>
                                                        : null
                                                }
                                                <div className={"flex row"} style={{ marginBottom: '0.5rem' }}>
                                                    <div><label>สถานะ </label> : {' '}
                                                        {
                                                            this.state.isActive ?
                                                                <><i className={"fas fa-circle"} style={{ color: "green" }}></i>  เปิดใช้งาน</>
                                                                :
                                                                <><i className={"fas fa-circle"} style={{ color: "red" }}></i>  ระงับการใช้งาน</>
                                                        }
                                                    </div>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <div className="dropdown">
                                                            <button className="btn btn-default btn-xs dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="far fa-edit"></i>
                                                            </button>
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <div className="dropdown-item" style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            isActive: true,
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className={"fas fa-circle"} style={{ color: "green" }}></i>  เปิดใช้งาน
                                                                </div>
                                                                <div className="dropdown-item" style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            isActive: false,
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className={"fas fa-circle"} style={{ color: "red" }}></i>  ระงับการใช้งาน
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input type="hidden" name={"dropdown"} ref={(ref) => { this.$status = window.$(ref) }} value={this.state.isActive} />
                                                </div>
                                                <div className={"flex row"} style={{ marginBottom: '0.5rem' }}>
                                                    <div><label>เปิดรับสมัคร </label> : {' '}
                                                        {
                                                            this.state.isRegisterActive ?
                                                                <><i className={"fas fa-circle"} style={{ color: "green" }}></i>  เปิดใช้งาน</>
                                                                :
                                                                <><i className={"fas fa-circle"} style={{ color: "red" }}></i>  ระงับการใช้งาน</>
                                                        }
                                                    </div>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <div className="dropdown">
                                                            <button className="btn btn-default btn-xs dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="far fa-edit"></i>
                                                            </button>
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <div className="dropdown-item" style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            isRegisterActive: true,
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className={"fas fa-circle"} style={{ color: "green" }}></i>  เปิดรับสมัคร
                                                                </div>
                                                                <div className="dropdown-item" style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            isRegisterActive: false,
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className={"fas fa-circle"} style={{ color: "red" }}></i>  ปิดรับสมัคร
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input type="hidden" name={"registerStatus"} ref={(ref) => { this.$registerStatus = window.$(ref) }} value={this.state.isRegisterActive} />
                                                </div>
                                                <div>
                                                    <label>เขต/อำเภอ </label> : {' '}
                                                    {this.state.all_amphoes.length}
                                                </div>
                                                <div>
                                                    <label>จำนวนแม่บ้านที่รับบริการ </label> : {' '}

                                                </div>
                                                <div>
                                                    <label>หมายเหตุ </label> : {' '}
                                                    <textarea className={'form-control'} ref={(ref) => { this.$note = window.$(ref) }} defaultValue={this.state.province.note}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className={"col-lg-8"}>
                                <div className="card">
                                    <div className="card-header">
                                        <div className={"flex row"}>
                                            <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                <input name={"select-all"}
                                                    type="checkbox"
                                                    value={"select-all"}
                                                    id={"select-all"}
                                                    style={{ marginRight: 5 }}
                                                    checked={this.state.selected_amphoes.length === this.state.all_amphoes.length}
                                                    onChange={() => {
                                                        if (this.state.is_loading) { return; }
                                                        let selected_amphoes = [];
                                                        if (this.state.selected_amphoes.length !== this.state.all_amphoes.length) {
                                                            this.state.all_amphoes.map((amp) => {
                                                                selected_amphoes.push(amp.code);
                                                                return true;
                                                            })
                                                        }
                                                        this.setState({
                                                            selected_amphoes: selected_amphoes,
                                                        })
                                                    }}
                                                />
                                                <label style={{ marginBottom: 0 }} htmlFor={"select-all"}>เลือกทั้งหมด</label>
                                            </div>
                                            <div style={{ marginLeft: 'auto' }}>
                                                <Link to={'/service-area/' + this.props.province_id}
                                                    className={"btn btn-default"}
                                                    style={{ marginRight: '0.5rem' }}>ยกเลิก</Link>
                                                <button className={"btn btn-primary"}
                                                    ref={(ref) => { this.$submit = window.$(ref) }}
                                                    type={'button'}
                                                    onClick={() => {
                                                        if (this.$submit.hasClass('loading')) { return; };
                                                        this.$submit.addClass("loading");
                                                        this.setState({
                                                            is_loading: true
                                                        }, () => {
                                                            this.updateServiceArea()
                                                        })
                                                    }}
                                                >บันทึก</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ position: 'relative' }}>

                                        {
                                            this.state.is_loading ?
                                                <div className={"absolute_loading"}>
                                                    <div className="ui active inline loader small" ></div>
                                                </div>
                                                : null
                                        }
                                        <div className={'row pointer-label'}>
                                            {
                                                this.state.all_amphoes.map((amphoe, amphoe_i) => {
                                                    let selected = false;
                                                    this.state.selected_amphoes.map((selected_amphoe, selected_amphoe_i) => {
                                                        if (parseFloat(selected_amphoe) === parseFloat(amphoe.code)) {
                                                            selected = true
                                                        }
                                                        return true;
                                                    })
                                                    return (
                                                        <div className={"col-lg-3"} key={amphoe_i}>
                                                            <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                                <input name={"amphoe"}
                                                                    type="checkbox"
                                                                    value={"select-all"}
                                                                    id={amphoe.amphoe}
                                                                    checked={selected}
                                                                    style={{ marginRight: 5 }}
                                                                    onChange={() => {
                                                                        let selected_amphoes = [...this.state.selected_amphoes];
                                                                        let count_selected = this.state.selected_amphoes.filter((selected) => { return selected === amphoe.code }).length;
                                                                        if (count_selected > 0) {
                                                                            selected_amphoes = this.state.selected_amphoes.filter((selected) => { return selected !== amphoe.code });
                                                                        }
                                                                        if (count_selected === 0) {
                                                                            selected_amphoes.push(amphoe.code)
                                                                        }
                                                                        this.setState({
                                                                            selected_amphoes: selected_amphoes,
                                                                        })
                                                                    }}
                                                                />
                                                                <label style={{ marginBottom: 0 }} htmlFor={amphoe.amphoe}>{amphoe.amphoe}</label>
                                                            </div>

                                                        </div>
                                                    )

                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
