import React from 'react';
import { Link } from '@reach/router';
import { db } from './../firebase';
import { Helper } from './../Helper';
const axios = require('axios').default;

export default class AdminDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: false,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        db.doc("admin/" + this.props.admin_id).get().then((doc) => {
            let data = doc.data();
            this.setState({
                data: data
            })
        })
    }


    render() {
        console.log(this.state.data)
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ข้อมูลผู้ใช้งาน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ข้อมูลผู้ใช้งาน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className="col-sm-12 col-lg-3">
                                <div className="card">
                                    <div className="card-header flex row" >
                                        <h3 className="card-title">{this.state.data ? this.state.data.displayName : null}</h3>
                                        {
                                            this.state.data ?
                                                <div style={{ marginLeft: 'auto' }}>
                                                    <div className={'link'}
                                                        onClick={() => {
                                                            Helper.reactToDom(
                                                                window.$('body'),
                                                                <ChangePassword
                                                                    uid={this.props.admin_id}
                                                                    data={this.state.data}
                                                                    onDone={() => {
                                                                        this.fetchData();
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    >เปลี่ยนรหัสผ่าน</div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <div className={"card-body"}>
                                        <div>
                                            <div>
                                                {
                                                    this.state.data.isCanEditAdmin ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditAdmin">จัดการผู้ดูแล</label>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                {
                                                    this.state.data.isCanEditUsers ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditUsers">จัดการสมาชิก</label>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                {
                                                    this.state.data.isCanEditProvider ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditProvider">จัดการแม่บ้าน</label>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                {
                                                    this.state.data.isCanEditBooking ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditBooking">จัดการ Booking</label>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                {
                                                    this.state.data.isCanEditTraningDay ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditTraningDay">จัดการ วันอบรม</label>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                {
                                                    this.state.data.isCanEditApp ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditApp">จัดการข้อมูล App</label>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                {
                                                    this.state.data.isCanEditServiceArea ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditApp"> จัดการพื้นที่ให้บริการ</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.state.data?.admin ?
                                        <>
                                        <Link className='btn btn-default' to={`/refferal-link`}>Refferal Link</Link>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

class ChangePassword extends React.Component {
    async update(data) {

        let _data = { password: data.new_password };
        console.log(this.props);
        console.log(data);
        console.log(_data);
        try {
            let _update = await axios.post('https://asia-southeast2-beeclean-app-66b97.cloudfunctions.net/app/update-user', {
                userUID: this.props.uid,
                password: _data.password
            });
            if (_update.data.code === 200) {
                window.$(document).Toasts('create', {
                    title: 'เปลี่ยนรหัสผ่านสำเร็จ',
                    body: 'ปลี่ยนรหัสผ่านเรียบร้อยแล้ว',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-success",
                    delay: 3000,
                })
                this.$modal.modal('hide');
                let settime = window.setTimeout(() => {
                    this.$modal.remove();
                    clearTimeout(settime);
                }, 500);
                this.props.onDone();
            } else {
                window.$(document).Toasts('create', {
                    title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
                    body: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
                    icon: 'fas fa-check',
                    autohide: true,
                    class: "bg-danger",
                    delay: 3000,
                })
                this.$form = this.$modal.find('form');
                let $submit = this.$form.find('button[type="submit"]');
                $submit.removeClass('loading');
            }
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
                body: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                class: "bg-danger",
                delay: 3000,
            })
            this.$form = this.$modal.find('form');
            let $submit = this.$form.find('button[type="submit"]');
            $submit.removeClass('loading');
        }
    }

    render() {
        return (
            <>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog" role="document"
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.$form = this.$modal.find('form');
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            if (!data.password || !data.new_password || !data.confirm_new_password) {
                                window.$(document).Toasts('create', {
                                    title: 'กรอกรหัสผ่านไม่ครบ',
                                    body: 'กรุณากรอกรหัสผ่านให้ครบถ้วน',
                                    icon: 'fas fa-times',
                                    autohide: true,
                                    class: "bg-danger",
                                    delay: 3000,
                                });
                                $submit.removeClass('loading');
                                return false;
                            }
                            if (this.props.data.password !== data.password) {
                                window.$(document).Toasts('create', {
                                    title: 'รหัสผ่านเก่า',
                                    body: 'รหัสผ่านเก่าไม่ถูกต้อง',
                                    icon: 'fas fa-times',
                                    autohide: true,
                                    class: "bg-danger",
                                    delay: 3000,
                                });
                                $submit.removeClass('loading');
                                return false;
                            }
                            if (data.new_password !== data.confirm_new_password) {
                                window.$(document).Toasts('create', {
                                    title: 'ยืนยันรหัสผ่านไม่ถูกต้อง',
                                    body: 'กรุณายืนยันรหัสผ่านอีกครั้ง',
                                    icon: 'fas fa-times',
                                    autohide: true,
                                    class: "bg-danger",
                                    delay: 3000,
                                })
                                $submit.removeClass('loading');
                                return false;
                            }
                            this.update(data);
                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">เปลี่ยนรหัสผ่าน</h4>
                            </div>
                            <div className="modal-body">
                                <div className={"form-group"}>
                                    <label>รหัสผ่านเก่า</label>
                                    <input type="password" name={"password"} className={"form-control"} />
                                </div>
                                <div className={"form-group"}>
                                    <label>รหัสผ่านใหม่</label>
                                    <input type="password" name={"new_password"} className={"form-control"} />
                                </div>
                                <div className={"form-group"}>
                                    <label>ยืนยันรหัสผ่านใหม่</label>
                                    <input type="password" name={"confirm_new_password"} className={"form-control"} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type={"button"} className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                        let settime = window.setTimeout(() => {
                                            this.$modal.remove();
                                            clearTimeout(settime);
                                        }, 500)
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}