import { Link, navigate } from '@reach/router';
import React from 'react';
import Pagination from '../Components/Pagination';
import { Helper } from '../Helper';
const axios = require('axios').default;
export default class SuspendPhoneNumber extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            count_items: 0,
            all_items: 0,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
        }
    }
    componentDidMount() {
        this.fetch();
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                data: [],
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : ''
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetch();
            })
        }
    }
    async fetch() {
        let qs = {
            page: this.state.page,
            limit: this.state.limit,
        }
        if (this.state.name) {
            qs.name = this.state.name;
        }
        let res = await axios.get(window.api_host + '/suspend-phone?' + window.$.param(qs));
        let count_items = 0;
        let all_items = 0;
        let data = [];
        if (res.data && res.data.success) {
            data = res.data.data;
            count_items = res.data.count_item;
            all_items = res.data.count_all;
        }
        this.setState({
            data: data,
            all_items: all_items,
            count_items: count_items,
            is_loading: false,
        })
    }
    async delete(data) {
        let qs = { id: data.id }
        let res = await axios.delete(window.api_host + '/suspend-phone?' + window.$.param(qs));
        if (res.data.success) {
            window.$(document).Toasts('create', {
                title: `ยกเลิกระงับใช้เบอร์ ${data.phoneNumber} แล้ว`,
                // body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.fetch()
            return;
        }
        if (res.data.error) {
            window.$(document).Toasts('create', {
                title: `ไม่พบเบอร์โทรศัพท์นี้`,
                // body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-warning",
            })
        }
    }
    render() {
        return (
            <>

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ระงับใช้งานเบอร์โทรศัพท์</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ระงับใช้งานเบอร์โทรศัพท์</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-4'>
                                <div className="card">
                                    <div className="card-header " style={{ display: 'flex' }}>
                                        <h3 className="card-title">ระงับ {this.state.all_items} เบอร์โทร</h3>
                                        <div className={"card-right"} style={{ marginRight: 0 }}>
                                            <button
                                                className='btn btn-default'
                                                onClick={() => {
                                                    Helper.reactToDom(window.$('body'),
                                                        <Form onSuccess={() => {
                                                            this.fetch();
                                                        }} />)
                                                }}
                                            >เพิ่มเบอร์โทรศัพท์</button>
                                        </div>

                                    </div>
                                    <div className="card-body">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        เบอร์โทร
                                                    </th>
                                                    <th style={{ width: 180 }}>ระบบ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !this.state.is_loading
                                                        && this.state.data.length > 0 ?
                                                        this.state.data.map((_data, _data_i) => {
                                                            return (
                                                                <tr key={_data_i}>
                                                                    <td>
                                                                        <div>{_data.phoneNumber}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="flex row" style={{ justifyContent: 'flex-end' }}>
                                                                            <div className="btn-group">
                                                                                <button type="button"
                                                                                    className="btn btn-danger"
                                                                                    ref={(ref) => {
                                                                                        window.$(ref).off().on('click', () => {
                                                                                            if (window.$(ref).hasClass('loading')) { return; }
                                                                                            window.$(ref).addClass('loading');
                                                                                            Helper.confirmModal({
                                                                                                title: "ยืนยันการยกเลิก",
                                                                                                description: "ต้องการยกเลิกระงับใช้งานเบอร์โทรศัพทนี้หรือไม่ ?",
                                                                                                submit_text: 'ลบ',
                                                                                                onConfirm: () => {
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                    this.delete(_data);
                                                                                                },
                                                                                                onCancel: () => {
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                }
                                                                                            });
                                                                                        })
                                                                                    }}
                                                                                >ลบ</button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                        : null
                                                }
                                                {
                                                    !this.state.is_loading
                                                        && this.state.data.length === 0 ?
                                                        <tr >
                                                            <td colSpan="2" style={{ textAlign: 'center' }}>
                                                                ไม่มีข้อมูล
                                                            </td>
                                                        </tr>
                                                        : null
                                                }
                                                {
                                                    this.state.is_loading ?
                                                        <tr >
                                                            <td colSpan="2" style={{ textAlign: 'center' }}>
                                                                <div className="ui active inline loader small" ></div>
                                                            </td>
                                                        </tr>
                                                        : null
                                                }


                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer clearfix">
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length !== 0 ?
                                                <Pagination
                                                    now={this.state.page}
                                                    all_items={this.state.all_items}
                                                    limit={this.state.limit}
                                                    location={this.props.location}
                                                />
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class Form extends React.Component {
    async create(data) {
        if (data.phoneNumber.length != 10) {
            window.$(document).Toasts('create', {
                title: `กรอกเบอร์โทรศัพท์ไม่ถูกต้อง`,
                // body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-warning",
            })
            this.$submit.removeClass('loading');
            return;
        }
        let phone = data.phoneNumber.replace('-', '');
        phone = phone.replace(' ', '');
        phone = '+66' + phone.substr(1, 9);
        let res = await axios.post(window.api_host + '/suspend-phone', { phoneNumber: phone });
        if (res.data.success) {
            window.$(document).Toasts('create', {
                title: `ระงับใช้เบอร์ ${data.phoneNumber} แล้ว`,
                // body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.props.onSuccess(data);
            this.$modal.modal('hide')
            return;
        }
        if (res.data.error) {
            window.$(document).Toasts('create', {
                title: `ไม่ะพบเบอร์โทรศัพท์`,
                // body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-warning",
            })
            this.$submit.removeClass('loading');
        }
    }
    render() {
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) { return false; }
                        this.$submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        this.create(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                เพิ่มเบอร์โทรศัพท์ที่ต้องการระงับ
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className={"form-group"}>
                                <label>เบอร์โทร</label>
                                <input type="text"
                                    name={"phoneNumber"}
                                    defaultValue={this.props.data && this.props.data.phoneNumber ? this.props.data.phoneNumber : ''}
                                    className={"form-control"}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >
                                ยกเลิก
                            </button>
                            <button className={"btn btn-primary"} type={"submit"}>เพิ่ม</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}