import React from 'react';
import { Link, navigate } from '@reach/router';
import { db } from '../firebase';
import { Helper } from './../Helper';
import Pagination from '../Components/Pagination';
const axios = require('axios').default;

const defaultLimit = 10;
export default class ViewUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            is_users_loading: true,
            promotion: false,
            users: [],
            promotion_id: props.promotion_id,
            page: Helper.getParameterByName(`page`) ? parseFloat(Helper.getParameterByName(`page`)) : 1,
            limit: defaultLimit,
            q: Helper.getParameterByName(`q`) || '',
            userId: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_users_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                q: Helper.getParameterByName('q') || false,
            }, () => {
                this.getUsers();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_users_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                q: Helper.getParameterByName('q') || false,
            }, () => {
                this.getUsers();
            })
        }

    }


    async fetch() {
        let promotion = await (await db.doc("promotions/" + this.state.promotion_id).get()).data();
        let market_user = await (await db.doc('market_users/' + promotion.marketUid).get()).data();
        let history = await db.collection('promotions/' + this.state.promotion_id + '/history').get();
        let users = [];
        let _history = await Promise.all(
            history.docs.map(async (element) => {
                let his = element.data();
                his.id = element.id;
                // if (his.bookUid) {
                //     let getBook = await db.collection('books').doc(element.data().bookUid).get();
                //     if (getBook.exists) {
                //         his.book = getBook.data();
                //         his.book.uid = getBook.id;
                //     }
                // }
                // let user = await db.doc('users/' + element.data().userUid).get();
                // if (user.exists) {
                //     users.push({
                //         ...user.data(),
                //         id: user.id,
                //         book: his.book || false,
                //         promotionHistoryUid: element.id
                //     });
                // }
                return {
                    ...his
                };

            })
        );
        promotion.startAt = JSON.parse(promotion.startAt) - (7 * 60 * 60 * 1000);
        promotion.EndAt = JSON.parse(promotion.EndAt) - (7 * 60 * 60 * 1000);
        promotion.history = _history;
        promotion.market_user = market_user;
        this.setState({
            promotion: promotion,
            is_loading: false,
        }, () => {
            this.getUsers();
        })
    }

    async getUsers() {
        let qs = {
            promotionId: this.state.promotion_id,
            page: this.state.page,
            limit: this.state.limit,
        }
        if (this.state.q) {
            qs.userId = this.state.q;
        }
        let res = await axios.get(`${window.api_host}/get-promotion-users?${window.$.param(qs)}`);
        // console.log(res);
        let count_all = 0;
        let users = [];
        if (res?.data?.status == 'success') {
            users = res.data.users;
            count_all = res.data.count_all;
        }
        this.setState({
            users: users,
            is_users_loading: false,
            all_items: count_all,
        })
    }


    async promotionDelete(promotionHistoryUid, options) {
        // console.log(promotionHistoryUid)
        // console.log(this.state.promotion_id)
        let data = {
            promotionHistoryUid: promotionHistoryUid,
            promotionId: this.state.promotion_id,
        }
        try {
            await axios.delete(`${window.api_host}/promotion-history?${window.$.param(data)}`);
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            let users = [...this.state.users];
            users = users.filter((e) => e.promotionHistoryUid != promotionHistoryUid);
            this.setState({
                users: users
            })
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: 'กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            if (options && options.onError) {
                options.onError();
            }
            return;
        }
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ข้อมูลโปรโมชั่น</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/promotion">โปรโมชั่น</Link></li>
                                    <li className="breadcrumb-item active">ข้อมูลโปรโมชั่น</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.promotion ?
                                <>
                                    <div className={"row"}>
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">ข้อมูลโปรโมชั่น  : {this.state.promotion ? this.state.promotion.name : null}  {this.state.promotion && this.state.promotion.nameEN ? `/ ` + this.state.promotion.nameEN : null}</h3>
                                                    <div className='card-tools'>
                                                        {this.state.promotion?.createdAt ?
                                                            <span>{Helper.getDateThai(this.state.promotion.createdAt, { is_show_time: true })}</span>
                                                            : null}
                                                    </div>
                                                </div>
                                                <div className="card-body" >
                                                    <div className={"row"}>
                                                        <div className={"col-sm-12 col-lg-4"}>
                                                            <div className="card">
                                                                <div className="card-header" style={{ display: 'flex' }}>
                                                                    <h3 className="card-title">ผู้รับผิดชอบ</h3>
                                                                    <div className={"card-right"}>
                                                                        {this.state.promotion?.market_user?.name ? this.state.promotion.market_user.name : null}
                                                                    </div>
                                                                </div>
                                                                <div className="card-body" >
                                                                    <div>
                                                                        <label>รหัสโค้ด</label> :{' '}
                                                                        {
                                                                            this.state.promotion.voucherCode ?
                                                                                this.state.promotion.voucherCode
                                                                                : '-'
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <label>ส่วนลด</label> :  {this.state.promotion.value}
                                                                        {this.state.promotion.valueType === 'raw' ? ' บาท' : null}
                                                                        {this.state.promotion.valueType === 'percent' ? ' %' : null}
                                                                    </div>
                                                                    <div className={"row"}>
                                                                        <div className={"col-xs-12 col-lg-6"}>
                                                                            <div>
                                                                                <label>ราคาขั้นต่ำ</label> : {this.state.promotion.minPrice ? Helper.numberFormat(this.state.promotion.minPrice) + ' บาท' : '0'}
                                                                            </div>
                                                                        </div>
                                                                        <div className={"col-xs-12 col-lg-6"}>
                                                                            <div>
                                                                                <label>ราคาสูงสุด</label> : {this.state.promotion.maxPrice ? Helper.numberFormat(this.state.promotion.maxPrice) + ' บาท' : '0'}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label>ประเภท</label> :{' '}
                                                                        {
                                                                            this.state.promotion.promotionType === 'event' ?
                                                                                "แสดงในหน้า app"
                                                                                : null
                                                                        }
                                                                        {
                                                                            this.state.promotion.promotionType === 'unique' ?
                                                                                "สำหรับการตลาดไปหาลูกค้าเอง"
                                                                                : null
                                                                        }
                                                                        {
                                                                            this.state.promotion.promotionType === 'package' ?
                                                                                "สำหรับการแพ็กเกจ"
                                                                                : null
                                                                        }
                                                                        {
                                                                            this.state.promotion.promotionType === 'agent' ?
                                                                                "สำหรับการตัวแทน"
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <label>เงื่อนไข</label> :{' '}
                                                                        {
                                                                            this.state.promotion.promotionCondition === 'all' ?
                                                                                "ใช้ได้ทุกที่"
                                                                                : null
                                                                        }
                                                                        {
                                                                            this.state.promotion.promotionCondition === 'only_first_code' ?
                                                                                "ใช้ครั้งแรกใน code ชุดนั้นๆ"
                                                                                : null
                                                                        }
                                                                        {
                                                                            this.state.promotion.promotionCondition === 'only_first_time' ?
                                                                                "ใช้ครั้งแรกใน app"
                                                                                : null
                                                                        }
                                                                        {
                                                                            this.state.promotion.promotionCondition === 'only_first_time_plus_code' ?
                                                                                "ใช้ code ครั้งแรกและ ใช้ code ชุดนั้นครั้งแรก"
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <label htmlFor="isCanEditAdmin">{!this.state.promotion.isAllTime ? "กำหนดเวลา" : "ไม่กำหนดเวลา"}</label>{' '}
                                                                            </div>
                                                                            {
                                                                                !this.state.promotion.isAllTime ?
                                                                                    <div id={"date_rage"}>
                                                                                        <div className={"row"}>
                                                                                            <div className={"col-12"}>
                                                                                                <label>วันที่เริ่มต้น</label> : {Helper.getDateThai(this.state.promotion.startAt, { plus_hours: 7, is_show_time: true })}
                                                                                            </div>
                                                                                            <div className={"col-12"}>
                                                                                                <label >วันที่สิ้นสุด</label> : {Helper.getDateThai(this.state.promotion.EndAt, { plus_hours: 7, is_show_time: true })}
                                                                                                {/* <div><small>ใช้ได้ถึง {Helper.getDateThai(this.state.promotion.EndAt)} - 23:59</small></div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <label htmlFor="isCanEditAdmin">{this.state.promotion.isUnLimit ? "ไม่จำกัดการใช้งาน" : "จำกัดการใช้งาน"}</label>  {!this.state.promotion.isUnLimit ? ": " + this.state.promotion.history.length + "/" + this.state.promotion.limit : <>: {this.state.promotion.history.length + "/"}<i className="fas fa-infinity" style={{ color: '#ffc107' }}></i></>}
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.promotion.isShowWeb ?
                                                                            <div>
                                                                                <div>
                                                                                    <label htmlFor="isCanEditAdmin">แสดงบนเว็บไซด์</label> : <i className="fas fa-check" style={{ color: 'green' }} />
                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"col-xs-12 col-lg-8"}>
                                                            <div className="card">
                                                                <div className="card-body" >
                                                                    <div className={"row"}>
                                                                        <div className={"col-lg-4 col-sm-12"}>
                                                                            <img src={this.state.promotion.imageURL || "/assets/images/no_image.png"}
                                                                                alt={"PromotionPic"}
                                                                                style={{ maxWidth: '100%', maxHeight: 205 }}
                                                                            />
                                                                        </div>
                                                                        <div className={"col-lg-8 col-sm-12"}>
                                                                            {
                                                                                this.state.promotion
                                                                                    && this.state.promotion.description ?
                                                                                    this.state.promotion.description
                                                                                    : '-'
                                                                            }
                                                                            {
                                                                                this.state.promotion
                                                                                    && this.state.promotion.descriptionEN ?
                                                                                    <><hr />{this.state.promotion.descriptionEN}</>
                                                                                    : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <form className='d-flex mb-2'
                                                        onSubmit={(e) => {
                                                            if (this.state.is_users_loading) { return; }
                                                            e.preventDefault();
                                                            navigate(`${window.location.origin}/promotion/${this.props.promotion_id}?page=1&q=${this.searchName.val()}`)
                                                            // this.$searchForm.find('[type="submit"]').trigger('click');
                                                        }}
                                                    >
                                                        <div className="input-group input-group-sm" style={{ marginTop: 0 }}>
                                                            <input
                                                                ref={(ref) => this.searchName = window.$(ref)}
                                                                type="text"
                                                                name="q"
                                                                className="form-control float-right"
                                                                placeholder="ค้นหาจาก User ID"
                                                                defaultValue={this.state.q}
                                                                style={{ maxWidth: 260 }}
                                                            />
                                                            <div className="input-group-append">
                                                                <button type="submit" className="btn btn-default"><i className="fas fa-search"></i></button>
                                                            </div>
                                                        </div>
                                                        <div className='ml-auto'>
                                                            <Link to={`/promotion/report/${this.props.promotion_id}`} className='btn btn-default'>รายงาน</Link>
                                                        </div>
                                                    </form>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>ชื่อ</th>
                                                                <th>book</th>
                                                                <th>อีเมล</th>
                                                                <th>ข้อมูลการติดต่อ</th>
                                                                <th style={{ width: 200 }}>ระบบ</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                !this.state.is_users_loading
                                                                    && this.state.users.length > 0 ?
                                                                    this.state.users.map((_data, _data_i) => {
                                                                        return (
                                                                            <tr key={_data_i}>
                                                                                <td>
                                                                                    {_data.displayName}
                                                                                    {
                                                                                        !_data.acceptTerms ?
                                                                                            <span className="badge badge-info ml-2">นอกระบบ</span>
                                                                                            : null
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {_data.book ? <a href={`${window.location.origin}/booking/${_data.book.uid}`} target={"_blank"}>{_data.book.bookOn}</a> : '-'}
                                                                                    <div>
                                                                                        {this.renderBookStatus(_data.book)}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {_data.email ? _data.email : _data.guestEmail ? _data.guestEmail : null}
                                                                                </td>
                                                                                <td>
                                                                                    {_data.phoneNumber ? _data.phoneNumber : _data.guestPhoneNumber ? _data.guestPhoneNumber : null}
                                                                                </td>
                                                                                <td>
                                                                                    <div className="btn-group" role="group" aria-label="Basic example">
                                                                                        <Link to={'/user/' + _data.userUid} type="button" className="btn btn-default">ดูข้อมูล</Link>
                                                                                        {
                                                                                            _data?.book?.bookStatus == 'cancel'
                                                                                                || _data?.book?.paymentStatus == 'refund' ?
                                                                                                <button
                                                                                                    ref={(ref) => {
                                                                                                        let ele = window.$(ref);
                                                                                                        ele.off().on('click', () => {
                                                                                                            Helper.confirmModal({
                                                                                                                title: "ยืนยันการลบ",
                                                                                                                description: 'ต้องการลบข้อมูลประวัติผู้ให้โปรโมชั่นนี้หรือไม่ ?',
                                                                                                                onConfirm: () => {
                                                                                                                    if (ele.hasClass('loading')) { return; }
                                                                                                                    ele.addClass('loading')
                                                                                                                    this.promotionDelete(
                                                                                                                        _data.promotionHistoryUid,
                                                                                                                        {
                                                                                                                            onError: () => {
                                                                                                                                ele.removeClass('loading')
                                                                                                                            }
                                                                                                                        }
                                                                                                                    );
                                                                                                                }
                                                                                                            })
                                                                                                        })
                                                                                                    }}
                                                                                                    className='btn btn-danger'
                                                                                                >ลบ</button>
                                                                                                : null
                                                                                        }
                                                                                    </div>

                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                    : null
                                                            }
                                                            {
                                                                !this.state.is_users_loading
                                                                    && this.state.users.length === 0 ?
                                                                    <tr >
                                                                        <td colSpan="6" style={{ textAlign: 'center' }}>
                                                                            ไม่มีข้อมูล
                                                                        </td>
                                                                    </tr>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.is_users_loading ?
                                                                    <tr >
                                                                        <td colSpan="4" style={{ textAlign: 'center' }}>
                                                                            <div className="ui active inline loader small" ></div>
                                                                        </td>
                                                                    </tr>
                                                                    : null
                                                            }
                                                        </tbody>
                                                    </table>

                                                    <>
                                                        {
                                                            !this.state.is_users_loading
                                                                && this.state.users.length !== 0 ?
                                                                <Pagination
                                                                    now={this.state.page}
                                                                    all_items={this.state.all_items}
                                                                    limit={this.state.limit}
                                                                    location={this.props.location}
                                                                />
                                                                : null
                                                        }
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null
                        }

                    </div>
                </section>
            </>
        );
    }

    renderBookStatus(booking) {
        return (
            <>
                {
                    booking.bookStatus === 'accept' && booking.paymentStatus !== 'refund' && booking.paymentStatus !== 'reverce' ?
                        <><i className={"fas fa-circle"} style={{ color: "blue" }}></i>  ยอมรับ</>
                        : null
                }
                {
                    booking.bookStatus === 'arrive' && booking.paymentStatus !== 'refund' && booking.paymentStatus !== 'reverce' ?
                        <><i className={"fas fa-circle"} style={{ color: "purple" }}></i>  ถึงแล้ว</>
                        : null
                }
                {
                    booking.bookStatus === 'done' && booking.paymentStatus !== 'refund' && booking.paymentStatus !== 'reverce' ?
                        <><i className={"fas fa-circle"} style={{ color: "lightgreen" }}></i>  เสร็จสิ้น</>
                        : null
                }
                {
                    booking.bookStatus === 'finish' && parseFloat(booking.starFromProvider) === 0 && booking.paymentStatus !== 'refund' && booking.paymentStatus !== 'reverce' ?
                        <><i className={"fas fa-circle"} style={{ color: "green" }}></i>  เสร็จงาน</>
                        : null
                }
                {
                    booking.bookStatus === 'finish' && parseFloat(booking.starFromProvider) > 0 && booking.paymentStatus !== 'refund' && booking.paymentStatus !== 'reverce' ?
                        <>
                            <i className={"fas fa-circle"} style={{ color: "darkgreen" }}></i>  ส่งงาน
                        </>
                        : null
                }
                {
                    booking.bookStatus === 'going' && booking.paymentStatus !== 'refund' && booking.paymentStatus !== 'reverce' ?
                        <><i className={"fas fa-circle"} style={{ color: "orange" }}></i>  กำลังเดินทาง</>
                        : null
                }
                {
                    booking.bookStatus === 'wait' && booking.paymentStatus !== 'refund' && booking.paymentStatus !== 'reverce' ?
                        <><i className={"fas fa-circle"} style={{ color: "yellow" }}></i>  รอ</>
                        : null
                }
                {
                    booking.bookStatus === 'working' && booking.paymentStatus !== 'refund' && booking.paymentStatus !== 'reverce' ?
                        <><i className={"fas fa-circle"} style={{ color: "darkblue" }}></i>  กำลังทำงาน</>
                        : null
                }
                {
                    booking.bookStatus === 'cancel' && booking.paymentStatus !== 'refund' && booking.paymentStatus !== 'reverce' ?
                        <><i className={"fas fa-circle"} style={{ color: "red" }}></i>  ยกเลิก
                            {booking.cancelRemark ? <div style={{ marginBottom: '0.5rem' }}>{booking.cancelRemark}</div> : null}
                        </>
                        : null
                }
                {
                    booking.paymentStatus === 'refund' ?
                        <><i className={"fas fa-circle"} style={{ color: "red" }}></i>  คืนเงิน</>
                        : null
                }
            </>
        )

    }
}