import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../Helper';
import Pagination from './../Components/Pagination';
import { db } from '../firebase';

export default class Salary extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            all_items: 0,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : 'createdAt',
            orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : 'desc',
            is_show_next_button: false,
            data_count: 0,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                data: [],
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : 'createdAt',
                orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : 'desc',
                is_show_next_button: false,
                data_count: 0,
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetch();
            })
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let prepare_data = [];
        let count = 0;
        let all_items = 0;
        let orderByType = 'asc';
        if (this.state.orderByType) {
            orderByType = this.state.orderByType;
        }
        let salaryRef = db.collection('salary');
        if (this.state.orderBy) {
            salaryRef = salaryRef.orderBy(this.state.orderBy, orderByType)
        }

        let salary = await salaryRef.get();
        let salary_data = await Promise.all(
            salary.docs.map(async (doc) => {
                let providerRef = db.collection('salary/' + doc.id + '/provider').get();
                let is_can_delete = true;
                let count_success = 0;
                (await providerRef).docs.map((_doc) => {
                    if (_doc.data().status === 'success') {
                        count_success++;
                    }
                    return true;
                })
                if (count_success > 0 || doc.data().status === 'successful') {
                    is_can_delete = false;
                }
                return { ...doc.data(), id: doc.id, is_can_delete: is_can_delete };
            })
        );

        salary_data = salary_data.filter((_data) => {
            return _data !== false
        })
        all_items = salary_data.length;
        if (this.state.status && this.state.status !== '') {
            salary_data = salary_data.filter((_data) => {
                return _data.status === this.state.status
            })
        }

        salary_data.forEach((_data, _data_i) => {
            let match_search = 0;
            if (this.state.name && this.state.name !== "") {
                match_search = _data.title.toLowerCase().indexOf(this.state.name.toLowerCase());
                if (match_search !== -1) {
                    count++;
                    prepare_data.push({ ..._data });
                }
            } else {
                count++;
                prepare_data.push({ ..._data });
            }
        })


        prepare_data = prepare_data.filter((__data, __data_i) => {
            return __data_i < (this.state.limit * this.state.page)
                && __data_i > (((this.state.limit * this.state.page) - this.state.limit) - 1)
        })


        this.setState({
            data: prepare_data,
            all_items: all_items,
            data_count: count,
            is_loading: false,
        })
    }

    setOrderBy(orderBy) {
        let orderByType = 'asc';
        if (this.state.orderByType && this.state.orderByType === 'asc') {
            orderByType = 'desc';
        }
        this.setState({
            orderBy: orderBy,
            orderByType: orderByType
        }, () => {
            this.$searchForm.find('[type="submit"]').trigger('click');
        })
    }

    async delete(data) {
        await db.doc('salary/' + data.id).delete();
        this.fetch();
        window.$(document).Toasts('create', {
            title: 'ลบข้อมูลสำเร็จ',
            // body: 'ทำการลบข้อมูลสำเร็จ',
            icon: 'fas fa-check',
            autohide: true,
            delay: 3000,
            class: "bg-success",
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการรายได้</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">จัดการรายได้</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายการโอนเงิน {this.state.all_items} รายการ</h3>
                                <div className={"card-right"} />
                                <div className="card-tools">
                                    <form className="input-group input-group-sm"
                                        ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let obj = {};
                                            obj.page = 1;
                                            obj.status = data.status;
                                            obj.name = data.name;
                                            if (data.orderBy) {
                                                obj.orderBy = data.orderBy
                                            }
                                            if (data.orderByType) {
                                                obj.orderByType = data.orderByType
                                            }
                                            let url = Helper.getQueryStringLinkFromObject({ ...obj }, '/salary')
                                            navigate(url)
                                        }}
                                    >
                                        <select
                                            className="form-control placeholder_font"
                                            name="status"
                                            defaultValue={this.state.orderBy}
                                            onChange={() => {
                                                this.$searchForm.find('[type="submit"]').trigger('click');
                                            }}
                                        >
                                            <option value="">ทั้งหมด</option>
                                            <option value="successful">สำเร็จ</option>
                                            <option value="unsuccessful">ไม่สำเร็จ</option>
                                        </select>
                                        {
                                            this.state.orderBy ?
                                                <>
                                                    <input name="orderBy" type="hidden" value={this.state.orderBy} />
                                                    <input name="orderByType" type="hidden" value={this.state.orderByType} />
                                                </>
                                                : null
                                        }
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากหัวข้อ" defaultValue={this.state.name} />
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <div className="flex row">
                                        <div>
                                            <button className="btn btn-primary"
                                                disabled={this.state.is_loading}
                                                onClick={() => {
                                                    if (this.state.is_loading) { return }
                                                    Helper.reactToDom(window.$('body'),
                                                        <CreateSalaryForm
                                                            data={this.state.data}
                                                            admin={this.props.admin}
                                                            onSuccess={(id) => {
                                                                navigate('/salary-provider/' + id)
                                                            }}
                                                        />
                                                    )
                                                }}
                                            >
                                                สร้างรายการโอนเงิน
                                            </button>
                                        </div>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <Link className="btn btn-default" to="/provider-salary">รายได้แม่บ้าน</Link>
                                        </div>
                                    </div>

                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th
                                                onClick={() => {
                                                    this.setOrderBy('createdAt');
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <div className={"flex row"}>
                                                    วันที่
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        {
                                                            this.state.orderBy === 'createdAt' ?
                                                                this.state.orderByType === 'asc' ?
                                                                    <i className="fas fa-sort-amount-down-alt"></i>
                                                                    :
                                                                    <i className="fas fa-sort-amount-down"></i>
                                                                : <i className="fas fa-arrows-alt-v"></i>
                                                        }
                                                    </div>
                                                </div>
                                            </th>
                                            <th
                                                onClick={() => {
                                                    this.setOrderBy('minPrice');
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <div className={"flex row"}>
                                                    {/* ยอดเงินทั้งหมด */}
                                                    รายได้ขั้นต่ำ
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        {
                                                            this.state.orderBy === 'minPrice' ?
                                                                this.state.orderByType === 'asc' ?
                                                                    <i className="fas fa-sort-amount-down-alt"></i>
                                                                    :
                                                                    <i className="fas fa-sort-amount-down"></i>
                                                                : <i className="fas fa-arrows-alt-v"></i>
                                                        }
                                                    </div>
                                                </div>
                                            </th>
                                            <th
                                                onClick={() => {
                                                    this.setOrderBy('totalPrice');
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <div className={"flex row"}>
                                                    ยอดเงินทั้งหมด
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        {
                                                            this.state.orderBy === 'totalPrice' ?
                                                                this.state.orderByType === 'asc' ?
                                                                    <i className="fas fa-sort-amount-down-alt"></i>
                                                                    :
                                                                    <i className="fas fa-sort-amount-down"></i>
                                                                : <i className="fas fa-arrows-alt-v"></i>
                                                        }
                                                    </div>
                                                </div>
                                            </th>
                                            <th>สถานะ</th>
                                            <th>หมายเหตุ</th>
                                            <th style={{ width: 180 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                <div>{_data.title}</div>
                                                                <div>{Helper.getDateThai(_data.createdAt, { is_show_time: true })}</div>
                                                            </td>
                                                            <td><div>{Helper.numberFormat(_data.minPrice)}</div></td>
                                                            <td><div>{Helper.numberFormat(_data.totalPrice)}</div></td>
                                                            <td>
                                                                <div>
                                                                    {
                                                                        _data.status === 'unsuccessful' ?
                                                                            <div style={{ color: 'red' }}>ยังไม่สำเร็จ</div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        _data.status === 'successful' ?
                                                                            <div style={{ color: 'green' }}>สำเร็จ</div>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {
                                                                        _data.note ?
                                                                            _data.note
                                                                            : '-'
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="flex row">
                                                                    <div className="btn-group">
                                                                        {
                                                                            _data.type == 'upload' ?
                                                                                <Link className="btn btn-default" to={"/csv-salary-provider/" + _data.id} >ดูข้อมูล (CSV)</Link>
                                                                                : <Link className="btn btn-default" to={"/salary-provider/" + _data.id} >ดูข้อมูล</Link>
                                                                        }

                                                                        {
                                                                            _data.is_can_delete ?
                                                                                <button type="button"
                                                                                    className="btn btn-danger"
                                                                                    ref={(ref) => {
                                                                                        window.$(ref).off().on('click', () => {
                                                                                            if (window.$(ref).hasClass('loading')) { return; }
                                                                                            window.$(ref).addClass('loading');
                                                                                            Helper.confirmModal({
                                                                                                title: "ยืนยันการลบ",
                                                                                                description: "ต้องการลบรายการโอนเงินนี้หรือไม่ ?",
                                                                                                submit_text: 'ลบ',
                                                                                                onConfirm: () => {
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                    this.delete(_data);
                                                                                                },
                                                                                                onCancel: () => {
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                }
                                                                                            });
                                                                                        })
                                                                                    }}
                                                                                >ลบ</button>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class CreateSalaryForm extends React.Component {
    constructor(props) {
        super(props);
        let is_can_add = props.data.filter((_data) => { return _data.status === "unsuccessful" }).length === 0;

        this.state = {
            disable_all: false,
            is_can_add: is_can_add,
        }
    }

    async add(data) {
        if (!this.state.is_can_add) {
            window.$(document).Toasts('create', {
                title: 'ไม่สามารถสร้างรายการโอนเงินได้',
                body: 'มีรายการโอนเงินที่ยังไม่สำเร็จ',
                icon: 'fas fa-times',
                autohide: true,
                delay: 10000,
                class: "bg-danger",
            })
            this.setState({
                disable_all: false
            }, () => {
                this.$submit.removeClass('loading');
            });
            return;
        }
        data.minPrice = parseFloat(data.minPrice);
        data.totalPrice = 0;
        // console.log(data);
        let providerRef = await db.collection('provider_users').where('wallet', '>=', data.minPrice);
        let provider = await providerRef.get();
        let provider_data = await Promise.all(
            provider.docs.map(async (ele) => {
                let element = ele.data();
                let displayName = element.displayName;
                let return_data = {};
                // let id = ele.id;
                let provider_user_data_ref = await db.collection('provider_users_data').where('phoneNumber', '==', element.phoneNumber).get();
                let provider_user = provider_user_data_ref.docs[0];
                let provider_user_data = false;

                if (provider_user_data_ref.docs.length > 0) {
                    if (provider_user.exists) {
                        provider_user_data = provider_user.data();
                    }
                }


                let booksRef = [];
                let books_count = 0;
                if (provider_user) {
                    booksRef = db.collection('books').where('providerUserUid', '==', provider_user.id);
                    books_count = await (await booksRef.get()).size
                }
                // console.log(provider_user_data);
                if (!element.displayName && provider_user_data) {
                    displayName = provider_user_data.prefix + ' ' + provider_user_data.firstName + ' ' + provider_user_data.lastName
                }
                if (element && element.wallet && element.wallet >= data.minPrice) {
                    data.totalPrice += element.wallet;
                    return_data.displayName = displayName;
                    return_data.defaultPrice = element.wallet;
                    return_data.providerUID = false;
                    if (provider_user) {
                        return_data.providerUID = provider_user.id
                    }
                    return_data.default_booking_count = books_count;
                    return_data.status = 'wait';
                    if (provider_user_data.bankAccountName) {
                        return_data.providerBankName = provider_user_data.bankAccountName;
                    }
                    if (provider_user_data.bankBranch) {
                        return_data.providerBankBranch = provider_user_data.bankBranch;
                    }
                    if (provider_user_data.bankAccountNo) {
                        return_data.providerBankAccountNo = provider_user_data.bankAccountNo;
                    }
                    if (provider_user_data.bank) {
                        return_data.transferBank = provider_user_data.bank;
                    }
                    return return_data;
                }
                return null
            })
        )
        provider_data = provider_data.filter((e) => {
            return e !== null && e.providerUID
        })

        if (provider_data.length === 0) {
            window.$(document).Toasts('create', {
                title: 'ไม่พบแม่บ้านที่มี ยอดเงินถึง ' + Helper.numberFormat(data.minPrice) + ' บาท',
                // body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 10000,
                class: "bg-danger",
            })
            this.setState({
                disable_all: false
            }, () => {
                this.$submit.removeClass('loading');
            });
            return false;
        }
        // console.log(provider_data);
        await db.collection('salary').add({ ...data }).then(async (documentReference) => {
            provider_data.map(async (_provider_data, _provider_data_i) => {
                await db.doc(`salary/` + documentReference.id + '/provider/' + _provider_data.providerUID).set({ ..._provider_data });
            })
            window.$(document).Toasts('create', {
                title: 'สร้างรายการโอนเงินสำเร็จ',
                // body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 10000,
                class: "bg-success",
            });
            this.$modal.modal('hide');
            if (this.props.onSuccess) {
                this.props.onSuccess(documentReference.id);
            }
        });

    }

    render() {
        // console.log(this.props.admin);
        // console.log(this.state.is_can_add)
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard="false" data-backdrop="static"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) { return; }
                        this.setState({
                            disable_all: true
                        }, () => {
                            this.$submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$searchForm);
                            data.createdAt = new Date().getTime();
                            data.createdAdminUid = this.props.admin.id.stringValue;
                            this.add(data);
                        })
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                สร้างรายการโอนเงิน
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>หัวข้อ</label>
                                <input name="title" className="form-control" placeholder="ระบุหัวข้อ" required={true} readOnly={this.state.disable_all} />
                            </div>
                            <div className="form-group">
                                <label>ยอดรายได้ขั้นต่ำ</label>
                                <input name="minPrice" type="number" className="form-control" placeholder="ระบุยอดรายได้ขั้นต่ำ" required={true} defaultValue={0} readOnly={this.state.disable_all} />
                            </div>
                            <div className="form-group">
                                <label>หมายเหตุ</label>
                                <textarea className="form-control" name="note" readOnly={this.state.disable_all}></textarea>
                            </div>
                            <input type="hidden" name="status" value="unsuccessful" />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal" disabled={this.state.disable_all}>ยกเลิก</button>
                            <button type="submit" className="btn btn-primary">สร้าง</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}