import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../../Helper';
import { db, storage } from '../../firebase';
import Pagination from '../../Components/Pagination';
const axios = require('axios').default;
const CancelToken = axios.CancelToken;
let cancel;

export default class Users extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            phoneNumber: Helper.getParameterByName('phoneNumber') ? Helper.getParameterByName('phoneNumber') : '',
            email: Helper.getParameterByName('email') ? Helper.getParameterByName('email') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            is_show_next_button: false,
            users_count: 0,
            all_items: 0,
            user_type: Helper.getParameterByName('user_type') ? Helper.getParameterByName('user_type') : 'all',
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                phoneNumber: Helper.getParameterByName('phoneNumber') ? Helper.getParameterByName('phoneNumber') : '',
                email: Helper.getParameterByName('email') ? Helper.getParameterByName('email') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                user_type: Helper.getParameterByName('user_type') ? Helper.getParameterByName('user_type') : 'all',
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: '',
                phoneNumber: '',
                email: '',
                user_type: 'all',
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        if (cancel !== undefined) cancel();
        let filter = {
            page: this.state.page,
            limit: this.state.limit,
        };
        if (this.state.name) {
            filter.name = this.state.name;
        }
        if (this.state.status) {
            filter.status = this.state.status;
        }
        if (this.state.user_type) {
            filter.user_type = this.state.user_type;
        }
        if (this.state.phoneNumber) {
            let phone = this.state.phoneNumber;
            let phoneCheck = phone.charAt(0);
            if (phoneCheck == '0') {
                phone = '+66' + phone.substr(1, 9);
            }
            filter.phoneNumber = phone;
        }
        if (this.state.email) {
            filter.email = this.state.email;
        }
        if (this.state.all_items == 0) {
            filter.requestCountAll = true
        }
        filter.requestCountItems = true
        let res = await axios.get(`${window.api_host_agent}/agents?${window.$.param(filter)}`, {
            cancelToken: new CancelToken(c => cancel = c),
        });
        let users = [];
        let users_count = this.state.users_count;
        let count = this.state.all_items;
        if (res.data.success) {
            users = res.data.users;
            if (res.data.count_all != undefined) {
                users_count = res.data.count_all;
                if (this.state.all_items == 0) {
                    count = res.data.count_all;
                }
            }
            if (res.data.count_items) {
                count = res.data.count_items;
            }
        }
        this.setState({
            data: users,
            is_loading: false,
            all_items: count,
            users_count: users_count,
        })
    }

    delete(data) {
        if (data.transferImageUrl) {
            storage.ref(data.transferImageUrl).delete();
        }
        let documentRef = db.doc(`agents/` + data.id);
        documentRef.delete().then(() => {
            this.fetchData();
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
        });
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ตัวแทน</h1>

                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ตัวแทน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex', alignItems: 'center' }}>
                                <h3 className="card-title" style={{ flex: 1, display: 'flex' }}>รายชื่อตัวแทน</h3>
                                <div className='card-tools'>
                                    <button className='btn btn-primary'
                                        onClick={() => {
                                            Helper.reactToDom(window.$('body'),
                                                <AgentFormModal mode="create"
                                                    onSuccess={() => {
                                                        this.setState({
                                                            is_loading: true,
                                                        }, () => {
                                                            this.fetchData();
                                                        })
                                                    }}
                                                />)
                                        }}
                                    >สร้างตัวแทน</button>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>ชื่อ</th>
                                            <th>อีเมล</th>
                                            <th>ข้อมูลการติดต่อ</th>
                                            <th style={{ width: 100 }}>ระบบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {_data.name}
                                                            </td>
                                                            <td>
                                                                {_data.email || null}
                                                            </td>
                                                            <td>
                                                                {_data.tel || null}
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <Link to={'/agent/' + _data.uid} type="button" className="btn btn-default">ดูข้อมูล</Link>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="4" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class AgentFormModal extends React.Component {
    update() {
        return;
    }

    async create(data) {
        console.log(data);
        let res = await axios.post(`${window.api_host_agent}/agents`, {
            ...data
        });
        if (res.data.success) {
            window.$(document).Toasts('create', {
                title: 'สำเร็จ',
                body: 'เพิ่มตัวแทนสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            this.$modal.modal('hide')
        } else {
            this.$submitBtn.removeClass('loading');
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: 'ไม่สามารถสร้างตัวแทนได้',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
        }
    }
    render() {
        return (

            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$form = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        data.createAt = new Date().getTime();
                        if (this.props.mode === 'create') {
                            this.create(data);
                        }
                        if (this.props.mode === 'edit') {
                            this.update(data);
                        }
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                สร้างตัวแทน
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className='form-group'>
                                <label>ชื่อ</label>
                                <input name="name" className='form-control' />
                            </div>
                            <div className='form-group'>
                                <label>อีเมล์</label>
                                <input name="email" className='form-control' />
                            </div>
                            <div className='form-group'>
                                <label>เบอร์โทรศัพท์</label>
                                <input name="tel" className='form-control' />
                            </div>
                            <div className='form-group'>
                                <label>รหัสผ่าน</label>
                                <input name="password" className='form-control' />
                                <small>สร้างรหัสผ่านให้ตัวแทน</small>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                            <button
                                ref={(ref) => { this.$submitBtn = window.$(ref) }}
                                type="submit"
                                className="btn btn-primary"
                            >สร้าง</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}