import React from 'react';
import { Link, navigate } from '@reach/router';
import { db } from '../firebase';
import { Helper } from './../Helper';
import CustomDateInput from './../Components/CustomDateInput';
import AddProviderSalaryImage from './../Components/AddProviderSalaryImage';
import DatePicker from "react-datepicker";
import { storage } from './../firebase';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProviderPaymentCard from '../Components/ProviderPaymentCard';
import AdjustForm from '../Components/AdjustForm'
import UrgentForm from './Books/UrgentForm';
import ChangeBookPriceForm from './Books/ChangeBookPriceForm';
import ReverseButton from './Books/include/ReverseButton';
import ReviewToProvider from './Books/include/ReviewToProvider';
const thailandJson = require('../thailand.json');
const axios = require('axios').default;
export default class ViewUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            booking: false,
            booking_id: props.booking_id,
            is_payment_loading: true,
            is_loading_time_plus: true,
        }
    }

    async componentDidMount() {
        this.fetch();
    }
    async fetch() {
        let qs = {};
        qs.bookingID = this.state.booking_id;
        let res = await axios.get(`${window.api_host}/booking-detail?${window.$.param(qs)}`);
        // console.log(res)
        let _booking = false;
        let payment = false;
        if (res.data.success) {
            _booking = res.data.booking;
            payment = res.data.payment;
        }
        this.setState({
            booking: _booking,
            payment: payment,
            is_loading: false,
            is_loading_time_plus: false,
        })
    }
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };
        let summary = Helper.bookingSummary({ booking: this.state.booking, payment: this.state.payment });
        // console.log(summary)
        let plustime_app_price_only = summary.plustime_app_price_only || 0;
        let plustime_price = summary.plustime_price;
        let plustime_total_price = summary.plustime_total_price;
        let plustime_app_price = summary.plustime_app_price;
        let plustime_provider_price = summary.plustime_provider_price;
        let hours = summary.hours;
        let total_price = summary.total_price;
        let wait_count = 0;
        let total_discount = summary.total_discount;

        if (this.state.booking?.priceUrgentProvider) {
            plustime_provider_price = parseFloat(plustime_provider_price) + parseFloat(this.state.booking.priceUrgentProvider)
        }
        // if (this.state.booking?.priceCancelApp) {
        //     plustime_app_price = parseFloat(plustime_app_price) + parseFloat(this.state.booking.priceCancelApp) + parseFloat(this.state.booking.priceUrgentVat)
        // }
        let totalAdjust = 0;
        let totalAdjustSpecialPrice = 0;
        let totalAdjustWithholdPrice = 0;
        if (this.state.booking && this.state.booking.adjustSalary) {
            for (const adj of this.state.booking.adjustSalary) {
                if (adj.type == 'special_wallet') {
                    totalAdjust += parseFloat(adj.amount)
                    totalAdjustSpecialPrice += parseFloat(adj.amount)
                }
                if (adj.type == 'withhold_wallet') {
                    totalAdjust -= parseFloat(adj.amount)
                    totalAdjustWithholdPrice += parseFloat(adj.amount)
                }
            }
        }
        let appPriceProvider = this.state.booking.priceProviderWithoutAgent || this.state.booking.priceProvider;
        let priceUrgentProvider = this.state.booking?.priceUrgentProvider ? parseFloat(this.state.booking?.priceUrgentProvider) : 0;
        let priceAgentCommissionFromProvider = this.state.booking?.priceAgentCommissionFromProvider || 0;
        if (this.state.booking.changeBookPrice) {
            appPriceProvider = this.state.booking.priceProvider;
        }
        let totalPriceProviderWithOutUrgent = appPriceProvider + parseFloat(priceAgentCommissionFromProvider)
        let totalPriceProvider = appPriceProvider + parseFloat(priceUrgentProvider) + parseFloat(priceAgentCommissionFromProvider)
        totalPriceProvider += totalAdjustSpecialPrice;
        totalPriceProvider -= totalAdjustWithholdPrice;

        plustime_provider_price += totalAdjustSpecialPrice;
        plustime_provider_price -= totalAdjustWithholdPrice;

        let appTotal = 0;
        let appGrandTotal = 0;
        let appPrice = 0;
        let appCommittion = 0;
        let appGrandTotalWithUrgent = 0;
        let appGrandTotalWithVat = 0;


        if (this.state.booking) {
            appPrice = this.state.booking.priceAppWithoutAgent || this.state.booking.priceApp
            if (this.state.booking.changeBookPrice) {
                appPrice = this.state.booking.priceApp;
            }
            if (this.state.booking?.priceAgentCommissionFromApp) {
                appCommittion = parseFloat(this.state.booking.priceAgentCommissionFromApp);
            }
            let discount = 0;
            if (this.state.booking?.priceDiscount) {
                discount = parseFloat(this.state.booking.priceDiscount);
            }
            let chargePrice = this.state.booking?.chargePrice > 0 ? this.state.booking?.chargePrice : 0
            appTotal = ((parseFloat(appPrice)) - parseFloat(appCommittion)) - discount;
            appGrandTotal = (parseFloat(appPrice) - parseFloat(appCommittion)) - discount;

            let priceUrgentApp = this.state.booking?.priceUrgentApp ? parseFloat(this.state.booking?.priceUrgentApp) : 0;
            if (this.state.booking?.priceUrgentVat) {
                priceUrgentApp += parseFloat(this.state.booking?.priceUrgentVat)
            }
            appGrandTotalWithVat = (appGrandTotal > 0 ? appGrandTotal : 0) + chargePrice
            appGrandTotalWithUrgent = appGrandTotalWithVat + priceUrgentApp + plustime_app_price_only
        }
        // if (appGrandTotalWithUrgent < 0) {
        //     appGrandTotalWithUrgent = 0;
        // }
        // if (appTotal < 0) {
        //     appTotal = 0;
        // }
        let isShowChangeProvider = this.state.booking.bookStatus === 'accept';
        let isShowUrgent = !this.state.booking.urgentAdminUid && (
            this.state.booking?.bookStatus == `accept`
            || this.state.booking?.bookStatus == `arrive`
            || this.state.booking?.bookStatus == `going`
            || this.state.booking?.bookStatus == `working`
        );
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ข้อมูล Booking </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/booking">Booking</Link></li>
                                    <li className="breadcrumb-item active">ข้อมูล Booking</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.booking ?
                                <>
                                    <div className={"row"}>
                                        <div className={"col-sm-12 col-lg-4"}>
                                            <div className="card">
                                                <div className="card-header" style={{ alignItems: 'center', display: 'flex' }}>
                                                    <h4 className="card-title" id="myModalLabel">Booking No : {this.state.booking.bookOn} {this.state.booking.createdAdmin ? <div className="badge badge-warning">สร้างจากระบบหลังบ้าน</div> : null}</h4>
                                                    <div style={{ marginLeft: 'auto' }} >

                                                        {
                                                            // this.state.booking.bookStatus === 'finish' && parseFloat(this.state.booking.starFromProvider) > 0 && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                            this.state.booking.paymentStatus == 'success'
                                                                && this.state.booking.providerUser?.provideruserDataUID ?
                                                                <>
                                                                    <Link to={'/invoice/' + this.props.booking_id} className='btn btn-default btn-sm mr-2'>ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ</Link>
                                                                </>
                                                                : null
                                                        }
                                                        <Link className='btn btn-default btn-sm' to={"/print-preview/" + this.props.booking_id}>พิมพ์</Link>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        this.state.booking.createdAdmin ?
                                                            <>
                                                                <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                                    <div><label>สร้างโดย</label> : {this.state.booking.createdAdmin.displayName}</div>
                                                                </div>
                                                                <div className="divider" />
                                                            </>
                                                            : null
                                                    }
                                                    <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                        <div><label>สถานะ</label> : {' '}
                                                            {
                                                                this.state.booking.bookStatus === 'accept' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "blue" }}></i>  ยอมรับ</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'arrive' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "purple" }}></i>  ถึงแล้ว</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'done' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "lightgreen" }}></i>  เสร็จสิ้น</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'finish' && parseFloat(this.state.booking.starFromProvider) === 0 && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "green" }}></i>  เสร็จงาน</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'finish' && parseFloat(this.state.booking.starFromProvider) > 0 && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <>
                                                                        <i className={"fas fa-circle"} style={{ color: "darkgreen" }}></i>  ส่งงาน
                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'going' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "orange" }}></i>  กำลังเดินทาง</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'wait' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "yellow" }}></i>  รอ</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'working' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "darkblue" }}></i>  กำลังทำงาน</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'cancel' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "red" }}></i>  ยกเลิก
                                                                        {this.state.booking.cancelRemark ? <div style={{ marginBottom: '0.5rem' }}>{this.state.booking.cancelRemark}</div> : null}
                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.paymentStatus === 'refund' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "red" }}></i>  คืนเงิน</>
                                                                    : null
                                                            }
                                                            {
                                                                this.props.is_super_admin && this.state.booking.bookStatus == 'finish' ?
                                                                    <span className="ml-2">
                                                                        <ReverseButton
                                                                            bookUid={this.state.booking_id}
                                                                            onSuccess={() => {
                                                                                console.log('success')
                                                                                this.fetch();
                                                                            }}
                                                                        />
                                                                    </span>
                                                                    : null
                                                            }
                                                        </div>
                                                        {
                                                            this.props.is_super_admin ?
                                                                <div style={{ marginLeft: 'auto', marginBottom: 5 }}>
                                                                    <button className="btn btn-danger btn-sm"
                                                                        ref={(ref) => {
                                                                            window.$(ref).on('click', () => {
                                                                                if (window.$(ref).hasClass('loading')) { return };
                                                                                window.$(ref).addClass('loading');
                                                                                Helper.confirmModal({
                                                                                    title: "ลบ Booking",
                                                                                    description: 'ยืนยันการลบ Booking : ' + this.state.booking.bookOn,
                                                                                    submit_text: 'ลบ',
                                                                                    onConfirm: async () => {
                                                                                        try {
                                                                                            await axios.post(window.api_host + '/books-delete', {
                                                                                                booksUid: this.state.booking.id,
                                                                                                code: 'imsuretodeleteit'
                                                                                            }).then(async (res) => {
                                                                                                if (res.data.code === 200) {
                                                                                                    window.$(document).Toasts('create', {
                                                                                                        title: 'ลบ Booking : ' + this.state.booking.bookOn + 'สำเร็จ',
                                                                                                        icon: 'fas fa-check',
                                                                                                        autohide: true,
                                                                                                        delay: 3000,
                                                                                                        class: "bg-success",
                                                                                                    })
                                                                                                    window.$(ref).removeClass('loading')
                                                                                                    navigate('/booking')
                                                                                                }
                                                                                            })
                                                                                        } catch (error) {
                                                                                            window.$(document).Toasts('create', {
                                                                                                title: 'ลบ Booking ไม่สำเร็จ',
                                                                                                // body: "ยกเลิกการชำระเงินไม่สำเร็จ",
                                                                                                icon: 'fas fa-times',
                                                                                                autohide: true,
                                                                                                delay: 3000,
                                                                                                class: "bg-danger",
                                                                                            })
                                                                                            window.$(ref).removeClass('loading')
                                                                                        }
                                                                                    },
                                                                                    onCancel: () => {
                                                                                        window.$(ref).removeClass('loading')
                                                                                    }
                                                                                })
                                                                            })
                                                                        }}
                                                                    >
                                                                        ลบ
                                                                    </button>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                    {
                                                        this.state.booking.updateAdmin ?
                                                            <div className={"flex row"}>
                                                                <div>
                                                                    <label>แก้ไขล่าสุด</label> : {' '} {this.state.booking.updateAdmin.displayName}
                                                                </div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {Helper.getDateThai(this.state.booking.updateAdmin.updatedAt, { is_show_time: true })}
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.booking.cancelAdmin ?
                                                            <div className={"flex row"}>
                                                                <div>
                                                                    <label>ผู้ยกเลิก</label> : {' '} {this.state.booking.cancelAdmin.displayName}
                                                                </div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {Helper.getDateThai(this.state.booking.cancelAt, { is_show_time: true })}
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                    <div className={"divider"} />
                                                    <div className={"flex row"}>
                                                        <div><label>สมาชิก</label> : <Link to={'/user/' + this.state.booking.userUid}>{this.state.booking.user.displayName}</Link> </div>
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            {this.state.booking.user && this.state.booking.user.phoneNumber ?
                                                                <><i className={"fas fa-phone"} /> {this.state.booking.user.phoneNumber.replace('+66', '0')}</>
                                                                : null
                                                            }
                                                            {this.state.booking.user && this.state.booking.user.guestPhoneNumber && !this.state.booking.user.phoneNumber ?
                                                                <><i className={"fas fa-phone"} /> {this.state.booking.user.guestPhoneNumber.replace('+66', '0')}</>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={"flex row"}>
                                                        <div><label>แม่บ้าน</label> :  {this.state.booking.providerUser.provideruserDataUID ?
                                                            <Link to={'/provider-users/' + this.state.booking.providerUser.provideruserDataUID}>
                                                                {this.state.booking.providerUser.displayName + ' '}
                                                            </Link>
                                                            : this.state.booking.providerUser.displayName ? this.state.booking.providerUser.displayName + ' ' : null}
                                                            {
                                                                !this.state.booking.providerUser.provideruserDataUID && this.state.booking.paymentStatus === 'success' ?
                                                                    <span className="badge badge-warning salary-custom-badge" style={{ marginRight: 5 }}>กำลังค้นหางาน</span>
                                                                    : null
                                                            }
                                                            {
                                                                !this.state.booking.providerUser.provideruserDataUID && this.state.booking.paymentStatus === 'wait' ?
                                                                    <span className="badge badge-info salary-custom-badge" style={{ marginRight: 5 }}>รอชำระเงิน</span>
                                                                    : null
                                                            }
                                                            {
                                                                !this.state.booking.providerUser.provideruserDataUID && this.state.booking.paymentStatus === 'fail' ?
                                                                    <span className="badge badge-danger salary-custom-badge" style={{ marginRight: 5 }}>ชำระเงินไม่สำเร็จ</span>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'wait' && this.state.booking.paymentStatus === 'success' ?
                                                                    <button className="btn btn-sm btn-default"
                                                                        style={{ marginBottom: '0.5rem' }}
                                                                        onClick={() => {
                                                                            Helper.reactToDom(
                                                                                window.$('body'),
                                                                                <AssignProvider
                                                                                    bookUid={this.state.booking.id}
                                                                                    onSuccess={() => {
                                                                                        this.setState({
                                                                                            is_loading: true
                                                                                        }, () => {
                                                                                            this.fetch()
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }}
                                                                    >
                                                                        มอบหมายแม่บ้าน
                                                                    </button>
                                                                    : null
                                                            }
                                                        </div>
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            {
                                                                !this.state.booking.providerUser.provideruserDataUID
                                                                    && (this.state.booking.paymentStatus === 'fail'
                                                                        || this.state.booking.paymentStatus === 'wait') ?
                                                                    <div style={{ marginBottom: '0.5rem' }}>
                                                                        <button className="btn btn-danger btn-sm"
                                                                            ref={(ref) => {
                                                                                window.$(ref).on('click', () => {
                                                                                    if (window.$(ref).hasClass('loading')) { return; }
                                                                                    window.$(ref).addClass('loading')
                                                                                    Helper.confirmModal({
                                                                                        title: 'ยกเลิก Booking',
                                                                                        description: 'ต้องการยกเลิก Booking #' + this.state.booking.bookOn + ' หรือไม่ ?',
                                                                                        onConfirm: async () => {
                                                                                            try {
                                                                                                await axios.post(window.api_host + '/books-cancel', {
                                                                                                    bookUid: this.state.booking.id
                                                                                                }).then(async (res) => {
                                                                                                    let _data = res.data;
                                                                                                    window.$(document).Toasts('create', {
                                                                                                        title: 'ยกเลิก Booking สำเร็จ',
                                                                                                        // body: 'ทำการลบข้อมูลสำเร็จ',
                                                                                                        icon: 'fas fa-check',
                                                                                                        autohide: true,
                                                                                                        delay: 3000,
                                                                                                        class: "bg-success",
                                                                                                    })

                                                                                                    let update = {
                                                                                                        cancelAdminUid: this.props.adminUid,
                                                                                                        cancelAt: new Date().getTime(),
                                                                                                    }
                                                                                                    // if (_data.message === 'success cancel with cannot payment') {
                                                                                                    //     update.bookStatus = `cancel`;
                                                                                                    //     update.paymentStatus = `cancel`
                                                                                                    // }
                                                                                                    let _res = await axios.put(`${window.api_host}/booking`, {
                                                                                                        id: this.state.booking.id,
                                                                                                        update: update,
                                                                                                    });
                                                                                                    if (_res.data.success) {
                                                                                                        this.setState({
                                                                                                            is_loading: true,
                                                                                                        }, () => {
                                                                                                            this.fetch();
                                                                                                        })
                                                                                                    }

                                                                                                    // await db.doc('/books/' + this.state.booking.id).update();
                                                                                                    // this.fetch();
                                                                                                })
                                                                                            } catch (error) {
                                                                                                console.log(error)
                                                                                                window.$(document).Toasts('create', {
                                                                                                    title: 'ยกเลิก Bookingไม่สำเร็จ',
                                                                                                    // body: 'ทำการลบข้อมูลสำเร็จ',
                                                                                                    icon: 'fas fa-times',
                                                                                                    autohide: true,
                                                                                                    delay: 3000,
                                                                                                    class: "bg-danger",
                                                                                                })

                                                                                                window.$(ref).removeClass('loading');
                                                                                                return;
                                                                                            }
                                                                                        },
                                                                                        onCancel: () => {
                                                                                            window.$(ref).removeClass('loading');
                                                                                        }
                                                                                    })
                                                                                })
                                                                            }}
                                                                        >
                                                                            ยกเลิก
                                                                        </button>
                                                                    </div>
                                                                    : null
                                                            }
                                                            {this.state.booking.providerUser && this.state.booking.providerUser.phoneNumber ?
                                                                <>
                                                                    <i className={"fas fa-phone"} /> {this.state.booking.providerUser.phoneNumber.replace('+66', '0')}
                                                                </>
                                                                : null
                                                            }

                                                        </div>
                                                    </div>

                                                    {
                                                        this.state.booking?.providerUser?.agent ?
                                                            <>
                                                                <div >

                                                                    <label>ตัวแทน </label> :  <a href={`${window.location.origin}/agent/${this.state.booking?.providerUser?.agentUid}`} target={"_blank"}> {this.state.booking?.providerUser?.agent.name}</a>
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        isShowChangeProvider || isShowUrgent ?
                                                            <div style={{ marginBottom: '0.5rem' }}>
                                                                {
                                                                    isShowChangeProvider ?
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-default"
                                                                            onClick={() => {
                                                                                Helper.reactToDom(window.$('body'),
                                                                                    <ChangeProviderUser
                                                                                        providerUser={this.state.booking.providerUser}
                                                                                        providerUserUid={this.state.booking.providerUser.uid}
                                                                                        providerUserPhoneNumber={this.state.booking.providerUser.phoneNumber}
                                                                                        bookUid={this.state.booking.id}
                                                                                        adminUid={this.props.adminUid}
                                                                                        onSuccess={() => {
                                                                                            this.setState({
                                                                                                is_loading: true,
                                                                                            }, () => {
                                                                                                this.fetch();
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        >
                                                                            เปลี่ยนแม่บ้าน
                                                                        </button>
                                                                        : null
                                                                }
                                                                {
                                                                    isShowUrgent ?
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-default"
                                                                            style={{ marginLeft: isShowChangeProvider ? 15 : 0 }}
                                                                            onClick={() => {
                                                                                Helper.reactToDom(window.$('body'),
                                                                                    <UrgentForm
                                                                                        bookUid={this.state.booking.id}
                                                                                        urgent={this.state.booking.urgent}
                                                                                        adminUid={this.props.adminUid}
                                                                                        onSuccess={() => {
                                                                                            this.setState({
                                                                                                is_loading: true,
                                                                                            }, () => {
                                                                                                this.fetch();
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        >
                                                                            ชั่วโมงเร่งด่วน
                                                                        </button>
                                                                        : null
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    <div className={"divider"} />
                                                    <div>
                                                        <label>ระยะเวลาที่ให้บริการ</label> :  {' '}
                                                        {this.state.booking.address && this.state.booking.bookTime && this.state.booking.bookTime.name_th ?
                                                            this.state.booking.bookTime.name_th
                                                            : null
                                                        }
                                                    </div>
                                                    {
                                                        this.state.booking.bookStatus === 'working' ?
                                                            <div style={{ marginBottom: '0.5rem' }}>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-default"
                                                                    onClick={() => {
                                                                        Helper.reactToDom(window.$('body'),
                                                                            <ChangeBookTime
                                                                                bookTimeUid={this.state.booking.bookTimeUid.id}
                                                                                bookUid={this.state.booking.id}
                                                                                adminUid={this.props.adminUid}
                                                                                // isForceSuccess={this.state.booking.createdAdmin ? true : false}
                                                                                // isForceSuccess={true}
                                                                                onSuccess={() => {
                                                                                    this.setState({
                                                                                        is_loading: true
                                                                                    }, () => {
                                                                                        this.fetch();
                                                                                    })
                                                                                }}
                                                                            />
                                                                        );
                                                                    }}
                                                                >
                                                                    เพิ่มชั่วโมงทำงาน
                                                                </button>
                                                            </div>
                                                            : null
                                                    }
                                                    <div>
                                                        <label>วันเวลาที่จอง</label> :  {' '}
                                                        {
                                                            this.state.booking.createdAt ?
                                                                Helper.getDateThai(this.state.booking.createdAt, { is_show_time: true }) : null
                                                        }
                                                    </div>
                                                    <div>
                                                        <label>วันเวลาที่ให้บริการ</label> :  {' '}
                                                        {
                                                            this.state.booking.bookedAt ?
                                                                Helper.getDateThai(this.state.booking.bookedAt, { is_show_time: true }) : null
                                                        }
                                                        {
                                                            ((this.state.booking.bookStatus === 'wait'
                                                                || this.state.booking.bookStatus === 'accept'
                                                                || this.state.booking.bookStatus === 'going'
                                                                || this.state.booking.bookStatus === 'arrive')
                                                                && this.state.booking.paymentStatus === 'success')
                                                                || (this.state.booking.bookHistoryChangeTime
                                                                    && this.state.booking.bookHistoryChangeTime.length > 0) ?
                                                                <div style={{ fontSize: 18, marginBottom: "0.5rem" }}>
                                                                    {
                                                                        (this.state.booking.bookStatus === 'wait'
                                                                            || this.state.booking.bookStatus === 'accept'
                                                                            || this.state.booking.bookStatus === 'going'
                                                                            || this.state.booking.bookStatus === 'arrive')
                                                                            && this.state.booking.paymentStatus === 'success' ?
                                                                            <button className={"btn btn-sm btn-default"}
                                                                                style={{ marginRight: 5 }}
                                                                                onClick={() => {
                                                                                    Helper.reactToDom(
                                                                                        window.$('body'),
                                                                                        <BookingChangeDateTime
                                                                                            data={this.state.booking}
                                                                                            adminUid={this.props.adminUid}
                                                                                            onSuccess={() => {
                                                                                                this.setState({
                                                                                                    is_loading: true,
                                                                                                }, () => {
                                                                                                    this.fetch();
                                                                                                })
                                                                                            }}
                                                                                        />
                                                                                    );
                                                                                }}
                                                                            >
                                                                                เปลี่ยนวันเวลาที่ให้บริการ
                                                                            </button>
                                                                            : null
                                                                    }
                                                                    {
                                                                        this.state.booking.bookHistoryChangeTime
                                                                            && this.state.booking.bookHistoryChangeTime.length > 0 ?
                                                                            <button className={"btn btn-sm btn-default"}
                                                                                onClick={() => {
                                                                                    Helper.reactToDom(
                                                                                        window.$('body'),
                                                                                        <BookingDateTimeList
                                                                                            data={this.state.booking.bookHistoryChangeTime}
                                                                                        />
                                                                                    );
                                                                                }}
                                                                            >
                                                                                ประวัติการเปลี่ยนวันเวลาที่ให้บริการ
                                                                            </button>
                                                                            : null
                                                                    }
                                                                </div>
                                                                : null
                                                        }

                                                    </div>
                                                    <div className={"divider"} />
                                                    <div>
                                                        {this.state.booking.address
                                                            && this.state.booking.address.name ?
                                                            <div><label>ชื่อสถานที่ : </label> {this.state.booking.address.name}</div>
                                                            : null
                                                        }
                                                        {this.state.booking.address
                                                            && this.state.booking.address.placeType
                                                            && this.state.booking.address.placeType.name_th ?
                                                            <div><label>ประเภท : </label> {this.state.booking.address.placeType.name_th}</div>
                                                            : null
                                                        }
                                                        {this.state.booking.address
                                                            && this.state.booking.address.placeType
                                                            && this.state.booking.address.placeType.place_sub_type
                                                            && this.state.booking.address.placeType.place_sub_type.description_th ?
                                                            <div><label>พืนที่ : </label> {this.state.booking.address.placeType.place_sub_type.description_th}</div>
                                                            : null
                                                        }
                                                        {this.state.booking.address
                                                            && this.state.booking.address.phoneNumber ?
                                                            <div><label>เบอร์โทรศัพท์ : </label> {this.state.booking.address.phoneNumber.replace('+66', '0')}</div>
                                                            : null
                                                        }

                                                        {this.state.booking.address
                                                            && this.state.booking.address.province
                                                            && this.state.booking.address.amphoe ?
                                                            <div>{this.state.booking.address.addressDetail}
                                                                {this.state.booking.address.province == '10' ? " เขต" : " อำเภอ"}
                                                                {thailandJson[this.state.booking.address.province].amphoes[this.state.booking.address.amphoe].name.th}
                                                                {" จังหวัด"}{thailandJson[this.state.booking.address.province].name.th} {" "} {this.state.booking.address.note}
                                                                {/* {this.state.booking.address.address} */}
                                                            </div>
                                                            : this.state.booking.address && this.state.booking.address.address ? this.state.booking.address.address
                                                                : null
                                                        }
                                                        {
                                                            this.state.booking.address
                                                                && this.state.booking.address.coordinate
                                                                && this.state.booking.address.coordinate._latitude
                                                                && this.state.booking.address.coordinate._longitude ?
                                                                <>
                                                                    <a href={"https://maps.google.com?q=" + this.state.booking.address.coordinate._latitude + ',' + this.state.booking.address.coordinate._longitude}
                                                                        className={"btn btn-default"}
                                                                        target="_blank"
                                                                        rel='noopener noreferrer'
                                                                    >
                                                                        ดูสถานที่
                                                                    </a>
                                                                </>
                                                                : null
                                                        }
                                                        {
                                                            this.state.booking.bookStatus == 'wait' || this.state.booking.bookStatus == 'accept' ?
                                                                <Link to={`/edit-location/${this.state.booking.userUid}/place/${this.state.booking.placeUid}?bookUid=${this.state.booking_id}`}
                                                                    className={"btn btn-default ml-3"}
                                                                    target="_blank"
                                                                    rel='noopener noreferrer'
                                                                >
                                                                    แก้ไข
                                                                </Link>
                                                                : null
                                                        }
                                                        <div className={"divider mt-2"} />
                                                        <div className='mt-1'>
                                                            <label>หมายเหตุ : </label> {this.state.booking.note ? this.state.booking.note : '-'} {' '}
                                                            <small className='link'
                                                                onClick={() => {
                                                                    Helper.reactToDom(
                                                                        window.$('body'),
                                                                        <BookingEditNote
                                                                            note={this.state.booking && this.state.booking.note ? this.state.booking.note : ''}
                                                                            onSuccess={() => {
                                                                                this.setState({
                                                                                    is_loading: true,
                                                                                }, () => {
                                                                                    this.fetch();
                                                                                })
                                                                            }}
                                                                            bookUid={this.state.booking_id}
                                                                        />
                                                                    )
                                                                }}
                                                            >
                                                                แก้ไข
                                                            </small>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.booking.acceptAt
                                                    || this.state.booking.goingAt
                                                    || this.state.booking.arriveAt
                                                    || this.state.booking.workingAt
                                                    || ((this.state.booking.bookStatus === 'done'
                                                        || this.state.booking.bookStatus === 'finish')
                                                        && this.state.booking.doneAt)
                                                    || this.state.booking.finishAt ?
                                                    <div className="card">
                                                        <div className="card-header" style={{ alignItems: 'center' }}>
                                                            <h4 className="card-title" id="myModalLabel">สถานะและเวลาการทำงาน</h4>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-minus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className={"timeline"}>
                                                                {
                                                                    this.state.booking.acceptAt ?
                                                                        <div>
                                                                            <i className="far fa-handshake bg-blue"></i>
                                                                            <div className="timeline-item">
                                                                                <span className="time">
                                                                                    <div><i className="far fa-clock"></i> {Helper.getDateThai(this.state.booking.acceptAt, { is_show_time: true })}</div>
                                                                                    {
                                                                                        this.state.booking.acceptAdmin ?
                                                                                            <div><i className="fas fa-user-cog"></i> {this.state.booking.acceptAdmin.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                                <h3 className="timeline-header no-border">ยอมรับ</h3>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    this.state.booking.goingAt ?
                                                                        <div>
                                                                            <i className="fas fa-road bg-yellow"></i>
                                                                            <div className="timeline-item">
                                                                                <span className="time">
                                                                                    <div><i className="far fa-clock"></i> {!this.state.booking.goingAdmin ? Helper.getDateThai(this.state.booking.goingAt - (7 * 3600 * 1000), { is_show_time: true }) : Helper.getDateThai(this.state.booking.goingAt, { is_show_time: true })}</div>
                                                                                    {
                                                                                        this.state.booking.goingAdmin ?
                                                                                            <div><i className="fas fa-user-cog"></i> {this.state.booking.goingAdmin.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                                <h3 className="timeline-header no-border">เริ่มเดินทาง</h3>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    this.state.booking.arriveAt ?
                                                                        <div>
                                                                            <i className="fas fa-map-marked-alt" style={{ background: 'darkblue', color: '#fff' }}></i>
                                                                            <div className="timeline-item">
                                                                                <span className="time">
                                                                                    <div><i className="far fa-clock"></i> {!this.state.booking.arriveAdmin ? Helper.getDateThai(this.state.booking.arriveAt - (7 * 3600 * 1000), { is_show_time: true }) : Helper.getDateThai(this.state.booking.arriveAt, { is_show_time: true })}</div>
                                                                                    {
                                                                                        this.state.booking.arriveAdmin ?
                                                                                            <div><i className="fas fa-user-cog"></i> {this.state.booking.arriveAdmin.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                                <h3 className="timeline-header no-border">ถึง</h3>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    this.state.booking.workingAt ?
                                                                        <div>
                                                                            <i className="fas fa-broom" style={{ background: 'purple', color: '#fff' }}></i>
                                                                            <div className="timeline-item">
                                                                                <span className="time">
                                                                                    <div><i className="far fa-clock"></i> {!this.state.booking.workingAdmin ? Helper.getDateThai(this.state.booking.workingAt - (7 * 3600 * 1000), { is_show_time: true }) : Helper.getDateThai(this.state.booking.workingAt, { is_show_time: true })}</div>
                                                                                    {
                                                                                        this.state.booking.workingAdmin ?
                                                                                            <div><i className="fas fa-user-cog"></i> {this.state.booking.workingAdmin.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                                <h3 className="timeline-header no-border">เริ่มทำงาน</h3>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (this.state.booking.bookStatus === 'done'
                                                                        || this.state.booking.bookStatus === 'finish')
                                                                        && this.state.booking.doneAt ?
                                                                        <div>
                                                                            <i className="fas fa-hand-sparkles" style={{ background: 'lightgreen', color: '#000' }}></i>
                                                                            <div className="timeline-item">
                                                                                <span className="time">
                                                                                    <div><i className="far fa-clock"></i> {!this.state.booking.doneAdmin ? Helper.getDateThai(this.state.booking.doneAt - (7 * 3600 * 1000), { is_show_time: true }) : Helper.getDateThai(this.state.booking.doneAt, { is_show_time: true })}</div>
                                                                                    {
                                                                                        this.state.booking.doneAdmin ?
                                                                                            <div><i className="fas fa-user-cog"></i> {this.state.booking.doneAdmin.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                                <h3 className="timeline-header no-border">เสร็จงาน</h3>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    this.state.booking.finishAt ?
                                                                        <div>
                                                                            <i className="fas fa-check bg-green"></i>
                                                                            <div className="timeline-item">
                                                                                <span className="time">
                                                                                    {/* <div><i className="far fa-clock"></i> {!this.state.booking.finishAdmin ? Helper.getDateThai(this.state.booking.finishAt - (7 * 3600 * 1000), { is_show_time: true }) : Helper.getDateThai(this.state.booking.finishAt, { is_show_time: true })}</div> */}
                                                                                    <div><i className="far fa-clock"></i> {Helper.getDateThai(this.state.booking.finishAt - (7 * 3600 * 1000), { is_show_time: true })}</div>
                                                                                    {
                                                                                        this.state.booking.finishAdmin ?
                                                                                            <div><i className="fas fa-user-cog"></i> {this.state.booking.finishAdmin.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                                <h3 className="timeline-header no-border">ส่งงาน</h3>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div>
                                                                    <i className="fas fa-clock bg-gray"></i>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                        </div>
                                        <div className={"col-sm-12 col-lg-4"}>
                                            {
                                                this.state.payment
                                                    && (this.state.booking.paymentStatus === "successful"
                                                        || this.state.booking.paymentStatus === "success"
                                                        || this.state.booking.paymentStatus === "finish") ?
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title" id="myModalLabel">สรุปผลค่าบริการ</h4>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-minus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div>
                                                                <div className={"flex row"}>
                                                                    <div><label>ค่าบริการเรียกเก็บจาก สมาชิก</label></div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {parseFloat(plustime_total_price) > 0 ? Helper.numberFormat(plustime_total_price, { decimal: 2 }) : 0} บาท
                                                                    </div>
                                                                </div>
                                                                <div className={"flex row"}>
                                                                    <div><label>ค่าบริการที่แม่บ้านได้รับ</label></div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {parseFloat(plustime_provider_price) > 0 ? Helper.numberFormat(plustime_provider_price, { decimal: 2 }) : 0} บาท
                                                                    </div>
                                                                </div>
                                                                <div className={"flex row"}>
                                                                    <div><label>ค่าบริการที่ APP ได้รับ</label> </div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {parseFloat(appGrandTotalWithUrgent) > 0 ? Helper.numberFormat(appGrandTotalWithUrgent, { decimal: 2 }) : 0} บาท
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                this.state.booking
                                                    && this.state.booking.adjustSalary
                                                    && this.state.booking.adjustSalary.length > 0 ?
                                                    <>
                                                        <AdjustSalaryInfo
                                                            booking={this.state.booking}
                                                        />
                                                    </>
                                                    : null
                                            }
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title" id="myModalLabel">ค่าบริการ</h4>
                                                    <div className="card-tools">
                                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                            <i className="fas fa-minus"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div>
                                                        <div className={"flex row"}>
                                                            <div><label>ค่าบริการเรียกเก็บจาก สมาชิก</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {parseFloat(this.state.booking.price) > 0 ? Helper.numberFormat(this.state.booking.price, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ส่วนลด</label> {this.state.booking.promotion ? <>({this.state.booking.promotion.voucherCode})</> : null}</div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                -{parseFloat(total_discount) > 0 ? Helper.numberFormat(total_discount, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ค่าบริการหลังหักส่วนลด</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {parseFloat(this.state.booking.price) - parseFloat(this.state.booking.priceDiscount) > 0 ? Helper.numberFormat((this.state.booking.price - this.state.booking.priceDiscount), { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>อื่นๆ</label>
                                                                {
                                                                    this.state.booking.isHavePet || this.state.booking.isUseBeeClean ?
                                                                        <> ({this.state.booking.isHavePet ? "มีสัตว์เลี้ยง" : null}
                                                                            {this.state.booking.isHavePet && this.state.booking.isUseBeeClean ? "," : null}
                                                                            {this.state.booking.isUseBeeClean ? "ผลิตภัณฑ์จาก BeeClean" : null}
                                                                            )</>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(0, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ภาษี 7%</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {parseFloat(this.state.booking.chargePrice) > 0 ? Helper.numberFormat(this.state.booking.chargePrice, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>รวม</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {total_price > 0 ? Helper.numberFormat(total_price, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"divider"} />
                                                    <div>
                                                        <div className={"flex row"}>
                                                            <div><label>ค่าบริการที่แม่บ้านได้รับ</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {
                                                                    this.state.booking.bookStatus == 'cancel'
                                                                        || this.state.booking.bookStatus == 'wait'
                                                                        || this.state.booking.paymentStatus == 'refund'
                                                                        || this.state.booking.paymentStatus == 'reverce'
                                                                        || (this.state.booking.bookStatus === 'finish' && parseFloat(this.state.booking.starFromProvider) > 0 && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce') ?
                                                                        null :
                                                                        <span className='link'
                                                                            onClick={() => {
                                                                                Helper.reactToDom(window.$('body'),
                                                                                    <ChangeBookPriceForm
                                                                                        bookUid={this.state.booking.id}
                                                                                        adminUid={this.props.adminUid}
                                                                                        booking={{
                                                                                            ...this.state.booking,
                                                                                            appPrice: appPrice,
                                                                                            plustime_total_price: plustime_total_price,
                                                                                            appPriceProvider: appPriceProvider,
                                                                                        }}
                                                                                        summary={summary}
                                                                                        onSuccess={() => {
                                                                                            this.setState({
                                                                                                is_loading: true,
                                                                                            }, () => {
                                                                                                this.fetch();
                                                                                            })
                                                                                        }}
                                                                                    />)
                                                                            }}
                                                                        > แก้ไข </span>

                                                                }
                                                                {Helper.numberFormat(appPriceProvider, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.booking?.providerUser?.agent ?
                                                                <>
                                                                    <div className={"flex row"}>
                                                                        <div><label >ค่าคอมมิชชั่นนายหน้า <small>{Helper.numberFormat(this.state.booking.agentCommissionFromProviderPercentage)}%</small></label></div>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            {Helper.numberFormat((this.state.booking.priceAgentCommissionFromProvider), { decimal: 2 })} บาท
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : null
                                                        }

                                                        {
                                                            this.state.booking?.priceUrgentProvider ?
                                                                <div className={"flex row"}>
                                                                    <div><label>เร่งด่วน</label></div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {Helper.numberFormat(this.state.booking?.priceUrgentProvider, { decimal: 2 })} บาท
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                        <div className={"flex row"}>
                                                            <div><label>อื่นๆ</label>
                                                                {
                                                                    this.state.booking.isHavePet || this.state.booking.isUseBeeClean ?
                                                                        <> ({this.state.booking.isHavePet ? "มีสัตว์เลี้ยง" : null}
                                                                            {this.state.booking.isHavePet && this.state.booking.isUseBeeClean ? "," : null}
                                                                            {this.state.booking.isUseBeeClean ? "ผลิตภัณฑ์จาก BeeClean" : null}
                                                                            )
                                                                        </>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(0, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        {
                                                            totalAdjustSpecialPrice ?
                                                                <>
                                                                    <div className={"flex row"}>
                                                                        <div><label>เร่งด่วน</label></div>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            {Helper.numberFormat(totalAdjustSpecialPrice, { decimal: 2 })} บาท
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : null
                                                        }
                                                        {
                                                            totalAdjustWithholdPrice ?
                                                                <>
                                                                    <div className={"flex row"}>
                                                                        <div><label>หักเงิน</label></div>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            -{Helper.numberFormat(totalAdjustWithholdPrice, { decimal: 2 })} บาท
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : null
                                                        }
                                                        <div className={"flex row"}>
                                                            <div><label>รวม</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(totalPriceProvider, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        {/* <div className={"flex row"}>
                                                            <div><label >รวมทั้งหมด <small>หลังปรับรายได้</small></label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(totalPriceProvider, { decimal: 2 })} บาท
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className={"divider"} />
                                                    <div>
                                                        <div className={"flex row"}>
                                                            <div><label>ค่าบริการที่ App ได้รับ</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {
                                                                    appPrice > 0 ? Helper.numberFormat(appPrice, { decimal: 2 }) : 0
                                                                } บาท
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.booking?.providerUser?.agent ?
                                                                <>
                                                                    <div className={"flex row"}>
                                                                        <div><label >ค่าคอมมิชชั่นนายหน้า <small>{Helper.numberFormat(this.state.booking.agentCommissionFromAppPercentage)}%</small></label></div>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            {Helper.numberFormat((this.state.booking.priceAgentCommissionFromApp), { decimal: 2 })} บาท
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : null
                                                        }
                                                        <div className={"flex row"}>
                                                            <div><label>ส่วนลด</label> {this.state.booking.promotion ? <>({this.state.booking.promotion.voucherCode})</> : null}</div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                -{parseFloat(total_discount) > 0 ? Helper.numberFormat(total_discount, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ส่วนต่างที่ APP ได้รับ</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {
                                                                    appTotal > 0 ? Helper.numberFormat(appTotal, { decimal: 2 })
                                                                        : appTotal < 0 ? `0 (${Helper.numberFormat(appTotal, { decimal: 2 })})`
                                                                            : 0
                                                                } บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>อื่นๆ</label>
                                                                {
                                                                    this.state.booking.isHavePet || this.state.booking.isUseBeeClean ?
                                                                        <> ({this.state.booking.isHavePet ? "มีสัตว์เลี้ยง" : null}
                                                                            {this.state.booking.isHavePet && this.state.booking.isUseBeeClean ? "," : null}
                                                                            {this.state.booking.isUseBeeClean ? "ผลิตภัณฑ์จาก BeeClean" : null}
                                                                            )
                                                                        </>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(0, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ภาษี 7% จากสมาชิก</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {this.state.booking.chargePrice > 0 ? Helper.numberFormat(this.state.booking.chargePrice, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>รวม</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {
                                                                    appGrandTotalWithVat > 0 ? Helper.numberFormat(appGrandTotalWithVat, { decimal: 2 })
                                                                        : 0
                                                                } บาท
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.booking?.priceUrgentApp ?
                                                                <div className={"flex row"}>
                                                                    <div><label>เร่งด่วนรวมภาษี</label></div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {Helper.numberFormat((this.state.booking?.priceUrgentApp + this.state.booking?.priceUrgentVat), { decimal: 2 })} บาท
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                        <div className={"flex row"}>
                                                            <div><label>รวมทั้งหมด</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(appGrandTotalWithUrgent, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ProviderPaymentCard
                                                payment={this.state.payment}
                                                booking={this.state.booking}
                                                summary={summary}
                                                onCheclOmiseSuccess={() => {
                                                    this.setState({
                                                        is_loading: true,
                                                    }, () => {
                                                        this.fetch();
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className={"col-sm-12 col-lg-4"}>
                                            {
                                                this.state.booking.bookStatus === 'wait'
                                                    && this.state.booking.channel == 'omise' ?
                                                    <div style={{ marginBottom: '0.5rem' }}>
                                                        <button className="btn btn-danger"
                                                            style={{ width: '100%' }}
                                                            ref={(ref) => {
                                                                window.$(ref).on('click', () => {
                                                                    if (window.$(ref).hasClass('loading')) { return; }
                                                                    window.$(ref).addClass('loading')
                                                                    Helper.confirmModal({
                                                                        title: 'ยกเลิก Booking',
                                                                        description: 'ต้องการยกเลิก Booking #' + this.state.booking.bookOn + ' หรือไม่ ?',
                                                                        onConfirm: async () => {
                                                                            try {
                                                                                await axios.post(window.api_host + '/books-cancel', {
                                                                                    bookUid: this.state.booking.id
                                                                                }).then(async (res) => {
                                                                                    let _data = res.data;
                                                                                    let update = {
                                                                                        cancelAdminUid: this.props.adminUid,
                                                                                        cancelAt: new Date().getTime(),
                                                                                    }
                                                                                    let _res = await axios.put(`${window.api_host}/booking`, {
                                                                                        id: this.state.booking.id,
                                                                                        update: update,
                                                                                    });
                                                                                    if (_res.data.success) {
                                                                                        window.$(document).Toasts('create', {
                                                                                            title: 'ยกเลิก Booking สำเร็จ',
                                                                                            // body: 'ทำการลบข้อมูลสำเร็จ',
                                                                                            icon: 'fas fa-check',
                                                                                            autohide: true,
                                                                                            delay: 3000,
                                                                                            class: "bg-success",
                                                                                        })
                                                                                        this.setState({
                                                                                            is_loading: true,
                                                                                        }, () => {
                                                                                            this.fetch();
                                                                                        })
                                                                                    }


                                                                                })
                                                                            } catch (error) {
                                                                                console.log(error)
                                                                                window.$(document).Toasts('create', {
                                                                                    title: 'ยกเลิก Bookingไม่สำเร็จ',
                                                                                    // body: 'ทำการลบข้อมูลสำเร็จ',
                                                                                    icon: 'fas fa-times',
                                                                                    autohide: true,
                                                                                    delay: 3000,
                                                                                    class: "bg-danger",
                                                                                })

                                                                                window.$(ref).removeClass('loading');
                                                                                return;
                                                                            }
                                                                        },
                                                                        onCancel: () => {
                                                                            window.$(ref).removeClass('loading');
                                                                        }
                                                                    })
                                                                })
                                                            }}
                                                        >
                                                            ยกเลิก
                                                        </button>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                (this.state.booking.bookStatus === 'accept'
                                                    || this.state.booking.bookStatus === 'going'
                                                    || this.state.booking.bookStatus === 'arrive'
                                                    || this.state.booking.bookStatus === 'working'
                                                    || (this.state.booking.bookStatus === 'wait' && this.state.booking.channel != 'omise')
                                                )
                                                    && this.state.booking.paymentStatus === 'success' ?
                                                    <div style={{ marginLeft: 'auto', fontSize: 18, width: '100%', marginBottom: 15 }}>
                                                        <button className={"btn btn-danger"}
                                                            style={{ width: '100%' }}
                                                            onClick={() => {
                                                                Helper.reactToDom(
                                                                    window.$('body'),
                                                                    <BookingDetails
                                                                        admin={this.props.admin}
                                                                        data={this.state.booking}
                                                                        onCancel={() => {
                                                                            this.setState({
                                                                                is_loading: true,
                                                                            }, () => {
                                                                                this.fetch();
                                                                            })
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        >
                                                            {
                                                                this.state.booking.bookStatus === 'wait' && this.state.booking.channel ?
                                                                    `ยกเลิก`
                                                                    : `คืนเงิน`
                                                            }

                                                        </button>
                                                    </div>
                                                    : null
                                            }
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title" id="myModalLabel">ข้อมูลการรายงาน</h4>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        (this.state.booking.bookStatus === 'finish' && parseFloat(this.state.booking.starFromProvider) > 0 && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce')
                                                            && this.state.booking.photoURLs ?
                                                            <>
                                                                <Slider {...settings} style={{ marginBottom: '1rem' }}>
                                                                    {
                                                                        this.state.booking.photoURLs.map((photoURL, photoURL_i) => (
                                                                            <div key={photoURL_i}>
                                                                                <a href={photoURL} data-fancybox={"gallery" + this.state.booking.id}>
                                                                                    <img src={photoURL} alt={"BeeCleanphotoURL"} style={{ maxWidth: '100%', height: 300, margin: 'auto' }} />
                                                                                </a>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </Slider>
                                                                <div className={"divider"} />
                                                            </>
                                                            : null
                                                    }
                                                    <div className={"flex row"}>
                                                        <div><label>ความประทับใจต่อลูกค้า</label></div>
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            <i className="fas fa-star" style={{ color: '#ffa200' }}></i> {this.state.booking && this.state.booking.starFromProvider !== undefined ? this.state.booking.starFromProvider.toFixed(1) : '0.0'}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div><label>รายงานจากแม่บ้าน</label> : {this.state.booking && this.state.booking.user_report && this.state.booking.user_report !== "" ? this.state.booking.user_report.report : '-'}</div>
                                                    </div>
                                                    <div className={"divider"} />
                                                    <div className={"flex row"}>
                                                        <div><label className='mr-2'>ความประทับใจต่อแม่บ้าน</label>
                                                            {
                                                                this.state.booking.starFromUser == 0 ?
                                                                    <ReviewToProvider

                                                                        bookUid={this.state.booking.id}
                                                                        adminUid={this.props.adminUid}
                                                                        onSuccess={() => {
                                                                            this.setState({
                                                                                is_loading: true,
                                                                            }, () => {
                                                                                this.fetch();
                                                                            })
                                                                        }}
                                                                    />
                                                                    : null
                                                            }
                                                        </div>
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            <i className="fas fa-star" style={{ color: '#ffa200' }}></i> {this.state.booking && this.state.booking.starFromUser !== undefined ? this.state.booking.starFromUser.toFixed(1) : '0.0'}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div><label>รายงานจากสมาชิก</label> :  {this.state.booking && this.state.booking.provider_report && this.state.booking.provider_report !== "" ? this.state.booking.provider_report.report : '-'}</div>
                                                    </div>
                                                    {
                                                        this.state.booking.provider_report
                                                            && this.state.booking.provider_report.rateClean ?
                                                            <>
                                                                <div className={"divider"} />
                                                                <div className={"row"} >
                                                                    <div className={"col-lg-3"}>
                                                                        <div className={this.state.booking.provider_report.rateClean[0] ? "raing_icon check" : "raing_icon"}>
                                                                            <div className={"rating_circle"}>
                                                                                <img src={'/assets/images/clean.png'} alt={"Clean"} />
                                                                            </div>
                                                                            <div>
                                                                                Clean & Comfy
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={"col-lg-3"}>
                                                                        <div className={this.state.booking.provider_report.rateClean[1] ? "raing_icon check" : "raing_icon"}>
                                                                            <div className={"rating_circle"}>
                                                                                <img src={'/assets/images/women-icon.png'} alt={"Clean"} />
                                                                            </div>
                                                                            <div>
                                                                                Good Personality
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={"col-lg-3"}>
                                                                        <div className={this.state.booking.provider_report.rateClean[2] ? "raing_icon check" : "raing_icon"}>
                                                                            <div className={"rating_circle"}>
                                                                                <img src={'/assets/images/chat-icon.png'} alt={"Clean"} />
                                                                            </div>
                                                                            <div >
                                                                                Good Communicate
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={"col-lg-3"}>
                                                                        <div className={this.state.booking.provider_report.rateClean[3] ? "raing_icon check" : "raing_icon"}>
                                                                            <div className={"rating_circle"}>
                                                                                <img src={'/assets/images/award-icon.png'} alt={"Clean"} />
                                                                            </div>
                                                                            <div>
                                                                                Excellent Service
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                                    }

                                                </div>
                                            </div>
                                            {
                                                this.state.booking.changeProviderData
                                                    && this.state.booking.changeProviderData.length > 0 ?
                                                    <div className="card collapsed-card">
                                                        <div className="card-header" style={{ alignItems: 'center' }}>
                                                            <h4 className="card-title" id="myModalLabel">
                                                                ข้อมูลการเปลี่ยนแม่บ้าน
                                                            </h4>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            {

                                                                this.state.booking.changeProviderData.map((changepvd, changepvd_i) => {
                                                                    return (
                                                                        <div className={"flex row"} key={changepvd_i}>
                                                                            {changepvd_i > 0 ? <div className="divider"></div> : null}
                                                                            <div>
                                                                                {
                                                                                    changepvd.currentProviderdisplayName ?
                                                                                        <div>
                                                                                            <label>แม่บ้านที่รับงาน</label> :  {' '}
                                                                                            {changepvd.currentProviderdisplayName}
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    changepvd.updateProviderdisplayName ?
                                                                                        <div>
                                                                                            <label>แม่บ้านที่เปลี่ยน</label> :  {' '}
                                                                                            {changepvd.updateProviderdisplayName}
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    changepvd.reason ?
                                                                                        <div>
                                                                                            <label>เหตุผลในการเปลี่ยน</label> :  {' '}
                                                                                            {changepvd.reason}
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    changepvd.note ?
                                                                                        <div>
                                                                                            <label>หมายเหตุ</label> :  {' '}
                                                                                            {changepvd.note}
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    changepvd.updatedAt ?
                                                                                        <div>
                                                                                            <label>เวลา</label> :  {' '}
                                                                                            {Helper.getDateThai(changepvd.updatedAt, { is_show_time: true })}
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>

                                                                                <button type="button" className='btn btn-default mb-3' style={{ width: '100%' }}
                                                                                    onClick={() => {
                                                                                        Helper.reactToDom(window.$('body'), <AdjustForm
                                                                                            bookUid={this.state.booking.id}
                                                                                            providerUid={changepvd.currentProviderUid}
                                                                                            onSuccess={() => {
                                                                                                this.fetch()
                                                                                            }}
                                                                                            providerName={changepvd.currentProviderdisplayName}
                                                                                            admin={this.props.admin}
                                                                                        />
                                                                                        )
                                                                                    }}
                                                                                >ปรับรายได้</button>
                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 60, }}>
                                                                                    {changepvd.number}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                this.state.booking.plusTime
                                                    && this.state.booking.plusTime.length > 0 ?
                                                    <div className="card collapsed-card">
                                                        <div className="card-header" style={{ alignItems: 'center' }}>
                                                            <h4 className="card-title" id="myModalLabel">
                                                                ข้อมูลการเพิ่มเวลา
                                                            </h4>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            {
                                                                this.state.is_loading_time_plus ?
                                                                    <div style={{ padding: '1rem', textAlign: 'center', position: 'absolute', left: 0, top: 0, height: '100%', width: '100%' }}>
                                                                        <div className="ui active inline loader" ></div>
                                                                    </div>
                                                                    : null
                                                            }
                                                            <div className={"flex row"}>
                                                                <div style={{ width: '100%' }}>
                                                                    <div>
                                                                        <label>
                                                                            ผลรวมการเพิ่มเวลา
                                                                        </label>
                                                                    </div>
                                                                    <div className={"flex row"}>
                                                                        <div>
                                                                            <label>จำนวนชั่วโมงที่เพิ่ม</label> :  {' '}
                                                                            {hours} ชม.
                                                                        </div>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            ฿{Helper.numberFormat(plustime_price)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className={"flex row"}>
                                                                <div><label>ภาษี 7%</label></div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    ฿{Helper.numberFormat(summary.tax_price)}
                                                                </div>
                                                            </div>
                                                            <div className={"flex row"}>
                                                                <div><label>ค่าบริการที่แม่บ้านได้รับ</label></div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    ฿{Helper.numberFormat(summary.plustime_provider_only)}
                                                                </div>
                                                            </div>
                                                            <div className={"flex row"}>
                                                                <div><label>ค่าบริการที่ APP ได้รับ</label></div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    ฿{Helper.numberFormat(summary.plustime_app_price_only)}
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.booking.plusTime.map((plusTime, plusTime_i) => {
                                                                    if (plusTime.status === "wait") {
                                                                        wait_count++;
                                                                    }
                                                                    return (
                                                                        <div className={"flex row"} key={plusTime_i}>
                                                                            <div className="divider"></div>
                                                                            <div style={{ width: '100%' }}>
                                                                                <div className={"flex row"} style={{ alignItems: 'center', marginBottom: '0.5rem' }}>
                                                                                    <label style={{ marginBottom: 0 }}>
                                                                                        {"เพิ่มชั่วโมงครั้งที่ " + (plusTime_i + 1)}
                                                                                    </label>

                                                                                    {
                                                                                        plusTime.status === "wait" ?
                                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                                <div style={{ marginBottom: 15 }}>
                                                                                                    <div style={{ textAlign: 'right' }}>฿{Helper.numberFormat(plusTime.priceTotal)} <small>ไม่รวมภาษี</small></div>
                                                                                                </div>
                                                                                                <button className={"btn btn-danger"}
                                                                                                    onClick={() => {
                                                                                                        if (wait_count > 1) {
                                                                                                            window.$(document).Toasts('create', {
                                                                                                                title: 'ไม่สามารถเพิ่มชั่วโมงได้',
                                                                                                                body: "ไม่สามารถทำการเพิ่มชั่วโมงเนื่องจากมีรายการเพิ่มชั่วโมงที่ไม่ได้ทำการชำระเงินก่อนหน้า",
                                                                                                                icon: 'fas fa-times',
                                                                                                                autohide: true,
                                                                                                                delay: 3000,
                                                                                                                class: "bg-danger",
                                                                                                            })
                                                                                                            return;
                                                                                                        }
                                                                                                        Helper.confirmModal({
                                                                                                            title: "ยกเลิกการชำระเงิน",
                                                                                                            description: "ยืนยันการยกเลิกการชำระเงิน",
                                                                                                            onConfirm: async () => {
                                                                                                                try {
                                                                                                                    await axios.post(window.api_host + '/books-plus-time-cancel-for-backend', {
                                                                                                                        bookPlusTimeUid: plusTime.id,
                                                                                                                    }).then(async (res) => {
                                                                                                                        let update = {
                                                                                                                            cancelPlustimeAdminUid: this.props.adminUid,
                                                                                                                            cancelPlustimeAt: new Date().getTime(),
                                                                                                                        }
                                                                                                                        let _res = await axios.put(`${window.api_host}/booking`, {
                                                                                                                            id: this.state.booking.id,
                                                                                                                            update: update,
                                                                                                                        });
                                                                                                                        window.$(document).Toasts('create', {
                                                                                                                            title: 'ยกเลิกการชำระเงินสำเร็จ',
                                                                                                                            body: 'ยกเลิกการชำระเงินเรียบร้อยแล้ว',
                                                                                                                            icon: 'fas fa-check',
                                                                                                                            autohide: true,
                                                                                                                            delay: 3000,
                                                                                                                            class: "bg-success",
                                                                                                                        })
                                                                                                                        this.setState({
                                                                                                                            is_loading_time_plus: true
                                                                                                                        }, () => {
                                                                                                                            this.fetch();
                                                                                                                        })
                                                                                                                    })
                                                                                                                } catch (error) {
                                                                                                                    window.$(document).Toasts('create', {
                                                                                                                        title: 'ยกเลิกการชำระเงินไม่สำเร็จ',
                                                                                                                        body: "ยกเลิกการชำระเงินไม่สำเร็จ",
                                                                                                                        icon: 'fas fa-times',
                                                                                                                        autohide: true,
                                                                                                                        delay: 3000,
                                                                                                                        class: "bg-danger",
                                                                                                                    })
                                                                                                                }
                                                                                                            },
                                                                                                            onCancel: () => {
                                                                                                                return false;
                                                                                                            }
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    ยกเลิก
                                                                                                </button>
                                                                                            </div>
                                                                                            : null}
                                                                                </div>

                                                                                {
                                                                                    plusTime.name_th ?
                                                                                        <div className={"flex row"}>
                                                                                            <div>
                                                                                                <label>จำนวนชั่วโมง</label> :  {' '}
                                                                                                {plusTime.name_th}
                                                                                            </div>
                                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                                ฿{Helper.numberFormat(plusTime.price)}
                                                                                            </div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    plusTime.omise
                                                                                        && plusTime.omise.card ?
                                                                                        <>
                                                                                            <div>
                                                                                                <label>ประเภทบัตร</label> :  {' '}
                                                                                                {plusTime.omise.card.brand}
                                                                                            </div>
                                                                                            <div>
                                                                                                <label>เลขบัตร 4 ตัวสุดท้าย</label> :  {' '}
                                                                                                {plusTime.omise.card.last_digits}
                                                                                            </div>
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    plusTime.status ?
                                                                                        <div className="flex row">
                                                                                            <div>
                                                                                                <label>สถานะ</label> :  {' '}
                                                                                                {
                                                                                                    plusTime.status === 'wait' ?
                                                                                                        "รอชำระเงิน" : null
                                                                                                }
                                                                                                {
                                                                                                    plusTime.status === 'cancel' ?
                                                                                                        "ยกเลิก" : null
                                                                                                }
                                                                                                {
                                                                                                    plusTime.status === 'fail' ?
                                                                                                        "ชำระเงินไม่สำเร็จ" : null
                                                                                                }
                                                                                                {
                                                                                                    plusTime.status === 'success' ?
                                                                                                        "ชำระเงินสำเร็จ" : null
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                plusTime.paymentBy === "banktransfer"
                                                                                                    && plusTime.plusTimeImages
                                                                                                    && plusTime.plusTimeImages.length > 0 ?
                                                                                                    <>
                                                                                                        <span
                                                                                                            style={{ marginLeft: 'auto' }}
                                                                                                            className="link"
                                                                                                            href={plusTime.plusTimeImages[0].imageURL}
                                                                                                            data-fancybox={`plustime${plusTime_i}`}
                                                                                                        >หลักฐานการโอนเงิน {plusTime.plusTimeImages.length} รูป</span>
                                                                                                        {
                                                                                                            plusTime.plusTimeImages.map((image, image_i) => {
                                                                                                                if (image_i === 0) { return null }
                                                                                                                return (
                                                                                                                    <img style={{ display: 'none' }}
                                                                                                                        data-fancybox={`plustime${plusTime_i}`}
                                                                                                                        src={image.imageURL} alt={'plustime payment'} />
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    plusTime.omise
                                                                                        && plusTime.omise.authorize_uri ?
                                                                                        <div style={{ wordBreak: 'break-all' }}>
                                                                                            <label>Link omise URL</label> :  {' '}
                                                                                            {plusTime.omise.authorize_uri}
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    plusTime.updatedAt ?
                                                                                        <div>
                                                                                            <label>เวลาที่แอดมินทำรายการขึ้นไป</label> :  {' '}
                                                                                            {Helper.getDateThai(plusTime.updatedAt, { is_show_time: true })}
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                            {
                                                this.state.booking.providerSalary ?
                                                    <div className="card collapsed-card">
                                                        <div className="card-header" style={{ alignItems: 'center' }}>
                                                            <h4 className="card-title" id="myModalLabel">
                                                                หลักฐานรายได้แม่บ้าน
                                                            </h4>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.booking.providerSalary.images
                                                                && this.state.booking.providerSalary.images.length > 0 ?
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {
                                                                            this.state.booking.providerSalary.images.map((image, image_i) => {
                                                                                return (

                                                                                    <div className={this.state.booking.providerSalary.images.length === 1 ? "col-12" : "col-6"} key={image_i}>
                                                                                        <a href={image.imageURL} data-fancybox={'slip'}>
                                                                                            <div className="card" style={{ marginBottom: 0 }}>
                                                                                                <div className="card-body" style={{ padding: '0.5rem' }}>
                                                                                                    <img src={image.imageURL} alt="Slip" style={{ maxWidth: '100%' }} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                        <div className="card-footer">
                                                            <div className="flex row">
                                                                {
                                                                    this.state.booking.providerSalary.salaryUid
                                                                        && this.state.booking.providerSalary.type == 'upload' ?
                                                                        <div>
                                                                            <Link to={"/csv-salary-provider/" + this.state.booking.providerSalary.salaryUid + "?page=1&name=" + this.state.booking.providerUser.displayName}>{this.state.booking.providerSalary.title}</Link>
                                                                        </div>
                                                                        : this.state.booking.providerSalary.salaryUid
                                                                            && this.state.booking.providerSalary.type != 'upload' ?
                                                                            <div>
                                                                                <Link to={"/salary-provider/" + this.state.booking.providerSalary.salaryUid + "?page=1&name=" + this.state.booking.providerUser.displayName}>{this.state.booking.providerSalary.title}</Link>
                                                                            </div>
                                                                            : null
                                                                }
                                                                {
                                                                    this.state.booking.providerSalary.updateProviderSalaryAdmin ?
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            {this.state.booking.providerSalary.updateProviderSalaryAdmin.displayName}
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        {/* <button
                                                        className="btn btn-default"
                                                        style={{ width: '100%' }}
                                                        onClick={() => {
                                                            Helper.reactToDom(window.$('body'),
                                                                <AddProviderSalaryImage
                                                                    providerUid={this.state.booking.providerUser.provideruserDataUID}
                                                                    bookingUid={this.state.booking.id}
                                                                    adminUid={this.props.adminUid}
                                                                    mode={'create'}
                                                                    onSuccess={() => {
                                                                        this.fetch();
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    >
                                                        อัพโหลดหลักฐานรายได้แม่บ้าน
                                                    </button> */}
                                                    </>
                                            }
                                            {
                                                !this.state.booking.providerSalary ?
                                                    <button type="button" className='btn btn-default mb-3' style={{ width: '100%' }}
                                                        onClick={() => {
                                                            Helper.reactToDom(window.$('body'), <AdjustForm
                                                                bookUid={this.state.booking.id}
                                                                providerUid={this.state.booking.providerUser.provideruserDataUID}
                                                                onSuccess={() => {
                                                                    this.fetch()
                                                                }}
                                                                providerName={this.state.booking.providerUser.displayName}
                                                                admin={this.props.admin}
                                                            />
                                                            )
                                                        }}
                                                    >ปรับรายได้</button>
                                                    : null
                                            }
                                            {
                                                this.state.booking?.priceUrgent > 0 ?
                                                    <div className="card collapsed-card">
                                                        <div className="card-header" style={{ alignItems: 'center' }}>
                                                            <h4 className="card-title" id="myModalLabel">
                                                                ชั่วโมงเร่งด่วน
                                                            </h4>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className={"flex row"}>
                                                                <div style={{ width: '100%' }}>
                                                                    {/* <div>
                                                                        <label>
                                                                            ผลรวมการเพิ่มเวลา
                                                                        </label>
                                                                    </div> */}
                                                                    <div className={"flex row"}>
                                                                        <div>
                                                                            <label>ชั่วโมงเร่งด่วน</label>
                                                                        </div>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            ฿{Helper.numberFormat(this.state.booking?.priceUrgent || 0)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className={"flex row"}>
                                                                <div><label>ภาษี 7%</label></div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    ฿{Helper.numberFormat(this.state.booking?.priceUrgentVat)}
                                                                </div>
                                                            </div>
                                                            <div className={"flex row"}>
                                                                <div><label>ค่าบริการที่แม่บ้านได้รับ</label></div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    ฿{Helper.numberFormat(this.state.booking?.priceUrgentProvider)}
                                                                </div>
                                                            </div>
                                                            <div className={"flex row"}>
                                                                <div><label>ค่าบริการที่ APP ได้รับ</label></div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    ฿{Helper.numberFormat(this.state.booking?.priceUrgentApp)}
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.booking?.urgentUpdateAt ?
                                                                    <div><label>เวลาที่ทำรายการ</label> :   {Helper.getDateThai(this.state.booking?.urgentUpdateAt, { is_show_time: true })}</div>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking?.urgentImage?.imagePath ?
                                                                    <div className={"flex row mb-2"}>
                                                                        <span
                                                                            // style={{ marginLeft: 'auto' }}
                                                                            className="link"
                                                                            href={this.state.booking?.urgentImage.imagePath}
                                                                            data-fancybox={`urgent`}
                                                                        >หลักฐานการโอนเงิน</span>

                                                                        {/* <img style={{ display: 'none' }}
                                                                    data-fancybox={`urgent`}
                                                                    src={this.state.booking?.urgentImage.imagePath} alt={'urgent'} /> */}
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking?.noteUrgent ?
                                                                    <>
                                                                        <div className="divider"></div>
                                                                        <div className={"flex row"}>
                                                                            <div><label>หมายเหตุ</label></div>
                                                                            <div style={{ marginLeft: 15 }} >
                                                                                {this.state.booking?.noteUrgent}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : null
                                                            }

                                                            <div className="divider"></div>
                                                            <div className={"flex row"}>
                                                                <div><label>รวมทั้งหมด</label></div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    ฿{Helper.numberFormat((this.state.booking?.priceUrgent || 0) + (this.state.booking?.priceUrgentVat || 0))}
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.booking.status == 'finish'
                                                                    || this.state.booking.status == 'done' ?
                                                                    null
                                                                    :
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-default"
                                                                        style={{ marginLeft: 0 }}
                                                                        onClick={() => {
                                                                            Helper.reactToDom(window.$('body'),
                                                                                <UrgentForm
                                                                                    bookUid={this.state.booking.id}
                                                                                    urgent={this.state.booking}
                                                                                    adminUid={this.props.adminUid}
                                                                                    onSuccess={() => {
                                                                                        this.setState({
                                                                                            is_loading: true,
                                                                                        }, () => {
                                                                                            this.fetch();
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            );
                                                                        }}
                                                                    >
                                                                        แก้ไขชั่วโมงเร่งด่วน
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                    : null
                                            }


                                        </div>
                                    </div>
                                </>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && !this.state.booking ?
                                <>
                                    <h4>ไม่มีข้อมูลหรือถูกลบไปแล้วโปรดติดต่อผู้ดูแลระบบ</h4>
                                </>
                                : null
                        }
                    </div>
                </section >
            </>
        );
    }
}

class BookingDetails extends React.Component {
    constructor() {
        super();
        this.state = {
            cancal_modal: false,
            vatPrice: 0,
        }
    }
    async wegateRefund(data) {
        let postData = { ...data };
        postData.bookUid = this.props.data.id;
        delete postData.storagePath;
        try {
            let res = await axios.post(`${window.api_host}/wegate-refund-booking`, postData);
            window.$(document).Toasts('create', {
                title: 'คืนเงิน Booking สำเร็จ',
                body: "ระบบได้ทำการยื่นขอคือเงิน Booking เรียบร้อยแล้ว",
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            if (this.checkbox.prop("checked")) {
                this.updateAdjust(data);
                return;
            }
            if (this.props.onCancel) {
                this.props.onCancel();
            }
            this.$modal.modal('hide');
        } catch (error) {
            if (data.storagePath) {
                storage.ref(data.storagePath).delete();
            }
            if (error && error.data && error.data.message) {
                window.$(document).Toasts('create', {
                    title: 'คืนเงินไม่สำเร็จ',
                    body: error.data.message,
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            } else {
                window.$(document).Toasts('create', {
                    title: 'คืนเงินไม่สำเร็จ',
                    body: `กรุณาลองใหม่อีกครั้ง`,
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
            if (this.$file) {
                let upload_btn = this.$file.parent().find('.btn');
                upload_btn.removeClass('loading');
            }
            this.$confim.removeClass('loading');
            if (this.props.onCancel) {
                this.props.onCancel();
            }
            this.$modal.modal('hide');
        }

    }
    cancelBooking(data) {
        let bookUid = this.props.data.id;
        axios.post(window.api_host + '/omise-refund', {
            bookUid
        }).then(async res => {
            let cancelRemark = this.$cancelRemark.val();
            if (res.data.code === 200) {
                let updateData = {
                    id: bookUid,
                    update: {
                        cancelRemark: cancelRemark
                    },
                }
                if (data) {
                    updateData.update.refundSlipURL = data.slipURL;
                    updateData.update.refundStoragePath = data.storagePath;
                }

                await axios.put(`${window.api_host}/booking`, updateData);
                if (this.checkbox.prop("checked")) {
                    this.updateAdjust(data);
                    return;
                }
                window.$(document).Toasts('create', {
                    title: 'ยกเลิก Booking สำเร็จ',
                    body: "ระบบได้ทำการยกเลิก Booking เรียบร้อยแล้ว",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                if (this.props.onCancel) {
                    this.props.onCancel();
                }
                this.$modal.modal('hide');
            } else {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มข้อมูลไม่สำเร็จ',
                    body: res.data.message,
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
                this.$confim.removeClass('loading');
            }
        }).catch(error => {
            this.$confim.removeClass('loading');
            window.$(document).Toasts('create', {
                title: 'เพิ่มข้อมูลไม่สำเร็จ',
                body: "กรุณาลองใหม่อีกครั้ง",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
        });
    }


    async updateAdjust(data) {
        let bookingDate = new Date();
        let bookingDaY = bookingDate.getDate();
        let bookingMonth = bookingDate.getMonth();
        let bookingYear = bookingDate.getFullYear();
        if (parseFloat(bookingDaY) < 10) {
            bookingDaY = `0${bookingDaY}`
        }
        if (parseFloat(bookingMonth) < 10) {
            bookingMonth = `0${bookingMonth}`
        }
        let bookingDateFormat = `${bookingYear}-${bookingMonth}-${bookingDaY}`;
        let priceNode = window.$(`input[name="priceCancel"]`)
        let appNode = window.$(`input[name="priceCancelApp"]`)
        let providerNode = window.$(`input[name="priceCancelProvider"]`);
        let send = {
            priceCancel: priceNode.val(),
            priceCancelApp: appNode.val(),
            priceCancelProvider: providerNode.val(),
            type: "special_wallet",
            amount: providerNode.val(),
            reason: `เรียกเก็บเงินจากลูกค้ากรณีคืนเงิน/ขอยกเลิก Book`,
            adjustType: "customer",
            date: bookingDateFormat,
            dateTime: bookingDate.getTime(),
            vat: this.state.vatPrice,
            bookUid: this.props.data.id,
            providerUid: this.props.data.providerUserUid,
            updatedBy: {
                name: this.props.admin.displayName.stringValue,
                id: this.props.admin.id.stringValue,
            },
            images: [
                {
                    imageURL: data.slipURL,
                    storagePath: data.storagePath
                }
            ]
        }

        if (send.priceCancelProvider && parseFloat(send.priceCancelProvider) > 0) {
            await axios.post(`${window.api_host}/adjust-salary`, { ...send });
        }
    }

    readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.$file.parent().find('.button').text('เปลี่ยนรูป');
                window.$('#show_image').html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
                window.$('#show_image').html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
            };

            reader.readAsDataURL(input.files[0]);
        }
    }
    uploadFile(node, options) {
        // let id = options.PromotionId;
        let upload_btn = node.parent().find('.btn');
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        const filename = Helper.setNewImageFileName(file);
        console.log('before upload image', filename);
        var uploadTask = storage.ref().child('bookingRefund/' + filename).put(file);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log('uploading image', snapshot);
        }, (error) => {
            console.log('upload image error', error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            upload_btn.removeClass('loading');
            this.$confim.removeClass('loading');
        }, async () => {
            let url = 'bookingRefund/' + filename;
            console.log('upload image success', url);
            if (options.onSuccess) {
                options.onSuccess(url);
            }

        });
    }
    calculatePrice(price) {
        price = parseFloat(price)
        const sharePercent = 50
        let vatNode = window.$('#vatPrice')
        let appNode = window.$(`input[name="priceCancelApp"]`)
        let providerNode = window.$(`input[name="priceCancelProvider"]`)

        let vat = (price * 7) / 100;
        this.state.vatPrice = vat;

        let renderTotal = window.$('#Total')
        renderTotal.html(Helper.numberFormat((price + vat), { decimal: 2 }))
        vatNode.html(Helper.numberFormat(vat, { decimal: 2 }))
        appNode.val((price * sharePercent / 100))
        providerNode.val((price * sharePercent / 100))
    }

    calculateApp(pinType) {
        let renderAppTotalNode = window.$('#AppTotal')
        let renderProviderTotalNode = window.$('#ProviderTotal')

        let piceNode = window.$(`input[name="priceCancel"]`)
        let appNode = window.$(`input[name="priceCancelApp"]`)
        let providerNode = window.$(`input[name="priceCancelProvider"]`)
        let priceVal = parseFloat(piceNode.val())
        let appVal = parseFloat(appNode.val());
        let providerVal = parseFloat(providerNode.val());
        if (pinType == 'app') {
            providerVal = priceVal - appVal
        } else if (pinType == 'provider') {
            appVal = priceVal - providerVal
        }

        appNode.val(appVal)
        providerNode.val(providerVal)
        renderAppTotalNode.html(Helper.numberFormat(appVal + this.state.vatPrice, { decimal: 2 }))
        renderProviderTotalNode.html(Helper.numberFormat(providerVal, { decimal: 2 }))
    }

    render() {
        console.log(this.props)
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                }}
            >
                <div className="modal-dialog modal-md" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className={"form-group"}>
                                <label>หมายเหตุ</label>
                                <textarea name={"cancelRemark"} className={"form-control"} ref={(ref) => { this.$cancelRemark = window.$(ref) }}></textarea>
                            </div>
                            {
                                this.props.data.type == 'wegate'
                                    || this.props.data.createdAdmin
                                    ?
                                    <>
                                        <div>
                                            <label>รูปภาพ (หลักฐานการโอนเงิน)</label>
                                            <input name="image"
                                                type={"file"}
                                                ref={(ref) => this.$file = window.$(ref)}
                                                style={{ display: 'none' }}
                                                id="file_image"
                                                onChange={(e) => {
                                                    this.readURL(this.$file[0]);
                                                }}
                                            />
                                            <div id="show_image" />
                                            <label htmlFor="file_image"
                                                type={"button"}
                                                className="btn btn-default"
                                                style={{ width: '100%' }}
                                            >
                                                อัพโหลด
                                            </label>
                                        </div>
                                        <div className="custom-control custom-switch">
                                            <input
                                                ref={ref => this.checkbox = window.$(ref)}
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="adjustType"
                                                name={"adjustType"}
                                                defaultValue={"customer"}
                                                onChange={(e) => {
                                                    if (window.$(e.target).prop("checked")) {
                                                        window.$('#adjustVat').show()
                                                    } else {
                                                        window.$('#adjustVat').hide()
                                                    }
                                                }}
                                                defaultChecked={this.state.data?.adjustType == 'customer' || ''}
                                            />
                                            <label className="custom-control-label" htmlFor="adjustType">ปรับรายได้แม่บ้าน</label>
                                        </div>
                                        <div style={{ display: `none` }} id="adjustVat">
                                            <div>
                                                <label>เร่งด่วน</label>
                                                <input type="number"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        let priceCancel = 0;
                                                        if (!isNaN(e.target.value)) {
                                                            priceCancel = e.target.value;
                                                        } else {
                                                            priceCancel = this.state.priceCancel;
                                                            window.$(`input[name="priceCancel"]`).val(priceCancel)
                                                        }
                                                        this.state.priceCancel = priceCancel;
                                                        this.calculatePrice(priceCancel);
                                                        this.calculateApp();

                                                    }}
                                                    name='priceCancel'
                                                    defaultValue={0}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <small>ภาษี 7%</small><span id="vatPrice" style={{ marginLeft: 10 }}>0</span> <small>บาท</small>
                                            </div>
                                            <div className={"salary-transfer-price"}>
                                                <div className={"title"}>รวมทั้งหมด</div>
                                                <div className={"price"} id="Total" style={{ color: 'green' }}>0</div>
                                            </div>
                                            <hr />
                                            <div className='row'>
                                                <div className='col-12 col-lg-6 form-group'>
                                                    <label>App</label>
                                                    <input step="0.01" type="number"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            let value = 0;
                                                            if (!isNaN(e.target.value)) {
                                                                value = e.target.value;
                                                            } else {
                                                                value = this.state.priceCancelApp;
                                                                window.$(`input[name="priceCancelApp"]`).val(value)
                                                            }
                                                            this.state.priceCancelApp = value;

                                                            this.calculateApp('app');
                                                        }}
                                                        name='priceCancelApp'
                                                        defaultValue={0}
                                                    />
                                                    <div className={"salary-transfer-price"}>
                                                        <div className={"title"}>App ได้รับ</div>
                                                        <div className={"price"} id="AppTotal" style={{ color: 'green', fontSize: 26 }}>0</div>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-lg-6 form-group'>
                                                    <label>แม่บ้าน</label>
                                                    <input step="0.01" type="number"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            let value = 0;
                                                            if (!isNaN(e.target.value)) {
                                                                value = e.target.value;
                                                            } else {
                                                                value = this.state.priceCancelProvider;
                                                                window.$(`input[name="priceCancelProvider"]`).val(value)
                                                            }
                                                            this.state.priceCancelProvider = value;

                                                            this.calculateApp('provider');
                                                        }}
                                                        name='priceCancelProvider'
                                                        defaultValue={0}
                                                    />
                                                    <div className={"salary-transfer-price"}>
                                                        <div className={"title"}>แม่บ้านได้รับ</div>
                                                        <div className={"price"} id="ProviderTotal" style={{ color: 'green', fontSize: 26 }}>0</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }
                        </div>
                        <div className="modal-footer">
                            <div className={"flex row"}>
                                <div style={{ marginLeft: 'auto' }}>
                                    <button className={"btn btn-default"}
                                        onClick={() => {
                                            this.$modal.modal('hide')
                                        }}
                                    >ไม่ดำเนิการ</button>
                                    <button className={"btn btn-primary"}
                                        style={{ marginLeft: '1rem' }}
                                        ref={(ref) => {
                                            this.$confim = window.$(ref);
                                        }}
                                        onClick={() => {
                                            if (this.$confim.hasClass('loading')) {
                                                return;
                                            }
                                            this.$confim.addClass('loading');
                                            if (this.props.data.type == 'wegate'
                                                || this.props.data.createdAdmin) {
                                                let data = {};
                                                let cancelRemark = this.$cancelRemark.val();
                                                data.refundReason = cancelRemark;
                                                if (this.$file && this.$file[0] && this.$file[0].files[0]) {
                                                    this.uploadFile(this.$file, {
                                                        onSuccess: async (url) => {
                                                            data.storagePath = url;
                                                            data.slipURL = await storage.ref(url).getDownloadURL();
                                                            if (this.props.data.type == 'wegate') {
                                                                this.wegateRefund(data);
                                                            } else if (this.props.data.createdAdmin) {
                                                                this.cancelBooking(data);
                                                            }

                                                        }
                                                    })
                                                    return;
                                                } else {
                                                    window.$(document).Toasts('create', {
                                                        title: 'คืนเงินไม่สำเร็จ',
                                                        body: "กรุณาอัพโหลดสลิป",
                                                        icon: 'fas fa-times',
                                                        autohide: true,
                                                        delay: 3000,
                                                        class: "bg-danger",
                                                    })
                                                    this.$confim.removeClass('loading');
                                                    return;
                                                }
                                            } else {
                                                this.cancelBooking();
                                            }
                                        }}
                                    >
                                        {
                                            this.props.data.type == 'wegate' ?
                                                `ดำเนินการคืนเงิน  Booking`
                                                : `ดำเนินการยกเลิก  Booking`
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        );
    }
}

class ChangeProviderUser extends React.Component {
    constructor() {
        super();
        this.state = {
            provider_users: [],
            is_loading: true,
        }
    }

    componentDidMount() {
        this.fetchProvider();
    }

    async fetchProvider() {
        let orderBy = 'firstName';
        let orderByType = 'asc';
        db.collection("provider_users_data").where("status", "==", "avaliable").orderBy(orderBy, orderByType).get().then(async (doc) => {
            let get_data = await Promise.all(
                doc.docs.map(async (element) => {
                    let element_data = { ...element.data() };
                    return {
                        ...element_data,
                        id: element.id,
                    };

                })
            );
            // get_data = get_data.filter((d) => { return d !== null })
            this.setState({
                provider_users: get_data,
                is_loading: false,
            })
        })
    }

    async update(data) {
        // console.log(data);
        // return;
        try {
            await axios.post(window.api_host + '/change-provider', { ...data }).then(async (res) => {
                window.$(document).Toasts('create', {
                    title: 'เปลี่ยนแม่บ้านสำเร็จ',
                    body: 'ทำการเปลี่ยนแม่บ้านเรียบร้อยแล้ว',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                this.props.onSuccess();
                this.$modal.modal('hide');
            })
        } catch (error) {
            if (error.response.data.code === -1) {
                window.$(document).Toasts('create', {
                    title: 'ไม่พบ Booking',
                    body: "ไม่พบหมายเลข Booking นี้ในระบบ",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
            if (error.response.data.code === -2) {
                window.$(document).Toasts('create', {
                    title: 'แม่บ้านยังไม่ได้กดยอมรับเงื่อนไขการให้บริการ',
                    // body: "ไม่พบแม่บ้านท่านนี้ในระบบ",
                    body: "โปรดกดยอมรับเงือนไขบน Application",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
            if (error.response.data.code === -3) {
                window.$(document).Toasts('create', {
                    title: 'สถานะไม่ถูกต้อง',
                    body: "สถานะต้องเป็น ยอมรับ (accept) เท่านั้น",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
            if (error.response.data.code === -4) {
                window.$(document).Toasts('create', {
                    title: 'แม่บ้านคนเดียวกัน',
                    body: "แม่บ้านคนเดียวกัน กรุณาเลือกแม่บ้านใหม่อีกครั้ง",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
            this.$submitBtn.removeClass('loading');
            return;
        }
    }


    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard="false" data-backdrop="static"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading') || this.state.is_loading) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        let ref = await db.collection('provider_users').where('phoneNumber', '==', data.providerUserPhoneNumber).get();
                        if (ref && ref.docs && ref.docs.length > 0) {
                            data.providerUid = ref.docs[0].id;
                        }
                        if (!data.note) {
                            Helper.messageTop({ message: 'กรุณากรอกหมายเหตุ' })
                            this.$submitBtn.removeClass('loading');
                            return;
                        }
                        delete data.providerUserPhoneNumber;
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                เปลี่ยนแม่บ้าน
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            {
                                this.state.is_loading ?
                                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                                        <div className="ui active inline loader" ></div>
                                    </div>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className="form-group">
                                            <label>แม่บ้านปัจจุบัน</label>
                                            <input type="text" disabled={true} value={this.props.providerUser.displayName} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>เลือกแม่บ้าน</label>
                                            <select
                                                ref={(ref) => { window.$(ref).select2(); }}
                                                className={"form-control select2"}
                                                name={"providerUserPhoneNumber"}
                                                style={{ width: '100%' }}
                                                defaultValue={this.props.providerUserPhoneNumber || ''}
                                            >
                                                {
                                                    this.state.provider_users.map((provider_user, provider_user_i) => {
                                                        return (
                                                            <option value={provider_user.phoneNumber} key={provider_user_i}>
                                                                {provider_user.prefix} {provider_user.firstName} {provider_user.lastName}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className={"form-group"}>
                                            <label>เหตุผลในการเปลี่ยน</label>
                                            <select
                                                className="form-control"
                                                name={"reason"}
                                            >
                                                <option value="ติดต่อแม่บ้านไม่ได้">ติดต่อแม่บ้านไม่ได้</option>
                                                <option value="แม่บ้านมีปัญหาระหว่างการเดินทาง">แม่บ้านมีปัญหาระหว่างการเดินทาง</option>
                                                <option value="เหตุฉุกเฉิน">เหตุฉุกเฉิน</option>
                                                <option value="แม่บ้านกดผิด">แม่บ้านกดผิด</option>
                                            </select>
                                        </div>
                                        <div className={"form-group"}>
                                            <label>หมายเหตุ</label>
                                            <textarea name={"note"} className={"form-control"}></textarea>
                                        </div>
                                        <input type="hidden" name={"bookUid"} defaultValue={this.props.bookUid} />
                                        <input type="hidden" name={"adminUid"} defaultValue={this.props.adminUid} />
                                    </>
                                    : null
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal" disabled={this.state.is_loading}>ยกเลิก</button>
                            <button type="submit" className="btn btn-primary"
                                disabled={this.state.is_loading}
                                ref={(ref) => { this.$submitBtn = window.$(ref) }}
                            >
                                เปลี่ยนแม่บ้าน
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

class ChangeBookTime extends React.Component {
    constructor() {
        super();
        this.state = {
            booking_time: [],
            prepare_file_upload: [],
            file_uploaded: [],
            is_loading: true,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        db.collection("book_time").orderBy("sequence", "asc").get().then(async (doc) => {
            let get_data = await Promise.all(
                doc.docs.map(async (element) => {
                    let element_data = { ...element.data() };
                    return {
                        ...element_data,
                        id: element.id,
                    };

                })
            );
            get_data = get_data.filter((d) => { return d !== null })
            this.setState({
                booking_time: get_data,
                is_loading: false,
            })
        })
    }

    prepareUpload(input) {
        if (input.files && input.files.length > 0) {
            let prepare_file_stack = [...this.state.prepare_file_upload];
            for (var key of Object.keys(input.files)) {
                prepare_file_stack.push(input.files[key]);
            }
            let file_stack = [];
            file_stack = [...prepare_file_stack]
            // file_stack.push(prepare_file_stack[prepare_file_stack.length - 1]);
            this.setState({
                prepare_file_upload: file_stack
            }, () => {
                this.$file.val('');
            })
        }
    }

    async uploadFile(upload_index, data, options) {
        let file = this.state.prepare_file_upload[upload_index];
        const filename = Helper.setNewImageFileName(file);
        let reszieFile = await Helper.resizeImageFn(file, { maxWidth: 600, maxHeight: 600 });
        var uploadTask = storage.ref().child('book/' + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {

        }, async (error) => {
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            this.clearStorageImage();
            return;
        }, async () => {
            let url = 'book/' + filename;
            const photoURL = await storage.ref(url).getDownloadURL();
            let uploaded_images = [...this.state.file_uploaded];
            uploaded_images.push({
                imageURL: photoURL,
                storagePath: url,
            })
            this.setState({
                file_uploaded: uploaded_images,
            }, async () => {
                upload_index++;
                if (upload_index >= this.state.prepare_file_upload.length) {
                    this.update(data);
                    return;
                }
                if (upload_index < this.state.prepare_file_upload.length) {
                    this.uploadFile(upload_index, data);
                }
            })
        });
    }

    async update(data) {
        delete data.isForceSuccess;
        delete data.forceSuccess;

        let plusTimeImages = [];
        if (this.state.file_uploaded && this.state.file_uploaded.length > 0) {
            plusTimeImages = [...this.state.file_uploaded];
        }
        data.plusTimeImages = plusTimeImages;
        try {
            await axios.post(window.api_host + '/book-plus-time', { ...data }).then(async (res) => {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มชั่วโมงทำงานสำเร็จ',
                    body: 'ทำการเพิ่มชั่วโมงทำงานเรียบร้อยแล้ว',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                this.props.onSuccess();
                this.$modal.modal('hide');
            })
        } catch (error) {
            if (error.response.data.code === -1) {
                window.$(document).Toasts('create', {
                    title: 'ไม่พบ Booking',
                    body: "ไม่พบหมายเลข Booking นี้ในระบบ",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
            if (error.response.data.code === -2) {
                window.$(document).Toasts('create', {
                    title: 'ไม่สามารถชำระเงินได้',
                    // body: "ไม่พบแม่บ้านท่านนี้ในระบบ",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
            if (error.response.data.code === -3) {
                window.$(document).Toasts('create', {
                    title: 'ไม่สามารถเพิ่มชั่วโมงได้',
                    body: "ไม่สามารถทำการเพิ่มชั่วโมงเนื่องจากมีรายการเพิ่มชั่วโมงที่ไม่ได้ทำการชำระเงินก่อนหน้า",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
            if (this.state.file_uploaded && this.state.file_uploaded.length > 0) {
                for (const file of this.state.file_uploaded) {
                    storage.ref().child(file.storagePath).delete();
                }
            }

            this.$submitBtn.removeClass('loading');
            return;
        }
    }

    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        let _data = { ...data };
                        _data.forceSuccess = false;
                        if (data.isForceSuccess) {
                            _data.forceSuccess = true;
                            _data.key = "forceSuccess";
                        }
                        delete data.isForceSuccess;
                        if (!_data.forceSuccess) {
                            this.update(_data);
                            return;
                        }
                        if (this.state.prepare_file_upload.length === 0) {
                            window.$(document).Toasts('create', {
                                title: 'ไม่สามารถเพิ่มชั่วโมงได้',
                                body: "กรุณาอัพโหลดสลิปการชำระเงิน",
                                icon: 'fas fa-times',
                                autohide: true,
                                delay: 3000,
                                class: "bg-danger",
                            })
                            this.$submitBtn.removeClass('loading');
                            return;
                        }
                        this.uploadFile(0, _data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                เพิ่มชั่วโมงทำงาน
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            {
                                this.state.is_loading ?
                                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                                        <div className="ui active inline loader" ></div>
                                    </div>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className="form-group">
                                            <label>เลือกเวลา</label>
                                            <select
                                                className={"form-control select2 "}
                                                name={"bookTimeUid"}
                                                style={{ width: '100%' }}
                                                defaultValue={this.props.providerUserUid || ''}
                                            >
                                                {
                                                    this.state.booking_time.map((booking_time, booking_time_i) => {
                                                        return (
                                                            <option value={booking_time.id} key={booking_time_i}>
                                                                {booking_time.name_th} - ฿{booking_time.price}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select>
                                            <input type="hidden" name={"bookUid"} defaultValue={this.props.bookUid} />
                                            <input type="hidden" name={"adminUid"} defaultValue={this.props.adminUid} />
                                        </div>
                                        {
                                            this.props.isForceSuccess ?
                                                <input type="hidden" name="isForceSuccess" value={'true'} />
                                                :
                                                <div className="custom-control custom-switch" style={{ display: 'none' }}>
                                                    <input type="checkbox"
                                                        className="custom-control-input"
                                                        ref={(ref) => { this.$checkbox = window.$(ref) }}
                                                        id="isForceSuccess"
                                                        name={"isForceSuccess"}
                                                        defaultValue={"on"}
                                                        checked={true}
                                                    // onChange={() => {
                                                    //     if (this.$checkbox.prop("checked")) {
                                                    //         this.forceSuccess.show()
                                                    //         this.$checkbox.parent().addClass('form-group')
                                                    //     } else {
                                                    //         this.forceSuccess.hide()
                                                    //         this.$checkbox.parent().removeClass('form-group')
                                                    //     }
                                                    // }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="isForceSuccess">อัพโหลดสลิป</label>
                                                </div>
                                        }
                                        <div
                                            ref={(ref) => { this.forceSuccess = window.$(ref) }}
                                            className={this.state.prepare_file_upload.length > 0 ? "form-group" : null}
                                            // style={{ display: this.props.isForceSuccess ? 'block' : 'none' }}
                                            style={{ display: 'block' }}
                                        >
                                            <label>แนบสลิปการชำระเงิน</label>
                                            <input name="image"
                                                type={"file"}
                                                accept="image/*"
                                                ref={(ref) => this.$file = window.$(ref)}
                                                style={{ display: 'none' }}
                                                id="file_image"
                                                // multiple="multiple"
                                                onChange={(e) => {
                                                    if (this.$file.parent().find('.btn').hasClass('loading')) {
                                                        return;
                                                    }
                                                    this.prepareUpload(this.$file[0]);
                                                }}
                                            />
                                            <label htmlFor="file_image"
                                                type={"button"}
                                                className="btn btn-default"
                                                style={{ width: '100%', marginBottom: 0 }}
                                            >อัพโหลด</label>
                                        </div>
                                        {
                                            this.state.prepare_file_upload
                                                && this.state.prepare_file_upload.length > 0 ?
                                                <div className={"form-group"}>
                                                    <div className="row">
                                                        {

                                                            this.state.prepare_file_upload.map((file_stack, file_stack_i) => {
                                                                var reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                    window.$('#images_file_upload_' + file_stack_i).html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
                                                                };
                                                                reader.readAsDataURL(file_stack);
                                                                return (
                                                                    <div className="col-6" key={file_stack_i} >
                                                                        <div className="mutiple_image_frame card" style={{ height: 'auto' }}>
                                                                            <div className="promotion-delete">
                                                                                <button className="btn btn-danger"
                                                                                    type="button"
                                                                                    onClick={() => {
                                                                                        let file_stack = [...this.state.prepare_file_upload];
                                                                                        file_stack.splice(file_stack_i, 1);
                                                                                        this.setState({
                                                                                            prepare_file_upload: file_stack
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-trash-alt"></i>
                                                                                </button>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div id={"images_file_upload_" + file_stack_i} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </>
                                    : null
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                            <button type="submit" className="btn btn-primary"
                                ref={(ref) => { this.$submitBtn = window.$(ref) }}
                            >
                                เพิ่มชั่วโมงทำงาน
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

class BookingChangeDateTime extends React.Component {
    constructor(props) {
        super(props);
        let select_date = new Date().getTime();
        if (props.data && props.data.bookedAt) {
            select_date = props.data.bookedAt
        }
        this.state = {
            is_loading: true,
            date: select_date,
            time_value: [],
            time: Helper.getTime(select_date, { bookSelectHalfTime: true })
        }
    }

    componentDidMount() {
        this.fetch();

    }

    async fetch() {
        let data = await (await db.doc('constants/bookSelectHalfTime').get()).data();
        this.setState({
            time_value: data.value,
            is_loading: false,
        })
    }

    async update(data) {
        let bookedAtSet = new Date(Helper.getDateToEdit(data.bookedAt));
        let _data = {
            booksUid: data.bookUid,
            day: bookedAtSet.getDate(),
            month: bookedAtSet.getMonth(),
            year: bookedAtSet.getFullYear(),
            timeValue: data.timeValue,
            note: data.note,
            adminUid: this.props.adminUid,
            note: data.changeTimeRemark,
        }
        try {
            await axios.post(window.api_host + '/books-change-time', { ..._data }).then(async (res) => {
                // let res_data = res.data.data;
                // console.log(res_data);
                window.$(document).Toasts('create', {
                    title: 'เปลี่ยนวันเวลาที่ให้บริการสำเร็จ',
                    // body: "ระบบได้ทำการยกเลิก Booking เรียบร้อยแล้ว",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
                this.$modal.modal('hide');
            });
        } catch (error) {
            // console.log(error.response.data);
            let message = "เปลี่ยนวันเวลาที่ให้บริการไม่สำเร็จ";
            if (error.response.data.code === -1) {
                message = "ไม่พบ Booking";
            }
            if (error.response.data.code === -2) {
                message = "ไม่สามารภเปลี่ยนวันเวลาที่ให้บริการได้ใรสถานะ Booking นี้";
            }
            window.$(document).Toasts('create', {
                title: message,
                // body: res.data.message,
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submit.removeClass('loading');
        }

        // console.log(_data);
    }
    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        // console.log(data);
                        // return;
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                เปลี่ยนวันเวลาที่ให้บริการ
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            {
                                this.state.is_loading ?
                                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                                        <div className="ui active inline loader" ></div>
                                    </div>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <input type="hidden" name="bookUid" defaultValue={this.props.data.id} />
                                        <div className="form-group">
                                            <label htmlFor={"bookedAt"}>วันที่</label>
                                            <div>
                                                <DatePicker
                                                    onChange={date => {
                                                        this.setState({
                                                            date: new Date(date).getTime()
                                                        })
                                                    }}
                                                    locale="th-TH"
                                                    selected={Helper.getDateToEdit(this.state.date)}
                                                    placeholderText={"วัน / เดือน / ปี"}
                                                    name={"bookedAt"}
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    withPortal
                                                    onCalendarOpen={() => { Helper.renderDate() }}
                                                    renderCustomHeader={({
                                                        date,
                                                        changeYear,
                                                        changeMonth,
                                                        decreaseMonth,
                                                        increaseMonth,
                                                        prevMonthButtonDisabled,
                                                        nextMonthButtonDisabled
                                                    }) => {
                                                        let now = new Date();
                                                        // months_th
                                                        let selected_data = new Date(date);
                                                        const years = [];
                                                        let i;
                                                        for (i = 1960; i <= now.getFullYear() + 1; i++) {
                                                            years.push(i)
                                                        }
                                                        return (
                                                            <div
                                                                style={{
                                                                    margin: 10,
                                                                    display: "flex",
                                                                    justifyContent: "center"
                                                                }}
                                                            >
                                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                    className={"btn btn-primary "}
                                                                    type={"button"}
                                                                    style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                >
                                                                    <i className="fas fa-chevron-left" />
                                                                </button>
                                                                <select
                                                                    className={"form-control"}
                                                                    // defaultValue={new Date(this.state.date).getFullYear()}
                                                                    value={selected_data.getFullYear()}
                                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                                    style={{ borderRadius: 0 }}
                                                                >
                                                                    {years.map(option => (
                                                                        <option key={option} value={option} >
                                                                            {option + 543}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <select
                                                                    className={"form-control"}
                                                                    defaultValue={window.months_th[date.getMonth()]}
                                                                    onChange={({ target: { value } }) =>
                                                                        changeMonth(window.months_th.indexOf(value))
                                                                    }
                                                                    value={window.months_th[selected_data.getMonth()]}
                                                                    style={{ borderRadius: 0 }}
                                                                >
                                                                    {window.months_th.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                    className={"btn btn-primary"}
                                                                    style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                    type={"button"}
                                                                >
                                                                    <i className="fas fa-chevron-right" />
                                                                </button>
                                                            </div>
                                                        )
                                                    }}
                                                    customInput={<CustomDateInput />}
                                                />
                                                <input type="hidden" className="form-control" id="bookedAt" name="bookedAt" value={this.state.date} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>เวลา</label>
                                            <select
                                                ref={(ref) => { window.$(ref).select2(); }}
                                                className={"form-control select2"}
                                                name={"timeValue"}
                                                style={{ width: '100%' }}
                                                defaultValue={this.state.time}
                                            >
                                                {
                                                    this.state.time_value.map((time_value, time_value_i) => {
                                                        return (
                                                            <option value={time_value.value} key={time_value_i}>
                                                                {time_value.name}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>หมายเหตุ</label>
                                            <textarea className="form-control" name="changeTimeRemark"></textarea>
                                        </div>
                                    </>
                                    : null
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                            <button type="submit" className="btn btn-primary"
                                ref={(ref) => { this.$submitBtn = window.$(ref) }}
                            >
                                เปลี่ยนวันเวลาที่ให้บริการ
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

class BookingDateTimeList extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            history: [],
        }
    }

    async componentDidMount() {
        let history = await Promise.all(
            this.props.data.map(async (_data) => {
                let admin = await (await db.doc('admin/' + _data.adminUid).get()).data();
                return {
                    ..._data,
                    admin: admin,
                }
            })
        )
        // console.log(history);
        this.setState({
            is_loading: false,
            history: history,
        })
    }

    render() {
        // console.log(this.state.history);
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        // console.log(data);
                        // return;
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                ประวัติการเปลี่ยนวันเวลาที่ให้บริการ
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            {
                                this.state.is_loading ?
                                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                                        <div className="ui active inline loader" ></div>
                                    </div>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        {
                                            this.state.history.map((history, history_i) => {
                                                return (
                                                    <React.Fragment key={history_i}>
                                                        {history_i > 0 ? <div className="divider"></div> : null}
                                                        <div>
                                                            <label>ครั้งที่ : </label> {history_i + 1}
                                                        </div>
                                                        <div className="flex row" >
                                                            <div>

                                                                <div>
                                                                    <label>เวลาเดิม : </label> {Helper.getDateThai(history.currentBookAt, { is_show_time: true })}
                                                                </div>
                                                                <div>
                                                                    <label>เวลาใหม่ : </label> {Helper.getDateThai(history.updateBookedAt, { is_show_time: true })}
                                                                </div>
                                                                {
                                                                    history.note ?
                                                                        <div>
                                                                            <label>หมายเหตุ : </label> {history.note}
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                <div style={{ textAlign: 'right' }}><label>แก้ไขโดย : </label> {history.admin.displayName}</div>
                                                                <div style={{ textAlign: 'right' }}>{Helper.getDateThai(history.updatedAt, { is_show_time: true })}</div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })
                                        }
                                    </>
                                    : null
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ปิด</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

class AssignProvider extends React.Component {
    constructor() {
        super();
        this.state = {
            provider_users: [],
            is_loading: true,
        }
    }

    componentDidMount() {
        this.fetchProvider();
    }

    async fetchProvider() {
        let orderBy = 'firstName';
        let orderByType = 'asc';
        db.collection("provider_users_data").where('status', '==', 'avaliable').orderBy(orderBy, orderByType).get().then(async (doc) => {
            let get_data = await Promise.all(
                doc.docs.map(async (element) => {
                    let element_data = { ...element.data() };
                    return {
                        ...element_data,
                        id: element.id,
                    };

                })
            );
            // get_data = get_data.filter((d) => { return d !== null })
            // console.log(get_data)
            this.setState({
                provider_users: get_data,
                is_loading: false,
            })
        })
    }

    async assign(data) {
        // console.log(data);
        // console.log(this.state.provider_users);
        let providerRef = await db.collection('provider_users_data').where('phoneNumber', '==', data.providerUserPhoneNumber).get()
        let provider_data = false;
        if (providerRef.docs.length > 0) {
            provider_data = providerRef.docs[0].data();
            provider_data.uid = providerRef.docs[0].id;
        }
        delete data.providerUserPhoneNumber;

        let providerUserRef = await db.collection(`provider_users`).doc(provider_data.uid).get();
        if (!providerUserRef.exists) {
            window.$(document).Toasts('create', {
                title: 'แม่บ้านยังไม่ได้กดยอมรับเงื่อนไขการให้บริการ',
                // body: "ไม่พบแม่บ้านท่านนี้ในระบบ",
                body: "โปรดกดยอมรับเงือนไขบน Application",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submitBtn.removeClass('loading');
            return;
        }
        try {
            data.isForce = true;
            await axios.post(window.api_host + '/books-accept', { ...data }).then(async (res) => {
                let message = false;
                // let provider_data = this.state.provider_users.filter((ele) => {
                //     return ele.provider_user.id === data.providerUid
                // })
                if (res.data.code === -1) {
                    window.$(document).Toasts('create', {
                        title: 'แม่บ้านได้มีการรับงานแล้ว',
                        // body: "ไม่พบหมายเลข Booking นี้ในระบบ",
                        icon: 'fas fa-times',
                        autohide: true,
                        delay: 3000,
                        class: "bg-danger",
                    })
                    this.$submitBtn.removeClass('loading');
                    return;
                }
                if (res.data.code === 200) {
                    if (provider_data) {
                        message = 'ทำการมอบหมายงานให้แม่บ้าน' + provider_data.prefix + ' ' + provider_data.firstName + ' ' + provider_data.lastName + 'เรียบร้อยแล้ว';
                    }
                    window.$(document).Toasts('create', {
                        title: 'มอบหมายงานให้แม่บ้านสำเร็จ',
                        body: message,
                        icon: 'fas fa-check',
                        autohide: true,
                        delay: 3000,
                        class: "bg-success",
                    })
                    this.props.onSuccess();
                    this.$modal.modal('hide');
                }
            })
        } catch (error) {
            console.log(error)
            if (error.response.data.code === -1) {
                window.$(document).Toasts('create', {
                    title: 'แม่บ้านได้มีการรับงานแล้ว',
                    // body: "ไม่พบหมายเลข Booking นี้ในระบบ",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
            if (error.response.data.code === -2) {
                window.$(document).Toasts('create', {
                    title: 'สถานะ Booking ไม่ถูกต้อง',
                    // body: "ไม่พบแม่บ้านท่านนี้ในระบบ",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
            if (error.response.data.code === -20) {
                window.$(document).Toasts('create', {
                    title: 'ไม่สามารถดำเนินการได้',
                    body: "แม่บ้านรับงานก่อนหน้าในช่วงเวลานี้",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
            this.$submitBtn.removeClass('loading');
            return;
        }
    }


    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard="false" data-backdrop="static"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading') || this.state.is_loading) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        let ref = await db.collection('provider_users').where('phoneNumber', '==', data.providerUserPhoneNumber).get();
                        if (ref && ref.docs && ref.docs.length > 0) {
                            data.providerUserUid = ref.docs[0].id;
                        }
                        // delete data.providerUserPhoneNumber;
                        this.assign(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                เลือกแม่บ้าน
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            {
                                this.state.is_loading ?
                                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                                        <div className="ui active inline loader" ></div>
                                    </div>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className="form-group">
                                            <label>เลือกแม่บ้าน</label>
                                            <select
                                                ref={(ref) => { window.$(ref).select2(); }}
                                                className={"form-control select2"}
                                                name={"providerUserPhoneNumber"}
                                                style={{ width: '100%' }}
                                                defaultValue={this.props.providerUserPhoneNumber || ''}
                                            >
                                                {
                                                    this.state.provider_users.map((provider_user, provider_user_i) => {
                                                        return (
                                                            <option value={provider_user.phoneNumber} key={provider_user_i}>
                                                                {provider_user.prefix} {provider_user.firstName} {provider_user.lastName}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <input type="hidden" name={"booksUid"} defaultValue={this.props.bookUid} />
                                    </>
                                    : null
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal" disabled={this.state.is_loading}>ยกเลิก</button>
                            <button type="submit" className="btn btn-primary"
                                disabled={this.state.is_loading}
                                ref={(ref) => { this.$submitBtn = window.$(ref) }}
                            >
                                บันทึก
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

class BookingEditNote extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true
        }
    }

    componentDidMount() {
        this.setState({
            is_loading: false,
        })
    }

    async update(data) {
        try {
            let res = await axios.put(window.api_host + '/books-update', { uid: this.props.bookUid, update: { ...data } });
            if (res.data.code !== 200) {
                window.$(document).Toasts('create', {
                    title: 'เกิดข้อผิดพลาด',
                    body: res.data.message || false,
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
                this.$submitBtn.removeClass('loading');
                return;
            }
            if (res.data.code === 200) {
                window.$(document).Toasts('create', {
                    title: 'สำเร็จ',
                    body: res.data.message,
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                this.props.onSuccess();
                this.$modal.modal('hide');
            }
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: "กรุณาลองใหม่อีกครั้ง",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submitBtn.removeClass('loading');
            return;
        }
    }
    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard="false" data-backdrop="static"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading') || this.state.is_loading) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                แก้ไขหมายเหตุ
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            <div>
                                <label>หมายเหตุ</label>
                                <textarea className='form-control' name="note" defaultValue={this.props.note}></textarea>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal" disabled={this.state.is_loading}>ยกเลิก</button>
                            <button type="submit" className="btn btn-primary"
                                disabled={this.state.is_loading}
                                ref={(ref) => { this.$submitBtn = window.$(ref) }}
                            >
                                บันทึก
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}


class AdjustSalaryInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            tab_show: 1,
        };
    }
    render() {
        let adjustSalaryList = this.state.tab_show == 2 && this.state.booking.transferedAdjustSalary ? this.state.booking.transferedAdjustSalary : this.state.booking.adjustSalary;
        return (
            <div className="card collapsed-card">
                <div className="card-header" style={{ alignItems: 'center' }}>
                    <h4 className="card-title" id="myModalLabel">
                        ข้อมูลการปรับรายได้
                    </h4>
                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    {
                        this.state.booking?.transferedAdjustSalary ?
                            <select className="form-control form-group"
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.setState({
                                        tab_show: parseFloat(value || 1)
                                    }, () => {
                                        console.log(this.state.tab_show)
                                    })
                                }}
                            >
                                <option value="1">ข้อมูลก่อนโอน</option>
                                <option value="2">ข้อมูลหลังโอน</option>
                            </select>
                            : null
                    }
                    {
                        adjustSalaryList.length > 0 ?
                            adjustSalaryList.map((item, i) => {
                                return (
                                    <div className={"flex"} key={i}>
                                        {i > 0 ? <div className="divider"></div> : null}
                                        <div className='d-flex'>
                                            <div>
                                                <div>
                                                    <label>{item.type == 'special_wallet' ? 'เพิ่มเงินพิเศษ' : 'หักเงิน'}</label> :  {' '}
                                                    {Helper.numberFormat(item.amount, { decimal: 2 })} บาท
                                                </div>
                                                <div>
                                                    <label>แม่บ้าน </label>  :  {' '}
                                                    {
                                                        item.providerUserName ?
                                                            item.providerUserName
                                                            : `ไม่มีข้อมูล`
                                                    }
                                                </div>

                                                {
                                                    item.adjustType == 'customer' ?

                                                        <div>
                                                            <label>ภาษี </label>  :  {' '}
                                                            {Helper.numberFormat(item.vat || 0, { decimal: 2 })} บาท
                                                        </div>
                                                        : null
                                                }

                                            </div>
                                            <div style={{ marginLeft: 'auto' }}>
                                                <small>{Helper.getDateThai(item.updatedAt, { is_show_time: true })}</small>
                                                <div style={{ marginTop: 5 }}>
                                                    <label>ผู้ทำรายการ</label> :  {' '}
                                                    {item.updateBy.name}
                                                </div>
                                                {
                                                    item.adjustType == 'customer' ?
                                                        <small>หักเงินจากลูกค้า</small>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <label>หมายเหตุ</label> :  {' '}
                                            {item.reason}
                                        </div>
                                        {
                                            item.images
                                                && item.images.length > 0 ?
                                                <div>
                                                    <span
                                                        style={{ marginLeft: 'auto' }}
                                                        className="link"
                                                        href={item.images[0].imageURL}
                                                        rel={`adjust${i}`}
                                                        data-fancybox={`adjust_${i}`}
                                                    >หลักฐานการโอนเงิน {item.images.length} รูป</span>
                                                    {
                                                        item.images.map((image, image_i) => {
                                                            if (image_i === 0) { return null }
                                                            return (
                                                                <img style={{ display: 'none' }}
                                                                    data-fancybox={`adjust_${i}`}
                                                                    src={image.imageURL} alt={'adjust payment'} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                                : null
                                        }
                                    </div>
                                )
                            }) : <p>ไม่มีข้อมูล</p>
                    }

                </div>
            </div>
        )
    }
}