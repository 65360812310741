import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from './../Helper';
import { db } from './../firebase';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import '@progress/kendo-theme-default/dist/all.css';
export default class TransectionsReport extends React.Component {
    constructor() {
        super();
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        this.state = {
            data: [],
            is_loading: true,
            type: Helper.getParameterByName('type') ? Helper.getParameterByName('type') : false,
            start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
            end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
            years: years,
            year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
            month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
                end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                start_date: Helper.getParameterByName('start_date') ? Helper.getParameterByName('start_date') : new Date().getTime(),
                end_date: Helper.getParameterByName('end_date') ? Helper.getParameterByName('end_date') : new Date().getTime(),
            }, () => {
                this.fetchData();
            })
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        db.collection("books").orderBy('bookedAt', 'desc').get().then(async (doc) => {
            let data = [];
            let prepare_data = [];
            let count = 0;
            let provider_all = 0;
            let get_data = await Promise.all(
                doc.docs.map(async (element) => {
                    return {
                        ...element.data(),
                        id: element.id,
                    };

                })
            );
            get_data = get_data.filter((_data) => {
                return _data !== false
            })
            get_data = get_data.filter((_data) => {
                return _data.bookStatus === "done" || _data.bookStatus === "finish"
            })

            get_data.forEach((_data, _data_i) => {
                let bookdate = new Date(parseFloat(_data.bookedAt));
                if (((bookdate.getMonth() + 1) >= parseFloat(this.state.month)
                    && bookdate.getFullYear() >= parseFloat(this.state.year))
                    &&
                    (bookdate.getMonth() <= parseFloat(this.state.month)
                        && bookdate.getFullYear() <= parseFloat(this.state.year))
                ) {
                    count++;
                    prepare_data.push({ ..._data });
                }
            })

            data = await Promise.all(
                prepare_data.map(async (__data, __data_i) => {
                    let user = db.doc('users/' + __data.user.uid + '/');
                    let user_report = db.doc('users/' + __data.user.uid + '/report/' + __data.id + '/');
                    let user_report_data = await (await user_report.get()).data();
                    let user_data = await (await user.get()).data();
                    let provider_report = db.doc('provider_users/' + __data.providerUser.uid + '/report/' + __data.id + '/');
                    let provider_report_data = await (await provider_report.get()).data();
                    let promotion = db.doc('promotions/' + __data.promotionUid);
                    let promotion_data = await (await promotion.get()).data();
                    let _providerUser = db.doc('provider_users/' + __data.providerUser.uid);
                    let providerUserData = await (await _providerUser.get()).data();
                    let provideruser = { ...__data.providerUser, ...providerUserData }

                    if (provideruser.phoneNumber) {
                        let _provider_users_data = db.collection("provider_users_data").where("phoneNumber", "==", provideruser.phoneNumber)
                        let provider_users_data = await (await _provider_users_data.get()).docs[0];

                        if (!provideruser.displayName && provideruser.phoneNumber && provider_users_data) {
                            provideruser.displayName = provider_users_data.data().firstName + " " + provider_users_data.data().lastName
                        }
                    }
                    let bookStatusth = "ไม่มีสถานะ";

                    if (__data.bookStatus === "accept") {
                        bookStatusth = "ยอมรับ";
                    }
                    if (__data.bookStatus === "arrive") {
                        bookStatusth = "ถึงแล้ว";
                    }
                    if (__data.bookStatus === "done") {
                        bookStatusth = "เสร็จสิ้น";
                    }
                    if (__data.bookStatus === "finish") {
                        bookStatusth = "ส่งงาน";
                    }
                    if (__data.bookStatus === "going") {
                        bookStatusth = "กำลังเดินทาง";
                    }
                    if (__data.bookStatus === "wait") {
                        bookStatusth = "รอ";
                    }
                    if (__data.bookStatus === "working") {
                        bookStatusth = "กำลังทำงาน";
                    }
                    if (__data.bookStatus === "cancel") {
                        bookStatusth = "ยกเลิก";
                    }
                    let payment = false;
                    let transectionsRef = await db.collection('transactions').where('booksUid', '==', __data.id).get();
                    let transactionData = await transectionsRef.docs[0].data();
                    if (transectionsRef.size > 0 && transactionData.omise) {
                        let _data = { ...transactionData }
                        // console.log(_data);
                        _data.statusText = "ไม่สำเร็จ";
                        if (_data.status === 'successful') {
                            _data.statusText = "สำเร็จ";
                        }
                        _data.paymentBy = false;
                        if (_data.omise.card) {
                            _data.paymentBy = 'บัตรเครดิต'
                        }
                        payment = _data;
                    }
                    __data.payment = payment;
                    let summary = Helper.bookingSummary({ booking: __data, payment: __data.payment });
                    console.log(summary);
                    // let priceUserPlus = 0;
                    // let priceUserAfterDiscount = (__data.price - __data.priceDiscount);
                    // let totalUserPrice = (__data.price - __data.priceDiscount) + __data.chargePrice;

                    // let bookedAtDate = Helper.getDateThai(new Date(__data.bookedAt), { is_show_time: true });
                    // let priceProviderPlus = 0;
                    // let totalPriceProvider = __data.priceProvider + priceProviderPlus;

                    // let priceApp = (__data.price - __data.priceDiscount) - __data.priceProvider;
                    // let priceAppPlus = 0;
                    // let totalPriceApp = ((__data.price - __data.priceDiscount) - __data.priceProvider) + __data.chargePrice;


                    return ({
                        ...__data,
                        bookStatusth: bookStatusth,
                        // bookedAtDate: bookedAtDate,
                        summary: summary,
                        user_data: user_data,
                        user_report: user_report_data,
                        provider_report: provider_report_data,
                        promotion: promotion_data,
                        providerUser: provideruser,
                        // priceProviderPlus: priceProviderPlus,
                        // totalPriceProvider: totalPriceProvider,
                        // priceApp: priceApp,
                        // priceAppPlus: priceAppPlus,
                        // totalPriceApp: totalPriceApp,
                        // priceUserPlus: priceUserPlus,
                        // totalUserPrice: totalUserPrice,
                        // priceUserAfterDiscount: priceUserAfterDiscount,
                        payment: payment
                    })
                })
            );
            this.setState({
                data: data,
                is_loading: false,
                all_items: count,
                provider_all: provider_all,
            })
        })
    }

    render() {
        let fileName = 'รายงาน';
        if (this.state.type === 'user') {
            fileName += 'สมาชิก'
        }
        if (this.state.type === 'provider') {
            fileName += 'แม่บ้าน'
        }
        fileName += ' ' + Helper.getDateThai(parseFloat(this.state.start_date)) + ' - ' + Helper.getDateThai(parseFloat(this.state.end_date));
        let transectionsData = [];
        this.state.data.map((_data, _data_i) => {
            let push_data = {
                date: 'ไม่พบข้อมูล',
                userName: 'ไม่พบข้อมูล',
                workingDetails: '',
                bookingNo: 'ไม่พบข้อมูล',
                paymentBy: 'ไม่พบข้อมูล',
                totalPriceIncludeVat: 0,
                totalTaxPrice: 0,
                appPrice: 0,
                holdingTaxPrice: 0,
                vatPrice: 0,
                totalPrice: 0,
                providerName: 'ไม่พบข้อมูล',
                providerPrice: 0,
            }
            push_data.date = Helper.getDateThai(_data.bookedAt, { is_show_time: true });
            push_data.userName = _data.user_data.displayName;
            push_data.workingDetails += _data.address.name + ' ';
            push_data.workingDetails += _data.address.placeType.name_th + ' ';
            push_data.workingDetails += _data.address.placeType.place_sub_type.description_th + ' ';
            push_data.workingDetails += _data.bookTime.name_th + ' ';
            push_data.bookingNo = _data.bookOn;
            push_data.paymentBy = _data.payment.paymentBy || 'ไม่พบข้อมูล';
            push_data.totalPriceIncludeVat = _data.summary.total_price;
            push_data.totalTaxPrice = _data.summary.tax_total_price;
            push_data.appPrice = _data.summary.plustime_app_price;
            push_data.holdingTaxPrice = 0;
            push_data.vatPrice = _data.summary.tax_total_price;
            push_data.totalPrice = _data.summary.plustime_total_with_tax_price;
            push_data.providerName = _data.providerUser.displayName || null;
            push_data.providerPrice = _data.summary.plustime_provider_price;
            transectionsData.push({ ...push_data })
            return true;
        })


        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายงาน Booking</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/booking">รายการ Booking</Link></li>
                                    <li className="breadcrumb-item active">
                                        รายงาน Booking
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header "
                                style={{ display: 'flex' }}
                            >
                                <h3 className="card-title" style={{ display: 'flex', alignItems: 'center' }}>รายการ Booking</h3>
                            </div>
                            <div className="card-body">
                                {
                                    this.state.is_loading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                {
                                    !this.state.is_loading ?
                                        <>

                                            <div className={"flex row"}
                                                style={{ marginBottom: '1rem' }}
                                            >
                                                <form
                                                    className="row flex"
                                                    ref={(ref) => { this.$searchForm = window.$(ref) }}
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        let data = Helper.getFormInputObject(this.$searchForm);
                                                        let url = Helper.getQueryStringLinkFromObject({
                                                            ...data
                                                        })
                                                        navigate(url)
                                                    }}
                                                >
                                                    <input type="hidden" name="type" value={this.state.type} />
                                                    <div className={"flex row merge-selectbox"} style={{ width: 250 }}>
                                                        <select className={"form-control form-control-sm"}
                                                            name={"month"}
                                                            style={{ flex: 1 }}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    month: e.target.value,
                                                                    is_chart_loading: true,
                                                                }, () => {
                                                                    this.$searchForm.trigger('submit')
                                                                })
                                                            }}
                                                            defaultValue={this.state.month}
                                                        >
                                                            {
                                                                window.months_th.map((month, month_i) => (
                                                                    <option value={month_i} key={month_i}>{month}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <select className={"form-control form-control-sm"}

                                                            style={{ flex: 1 }}
                                                            name={"year"}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    year: e.target.value,
                                                                    is_chart_loading: true,
                                                                }, () => {
                                                                    this.$searchForm.trigger('submit')
                                                                })
                                                            }}
                                                            defaultValue={this.state.year}
                                                        >
                                                            {
                                                                this.state.years.map((years) => (
                                                                    <option value={years} key={years}>{years}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="input-group-append" style={{ display: 'none' }}>
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                                {
                                                    !this.state.is_loading ?
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            <button
                                                                title="Export Excel"
                                                                className="btn btn-default"
                                                                onClick={() => { this._export.save() }}
                                                            >
                                                                โหลดรายงาน
                                        </button>
                                                        </div>
                                                        : null
                                                }
                                            </div>

                                            <ExcelExport
                                                fileName={fileName}
                                                data={transectionsData}
                                                ref={exporter => this._export = exporter}
                                            >
                                                <Grid data={transectionsData} style={{ height: '500px' }}>
                                                    <GridColumn field="date" title="เวลา Booking" width="230" />
                                                    <GridColumn field="userName" title="ชื่อผู้เรียกบริการ" width="200" />
                                                    <GridColumn field="workingDetails" title="รายการเรียกบริการ" width="250" />
                                                    <GridColumn field="bookingNo" title="หมายเลข Booking" width="150" />
                                                    <GridColumn field="paymentBy" title="ช่องทางชำระ" width="150" />
                                                    <GridColumn field="totalPriceIncludeVat" title="ราคา (included vat)" width="150" />
                                                    <GridColumn field="totalTaxPrice" title="VAT 7%" width="150" />
                                                    <GridColumn field="appPrice" title="ค่าธรรมเนียม ชำระเงิน" width="150" />
                                                    <GridColumn field="holdingTaxPrice" title="ภาษีหัก ณ ที่จ่าย" width="150" />
                                                    <GridColumn field="vatPrice" title="ภาษี" width="150" />
                                                    <GridColumn field="totalPrice" title="รายได้ก่อนหักส่วนแบ่งของแม่บ้าน" width="200" />
                                                    <GridColumn field="providerName" title="แม่บ้านที่ให้บริการ" width="150" />
                                                    <GridColumn field="providerPrice" title="ส่วนแบ่งแม่บ้าน" width="150" />

                                                </Grid>
                                            </ExcelExport>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}