import React, { useState, useEffect } from "react";
import { Helper } from "../../../Helper";
const axios = require('axios').default;

export default function ReviewToProvider(props) {

    return (
        <>
            <small class="link"
                onClick={() => {
                    Helper.reactToDom(window.$('body'),
                        <ReviewModalForm

                            bookUid={props.bookUid}
                            adminUid={props.adminUid}
                            onSuccess={props?.onSuccess}
                        />)
                }}
            >รีวิว</small>
        </>
    )

}


class ReviewModalForm extends React.Component {
    constructor() {
        super();
        this.state = {
            rateClean: [false, false, false, false]
        }
    }


    async update(data) {
        // console.log(data);
        // return;
        data.rateClean = JSON.stringify(this.state.rateClean)
        try {
            await axios.post(window.api_host + '/review-to-provider', { ...data }).then(async (res) => {
                window.$(document).Toasts('create', {
                    title: 'รีวิวแม่บ้าน',
                    body: 'ทำการรีวิวแม่บ้านเรียบร้อยแล้ว',
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                this.props.onSuccess();
                this.$modal.modal('hide');
            })
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'รีวิวแม่บ้าน',
                body: "รีวิวแม่บ้านไม่สำเร็จ กรุณาเลือกแม่บ้านใหม่อีกครั้ง",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })

            this.$submitBtn.removeClass('loading');
            return;
        }
    }

    updateRateClean(number) {
        let rateClean = this.state.rateClean;
        rateClean[number] = !this.state.rateClean[number];
        this.setState({
            rateClean
        })
    }

    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard="false" data-backdrop="static"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading') || this.state.is_loading) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        // if (!data.rating) {
                        //     Helper.messageTop({ message: 'กรุณากรอกหมายเหตุ' })
                        //     this.$submitBtn.removeClass('loading');
                        //     return;
                        // }
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                รีวิวแม่บ้าน
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            {
                                this.state.is_loading ?
                                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                                        <div className="ui active inline loader" ></div>
                                    </div>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className="form-group">
                                            <RenderRating />
                                        </div>
                                        <div className={"form-group"}>
                                            <label>รายงานจากสมาชิก</label>
                                            <textarea name={"reportFromUser"} className={"form-control"}></textarea>
                                        </div>
                                        <div className={"row"} >
                                            <div className={"col-lg-3"}>
                                                <div className={this.state.rateClean[0] ? "raing_icon check" : "raing_icon"}
                                                    onClick={() => {
                                                        this.updateRateClean(0)
                                                    }}>
                                                    <div className={"rating_circle"}>
                                                        <img src={'/assets/images/clean.png'} alt={"Clean"} />
                                                    </div>
                                                    <div>
                                                        Clean & Comfy
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-lg-3"}>
                                                <div className={this.state.rateClean[1] ? "raing_icon check" : "raing_icon"}
                                                    onClick={() => {
                                                        this.updateRateClean(1)
                                                    }}
                                                >
                                                    <div className={"rating_circle"}>
                                                        <img src={'/assets/images/women-icon.png'} alt={"Clean"} />
                                                    </div>
                                                    <div>
                                                        Good Personality
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-lg-3"}>
                                                <div className={this.state.rateClean[2] ? "raing_icon check" : "raing_icon"}
                                                    onClick={() => {
                                                        this.updateRateClean(2)
                                                    }}>
                                                    <div className={"rating_circle"}>
                                                        <img src={'/assets/images/chat-icon.png'} alt={"Clean"} />
                                                    </div>
                                                    <div >
                                                        Good Communicate
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-lg-3"}>
                                                <div className={this.state.rateClean[3] ? "raing_icon check" : "raing_icon"}
                                                    onClick={() => {
                                                        this.updateRateClean(3)
                                                    }}>
                                                    <div className={"rating_circle"}>
                                                        <img src={'/assets/images/award-icon.png'} alt={"Clean"} />
                                                    </div>
                                                    <div>
                                                        Excellent Service
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="hidden" name={"bookUid"} defaultValue={this.props.bookUid} />
                                        <input type="hidden" name={"adminUid"} defaultValue={this.props.adminUid} />
                                    </>
                                    : null
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal" disabled={this.state.is_loading}>ยกเลิก</button>
                            <button type="submit" className="btn btn-primary"
                                disabled={this.state.is_loading}
                                ref={(ref) => { this.$submitBtn = window.$(ref) }}
                            >
                                รีวิวแม่บ้าน
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

function RenderRating() {
    const [rating, setRating] = useState(0)
    const allStars = 5;
    const stars = [];
    for (let index = 1; index <= allStars; index++) {
        stars.push(index)
    }
    return (
        <div className="d-flex">
            {
                stars.map((i) => (<i
                    className="fas fa-star"
                    style={{ fontSize: starSize, color: rating < i ? emptyStarColor : fullStarColor }}
                    onClick={() => {
                        console.log('rating' + i)
                        setRating(i)
                    }}
                ></i>))
            }
            <input type="hidden" name="starFromUser" value={rating} />
        </div>
    )
}

const fullStarColor = `#ffa200`;
const emptyStarColor = `#ccc`;
const starSize = `30px`;