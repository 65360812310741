import React from "react";
import { Helper } from "../Helper";
import CustomDateInput from "./CustomDateInput";
import DatePicker from "react-datepicker";
import { db, storage } from './../firebase';
const axios = require('axios').default;


export default class AdjustForm extends React.Component {
    constructor() {
        super();
        this.state = {
            selected_booking_date: new Date(),
            type: '',
            file_stack: [],
            remove_image: [],
            uploaded_images: [],
            vat: 0
        }
    }
    readURL(input) {
        if (input.files && input.files.length > 0) {
            let prepare_file_stack = [...this.state.file_stack];
            for (var key of Object.keys(input.files)) {
                prepare_file_stack.push(input.files[key]);
            }
            let file_stack = [];
            file_stack = [...prepare_file_stack]
            // file_stack.push(prepare_file_stack[prepare_file_stack.length - 1]);
            this.setState({
                file_stack: file_stack
            }, () => {
                this.$file.val('');
            })
        }
    }
    async uploadFile(upload_index, data, options) {
        let file = this.state.file_stack[upload_index];
        // console.log(this.state.file_stack)
        // console.log(file)
        const directory = 'adjust/'
        const filename = Helper.setNewImageFileName(file);
        let reszieFile = await Helper.resizeImageFn(file, { maxWidth: 600, maxHeight: 600 });
        var uploadTask = storage.ref().child(directory + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // console.log(snapshot);
        }, async (error) => {
            // console.log(error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            for (const uploaded_image of this.state.uploaded_images) {
                await storage.ref().child(uploaded_image.storagePath).delete();
            }
            this.clearButton();
            this.setState({
                is_loading: false,
                file_stack: [],
                remove_image: [],
                image_paths: [],
                uploaded_images: [],
                data: false,
            })
            return;
        }, async () => {
            let url = directory + filename;
            const photoURL = await storage.ref(url).getDownloadURL();
            let uploaded_images = [...this.state.uploaded_images];
            uploaded_images.push({
                imageURL: photoURL,
                storagePath: url,
            })
            this.setState({
                uploaded_images: uploaded_images,
            }, async () => {
                upload_index++;
                if (upload_index >= this.state.file_stack.length) {
                    this.update(data);
                    return;
                }
                if (upload_index < this.state.file_stack.length) {
                    this.uploadFile(upload_index, data);
                }
            })
        });
    }
    async update(data) {
        let send = { ...data };
        if (this.props.deposit_wallet) {
            send.walletId = this.props.deposit_wallet.id;
        }
        send.bookUid = this.props.bookUid;
        send.providerUid = this.props.providerUid;
        if (this.props.admin) {
            send.updatedBy = {
                name: this.props.admin.displayName.stringValue,
                id: this.props.admin.id.stringValue,
            }
        }
        send.images = []
        if (this.state.uploaded_images.length > 0) {
            for (const image of this.state.uploaded_images) {
                send.images.push({
                    imageURL: image.imageURL,
                    storagePath: image.storagePath,
                })
            }
        }

        try {
            let res = await axios.post(`${window.api_host}/adjust-salary`, { ...send });
            if (res.data && res.data.success) {
                Helper.messageTop({
                    message: `ทำรายการสำเร็จ`,
                    type_class: 'alert-success'
                })
                this.$modal.modal('hide');
                if (this.props.onSuccess) {
                    this.props.onSuccess()
                }
                this.$submit.removeClass('loading')
            } else {
                Helper.messageTop({
                    message: res.data && res.data.message ? res.data.message : 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
                })
                this.$submit.removeClass('loading')
            }
        } catch (error) {
            Helper.messageTop({
                message: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
            })
            this.$submit.removeClass('loading')
        }
    }

    calculateVat() {
        let amount = 0;
        let amoutNode = window.$(`input[name="amount"]`);
        let vatNode = window.$(`input[name="vat"]`);
        if (amoutNode?.val()) {
            amount = parseFloat(amoutNode.val());
        }
        let vatAmount = (amount * 7) / 100;
        vatNode.val(vatAmount)
    }

    render() {
        let bookingDate = new Date(this.state.selected_booking_date);
        let bookingDaY = bookingDate.getDate();
        let bookingMonth = bookingDate.getMonth();
        let bookingYear = bookingDate.getFullYear();
        if (parseFloat(bookingDaY) < 10) {
            bookingDaY = `0${bookingDaY}`
        }
        if (parseFloat(bookingMonth) < 10) {
            bookingMonth = `0${bookingMonth}`
        }
        let bookingDateFormat = `${bookingYear}-${bookingMonth}-${bookingDaY}`;
        // let maxAmount = parseFloat(this.props.deposit_wallet.walletTotal);
        console.log(this.props.providerUid)
        return (
            <div className="modal fade"
                id="edit_bank"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        let submit_btn = this.$submit;
                        if (submit_btn.hasClass('loading')) { return false; }
                        submit_btn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        if (!data.type) {
                            Helper.messageTop({
                                message: 'เกิดข้อผิดพลาด กรุณาเลือกประเภทในการปรับเงิน'
                            })
                            submit_btn.removeClass('loading')
                            return;
                        }

                        if (this.state.file_stack.length === 0) {
                            this.update(data);
                            return;
                        }
                        this.uploadFile(0, { ...data });
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">ปรับเงินแม่บ้าน {this.props.providerName ? ` : ${this.props.providerName}` : null}</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <TypeForm />
                            </div>
                            <div className="form-group">
                                <label>จำนวนเงิน</label>
                                <input
                                    required={true}
                                    name="amount"
                                    className="form-control"
                                    type="number"
                                    onChange={() => {
                                        this.calculateVat();
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label>หมายเหตุ</label>
                                <textarea required={true} name="reason" className="form-control" />
                            </div>

                            <div>
                                <div className="row">
                                    {
                                        this.state.file_stack
                                            && this.state.file_stack.length > 0 ?
                                            this.state.file_stack.map((file_stack, file_stack_i) => {
                                                var reader = new FileReader();
                                                reader.onload = (e) => {
                                                    window.$('#images_file_upload_' + file_stack_i).html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
                                                };
                                                reader.readAsDataURL(file_stack);
                                                return (
                                                    <div className="col-4" key={file_stack_i} >
                                                        <div className="mutiple_image_frame card" style={{ height: 'auto' }}>
                                                            <div className="promotion-delete">
                                                                <button className="btn btn-danger"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        let file_stack = [...this.state.file_stack];
                                                                        file_stack.splice(file_stack_i, 1);
                                                                        this.setState({
                                                                            file_stack: file_stack
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                            <div className="card-body" style={{ padding: 8 }}>
                                                                <div id={"images_file_upload_" + file_stack_i} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : null
                                    }
                                    {
                                        this.state.image_paths
                                            && this.state.image_paths.length > 0 ?
                                            this.state.image_paths.map((photoPath, photoPath_index) => {

                                                return (
                                                    <div className="col-4" key={photoPath_index} >
                                                        <div className="mutiple_image_frame card" style={{ height: 'auto' }}>
                                                            <div className="promotion-delete">
                                                                <button className="btn btn-danger"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        // console.log(photoPath_index);
                                                                        let remove_image = [...this.state.remove_image];
                                                                        let image_paths = [...this.state.image_paths];
                                                                        image_paths.splice(photoPath_index, 1);
                                                                        remove_image.push(photoPath.storagePath);
                                                                        this.setState({
                                                                            remove_image: remove_image,
                                                                            image_paths: image_paths,
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                            <div className="card-body" style={{ padding: 8 }}>
                                                                <img src={photoPath.imageURL} alt={"EventAndPromotion"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : null
                                    }
                                </div>
                            </div>
                            <div className={"form-group"}>
                                <label>รูปภาพ</label>
                                <input name="image"
                                    type={"file"}
                                    accept="image/*"
                                    ref={(ref) => this.$file = window.$(ref)}
                                    style={{ display: 'none' }}
                                    id="file_image"
                                    multiple="multiple"
                                    onChange={(e) => {
                                        if (this.$file.parent().find('.btn').hasClass('loading')) {
                                            return;
                                        }
                                        this.readURL(this.$file[0]);
                                    }}
                                />
                                <label htmlFor="file_image"
                                    type={"button"}
                                    className="btn btn-default"
                                    style={{ width: '100%', marginBottom: 0 }}
                                >
                                    อัพโหลด
                                </label>
                            </div>
                            <div className="custom-control custom-switch">
                                <input type="checkbox"
                                    className="custom-control-input"
                                    id="adjustType"
                                    name={"adjustType"}
                                    defaultValue={"customer"}
                                    onChange={(e) => {
                                        if (window.$(e.target).prop("checked")) {
                                            this.calculateVat();
                                            window.$('#adjustVat').show()
                                        } else {
                                            window.$('#adjustVat').hide()
                                        }
                                    }}
                                    defaultChecked={this.state.data?.adjustType == 'customer' || ''}
                                />
                                <label className="custom-control-label" htmlFor="adjustType">เรียกเก็บเงินจากลูกค้า</label>
                            </div>
                            <input name="date" value={bookingDateFormat} type="hidden" />
                            <input name="dateTime" value={bookingDate.getTime()} type="hidden" />
                            <div style={{ display: `none` }} id="adjustVat">
                                <div className="form-group">
                                    <label>ภาษี</label>
                                    <input
                                        name="vat"
                                        className="form-control"
                                        type="text"
                                        defaultValue={0}
                                        onChange={(e)=> {
                                            let value = e.target.value;
                                            console.log(`this.state.vat`,this.state.vat)
                                            if(isNaN(value)) {
                                                if(!this.state.vat) {
                                                    value = 0;
                                                }
                                                // value = this.state.vat;
                                                e.target.value = this.state.vat;
                                                return;
                                            }
                                            this.state.vat = value;

                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type={"button"} className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >ยกเลิก</button>
                            <button type={"submit"} className="btn btn-primary">ดำเนินการ</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
class TypeForm extends React.Component {
    constructor() {
        super();
        this.state = {
            selected_booking_date: new Date(),
            type: '',
        }
    }
    render() {
        return (
            <div className="btn-group">
                <button
                    type="button"
                    className={this.state.type == "special_wallet" ? "btn btn-default active" : "btn btn-default"}
                    onClick={() => {
                        this.setState({
                            type: "special_wallet",
                        })
                    }}
                >
                    เพิ่มเงิน
                </button>
                <button
                    type="button"
                    className={this.state.type == "withhold_wallet" ? "btn btn-default active" : "btn btn-default"}
                    onClick={() => {
                        this.setState({
                            type: "withhold_wallet",
                        })
                    }}
                >
                    หักเงิน
                </button>
                <input type="hidden" name="type" value={this.state.type} />
            </div>
        )
    }
}