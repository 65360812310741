import httpClient, { serverUrl } from './HttpClient';
const _serverUrl = serverUrl;

const baseUrl = `${_serverUrl}/agent-salary`;

export const agentSalaryService = {
    getBookWithoutSalary: async (uid) => {
        const url = `${baseUrl}-books/${uid}`;
        try {
            const res = await httpClient.get(url)
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    get: async (uid) => {
        const url = `${baseUrl}/${uid}`;
        try {
            const res = await httpClient.get(url)
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    getList: async (page, limit, name) => {
        const url = `${baseUrl}/${page}/${limit}?${window.$.param({ name: name })}`;
        try {
            const res = await httpClient.get(url)
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    create: async ({ title, minPrice, note }) => {
        const url = `${baseUrl}`;
        try {
            const res = await httpClient.post(url, { title, minPrice, note })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    update: async ({ uid, bank, bankBranch, bankAccountName, bankAccountNo }) => {
        const url = `${baseUrl}`;
        try {
            const res = await httpClient.post(url, { agentUid: uid, bank, bankBranch, bankAccountName, bankAccountNo })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    delete: async (salaryAgentUid) => {
        const url = `${baseUrl}/${salaryAgentUid}`;
        try {
            const res = await httpClient.delete(url)
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    pay: async ({ agentUid, salaryAgentUid, allImages, slipImageURL, slipUrl, transferBank, transferPrice, bookSelected }) => {
        const url = `${baseUrl}/pay`;
        try {
            const res = await httpClient.put(url, { agentUid, salaryAgentUid, allImages, slipImageURL, slipUrl, transferBank, transferPrice, bookSelected })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    payCancel: async ({ agentUid, salaryAgentUid }) => {
        const url = `${baseUrl}/pay/cancel`;
        try {
            const res = await httpClient.put(url, { agentUid, salaryAgentUid })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    payConfirm: async ({ agentUid, salaryAgentUid }) => {
        const url = `${baseUrl}/confirm`;
        try {
            const res = await httpClient.put(url, { agentUid, salaryAgentUid })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    payDelete: async ({ agentUid, salaryAgentUid }) => {
        const url = `${baseUrl}/pay/${agentUid}/${salaryAgentUid}`;
        try {
            const res = await httpClient.delete(url)
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    }
}