import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../../Helper';
import { db, storage } from '../../firebase';
import { BankForm } from '../ViewProviderUsers'
import Pagination from '../../Components/Pagination';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import Papa from "papaparse";
// import CustomDateInput from './../Components/CustomDateInput';
// import DatePicker from "react-datepicker";
// import { count } from '@progress/kendo-data-query/dist/npm/array.operators';
import BankCode from '../../bankCode';
import { salaryService } from '../../Services/salaryService';

const axios = require('axios').default;
const collectionSalary = "salary";
const collectionSalaryProvider = "salary-provider";
const collectionProviderUser = 'provider_users';
const collectionProviderUserData = 'provider_users_data';
export default class CSVSalaryProvider extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            allProvider: [],
            data: [],
            salary: false,
            all_items: 0,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : '',
            orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : '',
            is_show_next_button: false,
            maid_count: 0,
            total_payment: 0,
            booking_count: 0,
            data_count: 0,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                data: [],
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                orderBy: Helper.getParameterByName('orderBy') ? Helper.getParameterByName('orderBy') : '',
                orderByType: Helper.getParameterByName('orderByType') ? Helper.getParameterByName('orderByType') : '',
                is_show_next_button: false,
                data_count: 0,
            }, () => {
                this.fetch();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetch();
            })
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch(refresh) {
        console.log('fetch')
        let all_items_count = this.state.all_items;
        let booking_count = this.state.booking_count;
        let total_payment = this.state.total_payment;
        let count = this.state.now_item;
        let prepare_data = [];
        let data = [];
        let count_provider_success_status = 0;
        let salary_data = this.state.salary;
        if (!salary_data) {
            let salaryRef = await db.doc(`${collectionSalary}/` + this.props.salary_id).get();
            salary_data = salaryRef.data();
        }
        let provider = [];
        let get_provider_users = [];
        this.setState({
            salary: salary_data,
            data: data,
            all_items: all_items_count,
            now_item: count,
            maid_count: all_items_count,
            total_payment: total_payment,
            booking_count: booking_count,
        }, async () => {
            if (this.state.salary.status == "successful") {
                // let page = this.state.page || 1;
                // let limit = this.state.limit || 10;
                // let offset = parseFloat(limit) * (parseFloat(page) - 1);
                if (!this.state.allProvider
                    || (this.state.allProvider && this.state.allProvider.length == 0)
                ) {
                    let salaryProviderRef = await db.collection(`${collectionSalary}/` + this.props.salary_id + '/provider').get();
                    for (const doc of salaryProviderRef.docs) {
                        if (doc.data().status === 'success') {
                            count_provider_success_status++;
                        }
                        let providerData = db.doc(`${collectionProviderUserData}/` + doc.data().providerDataUID).get();
                        get_provider_users.push(providerData);

                        let books_count = doc.data().default_booking_count;
                        total_payment += doc.data().defaultPrice;
                        booking_count += books_count;
                        let image_paths = [];
                        if (doc.data().slipImageURL) {
                            let slipImageType = typeof doc.data().slipImageURL;
                            if (slipImageType === 'object') {
                                let i = 0;
                                for (const image of doc.data().slipImageURL) {
                                    image_paths.push({
                                        imageURL: image,
                                        storagePath: doc.data().slipUrl[i]
                                    })
                                    i++;
                                }
                            }
                            if (slipImageType === 'string') {
                                image_paths.push({
                                    imageURL: doc.data().slipImageURL,
                                    storagePath: doc.data().slipUrl
                                })
                            }
                        }
                        let _data = {
                            ...doc.data(),
                            paymentStatus: doc.data().status,
                            image_paths: image_paths,
                            id: doc.id,
                        };
                        provider.push(_data)
                    }

                    let get_provider_users_await = await Promise.all(get_provider_users);
                    let i = 0;
                    for (const doc of get_provider_users_await) {
                        let providerData = doc.data();
                        let _provider = false;
                        let _provider_user = false;
                        if (doc && providerData && providerData.phoneNumber) {
                            let _providerData = await db.collection(collectionProviderUser).where('phoneNumber', '==', providerData.phoneNumber).get();
                            if (_providerData.exists) {
                                if (!_providerData.empty) {
                                    _provider = _providerData.docs[0].data();
                                }
                                if (_provider) {
                                    _provider_user = _provider;
                                }

                                delete providerData.updateBy;
                                delete providerData.ban;
                                delete providerData.traningUID;
                                delete providerData.tranningStatus;
                                delete providerData.provincesCode;
                                delete providerData.amphoesCode;
                                delete providerData.amphoe;
                                delete providerData.province;
                                delete providerData.status;

                            }
                        }
                        let _data = {
                            ...provider[i],
                            ...providerData,
                            provider_user: _provider_user,
                        };

                        _data.displayName = _data.firstName + ' ' + _data.lastName;
                        provider[i] = _data;
                        i++;
                    }


                    all_items_count = provider.length;
                    // if (provider.length === count_provider_success_status) {
                    //     await db.doc(`${collectionSalary}/` + this.props.salary_id).update({ status: 'successful' });
                    //     salary_data.paymentStatus = 'successful';
                    // }
                    provider.forEach((_data, _data_i) => {
                        let match_search = 0;
                        if (this.state.name && this.state.name !== "") {
                            let displayName = _data.firstName + ' ' + _data.lastName;
                            match_search = displayName.toLowerCase().indexOf(this.state.name.toLowerCase());
                            if (match_search === -1) {
                                match_search = _data.phoneNumber.indexOf(this.state.name)
                            }
                            if (match_search === -1) {
                                match_search = _data.phoneNumber.replace('+66', '0').indexOf(this.state.name)
                            }
                            if (match_search === -1) {
                                match_search = _data.personalNo.indexOf(this.state.name)
                            }
                            if (match_search === -1) {
                                match_search = _data.personalNo.split(' ').join('').indexOf(this.state.name)
                            }
                            if (match_search !== -1) {
                                count++;
                                prepare_data.push({ ..._data });
                            }
                        } else {
                            count++;
                            prepare_data.push({ ..._data });
                        }
                    })
                } else {
                    prepare_data = this.state.allProvider;
                }

                prepare_data = prepare_data.sort(function (a, b) {
                    if (a.displayName > b.displayName) {
                        return 1;
                    }
                    if (a.displayName < b.displayName) {
                        return -1;
                    }
                    return 0;
                });

                data = prepare_data.filter((__data, __data_i) => {
                    return __data_i < (this.state.limit * this.state.page)
                        && __data_i > (((this.state.limit * this.state.page) - this.state.limit) - 1)
                })
                let createdAdmin = await (await db.doc('admin/' + salary_data.createdAdminUid).get()).data();
                salary_data.admin = createdAdmin;
                this.setState({
                    allProvider: prepare_data,
                    salary: salary_data,
                    data: data,
                    all_items: all_items_count,
                    now_item: count,
                    maid_count: all_items_count,
                    total_payment: total_payment,
                    booking_count: booking_count,
                    is_loading: false,
                    is_absolute_loading: false,
                })
            } else {
                this.setState({
                    is_loading: false,
                    is_absolute_loading: false,
                })
            }
        })


    }

    async delete(_data) {
        // let data = {};
        // data.totalPrice = this.state.salary.totalPrice - _data.defaultPrice;
        // await db.doc('/salary/' + this.props.salary_id).update({ ...data })
        await db.doc(`${collectionSalary}/` + this.props.salary_id + '/provider/' + _data.id).delete();
        window.$(document).Toasts('create', {
            title: 'ลบข้อมูลสำเร็จ',
            icon: 'fas fa-check',
            autohide: true,
            delay: 3000,
            class: "bg-success",
        });
        let collection_count = await db.collection(`${collectionSalary}/` + this.props.salary_id + '/provider').get();
        if (collection_count.docs.length === 0) {
            await db.doc(`${collectionSalary}/` + this.props.salary_id).delete();
            navigate('/csv-salary')
        }

        let salaryProviderRef = await db.collection(`${collectionSalary}/` + this.props.salary_id + '/provider').get();
        let summary = 0;
        for (const doc of salaryProviderRef.docs) {
            summary += doc.data().defaultPrice;
        }
        await db.collection(collectionSalary).doc(this.props.salary_id).update({ totalPrice: summary });
        this.setState({
            is_loading: true,
        }, () => {
            this.fetch();
        })

    }


    onUpload(file) {
        Helper.confirmModal({
            title: "ยืนยันการโอนเงิน",
            description: "หากทำการยืนยันแล้วระบบจะทำการบันทึกข้อมูล และไม่สามารถทำการแก้ไขข้อมูลได้อีก",
            onConfirm: () => {
                this.setState({
                    is_loading: true,
                }, () => {
                    Papa.parse(file, {
                        skipEmptyLines: false,
                        complete: async (results) => {
                            console.log(results)
                            let data = [];
                            results.data.forEach((d, index) => {
                                if (index != 0) {
                                    let prepare = {};
                                    prepare.bookUid = d[0]
                                    prepare.providerUserUid = d[1]
                                    prepare.bookNo = d[2]
                                    prepare.urgent = parseFloat(d[10])
                                    prepare.price = parseFloat(d[11])
                                    prepare.priceOutApp = parseFloat(d[12])
                                    prepare.remove = parseFloat(d[13])
                                    prepare.removeNote = d[14]
                                    prepare.add = parseFloat(d[15])
                                    prepare.addNote = d[16]
                                    prepare.total = parseFloat(d[17])
                                    prepare.note = d[18]
                                    let getIndex = data.findIndex((e) => { return e.bookUid == d[0] });
                                    if (getIndex == -1) {
                                        data.push(prepare)
                                    } else {
                                        //change provider
                                        if (data[getIndex].providerUserUid != d[1]) {
                                            data.push(prepare)
                                        } else { //book plus time
                                            data[getIndex].bookUid = d[0]
                                            data[getIndex].providerUserUid = d[1]
                                            data[getIndex].bookNo = d[2]
                                            data[getIndex].price = data[getIndex].price + parseFloat(d[11])
                                            data[getIndex].priceOutApp = data[getIndex].priceOutApp + parseFloat(d[12])
                                            data[getIndex].remove = data[getIndex].remove + parseFloat(d[13])
                                            data[getIndex].removeNote += data[getIndex].removeNote && d[14] != "" ? `,${d[14]}` : ``
                                            data[getIndex].add = data[getIndex].add + parseFloat(d[15])
                                            data[getIndex].addNote += data[getIndex].addNote && d[16] != "" ? `,${d[16]}` : ``
                                            data[getIndex].total = data[getIndex].total + parseFloat(d[17])
                                        }
                                    }
                                }
                            });

                            // console.log(data)
                            // for (const doc of data) {
                            //     if (doc.bookUid == `JBXruLK62TQB9KtdoM4c`) {
                            //         console.log(doc)
                            //     }
                            // }
                            // return;
                            let res = await salaryService.payWithCSVFile(data, this.props.salary_id);

                            if (!res.error) {
                                let salary_data = this.state.salary;
                                let salaryRef = await db.doc(`${collectionSalary}/` + this.props.salary_id).get();
                                if (salaryRef.exists) {
                                    salary_data = salaryRef.data();
                                }
                                window.$(document).Toasts('create', {
                                    title: 'เพิ่มข้อมูลสำเร็จ',
                                    body: 'อัพโหลดข้อมูลการชำระเงินแม่บ้านสำเร็จแล้ว',
                                    icon: 'fas fa-check',
                                    autohide: false,
                                    //delay: 3000,
                                    class: "bg-success",
                                })
                                this.setState({
                                    salary: salary_data,
                                    is_loading: true
                                }, () => {
                                    this.fetch();
                                })

                            } else {
                                let error_message = "ไม่สามารถบันทึกข้อมูลได้";
                                if (res.response.data.message) {
                                    error_message = res.response.data.code + ": " + res.response.data.message // เปลี่ยน error message จาก API
                                }
                                window.$(document).Toasts('create', {
                                    title: 'เกิดข้อผิดพลาด',
                                    body: error_message,
                                    icon: 'fas fa-exclamation',
                                    autohide: false,
                                    //delay: 3000,
                                    class: "bg-danger",
                                })
                                this.setState({
                                    is_loading: false
                                })
                            }
                        },
                    });
                })
            },
            onCancel: () => {

            }
        });


    }


    render() {
        let export_data = [];
        this.state.data.map((_data) => {
            // console.log(_data)
            let _status = 'ไม่มีสถานะ';
            if (_data.paymentStatus === 'pending') {
                _status = 'รอ';
            }
            if (_data.paymentStatus === 'pending' && !_data.bank) {
                _status = 'ไม่พบธนาคาร';
            }
            if (_data.paymentStatus === 'success') {
                _status = 'โอนแล้ว';
            }
            if (_data.bank) {
                let getBank = BankCode.filter((e) => e.name.indexOf(_data.bank) != -1)[0];
                if (getBank) {
                    _data.bankCode = getBank.code;
                }
            }
            let item = {
                displayName: _data.prefix + ' ' + _data.firstName + ' ' + _data.lastName,
                phoneNumber: _data.phoneNumber,
                wallet: Helper.numberFormat(_data?.provider_user?.wallet ? _data.provider_user.wallet : 0),
                priceAfterTransfer: Helper.numberFormat(_data && _data.priceAfterTransfer ? _data.priceAfterTransfer : 0),
                bankCode: _data.bankCode,
                bank: _data.bank,
                bankBranch: _data.bankBranch,
                bankAccountName: _data.bankAccountName,
                bankAccountNo: _data.bankAccountNo,
                status: _status,
                transferPrice: Helper.numberFormat(_data.transferPrice ? _data.transferPrice : 0),
            }
            export_data.push(item);
            return true
        })

        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการรายได้แม่บ้าน (CSV)</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/csv-salary"}>จัดการรายได้</Link></li>
                                    <li className="breadcrumb-item active">จัดการรายได้แม่บ้าน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายการ</h3>
                                <div className={"card-right"} />
                                <div className="card-tools">
                                    <form className="input-group input-group-sm"
                                        ref={(ref) => { this.$searchForm = window.$(ref) }}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            let data = Helper.getFormInputObject(this.$searchForm);
                                            let obj = {};
                                            obj.page = 1;
                                            obj.name = data.name;
                                            let url = Helper.getQueryStringLinkFromObject({ ...obj }, `/csv-salary-provider/` + this.props.salary_id)
                                            navigate(url)
                                        }}
                                    >
                                        <input type="text" name="name" className="form-control float-right" placeholder="ค้นหาจากชื่อแม่บ้าน" defaultValue={this.state.name} />
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card card-widget widget-user">
                                            <div className="widget-user-header bg-success" style={{ height: 'auto' }}>
                                                <h3 className="widget-user-username">Summary</h3>
                                                {/* <h5 className="widget-user-desc">Founder &amp; CEO</h5> */}
                                            </div>
                                            <div className="card-footer" style={{ paddingTop: '1rem' }}>
                                                <div className="row">
                                                    <div className="col-sm-4 border-right">
                                                        <div className="description-block">
                                                            <span className="description-text">Maid</span>
                                                            <h5 className="description-header">{Helper.numberFormat(this.state.maid_count)}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 border-right">
                                                        <div className="description-block">
                                                            <span className="description-text">Booking</span>
                                                            <h5 className="description-header">
                                                                <div>
                                                                    {Helper.numberFormat(this.state.booking_count)}
                                                                </div>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="description-block">
                                                            <span className="description-text">Total Payment</span>
                                                            <h5 className="description-header">
                                                                <div>
                                                                    {Helper.numberFormat(this.state.salary.totalPrice)}
                                                                </div>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div><label>วันเวลาที่ทำรายการ : </label> {Helper.getDateThai(this.state.salary.createdAt, { is_show_time: true })}</div>
                                        {/* <div><label>ยอดรายได้มากกว่า : </label> {Helper.numberFormat(this.state.salary.minPrice)}</div> */}
                                        {
                                            this.state.salary.admin ?
                                                <div><label>ผู้สร้างรายการโอนเงิน : </label> {this.state.salary.admin.displayName}</div>
                                                : null
                                        }
                                        {
                                            this.state.salary.note ?
                                                <div><label>หมายเหตุ : </label> {this.state.salary.note}</div>
                                                : null
                                        }
                                    </div>
                                    <div className="col-md-4" style={{ textAlign: 'right' }}>
                                        <h3><span>สถานะ : </span>
                                            {
                                                this.state.salary.status === 'unsuccessful' ?
                                                    <label style={{ color: 'red' }}>ไม่สำเร็จ</label>
                                                    : null
                                            }
                                            {
                                                this.state.salary.status === 'successful' ?
                                                    <label style={{ color: 'green' }}>สำเร็จ</label>
                                                    : null
                                            }
                                            {
                                                !this.state.salary.status
                                                    && !this.state.salary.status ?
                                                    <label>ไม่ระบุ</label>
                                                    : null
                                            }
                                        </h3>
                                        <div>
                                            <button type="button"
                                                className={this.state.is_loading ? "btn btn-default loading" : "btn btn-default"}
                                                onClick={() => {
                                                    if (this.state.data.length <= 0) { return false }
                                                    this._export.save(export_data);
                                                }}
                                                disabled={this.state.data.length <= 0}
                                            >
                                                <i className="far fa-file-excel"></i> ดาวน์โหลดรายงาน
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <ExcelExport
                                    data={export_data}
                                    ref={exporter => this._export = exporter}
                                    fileName={"จัดการรายได้แม่บ้าน " + this.state.salary.title + " (" + Helper.getDateThai(this.state.salary.createdAt) + ")"}
                                >
                                    <ExcelExportColumn field="displayName" title="ชื่อ-นามสกุล" width={200} />
                                    <ExcelExportColumn field="phoneNumber" title="ข้อมูลติดต่อ" width={130} />
                                    <ExcelExportColumn field="wallet" title="ยอดเงินในกระเป๋าทั้งหมด" width={150} />
                                    <ExcelExportColumn field="priceAfterTransfer" title="เงินกระเป๋าหลังทำการโอน" width={150} />
                                    <ExcelExportColumn field="bankCode" title="รหัสธนาคาร" width={130} />
                                    <ExcelExportColumn field="bank" title="ธนาคาร" width={130} />
                                    <ExcelExportColumn field="bankBranch" title="สาขา" width={130} />
                                    <ExcelExportColumn field="bankAccountName" title="ชื่อบัญชี" width={150} />
                                    <ExcelExportColumn field="bankAccountNo" title="เลขบัญชี" width={150} />
                                    <ExcelExportColumn field="status" title="สถานะ" width={150} />
                                    <ExcelExportColumn field="transferPrice" title="จำนวนเงินโอน" width={150} />
                                </ExcelExport>
                                {
                                    this.state.is_absolute_loading ?
                                        <div className="absolute_loading">
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                {this.state.salary.status != "successful" ?
                                    <div className='d-flex justify-content-center align-items-ceter'>
                                        {
                                            this.state.is_loading ?
                                                <button type="button"
                                                    id={"custom-btn"}
                                                    className="btn btn-success btn-lg loading">
                                                    อัพโหลดไฟล์
                                                </button>
                                                :
                                                <>

                                                    <input
                                                        ref={(ref) => { this.$uploadFile = window.$(ref) }}
                                                        type="file"
                                                        name="file"
                                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                        onChange={(event) => {
                                                            this.onUpload(this.$uploadFile[0].files[0])
                                                        }}
                                                        style={{ display: "none" }}
                                                        id="uploadFile"
                                                    />

                                                    <label htmlFor={"uploadFile"}
                                                        type={"button"}
                                                        id={"custom-btn"}
                                                        className="btn btn-success btn-lg"
                                                    >
                                                        อัพโหลดไฟล์
                                                    </label>
                                                </>
                                        }
                                    </div> : null
                                }

                                {
                                    this.state.salary.status == "successful" ?
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>แม่บ้าน</th>
                                                    <th>ข้อมูลการติดต่อ</th>
                                                    <th>เงินกระเป๋าปัจจุบัน</th>
                                                    <th>บัญชีธนาคาร</th>
                                                    <th>สถานะการโอน</th>
                                                    <th style={{ width: 180 }}>ระบบ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !this.state.is_loading
                                                        && this.state.data.length > 0 ?
                                                        this.state.data.map((_data, _data_i) => {
                                                            return (
                                                                <tr key={_data_i}>
                                                                    <td>
                                                                        {_data.gender === "ชาย" ? <i className="fas fa-mars"></i> : null}
                                                                        {_data.gender === "หญิง" ? <i className="fas fa-venus"></i> : null}
                                                                        {' '}
                                                                        <Link to={"/provider-users/" + _data.providerUID} >{_data.prefix} {_data.firstName} {_data.lastName}</Link><br />
                                                                        <i className="fas fa-star" style={{ color: '#ffa200' }}></i> {_data.provider_user && _data.provider_user.starFloat ? _data.provider_user.starFloat.toFixed(2) : '5.00'}
                                                                    </td>
                                                                    <td>
                                                                        {_data.phoneNumber}
                                                                    </td>
                                                                    <td>
                                                                        ฿{Helper.numberFormat(_data.defaultPrice ? _data.defaultPrice : 0)}
                                                                        {
                                                                            _data.paymentStatus !== "wait" && _data.bank && _data.priceAfterTransfer ?
                                                                                <span className="badge badge-info salary-custom-badge" style={{ marginLeft: 5 }}>
                                                                                    ฿{Helper.numberFormat(_data.priceAfterTransfer || 0)}
                                                                                </span>
                                                                                : null
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {_data.transferBank ?
                                                                            <>
                                                                                <div>{_data.transferBank + ' ' + _data.providerBankBranch}</div>
                                                                                <div>{_data.providerBankName + ' ' + _data.providerBankAccountNo}</div>
                                                                            </>
                                                                            : null
                                                                        }
                                                                        {!_data.transferBank ?
                                                                            <>
                                                                                <div className={"link"}
                                                                                    onClick={() => {
                                                                                        Helper.reactToDom(
                                                                                            window.$('body'),
                                                                                            <BankForm
                                                                                                provider_id={_data.providerUID}
                                                                                                user={_data}
                                                                                                onSuccess={async (bank) => {
                                                                                                    let update_data = {};
                                                                                                    if (bank.bankAccountName) {
                                                                                                        update_data.providerBankName = bank.bankAccountName;
                                                                                                    }
                                                                                                    if (bank.bankBranch) {
                                                                                                        update_data.providerBankBranch = bank.bankBranch;
                                                                                                    }
                                                                                                    if (bank.bankAccountNo) {
                                                                                                        update_data.providerBankAccountNo = bank.bankAccountNo;
                                                                                                    }
                                                                                                    if (bank.bank) {
                                                                                                        update_data.transferBank = bank.bank;
                                                                                                    }
                                                                                                    await db.collection(collectionSalary).doc(this.props.salary_id).collection('provider').doc(_data.providerUID).update({ ...update_data });
                                                                                                    this.setState({
                                                                                                        is_absolute_loading: true
                                                                                                    }, () => {
                                                                                                        this.fetch();
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    แก้ไขข้อมูลธนาคาร
                                                                                </div>
                                                                            </>
                                                                            : null
                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        <div className="flex row">
                                                                            <div>
                                                                                {
                                                                                    _data.paymentStatus === "wait" && _data.transferBank ?
                                                                                        <div className={"text-muted"}>รอ</div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    _data.paymentStatus === "pending" && _data.transferBank ?
                                                                                        <div><span style={{ color: 'orange' }}>รอยืนยัน</span>
                                                                                            {
                                                                                                _data.transferPrice ?
                                                                                                    <span className="badge badge-info salary-custom-badge" style={{ marginLeft: 5 }}>
                                                                                                        ฿ {Helper.numberFormat(_data.transferPrice)}
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    _data.paymentStatus === "success" ?
                                                                                        <div><span style={{ color: 'green' }}>โอนแล้ว</span>
                                                                                            {
                                                                                                _data.transferPrice ?
                                                                                                    <span className="badge badge-success salary-custom-badge" style={{ marginLeft: 5 }}>
                                                                                                        ฿ {Helper.numberFormat(_data.transferPrice)}
                                                                                                    </span>
                                                                                                    : _data.transferPrice == 0 ?
                                                                                                        <span className="badge badge-warning salary-custom-badge" style={{ marginLeft: 5 }}>
                                                                                                            ฿ 0 (ไม่มีการโอนเงิน)
                                                                                                        </span>
                                                                                                        :
                                                                                                        null
                                                                                            }
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    !_data.transferBank ?
                                                                                        <>
                                                                                            <div>ไม่พบธนาคาร</div>
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                            </div>

                                                                        </div>


                                                                    </td>
                                                                    <td>
                                                                        <div className="flex row">
                                                                            {
                                                                                _data.paymentStatus !== "wait" ?
                                                                                    <button className={"btn btn-default"}
                                                                                        style={{ marginRight: 5 }}
                                                                                        onClick={() => {
                                                                                            Helper.reactToDom(window.$('body'),
                                                                                                <SalaryDetail
                                                                                                    data={_data}
                                                                                                />)
                                                                                        }}
                                                                                    >
                                                                                        ดูข้อมูล
                                                                                    </button>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                        : null
                                                }
                                                {
                                                    !this.state.is_loading
                                                        && this.state.data.length === 0 ?
                                                        <tr >
                                                            <td colSpan="6" style={{ textAlign: 'center' }}>
                                                                ไม่มีข้อมูล
                                                            </td>
                                                        </tr>
                                                        : null
                                                }
                                                {
                                                    this.state.is_loading ?
                                                        <tr >
                                                            <td colSpan="6" style={{ textAlign: 'center' }}>
                                                                <div className="ui active inline loader small" ></div>
                                                            </td>
                                                        </tr>
                                                        : null
                                                }


                                            </tbody>
                                        </table>
                                        : null
                                }

                            </div>
                            {
                                !this.state.is_loading
                                    && this.state.data.length !== 0
                                    && !this.state.name ?
                                    <div className="card-footer clearfix">
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </section>
            </>
        );
    }
}


class SalaryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: false,
            is_absolute_loading: false,
            selected_books: [],
            provider_books: [],
            vat_price: 0,
            total_price: this.props.data.transferPrice || 0,
            price: this.props.data.transferPrice || 0,
            vat: 0 //3,
        }
    }


    readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.$file.parent().find('.button').text('เปลี่ยนรูป');
                window.$('#show_image').html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
                window.$('#show_image').html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    async pay() {
        let _data = {};
        _data.providerUid = this.props.data.providerUID;
        _data.value = parseFloat(this.state.price);
        _data.salaryUid = this.props.salary_id;
        // console.log(_data)
        // return;
        axios.post(window.api_host + '/pay-provider', {
            ..._data
        }).then(async res => {
            if (res.data.code >= 200) {
                window.$(document).Toasts('create', {
                    title: 'การโอนเงินสำเร็จ',
                    body: `เงินที่ต้องการโอน : ${res.data.totalPrice} \nภาษี : ${res.data.totalVat}\nจำนวนเงินที่ต้องโอน ` + (res.data.totalPrice - res.data.totalVat),
                    icon: 'fas fa-check',
                    autohide: true,
                    delay: 10000,
                    class: "bg-success",
                })
                // update Salary status
                await db.doc(`${collectionSalary}/` + this.props.salary_id + '/provider/' + this.props.data.id).update({
                    status: 'success'
                });
                let now = new Date();
                let updateTime = now.getTime();
                let adminUpdated = false;

                let adminRef = await db.collection('admin').doc(this.props.adminUid).get();
                if (adminRef.exists) {
                    adminUpdated = adminRef.data();
                }
                // merge all image
                let all_images = [];
                if (this.props.data.image_paths && this.props.data.image_paths.length > 0) {
                    for (const image_path of this.props.data.image_paths) {
                        all_images.push(image_path)
                    }
                }
                if (this.props.bookSelected && this.props.bookSelected.length > 0) {
                    for (const book of this.props.bookSelected) {
                        if (book.images && book.images.length > 0) {
                            for (const image of book.images) {
                                all_images.push(image)
                            }
                        }
                    }
                }

                // update deposit wallet by bookUid
                // do *** get wallet data forloop and update wallet

                if (this.props.data.bookSelected && this.props.data.bookSelected.length > 0) {

                    for (const book of this.props.data.bookSelected) {
                        let depositWalletRef = await db.collection(`provider_users/${this.props.data.id}/wallets`).where('bookUid', '==', book.bookUid).get();
                        if (!depositWalletRef.empty) {
                            for (const wallet of depositWalletRef.docs) {
                                let wallet_data = wallet.data();
                                wallet_data.id = wallet.id;
                                let bookSelectedCount = this.props.data.bookSelected.filter((e) => { return e.bookUid === wallet_data.bookUid });

                                let bookSelected = false
                                if (bookSelectedCount.length > 0) {
                                    bookSelected = {};
                                    bookSelected = bookSelectedCount[0];
                                }
                                let updateWalletDepositData = {};
                                updateWalletDepositData.updatedAt = updateTime;
                                if (bookSelected) {
                                    updateWalletDepositData.images = bookSelected.images
                                }
                                if (adminUpdated) {
                                    updateWalletDepositData.updateProviderSalaryAdminUid = this.props.adminUid;
                                    updateWalletDepositData.updateProviderSalaryAdmin = adminUpdated;
                                }
                                updateWalletDepositData.isPaid = true;
                                await db.collection("provider_users").doc(this.props.data.id).collection('wallets').doc(wallet.id).update({ ...updateWalletDepositData });

                            }
                        }
                    }
                }


                // update withdraw wallet by salaryUid
                // do *** get wallet data and update wallet 
                let walletUid = false
                let withdrawWalletRef = await db.collection(`provider_users/${this.props.data.id}/wallets`).where('salaryUid', '==', this.props.salary_id).get();
                if (!withdrawWalletRef.empty) {
                    let updateWalletWithdrawData = {
                        images: all_images,
                        bookingPaid: this.props.data.bookingPaid,
                        bookSelected: this.props.data.bookSelected,
                        updatedAt: updateTime,
                    }
                    walletUid = withdrawWalletRef.docs[0].id;
                    await db.doc(`provider_users/${this.props.data.id}/wallets/${withdrawWalletRef.docs[0].id}`).update({ ...updateWalletWithdrawData });
                }


                //update books
                // do forloop bookSelected and update data to book

                if (this.props.data.bookSelected && this.props.data.bookSelected.length > 0) {
                    for (const book of this.props.data.bookSelected) {
                        // console.log(book)
                        let p_data = { ...this.props.data };
                        delete p_data.allImages;
                        delete p_data.bookSelected;
                        let updateBook = {};
                        updateBook.providerSalary = {
                            walletUid: walletUid,
                            updatedAt: updateTime,
                            images: book.images,
                            salaryUid: this.props.salary_id,
                            ...this.props.salary,
                            ...p_data,
                        }
                        if (adminUpdated) {
                            updateBook.providerSalary.updateProviderSalaryAdminUid = this.props.adminUid;
                            updateBook.providerSalary.updateProviderSalaryAdmin = adminUpdated;
                        }
                        await axios.put(window.api_host + '/booking', { id: book.bookUid, update: { ...updateBook } });
                    }
                }



                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
                this.$modal.modal('hide');
            }
        }).catch(error => {
            let error_message = "เกิดข้อผิดพลายกรุณาลองใหม่อีกครั้ง"
            if (error.response
                && error.response.data
                && error.response.data.code === -2
            ) {
                error_message = "ไม่มีบัญชีธนาคาร";
            }
            // alert(error_message);
            window.$(document).Toasts('create', {
                title: 'การโอนเงินไม่สำเร็จ',
                body: error_message,
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submit.removeClass('loading')
        });
    }


    render() {
        let _data = this.props.data;
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-keyboard={this.props.mode === 'confirm' ? "false" : "true"} data-backdrop={this.props.mode === 'confirm' ? "static" : "true"}
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >

                <form className="modal-dialog modal-lg"
                    style={{ maxWidth: 1000 }}
                    role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) {
                            return;
                        }
                        this.$submit.addClass('loading');
                        this.pay()

                    }}
                >
                    {
                        this.state.is_loading ?
                            <div className="absolute_loading">
                                <div className="ui active inline loader" ></div>
                            </div>
                            : null
                    }
                    <div className="modal-content">
                        <div className="modal-header" style={{ display: "flex", alignItems: 'center' }}>
                            <h4 className="modal-title" id="myModalLabel">
                                {
                                    this.props.mode === 'confirm' ? "ยืนยันการโอนเงิน" : "ดูข้อมูลการโอนเงิน"
                                }
                            </h4>
                            <div style={{ marginLeft: 'auto' }}>
                                {_data.prefix} {_data.firstName} {_data.lastName}
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-5">
                                    <div className={"row"} style={{ justifyContent: "center" }}>
                                        {
                                            this.state.vat > 0 ?
                                                <div className={"col-sm-12 col-lg-6"}>
                                                    <div className={"salary-transfer-price"}>
                                                        <div className={"title"}>หักภาษี {this.state.vat}%</div>
                                                        <div className={"price"}>
                                                            {Helper.numberFormat(this.state.vat_price)}
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        <div className={"col-sm-12 col-lg-6"}>
                                            <div className={"salary-transfer-price"}>
                                                <div className={"title"}>จำนวนเงิน</div>
                                                <div className={"price"} style={{ color: 'green' }}>
                                                    {Helper.numberFormat(this.state.total_price)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="divider" style={{ margin: 0 }} />
                                    <div className="modal-body">
                                        <div>
                                            <label>รูปภาพเพิ่มเติม</label>
                                            <input name="image"
                                                type={"file"}
                                                ref={(ref) => this.$file = window.$(ref)}
                                                style={{ display: 'none' }}
                                                id="file_image_bank"
                                                onChange={(e) => {
                                                    this.readURL(this.$file[0]);
                                                }}
                                            />
                                            <div id="show_image" className="row">
                                                {
                                                    this.props.data
                                                        && this.props.data.image_paths
                                                        && this.props.data.image_paths.length > 0 ?
                                                        this.props.data.image_paths.map((image, image_i) => {
                                                            return (
                                                                <div className={this.props.data.image_paths.length === 1 ? "col-12" : "col-6"} key={image_i}>
                                                                    <div className="mutiple_image_frame card" style={{ height: 'auto' }}>
                                                                        <div className="card-body">
                                                                            <div>
                                                                                <img src={image.imageURL} alt={"PromotionPic"} style={{ maxWidth: '100%' }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : <div className="col-12"><p>-</p></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.props.data.note ?
                                            <>
                                                <div className="divider" style={{ margin: 0 }} />
                                                <div className="modal-body">
                                                    <div>
                                                        <label>บันทึกเพิ่มเติม</label>
                                                        <div>
                                                            {this.props.data.note || '-'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }
                                </div>

                                <div className="col-7">
                                    {
                                        this.props.data.bookSelected
                                            && this.props.data.bookSelected.length > 0 ?
                                            <div className="row">
                                                {
                                                    this.props.data.bookSelected.map((book, book_i) => {
                                                        return (
                                                            <div className="col-6" key={book_i}>
                                                                <div className="card" >
                                                                    <div className="card-header">
                                                                        <h3 className="card-title"><Link to={'/booking/' + book.bookUid} onClick={() => { this.$modal.modal('hide') }}><i className="fas fa-clipboard-check" /> {book.bookOn}</Link></h3>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p><i className="far fa-calendar-alt" /> {Helper.getDateThai(book.bookedAt, { is_show_time: true })}</p>
                                                                        <div className="row">
                                                                            {
                                                                                book.images && book.images.length > 0 ?
                                                                                    book.images.map((image, image_i) => {
                                                                                        return (
                                                                                            <div className="col-6" key={image_i} >
                                                                                                <div className="mutiple_image_frame" style={{ height: 'auto' }}>
                                                                                                    <a href={image.imageURL} data-fancybox={"book_slip_" + book_i}>
                                                                                                        <img src={image.imageURL} alt={"BookingSlipImg"} />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }) : null
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : <div className="text-center">ไม่มีข้อมูล Book</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {
                                this.props.mode === "confirm" ?
                                    <p>*กรุณาตรวจสอบข้อมูลให้ครบถ้วนและถูกต้องก่อนทำการยืนยันการโอนเงิน</p>
                                    : null
                            }
                            <button type="button"
                                className="btn btn-default"
                                onClick={() => {
                                    let $submit = this.$form.find('button[type="submit"]');
                                    $submit.removeClass('loading');
                                    this.$modal.modal('hide');
                                }}
                            >
                                {
                                    this.props.mode === "confirm" ? "ยกเลิก" : "ปิด"
                                }
                            </button>
                            {
                                this.props.mode === "confirm" ?
                                    <button className={"btn btn-primary"} type={"submit"}>ยืนยัน</button>
                                    : null
                            }
                        </div>

                    </div>
                </form>
            </div>
        );
    }
}