import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import { db, getProviderUserByProderviderUserDataUid } from '../firebase';
import thailand from "./../thailand.json";
const axios = require('axios').default;

export default class ServiceArea extends React.Component {
    constructor(props) {
        super(props);
        let amphoes = [];
        for (const [key, value] of Object.entries(thailand)) {
            if (key === props.province_id)
                for (const [_key, _value] of Object.entries(value.amphoes)) {
                    amphoes.push({ code: _key, amphoe: _value.name.th });
                    // console.log(`${_key}: ${_value.name.th}`);
                }
        }
        this.state = {
            is_loading: true,
            data: [],
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            userUID: Helper.getParameterByName('userUID') ? Helper.getParameterByName('userUID') : '',
            is_show_next_button: false,
            provinces_count: 0,
            provider_id: props.provider_id,
            province_id: props.province_id,
            amphoes: [],
            all_amphoes: amphoes,
            selected_amphoes: [],
            more_selected_amphoe: [],
        }
    }

    componentDidMount() {
        this.fetchData();
        // console.log(this.state.provinces);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                userUID: Helper.getParameterByName('userUID') ? Helper.getParameterByName('userUID') : '',
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        let user = {};
        await db.doc("provider_users_data/" + this.state.provider_id).get().then(async (doc) => {
            user = { ...doc.data() };
            if (user.traningUID) {
                await db.doc("training_date/" + user.traningUID).get().then(async (doc) => {
                    user.educateDate = doc.data() && doc.data().date ? doc.data().date : new Date().getTime();
                })
            }
            if (user.tranningStatus) {
                await db.doc("training_date_status/" + user.tranningStatus).get().then(async (doc) => {
                    user.tranningStatusData = { ...doc.data() };
                })
            }
            const providerUser = await getProviderUserByProderviderUserDataUid(this.state.provider_id);
            if (providerUser) {
                let provider_user = await db.doc("provider_users/" + providerUser.id).get();
                user.provider_user = provider_user.data();
                user.provider_user.id = providerUser.id;
            }
        })
        let _user = { ...user };
        let provinces_count = 0;
        if (_user.provincesCode && _user.provincesCode.length > 0) {
            provinces_count = _user.provincesCode.length;
        }
        let _provinces = Helper.showProvinceWithAmphoe(_user.provincesCode, _user.amphoesCode);
        let province = false;
        let selected_amphoe = [];
        let more_selected_amphoe = [];
        if (_provinces.length > 0) {
            let count_match_province = _provinces.filter((_province) => { return parseFloat(_province.code) === parseFloat(this.state.province_id) }).length;
            if (count_match_province > 0) {
                province = _provinces.filter((_province) => { return parseFloat(_province.code) === parseFloat(this.state.province_id) })[0];
            }
            if (province.amphoes && province.amphoes.length > 0) {
                province.amphoes.map((amphoe) => {
                    selected_amphoe.push(amphoe.code);
                    return true
                })
            }
            let more_province = _provinces.filter((_province) => { return parseFloat(_province.code) !== parseFloat(this.state.province_id) });
            more_province.map((_more_province) => {
                if (_more_province.amphoes && _more_province.amphoes.length > 0) {
                    _more_province.amphoes.map((amphoe) => {
                        more_selected_amphoe.push(amphoe.code)
                        return true
                    })
                }
                return true
            })
        }
        // if (_user.amphoesCode && _user.amphoesCode.length > 0) {
        //     _user.amphoesCode.map((amphoeCode) => {
        //         selected_amphoe.push(amphoeCode)
        //         return true;
        //     })
        // }
        // console.log(selected_amphoe);
        // console.log(more_selected_amphoe);
        this.setState({
            user: _user,
            is_loading: false,
            selected_amphoes: selected_amphoe,
            more_selected_amphoe: more_selected_amphoe,
            province: province,
            provinces_count: provinces_count
        })
    }

    async updateServiceArea() {
        let data = {};
        data.amphoesCode = [...this.state.more_selected_amphoe];
        this.state.selected_amphoes.map((code) => {
            data.amphoesCode.push(code);
            return true
        })
        data.provider_user = this.state.user && this.state.user.provider_user ? this.state.user.provider_user : false;
        data.provider_id = this.props.provider_id || false;
        // console.log(data);
        let res = await axios.put(`${window.api_host}/provider-users-service-area`, { ...data });
        // console.log(res);
        if(res.data.code === 200) {
            window.$(document).Toasts('create', {
                title: 'เพิ่มจังหวัดสำเร็จ',
                body: 'เพิ่มจังหวัดให้บริการสำหรับแม่บ้านท่านนี้แล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            navigate('/provider-users/provider-service-area/' + this.props.provider_id);
            return;
        }

        window.$(document).Toasts('create', {
            title: 'เกิดข้อผิดพลาด',
            body: res.data.message,
            icon: 'fas fa-check',
            autohide: true,
            delay: 3000,
            class: "bg-danger",
        })
        this.setState({
            is_loading: false
        }, () => {
            // navigate('/provider-users/provider-service-area/' + this.props.provider_id);
            this.$submit.removeClass("loading");
        })
    }

    async deleteProvider() {
        let data = {};
        let _user = this.state.user;
        data.provincesCode = _user.provincesCode.filter((p) => { return parseFloat(p) !== parseFloat(this.props.province_id) });
        data.amphoesCode = [];
        let _provinces = Helper.showProvinceWithAmphoe(_user.provincesCode, _user.amphoesCode);
        // console.log(_provinces);
        _provinces.map((_province) => {
            if (parseFloat(_province.code) !== parseFloat(this.props.province_id)) {
                _province.amphoes.map((amphoe) => {
                    data.amphoesCode.push(amphoe.code)
                    return true
                })
            }
            return true
        })
        await db.doc("provider_users_data/" + this.props.provider_id).update({ ...data });
        window.$(document).Toasts('create', {
            title: 'ยกเลิกพื้นที่ให้บริการสำเร็จ',
            body: 'ยกเลิกจังหวัดให้บริการสำหรับแม่บ้านท่านนี้แล้ว',
            icon: 'fas fa-check',
            autohide: true,
            delay: 3000,
            class: "bg-success",
        })
        this.setState({
            is_loading: false
        }, () => {
            navigate('/provider-users/provider-service-area/' + this.props.provider_id);
            this.$submit.removeClass("loading");
        })
    }

    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการพื้นที่ให้บริการ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/provider-users/provider-service-area/" + this.state.provider_id}>จัดการพื้นที่ให้บริการ</Link></li>
                                    <li className="breadcrumb-item active">แก้ไข</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        {
                            this.state.province ?
                                <div className="card">
                                    <div className="card-header">
                                        <div className={"flex row"} style={{ alignItems: 'center' }}>
                                            <div>
                                                <h3 className="card-title">พื้นที่ให้บริการ{this.state.user ? 'ของ ' + this.state.user.prefix + ' ' + this.state.user.firstName + ' ' + this.state.user.lastName : null} : {this.state.province.province}</h3>
                                            </div>
                                            {/* <div style={{ marginLeft: 'auto' }}>
                                                <button className={"btn btn-danger"}
                                                    ref={(ref) => { this.$submit = window.$(ref) }}
                                                    onClick={() => {
                                                        this.deleteProvider();
                                                    }}
                                                >ยกเลิกพื้นที่ให้บริการ</button>
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                                : null
                        }
                        <div className="card">
                            <div className="card-header">
                                <div className={"flex row"}>
                                    <div className={"flex row"} style={{ alignItems: 'center' }}>
                                        <input name={"select-all"}
                                            type="checkbox"
                                            value={"select-all"}
                                            id={"select-all"}
                                            style={{ marginRight: 5 }}
                                            checked={this.state.selected_amphoes.length === this.state.all_amphoes.length}
                                            onChange={() => {
                                                if (this.state.is_loading) { return; }
                                                let selected_amphoes = [];
                                                if (this.state.selected_amphoes.length !== this.state.all_amphoes.length) {
                                                    this.state.all_amphoes.map((amp) => {
                                                        selected_amphoes.push(amp.code);
                                                        return true
                                                    })
                                                }
                                                this.setState({
                                                    selected_amphoes: selected_amphoes,
                                                })
                                            }}
                                        />
                                        <label style={{ marginBottom: 0 }} htmlFor={"select-all"}>เลือกทั้งหมด</label>
                                    </div>
                                    <div style={{ marginLeft: 'auto' }}>
                                        <Link to={'/provider-users/provider-service-area/' + this.props.provider_id}
                                            className={"btn btn-default"}
                                            style={{ marginRight: '0.5rem' }}>กลับ</Link>
                                        <button className={"btn btn-primary"}
                                            ref={(ref) => { this.$submit = window.$(ref) }}
                                            onClick={() => {
                                                if (this.$submit.hasClass('loading')) { return; };
                                                this.$submit.addClass("loading");
                                                this.setState({
                                                    is_loading: true
                                                }, () => {
                                                    this.updateServiceArea()
                                                })
                                            }}
                                        >บันทึก</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ position: 'relative' }}>

                                {
                                    this.state.is_loading ?
                                        <div className={"absolute_loading"}>
                                            <div className="ui active inline loader small" ></div>
                                        </div>
                                        : null
                                }
                                <div className={'row pointer-label'}>
                                    {
                                        this.state.all_amphoes.map((amphoe, amphoe_i) => {
                                            let selected = false;
                                            this.state.selected_amphoes.map((selected_amphoe, selected_amphoe_i) => {
                                                if (parseFloat(selected_amphoe) === parseFloat(amphoe.code)) {
                                                    selected = true
                                                }
                                                return true
                                            })
                                            return (
                                                <div className={"col-lg-3"} key={amphoe_i}>
                                                    <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                        <input name={"amphoe"}
                                                            type="checkbox"
                                                            value={"select-all"}
                                                            id={amphoe.amphoe}
                                                            checked={selected}
                                                            style={{ marginRight: 5 }}
                                                            onChange={() => {
                                                                let selected_amphoes = [...this.state.selected_amphoes];
                                                                let count_selected = this.state.selected_amphoes.filter((selected) => { return selected === amphoe.code }).length;
                                                                if (count_selected > 0) {
                                                                    selected_amphoes = this.state.selected_amphoes.filter((selected) => { return selected !== amphoe.code });
                                                                }
                                                                if (count_selected === 0) {
                                                                    selected_amphoes.push(amphoe.code)
                                                                }
                                                                this.setState({
                                                                    selected_amphoes: selected_amphoes,
                                                                })
                                                            }}
                                                        />
                                                        <label style={{ marginBottom: 0 }} htmlFor={amphoe.amphoe}>{amphoe.amphoe}</label>
                                                    </div>

                                                </div>
                                            )

                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
