import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Helper';
import { db, storage } from '../firebase';
import Pagination from '../Components/Pagination';
import CustomDateInput from './../Components/CustomDateInput';
import DatePicker from "react-datepicker";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
const axios = require('axios').default;

export default class Promotion extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: [],
            export_data: [],
            all_items: 0,
            page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
            limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
            name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
            status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
            type: Helper.getParameterByName('type') ? Helper.getParameterByName('type') : '',
            market_user_id: Helper.getParameterByName('market_user_id') ? Helper.getParameterByName('market_user_id') : '',
            promotionCondition: Helper.getParameterByName('promotionCondition') ? Helper.getParameterByName('promotionCondition') : '',
            isAllTime: Helper.getParameterByName('isAllTime') ? Helper.getParameterByName('isAllTime') : '',
            isUnLimit: Helper.getParameterByName('isUnLimit') ? Helper.getParameterByName('isUnLimit') : '',
            voucherListCode: Helper.getParameterByName('voucherListCode') ? Helper.getParameterByName('voucherListCode') : '',
            is_show_next_button: false,
            users_count: 0,
            margin_users: [],
        }
    }
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                export_data: [],
                page: Helper.getParameterByName('page') ? Helper.getParameterByName('page') : 1,
                name: Helper.getParameterByName('name') ? Helper.getParameterByName('name') : '',
                status: Helper.getParameterByName('status') ? Helper.getParameterByName('status') : '',
                type: Helper.getParameterByName('type') ? Helper.getParameterByName('type') : '',
                limit: Helper.getParameterByName('limit') ? parseFloat(Helper.getParameterByName('limit')) : 10,
                market_user_id: Helper.getParameterByName('market_user_id') ? Helper.getParameterByName('market_user_id') : '',
                promotionCondition: Helper.getParameterByName('promotionCondition') ? Helper.getParameterByName('promotionCondition') : '',
                isAllTime: Helper.getParameterByName('isAllTime') ? Helper.getParameterByName('isAllTime') : '',
                isUnLimit: Helper.getParameterByName('isUnLimit') ? Helper.getParameterByName('isUnLimit') : '',
                voucherListCode: Helper.getParameterByName('voucherListCode') ? Helper.getParameterByName('voucherListCode') : '',
            }, () => {
                this.fetchData();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                export_data: [],
                name: ''
            }, () => {
                this.fetchData();
            })
        }
    }

    async fetchData() {
        await axios.post(`${window.api_host}/set-promotion-expired`);
        let marginUser = await db.collection("market_users").where('status', '!=', 'ban').get()
        let qs_data = {};
        qs_data._page = parseFloat(this.state.page);
        qs_data._limit = parseFloat(this.state.limit);
        if (this.state.type) {
            qs_data.type = this.state.type;
        }
        if (this.state.voucherListCode) {
            qs_data.voucherListCode = this.state.voucherListCode;
        }
        if (this.state.market_user_id) {
            qs_data.market_user_id = this.state.market_user_id;
        }
        // if (this.state.status) {
        //     qs_data.promotionType = this.state.status;
        // }
        if (this.state.isUnLimit) {
            qs_data.isUnLimit = JSON.parse(this.state.isUnLimit);
        }
        if (this.state.isAllTime) {
            qs_data.isAllTime = JSON.parse(this.state.isAllTime);
        }
        if (this.state.promotionCondition) {
            qs_data.promotionCondition = this.state.promotionCondition;
        }
        if (this.state.name) {
            qs_data.name = this.state.name;
        }
        axios.get(window.api_host + '/promotionAPI?' + window.$.param(qs_data)).then(async (res) => {
            // console.log(res)
            let data = [];
            let count = res.data.count_all;
            let users_count = 0;
            let get_data = res.data.data;
            users_count = get_data.length;

            let margin_users = [];
            margin_users = await Promise.all(
                marginUser.docs.map(async (element) => {
                    return {
                        ...element.data(),
                        id: element.id,
                    };
                })
            );

            data = get_data;
            let export_datas = [];
            let i = 0;
            for (const data_doc of data) {
                let export_data = {};
                export_data.name = data_doc.name;
                export_data.code = data_doc.voucherCode;
                export_data.used = data_doc.history.length;
                export_data.limit = data_doc.limit;
                export_data.startAt = JSON.parse(data_doc.startAt) - (7 * 60 * 60 * 1000);
                export_data.EndAt = JSON.parse(data_doc.EndAt) - (7 * 60 * 60 * 1000);
                data[i].startAt = JSON.parse(data_doc.startAt) - (7 * 60 * 60 * 1000);
                data[i].EndAt = JSON.parse(data_doc.EndAt) - (7 * 60 * 60 * 1000);
                export_datas.push(export_data);
                i++;
            }
            // console.log(export_datas)
            this.setState({
                export_data: export_datas,
                data: data,
                margin_users: margin_users,
                is_loading: false,
                all_items: count,
                users_count: users_count,
            })
        }).catch(error => {
            console.log(error);

            if (error.response) {
                Helper.messageTop({ message: error.response.message || 'ไม่สามารถดึงข้อมูลได้กรุณาติดต่อผู้จัดทำระบบ' });
            }
            this.setState({
                data: [],
                all_items: 0,
                users_count: 0,
                margin_users: this.state.margin_users,
                is_loading: false,
            })
        });
    }


    async printData() {
        let qs_data = {};
        qs_data._page = parseFloat(1);
        qs_data._limit = parseFloat(this.state.all_items);
        if (this.state.type) {
            qs_data.type = this.state.type;
        }
        if (this.state.voucherListCode) {
            qs_data.voucherListCode = this.state.voucherListCode;
        }
        if (this.state.market_user_id) {
            qs_data.market_user_id = this.state.market_user_id;
        }
        if (this.state.status) {
            qs_data.promotionType = this.state.status;
        }
        if (this.state.isUnLimit) {
            qs_data.isUnLimit = JSON.parse(this.state.isUnLimit);
        }
        if (this.state.isAllTime) {
            qs_data.isAllTime = JSON.parse(this.state.isAllTime);
        }
        if (this.state.promotionCondition) {
            qs_data.promotionCondition = this.state.promotionCondition;
        }
        if (this.state.name) {
            qs_data.name = this.state.name;
        }
        window.$(document).Toasts('create', {
            title: 'กรุณารอสักครู่',
            body: 'ระบบกำลังทำการดึงข้อมูล',
            icon: 'fas fa-check',
            autohide: true,
            delay: 5000,
            class: "bg-success",
        })
        axios.get(window.api_host + '/promotionAPI?' + window.$.param(qs_data)).then(async (res) => {
            console.log(res)
            let data = [];
            let get_data = res.data.data;
            data = get_data;
            let export_datas = [];
            for (const data_doc of data) {
                let export_data = {};
                export_data.name = data_doc.name;
                export_data.code = data_doc.voucherCode;
                export_data.used = data_doc.history.length;
                export_data.startAt = JSON.parse(data_doc.startAt) - (7 * 60 * 60 * 1000);
                // export_data.EndAt = JSON.parse(data_doc.EndAt) - (7 * 60 * 60 * 1000);
                export_data.limit = data_doc.limit;
                export_datas.push(export_data)
            }

            this.setState({
                export_data: export_datas,
            }, () => {
                this._export.save(this.state.export_data);
                this.download.removeClass('loading')
            })
        }).catch(error => {
            console.log(error);
            if (error.response) {
                Helper.messageTop({ message: error.response.message });
            }
            this.download.removeClass('loading')
        });
    }

    delete(data) {
        if (data.imageUrl) {
            storage.ref(data.imageUrl).delete();
        }
        axios.post(window.api_host + '/deletePromotionAPI', { promotionUid: data.id }).then(async (res) => {
            this.fetchData();
            window.$(document).Toasts('create', {
                title: 'ลบข้อมูลสำเร็จ',
                body: 'ทำการลบข้อมูลสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
        }).catch(error => {
            console.log(error);
            if (error.response) {
                Helper.messageTop({ message: error.response.message });
            }
        });
    }

    selectPromotionType(type) {
        if (!type) {
            type = '';
        }
        let _data = { page: 1, type: type }
        if (this.state.market_user_id) {
            _data.market_user_id = this.state.market_user_id;
        }
        let url = Helper.getQueryStringLinkFromObject({ ..._data }, '/promotion')
        navigate(url)
    }



    render() {
        return (
            <>

                <ExcelExport
                    data={this.state.export_data}
                    ref={exporter => this._export = exporter}
                    fileName={"รายงาน Voucher (" + Helper.getDateThai(new Date().getTime()) + ")"}
                >
                    <ExcelExportColumn field="name" title="Voucher" width={200} />
                    <ExcelExportColumn field="code" title="รหัส" width={130} />
                    <ExcelExportColumn field="used" title="ใช้แล้ว" width={150} />
                    <ExcelExportColumn field="limit" title="จำนวนสิทธิ์" width={130} />
                </ExcelExport>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">จัดการโปรโมชั่น</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    {
                                        this.state.voucherListCode ?
                                            <>
                                                <li className="breadcrumb-item"><Link to="/promotion">โปรโมชั่น</Link></li>
                                                <li className="breadcrumb-item"><Link to="/voucher">Voucher</Link></li>
                                            </>
                                            : null
                                    }
                                    {
                                        this.state.voucherListCode ?
                                            <li className="breadcrumb-item active">รายการโปรโมชั่น</li>
                                            :
                                            <li className="breadcrumb-item active">จัดการโปรโมชั่น</li>
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header " style={{ display: 'flex' }}>
                                <h3 className="card-title">รายการโปรโมชั่น <small style={{ color: 'red' }}> * Application เวอชันเก่า จะพบปัญหาการแสดงผลโปรโมชั่นไม่ตรงกับการหมดอายุจริง (-7 ชั่วโมง)</small></h3>
                                <div className={"card-right"} style={{ marginRight: 0 }}>
                                    <div>ทั้งหมด {this.state.all_items} โปรโมชั่น</div>
                                </div>
                                {
                                    !this.state.voucherListCode ?
                                        null
                                        :
                                        <button type="button"
                                            ref={(ref) => { this.download = window.$(ref); }}
                                            style={{ marginLeft: 10 }}
                                            className={this.state.is_loading ? "btn btn-default loading" : "btn btn-default"}
                                            onClick={() => {
                                                if (this.state.data.length <= 0 || this.download.hasClass('loading')) { return false }
                                                this.download.addClass('loading')
                                                this.printData();
                                            }}
                                            disabled={this.state.export_data.length <= 0}
                                        >
                                            <i className="far fa-file-excel"></i> ดาวน์โหลดรายงาน
                                        </button>
                                }

                            </div>
                            <div className="card-body">
                                {
                                    !this.state.voucherListCode ?
                                        <div className={"form-group"}>
                                            <div className={"flex row"}>
                                                <div>
                                                    <button className={"btn btn-primary"}
                                                        disabled={this.state.is_loading}
                                                        onClick={() => {
                                                            if (this.state.is_loading) { return; }
                                                            Helper.reactToDom(
                                                                window.$('body'),
                                                                <PromotionForm
                                                                    margin_users={this.state.margin_users}
                                                                    mode={"create"}
                                                                    onSuccessCreated={() => {
                                                                        this.fetchData();
                                                                        window.$(document).Toasts('create', {
                                                                            title: 'เพิ่มข้อมูลสำเร็จ',
                                                                            body: 'ทำการเพิ่มข้อมูลเรียบร้อยแล้ว',
                                                                            icon: 'fas fa-check',
                                                                            autohide: true,
                                                                            delay: 3000,
                                                                            class: "bg-success",
                                                                        })
                                                                    }}
                                                                />
                                                            );
                                                        }}
                                                    >เพิ่มโปรโมชั่น</button>
                                                </div>
                                                <button className="btn btn-default"
                                                    style={{ marginLeft: 15 }}
                                                    onClick={() => {
                                                        Helper.reactToDom(window.$('body'),
                                                            <PromotionSearchForm
                                                                data={this.state}
                                                                market_user={this.state.margin_users}
                                                                onSuccess={(data) => {
                                                                    let url = Helper.getQueryStringLinkFromObject({
                                                                        page: 1,
                                                                        ...data
                                                                    }, '/promotion')
                                                                    navigate(url)
                                                                }}
                                                            />)
                                                    }}
                                                >ค้นหา</button>
                                                {/* <div className="btn-group btn-group-toggle" style={{ marginLeft: 15 }}>
                                            <button className={!this.state.type || this.state.type === '' ? "btn btn-default active" : "btn btn-default"}
                                                onClick={() => {
                                                    this.selectPromotionType('')
                                                }}
                                                disabled={this.state.is_loading}
                                            >
                                                ทั้งหมด
                                            </button>
                                            <button className={this.state.type === 'unique' ? "btn btn-default active" : "btn btn-default"}
                                                onClick={() => {
                                                    this.selectPromotionType('unique')
                                                }}
                                                disabled={this.state.is_loading}
                                            >
                                                สำหรับการตลาด
                                            </button>
                                            <button className={this.state.type === 'event' ? "btn btn-default active" : "btn btn-default"}
                                                onClick={() => {
                                                    this.selectPromotionType('event')
                                                }}
                                                disabled={this.state.is_loading}
                                            >
                                                แสดงในหน้า app
                                            </button>
                                        </div>
                                         */}
                                                <div style={{ marginLeft: 'auto' }}>
                                                    <Link to={"/promotion-history"}
                                                        className={"btn btn-default"}
                                                        style={{ marginRight: 15 }}
                                                    >
                                                        ประวัติโปรโมชั่น
                                                    </Link>
                                                    <Link to={'/marketing-users'}
                                                        className={"btn btn-default"}
                                                        style={{ marginRight: 15 }}
                                                    >
                                                        ผู้รับผิดชอบทั้งหมด
                                                    </Link>
                                                    <Link to={'/voucher'}
                                                        className={"btn btn-default"}
                                                    >
                                                        รายการ Voucher
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                        : null
                                }

                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>โปรโมชั่น</th>
                                            <th>ประเภท</th>
                                            <th>เงื่อนไข</th>
                                            <th>ช่วงเวลา</th>
                                            <th>จำนวนสิทธิ์</th>
                                            <th style={{ width: 100 }}>แสดงบนหน้าเว็บไซด์</th>
                                            {
                                                !this.state.voucherListCode ?
                                                    <>
                                                        <th>ผู้รับผิดชอบ</th>
                                                        <th style={{ width: 200 }}>ระบบ</th>
                                                    </>
                                                    : null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {

                                                    let endDate = new Date(parseFloat(_data.EndAt) + (7 * 60 * 60 * 1000));
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                <div>
                                                                    {_data.name}
                                                                </div>
                                                                <div className={"table-label"}>
                                                                    {
                                                                        this.state.voucherListCode
                                                                            && _data.voucherCode ?
                                                                            <span className="badge badge-info" style={{ marginRight: 5 }}>{_data.voucherCode}</span>

                                                                            : null
                                                                    }
                                                                    {
                                                                        _data.history
                                                                            && _data.history.length >= _data.limit
                                                                            && !_data.isUnLimit ?
                                                                            <span className="badge badge-danger" style={{ marginRight: 5 }}>ครบจำนวนสิทธิ์</span>
                                                                            : null
                                                                    }
                                                                    {
                                                                        endDate < Date.now()
                                                                            && !_data.isAllTime ?
                                                                            <span className="badge badge-warning">หมดอายุ</span>
                                                                            : null
                                                                    }
                                                                </div>
                                                                {
                                                                    _data.createdAt ?
                                                                        <div>
                                                                            <small><i className='far fa-calendar-check'></i> {Helper.getDateThai(_data.createdAt, { is_show_time: true })}</small>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.promotionType === 'event' ?
                                                                        "แสดงในหน้า app"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionType === 'unique' ?
                                                                        "สำหรับการตลาด"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionType === 'package' ?
                                                                        "แพ็กเกจ"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionType === 'agent' ?
                                                                        "ตัวแทน"
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.promotionCondition === 'all' ?
                                                                        "ใช้ได้ทุกที่"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionCondition === 'only_first_code' ?
                                                                        "ใช้ครั้งแรกใน code ชุดนั้นๆ"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionCondition === 'only_first_time' ?
                                                                        "ใช้ครั้งแรกใน app"
                                                                        : null
                                                                }
                                                                {
                                                                    _data.promotionCondition === 'only_first_time_plus_code' ?
                                                                        "ใช้ code ครั้งแรกและ ใช้ code ชุดนั้นครั้งแรก"
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.isAllTime ?
                                                                        "ไม่กำหนดระยะเวลา"
                                                                        :
                                                                        <>
                                                                            <div>{Helper.getDateThai(new Date(_data.startAt), { plus_hours: 7, is_show_time: true })}</div>
                                                                            <div>{Helper.getDateThai(new Date(_data.EndAt), { plus_hours: 7, is_show_time: true })}</div>
                                                                        </>
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    _data.isUnLimit ?
                                                                        <>{_data.history.length + '/'}<i className="fas fa-infinity" style={{ color: '#ffc107' }}></i></>
                                                                        // _data.history.length + '/ ไม่จำกัดการใช้งาน' 
                                                                        :
                                                                        _data.history.length + '/' + _data.limit
                                                                }
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                {
                                                                    _data.isShowWeb ?
                                                                        <><i className="fas fa-check" style={{ color: 'green' }} /></>
                                                                        :
                                                                        null
                                                                }
                                                            </td>
                                                            {
                                                                !this.state.voucherListCode ?
                                                                    <>
                                                                        <td>
                                                                            {
                                                                                _data.market_user ?
                                                                                    _data.market_user.name
                                                                                    : "ไม่มีผู้รับผิดชอบ"
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                                                <Link className="btn btn-default" to={"/promotion/" + _data.id}>ดูข้อมูล</Link>
                                                                                <button type="button" className="btn btn-default"
                                                                                    onClick={() => {
                                                                                        Helper.reactToDom(
                                                                                            window.$('body'),
                                                                                            <PromotionForm
                                                                                                data={_data}
                                                                                                mode={"edit"}
                                                                                                margin_users={this.state.margin_users}
                                                                                                onSuccessUpdated={() => {
                                                                                                    this.fetchData();
                                                                                                    window.$(document).Toasts('create', {
                                                                                                        title: 'แก้ไขข้อมูลสำเร็จ',
                                                                                                        body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
                                                                                                        icon: 'fas fa-check',
                                                                                                        autohide: true,
                                                                                                        delay: 3000,
                                                                                                        class: "bg-success",
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                >แก้ไข</button>
                                                                                <button type="button"
                                                                                    className="btn btn-danger"
                                                                                    ref={(ref) => {
                                                                                        window.$(ref).off().on('click', () => {
                                                                                            if (window.$(ref).hasClass('loading')) { return; }
                                                                                            window.$(ref).addClass('loading');
                                                                                            Helper.confirmModal({
                                                                                                title: "ยืนยันลบโปรโมชั่น",
                                                                                                description: "ต้องการลบโปรโมชั่นท่านนี้หรือไม่ ?",
                                                                                                onConfirm: () => {
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                    this.delete(_data);
                                                                                                },
                                                                                                onCancel: () => {
                                                                                                    window.$(ref).removeClass('loading');
                                                                                                }
                                                                                            });
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    ลบ
                                                                                </button>
                                                                            </div>

                                                                        </td>
                                                                    </>
                                                                    : null
                                                            }

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                {
                                    !this.state.is_loading
                                        && this.state.data.length !== 0 ?
                                        <Pagination
                                            now={this.state.page}
                                            all_items={this.state.all_items}
                                            limit={this.state.limit}
                                            location={this.props.location}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

class PromotionForm extends React.Component {
    constructor(props) {
        super(props);
        let defaultStartAt = new Date().setHours(0, 0, 0, 0);
        let defaultEndAt = new Date().setHours(23, 59, 0, 0);
        this.state = {
            start_date: props.data && props.data.startAt ? new Date(props.data.startAt) : defaultStartAt,
            end_date: props.data && props.data.EndAt ? new Date(props.data.EndAt) : defaultEndAt,
        }
    }

    endDateDetail() {
        let endDate = new Date(this.state.end_date);
        // endDate.setDate(endDate.getDate() - 1);
        let endDateFormat = Helper.getDateThai(endDate);
        if (!endDateFormat) { return null }
        return `${endDateFormat} - 23:59`;
        // return `${endDateFormat} - 23:59`;
    }

    readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.$file.parent().find('.button').text('เปลี่ยนรูป');
                window.$('#show_image').html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
                window.$('#show_image').html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    uploadFile(node, options) {
        // let id = options.PromotionId;
        let upload_btn = node.parent().find('.btn');
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        const filename = Helper.setNewImageFileName(file);
        var uploadTask = storage.ref().child('promotions/' + filename).put(file);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log(snapshot);
        }, (error) => {
            console.log(error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            upload_btn.removeClass('loading');
        }, async () => {
            let url = 'promotions/' + filename;
            if (options.onSuccess) {
                options.onSuccess(url);
            }

        });
    }

    create(data) {

        // data.startAt = JSON.parse(data.startAt) + (7 * 60 * 60 * 1000);
        // data.EndAt = JSON.parse(data.EndAt) + (7 * 60 * 60 * 1000);
        data.startAt = JSON.parse(data.startAt);
        data.EndAt = JSON.parse(data.EndAt);
        let start_date = new Date(parseFloat(data.startAt));
        let end_date = new Date(parseFloat(data.EndAt));
        data.startAt = `${start_date.getDate()}-${start_date.getMonth()}-${start_date.getFullYear()}`;
        data.EndAt = `${end_date.getDate()}-${end_date.getMonth()}-${end_date.getFullYear()}`;
        // console.log(start, end)
        // console.log(data);
        // return;
        axios.put(window.api_host + '/promotionAPI', { ...data }).then(async (res) => {
            // console.log(res.data);
            this.props.onSuccessCreated();
            this.$modal.modal('hide');
        }).catch(error => {
            Helper.messageTop({ message: error.response.data.message });
            this.$submit.removeClass('loading');
            this.$file.parent().find('.btn').removeClass('loading');
        });
    }

    update(data) {
        axios.patch(window.api_host + '/promotionAPI', { ...data }).then(async (res) => {
            this.props.onSuccessUpdated();
            this.$modal.modal('hide');
        }).catch(error => {
            console.log(error);
            Helper.messageTop({ message: error.response.data.message });
            this.$submit.removeClass('loading');
            this.$file.parent().find('.btn').removeClass('loading');
        });
    }


    render() {
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) { return false; }
                        this.$submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        data.sameCode = true;
                        if (data.isAllTime) {
                            data.isAllTime = false;
                        } else {
                            data.isAllTime = true;
                            data.startAt = 0;
                            data.EndAt = 0;
                        }
                        if (data.isUnLimit) {
                            data.isUnLimit = true;
                            data.limit = 0;
                        } else {
                            data.isUnLimit = false;
                        }

                        if (data.isShowWeb) {
                            data.isShowWeb = true;
                        } else {
                            data.isShowWeb = false;
                        }
                        if (data.name) {
                            data.nameTH = data.name;
                        }
                        if (data.description) {
                            data.descriptionTH = data.description;
                        }
                        if (this.$file && this.$file[0].files[0]) {
                            this.uploadFile(this.$file, {
                                onSuccess: async (url) => {
                                    data.imageURL = await storage.ref(url).getDownloadURL();
                                    if (this.props.mode === 'create') {
                                        this.create(data);
                                    }
                                    if (this.props.mode === 'edit') {
                                        this.update(data);
                                    }
                                }
                            })
                        }
                        if ((!this.$file || !this.$file[0].files[0])) {
                            if (this.props.mode === 'create') {
                                if (data.isShowWeb) {
                                    Helper.messageTop({ message: 'กรุณาอัพโหลดรูปภาพ' });
                                    this.$submit.removeClass('loading');
                                    this.$file.parent().find('.btn').removeClass('loading');
                                    return;
                                }
                                data.imageURL = null;
                                this.create(data);
                            }
                            if (this.props.mode === 'edit') {
                                data.imageURL = this.props.data.imageURL;

                                if (data.isShowWeb && !data.imageURL) {
                                    Helper.messageTop({ message: 'กรุณาอัพโหลดรูปภาพ' });
                                    this.$submit.removeClass('loading');
                                    this.$file.parent().find('.btn').removeClass('loading');
                                    return;
                                }
                                this.update(data);
                            }
                        }
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                {this.props.mode === 'create' ? 'เพิ่มโปรโมชั่น' : null}
                                {this.props.mode === 'edit' ? 'แก้ไขโปรโมชั่น' : null}
                                {this.props.mode === 'view' ? 'ดูข้อมูลโปรโมชั่น' : null}
                            </h4>
                        </div>
                        <div className="modal-body">
                            {
                                this.props.mode === 'create'
                                    || this.props.mode === 'edit' ?
                                    <>
                                        {this.props.mode === 'edit' ? <input type="hidden" name={"promotionUid"} value={this.props.data.id} readOnly={true} /> : null}
                                        <div className={"row"} style={this.props.mode === 'edit' ? { display: 'none' } : null}>
                                            <div className={"col-xs-12 col-lg-12"}>
                                                <div className={"form-group"}>
                                                    <label>ประเภท</label>
                                                    <select className="form-control"
                                                        name={"promotionType"}
                                                        defaultValue={this.props.data && this.props.data.promotionType ? this.props.data.promotionType : ''}
                                                    >
                                                        <option value={""}>เลือกประเภท</option>
                                                        <option value={"event"}>แสดงในหน้า app</option>
                                                        <option value={"unique"}>สำหรับการตลาด</option>
                                                        <option value={"package"}>แพ็กเกจ</option>
                                                        <option value={"agent"}>ตัวแทน</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div className={"form-group"}>
                                                    <label>ชื่อโปรโมชั่น (ไทย)</label>
                                                    <input type={"text"}
                                                        name={"name"}
                                                        defaultValue={this.props.data && this.props.data.name ? this.props.data.name : ''}
                                                        className={"form-control"}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div className={"form-group"}>
                                                    <label>ชื่อโปรโมชั่น (อังกฤษ)</label>
                                                    <input type={"text"}
                                                        name={"nameEN"}
                                                        defaultValue={this.props.data && this.props.data.nameEN ? this.props.data.nameEN : ''}
                                                        className={"form-control"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"form-group"}>
                                            <label>รูปภาพ</label>
                                            <input name="image"
                                                type={"file"}
                                                ref={(ref) => this.$file = window.$(ref)}
                                                style={{ display: 'none' }}
                                                id="file_image_bank"
                                                onChange={(e) => {
                                                    this.readURL(this.$file[0]);
                                                }}
                                            />
                                            <div id="show_image">
                                                {
                                                    this.props.data && this.props.data.imageURL ?
                                                        <img src={this.props.data.imageURL} alt={"PromotionPic"} style={{ maxWidth: '100%' }} />
                                                        : null
                                                }
                                            </div>
                                            <label htmlFor="file_image_bank"
                                                type={"button"}
                                                className="btn btn-default"
                                                style={{ width: '100%' }}
                                            >
                                                อัพโหลด
                                            </label>
                                        </div>
                                        <div className={"form-group"}>
                                            <label>รายละเอียดโปรโมชั่น (ไทย)</label>
                                            <textarea className={"form-control"}
                                                name={"description"}
                                                defaultValue={this.props.data && this.props.data.description ? this.props.data.description : ''}
                                            />
                                        </div>
                                        <div className={"form-group"}>
                                            <label>รายละเอียดโปรโมชั่น (อังกฤษ)</label>
                                            <textarea className={"form-control"}
                                                name={"descriptionEN"}
                                                defaultValue={this.props.data && this.props.data.descriptionEN ? this.props.data.descriptionEN : ''}
                                            />
                                        </div>
                                        <div className={"row"} style={this.props.mode === 'edit' ? { display: 'none' } : null}>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div className={"form-group"}>
                                                    <label>ผู้รับผิดชอบ</label>
                                                    <select className="form-control" name={"marketUid"}
                                                        defaultValue={this.props.data && this.props.data.marketUid ? this.props.data.marketUid : ''}
                                                    >
                                                        <option value={""}>เลือกผู้รับผิดชอบ</option>
                                                        {
                                                            this.props.margin_users.map((margin_user, margin_user_i) => (
                                                                <option value={margin_user.id} key={margin_user_i}>{margin_user.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div className={"form-group"}>
                                                    <label>กำหนดเงื่อนไข</label>
                                                    <select className="form-control" name={"promotionCondition"}
                                                        defaultValue={this.props.data && this.props.data.promotionCondition ? this.props.data.promotionCondition : ''}
                                                    >
                                                        <option value={""}>เลือกเงื่อนไข</option>
                                                        <option value={"all"}>ใช้ได้ทุกที่</option>
                                                        <option value={"only_first_code"}>ใช้ครั้งแรกใน code ชุดนั้นๆ</option>
                                                        <option value={"only_first_time"}>ใช้ครั้งแรกใน app</option>
                                                        <option value={"only_first_time_plus_code"}>ใช้ code ครั้งแรกและ ใช้ code ชุดนั้นครั้งแรก</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"form-group"} style={this.props.mode === 'edit' ? { display: 'none' } : null}>
                                            <div className="custom-control custom-switch"
                                            >
                                                <input type="checkbox"
                                                    className="custom-control-input"
                                                    ref={(ref) => { this.$checkboxDate = window.$(ref) }}
                                                    id="isAllTime"
                                                    name={"isAllTime"}
                                                    defaultChecked={this.props.data && !this.props.data.isAllTime}
                                                    defaultValue={"on"}
                                                    onChange={() => {
                                                        if (this.$checkboxDate.prop("checked")) {
                                                            window.$('#date_rage').show()
                                                        } else {
                                                            window.$('#date_rage').hide()
                                                        }
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor="isAllTime">กำหนดเวลา</label>
                                            </div>
                                            <div id={"date_rage"} style={{ display: this.props.data && !this.props.data.isAllTime ? 'block' : 'none' }}>
                                                <div className={"row"}>
                                                    <div className={"col-xs-12 col-lg-6"}>
                                                        <label>วันที่เริ่มต้น</label>
                                                        <DatePicker
                                                            onChange={date => {
                                                                date = new Date(date);
                                                                date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
                                                                let start_date = date.getTime() - (3600 * 7 * 1000);
                                                                this.setState({
                                                                    start_date: start_date
                                                                })
                                                            }}
                                                            selectsStart
                                                            startDate={this.state.start_date}
                                                            endDate={this.state.end_date}
                                                            locale="th-TH"
                                                            selected={this.state.start_date}
                                                            placeholderText={"วัน / เดือน / ปี"}
                                                            name={"birthDay"}
                                                            dateFormat="MMMM d, yyyy 00:00:00"
                                                            withPortal
                                                            onCalendarOpen={() => { Helper.renderDate() }}
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled
                                                            }) => {
                                                                date = new Date(date);
                                                                let now = new Date();
                                                                // months_th
                                                                const years = [];
                                                                let i;
                                                                for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                                    years.push(i)
                                                                }
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            margin: 10,
                                                                            display: "flex",
                                                                            justifyContent: "center"
                                                                        }}
                                                                    >
                                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                            className={"btn btn-primary "}
                                                                            type={"button"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                        >
                                                                            <i className="fas fa-chevron-left" />
                                                                        </button>
                                                                        <select
                                                                            className={"form-control"}
                                                                            value={date.getFullYear()}
                                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {years.map(option => (
                                                                                <option key={option} value={option} >
                                                                                    {option + 543}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <select
                                                                            className={"form-control"}
                                                                            value={window.months_th[date.getMonth()]}
                                                                            onChange={({ target: { value } }) =>
                                                                                changeMonth(window.months_th.indexOf(value))
                                                                            }
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {window.months_th.map(option => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                            className={"btn btn-primary"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                            type={"button"}
                                                                        >
                                                                            <i className="fas fa-chevron-right" />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }}
                                                            customInput={<CustomDateInput />}
                                                        />
                                                        <input type="hidden" id="start_date" name="startAt" value={new Date(this.state.start_date).getTime()} onChange={() => { console.log('change') }} readOnly={true} />
                                                    </div>
                                                    <div className={"col-xs-12 col-lg-6"}>
                                                        <label>วันที่สิ้นสุด</label>
                                                        <DatePicker
                                                            onChange={date => {
                                                                date = new Date(date);
                                                                date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 0));
                                                                let end_date = date.getTime() - (3600 * 7 * 1000);
                                                                this.setState({
                                                                    end_date: end_date
                                                                })
                                                            }}
                                                            selectsEnd
                                                            startDate={this.state.start_date}
                                                            endDate={this.state.end_date}
                                                            locale="th-TH"
                                                            selected={this.state.end_date}
                                                            placeholderText={"วัน / เดือน / ปี"}
                                                            name={"birthDay"}
                                                            dateFormat="MMMM d, yyyy 23:59:00"
                                                            withPortal
                                                            onCalendarOpen={() => { Helper.renderDate() }}
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled
                                                            }) => {
                                                                date = new Date(date);
                                                                let now = new Date();
                                                                // months_th
                                                                const years = [];
                                                                let i;
                                                                for (i = 1980; i <= now.getFullYear() + 1; i++) {
                                                                    years.push(i)
                                                                }
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            margin: 10,
                                                                            display: "flex",
                                                                            justifyContent: "center"
                                                                        }}
                                                                    >
                                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                            className={"btn btn-primary "}
                                                                            type={"button"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                        >
                                                                            <i className="fas fa-chevron-left" />
                                                                        </button>
                                                                        <select
                                                                            className={"form-control"}
                                                                            value={date.getFullYear()}
                                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {years.map(option => (
                                                                                <option key={option} value={option} >
                                                                                    {option + 543}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <select
                                                                            className={"form-control"}
                                                                            value={window.months_th[date.getMonth()]}
                                                                            onChange={({ target: { value } }) =>
                                                                                changeMonth(window.months_th.indexOf(value))
                                                                            }
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {window.months_th.map(option => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                            className={"btn btn-primary"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                            type={"button"}
                                                                        >
                                                                            <i className="fas fa-chevron-right" />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }}
                                                            customInput={<CustomDateInput />}
                                                        />
                                                        <input type="hidden" id="end_date" name="EndAt" value={new Date(this.state.end_date).getTime()} onChange={() => { console.log('change') }} readOnly={true} />
                                                        {
                                                            this.state.end_date ?
                                                                <div><small>ใช้ได้ถึง {this.endDateDetail()}</small></div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"form-group"} style={this.props.mode === 'edit' ? { display: 'none' } : null}>
                                            <div className="custom-control custom-switch"
                                            >
                                                <input type="checkbox"
                                                    className="custom-control-input"
                                                    ref={(ref) => { this.$checkboxLimit = window.$(ref) }}
                                                    id="isUnLimit"
                                                    name={"isUnLimit"}
                                                    defaultChecked={this.props.data && this.props.data.isUnLimit}
                                                    defaultValue={"on"}
                                                    onChange={() => {
                                                        if (this.$checkboxLimit.prop("checked")) {
                                                            window.$('#limit_user').hide()
                                                        } else {
                                                            window.$('#limit_user').show()
                                                        }
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor="isUnLimit">ไม่จำกัดการใช้งาน</label>
                                            </div>
                                            <div id={"limit_user"} style={{ display: !this.props.data || !this.props.data.isUnLimit ? 'block' : 'none' }}>
                                                <label>จำนวนโปรโมชั่นที่สามารถใช้งาน</label>
                                                <input name={"limit"}
                                                    type={"number"}
                                                    className={"form-control"}
                                                    defaultValue={this.props.data && this.props.data.limit ? this.props.data.limit : '0'}
                                                />
                                            </div>
                                        </div>
                                        <div className={"row"} style={this.props.mode === 'edit' ? { display: 'none' } : null}>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div className={"form-group"}>
                                                    <label>รหัสโค้ด</label>
                                                    <input name={"voucherCode"}
                                                        className={"form-control"}
                                                        defaultValue={this.props.data && this.props.data.voucherCode ? this.props.data.voucherCode : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div className={"form-group"}>
                                                    <label>ส่วนลด</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            name={"value"}
                                                            defaultValue={this.props.data && this.props.data.value ? this.props.data.value : '0'}
                                                        />
                                                        <div className="input-group-append">
                                                            <select className="form-control"
                                                                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                name={"valueType"}
                                                                defaultValue={this.props.data && this.props.data.valueType ? this.props.data.valueType : 'raw'}
                                                            >
                                                                <option value={"raw"}>฿</option>
                                                                <option value={"percent"}>%</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"row"} style={this.props.mode === 'edit' ? { display: 'none' } : null}>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div className={"form-group"}>
                                                    <label>ราคาขั้นต่ำ</label>
                                                    <input name={"minPrice"}
                                                        className={"form-control"}
                                                        defaultValue={this.props.data && this.props.data.minPrice ? parseFloat(this.props.data.minPrice) : 0}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div className={"form-group"}>
                                                    <label>ราคาสูงสุด</label>
                                                    <input name={"maxPrice"}
                                                        className={"form-control"}
                                                        defaultValue={this.props.data && this.props.data.maxPrice ? parseFloat(this.props.data.maxPrice) : 0}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }

                            {
                                this.props.mode === 'view' ?
                                    <>
                                        <div className={"row"}>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div>
                                                    <label>ประเภท</label> : {' '}
                                                    {
                                                        this.props.data.promotionType === 'event' ?
                                                            "แสดงในหน้า app"
                                                            : null
                                                    }
                                                    {
                                                        this.props.data.promotionType === 'unique' ?
                                                            "สำหรับการตลาดไปหาลูกค้าเอง"
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div>
                                                    <label>ชื่อโปรโมชั่น</label> : {this.props.data.name || '-'}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label>รูปภาพ</label>
                                            <div>
                                                {this.props.data.imageURL ?
                                                    <img src={this.props.data.imageURL} alt={"PromotionPic"} style={{ maxWidth: '100%' }} />
                                                    : '-'
                                                }
                                            </div>
                                        </div>
                                        <div >
                                            <label>รายละเอียดโปรโมชั่น</label>
                                            <div>
                                                {this.props.data.description || '-'}
                                            </div>
                                        </div>
                                        <div className={"row"}>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div>
                                                    <label>ผู้รับผิดชอบ</label> :  {this.props.data.market_user ? this.props.data.market_user.name : '-'}
                                                </div>
                                            </div>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div>
                                                    <label>กำหนดเงื่อนไข</label> :  {' '}
                                                    {
                                                        this.props.data.promotionCondition === 'all' ?
                                                            "ใช้ได้ทุกที่"
                                                            : null
                                                    }
                                                    {
                                                        this.props.data.promotionCondition === 'only_first_code' ?
                                                            "ใช้ครั้งแรกใน code ชุดนั้นๆ"
                                                            : null
                                                    }
                                                    {
                                                        this.props.data.promotionCondition === 'only_first_time' ?
                                                            "ใช้ครั้งแรกใน app"
                                                            : null
                                                    }
                                                    {
                                                        this.props.data.promotionCondition === 'only_first_time_plus_code' ?
                                                            "ใช้ code ครั้งแรกและ ใช้ code ชุดนั้นครั้งแรก"
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                {
                                                    !this.props.data.isAllTime ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditAdmin">กำหนดเวลา</label>
                                            </div>
                                            {
                                                !this.props.data.isAllTime ?
                                                    <div id={"date_rage"}>
                                                        <div className={"row"}>
                                                            <div className={"col-xs-12 col-lg-6"}>
                                                                <label>วันที่เริ่มต้น</label> : {Helper.getDateThai(this.props.data.startAt)}
                                                            </div>
                                                            <div className={"col-xs-12 col-lg-6"}>
                                                                <label>วันที่สิ้นสุด</label> : {Helper.getDateThai(this.props.data.EndAt)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </div>
                                        <div>
                                            <div>
                                                {
                                                    this.props.data.isUnLimit ?
                                                        <i className="fas fa-toggle-on switch-icon on"></i>
                                                        :
                                                        <i className="fas fa-toggle-off switch-icon"></i>
                                                }
                                                <label htmlFor="isCanEditAdmin">ไม่จำกัดการใช้งาน</label>
                                            </div>
                                            {
                                                this.props.data.isUnLimit ?
                                                    <div id={"limit_user"}>
                                                        <label>จำนวนโปรโมชั่นที่สามารถใช้งาน</label> : {this.props.data.used} / {this.props.data.limit}
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className={"row"}>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div>
                                                    <label>รหัสโค้ด</label> : {this.props.data.voucherCode || '-'}
                                                </div>
                                            </div>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div>
                                                    <label>ส่วนลด</label> :  {this.props.data.value}
                                                    {this.props.data.valueType === 'raw' ? ' บาท' : null}
                                                    {this.props.data.valueType === 'percent' ? ' %' : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"row"}>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div>
                                                    <label>ราคาขั้นต่ำ</label> : {this.props.data.minPrice ? Helper.numberFormat(this.props.data.minPrice) + ' บาท' : '0'}
                                                </div>
                                            </div>
                                            <div className={"col-xs-12 col-lg-6"}>
                                                <div>
                                                    <label>ราคาสูงสุด</label> : {this.props.data.maxPrice ? Helper.numberFormat(this.props.data.maxPrice) + ' บาท' : '0'}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }

                            <div className={"form-group"} >
                                <div className="custom-control custom-switch"
                                >
                                    <input type="checkbox"
                                        className="custom-control-input"
                                        ref={(ref) => { this.$checkboxShowWeb = window.$(ref) }}
                                        id="isShowWeb"
                                        name={"isShowWeb"}
                                        defaultChecked={this.props.data && this.props.data.isShowWeb}
                                        defaultValue={"on"}
                                    />
                                    <label className="custom-control-label" htmlFor="isShowWeb">แสดงบนหน้าเว็บไซด์</label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >
                                ยกเลิก
                            </button>
                            {
                                this.props.mode === 'create' || this.props.mode === 'edit' ?
                                    <button className={"btn btn-primary"} type={"submit"}>บันทึก</button>
                                    : null
                            }
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

class PromotionSearchForm extends React.Component {
    constructor(props) {
        super(props);
        let defaultStartAt = new Date().setHours(0, 0, 0, 0);
        let defaultEndAt = new Date().setHours(23, 59, 0, 0);
        this.state = {
            start_date: props.data && props.data.startAt ? new Date(props.data.startAt) : defaultStartAt,
            end_date: props.data && props.data.EndAt ? new Date(props.data.EndAt) : defaultEndAt,
        }
    }


    render() {
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) { return false; }
                        this.$submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        this.props.onSuccess(data);
                        this.$modal.modal('hide')
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                ค้นหาโปรโมชั่น
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className={"row"}>
                                <div className={"col-xs-12 col-lg-6"}>
                                    <div className={"form-group"}>
                                        <label>ชื่อโปรโมชั่น</label>
                                        <input type={"text"}
                                            name={"name"}
                                            defaultValue={this.props.data && this.props.data.name ? this.props.data.name : ''}
                                            className={"form-control"}
                                        />
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-lg-6"}>
                                    <div className={"form-group"}>
                                        <label>ประเภท</label>
                                        <select className="form-control"
                                            name={"type"}
                                            defaultValue={this.props.data && this.props.data.type ? this.props.data.type : ''}
                                        >
                                            <option value={""}>เลือกประเภท</option>
                                            <option value={"event"}>แสดงในหน้า app</option>
                                            <option value={"unique"}>สำหรับการตลาด</option>
                                        </select>
                                    </div>
                                </div>

                                <div className={"col-xs-12 col-lg-6"}>
                                    <div className={"form-group"}>
                                        <label>เงื่อนไข</label>
                                        <select className="form-control" name={"promotionCondition"}
                                            defaultValue={this.props.data && this.props.data.promotionCondition ? this.props.data.promotionCondition : ''}
                                        >
                                            <option value={""}>เลือกเงื่อนไข</option>
                                            <option value={"all"}>ใช้ได้ทุกที่</option>
                                            <option value={"only_first_code"}>ใช้ครั้งแรกใน code ชุดนั้นๆ</option>
                                            <option value={"only_first_time"}>ใช้ครั้งแรกใน app</option>
                                            <option value={"only_first_time_plus_code"}>ใช้ code ครั้งแรกและ ใช้ code ชุดนั้นครั้งแรก</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-lg-6"}>
                                    <div className={"form-group"}>
                                        <label>ช่วงเวลา</label>
                                        <select className="form-control" name={"isAllTime"}
                                            defaultValue={this.props.data && this.props.data.isAllTime ? this.props.data.isAllTime : ''}
                                        >
                                            <option value={""}>ทั้งหมด</option>
                                            <option value={"false"}>กำหนด</option>
                                            <option value={"true"}>ไม่กำหนด</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-lg-6"}>
                                    <div className={"form-group"}>
                                        <label>จำนวนสิทธ์</label>
                                        <select className="form-control" name={"isUnLimit"}
                                            defaultValue={this.props.data && this.props.data.isUnLimit ? this.props.data.isUnLimit : ''}
                                        >
                                            <option value={""}>ทั้งหมด</option>
                                            <option value={"false"}>กำหนด</option>
                                            <option value={"true"}>ไม่กำหนด</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-lg-6"}>
                                    <div className={"form-group"}>
                                        <label>ผู้รับผิดชอบ</label>
                                        <select className="form-control" name={"market_user_id"}
                                            defaultValue={this.props.data && this.props.data.market_user_id ? this.props.data.market_user_id : ''}
                                        >
                                            <option value={""}>ทั้งหมด</option>
                                            {
                                                this.props.market_user.map((margin_user, margin_user_i) => (
                                                    <option value={margin_user.id} key={margin_user_i}>{margin_user.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >
                                ยกเลิก
                            </button>
                            <button className={"btn btn-primary"} type={"submit"}>บันทึก</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}